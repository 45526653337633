import moment from 'moment';

export default ['$scope', '$location', '$async', '$state', 'API_URL', 'WEBAPP_URL', 'ywUtil', 'ywEvent', 'confirmDialog', 'Upload', 'unorderedReservationUtil', 'currentUser', 'Restangular', 'UnorderedReservation', 'address', 'filterItemFactory', 'reservationUtil', 'sinyiStaffInfoUtil', 'ngDialog',
  function controller ($scope, $location, $async, $state, API_URL, WEBAPP_URL, ywUtil, ywEvent, confirmDialog, Upload, unorderedUtil, currentUser, Restangular, UnorderedReservation, address, filterItemFactory, reservationUtil, sinyiStaffInfoUtil, ngDialog) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;
    $scope.useReactTable = true;
    $scope.validStates = [];
    $scope.isCompletedStates = [];

    const isServiceAdmin = currentUser.hasRole('serviceAdmin');
    const { userId, state, removeVisibility, unorderedReservationNo } = $location.search();

    const resetExtraParams = () => {
      $scope.extraFilter.sort.selected = '-createdTime';
      $scope.extraFilter.durationType.selected = 'createdTime';
      $scope.extraFilter.startDate.value = moment().subtract(1, 'year').add(1, 'day').startOf('day').toDate();
      $scope.extraFilter.dueDate.value = moment().endOf('day').toDate();
    };

    const clearFilter = $scope.mainAction.clearFilter;
    $scope.mainAction.clearFilter = () => {
      resetExtraParams();
      clearFilter(['sort', 'createdAfterOrEqualTo', 'createdBeforeOrEqualTo'], $scope.searchBar.textInput);
    };

    const setCurrentExtraQueryToQueryParams = () => {
      const startDate = $scope.extraFilter.startDate.value;
      const dueDate = $scope.extraFilter.dueDate.value;
      if ($scope.extraFilter.durationType.selected === 'createdTime') {
        delete $scope.mainAction.queryParams.bookedSession;
        $scope.mainAction.queryParams.createdAfterOrEqualTo = startDate && moment(startDate).startOf('day').toDate();
        $scope.mainAction.queryParams.createdBeforeOrEqualTo = dueDate && moment(dueDate).endOf('day').toDate();
      } else if ($scope.extraFilter.durationType.selected === 'bookedSession') {
        delete $scope.mainAction.queryParams.createdAfterOrEqualTo;
        delete $scope.mainAction.queryParams.createdBeforeOrEqualTo;
        $scope.mainAction.queryParams.bookedSession = JSON.stringify({
          start: startDate && moment($scope.extraFilter.startDate.value).startOf('day').toDate(),
          end: dueDate && moment($scope.extraFilter.dueDate.value).endOf('day').toDate()
        });
      }

      $scope.mainAction.queryParams.sort = $scope.extraFilter.sort.selected;
    };

    const filterQuery = $scope.mainAction.filterQuery;
    $scope.mainAction.filterQuery = () => {
      // Origin condition
      filterItemFactory.setQueryParamsFromOriginFilter($scope, '_origin');

      setCurrentExtraQueryToQueryParams();

      filterQuery();

      ywEvent.emit('IFC_EVENT', { type: 'SHOW_PIN_RESERVATIONS', payload: getPinReservationShowFlag() });
      if ($scope.mainAction.pager.currentPage === 1) {
        loadPinReservations();
      }
    };

    const init = $async(async () => {
      // init event handler
      $scope.reactIFCEvent = ywEvent.register('IFC_EVENT_FROM_REACT', handleEventFromReact);
      $scope.websocketEvent = ywEvent.register('WEBSOCKET_NOTIFICATION', handleWebsocketEvent);

      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '訂單編號', placeholder: '請輸入訂單編號', params: 'unorderedReservationNo' },
          { btnName: '聯絡人名稱', placeholder: '請輸入聯絡人名稱', params: 'name' },
          { btnName: '聯絡人電話', placeholder: '請輸入聯絡人電話', params: 'phone' },
          { btnName: '聯絡人mail', placeholder: '請輸入聯絡人mail', params: 'email' },
          { btnName: '聯絡地址', placeholder: '請輸入聯絡地址', params: 'address' },
          { btnName: '會員ID', placeholder: '請輸入會員ID', params: 'userId' },
          { btnName: '物件編號', placeholder: '請輸物件編號', params: 'objectNo' },
          { btnName: '服務分類', placeholder: '請輸服務分類', params: 'serviceClassificationName' },
          { btnName: '服務方案', placeholder: '請輸服務方案', params: 'serviceName' },
          { btnName: '推薦同仁員編', placeholder: '請輸入推薦同仁員編', params: 'empId' },
          { btnName: '推薦同仁姓名', placeholder: '請輸入推薦同仁姓名', params: 'empName' }
        ].concat(
          isServiceAdmin
            ? [
                { btnName: '廠商名稱', placeholder: '請輸入廠商名稱', params: 'venderName' },
                { btnName: '推薦同仁手機', placeholder: '請輸入推薦同仁手機', params: 'empPhone' }
              ]
            : []
        )
      );

      // order states
      const states = await Restangular.service('freeStates').getList({ limit: 200 });
      if (!states.error) {
        $scope.validStates = currentUser.hasRole('serviceAdmin')
          ? states
          : states.filter(state => !state.isInitial);
        $scope.isCompletedStates = $scope.validStates.filter(state => state.isCompleted);
      }

      $scope.customTextChangeCb = function (text) {
        $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
          ? undefined
          : text;
      };

      if (userId) {
        $scope.customTextChangeCb(userId);
        $scope.mainAction.customSearchText = userId;
        const newOption = _.find($scope.searchBarOptions, option => option.params === 'userId');
        $scope.changeSearchBarActiveOption($scope.searchBarOptionsActive, newOption);
      }

      if (state) {
        const orderState = typeof state === 'string' && state;
        if (orderState === 'completed') {
          $scope.mainAction.queryParams.state = $scope.isCompletedStates.map(
            state => { return state._id; }
          );
        }
      }

      if (unorderedReservationNo) {
        $scope.customTextChangeCb(unorderedReservationNo);
        $scope.mainAction.customSearchText = unorderedReservationNo;
      }

      const stateFilterItem = {
        name: 'state',
        label: '訂單狀態',
        options: $scope.validStates.map(
          state => ({ label: state.displayNameAtConsole, value: state._id })
        ),
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'multiFilter'
      };

      const districtFilterItem = filterItemFactory.getDistrictFilter(
        $scope,
        'regions',
        '_counties',
        '_districts'
      );
      const countyFilterItem = filterItemFactory.getCountyFilter(
        $scope,
        'regions',
        '_counties',
        '_districts',
        districtFilterItem
      );

      const serviceClassificationFilterItem = filterItemFactory.getServiceClassificationFilter(
        $scope,
        'serviceClassification',
        '_service',
        'service'
      );
      const serviceFilterItem = filterItemFactory.getServiceFilter(
        $scope,
        '_service',
        'service',
        'serviceClassification'
      );

      const originFilterItem = {
        name: '_origin',
        label: '訂單來源',
        options: ywUtil.getDefaultOriginOptions(),
        changed: $scope.mainAction.setClearFilterTrue,
        click: $async(async () => {
          const sourceRestrictionSetting = await Restangular
            .service('systemSettings')
            .one('sourceRestrictionSetting')
            .get();
          if (sourceRestrictionSetting.error) {
            $scope.alert(sourceRestrictionSetting.error);
          } else {
            const defaultOriginOptions = ywUtil.getDefaultOriginOptions();
            const originList = (sourceRestrictionSetting || []).map(value => ({ label: value, value }));
            originFilterItem.options = defaultOriginOptions.concat(originList);
          }
        }),
        type: 'multiFilter'
      };

      const labelInput = {
        name: 'labels',
        label: '訂單標籤',
        options: [],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'colorFilter',
        click: $async(async () => {
          const res = await Restangular.service('labels').getList({ limit: 999, target: 'commonReservation' });
          if (!res.error) {
            labelInput.options = res.map(label => ({
              label: label.name,
              value: label._id,
              color: label.color
            }));
          }
        })
      };

      const paymentMethodOptions = [
        { label: '現金', value: 'cash' },
        { label: '匯款', value: 'bankTransfer' },
        { label: '感服金請款', value: 'branchBudget' },
        { label: '信用卡', value: 'card' },
        { label: 'Apple pay', value: 'applePay' }
      ];
      const visibilityOptions = [
        { label: '未隱藏訂單', value: 'visible' },
        { label: '已隱藏訂單', value: 'invisible' }
      ];

      /* Order & date filter */
      $scope.searchBar.hideSearchAndClearBtn = true;
      $scope.customizeContentTemplate = '/view/page/unordered-reservation-filter.html';
      $scope.extraFilter = {
        sort: {
          selected: '-createdTime',
          options: _.flatten([
            ['createdTime', '建立時間'],
            ['bookedTime', '服務時間'],
            ['completedTime', '訂單完成時間']
          ].map(([field, name]) => [
            { value: `-${field}`, label: `${name} 新-舊` },
            { value: `${field}`, label: `${name} 舊-新` }
          ]))
        },
        durationType: {
          options: [
            { value: 'createdTime', label: '依照建立時間' },
            { value: 'bookedSession', label: '依照服務時間' }
          ],
          selected: 'createdTime'
        },
        startDate: {
          value: $location.search().removeDateStart ? undefined : moment().subtract(1, 'year').add(1, 'day').startOf('day').toDate(),
          changed: (date) => {
            $scope.extraFilter.startDate.value = moment(date).startOf('day').toDate();
          }
        },
        dueDate: {
          value: moment().endOf('day').toDate(),
          changed: (date) => {
            $scope.extraFilter.dueDate.value = moment(date).endOf('day').toDate();
          }
        }
      };

      $scope.filterItems = [
        stateFilterItem,
        countyFilterItem,
        districtFilterItem,
        serviceClassificationFilterItem,
        serviceFilterItem,
        originFilterItem,
        {
          name: 'hasProcessingImage',
          label: '完工照',
          options: { true: '有完工照', false: '無完工照' },
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        },
        {
          name: 'defaultPaymentMethod',
          label: '付款方式',
          options: paymentMethodOptions,
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'multiFilter'
        },
        labelInput,
        { name: 'billProcessStatus', label: '出帳狀態', options: { published: '已出帳', yet: '未出帳' }, changed: $scope.mainAction.setClearFilterTrue, type: 'noOwnAttrSelect' },
        {
          name: 'displayVisible',
          label: '隱藏訂單',
          options: visibilityOptions,
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        }
      ];

      // control buttons
      if (currentUser.hasRole('serviceAdmin')) {
        $scope.toolbarBtns = [
          {
            label: '設定標籤',
            onClick: $async(async () => {
              const selectedItems = $scope.items.filter(item => item._selected);
              const selectedIds = selectedItems.map(item => item._id);
              if (!selectedItems.length) { return $scope.alert('請選擇要設定的項目'); }
              const data = await confirmDialog.openConfirm({
                title: '設定訂單標籤',
                templateUrl: '/view/dialog/reservation-color-label-setting.html',
                labelResource: Restangular.service('labels'),
                confirmValue: {
                  action: 'add',
                  labels: []
                }
              });
              const results = await UnorderedReservation.one('labels').customPUT({
                unorderedReservations: selectedIds,
                labels: data.labels,
                action: data.action
              });
              if (results.error) {
                $scope.alert(results.error);
              } else {
                $scope.mainAction.filterQuery();
                $scope.success('設定完成');
              }
            })
          }
        ];
      }

      $scope.dropdownItemList = [
        {
          label: '建立自建單',
          items: [
            {
              buttonListText: '單筆建立',
              callback: async function openSelfConstructSingleReservationPage () {
                const token = currentUser.getToken();
                const { cipherText } = await getEncryptToken(token);
                if (cipherText) {
                  const params = {
                    scCode: cipherText,
                    utm_source: 'backstage',
                    utm_medium: 'customerservice',
                    clearCart: true
                  };
                  window.open(`${WEBAPP_URL}self-construct-singlereservation?${ywUtil.convertSimpleObjToQueryString(params)}`, '_blank');
                }
              }
            },
            {
              buttonListText: '批次匯入',
              callback: function importSelfConstructCSV (file) {
                if (!file) return;

                // 檢查檔案類型，基本上不會用到因上方 dropdownItemList 已先限制，多做一層預防萬一
                const validFileType = 'text/csv';
                if (file.type !== validFileType) {
                  $scope.alert('請提供一個 csv 檔案');
                  return;
                }

                $scope.status.isLoading = true;
                Upload.upload({
                  url: `${API_URL}unorderedReservations/importCsv`,
                  header: { Authorization: `Bearer ${currentUser.getToken()}` },
                  data: {
                    file: file,
                    envInfo: { deviceType: 'web', origin: 'lsWeb' },
                    utm: {
                      source: 'backstage',
                      medium: 'customerservice'
                    }
                  }
                }).then(res => {
                  if (res.data.error) {
                    $scope.alert(res.data.msg);
                  } else {
                    $scope.success('檔案已匯入，建立中...');
                  }
                }).catch(() => {
                  // 處理非預期錯誤
                  $scope.alert('上傳失敗，請檢查檔案格式或稍後再試');
                }).finally(() => {
                  $scope.status.isLoading = false;
                });
              },
              file: { accept: 'text/csv' }
            },
            {
              buttonListText: '批次匯入自建單說明',
              class: 'fas fa-info-circle',
              callback: function openSelfConstructTipPage () {
                window.open('https://link.livinglife.com.tw/s57HfB', '_blank');
              }
            }
          ]
        },
        {
          label: '下載',
          items: [
            { buttonListText: '訂單資料', class: 'fab fa-apple', callback: _.partial(exportCsv, 'utf8') },
            { buttonListText: '訂單資料', class: 'fab fa-windows', callback: _.partial(exportCsv, 'big5') }
          ]
        }
      ];

      if (currentUser.hasRole('serviceAdmin')) {
        $scope.dropdownItemList[0].items = $scope.dropdownItemList[0].items.concat([
          {
            buttonListText: '批次匯入自建單紀錄',
            callback: async function openSelfConstructLogDialog () {
              const logs = await Restangular.service('batchReservationImportLogs').getList();
              confirmDialog.openConfirm({
                title: '建立自建單紀錄',
                templateUrl: '/view/dialog/self-construct-import-log.html',
                hideCancel: true,
                logs: _.map(logs, log => ({
                  ...log,
                  _successCount: _.uniqWith(_.get(log, 'successResults', []), log => log.rows).length,
                  _errorCount: _.uniqWith(_.get(log, 'errorResults', []), log => log.rows).length
                })),
                onErrorDetailClick: async function openSelfConstructImportErrorDialog (log) {
                  confirmDialog.openConfirm({
                    title: '匯入內容錯誤',
                    templateUrl: '/view/dialog/self-construct-import-error-log.html',
                    hideCancel: true,
                    errors: log.errorResults
                  }, 'custom-width-600');
                },
                onDownloadFile: (file) => ywUtil.downloadFile(file.url, file.originalFilename)
              }, 'custom-width-600');
            }
          }
        ]);
      }

      $scope.queryPage = 'unorderedReservation'; // for GA

      setCurrentExtraQueryToQueryParams();
      if (removeVisibility) {
        $scope.mainAction.initResource(
          UnorderedReservation,
          { isGetConnectionSetting: true }
        );
      } else {
        $scope.mainAction.initResource(
          UnorderedReservation,
          { displayVisible: 'visible', isGetConnectionSetting: true }
        );
      }

      loadPinReservations();
      setTimeout(() => {
        ywEvent.emit(
          'IFC_EVENT',
          { type: 'SHOW_PIN_RESERVATIONS', payload: getPinReservationShowFlag() }
        );
      }, 500);

      $scope.listItemUpdateEvent = ywEvent.register('UPDATE_ITEM_IN_LIST', handleItemUpdateInList);
      $scope.watchPerPage = $scope.$watch('mainAction.pager.currentPage', currentPageUpdated);
    });

    function initActionItem () {
      $scope.currentActionItem = {
      };
    }

    function exportCsv (encodeType) {
      const { limit, skip, where, ...rest } = $scope.mainAction.queryParams;
      const params = Object.assign({ encodeType }, rest);
      params.timezoneOffset = -new Date().getTimezoneOffset();
      window.location = API_URL + 'unorderedReservations/exportCsv?' +
        ywUtil.convertSimpleObjToQueryString(params) +
        '&access_token=' + currentUser.getToken();
    }

    async function getEncryptToken (token) {
      try {
        if (!token) throw new Error('User\'s token is missing.');

        $scope.status.isLoading = true;
        const result = await Restangular.service('system/encrypt').post({
          type: 'selfConstructedBooking',
          plainText: token
        });
        if (result.error) throw new Error(result.error);
        return result;
      } catch (error) {
        ywEvent.emit('ALERT', error);
      } finally {
        $scope.status.isLoading = false;
      }
    }

    const handleEventFromReact = $async(async ({ type, payload }) => {
      if (type === 'OPEN_RESERVATION_STATE_DIALOG') {
        unorderedUtil.handleStateControl({ reservation: payload });
      } else if (type === 'OPEN_VENDER_ASSIGN_DIALOG') {
        unorderedUtil.handleVenderAssign({ reservation: payload });
      } else if (type === 'OPEN_RESERVATION_DETAIL') {
        const displayVisible = _.get($scope.mainAction, 'queryParams.displayVisible');
        unorderedUtil.openDetail({ reservation: payload, displayVisible });
      } else if (type === 'OPEN_PIN_DIALOG') {
        unorderedUtil.openPinDialog({
          reservation: payload.reservation,
          isPined: payload.isPined
        });
      } else if (type === 'OPEN_CONTACT_DEADLINE_DIALOG') {
        unorderedUtil.openContactDeadlineDialog({ reservation: payload });
      } else if (type === 'OPEN_SINYI_STAFF_EDIT_DIALOG') {
        sinyiStaffInfoUtil.modifySinyiStaffInfo({ reservation: payload });
      } else if (type === 'OPEN_SINYI_STAFF_REMOVE_DIALOG') {
        sinyiStaffInfoUtil.removeSinyiStaffInfo({ reservation: payload });
      }
    });

    const handleWebsocketEvent = $async(async (eventSource) => {
      console.log('ws event: ', eventSource);
      const { event } = eventSource;
      if (!event) {
        return;
      }
      const { key, msg, payload } = event;
      if (key === 'RESERVATION_CREATED' || key === 'UNORDERED_RESERVATION_CREATED') {
        $scope.info(msg);
      } else if (key === 'UNORDERED_RESERVATION_STATE_CHANGED') {
        const id = payload.unorderedReservationId;
        const reservation = await UnorderedReservation.one(id).customGET();
        $scope.updateItemInList(reservation);
      } else if (key === 'UNORDERED_RESERVATION_TRANSFER') {
        $scope.info(msg, payload);
        if (currentUser.hasRole('vender')) {
          const id = payload.unorderedReservationId;
          $scope.removeItemInList({ _id: id });
        }
      } else if (key === 'UNORDERED_RESERVATION_UPDATE_QUOTATION') {
        const id = payload.unorderedReservationId;
        const reservation = await UnorderedReservation.one(id).customGET();
        const displayVisible = _.get($scope.mainAction, 'queryParams.displayVisible');
        $scope.updateItemInList(reservation);
        ngDialog.close();
        unorderedUtil.openDetail({ reservation, displayVisible });
      }
    });

    function handleItemUpdateInList (item) {
      loadPinReservations();
    }

    function currentPageUpdated (newValue, oldValue) {
      if ($scope.mainAction.pager.isSafe) {
        ywEvent.emit(
          'IFC_EVENT',
          { type: 'SHOW_PIN_RESERVATIONS', payload: $scope.mainAction.pager.currentPage === 1 }
        );
        if ($scope.mainAction.pager.currentPage === 1) {
          loadPinReservations();
        }
      }
    }

    let isPinReservationSyncLoading = false;
    async function loadPinReservations () {
      if (isPinReservationSyncLoading) {
        return;
      }
      isPinReservationSyncLoading = true;
      const pinResults = await Restangular
        .service('pinReservations')
        .one('myself')
        .customGET('', { limit: 999, target: 'unorderedReservation' });
      const pinReservations = pinResults.error ? [] : pinResults.plain();
      ywEvent.emit('IFC_EVENT', { type: 'SET_PIN_RESERVATIONS', payload: pinReservations });
      isPinReservationSyncLoading = false;
    }

    function getPinReservationShowFlag () {
      const { skip, limit, sort, ...rest } = $scope.mainAction.queryParams;
      const defaultFilter = {
        createdAfterOrEqualTo: moment().subtract(1, 'year').add(1, 'day').startOf('day').toDate(),
        createdBeforeOrEqualTo: moment().endOf('day').toDate(),
        isGetConnectionSetting: true
      };
      if (currentUser.hasRole('serviceAdmin') && !$location.search().removeVisibility) {
        defaultFilter.displayVisible = 'visible';
      }
      return _.isEqual(defaultFilter, rest);
    }

    $scope.$on('$destroy', () => {
      ywEvent.unregister($scope.reactIFCEvent);
      ywEvent.unregister($scope.websocketEvent);
      ywEvent.unregister($scope.listItemUpdateEvent);
    });

    init();
  }];
