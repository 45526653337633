export default ['$scope', 'confirmDialog', 'Restangular',
  function controller ($scope, confirmDialog, Restangular) {
    $scope._delete = _delete;
    $scope._enable = _enable;
    $scope._makeVoid = _makeVoid;

    /**
     * Base delete callback to be inherited. With valdation injector and basic success callback.
     * @return {Object} Promise resolved with array of deleted ids.
     */
    function _delete (items) {
      return confirmDialog.openConfirm({
        title: '刪除',
        content: '確定刪除？',
        confirmValue: items,
        isDangers: true
      }).then(deleteSelectedItems)
        .then(allRequestsFinished)
        .catch(err => {
          if (err !== '$document' && !_.isUndefined(err)) $scope.alert(err);
        });
    }

    function _enable (items) {
      return confirmDialog.openConfirm({
        title: '確定上架？',
        content: '＊地標上架後須等到下次重新計算才會生效',
        confirmValue: items
      }).then(enableCallback)
        .then(allRequestsFinished)
        .catch($scope.alert);
    }

    function _makeVoid (items) {
      return confirmDialog.openConfirm({
        title: '作廢',
        content: '作廢後無法復原！確定作廢？',
        confirmValue: items
      }).then(makeVoidCallback);
    }

    function enableCallback (items) {
      $scope.status.isLoading = true;
      console.log('ready to call put API: ', items);

      // build API call promises
      const promises = _.map(items, item => {
        return item.put();
      });
      return [Promise.all(promises), items];
    }

    function makeVoidCallback (items) {
      $scope.status.isLoading = true;

      const putData = {
        couponIds: []
      };

      items.forEach(function (item) {
        putData.couponIds.push(item._id);
      });

      return Restangular.all('coupons')
        .customPUT(putData, 'manualLoseEffective')
        .then(function (items) {
          $scope.status.isLoading = false;
          $scope.success('完成。');
          return _.map(items, '_id');
        }, function (response) {
          $scope.status.isLoading = false;
          if (response.result) {
            return $scope.alert(`Error : ${response.result.errorMessage}`);
          } else {
            return $scope.alert('請檢查網路連線');
          }
        });
    }

    function deleteSelectedItems (items) {
      $scope.status.isLoading = true;
      console.log('ready to call delete API: ', items);
      return [Promise.all(_.map(items, item => item.remove())), items];
    }

    function allRequestsFinished ([promise, items]) {
      return promise.then(function (res) {
        $scope.status.isLoading = false;

        // find the first error message returned
        const error = _.get(_.find(res, function (obj) { return _.get(obj, 'error'); }), 'error');
        if (error) throw new Error(error);
        $scope.success('完成。');

        return _.map(_.filter(items, item => !item.error), '_id');
      });
    }
  }];
