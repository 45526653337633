// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/cross.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "  <div class=\"life-title-large life-standard-title\" ng-if=\"ngDialogData.title\">\n    {{ngDialogData.title}}\n  </div>\n  <img class=\"life-dialog-cross\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" ng-click=\"closeThisDialog()\"/>\n\n  <div class=\"life-content pre-line\" ng-if=\"ngDialogData.content\" style=\"margin-top: 20px;\" ng-bind=\"ngDialogData.content\"></div>\n  <div class=\"life-content template\" ng-if=\"ngDialogData.templateUrl\" ng-include=\"ngDialogData.templateUrl\"></div>\n  <div class=\"life-content\" ng-if=\"ngDialogData.htmlContent\" ng-bind-html=\"ngDialogData.htmlContent\"></div>\n  <life-dialog-footer \n    hide-footer=\"ngDialogData.hideFooter\" \n    left-button=\"ngDialogData.leftButton\"\n    right-button=\"ngDialogData.rightButton\"\n    is-full-width-page=\"ngDialogData.isFullWidthPage\"\n    ng-dialog-data=\"ngDialogData\"\n    confirm=\"confirm()\"\n    close-this-dialog=\"closeThisDialog()\"\n  />\n";
// Exports
var _module_exports = code;;
var path = '/view/life-template/life-dialog.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;