import moment from 'moment';
export default ['$scope', '$async', '$state', 'ywUtil', 'Account', 'Restangular', 'API_URL', 'currentUser', 'confirmDialog',
  function controller ($scope, $async, $state, ywUtil, Account, Restangular, API_URL, currentUser, confirmDialog) {
    $scope.filterItems = [];
    $scope.tableHead = [];

    const labelInput = {
      name: 'venderLabels',
      label: '廠商標籤',
      options: [],
      changed: $scope.mainAction.setClearFilterTrue,
      type: 'colorFilter',
      click: $async(async () => {
        const res = await Restangular.service('labels').getList({ limit: 999, target: 'vender' });
        if (!res.error) {
          labelInput.options = res.map(label => ({
            label: label.name,
            value: label._id,
            color: label.color
          }));
        }
      })
    };

    $scope.filterItems = [
      labelInput
    ];

    $scope.searchBarOptions = [
      {
        btnName: '公司名稱',
        placeholder: '請輸入公司名稱',
        params: 'venderName'
      },
      {
        btnName: '公司統編',
        placeholder: '請輸入公司統編',
        params: 'vatNo'
      },
      {
        btnName: '虛擬帳號',
        placeholder: '請輸入虛擬帳號',
        params: 'virtualAccount'
      }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    $scope.toolbarBtns = [
      {
        label: '交易明細',
        onClick: () => {
          $state.go('service-admin.bill/account-transaction-detail');
        }
      }
    ];

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'account'; // for GA

      $scope.mainAction.initResource(Account);
    });

    init();

    $scope.customizeInfo = '帳戶將在每日凌晨2:00~3:00及每月19號22:00~20號07:00進行更新，並處理帳單繳款核銷作業，退款功能將無法執行操作。';

    $scope.cellActions = {
      openDetail: (item) => {
        $state.go('service-admin.bill/account-transaction-detail', { vatNo: item.vatNo });
      }
    };

    const format = 'hh:mm';
    $scope.onBtnClick = (item) => {
      const now = moment();
      if (now.isBetween(moment('02:00', format), moment('03:00', format)) ||
    ((now.date() === 19 && now.hour() >= 22) ||
    (now.date() === 20 && now.hour() < 7))) {
        return $scope.alert('每日凌晨2:00~3:00及每月19號22:00~20號07:00帳務處理中，功能暫停使用。');
      }
      confirmDialog.openConfirm({
        title: '退款',
        templateUrl: '/view/dialog/vender-account-refund-dialog.html',
        confirmValue: {
          accountId: item._id,
          currentAmount: String(item.total)
        },
        confirmWhenResolve: (confirmValue, dialogData, confirmCb) => {
          if (confirmValue?.amount < 1 || !_.isInteger(confirmValue?.amount)) {
            $scope.alert('退款金額須為正整數');
            return;
          }

          if (confirmValue?.amount > confirmValue?.currentAmount) {
            $scope.alert('還款金額不可超過廠商繳款帳戶金額');
            return;
          }
          if (!confirmValue?.note) {
            $scope.alert('備註 必填');
            return;
          }
          if (confirmValue?.note?.length > 50) {
            $scope.alert('備註最多50字');
            return;
          }
          confirmCb(confirmValue);
        }
      }, 'custom-width-680').then(async (confirmValue) => {
        const results = await Account.one('refund').customPOST(confirmValue);
        if (results.error) {
          $scope.alert(results.error);
        } else {
          const index = _.findIndex($scope.items, item => item._id === results._id);
          $scope.items[index] = results.clone();
          $scope.success('退款完成');
        }
      });
    };
  }];
