export default {
  bindings: {
    reservation: '<',
    displayVisible: '<'
  },
  template: `
    <i ng-if="$ctrl.isServiceAdmin" 
      class="fas pull-right ml-15"
      tooltip-class="remove-triangle"
      ng-class="{'fa-bell': $ctrl.reservation.isSendNotify, 'fa-bell-slash': !$ctrl.reservation.isSendNotify, 'red-font': !$ctrl.reservation.isSendNotify}"
      ng-click="$ctrl.onClick($ctrl.reservation)"
      uib-dropdown-toggle uib-tooltip="訂單通知：{{$ctrl.reservation.isSendNotify ? '開啟' : '關閉'}}"
    />
  `,
  controller: ['$element', '$async', 'currentUser', 'ywEvent', 'confirmDialog', 'Reservation', 'UnorderedReservation', function reservationSinyiStaffInfo ($element, $async, currentUser, ywEvent, confirmDialog, Reservation, UnorderedReservation) {
    this.onClick = $async(async (reservation) => {
      const _resource = _.get(reservation, 'reservationNo') ? Reservation : UnorderedReservation;

      await confirmDialog.openConfirm({
        title: '不發送訂單變更通知',
        content: reservation.isSendNotify ? '關閉訂單通知？' : '開啟訂單通知？'
      });
      const res = await _resource
        .one(reservation._id)
        .one('stateNotification')
        .customPUT();
      if (res.error) {
        ywEvent.emit('ALERT', res.error);
      } else {
        reservation.isSendNotify = !reservation.isSendNotify;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        ywEvent.emit('SUCCESS', '已變更');
      }
    });

    this.$onInit = () => {
      this.isServiceAdmin = currentUser.hasRole('serviceAdmin');
    };
  }]
};
