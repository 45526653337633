export default ['User', function factory (User) {
  const util = {};

  function findCreatorById (id) {
    return User.one(id).obj.get().then(res => {
      return { name: _.get(res, 'obj.fullname'), id: id };
    });
  }

  util.findDiffCreator = function (toBuyOrderObj) {
    const creatorIds = _.uniq(_.map(toBuyOrderObj.diff, diff => diff.createdBy));
    Promise.all(creatorIds.map(findCreatorById))
      .then((nameAndIds) => {
        _.map(toBuyOrderObj.diff, diff => {
          diff.creator = _.find(nameAndIds, u => u.id === diff.createdBy).name;
        });
      });
    return toBuyOrderObj;
  };

  util.getOrderStatusList = function () {
    return [
      { value: 'established', label: '剛下單', className: 'green' },
      { value: 'await', label: '已接單', className: 'green' },
      { value: 'requestDelivery', label: '店已接', className: 'green' },
      { value: 'handling', label: '已下訂', className: 'green' },
      { value: 'finishProductionOfMeals', label: '餐點完成', className: 'green' },
      { value: 'shipping', label: '已取件', className: 'green' },
      { value: 'shipped', label: '已到件', className: 'green' },
      { value: 'refunding', label: '待退款', className: 'orange' },
      { value: 'refunded', label: '已退款', className: 'gray' },
      { value: 'cancelled', label: '取消', className: 'gray' },
      { value: 'error', label: '錯誤', className: 'gray' }
    ];
  };

  util.getGroupBuyStatusMap = function () {
    return {
      dismissed: { desc: '已解散', color: 'gray' },
      ended: { desc: '已結束(成團)', color: 'blue' },
      dead: { desc: '已截止', color: 'yellow' },
      recruiting: { desc: '揪團中', color: 'green' },
      unknown: { desc: '未知', color: 'red' }
    };
  };

  return util;
}];
