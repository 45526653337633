export default ['Restangular', 'confirmDialog',
  function directive (Restangular, confirmDialog) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        data: '=',
        systemSettingKey: '='
      },
      template: `
      <div>
        <div class="to-buy-tag-preview-block" style="display: inline-block;">
            <div ng-repeat="word in data" class="tag">
                {{word.content}}
            </div>
        </div>
        <button class="yw-btn yw-btn-blue" ng-click="openConfirm()" style="vertical-align: top;">設定</button>
      </div>
      `,
      link: link
    };

    function link (scope, element, attr) {
      scope.openConfirm = function () {
        const dialogData = {
          title: `設定${scope.systemSettingKey === 'customerCancelledDefaultWords' ? '客戶' : '後台'}取消訂單預選文字`,
          templateUrl: '/view/dialog/dragable-item-dialog-content.html',
          confirmLabel: '關閉',
          hideCancel: true,

          inputPlaceholder: '請輸入取消訂單預選文字',

          createNewOne: createNewOne,
          editConfirmed: editConfirmed,
          cancelEditing: cancelEditing,
          editClicked: editClicked,
          removeClicked: removeClicked,
          itemMoved: itemMoved,

          items: scope.data.map(genItem)
        };

        return confirmDialog.openConfirm(dialogData);
      };

      function genItem (word) {
        return { _state: 'preview', _title: word.content, entity: word.content };
      }

      function genWord (value) {
        return { content: value };
      }

      function createNewOne (dialogData, title) {
        if (_.isUndefined(title) || _.isEmpty(title)) { return; }
        Restangular.service('systemSettings').one(scope.systemSettingKey).customPUT({
          value: [genWord(title), ...scope.data]
        }).then(res => {
          scope.data = res.value;
          dialogData.items = scope.data.map(genItem);
          dialogData.state = 'previewing';
          dialogData.inputText = '';
        });
      }

      function editConfirmed (dialogData, idx) {
        const data = _.get(dialogData, `items[${idx}]`);
        if (!_.get(data, '_title')) { return; }

        // data.entity = data._title; // replace title field by input text
        Restangular.service('systemSettings').one(scope.systemSettingKey).customPUT({
          value: scope.data.map((word, index) => index === idx ? genWord(data._title) : word)
        }).then(res => {
          scope.data = res.value;
          dialogData.items[idx] = genItem(genWord(data._title));
          dialogData.state = 'previewing';
        });
      }

      function cancelEditing (dialogData, idx) {
        const data = _.get(dialogData, `items[${idx}]`);
        if (_.eq('edit', _.get(data, '_state'))) { dialogData.items[idx]._state = 'preview'; }
        dialogData.items[idx]._title = dialogData.items[idx].entity;
      }

      function editClicked (dialogData, idx) {
        // remove other edit state
        _.forEach(dialogData.items, (item, index) => {
          if (_.eq('edit', item._state) && !_.eq(index, idx)) { cancelEditing(dialogData, index); }
        });

        dialogData.state = 'editting';
        dialogData.items[idx]._state = 'edit';
        dialogData.items[idx]._title = dialogData.items[idx].entity;
      }

      function removeClicked (dialogData, idx) {
        confirmDialog.openConfirm({
          title: '確認刪除？'
        }).then(() => {
          return Restangular.service('systemSettings').one(scope.systemSettingKey).customPUT({
            value: scope.data.filter((word, index) => index !== idx)
          });
        }).then(() => {
          dialogData.items.splice(idx, 1);
          scope.data = dialogData.items.map(item => genWord(item.entity));
        });
      }

      function itemMoved (dialogData, idx) {
        console.log('event moved: ', idx);
        dialogData.items.splice(idx, 1); // remove from list

        // set order to server
        const data = _.map(dialogData.items, item => genWord(item.entity));
        Restangular.service('systemSettings').one(scope.systemSettingKey).customPUT({
          value: data
        }).then(res => {
          scope.data = res.value;
        });
      }
    }
  }];
