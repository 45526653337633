import moment from 'moment';

export default ['confirmDialog', 'FacilityReservation', 'ywEvent', 'TIME_ZONE_OFFSET', function (confirmDialog, FacilityReservation, ywEvent, TIME_ZONE_OFFSET) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      buttonTitle: '=',
      selectedTimes: '=',
      reserveTimeLength: '=',
      facilityId: '='
    },
    template: `
      <md-button class="md-raised md-primary" ng-click="openConfirm()">{{buttonTitle || '設定'}}</md-button>
      <span ng-bind="params.selectedTimesDisplay"></span>
    `,
    link
  };
  function link (scope) {
    const params = {
      templateUrl: '/view/directive/timepart-picker.html',
      title: '選擇預約時間',
      currentDate: new Date(),
      currentDateChange,
      reserveTimeLength: _.get(scope, 'reserveTimeLength', 0.5),
      reservedList: [],
      selectedTimes: scope.selectedTimes,
      selectedTimesDisplay: '',
      isLoading: false,
      onChange (data) {
        params.selectedTimes = data;
        if (!data[0]) {
          params.selectedTimesDisplay = '';
        } else {
          params.selectedTimesDisplay = '';
          params.selectedTimesDisplay += _.first(data).utcOffset(TIME_ZONE_OFFSET).format('YYYY/MM/DD HH:mm');
          params.selectedTimesDisplay += ' ~ ';
          params.selectedTimesDisplay += _.last(data).clone().add(30, 'minutes').utcOffset(TIME_ZONE_OFFSET).format('HH:mm');
        }
      }
    };
    scope.params = params;

    (function initSelectedTimesDisplay () {
      if (_.isEmpty(scope.selectedTimes)) return;
      params.onChange(scope.selectedTimes.map(time => moment(time)));
    })();

    async function currentDateChange (date) {
      params.reservedList = await getReservedList(date);
      scope.$apply();
    }

    async function getReservedList (date) {
      params.isLoading = true;
      const reservedList = [];
      const [dateStart, dateEnd] = [
        moment(date).subtract(3, 'days').startOf('day').utc().format(),
        moment(date).add(3, 'days').endOf('day').utc().format()
      ];
      try {
        const list = await FacilityReservation.one(scope.facilityId).obj.one('status').get({ start: dateStart, end: dateEnd });
        if (list.errorMessage) throw new Error(list.errorMessage);
        reservedList.push(...list.status);
      } catch (error) {
        console.log(error);
        ywEvent.emit('ALERT', error);
      } finally {
        params.isLoading = false;
      }
      return reservedList;
    }

    async function updateReservedList () {
      if (!_.isEmpty(scope.selectedTimes)) params.currentDate = scope.selectedTimes[0];
      params.reservedList = await getReservedList(params.currentDate);
      scope.$apply();
    }

    scope.openConfirm = async () => {
      updateReservedList();
      await confirmDialog.openConfirm(params, 'custom-width-1024');
      scope.selectedTimes = params.selectedTimes.map(time => moment(time).utc().format());
    };
  }
}];
