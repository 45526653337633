export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <div uib-dropdown class="pull-right">
      <img tooltip-class="remove-triangle" src="/img/ic-sinyi-member.svg" style="padding: 0;" uib-dropdown-toggle uib-tooltip="編輯信義員工資訊"/>
      <ul uib-dropdown-menu class="dropdown-menu dropdown-menu-right">
        <li>
          <a ng-click="$ctrl.modifySinyiStaffInfo($ctrl.reservation)">修改信義員工資訊</a>
        </li>
        <li ng-if="$ctrl.reservation.staffInfo.empId">
          <a ng-click="$ctrl.removeSinyiStaffInfo($ctrl.reservation)">移除信義員工資訊</a>
        </li>
      </ul>
    </div>
  `,
  controller: ['$element', '$async', 'sinyiStaffInfoUtil', function reservationEditIcon ($element, $async, sinyiStaffInfoUtil) {
    this.$onInit = () => {
    };

    this.modifySinyiStaffInfo = $async((reservation) => sinyiStaffInfoUtil.modifySinyiStaffInfo({ reservation }));
    this.removeSinyiStaffInfo = $async((reservation) => sinyiStaffInfoUtil.removeSinyiStaffInfo({ reservation }));
  }]
};
