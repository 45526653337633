export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <md-button class="md-raised md-primary"
      ng-bind="'出帳轉介費資訊'"
      ng-click="$ctrl.openReferralInfoDialog($ctrl.reservation)"
    ></md-button>
  `,
  controller: ['$async', 'currentUser', 'Reservation', 'UnorderedReservation', 'confirmDialog', 'ywEvent',
    function reservationReferralFeeUpdateButton ($async, currentUser, Reservation, UnorderedReservation, confirmDialog, ywEvent) {
      this.$onInit = () => {
      };

      this.openReferralInfoDialog = $async(async (reservation) => {
        let referralFeeDetail;
        const reservationType = _.get(reservation, 'reservationNo') ? 'reservation' : 'unOrderedReservation';
        if (reservationType === 'unOrderedReservation') {
          referralFeeDetail = await UnorderedReservation.one(reservation._id).customGET('totalReferralFeeProperty');
        } else {
          referralFeeDetail = await Reservation.one(reservation._id).customGET('totalReferralFeeProperty');
        }

        if (referralFeeDetail.error) return ywEvent.emit('ALERT', '出帳轉介費資訊取得失敗');

        const dialogData = {
          title: '出帳轉介費資訊',
          templateUrl: '/view/dialog/referral-fee-info.html',
          totalReferralFee: referralFeeDetail?.totalReferralFee?.total,
          referralFeeBills: referralFeeDetail?.totalReferralFee?.billsRecord,
          hideCancel: true,
          confirmValue: { }
        };
        await confirmDialog.openConfirm(dialogData, 'custom-width-680');
      });
    }]
};
