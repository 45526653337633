export default ['$scope', '$stateParams', '$async', 'ywUtil', 'currentUser', 'Vender', 'Account', 'User', 'Restangular', 'confirmDialog',
  function controller ($scope, $stateParams, $async, ywUtil, currentUser, Vender, Account, User, Restangular, confirmDialog) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.cellActions = {
      sendNewPassword: $async(async (item) => {
        await confirmDialog.openConfirm({
          title: '寄送新密碼',
          htmlContent: `
            確定發送密碼以下位置?<br/>
            ${item.phone ? '手機：' + item.phone + '<br/>' : ''}
            ${item.email ? 'email：' + item.email : ''}
          `
        });
        User.one(item._id).one('password').one('reset').customPOST({
          origin: 'lsWeb'
        }).then(res => {
          if (res.error) {
            $scope.alert(res.error);
          } else {
            $scope.success('新密碼已發送');
          }
        });
      })
    };

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'vender-account'; // for GA

      $scope.mainAction.initResource(
        Restangular.service('accounts', Vender.one($stateParams.venderId))
      );
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
      };
    }
  }];
