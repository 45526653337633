/*
  example:
  <life-input placeholder="'placeholder'" value="inputValue"></life-input>
*/

export default {
  bindings: {
    placeholder: '<', // string
    value: '=', // string, 必填
    disabled: '<', // boolean
    onKeyup: '&', // function, 參數 keyName: event
    onFocus: '&', // function, 參數 keyName: event
    onClick: '&' // function, 參數 keyName: event
  },
  template: `
    <div class="life-input normal-input">
      <input
        style="padding: 8px 48px 8px 16px;"
        type="text"
        ng-model="$ctrl.value"
        ng-disabled="$ctrl.disabled"
        ng-attr-placeholder="{{$ctrl.placeholder}}"
        ng-focus="$ctrl.handleKeyup($event)"
        ng-focus="$ctrl.handleFocus($event)"
        ng-click="$ctrl.handleClick($event)"
      />
      <img ng-if="$ctrl.value && !$ctrl.disabled" src="/img/x-circle.svg" ng-click="$ctrl.onClearClick($event)" />
    </div>
  `,
  controller: ['$element',
    function inputController ($element) {
      this.onClearClick = (event) => {
        this.value = '';
        event.stopPropagation();
      };
      this.handleKeyup = (event) => {
        this.onKeyup({ event });
      };
      this.handleFocus = (event) => {
        this.onFocus({ event });
      };
      this.handleClick = (event) => {
        this.onClick({ event });
      };
    }
  ]
};
