export default ['Restangular', 'currentUser', 'confirmDialog', 'ywEvent', 'ywTimezone', '$filter', function factory (Restangular, currentUser, confirmDialog, ywEvent, ywTimezone, $filter) {
  Restangular.addElementTransformer('bills', false, _transformer);
  const Bill = Restangular.service('bills');
  return Bill;

  function updateNote (bill) {
    confirmDialog.openConfirm({
      title: bill.type === 'refundment' ? '編輯退款備註' : '編輯收款備註',
      templateUrl: '/view/dialog/single-textarea.html',
      inputLabel: bill.type === 'refundment' ? '退款備註*' : '收款備註*',
      maxlength: 200,
      confirmValue: bill?.note,
      confirmWhenResolve: (confirmValue, dialogData, confirmCb) => {
        if (!confirmValue) {
          ywEvent.emit('ALERT', bill.type === 'refundment' ? '退款備註 必填' : '收款備註 必填');
          return;
        }
        confirmCb(confirmValue);
      }
    }, 'custom-width-500').then(async (confirmValue) => {
      const results = await Bill.one(bill._id).one('note').customPUT({
        note: confirmValue
      });
      if (results.error) {
        ywEvent.emit('ALERT', results.error);
      } else {
        ywEvent.emit('REFRESH_LIST', Bill);
        ywEvent.emit('SUCCESS', '更新成功');
      }
    });
  }

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number' || typeof item === 'string') return item; // count

    const processedLog = _.find(item?.stateLogs, (obj) => { return obj.state === 'processed'; });

    item._columns = [
      item?.vender?.name,
      {
        referralFeeInvoice: '轉介費帳單',
        serviceInvoice: '收款單',
        refundment: '退款單',
        couponInvoice: '優惠券請款單'
      }[item?.type],
      item?.title,
      item?.no,

      item?.total?.toString()
        .split('')
        .reverse()
        .reduce((result, num, index) => result + (index && index % 3 === 0 && num !== '-' ? ',' : '') + num, '')
        .split('')
        .reverse()
        .join(''),
      item?.checkingInfo?.state === 'success'
        ? (
            item?.state === 'processed'
              ? {
                  anchorType: 'content',
                  content: `已處理\n${$filter('date')(item?.stateTimes?.processed, 'yyyy/MM/dd HH:mm:ss', ywTimezone.get())}`,
                  contentPopover: item.note,
                  icon: currentUser.hasRole('serviceAdmin') && processedLog?.note === 'otherMethod' ? 'edit' : '',
                  clickAction: updateNote
                }
              : {
                  anchorType: 'button',
                  info: {
                    established: '尚未處理',
                    invalid: '作廢'
                  }[item?.state],
                  hideBtn: !currentUser.hasRole('serviceAdmin') || (item?.state !== 'established' && item?.state !== 'processed'),
                  label: item?.state === 'established'
                    ? {
                        referralFeeInvoice: '繳款',
                        serviceInvoice: '繳款',
                        refundment: '退款'
                      }[item?.type]
                    : ''
                }
          )
        : {
            anchorType: 'content',
            content: '異常',
            contentPopover: '單據資料經系統驗證內容可能有異常，請待開發團隊確認後處理'
          },
      { anchorType: 'icon-button', icon: 'file-alt', name: 'openDetail' },
      item?.state === 'invalid'
        ? ''
        : {
            anchorType: 'menu-buttons',
            btnIconClass: 'glyphicon glyphicon-download',
            btnList: [
              { name: 'downloadCsvMAC', label: '單據明細資料', class: 'fab fa-apple' },
              { name: 'downloadCsvWindows', label: '單據明細資料', class: 'fab fa-windows' }
            ]
          }
    ];

    if (currentUser.hasRole('serviceAdmin')) {
      item._columns.splice(0, 0, item?.state === 'invalid' ? '' : { anchorType: 'checkbox' });
      if (['referralFeeInvoice', 'serviceInvoice'].indexOf(item?.type) > -1 && ['processed', 'invalid'].indexOf(item?.state) < 0) {
        item._columns.push(
          ['referralFeeInvoice'].indexOf(item?.type) > -1
            ? { anchorType: 'icon-button', icon: 'bell', tips: '轉介費繳款通知，將會通知廠商繳付所有尚未繳款之金額。', name: 'notifyPayment' }
            : { anchorType: 'icon-button', icon: 'bell', name: 'notifyPayment' }
        );
      } else {
        item._columns.push('');
      }
    }

    if (item.state === 'invalid') {
      item._columns._deleted = true;
    }

    return item;
  }
}];
