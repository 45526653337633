// this directive can be migrate to yw-popup-selection... in the future!!

export default ['$timeout', '$async', 'ngDialog', function directive ($timeout, $async, ngDialog) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      options: '=ywOptions',
      label: '=ywLabel',
      changed: '&ywOnChange',
      fetchOptions: '&',
      fetchOptionsAfterClick: '=',
      selectedList: '=ywSelectedList',
      disabled: '=ywDisabled',
      searchable: '=ywSearchable',
      byCategory: '=ywByCategory',
      hideCategory: '=ywHideCategory',
      activeStyle: '=',
      clicked: '&ywOnClick'
    },
    template: `
      <div
        class="select-wrapper wrapper"
        ng-class="{disabled: disabled, 'active-control': (selected !== undefined || selectedList.length > 0) && activeStyle}"
        ng-click="disabled || onClick()"
      >
        <a class="select" ng-disabled="disabled">
          <span uib-tooltip="{{displayedLabel}}" tooltip-enable="selectedList.length">
            <div ng-if="!selectedList.length">{{label}}</div>
            <div
              ng-if="selectedList.length"
              ng-repeat="color in displayedColors track by $index"
              style="display: inline-block;"
            >
              <span ng-if="$index > 0" style="margin: 0 0 0 3px;">,</span>
              <span class="color-label-icon" style="background-color: {{color}}"></span>
            </div>
          </span>
          <span class="caret"></span>
        </a>
      </div>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.onClick = $async(async function () {
      if (scope.clicked) {
        await scope.clicked();
      }
      scope.openPopupMenu();
    });

    scope.openPopupMenu = async () => {
      const options = scope.fetchOptionsAfterClick === true ? buildOptions(await scope.fetchOptions()) : buildOptions(_.get(scope, 'options', []));
      const clonedSelectedList = _.clone(_.get(scope, 'selectedList', []));

      const dialogData = {
        title: _.get(scope, 'label', '請選擇'),
        options: options,
        selectedList: clonedSelectedList,
        showFooter: true,
        itemClicked: itemClicked,
        confirmClicked: confirmClicked
      };
      const params = {
        template: '/view/dialog/color-selection.html',
        showClose: false,
        className: 'ngdialog-theme-default yw-plain-dialog',
        closeByEscape: true,
        closeByDocument: true,
        data: dialogData
      };

      function itemClicked (value) {
        if (_.includes(dialogData.selectedList, value)) { _.pull(dialogData.selectedList, value); } else { dialogData.selectedList.push(value); }
      }

      function confirmClicked (confirm, closeThisDialog) {
        confirm(dialogData.selectedList);
      }

      ngDialog.openConfirm(params).then(selectedList => {
        scope.selectedList = selectedList;
      });
    };

    // returned option format => { label: String, value: String, category: String(Optional) }
    function buildOptions (opts) {
      if (_.isArray(opts) && _.isEmpty(opts)) return [];
      if (_.isArray(opts) && _.isString(_.head(opts))) { return _.map(opts, opt => Object.assign({}, { label: opt, value: opt })); }
      return opts.map(opt => Object.assign({}, opt, { colorStyle: { 'background-color': opt.color } }));
    }

    function setSelectedInfo (options, selectedList) {
      if (_.isEmpty(selectedList)) {
        scope.displayedLabel = _.get(scope, 'label', '請選擇');
        scope.displayedColors = [];
      } else {
        const selectedItems = _.map(selectedList, item => _.find(options, opt => _.eq(item, opt.value)));
        const selectedLabelList = _.map(selectedItems, item => _.get(item, 'label'));
        scope.displayedLabel = _.join(selectedLabelList, ', ');
        scope.displayedColors = _.map(selectedItems, item => item.color);
      }
    }

    setSelectedInfo(buildOptions(_.get(scope, 'options', [])), _.get(scope, 'selectedList', []));

    scope.$watch('selectedList', (newValue, oldValue) => {
      if (_.isEqual(newValue, oldValue)) return;
      setSelectedInfo(buildOptions(scope.options), scope.selectedList);

      $timeout(() => {
        scope.$apply();
        if (scope.changed) { scope.changed(); }
      });
    });

    scope.$watch('options', (newValue, oldValue) => {
      if (_.isEqual(newValue, oldValue)) return;
      setSelectedInfo(buildOptions(scope.options), scope.selectedList);

      $timeout(() => {
        scope.$apply();
        if (scope.changed) { scope.changed(); }
      });
    });
  }
}];
