/*
  example:
  <life-button size="'md'" type="'raised'" color="'primary'" label="'Confirm'"></life-button>
  <life-button size="'md'" type="'flat'" color="'primary'" label="'Confirm'"></life-button>
  <life-button size="'sm'" type="'outline'" color="'neutral'" label="'Confirm'"></life-button>
*/

export default {
  bindings: {
    size: '<', // string, 必填, sm / md
    type: '<', // string, 必填, raised / flat / outline
    color: '<', // string, 必填, primary / neutral / warning
    disabled: '<', // boolean
    label: '<',
    isFullWidth: '<' // string, 必填, 按鈕文字
  },
  template: `
    <md-button
      ng-attr-class="life-button life-button-{{$ctrl.size}} life-button-{{$ctrl.color}} life-button-{{$ctrl.type}} {{$ctrl.isFullWidth ? 'full-width' : ''}}"    
      ng-disabled="$ctrl.disabled">
      {{$ctrl.label}}
    </md-button>
  `
};
