import { removeInnerProperties } from './../share';

export default ['RestangularProvider', function (RestangularProvider) {
  RestangularProvider.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
    if (response.status === 401 || _.get(data, 'error.code') === 401) {
      location.pathname = '/login'; /* Unauthrized */
      return;
    }

    if (_.get(data, 'errorMessage') === 'SESSION_FAIL') {
      return { error: '登入逾時，請按右側登出鈕重新登入！！' };
    } else if (!data) {
      return { error: '' };
    } else if (_.isNil(data.result) && data.error) {
      return { error: data.msg, errorSource: data.error };
    }
    return _.get(data, 'result.list') || data.result;
  });

  // common params
  RestangularProvider.addFullRequestInterceptor(function (data, operation, what, url, headers, params) {
    return {
      element: removeInnerProperties(data),
      params: removeInnerProperties(params),
      headers: headers
    };
  });

  RestangularProvider.setDefaultHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

  RestangularProvider.setRestangularFields({ id: '_id' });
}];
