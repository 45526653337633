const displayAtMap = {
  homepage: '首頁',
  articlepage: '文章頁面',
  articleIdleReminder: '文章閒置提醒',
  articleListAdvertisement: '文章列表廣告'
};

export default ['$filter', 'Restangular', 'ywTimezone', function factory ($filter, Restangular, ywTimezone) {
  Restangular.addElementTransformer('banners', false, _transformer);
  const Banner = Restangular.service('banners');
  return Banner;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'order'),
      _.get(item, 'isVisible') ? '是' : '否',
      _.get(item, 'name'),
      _.get(item, 'visibleSources', [])
        .sort((a, b) => {
          const priority = (src) => {
            if (src === 'unlimited') return 3;
            if (src === 'lsApp') return 2;
            if (src === 'lsWeb') return 1;
            return 0;
          };
          return priority(b) - priority(a);
        })
        .map(src => {
          if (src === 'unlimited') return '不限定通路';
          if (src === 'lsApp') return '信義居家app';
          if (src === 'lsWeb') return '信義居家網頁';
          return src;
        })
        .join(','),
      _.get(item, 'displayInfo', []).map(info => displayAtMap[info.at]).join(','),
      $filter('date')(_.get(item, 'visibility.period.from'), 'yyyy/MM/dd', ywTimezone.get()) +
        '~' +
        $filter('date')(_.get(item, 'visibility.period.to'), 'yyyy/MM/dd', ywTimezone.get()),
      { anchorType: 'banner-locations' },
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
