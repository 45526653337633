import moment from 'moment';

const categoryMap = {
  message: '純訊息',
  acquireCoupon: '歸戶優惠券'
};

const roleMap = {
  serviceAdmin: '居家服務員',
  vender: '廠商'
};

const getMethods = (notificationInfo = {}) => {
  return [
    { key: 'isAppPub', name: 'app推播' },
    { key: 'isEmail', name: 'email' },
    { key: 'isSms', name: '簡訊' }
  ].filter(method => notificationInfo[method.key])
    .map(method => method.name)
    .join(',');
};

const getTimeExpired = time => {
  return !moment(time).isAfter(moment());
};

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('notificationGroups', false, _transformer);
  const NotificationGroup = Restangular.service('notificationGroups');
  return NotificationGroup;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count
    const isTimeExpired = getTimeExpired(item.time || item.createdAt);
    const canTermination = typeof _.get(item, 'canTermination') !== 'undefined' ? _.get(item, 'canTermination') : true;

    item._columns = [
      {
        anchorType: 'notification-stop',
        isActive: !!canTermination && !isTimeExpired,
        isStoped: !canTermination && !isTimeExpired
      },
      moment(item.time || item.createdAt).format('YYYY/MM/DD HH:mm'),
      categoryMap[item.category] || '',
      getMethods(item.notificationInfo),
      { anchorType: 'notification-content' },
      _.get(item, 'count.total'),
      roleMap[_.get(item, 'creator.role')] + '-' + _.get(item, 'creator.fullname'),
      _.get(item, 'note'),
      { anchorType: 'notification-detail' }
    ];

    return item;
  }
}];
