export default ['confirmDialog', 'ywEvent', function factory (confirmDialog, ywEvent) {
  const openConfirm = (props) => {
    const uploadDialogData = {
      title: props.title || '上傳圖片',
      templateUrl: '/view/dialog/upload-images.html',
      confirmValue: [],
      _files: [],
      disableConfirm: true,
      success: (file) => {
        uploadDialogData.confirmValue.push(file);
        uploadDialogData.disableConfirm = !uploadDialogData.confirmValue.length;
      },
      removeFile: (index) => {
        uploadDialogData.confirmValue.splice(index, 1);
        uploadDialogData.disableConfirm = !uploadDialogData.confirmValue.length;
      },
      error: (err) => ywEvent.emit('ALERT', err)
    };
    return confirmDialog.openConfirm(uploadDialogData);
  };

  return { openConfirm };
}];
