export default ['$scope', '$async', 'ywUtil', 'LayoutModule', 'layoutModuleUtils', 'localStorageService',
  function controller ($scope, $async, ywUtil, LayoutModule, layoutModuleUtils, localStorageService) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;
    $scope.moduleTypes = [];
    $scope.moduleTypesItems = [];
    $scope.dropdownItemList = [];

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      layoutModuleUtils.getLayoutModuleTypes($scope);

      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '模組名稱', placeholder: '請輸入模組名稱', params: 'name' },
          { btnName: '使用頁面', placeholder: '請輸入頁面名稱', params: 'layoutName' }
        ]
      );

      $scope.filterItems = [
        {
          name: 'type',
          label: '模組類型',
          options: [],
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'multiFilter',
          click: $async(async (item) => {
            item.options = $scope.moduleTypes || [];
          })
        },
        {
          name: 'isVisible',
          label: '顯示狀態',
          options: { true: '顯示', false: '隱藏' },
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        }
      ];

      $scope.dropdownItemList = [
        {
          label: '新增',
          items: $scope.moduleTypesItems
        }
      ];

      $scope.queryPage = ''; // for GA

      const module = JSON.parse(localStorageService.get('module'));
      let moduleId;
      let moduleType;
      if (module) {
        moduleId = module?.id;
        moduleType = module?.type;
      }
      localStorageService.remove('module');

      $scope.mainAction.initResource(LayoutModule, { moduleId }).then(() => {
        if (moduleId) $scope.changePageState('edit', _.head($scope.items), moduleType);
      });
    });

    init();

    function initActionItem (label, type) {
      $scope.currentActionItem = {
        isVisible: true,
        label,
        type,
        style: 'none'
      };
    }
  }];
