import moment from 'moment';

export default ['$timeout', '$filter', '$sce', 'ywTimezone', 'ywUtil', 'Restangular', 'Service', 'ServiceClassification', function directive ($timeout, $filter, $sce, ywTimezone, ywUtil, Restangular, Service, ServiceClassification) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      coupon: '=',
      couponEvent: '=',
      iconOnly: '=',
      popoverPlacement: '=',
      popoverAlwaysOpen: '='
    },
    template: '<span uib-popover-html="popoverStr" popover-trigger="popoverAlwaysOpen ? \'none\' : \'mouseenter\'" popover-placement="{{_popoverPlacement}}" popover-animation="false" popover-is-open="_isOpen" }">' +
      '<span class="fa fa-info-circle mr-5"></span>' +
      '<span ng-if="!iconOnly" ng-bind="createdAt"></span>' +
      '</span>',
    link: link
  };

  function link (scope, element, attr) {
    function initCouponEventDetail (coupon) {
      let popoverStr = '';

      if (_.get(coupon, 'sourceRestrictions.length')) {
        popoverStr += `
          <div><b>可使用對象：</b>${coupon.sourceRestrictions.join('、')}</div>
        `;
      }

      if (_.get(coupon, 'available.maxUserNum')) {
        popoverStr += `
          <div><b>總可用次數：</b>${coupon.available.maxUserNum}</div>
        `;
      }

      if (_.get(coupon, 'available.max')) {
        popoverStr += `
          <div><b>每人限用次數：</b>${coupon.available.max}</div>
        `;
      }

      let discountValue = _.get(coupon, 'discountValue', 0);
      const valueType = _.get(coupon, 'discountValueType');
      if (valueType === 'percentage') {
        if (discountValue < 10) discountValue = (discountValue / 10) + '折';
        if (discountValue >= 10)discountValue = (discountValue % 10 === 0 ? discountValue / 10 : discountValue) + '折';
      }
      popoverStr += `<div><b>折扣金額：</b>${discountValue}</div>`;

      popoverStr += `<div><b>可與方案優惠併用：</b>${coupon.isCompatibleWithServiceDiscount ? '是' : '否'}</div>`;

      popoverStr += `<div><b>可抵用客製化項目：</b>${coupon.isCompatibleWithCustomizations ? '是' : '否'}</div>`;

      if (coupon.conditionValue) {
        popoverStr += `<div><b>使用低消金額：</b>${coupon.conditionValue}</div>`;
      }

      const effectiveDate = _.get(coupon, 'effectiveDate.begin')
        ? moment(coupon.effectiveDate.begin).format('YYYY/MM/DD') +
          '~' +
          moment(coupon.effectiveDate.end).format('YYYY/MM/DD')
        : '';
      popoverStr += `<div><b>活動期間：</b>${effectiveDate}</div>`;

      const dateConditions = [];
      if (_.get(coupon, 'effectiveDate.isIncludeNormalDay')) {
        dateConditions.push('平日');
      }
      if (_.get(coupon, 'effectiveDate.isIncludeHoliday')) {
        dateConditions.push('國定假日');
      }
      popoverStr += `<div><b>期間條件：</b>${dateConditions.join('、')}</div>`;

      if (coupon.effectiveDaysLimit) {
        popoverStr += `<div><b>歸戶限定使用天數：</b>${coupon.effectiveDaysLimit}</div>`;
      }

      const isFirstOrder = _.get(coupon, 'isFirstOrder') ? '是' : '否';
      popoverStr += `<div><b>限首單使用：</b>${isFirstOrder}</div>`;

      const notifications = [
        { label: 'app推播', value: 'isAppPub' },
        { label: 'email', value: 'isEmail' },
        { label: '簡訊', value: 'isSms' }
      ].filter(notification => _.get(coupon, `notification.info[${notification.value}]`))
        .map(notification => notification.label)
        .join('、');
      popoverStr += `<div><b>到期通知方式：</b>${notifications || '無'}</div>`;

      if (_.get(coupon, 'venderName')) {
        popoverStr += `<div><b>異業合作名稱：</b>${coupon.venderName}</div>`;
      }
      popoverStr += `<div><b>信義用：</b>${coupon.isForSinyiUsed ? '是' : '否'}</div>`;

      scope.popoverStr = $sce.trustAsHtml(popoverStr);

      function generateTargetClassificationsStr () {
        return new Promise((resolve, reject) => {
          if (_.get(coupon, 'targetClassifications.length')) {
            // 若拿得到第一個分類的名稱，則表示為陣列物件，而非陣列ID
            if (_.get(coupon, 'targetClassifications[0].name')) {
              const nameList = coupon.targetClassifications.map(serviceClassification => serviceClassification.name).join('、');
              popoverStr += `<div><b>限定分類：</b>${nameList}</div>`;
              scope.popoverStr = $sce.trustAsHtml(popoverStr);
              resolve();
            } else {
              Promise.all(coupon.targetClassifications.map(id => ServiceClassification.one(id).customGET()))
                .then(serviceClassifications => {
                  const nameList = serviceClassifications.map(serviceClassification => serviceClassification.name).join('、');
                  popoverStr += `<div><b>限定分類：</b>${nameList}</div>`;
                  scope.popoverStr = $sce.trustAsHtml(popoverStr);
                  resolve();
                });
            }
          } else {
            resolve();
          }
        });
      }

      function generateTargetServicesStr () {
        return new Promise((resolve, reject) => {
          if (_.get(coupon, 'targetServices.length')) {
            // 若拿得到第一個分類的名稱，則表示為陣列物件，而非陣列ID
            if (_.get(coupon, 'targetServices[0].name')) {
              const nameList = coupon.targetServices.map(service => service.name).join('、');
              popoverStr += `<div><b>限定方案：</b>${nameList}</div>`;
              scope.popoverStr = $sce.trustAsHtml(popoverStr);
              resolve();
            } else {
              Promise.all(coupon.targetServices.map(id => Service.one(id).customGET()))
                .then(services => {
                  const nameList = services.map(service => service.name).join('、');
                  popoverStr += `<div><b>限定方案：</b>${nameList}</div>`;
                  scope.popoverStr = $sce.trustAsHtml(popoverStr);
                  resolve();
                });
            }
          } else {
            resolve();
          }
        });
      }

      generateTargetClassificationsStr().then(generateTargetServicesStr);
    }

    scope._popoverPlacement = scope.popoverPlacement || 'left';
    scope._isOpen = !!scope.popoverAlwaysOpen;

    if (scope.coupon) {
      initCouponEventDetail(scope.coupon);
    } else if (scope.couponEvent) {
      initCouponEventDetailByCouponEvent(scope.couponEvent);
    }

    function initCouponEventDetailByCouponEvent (couponEvent) {
      initCouponEventDetail(scope.couponEvent.couponDefault);
    }

    scope.$watch('coupon', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      initCouponEventDetail(scope.coupon);
    });
    scope.$watch('couponEvent', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      initCouponEventDetailByCouponEvent();
    });

    scope.$watch('order', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      initCouponEventDetail(scope.coupon);
    });
  }
}];
