import moment from 'moment';

export default ['$scope', '$async', 'ywUtil', 'AccountTransactionDetail', '$stateParams',
  function controller ($scope, $async, ywUtil, AccountTransactionDetail, $stateParams) {
    $scope.filterItems = [];
    $scope.tableHead = [];

    $scope.filterItems = [
      {
        name: 'types',
        label: '交易內容',
        options: [
          { value: 'transferReceived', label: '匯款入帳' },
          { value: 'billPayment', label: '帳單繳款' },
          { value: 'accountRefund', label: '帳戶退款' },
          { value: 'remittanceRefund', label: '匯款退匯' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'multiFilter'
      }
    ];

    $scope.searchBarOptions = [
      {
        btnName: '公司名稱',
        placeholder: '請輸入公司名稱',
        params: 'venderName'
      },
      {
        btnName: '公司統編',
        placeholder: '請輸入公司統編',
        params: 'vatNo'
      }
    ];
    if ($stateParams?.vatNo) {
      $scope.searchBarOptionsActive = $scope.searchBarOptions[1];
      $scope.mainAction.customSearchText = $stateParams?.vatNo;
    } else {
      $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);
    }

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    $scope.customizeContentTemplate = '/view/template/bill-filter.html';
    $scope.extraFilter = {
      sort: {
        selected: '-transactionTime',
        options: _.flatten([
          ['transactionTime', '交易時間']
        ].map(([field, name]) => [
          { value: `-${field}`, label: `${name} 新-舊` },
          { value: `${field}`, label: `${name} 舊-新` }
        ]))
      },
      durationType: {
        options: [
          { value: 'transactionTime', label: '依照交易時間' }
        ],
        selected: 'transactionTime'
      },
      startDate: {
        value: moment().subtract(180, 'day').startOf('day').toDate(),
        changed: (date) => {
          $scope.extraFilter.startDate.value = moment(date).startOf('day').toDate();
        }
      },
      dueDate: {
        value: moment().endOf('day').toDate(),
        changed: (date) => {
          $scope.extraFilter.dueDate.value = moment(date).endOf('day').toDate();
        }
      }
    };
    const resetExtraParams = () => {
      $scope.extraFilter.sort.selected = '-transactionTime';
      $scope.extraFilter.durationType.selected = 'transactionTime';
      $scope.extraFilter.startDate.value = moment().subtract(180, 'day').startOf('day').toDate();
      $scope.extraFilter.dueDate.value = moment().endOf('day').toDate();
    };

    $scope.customClearFilter = () => {
      resetExtraParams();
      applyInitialConditions();
      $scope.mainAction.clearFilter(['sort', 'transactionFrom', 'transactionTo'], $scope.searchBar.textInput);
    };
    $scope.customFilterQuery = () => {
      $scope.mainAction.queryParams.sort = $scope.extraFilter.sort.selected;
      const startDate = $scope.extraFilter.startDate.value;
      const dueDate = $scope.extraFilter.dueDate.value;
      $scope.mainAction.queryParams.transactionFrom = startDate && moment($scope.extraFilter.startDate.value).startOf('day').toDate();
      $scope.mainAction.queryParams.transactionTo = dueDate && moment($scope.extraFilter.dueDate.value).endOf('day').toDate();

      $scope.mainAction.filterQuery();
    };

    const applyInitialConditions = () => {
      $scope.mainAction.queryParams.sort = $scope.extraFilter.sort.selected;
      $scope.mainAction.queryParams.transactionFrom = $scope.extraFilter.startDate.value;
      $scope.mainAction.queryParams.transactionTo = $scope.extraFilter.dueDate.value;
    };

    // 在 query 之前，放入預設條件
    applyInitialConditions();

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.searchBar.hideSearchAndClearBtn = true;
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'accountTransactionDetail'; // for GA

      $scope.mainAction.initResource(AccountTransactionDetail, { vatNo: $stateParams?.vatNo });
      if ($stateParams?.vatNo) {
        $scope.mainAction.showClearFilter = true;
      }
    });

    init();
  }];
