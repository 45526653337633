import { generateTableSettings, getOrigin } from './../../../share';
import moment from 'moment';

export default ['$scope', '$async', 'Upload', 'address', 'confirmDialog', 'ywUtil', 'User', 'UserGroup',
  function controller ($scope, $async, Upload, address, confirmDialog, ywUtil, User, UserGroup) {
    const self = this;
    $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.avatarFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);

      if (!item.fullname) return $scope.alert('名稱為必填');

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      console.log('before transform: ', item);

      item.address.details = item._addressDetails;

      item.deniedSetting = {
        isExcludeAutoDispatch: $scope.deniedSettingCheckboxes
          .find(checkbox => checkbox.index === '_deniedExcludeAutoDispatch').checked,
        isOnlyCardPayment: $scope.deniedSettingCheckboxes
          .find(checkbox => checkbox.index === '_deniedOnlyCardPayment').checked,
        isForbidLogin: $scope.deniedSettingCheckboxes
          .find(checkbox => checkbox.index === '_deniedForbidLogin').checked,
        reason: item._denidReason || ''
      };

      item.setting = {
        isSmsMarketing: $scope.settingCheckboxes
          .find(checkbox => checkbox.index === '_settingSmsMarketing').checked,
        isEmailMarketing: $scope.settingCheckboxes
          .find(checkbox => checkbox.index === '_settingEmailMarketing').checked,
        isPushMarketing: $scope.settingCheckboxes
          .find(checkbox => checkbox.index === '_settingPushMarketing').checked
      };

      // Change to UTC time from local date
      if (item._birthday) item.birthday = new Date(Date.UTC(item._birthday.getFullYear(), item._birthday.getMonth(), item._birthday.getDate()));
    }

    const syncCurrentActionItem = $async(async () => {
      const item = $scope.currentActionItem;

      // Change to local time from UTC date
      if (!_.isEmpty(item.birthday)) item._birthday = moment(item.birthday.split('T')[0]).toDate();

      if ($scope.currentPageState === 'edit') {
        item.address = {
          county: _.get(item, 'contactAddress.county'),
          district: _.get(item, 'contactAddress.district')
        };
        item._addressDetails = _.get(item, 'contactAddress.details', '');
        item._denidReason = _.get(item, 'deniedSetting.reason');

        item._dealCustomerInfo = _.get(item, 'syMember.dealCustomerInfo', [])
          .sort((a, b) => moment(new Date(b.date)).diff(moment(new Date(a.date))));

        item._userGroups = _.get(item, 'userGroups', []).map(group => group.name).join('，');
        item.userGroups = _.get(item, 'userGroups', []).map(group => group._id);
      }

      /* address settings */
      $scope.districtInput = {
        label: '地區',
        type: 'dropdown',
        selected: _.get(item, 'contactAddress.district'),
        options: [],
        changed: (value) => {
          _.set(item, 'address.district', value || '');
        }
      };
      $scope.syncDistrictOptions = function (county) {
        $scope.districtInput.options = address[county];
      };
      if (_.hasIn($scope, 'currentActionItem.address.county')) { $scope.syncDistrictOptions($scope.currentActionItem.address.county); }

      $scope.deniedSettingCheckboxes = [
        {
          value: false,
          label: '訂單不媒合廠商',
          index: '_deniedExcludeAutoDispatch',
          checked: _.get(item, 'deniedSetting.isExcludeAutoDispatch', false)
        },
        {
          value: false,
          label: '僅能信用卡下單',
          index: '_deniedOnlyCardPayment',
          checked: _.get(item, 'deniedSetting.isOnlyCardPayment', false)
        },
        {
          value: false,
          label: '禁止登入',
          index: '_deniedForbidLogin',
          checked: _.get(item, 'deniedSetting.isForbidLogin', false)
        }
      ];

      const user = await User.one(item._id).customGET();

      $scope.settingCheckboxes = [
        {
          value: false,
          label: '以簡訊方式接收',
          index: '_settingSmsMarketing',
          checked: _.get(item, 'setting.sms.marketing', false)
        },
        {
          value: false,
          label: '以email方式接收',
          index: '_settingEmailMarketing',
          checked: _.get(item, 'setting.email.marketing', false)
        },
        {
          value: false,
          label: '以App推播方式接收',
          index: '_settingPushMarketing',
          checked: _.get(item, 'setting.push.marketing', false)
        }
      ];

      $scope.inputItems = [
        {
          title: '一般資料',
          inputs: [
            { label: '會員ID', type: 'description', description: item._id },
            { label: '註冊手機', type: 'description', description: item.phone },
            { label: '註冊來源', type: 'description', description: getOrigin(_.get(item, 'firstCreated.origin')) },
            { label: '註冊utm資訊', type: 'register-utm' },
            {
              label: '登入過的來源',
              type: 'description',
              description: _.get(user, 'loginOrigins', [])
                .map(source => getOrigin(source))
                .join(',')
            },
            {
              label: '最近使用紀錄',
              type: 'last-access-info',
              labelInfo: '以用戶最近一次和server交換資訊的時間做為紀錄，以判定用戶活躍狀況。註: 開發版本v1.19.0發佈後才有紀錄'
            },
            {
              label: '會員群組',
              type: 'multi-searchable-obj-picker',
              index: 'userGroups',
              selectedListIndex: '_userGroups',
              title: '會員群組',
              resource: UserGroup,
              filterField: 'name',
              titleField: 'name',
              closeCityFilter: true,
              showSelectedItemsCount: true,
              onConfirm: (items) => {
                item._userGroups = (items || []).map(item => item.name).join('，');
              }
            },
            generateTableSettings($scope, {
              label: '成交資訊',
              class: 'w-75',
              index: '_dealCustomerInfo',
              columns: [
                { index: 'date', header: '成交日期', class: 'w-100', type: 'description' },
                { index: 'houseNo', header: '成交銷編', class: 'w-100', type: 'description' },
                { index: 'objNo', header: '成交物編', class: 'w-100', type: 'description' },
                { index: 'salesNo', header: '服務同仁', class: 'w-100', type: 'description' }
              ],
              disableMovement: true,
              disableDeletion: true,
              disableAdd: true
            }),
            { label: '', type: 'empty' },
            { label: '[會員資料維護]', type: 'empty' },
            { label: '名稱*', type: 'text', index: 'fullname' },
            { label: 'email', type: 'text', index: 'email', class: 'long' },
            { label: '生日', type: 'date-picker', index: '_birthday' },
            { label: '[地址]', type: 'empty' },
            {
              label: '縣市',
              type: 'dropdown',
              selected: _.get(item, 'contactAddress.county'),
              options: Object.keys(address),
              changed: (value) => {
                $scope.syncDistrictOptions(value);
                _.set(item, 'address.county', value || '');
              }
            },
            $scope.districtInput,
            {
              label: '地址',
              type: 'text',
              class: 'long',
              index: '_addressDetails',
              placeholder: '詳細地址',
              describeClass: 'color-red'
            },
            { label: '內部備註', type: 'textarea', index: 'note' },
            { type: 'action' }
          ]
        },
        {
          title: '設定',
          inputs: [
            { label: '[黑名單設定]', type: 'empty' },
            {
              label: '黑名單限制方式',
              type: 'checkbox',
              index: '_deniedSetting',
              checkboxes: $scope.deniedSettingCheckboxes
            },
            { label: '黑名單原因', type: 'textarea', index: '_denidReason' },
            { label: '[接收行銷廣告]', type: 'empty' },
            {
              label: '開關',
              type: 'checkbox',
              index: '_setting',
              checkboxes: $scope.settingCheckboxes
            },
            { type: 'action' }
          ]
        }
      ];
    });

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
