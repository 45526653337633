export default ['$timeout', '$log', 'Restangular', 'ngDialog', 'ywUtil',
  function directive ($timeout, $log, Restangular, ngDialog, ywUtil) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        tags: '='
      },
      template:
            '<button class="yw-btn yw-btn-blue" ng-click="openConfirm()">管理標籤</button>' +
            '<div class="tag-preview-block">' +
                '<div ng-repeat="tag in tags track by $index" class="yw-btn preview-tag" ng-bind="tag"></div>' +
            '</div>',
      link: link
    };

    function link (scope, element, attr) {
      scope.openConfirm = function openConfirm () {
        const dialogData = {
          title: '管理標籤',
          templateUrl: '/view/dialog/tag-setting-dialog-content.html',
          tags: angular.copy(scope.tags),
          addTag: addTag,
          removeTag: removeTag,
          confirmWhenResolve: confirmWhenResolve
        };

        const params = {
          template: '/view/dialog/confirm-dialog.html',
          showClose: false,
          className: 'ngdialog-theme-default yw-plain-dialog',
          closeByEscape: true,
          closeByDocument: true,
          data: dialogData
        };

        ngDialog.openConfirm(params);
      };

      function addTag (tag, tags) {
        if (tag) { tags.push(tag); }
      }

      function removeTag (index, tags) {
        tags.splice(index, 1);
      }

      function confirmWhenResolve (confirmValue, dialogData, dialogConfirm) {
        scope.tags = dialogData.tags;
        dialogConfirm();
      }
    }
  }];
