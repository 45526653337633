export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('serviceCapacities', false, _transformer);
  const ServiceCapacity = Restangular.service('serviceCapacities');
  return ServiceCapacity;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    return item;
  }
}];
