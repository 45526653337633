export default {
  bindings: {
    imgSource: '<',
    customClickHandle: '<',
    onClick: '&'
  },
  template: `
    <a ng-click="$ctrl.clicked()">
      <img class="img-image img" ng-src="{{$ctrl.thumbImgUrl}}">
    </a>
  `,
  controller: function TableCheckbox () {
    const ctrl = this;

    ctrl.$onChanges = (changes) => {
      ctrl.originImgUrl = _.get(ctrl, 'imgSource.url', '');
      const thumbImgUrl = _.get(ctrl, 'imgSource.thumbImage.url');
      ctrl.thumbImgUrl = thumbImgUrl || ctrl.originImgUrl || '/img/ic_list_nophoto.png';
    };

    ctrl.clicked = () => {
      if (ctrl.customClickHandle) {
        ctrl.onClick();
      } else {
        window.open(ctrl.originImgUrl, '_blank');
      }
    };
  }
};
