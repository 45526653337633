export default function () {
  return function (house) {
    if (!house) return '';
    if (house.receiverBuilding) {
      if (house.receiverFloor < 0) house.receiverFloor = `B${Math.abs(house.receiverFloor)}`;
      let shortAddress = house.receiverBuilding + '-' + house.receiverFloor + '樓';
      if (house.receiverRoom !== 0) shortAddress += '之' + house.receiverRoom;
      return shortAddress;
    } else {
      let shortAddress = house.building;
      if (house.floor < 0) house.floor = `B${Math.abs(house.floor)}`;
      if (_.isNumber(house.floor) || !_.isEmpty(house.floor)) shortAddress += '-' + house.floor + '樓';
      if (_.isNumber(house.room) && house.room !== 0) shortAddress += '之' + house.room;
      return shortAddress;
    }
  };
}
