import React, { Fragment } from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';
import moment from 'moment';

const style = {
  icon: {
    verticalAlign: 'sub'
  }
};

const dateTimeFormat = 'YYYY/MM/DD HH:mm';

const CurrentStatus = ({ reservation }) => {
  const currentLog = _.get(reservation, 'stateLogs', []).findLast(
    (log) => log.newState === _.get(reservation, 'state._id')
  );

  if (!currentLog) {
    return null;
  }
  return (
    <StatefulToolTip
      parent={(
        <span>
          {_.get(currentLog, 'newInfo.consoleStateName')}
          ：
          {moment(_.get(currentLog, 'at')).format(dateTimeFormat)}
        </span>
      )}
      position='top'
      arrow='center'
    >
      {_.get(reservation, 'stateLogs', []).map((log) => (
        <div key={log._id}>
          {_.get(log, 'newInfo.consoleStateName')}
          ：
          {moment(_.get(log, 'at')).format(dateTimeFormat)}
        </div>
      ))}
    </StatefulToolTip>
  );
};

const ReservationStatus = ({ reservation, currentUser, onEditStateClick }) => {
  const currentLog = _.get(reservation, 'stateLogs', []).findLast(
    (log) => log.newState === _.get(reservation, 'state._id')
  );
  const isCancelledOrCompleted = _.get(currentLog, 'newInfo.isCancelled') ||
    _.get(currentLog, 'newInfo.isCompleted');
  const hideControl = !currentUser.hasRole('serviceAdmin') && isCancelledOrCompleted;

  return (
    <>
      <CurrentStatus reservation={reservation} />
      {!hideControl &&
        <span
          className='img icon-list-edit ptr'
          style={style.icon}
          onClick={() => onEditStateClick(reservation)}
        />}
    </>
  );
};

export default ReservationStatus;
