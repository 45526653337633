'use strict';

export default ['confirmDialog', 'ywEvent',
  function directive (confirmDialog, ywEvent) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        callback: '=',
        obj: '=',
        text: '=',
        title: '=',
        partiallyEditable: '=',
        uneditableText: '='
      },
      template:
        '<div class="popover-color-black">' +
          '<span class="mr-5">' +
            '<span>{{text.slice(0, 11)}}</span>' +
            '<span ng-if="text.length > 11">...</span>' +
          '</span>' +
          '<span ng-click="openConfirm()">' +
            '<i class="glyphicon glyphicon-edit" aria-hidden="true"></i>' +
          '</span>' +
        '</div>',
      link: link
    };

    function link (scope, element, attr) {
      scope.openConfirm = function () {
        let messageCopy;
        if (scope.partiallyEditable) {
          messageCopy = scope.text.substr(11, scope.text.length);
        } else {
          messageCopy = scope.text;
        }

        const dialogData = {
          title: scope.title,
          templateUrl: '/view/page/push-message-dialog-content.html',
          customDialog: true,
          message: messageCopy,
          partiallyEditable: scope.partiallyEditable,
          uneditableText: scope.uneditableText
        };

        confirmDialog.openConfirm(dialogData).then(function (data) {
          if (_.isEmpty(dialogData.message)) return Promise.reject(new Error('請輸入備註內容'));
          if (scope.partiallyEditable) {
            dialogData.message = dialogData.uneditableText + dialogData.message;
          }
          scope.text = dialogData.message;
          return scope.callback(scope.obj, dialogData.message);
        }).catch(err => {
          console.log(String(err));
          if (_.isUndefined(err) || err === '$document' || err === '$escape') return;
          ywEvent.emit('ALERT', String(err));
        });
      };
    }
  }
];
