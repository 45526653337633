export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('reservations', false, _transformer);
  const Reservation = Restangular.service('reservations');
  return Reservation;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
    ];

    return item;
  }
}];
