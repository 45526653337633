import React, { Component } from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';

import Checkbox from './../common/Checkbox';
import { HeaderTR, Th } from './../common/Table';

class Header extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    const { reservations, selectedItems, toggleAll } = this.props;

    const allChecked = selectedItems.length > 0 && selectedItems.length === reservations.length;
    const indeterminate = selectedItems.length > 0 && selectedItems.length !== reservations.length;

    return (
      <HeaderTR>
        <Th>
          <Checkbox
            checked={allChecked}
            indeterminate={indeterminate}
            onChange={() => toggleAll()}
          />
        </Th>
        <Th />
        <Th alignLeft>單號金額</Th>
        <Th alignLeft>分類</Th>
        <Th alignLeft>服務方案</Th>
        <Th>聯絡人</Th>
        <Th alignLeft>廠商</Th>
        <Th>服務員</Th>
        <Th alignLeft>
          訂單狀態
          <StatefulToolTip
            parent={<i className='fas fa-info-circle ptr' aria-hidden='true' />}
            position='top'
            arrow='center'
          >
            <div className='color-primary-600'>訂單狀態{'>>>'}狀態說明(客戶前台狀態顯示)</div>
            <div>已下訂{'>>>'}訂單成立廠商媒合中(訂單成立)</div>
            <div>廠商已接單{'>>>'}訂單成立廠商媒合中(訂單成立)</div>
            <div>廠商已派單{'>>>'}已指派服務人員(訂單成立)</div>
            <div>服務人員已到現場{'>>>'}服務人員已到現場(到府服務)</div>
            <div>服務人員服務中{'>>>'}服務進行中(到府服務)</div>
            <div>服務完成{'>>>'}服務已完成，等待驗收(驗收付款)</div>
            <div>驗收完成{'>>>'}驗收完成(驗收付款)</div>
            <div>待用戶付款{'>>>'}現金訂單，確認現場有收款(驗收付款)</div>
            <div>訂單完成{'>>>'}訂單結束，請協助進行評價(進行評價)</div>
          </StatefulToolTip>
        </Th>
        <Th>明細</Th>
        <Th nowrap>驗收單</Th>
        <Th>訂單來源</Th>
      </HeaderTR>
    );
  }
}

Header.defaultProps = {
  reservations: [],
  selectedItems: []
};

export default Header;
