/*
  example:
  <md-radio-group ng-model="selectedValue" md-no-ink>
    <life-check ng-repeat="option in radioOptions track by $index" option="option" has-line="true" has-input="true" has-count="true" max="3"></life-check>
  </md-radio-group>
*/

export default {
  bindings: {
    option: '=', // object, 必填, { value, label, inputContent, selected, count }
    hasLine: '<', // boolean
    hasInput: '<', // boolean
    inputPlaceholder: '<', // string
    hasCount: '<', // boolean
    max: '<', // number
    hint: '<' // string
  },
  template: `
    <div class="life-check" ng-click="$ctrl.handleClick($event)" ng-mouseover="$ctrl.mouseover()" ng-mouseleave="$ctrl.mouseleave()" ng-class="{'life-check-isHover': $ctrl.isHover, 'life-check-hasLine': $ctrl.hasLine}">
      <div class="life-check-wrapper">
        <div ng-if="$ctrl.hasCount && $ctrl.option.count" class="life-check-number-wrapper life-body-medium">
          <div class="life-checkbox mr-8">
            <img src="/img/plus-icon-active.svg" />
            <img ng-if="$ctrl.option.count >= $ctrl.max" src="/img/plus-icon-disable.svg" />
          </div>
          <div ng-bind="$ctrl.option.count" class="life-check-label-active"></div>
          <div class="life-checkbox mr-8 ml-8" ng-click="$ctrl.handleMinusClick($event)">
            <img src="/img/minus-icon-active.svg" />
          </div>
        </div>
        <div ng-if="!$ctrl.option.count" class="life-checkbox mr-8">
          <img ng-if="$ctrl.option.selected" src="/img/checkbox.svg" />
        </div>
        <div class="life-body-medium" ng-class="{'life-check-label-active': $ctrl.option.selected || $ctrl.option.count}">{{$ctrl.option.label}}</div>
      </div>
      <life-input
        ng-if="$ctrl.hasInput"
        placeholder="$ctrl.inputPlaceholder"
        on-keyup="$ctrl.handleInputKeyup(event)"
        on-focus="$ctrl.handleInputFocus(event)"
        on-click="$ctrl.handleInputClick(event)"
        value="$ctrl.option.inputContent"
        class="life-check-input">
      </life-input>
    </div>
    <div class="life-check-hint life-body-small" ng-if="$ctrl.hint" ng-bind="$ctrl.hint"></div>
  `,
  controller: ['$element',
    function inputController ($element) {
      this.isHover = false;
      this.mouseover = () => {
        this.isHover = true;
      };
      this.mouseleave = () => {
        this.isHover = false;
      };
      this.handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (this.hasCount) {
          if (this.max && this.option.count >= this.max) return;
          this.option.count++;
        } else {
          this.option.selected = !this.option.selected;
        }
      };
      this.handleMinusClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.option.count--;
      };
      this.handleInputKeyup = (event) => {
        event.stopPropagation();
        if (event.keyCode === 32) event.preventDefault();
      };
      this.handleInputFocus = (event) => {
        event.stopPropagation();
      };
      this.handleInputClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
      };
    }
  ]
};
