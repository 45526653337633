import React, { Component } from 'react';

import Header from './Header';
import Content from './Content';
import withTheme from '../withTheme';
import { Table } from '../common/Table';

const subHeaderStyle = {
  backgroundColor: '#ebebeb',
  textAlign: 'center'
};

class ReservationList extends Component {
  constructor (props) {
    super(props);

    this.handleIFCReceived = this.handleIFCReceived.bind(this);
    this.openStateChangeDialog = this.openStateChangeDialog.bind(this);
    this.openVenderAssignDialog = this.openVenderAssignDialog.bind(this);
    this.openStaffAssignDialog = this.openStaffAssignDialog.bind(this);
    this.openAcceptanceNoteDialog = this.openAcceptanceNoteDialog.bind(this);
    this.selectItems = this.selectItems.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.toggleItem = this.toggleItem.bind(this);

    // ready for receiving inter framework communication
    const IFCEvent = this.props.ywEvent.register('IFC_EVENT', this.handleIFCReceived);
    this.state = {
      IFCEvent,
      reservations: [],
      selectedItems: [],
      pinReservations: [],
      showPinReservations: true
    };
  }

  componentWillUnmount () {
    const IFCEvent = this.state.IFCEvent;
    if (IFCEvent) {
      this.props.ywEvent.unregister(IFCEvent);
    }
  }

  handleIFCReceived ({ type, payload }) {
    if (type === 'SET_CURRENT_TABLE_LIST') {
      this.setState({ reservations: payload, selectedItems: [] });
      // forceCheck();
    }
    if (type === 'SET_TABLE_HEAD_FOOT') {
      this.setState({ tableHeadFoot: payload });
    }
    if (type === 'FORCE_VISIBLE') {
      // forceVisible();
    }
    if (type === 'SET_PIN_RESERVATIONS') {
      const pinReservations = (payload || []).sort((a, b) => {
        if (_.get(a, 'pinConnectionInfo.date') && !_.get(b, 'pinConnectionInfo.date')) {
          return -1;
        }
        if (_.get(a, 'pinConnectionInfo.date') && _.get(b, 'pinConnectionInfo.date')) {
          return new Date(_.get(a, 'pinConnectionInfo.date')).getTime() -
            new Date(_.get(b, 'pinConnectionInfo.date')).getTime();
        }
        return 0;
      });
      this.setState({ pinReservations });
    }
    if (type === 'SHOW_PIN_RESERVATIONS') {
      this.setState({ showPinReservations: payload });
    }
  }

  openStateChangeDialog (reservation) {
    this.props.ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_RESERVATION_STATE_DIALOG',
      payload: reservation
    });
  }

  openVenderAssignDialog (reservation) {
    this.props.ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_VENDER_ASSIGN_DIALOG',
      payload: reservation
    });
  }

  openStaffAssignDialog (reservation) {
    this.props.ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_STAFF_ASSIGN_DIALOG',
      payload: reservation
    });
  }

  openReservationDetail (reservation) {
    this.props.ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_RESERVATION_DETAIL',
      payload: reservation
    });
  }

  openAcceptanceNoteDialog (reservation) {
    this.props.ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_OPEN_ACCEPTANCE_NOTE_DIALOG',
      payload: reservation
    });
  }

  selectedItemsChange ({ previous, current }) {
    this.props.ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'SELECTED_ITEMS_CHANGE',
      payload: { previous, current }
    });
  }

  selectItems (items) {
    this.setState(({ selectedItems }) => {
      this.selectedItemsChange({ previous: selectedItems, current: items });
      return { selectedItems: items || [] };
    });
  }

  toggleAll () {
    const { reservations, selectedItems } = this.state;
    this.selectItems(selectedItems.length === 0 ? [...reservations] : []);
  }

  toggleItem (reservation) {
    const { selectedItems } = this.state;
    const selectedItemIdx = selectedItems.findIndex(
      (item) => item._id === reservation._id
    );
    if (selectedItemIdx > -1) {
      selectedItems.splice(selectedItemIdx, 1);
      this.selectItems([...selectedItems]);
    } else {
      this.selectItems([...selectedItems, reservation]);
    }
  }

  render () {
    const { currentUser } = this.props;

    const {
      reservations,
      tableHeadFoot,
      selectedItems,
      showPinReservations,
      pinReservations
    } = this.state;

    const showReservationSplitHeader = showPinReservations &&
      !!_.get(pinReservations, 'length') &&
      !!_.get(reservations, 'length');

    return (
      <Table>
        <thead>
          <Header
            tableHeadFoot={tableHeadFoot}
            selectedItems={selectedItems}
            reservations={reservations}
            toggleAll={this.toggleAll}
          />
        </thead>

        <tbody>
          {showReservationSplitHeader &&
            <tr style={subHeaderStyle}>
              <td colSpan='12' style={subHeaderStyle}>
                置頂訂單
              </td>
            </tr>}
          {showPinReservations && pinReservations.map(item => (
            <Content
              key={`pin-${item._id}`}
              reservation={item}
              ywEvent={this.props.ywEvent}
              currentUser={currentUser}
              toggleItem={this.toggleItem}
              selectedItems={selectedItems}
              openStateChangeDialog={this.openStateChangeDialog}
              openVenderAssignDialog={this.openVenderAssignDialog}
              openStaffAssignDialog={this.openStaffAssignDialog}
              openReservationDetail={this.openReservationDetail}
              openAcceptanceNoteDialog={this.openAcceptanceNoteDialog}
              isInPinList
            />
          ))}

          {showReservationSplitHeader &&
            <tr style={subHeaderStyle}>
              <td colSpan='12' style={subHeaderStyle}>
                一般訂單
              </td>
            </tr>}
          {
            reservations.map(item => (
              <Content
                key={item._id}
                reservation={item}
                ywEvent={this.props.ywEvent}
                currentUser={currentUser}
                toggleItem={this.toggleItem}
                selectedItems={selectedItems}
                openStateChangeDialog={this.openStateChangeDialog}
                openVenderAssignDialog={this.openVenderAssignDialog}
                openStaffAssignDialog={this.openStaffAssignDialog}
                openReservationDetail={this.openReservationDetail}
                openAcceptanceNoteDialog={this.openAcceptanceNoteDialog}
                pinReservations={pinReservations}
              />
            ))
          }
        </tbody>

        <tfoot>
          <Header tableHeadFoot={tableHeadFoot} />
        </tfoot>
      </Table>
    );
  }
}

export default withTheme(ReservationList);
