import React from 'react';

import Checkbox from './../common/Checkbox';
import ExtraControls from './../common/ReservationInfo/ExtraControls';
import DetailControls from './../common/ReservationInfo/DetailControls';
import PaymentInfo from './../common/ReservationInfo/PaymentInfo';
import ServiceClassificationCell from './../common/ReservationInfo/ServiceClassificationCell';
import ServiceInfoCell from './../common/ReservationInfo/ServiceInfoCell';
import UserInfo from './../common/ReservationInfo/UserInfo';
import DeviceType from './../common/ReservationInfo/DeviceType';
import ReservationStatus from './ReservationStatus';

const style = {
  icon: {
    fontSize: '24px'
  },
  td: {
    textAlign: 'center'
  }
};

const Content = ({ item, ywEvent, currentUser, pinReservations }) => {
  const onToggle = () => {
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'TOGGLE_ITEM_SELECTION',
      payload: item._id
    });
  };

  const openVenderAssignDialog = (item) => {
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_VENDER_ASSIGN_DIALOG',
      payload: item
    });
  };

  const openStateChangeDialog = (item) => {
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_RESERVATION_STATE_DIALOG',
      payload: item
    });
  };

  return (
    <tr>
      <td style={style.td}>
        <Checkbox checked={!!item._selected} onChange={onToggle} />
      </td>
      <td>
        <ExtraControls
          reservation={item}
          ywEvent={ywEvent}
          pinReservations={pinReservations}
        />
      </td>
      <td><PaymentInfo reservation={item} /></td>
      <td>
        <ServiceClassificationCell reservation={item} />
      </td>
      <td>
        <ServiceInfoCell reservation={item} />
      </td>
      <td style={style.td}>
        <UserInfo reservation={item} />
      </td>
      <td>
        {_.get(item, 'vender.name')}
        {currentUser.hasRole('serviceAdmin') &&
          <i
            className='img icon-list-edit ptr'
            aria-hidden='true'
            style={style.icon}
            onClick={() => openVenderAssignDialog(item)}
          />}
      </td>
      <td>
        <ReservationStatus
          reservation={item}
          currentUser={currentUser}
          onEditStateClick={openStateChangeDialog}
        />
      </td>
      <td style={style.td}>
        <DetailControls
          reservation={item}
          ywEvent={ywEvent}
        />
      </td>
      <td style={style.td}>
        <DeviceType
          reservation={item}
        />
      </td>
    </tr>
  );
};

export default Content;
