// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/ic_search.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div style=\"padding: 0 15px;\">\n  <div layout=\"row\" layout-align=\"start center\" class=\"mb-10\" ng-repeat=\"textInput in ngDialogData.textInputs track by $index\">\n    <div>\n      <div class=\"search-bar\" style=\"display: block;\">\n        <div class=\"input-wrapper\">\n            <div class=\"input-group\">\n                <input class=\"input\"\n                  type=\"text\"\n                  ng-model=\"textInput.value\"\n                  class=\"active-input\"\n                  placeholder=\"{{textInput.placeholder}}\"\n                ></input>\n                <div class=\"input-group-btn\" uib-dropdown>\n                    <button type=\"button\"\n                      class=\"btn dropdown-toggle\"\n                      data-toggle=\"dropdown\"\n                      aria-haspopup=\"true\"\n                      aria-expanded=\"false\"\n                      style=\"height: 36px; border-radius: 0 4px 4px 0;\"\n                      uib-dropdown-toggle\n                    >\n                      {{textInput.btnName}}\n                      <span class=\"caret\"></span>\n                    </button>\n                    <ul class=\"dropdown-menu\" uib-dropdown-menu style=\"right:0; left:auto;\">\n                      <li ng-repeat=\"option in ngDialogData.searchBarOptions\">\n                        <a ng-bind=\"option.btnName\" ng-click=\"ngDialogData.onChangeSearchBarOption($parent.$index, option)\"></a>\n                      </li>\n                    </ul>\n                </div>\n            </div>\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" />\n        </div>\n      </div>\n    </div>\n\n    <div>\n      <i ng-if=\"$index === 0\" class=\"fa fa-plus ptr\" style=\"padding: 8px\" ng-click=\"ngDialogData.onAddTextInput()\"></i>\n      <i ng-if=\"$index > 0\" class=\"fas fa-trash ptr\" style=\"padding: 8px\" ng-click=\"ngDialogData.onRemoveTextInput($index)\"></i>\n    </div>\n  </div>\n</div>\n";
// Exports
var _module_exports = code;;
var path = '/view/dialog/compound-search-text-dialog.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;