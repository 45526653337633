export default ['$scope', '$async', '$state', 'ywUtil', 'currentUser', 'Album', 'albumUtil',
  function controller ($scope, $async, $state, ywUtil, currentUser, Album, albumUtil) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '相簿名稱', placeholder: '請輸入相簿名稱', params: 'name' },
          { btnName: '相簿關鍵字', placeholder: '請輸入相簿關鍵字', params: 'keywordName' },
          { btnName: '信義原廠商ID', placeholder: '請輸入信義原廠商ID', params: 'sinyiOriginalVender' },
          { btnName: '網址辨識名稱', placeholder: '請輸入網址辨識名稱', params: 'urlId' },
          { btnName: '關聯廠商名稱', placeholder: '請輸入關聯廠商名稱', params: 'venderName' }
        ]
      );

      $scope.filterItems = [
        {
          name: 'isVisible',
          label: '顯示狀態',
          options: { true: '顯示', false: '隱藏' },
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        }
      ];

      $scope.queryPage = ''; // for GA

      $scope.mainAction.initResource(Album);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true,
        keywords: [],
        sort: 0
      };
    }

    $scope.menuButtons = {
      edit: (item) => $scope.changePageState('edit', item),
      photo: (item) => {
        $state.go('service-admin.content/photo', { albumId: item._id });
      }
    };

    $scope.toolbarBtns = [
      {
        label: '批次設定',
        onClick: () => {
          const selectedItems = $scope.items.filter(item => item._selected);
          const selectedIds = selectedItems.map(item => item._id);
          if (!selectedItems.length) { return $scope.alert('請選擇要設定的項目'); }
          albumUtil.openBatchUpdate(selectedIds, $scope.items);
        }
      }
    ];
  }];
