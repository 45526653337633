export default ['ngDialog', function factory (ngDialog) {
  return {
    openConfirm: openConfirm
  };

  /// //////
  function openConfirm (template, data, scope, options = {}) {
    const params = {
      template: template,
      className: 'ngdialog-theme-default',
      data: data
    };
    if (scope) params.scope = scope;

    if (options) {
      if (options.plain) {
        params.showClose = false;
        params.className += ' yw-plain-dialog';
      }
      if (options.size) {
        params.className += ' custom-width-' + options.size;
      }
    }
    params.closeByEscape = _.isBoolean(options.closeByEscape) ? options.closeByEscape : true;
    params.closeByDocument = _.isBoolean(options.closeByDocument) ? options.closeByDocument : true;

    return ngDialog.openConfirm(params);
  }
}];
