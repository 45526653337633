export default {
  bindings: {
    item: '<',
    type: '<',
    onClick: '&',
    shouldHide: '<',
    className: '<'
  },
  template:
        '<a ng-click="$ctrl.clicked()" ng-if="!$ctrl.shouldHide">' +
          '<span class="img" ng-class="$ctrl.className"></span>' +
        '</a>',
  controller: function IconButton () {
    const ctrl = this;

    ctrl.clicked = () => {
      ctrl.onClick(ctrl.item);
    };
  }
};
