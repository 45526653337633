import { inputLimit } from '../../../share/input-limit.js';

export default ['$scope', 'LayoutModule', 'layoutModuleUtils',
  function controller ($scope, LayoutModule, layoutModuleUtils) {
    const self = this;
    self.imageFiles = {};
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;
    $scope.dragContents = [];

    function validator () {
      const item = $scope.currentActionItem;
      const articleMainImageModulePages = item.articleMainImageModule.pages;
      let linkResult = true;
      let titleResult = true;
      let imageResult = true;
      console.log('validator:', item);

      if (!item.name) {
        return $scope.alert('模組名稱為必填');
      }

      if (articleMainImageModulePages.some(page => !page?.title)) {
        return $scope.alert('需填寫模組內容所有頁籤標題');
      }

      articleMainImageModulePages.forEach(page => {
        page?.contents.forEach(content => {
          if (!content.image) imageResult = false;
          if (content.articlesInfo?.some(info => !info?.link)) linkResult = false;
          if (content.articlesInfo?.some(info => !info?.title)) titleResult = false;
        });
      });

      if (!imageResult) return $scope.alert('需上傳模組內容所有圖片');
      if (!linkResult) return $scope.alert('需填寫模組內容所有文章連結');
      if (!titleResult) return $scope.alert('需填寫模組內容所有標題');

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;

      const pages = item.contents.map((content) => ({
        title: content[0].title,
        contents: [2, 10, 18].map((startIndex) => {
          const imageKey = Object?.keys(content[startIndex]).find(key => !key.includes('_') && key.includes('image'));
          const dataNumber = imageKey?.substring(imageKey.length - 1, imageKey.length);
          return ({
            image: content[startIndex][imageKey],
            articlesInfo: [0, 1, 2].map((infoCount) => ({
              link: content[startIndex + (2 * infoCount) + 1][`link${dataNumber}-${infoCount}`],
              title: content[startIndex + (2 * infoCount) + 2][`articleTitle${dataNumber}-${infoCount}`]
            }))
          });
        }
        )
      }));

      item.articleMainImageModule = {
        paragraphTitle: item.paragraphTitle,
        pages
      };

      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();

        item.style = layoutModule?.style;
        item.isVisible = layoutModule?.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item.paragraphTitle = layoutModule.articleMainImageModule.paragraphTitle || '';
        item[`${item.type}Module`] = layoutModule[`${item.type}Module`];
      }
      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      currentActionItem.visibleItemIndex = 0;
      currentActionItem.logListEvent = (index) => {
        currentActionItem.newIndex = index;
      };

      const moduleContentsLength = 3;
      const defaultContents = [
        {
          titleLabel: '*頁籤標題：',
          type: 'module-text',
          index: 'title',
          title: '預設標題',
          remark: '字數限制：256字(多個頁籤時顯示，建議8字內，字數寬度超過360px會隱藏)',
          maxlength: inputLimit.default
        }
      ];

      const articleContent = (itemNumber, index) => {
        return (
          [
            {
              type: 'module-switch-title',
              switchTitle: `內容${index + 1}`,
              order: index,
              maxlength: moduleContentsLength,
              itemMoveForward: (itemIndex, contentIndex) => {
                const switchContentsArray = _.cloneDeep(currentActionItem.contents)[contentIndex];
                defaultContents[0].title = switchContentsArray[0]?.title;
                const switchContents = [];
                [1, 9, 17].forEach((startIndex) => {
                  const afterSwitch = startIndex !== 17 ? switchContentsArray.slice(startIndex, startIndex + 8) : switchContentsArray.slice(startIndex);
                  switchContents.push(afterSwitch);
                });
                const switchItem = switchContents.splice(itemIndex, 1);
                switchContents.splice(itemIndex - 1, 0, ...switchItem);
                switchContents.forEach((item, index) => {
                  item[0].order = index;
                  item[0].switchTitle = `內容${index + 1}`;
                });
                const newContents = [...defaultContents, ...switchContents.flat()];
                currentActionItem.contents[contentIndex] = _.cloneDeep(newContents);
              },
              itemMoveBackward: (itemIndex, contentIndex) => {
                const switchContentsArray = _.cloneDeep(currentActionItem.contents)[contentIndex];
                defaultContents[0].title = switchContentsArray[0]?.title;
                const switchContents = [];
                [1, 9, 17].forEach((startIndex) => {
                  const afterSwitch = startIndex !== 17 ? switchContentsArray.slice(startIndex, startIndex + 8) : switchContentsArray.slice(startIndex);
                  switchContents.push(afterSwitch);
                });
                const switchItem = switchContents.splice(itemIndex, 1);
                switchContents.splice(itemIndex + 1, 0, ...switchItem);
                switchContents.forEach((item, index) => {
                  item[0].order = index;
                  item[0].switchTitle = `內容${index + 1}`;
                });
                const newContents = [...defaultContents, ...switchContents.flat()];
                currentActionItem.contents[contentIndex] = _.cloneDeep(newContents);
              }
            },
            {
              label: '*圖片：',
              remark: '圖片比例：3:2、建議尺寸：不要低於寬700 x 高466 ~ 寬1200 x 高800，限制 1 MB',
              type: 'module-file',
              index: `_imageUrl${itemNumber}`,
              originIndex: `image${itemNumber}`,
              class: 'images-wrapper',
              files: $scope.imageFiles,
              success: (result, item) => {
                item[`_imageUrl${itemNumber}`] = result;
                item[`image${itemNumber}`] = result._id;
              },
              error: $scope.alert,
              validate: { limitSize: 1000000 }
            },
            { label: '*連結1：', type: 'module-link', index: `link${itemNumber}-0` },
            {
              label: '*文章標題1：',
              type: 'module-text',
              index: `articleTitle${itemNumber}-0`,
              remark: '字數限制：20字，圖片的Title、alt同此標題',
              maxlength: 20
            },
            { label: '*連結2：', type: 'module-link', index: `link${itemNumber}-1` },
            {
              label: '*文章標題2：',
              type: 'module-text',
              index: `articleTitle${itemNumber}-1`,
              remark: '字數限制：20字，圖片的Title、alt同此標題',
              maxlength: 20
            },
            { label: '*連結3：', type: 'module-link', index: `link${itemNumber}-2` },
            {
              label: '*文章標題3：',
              type: 'module-text',
              index: `articleTitle${itemNumber}-2`,
              remark: '字數限制：20字，圖片的Title、alt同此標題',
              maxlength: 20
            }
          ]
        );
      };

      const articleContents = _.chain([1, 2, 3])
        .map((itemNumber, index) => {
          return articleContent(itemNumber, index);
        }).flatten().value();

      $scope.dragContents = [...defaultContents, ...articleContents];

      if (currentActionItem.articleMainImageModule) {
        const pages = currentActionItem.articleMainImageModule.pages.map((page) => {
          const pageDragContents = _.cloneDeep($scope.dragContents);
          pageDragContents[0].title = page.title;
          [2, 10, 18].forEach((startIndex, index) => {
            pageDragContents[startIndex][`image${index + 1}`] = page.contents[index].image._id;
            pageDragContents[startIndex][`_imageUrl${index + 1}`] = page.contents[index].image;
            page.contents[index].articlesInfo.forEach((info, infoCount) => {
              pageDragContents[startIndex + (infoCount * 2) + 1][`link${index + 1}-${infoCount}`] = info.link;
              pageDragContents[startIndex + (infoCount * 2) + 2][`articleTitle${index + 1}-${infoCount}`] = info.title;
            });
          });
          return pageDragContents;
        });
        currentActionItem.contents = pages;
      } else {
        currentActionItem.contents = [_.cloneDeep($scope.dragContents)];
      }
      const dragModules = layoutModuleUtils.getDragModules($scope, 'contents');

      return [
        { label: '模組類型：', type: 'description', description: currentActionItem.label },
        { label: '*模組名稱：', type: 'text', index: 'name', remark: '不會顯示於前台' },
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ]
        },
        { type: 'underline' },
        { label: '段落標題：', type: 'text', index: 'paragraphTitle', remark: 'H1、字數限制：256字(未填則不顯示，建議8字內)', maxlength: inputLimit.default },
        {
          type: 'drag-module',
          module: 'articleMainImage',
          subType: 'graphicDragModule-wrapper',
          index: 'contents',
          itemDropped: dragModules.itemDropped,
          itemRemoved: dragModules.itemRemoved,
          itemAdded: dragModules.itemAdded,
          itemVisible: dragModules.itemVisible,
          itemDragend: dragModules.itemDragend
        },
        { type: 'action' }
      ];
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
