export default ['$timeout', '$log', 'ngDialog', function directive ($timeout, $log, ngDialog) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      days: '=ywDays',
      disabled: '=ywDisabled',
      daysChanged: '&ywDaysChnaged'
    },
    template: '<div title="{{selectedLabel}}" class="dropdown-wrapper wrapper" ng-class="{disabled: disabled}" ng-click="disabled || openConfirm(this)">' +
      '<a class="select">' +
      '<span ng-bind="selectedLabel">' +
      '</span>' +
      '<img src="/img/ic_form_arrow.png" />' +
      '</a>' +
      '</div>',
    link: link
  };

  function link (scope, element, attr) {
    scope.openConfirm = openConfirm;
    scope.dayOptions = [
      { label: '星期日', value: 0, checked: _.includes(scope.days, 0) },
      { label: '星期一', value: 1, checked: _.includes(scope.days, 1) },
      { label: '星期二', value: 2, checked: _.includes(scope.days, 2) },
      { label: '星期三', value: 3, checked: _.includes(scope.days, 3) },
      { label: '星期四', value: 4, checked: _.includes(scope.days, 4) },
      { label: '星期五', value: 5, checked: _.includes(scope.days, 5) },
      { label: '星期六', value: 6, checked: _.includes(scope.days, 6) }
    ];

    setSelectedLabel(scope, scope.days);
  }

  function openConfirm (scope) {
    scope.dialogData = {
      dayOptions: scope.dayOptions,
      days: scope.days,
      // functions
      daySelectChanged: daySelectChanged,
      selectAll: selectAll
    };

    const params = {
      template: '/view/dialog/day-picker-dialog.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog',
      closeByEscape: true,
      closeByDocument: true,
      data: scope.dialogData
    };

    ngDialog.openConfirm(params).then(function (days) {
      $log.debug('days: ' + days);
      setSelectedLabel(scope, days);
      scope.days = days;
      scope.daysChanged();
    });

    selectAllChecked(scope);
  }

  function setSelectedLabel (scope, days) {
    if (_.isEmpty(days)) {
      scope.selectedLabel = '請選擇';
      return scope.selectedLabel;
    }

    let str = '';
    let start = null;
    let end = null;
    scope.dayOptions.map(function (opt, index) { // eslint-disable-line
      if (opt.checked) {
        if (!start) {
          start = opt;
        } else {
          end = opt;
        }

        if (index === 6) { setDisplayStr(); } // last element
      } else {
        setDisplayStr();
        start = end = null;
      }
    });
    scope.selectedLabel = str;

    function setDisplayStr () {
      if (start && !end) {
        str += (_.isEmpty(str) ? '' : ', ') + start.label;
      } else if (start && end) {
        str += (_.isEmpty(str) ? '' : ', ') + start.label + ' - ' + end.label[2];
      }
    }
  }

  function daySelectChanged (scope, option) {
    console.log(scope, option);
    if (!option.checked) {
      scope.ngDialogData.days.push(option.value);
    } else {
      _.remove(scope.ngDialogData.days, function (n) { return n === option.value; });
    }
  }

  function selectAllChecked (scope) {
    if (scope.dialogData && scope.dialogData.days.length === 7) {
      scope.dialogData.isSelectAll = true;
    } else {
      scope.dialogData.isSelectAll = false;
    }
  }

  function selectAll (scope) {
    if (!scope.ngDialogData.isSelectAll) {
      scope.ngDialogData.days = [0, 1, 2, 3, 4, 5, 6];
      scope.ngDialogData.dayOptions.forEach(d => { d.checked = true; });
    } else {
      scope.ngDialogData.days = [];
      scope.ngDialogData.dayOptions.forEach(d => { d.checked = false; });
    }
  }
}];
