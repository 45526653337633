export default ['ngDialog', 'ywUtil', 'ywEvent', function factory (ngDialog, ywUtil, ywEvent) {
  function openDialog (activity) {
    const dialogData = { activity };
    const params = {
      template: '/view/dialog/activity-detail-dialog.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog custom-width-600',
      closeByEscape: true,
      closeByDocument: true,
      data: dialogData
    };

    ngDialog.openConfirm(params);
  }

  return { openDialog };
}];
