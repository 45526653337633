export default ['$scope', '$stateParams', '$async', 'ywUtil', 'Restangular', 'PhotoTag', 'Photo',
  function controller ($scope, $stateParams, $async, ywUtil, Restangular, PhotoTag, Photo) {
    const self = this;
    // $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.imageFile = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    const save = async () => {
      const result = await $scope.$parent.save({ doNothingAfterSave: true });
      if (result.error) {
        return Promise.reject(result.error);
      }
      const photos = await Photo.getList({ album: $stateParams.albumId, limit: 9999 });
      if (photos.length && !photos.find(photo => photo.isFrontCover)) {
        const res = await Photo.one(result._id).customPUT({}, 'frontCover');
        $scope.afterSaved({ res: res });
      } else {
        $scope.afterSaved({ res: result });
      }
    };
    $scope.save = save;

    function validator () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      console.log('validator:', item);

      if (isEdit && !item.urlId) return $scope.alert('網址辨識名稱不能為空');

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      console.log('after transform: ', item);

      item.sort = item.sort || 0;
      if (!item.album) {
        item.album = $stateParams.albumId;
      }
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';

      if (isEdit) {
        item._imageUrl = _.get(item, 'image');
        item.image = _.get(item, 'image._id');

        item._tags = item.tags.map(tag => `${tag.photoClassification}-${tag._id}`);
        item.tags = item.tags.map(tag => tag._id);
      }

      const [allPhotoClassifications, allTags] = await Promise.all([
        Restangular.service('photoClassifications').getList({ limit: 999 }),
        PhotoTag.getList({ limit: 999 })
      ]);
      const tagOptions = allTags.map(tag => {
        const photoClassification = allPhotoClassifications
          .find(item => item._id === tag.photoClassification);
        return {
          label: tag.name,
          value: `${photoClassification._id}-${tag._id}`,
          category: photoClassification.name
        };
      });

      $scope.photoTag = {
        label: '相片分類標籤',
        type: 'multi-selection-dropdown',
        index: '_tags',
        selectedList: item._tags,
        options: tagOptions,
        byCategory: true,
        hideInput: true,
        imageUrl: item?._imageUrl?.url || '/img/photo-tag-default-image.jpg',
        changed: (items) => {
          item.tags = items.map(value => value.split('-')[1]);
        }
      };

      $scope.inputItems = [
        { label: '相片名稱*', type: 'text', index: 'name' },
        {
          label: '相片圖*',
          type: 'single-file',
          index: '_imageUrl',
          originIndex: 'image',
          class: 'images-wrapper',
          files: $scope.imageFile,
          success: (result) => {
            item._imageUrl = result;
            item.image = result._id;
            $scope.photoTag.imageUrl = result?.url;
          },
          remove: () => {
            $scope.photoTag.imageUrl = '/img/photo-tag-default-image.jpg';
          },
          error: $scope.alert
        },
        { label: '相片說明', type: 'textarea', index: 'description' },
        $scope.photoTag,
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ]
        },
        {
          label: '網址辨識名稱',
          type: 'text',
          class: 'long',
          index: 'urlId',
          info: '由此自定網址名稱，使用全英文小寫或數字命名，可使用"-"分隔，例 interior-select，不設定則系統預設使用id'
        },
        { label: '排序', type: 'number', index: 'sort' },
        { type: 'action' }
      ];
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
