import moment from 'moment';

export default ['Restangular', 'WEBAPP_URL', function factory (Restangular, WEBAPP_URL) {
  Restangular.addElementTransformer('albums', false, _transformer);
  const Album = Restangular.service('albums');
  return Album;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    function clickAction () {
      window.open(`${WEBAPP_URL}album/${item.urlId}`, '_blank');
    }

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'sort'),
      { anchorType: 'true-or-false', isTrue: _.get(item, 'isVisible') },
      { anchorType: 'preview-image', imageRef: _.get(item, 'frontCover.image') },
      {
        anchorType: 'content',
        content: _.get(item, 'name'),
        popover: '點擊開啟',
        icon: 'link',
        clickAction
      },
      _.get(item, 'keywords', []).slice(0, 5).map(keyword => keyword.name).join('、'),
      _.get(item, 'photoCount'),
      _.get(item, 'photoTotalViews'),
      moment(_.get(item, 'updateFieldsAt')).format('YYYY/MM/DD'),
      {
        anchorType: 'menu-buttons',
        btnList: [
          { name: 'edit', label: '相簿設定' },
          { name: 'photo', label: '相片設定' }
        ]
      }
    ];

    return item;
  }
}];
