export default ['$scope', '$stateParams', '$location', '$async', 'ywUtil', 'currentUser', 'Quotation', 'confirmDialog', 'Restangular',
  function controller ($scope, $stateParams, $location, $async, ywUtil, currentUser, Quotation, confirmDialog, Restangular) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'quotation'; // for GA

      $scope.mainAction.initResource(
        Quotation,
        {
          reservationId: $stateParams.reservationId,
          venderId: _.get($location.search(), 'venderId')
        }
      );

      const clearFilter = $scope.mainAction.clearFilter;
      $scope.mainAction.clearFilter = () => clearFilter(['reservationId', 'venderId']);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        reservationId: $stateParams.reservationId,
        paymentStageSettings: [],
        _attachments: [],
        _attachmentObjs: []
      };
    }

    $scope.publishQuotation = $async(async (quotation) => {
      await confirmDialog.openConfirm({
        title: '發布給客戶確認',
        content: '確定將此份報價/合約傳給客戶確認？'
      });
      const res = await Quotation.one(quotation._id).customPOST({}, 'publish');
      if (res.error) {
        $scope.alert(res.error);
      } else {
        const index = _.findIndex($scope.items, item => item._id === quotation._id);
        const newQuotation = Quotation.one(quotation._id);
        Object.keys(res.plain()).forEach(key => { newQuotation[key] = res[key]; });
        $scope.items[index] = newQuotation.clone();
      }
    });

    const updateContract = $async(async (quotation, updateMethod) => {
      const dialogData = {
        title: '線下客戶確認合約與報價',
        templateUrl: '/view/dialog/upload-quotation-contract-files.html',
        disableConfirm: true,
        confirmValue: {
          contractFiles: _.get(quotation, 'paperContracts', []),
          contractNote: quotation.contractNote
        },
        confirmation: quotation.confirmation,
        files: [],
        uploadSuccess: $async(async (file) => {
          dialogData.confirmValue.contractFiles.push(file);
          handleConfirmDisabled(dialogData.confirmValue);
        }),
        uploadError: (err) => $scope.alert(err),
        removeOne: (index) => {
          dialogData.confirmValue.contractFiles.splice(index, 1);
          handleConfirmDisabled(dialogData.confirmValue);
        },
        onContractNoteChange: (data) => handleConfirmDisabled(data),
        confirmWhenResolve: $async(async (data, ngDialogData, confirmCb) => {
          dialogData.disableConfirm = true;
          if (_.get(quotation, 'publication.state') === 'pending') {
            const publishResult = await Quotation.one(quotation._id).customPOST({}, 'publish');
            if (publishResult.error) {
              return $scope.alert(publishResult.error);
            } else {
              quotation.publication.state = 'published';
            }
          }
          const res = await updateMethod({
            paperContracts: data.contractFiles.map(file => ({
              name: file.name || file.originalFilename,
              file: file.file || file._id
            })),
            contractNote: data.contractNote
          });
          if (res.error) {
            $scope.alert(res.error);
          } else {
            const updated = await Quotation.one(quotation._id).customGET();
            const index = _.findIndex($scope.items, item => item._id === updated._id);
            $scope.items[index] = updated;
            confirmCb();
            $scope.success('更新完成');
          }
          dialogData.disableConfirm = false;
        }),
        downloadFile: $async(async (file) => {
          const res = await Restangular.service('files').one(_.get(file, 'file._id', file.file)).customGET();
          if (res.error) {
            $scope.alert(res.error);
          } else {
            ywUtil.downloadFile(res.url, file.name);
          }
        })
      };
      const handleConfirmDisabled = (data) => {
        dialogData.disableConfirm = _.isEmpty(data.contractFiles) && !data.contractNote;
      };
      await confirmDialog.openConfirm(dialogData);
    });

    $scope.confirmContract = $async(async (quotation) => {
      if (!_.get(quotation, 'paperContracts.length')) {
        updateContract(quotation, (data) => Quotation.one(quotation._id).customPOST(data, 'confirm'));
      }
    });

    $scope.updateContractFiles = $async(async (quotation) => {
      updateContract(quotation, Quotation.one(quotation._id).customPUT);
    });

    $scope.cellActions = {
      deductReferralFee: (quotation) => {
        const dialogData = {
          title: '轉介費扣除項',
          templateUrl: '/view/dialog/deduct-referral-fee-dialog.html',
          confirmValue: _.cloneDeep(quotation.referralFeeDeduction) || [],
          confirmWhenResolve,
          addItem,
          removeItem
        };
        confirmDialog.openConfirm(dialogData).then(() => {
          Quotation.one(quotation._id).customPOST({ referralFeeDeduction: dialogData.confirmValue }, 'deductReferralFee').then(res => {
            if (res.error) {
              $scope.alert(res.error);
            } else {
              const index = _.findIndex($scope.items, item => item._id === quotation._id);
              const newQuotation = Quotation.one(quotation._id);
              Object.keys(res.plain()).forEach(key => { newQuotation[key] = res[key]; });
              $scope.items[index] = newQuotation.clone();
              $scope.success('更新成功');
            }
          });
        });

        function confirmWhenResolve (items, dialogData, confirmCb) {
          if (items?.length === 0) confirmCb(items);
          const requiredMissing = items.some(item => !item.item);
          if (requiredMissing) {
            $scope.alert('項目名稱必填');
            return;
          }

          const amountValid = items.some(item => !_.isNumber(item.amount) || item.amount < 1);
          if (amountValid) {
            $scope.alert('金額須為正整數');
            return;
          }

          confirmCb(items);
        }
        function addItem () {
          dialogData.confirmValue.push({});
        }
        function removeItem (index) {
          dialogData.confirmValue.splice(index, 1);
        }
      }
    };
  }];
