export default {
  bindings: {
    labels: '<',
    onAdd: '<',
    onRemove: '<',
    onEditColor: '<'
  },
  template: `
    <table class="table">
      <tr>
        <th>標籤名稱</th>
        <th>標籤顏色</th>
        <th>刪除</th>
      </tr>
      <tr ng-repeat="label in $ctrl.labels track by $index">
        <td><input class="yw-input" ng-model="label.name"/></td>
        <td>
          <span
            class="color-preview-btn ptr"
            ng-style="{'background-color': label.color}"
            ng-click="$ctrl.onEditColor(label)"
          ></span>
        </td>
        <td>
          <span
            class="glyphicon glyphicon-remove btn-minus ptr"
            ng-click="$ctrl.onRemove($index)"
          ></span>
        </td>
      </tr>
    </table>
    <span
      class="glyphicon glyphicon-plus btn-plus ptr"
      ng-click="$ctrl.onAdd()"
    ></span>
  `,
  controller: ['$element', function reservationEnvInfo ($element) {
  }]
};
