import moment from 'moment';

const isSignedPositiveInt32 = num => {
  return _.isInteger(num) && _.gte(num, 0) && _.lt(num, Math.pow(2, 32) - 1);
};

export default ['$scope', '$async', 'Upload', 'API_URL', 'currentUser', 'Restangular', 'Service', 'Vender', 'ServiceClassification',
  function controller ($scope, $async, Upload, API_URL, currentUser, Restangular, Service, Vender, ServiceClassification) {
    const self = this;

    self.iconFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    Restangular.service('systemSettings').getList({ key: 'sourceRestrictionSetting' })
      .then(res => {
        if (!res.error) {
          $scope.sourceRestrictionInput.options = _.get(res, '[0].value', []);
        }
      });

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      if (!item.subjectName) {
        return $scope.alert('請輸入活動主題');
      }
      if (!item.couponTitle) {
        return $scope.alert('請輸入優惠名稱');
      }

      const getMaxUserNumTitle = _.cond([
        [type => type === 'totalUsed', () => '總使用次數'],
        [type => type === 'acquire', () => '總歸戶人數'],
        [_.stubTrue, () => '']
      ]);
      if (!_.isNumber(item.couponAvailableMaxUserNum)) {
        return $scope.alert(`請輸入${getMaxUserNumTitle(item.couponAvailableMaxUserType)}`);
      } else if (!isSignedPositiveInt32(item.couponAvailableMaxUserNum)) {
        return $scope.alert(
          `${getMaxUserNumTitle(item.couponAvailableMaxUserType)}須為小於2^31 - 1的整數`
        );
      }

      const getDiscountValueTitle = _.cond([
        [type => type === 'number', () => '折扣金額'],
        [type => type === 'percentage', () => '折扣後成數'],
        [_.stubTrue, () => '']
      ]);
      if (!_.isNumber(item._couponDiscountValue)) {
        const title = getDiscountValueTitle(item.couponDiscountValueType);
        return $scope.alert(`請輸入${title}`);
      } else if (!isSignedPositiveInt32(item._couponDiscountValue)) {
        const title = getDiscountValueTitle(item.couponDiscountValueType);
        return $scope.alert(`${title}須為小於2^31 - 1的整數`);
      }

      if (!_.isInteger(item.couponAvailableMax) || item.couponAvailableMax < 0) {
        return $scope.alert('每人限用次數須為正整數');
      }

      if (item.couponDiscountValueType === 'percentage') {
        if (!_.isInteger(item._couponDiscountValue) ||
            _.lt(item._couponDiscountValue, 0) ||
            _.gt(item._couponDiscountValue, 99)
        ) {
          return $scope.alert('折扣後成數須為0-99間的整數');
        }
        if (_.isNumber(item.discountValueUpperThreshold) &&
            !isSignedPositiveInt32(item.discountValueUpperThreshold)
        ) {
          return $scope.alert('折扣金額上限須為小於2^31 - 1的整數');
        }
      }

      if (_.isNumber(item.couponConditionValue) && !isSignedPositiveInt32(item.couponConditionValue)) {
        return $scope.alert('使用低消金額須為小於2^31 - 1的整數');
      }

      if (_.isNumber(item.couponEffectiveDaysLimit) &&
          !isSignedPositiveInt32(item.couponEffectiveDaysLimit)
      ) {
        return $scope.alert('歸戶限定使用天數須為小於2^31 - 1的整數');
      }

      if (!item.couponEffectiveDateBegin || !item.couponEffectiveDateEnd) {
        return $scope.alert('請選擇活動期間');
      }
      if (!item.couponAcquireDateBegin || !item.couponAcquireDateEnd) {
        return $scope.alert('請選擇歸戶期間');
      }
      if (!item.effectiveDateIncludeNormalDay && !item.effectiveDateIncludeHoliday) {
        return $scope.alert('平日、國定假日請至少擇一');
      }
      if (item._notificationIsAppPub && !item._notificationPushContent) {
        return $scope.alert('請輸入推播文案');
      }
      if (item._notificationIsEmail && !item._notificationEmailTitle) {
        return $scope.alert('請輸入email title');
      }
      if (item._notificationIsEmail && !item._notificationEmailContent) {
        return $scope.alert('請輸入email內文');
      }
      if (item._notificationIsSms && !item._notificationSmsContent) {
        return $scope.alert('請輸入簡訊文案');
      }

      if (item.displayDiscountValueInfo.setting === 'custom' && !item.displayDiscountValueInfo.customWord) {
        return $scope.alert('請輸入自訂價格折數顯示文字');
      }

      console.log('currentActionItem: ', item);
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;

      item.couponDiscountValue = -Math.abs(item._couponDiscountValue);

      $scope.effectiveDateCheckboxes.forEach(
        checkbox => _.set(item, checkbox.index, !!checkbox.checked)
      );

      item.notificationMethod = {
        isAppPub: $scope.notificationTypesCheckboxes.find(checkbox => checkbox.index === 'isAppPub').checked,
        pushContent: item._notificationPushContent || '',
        isEmail: $scope.notificationTypesCheckboxes.find(checkbox => checkbox.index === 'isEmail').checked,
        emailTitle: item._notificationEmailTitle,
        emailContent: item._notificationEmailContent,
        isSms: $scope.notificationTypesCheckboxes.find(checkbox => checkbox.index === 'isSms').checked,
        smsContent: item._notificationSmsContent
      };

      item.couponAvailableMax = item._couponAvailableMax;

      if (item.couponDiscountValueType === 'percentage') {
        item.discountValueUpperThreshold = item._discountValueUpperThreshold || 0;
      }
      item.couponConditionValue = item._couponConditionValue || 0;
      item.couponEffectiveDaysLimit = item._couponEffectiveDaysLimit || 0;

      item.remind = {
        toUser: item._remindToUser || '',
        toVender: item._remindToVender || ''
      };

      if (item.displayDiscountValueInfo.setting !== 'custom') {
        item.displayDiscountValueInfo.customWord = '';
      }

      item.biReport = {
        otherDeptCost: item._biReportOtherDeptCost || 0,
        approvalNumOfInvoice: item._biReportApprovalNumOfInvoice || '',
        isVenderPayCouponCost: $scope.isVenderPayCouponCostCheckbox.checked || false
      };

      console.log('transform finished: ', item);
    }

    function syncCurrentActionItem () {
      const item = $scope.currentActionItem;

      if ($scope.currentPageState === 'add') {
        item.couponEffectiveDateBegin = moment().startOf('day');
        item.couponAcquireDateBegin = moment().startOf('day');
        item.couponTargetClassifications = [];
        item.couponTargetServices = [];
        item.priorityDispatchVenders = [];
      } else if ($scope.currentPageState === 'edit') {
        item.couponTitle = _.get(item, 'couponDefault.title');
        item.sourceRestrictions = _.get(item, 'couponDefault.sourceRestrictions', []);
        item.couponAvailableMaxUserType = _.get(item, 'couponDefault.available.maxUserType');
        item.couponAvailableMaxUserNum = _.get(item, 'couponDefault.available.maxUserNum');
        item._couponAvailableMax = _.get(item, 'couponDefault.available.max');
        item.couponDiscountValueType = _.get(item, 'couponDefault.discountValueType');
        item._couponDiscountValue = Math.abs(_.get(item, 'couponDefault.discountValue', 0));
        item._couponConditionValue = _.get(item, 'couponDefault.conditionValue') || '';
        item._discountValueUpperThreshold = _.get(item, 'couponDefault.discountValueUpperThreshold');
        item.couponEffectiveDateBegin = _.get(item, 'couponDefault.effectiveDate.begin');
        item.couponEffectiveDateEnd = _.get(item, 'couponDefault.effectiveDate.end');
        item.couponAcquireDateBegin = _.get(item, 'couponDefault.acquireDate.begin');
        item.couponAcquireDateEnd = _.get(item, 'couponDefault.acquireDate.end');
        item.effectiveDateIncludeNormalDay = _.get(item, 'couponDefault.effectiveDate.isIncludeNormalDay');
        item.effectiveDateIncludeHoliday = _.get(item, 'couponDefault.effectiveDate.isIncludeHoliday');
        item._couponEffectiveDaysLimit = _.get(item, 'couponDefault.effectiveDaysLimit') || '';
        item.isFirstOrder = _.get(item, 'couponDefault.isFirstOrder');
        item._notificationIsAppPub = _.get(item, 'couponDefault.notification.info.isAppPub', false);
        item._notificationPushContent = _.get(item, 'couponDefault.notification.info.pushContent', '');
        item._notificationIsEmail = _.get(item, 'couponDefault.notification.info.isEmail', false);
        item._notificationEmailTitle = _.get(item, 'couponDefault.notification.info.emailTitle', '');
        item._notificationEmailContent = _.get(item, 'couponDefault.notification.info.emailContent', '');
        item._notificationIsSms = _.get(item, 'couponDefault.notification.info.isSms', false);
        item._notificationSmsContent = _.get(item, 'couponDefault.notification.info.smsContent', '');
        item.couponTargetServices = _.get(item, 'couponDefault.targetServices', []);
        item.couponTargetClassifications = _.get(item, 'couponDefault.targetClassifications', []);
        item.depiction = _.get(item, 'couponDefault.depiction');
        item.isForSinyiUsed = _.get(item, 'couponDefault.isForSinyiUsed');
        item.isForSinyiHideCode = _.get(item, 'couponDefault.isForSinyiHideCode');
        item._remindToUser = _.get(item, 'couponDefault.remind.toUser');
        item._remindToVender = _.get(item, 'couponDefault.remind.toVender');
        item.navData = _.get(item, 'couponDefault.navData');
        item.priorityDispatchVenders = _.get(item, 'couponDefault.priorityDispatchVenders', []).map(vender => vender._id);
        item.isCompatibleWithServiceDiscount = _.get(item, 'couponDefault.isCompatibleWithServiceDiscount', false);
        item.isCompatibleWithCustomizations = _.get(item, 'couponDefault.isCompatibleWithCustomizations', false);
        item.displayDiscountValueInfo = _.get(item, 'couponDefault.displayDiscountValueInfo', { setting: 'defalut' });

        // 信義
        item._iconUrl = _.get(item, 'couponDefault.icon');
        item.icon = _.get(item, '_iconUrl._id');
        item.link = _.get(item, 'couponDefault.link');
        item.venderName = _.get(item, 'couponDefault.venderName');

        // 報表
        item._biReportOtherDeptCost = _.get(item, 'couponDefault.biReport.otherDeptCost');
        item._biReportApprovalNumOfInvoice = _.get(item, 'couponDefault.biReport.approvalNumOfInvoice');
        item._biReportIsVenderPayCouponCost = _.get(item, 'couponDefault.biReport.isVenderPayCouponCost');
      }

      $scope.sourceRestrictionInput = {
        label: '可使用對象',
        // type: 'multi-selection-dropdown',
        type: 'multi-selection-dropdown',
        index: 'sourceRestrictions',
        selectedList: item.sourceRestrictions,
        changed: (value) => _.set(item, 'sourceRestrictions', value, []),
        options: [],
        blankItem: '請選擇',
        info: '未選擇時，不限定可使用對象'
      };

      const getMaxUserNumTitle = _.cond([
        [type => type === 'totalUsed', () => '總使用次數*'],
        [type => type === 'acquire', () => '總歸戶人數*'],
        [_.stubTrue, () => '']
      ]);
      $scope.couponAvailableMaxUserNumInput = {
        label: getMaxUserNumTitle(item.couponAvailableMaxUserType),
        index: 'couponAvailableMaxUserNum',
        type: 'number'
      };

      const getDiscountValueTitle = _.cond([
        [type => type === 'number', () => '折扣金額*'],
        [type => type === 'percentage', () => '折扣後成數*'],
        [_.stubTrue, () => '']
      ]);
      const getDiscountValuePrefix = _.cond([
        [type => type === 'number', () => ''],
        [type => type === 'percentage', () => '原價'],
        [_.stubTrue, () => '']
      ]);
      const getDiscountValueDescribe = _.cond([
        [type => type === 'number', () => ''],
        [type => type === 'percentage', () => '%'],
        [_.stubTrue, () => '']
      ]);
      const getDiscountValueInfo = _.cond([
        [type => type === 'number', () => ''],
        [type => type === 'percentage', () => '輸入「88」時，表示打88折; 輸入「90」時，表示打九折; 全部折抵輸入「0」'],
        [_.stubTrue, () => '']
      ]);
      $scope.couponDiscountValueInput = {
        label: getDiscountValueTitle(item.couponDiscountValueType),
        index: '_couponDiscountValue',
        type: 'number',
        prefix: getDiscountValuePrefix(item.couponDiscountValueType),
        describe: getDiscountValueDescribe(item.couponDiscountValueType),
        info: getDiscountValueInfo(item.couponDiscountValueType)
      };
      $scope.discountValueUpperThresholdInput = {
        label: '折扣金額上限',
        index: '_discountValueUpperThreshold',
        type: 'number',
        showIndex: item.couponDiscountValueType === 'percentage',
        info: '未輸入內容時，不設折扣金額上限'
      };

      $scope.effectiveDateCheckboxes = [
        {
          value: false,
          label: '平日',
          index: 'effectiveDateIncludeNormalDay',
          checked: item.effectiveDateIncludeNormalDay
        },
        {
          value: false,
          label: '國定假日',
          index: 'effectiveDateIncludeHoliday',
          checked: item.effectiveDateIncludeHoliday
        }
      ];

      $scope.notificationTypesCheckboxes = [
        {
          value: false,
          label: 'app推播',
          index: 'isAppPub',
          checked: item._notificationIsAppPub
        },
        {
          value: false,
          label: 'email',
          index: 'isEmail',
          checked: item._notificationIsEmail
        },
        {
          value: false,
          label: '簡訊',
          index: 'isSms',
          checked: item._notificationIsSms
        }
      ];

      $scope.notificationPushContentInput = {
        label: '推播文案',
        type: 'textarea',
        index: '_notificationPushContent',
        showIndex: !!_.get(item, '_notificationIsAppPub'),
        maxlength: 200
      };

      $scope.notificationEmailTitleInput = {
        label: 'email title',
        type: 'text',
        class: 'long',
        index: '_notificationEmailTitle',
        showIndex: !!_.get(item, '_notificationIsEmail'),
        maxlength: 200
      };
      $scope.notificationEmailContentInput = {
        label: 'email內文',
        type: 'textarea',
        index: '_notificationEmailContent',
        showIndex: !!_.get(item, '_notificationIsEmail')
      };

      $scope.notificationSmsContentInput = {
        label: '簡訊文案',
        type: 'textarea',
        index: '_notificationSmsContent',
        showIndex: !!_.get(item, '_notificationIsSms'),
        showLength: true,
        info: `
          <div>扣點規則如下，請參考~</div>
          <div>1.訊息內容為長度超過160個字的純半形英數字，或內容包含中文且長度超過70個字時，將以長簡訊發送；反之則為短簡訊。</div>
          <div>2.【國內長簡訊】若簡訊內容為純半形英數字，每153個字需一點；包含任一中文字，則每67個字需一點。</div>
          <div>3.【國內短簡訊】每一則需一點。</div>
          <div>4.【國際簡訊】為上述國內訊息的三倍計費。</div>
        `
      };

      $scope.isVenderPayCouponCostCheckbox = {
        value: false,
        label: '廠商自付優惠成本',
        index: '_biReportIsVenderPayCouponCost',
        checked: item._biReportIsVenderPayCouponCost
      };

      $scope.inputItems = [
        {
          label: '活動主題*',
          type: 'text',
          index: 'subjectName',
          maxlength: 20,
          info: '最多輸入20字'
        },
        {
          label: '優惠名稱*',
          type: 'text',
          index: 'couponTitle',
          info: '此為用戶所見的優惠券名稱<br/>最多輸入20字',
          maxlength: 20
        },
        $scope.sourceRestrictionInput,
        {
          label: '使用上限規則',
          type: 'radio',
          index: 'couponAvailableMaxUserType',
          radios: [
            { value: 'totalUsed', label: '總使用次數' },
            { value: 'acquire', label: '總歸戶人數' }
          ],
          changed: (type) => {
            $scope.couponAvailableMaxUserNumInput.label = getMaxUserNumTitle(type);
          }
        },
        $scope.couponAvailableMaxUserNumInput,
        {
          label: '每人限用次數*',
          index: '_couponAvailableMax',
          type: 'number',
          info: '必填，設定999(含)以上，用戶端會顯示為不限'
        },
        {
          label: '折扣方式',
          type: 'radio',
          index: 'couponDiscountValueType',
          radios: [
            { value: 'number', label: '固定金額折抵' },
            { value: 'percentage', label: '百分比折抵', disabled: true }
          ],
          blankItem: '請選擇',
          changed: (type) => {
            $scope.couponDiscountValueInput.label = getDiscountValueTitle(type);
            $scope.couponDiscountValueInput.prefix = getDiscountValuePrefix(type);
            $scope.couponDiscountValueInput.describe = getDiscountValueDescribe(type);
            $scope.couponDiscountValueInput.info = getDiscountValueInfo(type);
            $scope.discountValueUpperThresholdInput.showIndex = type === 'percentage';
          }
        },
        $scope.couponDiscountValueInput,
        $scope.discountValueUpperThresholdInput,
        {
          label: '使用低消金額',
          type: 'number',
          index: '_couponConditionValue',
          info: '未輸入內容時，不設使用低消金額'
        },
        {
          label: '可與方案優惠併用',
          type: 'switch',
          index: 'isCompatibleWithServiceDiscount',
          info: '開啟時該張優惠券可與服務方案設定的優惠價格一起併用'
        },
        {
          label: '可抵用客製化項目',
          type: 'switch',
          index: 'isCompatibleWithCustomizations',
          info: `
            設定關閉時:</br>
            固定金額優惠券>僅可折抵至服務方案的價格上限，不含客製化選項的加價的折抵</br>
            百分比優惠券>僅以服務方案的價格進行百分比折抵計算，不納入客製化加價項目計算</br>
            </br>
            設定開啟時:</br>
            固定金額優惠券>折抵上限可至服務方案+客製化選項加價的總訂單金額</br>
            百分比優惠券>依照服務方案+客製化選項加價的總額進行百分比折抵計算</br>
          `
        },
        {
          label: '使用期間*',
          type: 'date-picker-duration',
          startIndex: 'couponEffectiveDateBegin',
          endIndex: 'couponEffectiveDateEnd'
        },
        {
          label: ' ',
          type: 'checkbox',
          index: '_effectiveDate',
          checkboxes: $scope.effectiveDateCheckboxes
        },
        {
          label: '歸戶期間*',
          type: 'date-picker-duration',
          startIndex: 'couponAcquireDateBegin',
          endIndex: 'couponAcquireDateEnd'
        },
        {
          label: '歸戶限定使用天數',
          type: 'number',
          index: '_couponEffectiveDaysLimit',
          info: '可使用天數在歸戶後開始倒數。<br/>若可使用天數超過活動期限，則以「歸戶限定使用天數」的倒數終點為最終期限。'
        },
        {
          label: '限定抵用分類',
          type: 'multi-searchable-obj-picker',
          index: 'couponTargetClassifications',
          title: '限定抵用分類',
          resource: ServiceClassification,
          searchField: 'classificationName',
          titleField: 'name',
          closeCityFilter: true,
          showSelectedItemsCount: true,
          queryParams: { limit: 200 }
        },
        {
          label: '限定抵用方案',
          type: 'multi-searchable-obj-picker',
          index: 'couponTargetServices',
          title: '限定抵用方案',
          resource: Service,
          filterField: 'name',
          titleField: 'name',
          subTitleField: 'classification.name',
          closeCityFilter: true,
          showSelectedItemsCount: true,
          queryParams: { limit: 200 }
        },
        { label: '限首單使用', type: 'switch', index: 'isFirstOrder' },
        {
          label: '到期通知方式',
          type: 'checkbox',
          index: '_notificationTypes',
          checkboxes: $scope.notificationTypesCheckboxes,
          checked: (bool, option) => {
            if (option.index === 'isAppPub') {
              $scope.notificationPushContentInput.showIndex = !!bool;
            } else if (option.index === 'isEmail') {
              $scope.notificationEmailTitleInput.showIndex = !!bool;
              $scope.notificationEmailContentInput.showIndex = !!bool;
            } else if (option.index === 'isSms') {
              $scope.notificationSmsContentInput.showIndex = !!bool;
            }
          },
          info: `
            <div>將於到期日15天前的早上九點進行推播。</div>
            <div>帶入變數名稱使用如下：</div>
            <div>客戶名稱: $name$</div>
            <div>優惠主題: $couponSubject$</div>
            <div>優惠名稱: $couponTitle$</div>
          `
        },
        $scope.notificationPushContentInput,
        $scope.notificationEmailTitleInput,
        $scope.notificationEmailContentInput,
        $scope.notificationSmsContentInput,
        {
          label: '提醒用戶注意事項',
          type: 'textarea',
          index: '_remindToUser',
          maxlength: 200,
          info: '支援換行顯示'
        },
        {
          label: '提醒廠商注意事項',
          type: 'textarea',
          index: '_remindToVender',
          maxlength: 200,
          info: '支援換行顯示'
        },
        {
          label: '頁面跳轉',
          type: 'navigation-setting',
          index: 'navData',
          buttonLabel: '頁面跳轉',
          info: '用戶歸戶優惠券時，點擊"前往預約"按鈕依照設定跳轉，沒設定時則跳轉首頁'
        },
        {
          label: '優先指派廠商',
          type: 'multi-searchable-obj-picker',
          index: 'priorityDispatchVenders',
          title: '優先指派廠商',
          resource: Vender,
          filterField: 'name',
          titleField: 'name',
          closeCityFilter: true,
          showSelectedItemsCount: true,
          info: `
            <div>優先指派廠商邏輯</div>
            <div>1. 用戶下單後，如果有使用優惠券，該優惠券有設定優先指派廠商，且廠商有符合指派條件下，則在優先指派廠商內，以目前的自動派單邏輯輪替指派，此時不會指派到非優先指派廠商身上</div>
            <div>2. 優先指派廠商皆不符指派條件，則以目前原派單邏輯，進行符合條件之廠商輪派</div>
          `
        },
        {
          label: '自訂價格折數顯示',
          type: 'coupon-display-info',
          index: 'displayDiscountValueInfo',
          labelInfo: `
            <div>為用戶優惠券歸戶後，所看到的綠色文字區塊處顯示文案設定</div>
            <div>系統預設: 例. 固定金額折抵設定100,顯示100元 ; 百分比折扣設定90,顯示9折 ; 百分比折扣設定88,顯示88折 ; 百分比折扣設定0或100/固定金額設定0,皆不顯示</div>
            <div>自訂: 覆蓋目前系統預設顯示文字，限定字數5字</div>
          `
        },
        { type: 'empty' },
        { type: 'underline' },
        { label: '信義連動參數' },
        {
          label: 'icon',
          type: 'single-file',
          index: '_iconUrl',
          originIndex: 'icon',
          class: 'images-wrapper',
          files: $scope.iconFiles,
          success: $scope.singleFileUploaded,
          error: $scope.alert
        },
        { label: '連結', type: 'text', index: 'link', class: 'long' },
        { label: '異業合作名稱', type: 'text', index: 'venderName' },
        { label: '描述', type: 'textarea', index: 'depiction', maxlength: 1000 },
        {
          label: '信義用',
          type: 'switch',
          index: 'isForSinyiUsed',
          info: '用於判斷是否要通知信義「優惠券已被使用」'
        },
        {
          label: '隱藏優惠碼',
          type: 'switch',
          index: 'isForSinyiHideCode',
          info: '券用於信義，是否要隱藏優惠碼'
        },
        { type: 'underline' },
        { label: '報表計算使用欄位' },
        {
          label: '其他部門負責成本',
          type: 'number',
          index: '_biReportOtherDeptCost',
          info: '紀錄成本分攤使用，輸入金額數字，報價會依 折扣金額-其他部門負責成本去推算"居家負責成本"'
        },
        { label: '請款使用簽呈單號', type: 'text', index: '_biReportApprovalNumOfInvoice', class: 'long' },
        {
          label: ' ',
          type: 'checkbox',
          index: '_notificationTypes',
          checkboxes: [$scope.isVenderPayCouponCostCheckbox],
          info: `
            <div>部門與居家成本分攤使用計算時，不計入</div>
          `
        },
        { type: 'action' }
      ];
    }
  }];
