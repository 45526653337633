import { inputLimit } from '../../../share/input-limit.js';

export default ['$scope', 'LayoutModule', 'layoutModuleUtils', 'confirmDialog',
  function controller ($scope, LayoutModule, layoutModuleUtils, confirmDialog) {
    const self = this;
    self.imageFiles = {};
    $scope.dragContents = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);
      if (!item.name) {
        return $scope.alert('模組名稱為必填');
      }
      if (_.isEmpty(item.graphicNModule?.pages)) {
        return $scope.alert('請至少建立一組頁籤內容');
      }
      if (!_.isEmpty(item.graphicNModule?.pages)) {
        if (item.graphicNModule?.pages?.some(page => !page?.title)) {
          return $scope.alert('頁籤標題皆為必填');
        }
        if (item.graphicNModule?.pages?.some(page => page?.contents?.some(content => !content?.image))) {
          return $scope.alert('需上傳頁籤內容所有桌機版圖片');
        }
        if (item.graphicNModule?.pages?.some(page => page?.contents?.some(content => !content?.mobileImage))) {
          return $scope.alert('需上傳頁籤內容所有手機版圖片');
        }
      }
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      const pagesData = [];
      item.pages.forEach((page) => {
        const pageContentData = [];
        for (let i = 2; i < page.length; i += 7) {
          pageContentData.push(page.slice(i, i + 7));
        }
        const contents = pageContentData.map(data => {
          return {
            image: data[1][data[1].originIndex],
            mobileImage: data[2][data[2].originIndex],
            title: data[3][data[3].index],
            priceDescription: data[4][data[4].index],
            link: data[5][data[5].index],
            tagWord: data[6][data[6].index]
          };
        });
        pagesData.push({
          title: page[0].title,
          contents: contents
        });
      });
      item.graphicNModule = {
        paragraphTitle: item.paragraphTitle,
        pages: pagesData
      };
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();
        item.isVisible = layoutModule?.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item.paragraphTitle = layoutModule.graphicNModule?.paragraphTitle || '';
        item[`${item.type}Module`] = layoutModule[`${item.type}Module`];
      }

      $scope.inputItems = getModuleAction(item, $scope);
    }

    function setOrder () {
      $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex][1]['content-order'] = $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].slice(2).filter((item, index) => (index - 3) % 7 === 0);
      let contentOrder = $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex][1]['content-order'];
      contentOrder = contentOrder.map((item, index) => {
        item._title = `內容${index + 1} ${item[item.index] ? item[item.index] : ''}`;
        return item;
      });

      const dialogData = {
        title: '調整內容順序',
        templateUrl: '/view/dialog/module-item-order-dialog.html',
        hideCancel: true,
        confirmValue: contentOrder,
        itemMoved: (srcList, srcIndex, targetIndex, dragContent) => {
          srcList.splice(srcIndex, 1);
          srcList.splice(targetIndex, 0, dragContent);

          const dragItem = $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].slice(2 + srcIndex * 7, 9 + srcIndex * 7);
          $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].splice(2 + srcIndex * 7, 7);
          $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].splice(2 + targetIndex * 7, 0, ...dragItem);
        }
      };

      confirmDialog.openAlert(dialogData).then(() => {
        $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex] = $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].map((item, index) => {
          if ((index - 2) % 7 === 0) {
            return {
              ...item,
              titleLabel: `內容${(index - 2) / 7 + 1}`
            };
          } else {
            return item;
          }
        });
      });
    }

    const getModuleAction = (currentActionItem, $scope) => {
      currentActionItem.visibleItemIndex = 0;
      currentActionItem.logListEvent = (index) => {
        currentActionItem.newIndex = index;
      };

      const defaultContent = [ // 有新增刪除項目記得更新各func裡面用到的index
        {
          titleLabel: '*頁籤標題：',
          type: 'module-text',
          index: 'title',
          title: '預設標題',
          remark: '字數限制：256字(多個頁籤時顯示，建議8字內，字數寬度超過360px會隱藏)',
          maxlength: inputLimit.default
        },
        {
          titleLabel: '調整內容順序',
          type: 'module-btn',
          index: 'content-order',
          onBtnClick: setOrder,
          btnText: '設定'
        }
      ];

      const graphicContent = (itemNumber) => { // 有新增刪除項目記得更新各func裡面用到的index
        const index = _.isNumber(itemNumber) ? itemNumber : ($scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].length - 2) / 7 + 1;
        return (
          [
            { titleLabel: `內容${index}`, withDelBtn: true, subItemDeleted },
            {
              label: '*桌機版圖片：',
              remark: '限制 1 MB，建議尺寸如下：\n1則圖片比例：4:1， 寬2400 x 高600\n2則圖片比例：2:1， 寬1200 x 高600\n3則圖片比例：3:2， 寬900 x 高600\n4則以上圖片比例：1:1，寬600 x 高600',
              type: 'module-file',
              index: `_imageUrl${itemNumber}`,
              originIndex: `image${itemNumber}`,
              class: 'images-wrapper',
              files: $scope.imageFiles,
              success: (result, item) => {
                console.log('moderator imageUploaded: ', item);
                item[`_imageUrl${itemNumber}`] = result;
                item[`image${itemNumber}`] = result._id;
              },
              error: $scope.alert,
              validate: { limitSize: 1000000 }
            },
            {
              label: '*手機版圖片：',
              remark: '限制 1 MB，建議尺寸如下：\n1則圖片比例：2:1， 寬1200 x 高600\n2則以上圖片比例：1:1， 寬600 x 高600',
              type: 'module-file',
              index: `_mobileImageUrl${itemNumber}`,
              originIndex: `mobileImage${itemNumber}`,
              class: 'images-wrapper',
              files: $scope.imageFiles,
              success: (result, item) => {
                console.log('moderator mobileImageUploaded: ', item);
                item[`_mobileImageUrl${itemNumber}`] = result;
                item[`mobileImage${itemNumber}`] = result._id;
              },
              error: $scope.alert,
              validate: { limitSize: 1000000 }
            },
            {
              label: '標題：',
              type: 'module-text',
              index: `title${itemNumber}`,
              remark: '字數限制：256字(圖片的 title 、 alt 同此標題)              ',
              maxlength: inputLimit.default
            },
            {
              label: '價格描述：',
              type: 'module-text',
              index: `priceDescription${itemNumber}`,
              remark: '字數限制：256字(前台最多顯示一行)',
              maxlength: inputLimit.default
            },
            {
              label: '連結：',
              type: 'module-link',
              index: `link${itemNumber}`
            },
            {
              label: '標籤文字：',
              type: 'module-text',
              index: `tagWord${itemNumber}`,
              remark: '字數限制：8字(未填則不顯示)',
              maxlength: 8
            }
          ]
        );
      };

      function subItemDeleted (index) {
        if (index === 2 && $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].length <= 9) return $scope.alert('最少需要有一筆內容');
        $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].splice(index, 7);
        $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex] = $scope.currentActionItem.pages[$scope.currentActionItem.visibleItemIndex].map((item, index) => {
          if ((index - 2) % 7 === 0) {
            return {
              ...item,
              titleLabel: `內容${(index - 2) / 7 + 1}`
            };
          } else {
            return item;
          }
        });
      }

      const graphicContents = graphicContent(1);

      $scope.dragContents = [...defaultContent, ...graphicContents];

      if (currentActionItem.graphicNModule) {
        currentActionItem.pages = [];
        for (let i = 0; i < currentActionItem.graphicNModule?.pages?.length; i++) {
          const moduleContent = currentActionItem.graphicNModule?.pages[i];
          const pageDragContents = _.cloneDeep($scope.dragContents);
          pageDragContents[0].title = moduleContent.title;
          moduleContent.contents.forEach((content, index) => {
            if (index > 0) {
              pageDragContents.push(...graphicContent(index + 1));
            }
            _.forIn(content, (value, key) => {
              if (key === 'image') {
                const result = _.find(pageDragContents, function (item) {
                  if (item.originIndex === `${key}${index + 1}`) {
                    return true;
                  }
                });
                if (result) {
                  result[`_imageUrl${index + 1}`] = value;
                  result[`image${index + 1}`] = value._id;
                }
              } else if (key === 'mobileImage') {
                const result = _.find(pageDragContents, function (item) {
                  if (item.originIndex === `${key}${index + 1}`) {
                    return true;
                  }
                });
                if (result) {
                  result[`_mobileImageUrl${index + 1}`] = value;
                  result[`mobileImage${index + 1}`] = value._id;
                }
              } else {
                const result = _.find(pageDragContents, function (item) {
                  if (item.index === `${key}${index + 1}`) {
                    return true;
                  }
                });
                if (result) result[`${key}${index + 1}`] = value;
              }
            });
          });
          currentActionItem.pages.push(_.cloneDeep(pageDragContents));
        }
      } else {
        currentActionItem.pages = [_.cloneDeep($scope.dragContents)];
      }

      const dragModules = layoutModuleUtils.getDragModules($scope, 'pages');

      return (
        [
          { label: '模組類型：', type: 'description', description: currentActionItem.label },
          { label: '*模組名稱：', type: 'text', index: 'name', remark: '不顯示於前台' },
          {
            label: '顯示設定',
            type: 'radio',
            index: 'isVisible',
            radios: [
              { value: true, label: '顯示' },
              { value: false, label: '隱藏' }
            ]
          },
          { type: 'underline' },
          { label: '段落標題：', type: 'text', index: 'paragraphTitle', remark: 'H1、字數限制：256字(未填則不顯示，建議8字內)', maxlength: inputLimit.default },
          {
            label: '頁籤'
          },
          {
            type: 'drag-module',
            module: 'graphicFix',
            subType: 'graphicDragModule-wrapper',
            index: 'pages',
            itemDropped: dragModules.itemDropped,
            itemRemoved: dragModules.itemRemoved,
            itemAdded: dragModules.itemAdded,
            itemVisible: dragModules.itemVisible,
            itemDragend: dragModules.itemDragend,
            subItemAdded: () => {
              const newItem = graphicContent(_.uniqueId('_')); // 為避免拖曳後參數重複，新生成的資料以"_"做區別
              currentActionItem.pages[currentActionItem.visibleItemIndex].push(...newItem);
            }
          },
          { type: 'action' }
        ]
      );
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
