export default ['$scope', '$async', '$state', 'ywUtil', 'Article', 'ArticleClassification', 'WEBAPP_URL', 'API_URL', 'currentUser', 'articleUtil',
  function controller ($scope, $async, $state, ywUtil, Article, ArticleClassification, WEBAPP_URL, API_URL, currentUser, articleUtil) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '文章標題', placeholder: '請輸入文章標題', params: 'title' },
          { btnName: '文章摘要', placeholder: '請輸入文章摘要', params: 'summary' },
          { btnName: '文章出處', placeholder: '請輸入文章出處', params: 'from' },
          { btnName: '原文作者', placeholder: '請輸入原文作者', params: 'byline' },
          { btnName: '信義原廠商ID', placeholder: '請輸入信義原廠商ID', params: 'sinyiOriginalVender' },
          { btnName: '網址辨識名稱', placeholder: '請輸入網址辨識名稱', params: 'urlId' },
          { btnName: '關聯廠商名稱', placeholder: '請輸入關聯廠商名稱', params: 'venderName' },
          { btnName: '關聯廠商ID', placeholder: '請輸入關聯廠商ID', params: 'venderId' }
        ]
      );

      $scope.filterItems = [
        {
          name: 'articleClassificationIds',
          label: '文章類別',
          options: [],
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'multiFilter',
          click: $async(async (item) => {
            const classifications = await ArticleClassification.getList({ limit: 999 });
            item.options = (classifications || []).map(classification => ({ label: classification.name, value: classification._id }));
          })
        },
        {
          name: 'isPublish',
          label: '上架',
          options: { true: '出版', false: '不出版' },
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        },
        {
          name: 'publishInterval',
          label: '上架區間',
          options: [
            { value: 'none', label: '不拘' },
            { value: 'inPublish', label: '目前上架中' },
            { value: 'publishYet', label: '尚未上架' },
            { value: 'expiry', label: '已經逾期' }
          ],
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'multiFilter'
        }
      ];

      $scope.queryPage = 'keyword'; // for GA

      $scope.mainAction.initResource(Article);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isPublish: false,
        keywords: [],
        docContents: [],
        isDisplayGotoReservation: true
      };
    }

    const getArticleUrl = (item) => `${WEBAPP_URL}article/${item.articleClassification.urlId}/${item.urlId}`;
    $scope.cellActions = {
      cloneLink: (item) => {
        ywUtil.cloneText(getArticleUrl(item));
        $scope.success('已複製');
      },
      previewArticle: (item) => {
        window.open(getArticleUrl(item) + '/preview', '_blank');
      }
    };

    const exportCsv = async (type = 'byQuery', encodeType) => {
      let params = { encodeType };
      if (type === 'byQuery') {
        const { limit, skip, ...rest } = $scope.mainAction.queryParams;
        params = { ...rest, ...params };
      } else if (type === 'bySelect') {
        const selectedItems = $scope.items.filter(item => item._selected);
        const articleIds = selectedItems.map(item => item._id);
        if (!articleIds.length) { return $scope.alert('請選擇要下載的項目'); }
        params = { ...params, articleIds };
      }
      window.location = API_URL + 'articles/exportCsv?' +
        ywUtil.convertSimpleObjToQueryString(params) +
        '&access_token=' + currentUser.getToken();
    };

    $scope.dropdownItemList = [
      {
        label: '下載',
        items: [
          { buttonListText: '文章資料(篩選)', class: 'fab fa-apple', callback: _.partial(exportCsv, 'byQuery', 'utf8') },
          { buttonListText: '文章資料(篩選)', class: 'fab fa-windows', callback: _.partial(exportCsv, 'byQuery', 'big5') },
          { buttonListText: '文章資料(勾選)', class: 'fab fa-apple', callback: _.partial(exportCsv, 'bySelect', 'utf8') },
          { buttonListText: '文章資料(勾選)', class: 'fab fa-windows', callback: _.partial(exportCsv, 'bySelect', 'big5') }
        ]
      }
    ];

    $scope.toolbarBtns = [
      {
        label: '批次設定',
        onClick: () => {
          const selectedItems = $scope.items.filter(item => item._selected);
          const selectedIds = selectedItems.map(item => item._id);
          if (!selectedItems.length) { return $scope.alert('請選擇要設定的項目'); }
          articleUtil.openBatchUpdate(selectedIds, $scope.items);
        }
      }
    ];
  }];
