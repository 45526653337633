export default ['confirmDialog', 'NgMap', 'GeoCoder',
  function directive (confirmDialog, NgMap, GeoCoder) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        coordinate: '=',
        address: '=',
        addressRequired: '=',
        disabled: '=ywDisabled',
        alert: '=ywAlert'
      },
      template: `
            <input type="text" class="input" ng-model="coordinate.latitude" style="margin-right: 10px;" placeholder="緯度" ng-disabled="disabled"/>
            <input type="text" class="input" ng-model="coordinate.longitude" placeholder="經度" ng-disabled="disabled"/>
            <md-button class="md-icon-button" ng-click="disabled || openConfirm()">
              <md-icon md-font-icon="fas fa-map-marked-alt"></md-icon>
            </md-button>
      `,
      link: link
    };

    function link (scope, element, attr) {
      scope.openConfirm = function openConfirm () {
        let markerPosition = '25.034149, 121.564462'; // default

        let address = null;
        if (scope.addressRequired) {
          if (!scope.address) return scope.alert('請先輸入地址');
          const addressObj = JSON.parse(scope.address);
          address = `${addressObj.city}${addressObj.area}${addressObj.detail}`;
          if (!addressObj.city || !addressObj.area || !addressObj.detail) {
            return scope.alert('請先輸入地址');
          }
        }

        if (!_.isEmpty(scope.coordinate) && !_.isEmpty(scope.coordinate.latitude) && !_.isEmpty(scope.coordinate.longitude)) {
          markerPosition = `${scope.coordinate.latitude}, ${scope.coordinate.longitude}`;
        } else if (address) {
          markerPosition = address;
        }

        const dialogData = {
          title: '設定經緯度',
          confirmLabel: '儲存',
          templateUrl: '/view/dialog/single-coordinate-dialog.html',
          mapParams: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAGCJZuKyaH-qXPdARSXxVzcwsuzp3peAo',
          existLandmarkIcon: { scaledSize: [32, 32], url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png' },
          coordinate: angular.copy(scope.coordinate),
          address: address,
          markerPosition: markerPosition,
          addressKeyInCb: addressKeyInCb,
          coordinateChangedCb: coordinateChangedCb,
          dragendCb: dragendCb,
          mapDragendCb: mapDragendCb,
          mapInitialized: mapInitialized
        };

        confirmDialog.openConfirm(dialogData, 'custom-width-600').then(() => {
          scope.coordinate = dialogData.coordinate;
        });

        function addressKeyInCb (e, addr) {
          if (e.keyCode !== 13) return;
          GeoCoder.geocode({ address: addr })
            .then(res => setMarker(_.get(res, '[0].geometry.location'), true));
        }

        function coordinateChangedCb (coordinate) {
          GeoCoder.geocode({ address: `${coordinate.latitude}, ${coordinate.longitude}` })
            .then(res => setMarker(_.get(res, '[0].geometry.location'), false));
        }

        function dragendCb (e) {
          setMarker(e.latLng, true);
        }

        function mapDragendCb (e) {
        }

        function setMarker (loc, resetCoordinate) {
          return NgMap.getMap().then(map => {
            dialogData.markerPosition = `${loc.lat()}, ${loc.lng()}`;
            map.setCenter(loc);
            if (resetCoordinate) { dialogData.coordinate = { longitude: loc.lng(), latitude: loc.lat() }; }
          });
        }

        function mapInitialized (map) {
          if (_.isEmpty(scope.coordiante)) {
            GeoCoder.geocode({ address })
              .then(res => _.get(res, '[0].geometry.location'))
              .then(loc => { dialogData.coordinate = { longitude: loc.lng(), latitude: loc.lat() }; });
          }
        }
      };
    }
  }];
