export default {
  bindings: {
    reservation: '<',
    displayVisible: '<'
  },
  template: `
    <i ng-if="$ctrl.isServiceAdmin" 
      class="fas pull-right ml-15"
      tooltip-class="remove-triangle"
      ng-class="{'fa-eye': $ctrl.reservation.isVisible, 'fa-eye-slash': !$ctrl.reservation.isVisible}"
      ng-click="$ctrl.onClick($ctrl.reservation, !$ctrl.reservation.isVisible)"
      uib-dropdown-toggle uib-tooltip="隱藏訂單狀態：{{$ctrl.reservation.isVisible ? '顯示' : '隱藏'}}"
    />
  `,
  controller: ['$element', '$async', 'currentUser', 'ywEvent', 'confirmDialog', 'Reservation', 'UnorderedReservation', function reservationSinyiStaffInfo ($element, $async, currentUser, ywEvent, confirmDialog, Reservation, UnorderedReservation) {
    this.onClick = $async(async (reservation, showReservation) => {
      await confirmDialog.openConfirm({
        title: '隱藏訂單',
        content: showReservation ? '是否解除訂單隱藏？' : '是否隱藏訂單？'
      });
      const res = await this._resource
        .one(reservation._id)
        .customPUT({ isVisible: showReservation }, 'isVisible');
      if (res.error) {
        ywEvent.emit('ALERT', res.error);
      } else {
        reservation.isVisible = res.isVisible;
        if ((this.displayVisible === 'visible' && !reservation.isVisible) ||
          (this.displayVisible === 'invisible' && reservation.isVisible)) {
          ywEvent.emit('REMOVE_ITEM_IN_LIST', reservation);
        } else {
          ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        }
        ywEvent.emit('SUCCESS', '已變更');
      }
    });

    this.$onInit = () => {
      this.isServiceAdmin = currentUser.hasRole('serviceAdmin');

      const mode = _.get(this.reservation, 'reservationListMode');
      this._resource = mode === 'stateUnordered' ? UnorderedReservation : Reservation;
    };
  }]
};
