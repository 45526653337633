export default ['$async', 'Restangular', function ($async, Restangular) {
  const utils = {};
  utils.getLayoutModuleTypes = $async(async ($scope) => {
    const moduleTypesData = await Restangular.one('layoutModules').customGET('moduleType');
    moduleTypesData.forEach((type) => {
      let label;
      let thumbNailUrl;
      switch (type) {
        case 'graphicFix':
          label = '01_圖文模組(固定三則)';
          thumbNailUrl = '/img/graphic_fix_thumbnail.jpg';
          break;
        case 'graphicMore':
          label = '02_圖文模組(1~N則)';
          thumbNailUrl = '/img/graphic_more_thumbnail.jpg';
          break;
        case 'publicitySmall':
          label = '03_宣傳模組(小圖)';
          thumbNailUrl = '/img/publicity_small_thumbnail.jpg';
          break;
        case 'publicityLarge':
          label = '04_宣傳模組(大圖)';
          thumbNailUrl = '/img/publicity_large_thumbnail.jpg';
          break;
        case 'bannerLarge':
          label = '05_橫幅大圖(不可點擊)';
          thumbNailUrl = '/img/banner_large_thumbnail.jpg';
          break;
        case 'banner':
          label = '06_橫幅Banner';
          thumbNailUrl = '/img/banner_thumbnail.jpg';
          break;
        case 'articleMainImage':
          label = '07_文章模組(1主圖3標題)';
          thumbNailUrl = '/img/article_main_image_thumbnail.jpg';
          break;
        case 'articleSmallImage':
          label = '08_文章模組(小圖+敘述)';
          thumbNailUrl = '/img/article_small_image_thumbnail.jpg';
          break;
        case 'customHtml':
          label = '09_自訂HTML模組';
          thumbNailUrl = '/img/html_thumbnail.jpg';
          break;
        case 'graphicN':
          label = '10_圖文模組(擴增N排)';
          thumbNailUrl = '/img/graphic_n_thumbnail.jpeg';
          break;
        default:
          label = '';
          thumbNailUrl = ' ';
          break;
      }
      $scope.moduleTypes.push({
        label,
        value: type
      });
      $scope.moduleTypesItems.push({
        buttonListText: label,
        imageUrl: thumbNailUrl,
        callback: () => {
          $scope.changePageState('add', $scope.mainAction._resource.one().clone(), type);
          $scope.initActionItem(label, type);
        }
      });
    });
  });

  utils.getLayoutModuleTypeName = (type) => {
    let label;
    switch (type) {
      case 'graphicFix':
        label = '01_圖文模組(固定三則)';
        break;
      case 'graphicMore':
        label = '02_圖文模組(1~N則)';
        break;
      case 'publicitySmall':
        label = '03_宣傳模組(小圖)';
        break;
      case 'publicityLarge':
        label = '04_宣傳模組(大圖)';
        break;
      case 'bannerLarge':
        label = '05_橫幅大圖(不可點擊)';
        break;
      case 'banner':
        label = '06_橫幅Banner';
        break;
      case 'articleMainImage':
        label = '07_文章模組(1主圖3標題)';
        break;
      case 'articleSmallImage':
        label = '08_文章模組(小圖+敘述)';
        break;
      case 'customHtml':
        label = '09_自訂HTML模組';
        break;
      case 'graphicN':
        label = '10_圖文模組(擴增N排)';
        break;
      default:
        label = '';
        break;
    }
    return label;
  };

  utils.getDragModules = ($scope, parameter) => {
    const currentActionItem = $scope.currentActionItem;
    const dragModules = {
      itemDropped: (srcList, srcIndex, targetIndex, dragContent) => {
        srcList.splice(srcIndex, 1);
        srcList.splice(targetIndex, 0, dragContent);
        return true;
      },
      itemRemoved: (index) => {
        if ($scope.currentActionItem[parameter]?.length === 1) return;
        currentActionItem[parameter].splice(index, 1);
        currentActionItem.visibleItemIndex = index === currentActionItem[parameter]?.length ? currentActionItem[parameter]?.length - 1 : index;
      },
      itemAdded: () => {
        currentActionItem[parameter]?.push(_.cloneDeep($scope.dragContents));
        currentActionItem.visibleItemIndex = currentActionItem[parameter]?.length - 1;
      },
      itemVisible: (index) => {
        currentActionItem.visibleItemIndex = index;
      },
      itemDragend: (index) => {
        if (index < currentActionItem.newIndex) currentActionItem.visibleItemIndex = currentActionItem.newIndex - 1;
        if (index > currentActionItem.newIndex) currentActionItem.visibleItemIndex = currentActionItem.newIndex;
      }
    };

    return dragModules;
  };

  return utils;
}];
