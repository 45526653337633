const Currency = (num) => {
  return num
    .toString()
    .split('')
    .reverse()
    .reduce((result, num, index) => result + (index && index % 3 === 0 && num !== '-' ? ',' : '') + num, '')
    .split('')
    .reverse()
    .join('');
};

export default ['$scope', 'UnorderedReservation', '$async', 'ywEvent', '$state', 'lifePageUtil', '$window', 'APP_URL', 'lifeDialog', 'lifeUnorderedUtil',
  function controller ($scope, UnorderedReservation, $async, ywEvent, $state, lifePageUtil, $window, APP_URL, lifeDialog, lifeUnorderedUtil) {
    $scope.reservationMode = 'stateUnordered';
    $scope.reservationDetail = {};
    $scope.isShowMenu = false;
    $scope._isPaid = false;
    $scope.reservationState = '';
    $scope.showReservationStateEdit = true;
    $scope.billTotal = '';
    const { reservationNo } = $state.params;

    if (reservationNo) {
      getReservationData(reservationNo);
    }

    function getReservationData (reservationNo) {
      const getReservation = $async(async () => {
        const reservation = await UnorderedReservation.one(reservationNo).customGET();
        if (reservation.error) {
          ywEvent.emit('ALERT', reservation.error);
        } else {
          $scope.reservationDetail = reservation;
          $scope._isPaid = getReservationPaymentStages(reservation);
          $scope.reservationState = reservation.state.displayNameAtConsole;
          $scope.billTotal = getBillTotal(reservation);
        }
      });
      getReservation();
    }

    const getReservationPaymentStages = (reservationDetail) => {
      const paymentStageSetting = _.get(reservationDetail, 'paymentStageSetting', {});
      const paymentData = Object.values(paymentStageSetting).filter(item => !_.isEmpty(item) && !!item.order);
      if (reservationDetail.state === 'completed') return true;
      if (reservationDetail.state === 'denied' && reservationDetail.travelingExpense > 0) return true;
      if (reservationDetail.reservationListMode === 'stateOrdered' && !reservationDetail.isStandard && paymentData.length === 1 && paymentData[0].order.state === 'paid') return true;
      return false;
    };

    const getBillTotal = (reservationDetail) => {
      let result;
      if (reservationDetail.state === 'denied') return `$ ${Currency(reservationDetail.travelingExpense)}`;
      if (reservationDetail.isStandard === true) return `$ ${Currency(reservationDetail.total)}`;
      if (!reservationDetail.quotation) {
        result = '評估後報價';
      } else {
        result = reservationDetail.quotation?.confirmation?.state === 'confirmed' ? `$ ${Currency(reservationDetail.total)}` : '評估後報價';
      }
      return result;
    };

    $scope.onStatusClick = $async(async () => {
      lifeUnorderedUtil.handleStateControl({ reservation: $scope.reservationDetail }).then(data => {
        console.log(data);
      });

      // return;
      // const res = await _resource
      //   .one(reservation._id)
      //   .one('stateNotification')
      //   .customPUT();
      // if (res.error) {
      //   ywEvent.emit('ALERT', res.error);
      // } else {
      // reservation.isSendNotify = !reservation.isSendNotify;
      // ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
      ywEvent.emit('SUCCESS', '已變更');
      // }
    });

    $window.onclick = (e) => {
      e.preventDefault();
      if (e.target.id !== 'life-menu-item' && e.target.id !== 'life-menu' && e.target.id !== 'life-menu-icon' && e.target.id !== 'life-menu-img') {
        if ($scope.isShowMenu) $scope.isShowMenu = false;
      }
      $scope.$apply();
    };

    $scope.onMenuIconClick = () => {
      $scope.isShowMenu = !$scope.isShowMenu;
    };

    $scope.onLogoIconClick = () => {
      $window.location.href = `${APP_URL}/service-admin/reservation`;
    };

    $scope.$on('$destroy', () => {
      console.log('pageDestroy');
      lifePageUtil.removeLoadViewPortMeta();
    });

    this.$onInit = () => {
      console.log('pageInit');
      lifePageUtil.addLoadViewPortMeta();
      if (window.screen.width >= 768) {
        this.desktop = true;
      }
    };
  }
];
