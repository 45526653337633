export default ['$async', 'ngDialog', 'address', function factory ($async, ngDialog, address) {
  /*
   * Params:
   * selectedIds,
   * objResource,
   * titleField,
   * subTitleField,
   * customSelectedFetch,
   * customQueryParams,
   * closeCityFilter,
   * useCustomOptions,
   * customOptions,
   * onConfirm,
   */
  const openConfirm = $async(async function (data) {
    if (!data) return console.log('missing parameters');
    const objResource = data.objResource;
    const titleField = data.titleField;
    const subTitleField = data.subTitleField;
    const searchField = data.searchField || data.titleField;
    const selectedIds = _.clone(data.selectedIds) || [];
    const customQueryParams = data.customQueryParams;
    const useCustomOptions = data.useCustomOptions;
    const customOptions = data.customOptions;

    // fetch selected data list for displaying
    let selectedItems = [];
    if (!useCustomOptions) {
      if (!data.useCustomSelectedFetch) {
        selectedItems = await Promise.all(selectedIds.map(id => {
          return objResource.one(id) ? objResource.one(id).customGET() : objResource.one(id).customGET();
        }));
        selectedItems = selectedItems.map(item => item);
      } else {
        selectedItems = await data.customSelectedFetch();
        console.log(selectedItems);
      }
    } else {
      if (data.useCustomSelectedFetch) {
        selectedItems = await data.customSelectedFetch();
        console.log(selectedItems);
      }
    }

    const cityOptions = Object.keys(address);
    cityOptions.unshift('全部縣市');
    const syncAreaOptions = city => {
      if (city === '全部縣市') {
        dialogData.addressOpts.city.selected = undefined;
        dialogData.addressOpts.area.options = [];
        dialogData.addressOpts.area.selected = [];
      }
      dialogData.addressOpts.area.options = _.get(address, city, []);
      dialogData.addressOpts.area.selected = [];
      queryItems();
    };

    const dialogData = {
      headerTitle: data.headerTitle,
      closeCityFilter: data.closeCityFilter,
      items: [],
      selectedItems: selectedItems,
      selectedFilteredItems: _.clone(selectedItems),
      titleField: titleField,
      subTitleField: subTitleField,
      addressOpts: {
        city: { label: '縣市', options: cityOptions, changed: syncAreaOptions, selected: undefined },
        area: { label: '地區', options: [], changed: queryItems, selected: [] }
      },
      setSelected: setSelected,
      removeSelected: removeSelected,
      selectAll: selectAll,
      disselectAll: disselectAll,
      titleSearchCb: titleSearchCb,
      selectedTextFilterCb: selectedTextFilterCb,
      isLoading: false,
      _: _
    };

    const params = {
      template: '/view/dialog/multi-searchable-select-dialog.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog custom-width-800',
      closeByEscape: true,
      closeByDocument: true,
      data: dialogData
    };

    let enableItemSwitching = false;
    queryItems();

    const confirmedItems = await ngDialog.openConfirm(params);
    data.onConfirm(confirmedItems);

    function setSelected (item) {
      dialogData.items.splice(dialogData.items.indexOf(item), 1);
      dialogData.selectedItems.push(item);
      selectedTextFilterCb();
    }

    function removeSelected (item) {
      if (!enableItemSwitching) return;
      dialogData.selectedItems.splice(dialogData.selectedItems.indexOf(item), 1);
      dialogData.items.push(item);
      selectedTextFilterCb();
    }

    function selectAll () {
      dialogData.selectedItems = dialogData.selectedItems.concat(dialogData.items);
      dialogData.items = [];
      selectedTextFilterCb();
    }

    function disselectAll () {
      dialogData.items = dialogData.items.concat(dialogData.selectedItems);
      dialogData.selectedItems = [];
      selectedTextFilterCb();
    }

    function queryItems () {
      if (!useCustomOptions) {
        dialogData.isLoading = true;
        dialogData.items = [];
        objResource.getList({
          city: dialogData.addressOpts.city.selected,
          areas: dialogData.addressOpts.area.selected,
          limit: 50,
          [searchField || titleField || 'q']: dialogData.queryText,
          ...customQueryParams
        }).then(res => {
          const items = (res.plain ? res.plain() : res).map(item => item);
          dialogData.items = items.filter(item => {
            return !dialogData.selectedItems.find(selected => _.get(selected, '_id') === item._id);
          });
          enableItemSwitching = true;
          dialogData.isLoading = false;
        });
      } else {
        dialogData.items = (customOptions || []).filter(item => {
          return _.get(item, titleField, '').toLowerCase().indexOf(_.get(dialogData, 'queryText', '').toLowerCase()) > -1 && !dialogData.selectedItems.find(selected => _.get(selected, '_id') === item._id);
        });
        enableItemSwitching = true;
      }
    }

    function titleSearchCb () {
      queryItems();
    }

    function selectedTextFilterCb () {
      if (dialogData.selectedFilterText) {
        dialogData.selectedFilteredItems = _.filter(dialogData.selectedItems, item => item[titleField]?.toLowerCase().indexOf(dialogData.selectedFilterText.toLowerCase()) > -1);
      } else {
        dialogData.selectedFilteredItems = _.clone(dialogData.selectedItems);
      }
    }
  });

  return { openConfirm };
}];
