/*
  example:
  <life-toast msg="notice.message" close-life-notice="closeLifeNotice(notice.id)""></life-toast>
  通常會以 $scope.lifeNotification = (msg, isFixed) 的方式呼叫
*/

export default {
  bindings: {
    msg: '=', // string, 必填
    closeLifeNotice: '&'
  },
  template: `
    <div class="life-toast life-body-medium ng-enter ng-enter-active life-toast-test">
      <div>{{$ctrl.msg}}</div>
      <img class="life-toast-cross" src="/img/cross-white.svg" ng-click="$ctrl.closeLifeNotice()"/>
    </div>
  `
};
