export default ['$async', 'multiSearchableObjPickerDialog', function directive ($async, multiSearchableObjPickerDialog) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      selectedIds: '=',
      selectedItems: '=',
      objResource: '=',
      customQueryParams: '=queryParams',
      titleField: '=',
      subTitleField: '=',
      codeField: '=',
      disabled: '=ywDisabled',
      customSelectedFetch: '&',
      useCustomSelectedFetch: '=',
      closeCityFilter: '=',
      showSinyiFilter: '=',
      showSinyiServiceAreaFilter: '=',
      showPushMessageFilter: '=',
      onConfirm: '=',
      closeSearch: '=',
      showSelectedItems: '=',
      showSelectedItemsCount: '=',
      useInputAppearance: '=',
      activeStyle: '=',
      searchField: '=',
      useCustomOptions: '=',
      customOptions: '='
    },
    template: `
      <div style="display: inline-block;">
        <div
          ng-if="useInputAppearance"
          class="select-wrapper dark wrapper"
          ng-class="{disabled: disabled, 'active-control': displaySelectedItems !== '請選擇' && activeStyle}"
        >
            <a
              class="select"
              ng-disabled="disabled"
              ng-click="disabled || openConfirm({})"
              uib-tooltip="{{displaySelectedItems}}"
              tooltip-enable="selectedIds.length || selectedItems.length"
            >
                <span ng-bind="displaySelectedItems">
                </span>
                <span class="caret"></span>
            </a>
        </div>
        <div ng-if="!useInputAppearance">
          <button class="yw-btn yw-btn-blue" ng-click="openConfirm({})" ng-disabled="disabled">設定</button>
          <span ng-if="showSelectedItems" ng-bind="displaySelectedItems"></span>
          <span ng-if="showSelectedItemsCount" ng-bind="displaySelectedItemsCount || '未設定'"></span>
        </div>
      </div>
    `,
    link: link
  };

  function link (scope, element, attr) {
    if (scope.showSelectedItems) {
      scope.$watch('selectedItems', () => {
        if (scope.selectedIds) {
          return;
        }
        const selectedItemsTitle = (scope.selectedItems || []).map(item => { return item.title || (scope.titleField && item[scope.titleField]) || item; });
        scope.displaySelectedItems = selectedItemsTitle.join(' ,');
        if (_.isEmpty(scope.selectedItems)) scope.displaySelectedItems = scope.useInputAppearance ? '請選擇' : '未設定';
      });

      scope.$watch('selectedIds', () => {
        if (scope.selectedItems) {
          return;
        }
        if (_.isEmpty(scope.selectedIds)) {
          scope.displaySelectedItems = scope.useInputAppearance ? '請選擇' : '未設定';
        } else {
          Promise.all(scope.selectedIds.map(id => scope.objResource.one(id).customGET('', scope.customQueryParams)))
            .then(results => {
              scope.displaySelectedItems = results.map(result => _.get(result, scope.titleField)).join(' ,');
            });
        }
      });
    }

    const useId = scope.selectedIds !== undefined;
    const selectedList = useId ? scope.selectedIds : scope.selectedItems;
    if (scope.showSelectedItemsCount) scope.displaySelectedItemsCount = selectedList.length > 0 ? '已選擇 ' + selectedList.length + ' 個' : '';

    scope.openConfirm = $async(async function (data) {
      const { onConfirm, ...dialogData } = scope;
      await multiSearchableObjPickerDialog.openConfirm({
        ...dialogData,
        selectedIds: useId ? dialogData.selectedIds : (dialogData.selectedItems || []).map(item => item._id),
        onConfirm: onSelectedConfirm
      });
    });

    function onSelectedConfirm (selectedItems) {
      if (useId) {
        scope.selectedIds = selectedItems.map(item => item._id);
      } else {
        scope.selectedItems = selectedItems;
      }
      if (scope.showSelectedItemsCount) scope.displaySelectedItemsCount = selectedItems.length > 0 ? '已選擇 ' + selectedItems.length + ' 個' : '';
      if (scope.onConfirm) scope.onConfirm(selectedItems);
    }
  }
}];
