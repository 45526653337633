import moment from 'moment';

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('couponEvents', false, _transformer);
  const CouponEvent = Restangular.service('couponEvents');
  return CouponEvent;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      { anchorType: 'copy-id' },
      _.get(item, 'subjectName'),
      _.get(item, 'couponDefault.title'),
      moment(_.get(item, 'couponDefault.effectiveDate.begin')).format('YYYY/MM/DD') +
        '~' +
        moment(_.get(item, 'couponDefault.effectiveDate.end')).format('YYYY/MM/DD'),
      moment(_.get(item, 'createdAt')).format('YYYY/MM/DD'),
      {
        anchorType: 'link',
        url: `/service-admin/coupon?couponEventId=${_.get(item, '_id')}`,
        linkText: _.get(item, 'couponDefault.available.maxUserNum', '')
      },
      _.get(item, 'couponDefault.venderName'),
      { anchorType: 'true-or-false', isTrue: _.get(item, 'couponDefault.isForSinyiUsed'), trueOnly: true },
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
