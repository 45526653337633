export default ['$filter', 'ywTimezone', function directive ($filter, ywTimezone) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      timePeriodValue: '='
    },
    template: '<button class="btn btn-default btn-sm" uib-popover-html="displayContent" popover-trigger="\'mouseenter\'" popover-placement="left">' +
    '<span class="glyphicon glyphicon-calendar"></span>' +
    '</button>',
    link: link
  };

  function link (scope, element, attr) {
    setDisplayContent();

    function setDisplayContent () {
      const timeValue = _.map(scope.timePeriodValue, (value, index) => {
        const time = index < 10 ? `0${index}` : index;
        return `<div><span class="color-gray-dark">${time}:</span> ${value}</div>`;
      });
      scope.displayContent = `<div class="column-2 width-120">${timeValue.join('')}</div>`;
    }
  }
}];
