export default function directive () {
  return {
    restrict: 'A',
    transclude: true,
    scope: {
      onLoaded: '&'
    },
    link: link
  };

  function link (scope, element, attr) {
    element.bind('load', function () {
      scope.onLoaded();
    });
  }
}
