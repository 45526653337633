/*
  example:
  <life-status-button label="'評估安排'" status="'done'"></life-status-button>
*/

const statusMap = {
  評估安排: 'assessment',
  接單確認: 'order-confirmation',
  報價簽約: 'contract-signing',
  訂金確認: 'deposit-confirmation',
  服務安排: 'service-arrangement',
  服務完成: 'service-completed',
  上傳完工照: 'upload-photos',
  備註留言: 'note'
};
export default {
  bindings: {
    label: '<', // string, 必填, 按鈕文字
    status: '<', // string, 必填, 按鈕狀態, default / check / done / disable
    onClick: '&' // function
  },
  template: `
    <div
      ng-click="$ctrl.clicked()"
      class="life-status-button"
      ng-class="{'life-status-button-default': $ctrl.status === 'default' || $ctrl.status === 'check', 'life-status-button-disable': $ctrl.status === 'done' || $ctrl.status === 'disable'}">
      <div class="life-status-button-icon-wrapper">
        <img class="life-status-button-icon" ng-src="{{$ctrl.iconImage}}" width="60" />
        <img ng-if="$ctrl.status === 'check'" class="life-status-button-check" src="/img/check-default.svg" width="20" />
        <img ng-if="$ctrl.status === 'done'" class="life-status-button-check" src="/img/check-disable.svg" width="20" />
      </div>
      <div class="life-body-medium life-status-button-label">{{$ctrl.label}}</div>
    </div>
  `,
  controller: ['$element',
    function tabController ($element) {
      this.clicked = () => {
        if (this.status === 'done' || this.status === 'disable') return;
        this.onClick();
      };

      this.$onInit = () => {
        this.iconImage = `/img/${statusMap[this.label]}-${this.status === 'done' || this.status === 'disable' ? 'disable' : 'default'}.svg`;
      };
    }
  ]
};
