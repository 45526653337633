import { inputLimit } from '../../../share/input-limit.js';

export default ['$scope', 'LayoutModule', 'layoutModuleUtils',
  function controller ($scope, LayoutModule, layoutModuleUtils) {
    const self = this;
    self.backGroundImageFile = {};
    self.imageFiles = {};
    self.imageDoubleFiles = {};
    self.uploaded = $scope.imageUploaded;
    $scope.singleFileUploaded = $scope.singleFileUploaded; // eslint-disable-line
    $scope.inputItems = [];
    $scope.subInputItem = [];
    $scope.dragContents = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      const articleSmallImageModulePages = item.articleSmallImageModule.pages;
      let titleResult = true;
      let brieflyResult = true;
      let imageResult = true;

      if (articleSmallImageModulePages.some(page => !page?.title)) {
        return $scope.alert('需填寫模組內容所有頁籤標題');
      }

      articleSmallImageModulePages.forEach(page => {
        page?.contents.forEach(content => {
          if (!content.image) imageResult = false;
          if (content.articlesInfo?.some(info => !info?.title)) titleResult = false;
          if (content.articlesInfo?.some(info => !info?.briefly)) brieflyResult = false;
        });
      });

      if (!imageResult) return $scope.alert('需上傳模組內容所有圖片');
      if (!titleResult) return $scope.alert('需填寫模組內容所有標題');
      if (!brieflyResult) return $scope.alert('需填寫模組內容所有簡述');

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      const pages = item.contents.map((content) => ({
        title: content[0].title,
        contents: [2, 7, 12, 17].map((startIndex) => {
          const imageKey = Object.keys(content[startIndex]).find(key => !key.includes('_') && key.includes('image'));
          const dataNumber = imageKey?.substring(imageKey.length - 1, imageKey.length);
          return ({
            image: content[startIndex][imageKey],
            articlesInfo: [{
              link: content[startIndex + 1][`link${dataNumber}`],
              title: content[startIndex + 2][`articleTitle${dataNumber}`],
              briefly: content[startIndex + 3][`briefly${dataNumber}`]
            }]
          });
        }
        )
      }));

      item.articleSmallImageModule = {
        paragraphTitle: item.paragraphTitle,
        pages
      };

      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();

        item.style = layoutModule?.style;
        item.isVisible = layoutModule?.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item.paragraphTitle = layoutModule.articleSmallImageModule.paragraphTitle || '';
        item[`${item.type}Module`] = layoutModule[`${item.type}Module`];
      }

      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      currentActionItem.visibleItemIndex = 0;
      currentActionItem.logListEvent = (index) => {
        currentActionItem.newIndex = index;
      };

      const moduleContentsLength = 4;
      const defaultContents = [
        {
          titleLabel: '*頁籤標題：',
          type: 'module-text',
          index: 'title',
          title: '預設標題',
          remark: '字數限制：256字(多個頁籤時顯示，建議8字內，字數寬度超過360px會隱藏)',
          maxlength: inputLimit.default
        }
      ];

      const articleSmallContent = (itemNumber, index) => {
        if (currentActionItem.style === 'none') {
          currentActionItem.style = 'left';
        }
        return (
          [
            {
              type: 'module-switch-title',
              switchTitle: `內容${index + 1}`,
              order: index,
              maxlength: moduleContentsLength,
              itemMoveForward: (itemIndex, contentIndex) => {
                const switchContentsArray = _.cloneDeep(currentActionItem.contents)[contentIndex];
                defaultContents[0].title = switchContentsArray[0]?.title;
                const switchContents = [];
                [1, 6, 11, 16].forEach((startIndex) => {
                  const afterSwitch = startIndex !== 16 ? switchContentsArray.slice(startIndex, startIndex + 5) : switchContentsArray.slice(startIndex);
                  switchContents.push(afterSwitch);
                });
                const switchItem = switchContents.splice(itemIndex, 1);
                switchContents.splice(itemIndex - 1, 0, ...switchItem);
                switchContents.forEach((item, index) => {
                  item[0].order = index;
                  item[0].switchTitle = `內容${index + 1}`;
                });
                const newContents = [...defaultContents, ...switchContents.flat()];
                currentActionItem.contents[contentIndex] = _.cloneDeep(newContents);
              },
              itemMoveBackward: (itemIndex, contentIndex) => {
                const switchContentsArray = _.cloneDeep(currentActionItem.contents)[contentIndex];
                defaultContents[0].title = switchContentsArray[0]?.title;
                const switchContents = [];
                [1, 6, 11, 16].forEach((startIndex) => {
                  const afterSwitch = startIndex !== 17 ? switchContentsArray.slice(startIndex, startIndex + 5) : switchContentsArray.slice(startIndex);
                  switchContents.push(afterSwitch);
                });
                const switchItem = switchContents.splice(itemIndex, 1);
                switchContents.splice(itemIndex + 1, 0, ...switchItem);
                switchContents.forEach((item, index) => {
                  item[0].order = index;
                  item[0].switchTitle = `內容${index + 1}`;
                });
                const newContents = [...defaultContents, ...switchContents.flat()];
                currentActionItem.contents[contentIndex] = _.cloneDeep(newContents);
              }
            },
            {
              label: '*圖片：',
              remark: '圖片比例：3:2、建議尺寸：不要低於寬300 x 高200 ~ 寬600 x 高400，限制 1 MB',
              type: 'module-file',
              index: `_imageUrl${itemNumber}`,
              originIndex: `image${itemNumber}`,
              class: 'images-wrapper',
              files: $scope.imageFiles,
              success: (result, item) => {
                item[`_imageUrl${itemNumber}`] = result;
                item[`image${itemNumber}`] = result._id;
              },
              error: $scope.alert,
              validate: { limitSize: 1000000 }
            },
            { label: '連結：', type: 'module-link', index: `link${itemNumber}` },
            {
              label: '*文章標題：',
              type: 'module-text',
              index: `articleTitle${itemNumber}`,
              remark: '字數限制：256字(圖片的 title 、 alt 同此標題)',
              maxlength: inputLimit.default
            },
            {
              label: '*文章簡述：',
              type: 'module-textarea',
              index: `briefly${itemNumber}`,
              remark: '字數限制：80字(未填則不顯示，576、768只會顯示兩行)',
              maxlength: 80
            }
          ]
        );
      };

      const articleSmallContents = _.chain([1, 2, 3, 4])
        .map((itemNumber, index) => {
          return articleSmallContent(itemNumber, index);
        }).flatten().value();

      $scope.dragContents = [...defaultContents, ...articleSmallContents];

      if (currentActionItem.articleSmallImageModule) {
        const pages = currentActionItem.articleSmallImageModule.pages.map((page) => {
          const pageDragContents = _.cloneDeep($scope.dragContents);
          pageDragContents[0].title = page.title;
          [2, 7, 12, 17].forEach((startIndex, index) => {
            pageDragContents[startIndex][`image${index + 1}`] = page.contents[index].image._id;
            pageDragContents[startIndex][`_imageUrl${index + 1}`] = page.contents[index].image;
            page.contents[index].articlesInfo.forEach((info) => {
              pageDragContents[startIndex + 1][`link${index + 1}`] = info.link;
              pageDragContents[startIndex + 2][`articleTitle${index + 1}`] = info.title;
              pageDragContents[startIndex + 3][`briefly${index + 1}`] = info.briefly;
            });
          });
          return pageDragContents;
        });
        currentActionItem.contents = pages;
      } else {
        currentActionItem.contents = [_.cloneDeep($scope.dragContents)];
      }

      const dragModules = layoutModuleUtils.getDragModules($scope, 'contents');

      return [
        { label: '模組類型：', type: 'description', description: currentActionItem.label },
        { label: '*模組名稱：', type: 'text', index: 'name', remark: '不會顯示於前台' },
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ]
        },
        {
          label: '樣式選擇',
          type: 'radio-with-image',
          index: 'style',
          radios: [
            { value: 'left', label: '圖左字右', image: '/img/articleSmallImage_left.png' },
            { value: 'right', label: '圖右字左', image: '/img/articleSmallImage_right.png' }
          ]
        },
        { type: 'underline' },
        { label: '段落標題：', type: 'text', index: 'paragraphTitle', remark: 'H1、字數限制：256字(未填則不顯示，建議8字內)', maxlength: inputLimit.default },
        {
          type: 'drag-module',
          module: 'articleSmallImage',
          subType: 'graphicDragModule-wrapper',
          index: 'contents',
          itemDropped: dragModules.itemDropped,
          itemRemoved: dragModules.itemRemoved,
          itemAdded: dragModules.itemAdded,
          itemVisible: dragModules.itemVisible,
          itemDragend: dragModules.itemDragend
        },
        { type: 'action' }
      ];
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
