export default ['$async', 'currentUser', 'address', 'ServiceCapacity', 'ServiceClassification', 'Service', function ($async, currentUser, address, ServiceCapacity, ServiceClassification, Service) {
  return {
    getCountyFilter: ($scope, regionKey, hiddenKey, districtHiddenKey, districtFilterItem) => {
      const countyOptions = address.getSortedCounties;
      const syncDistrictOptions = function (counties) {
        if (_.isEmpty(counties)) {
          $scope.mainAction.queryParams[districtHiddenKey] = [];
          delete $scope.mainAction.queryParams[regionKey];
          districtFilterItem.options = [];
        } else {
          districtFilterItem.options = address.syncDistrictOptions(counties);
          $scope.mainAction.queryParams[districtHiddenKey] =
            ($scope.mainAction.queryParams[districtHiddenKey] || []).filter(
              district => counties.find(county => district.startsWith(county))
            );
          $scope.mainAction.queryParams[regionKey] = address.getRegions(
            counties,
            $scope.mainAction.queryParams[districtHiddenKey]
          );
        }
        $scope.mainAction.setClearFilterTrue();
      };
      return {
        name: hiddenKey,
        label: '縣市',
        options: countyOptions,
        changed: syncDistrictOptions,
        type: 'multiFilter'
      };
    },
    getDistrictFilter: ($scope, regionKey, countyHiddenKey, hiddenKey) => {
      const districtFilterItem = {
        name: hiddenKey,
        label: '地區',
        options: [],
        changed: (districts) => {
          $scope.mainAction.queryParams[regionKey] = address.getRegions(
            $scope.mainAction.queryParams[countyHiddenKey],
            districts
          );
          $scope.mainAction.setClearFilterTrue();
        },
        type: 'multiFilter',
        byCategory: true
      };
      return districtFilterItem;
    },

    getServiceClassificationFilter: ($scope, queryKey, serviceHiddenKey, serviceKey) => {
      const serviceClassificationInput = {
        name: queryKey || 'serviceClassification',
        label: '服務分類',
        searchable: true,
        options: [],
        click: $async(async () => {
          if (currentUser.hasRole('vender')) {
            let capacities = await ServiceCapacity.getList({ venderId: currentUser.getVenderId() });
            if ($scope.queryPage === 'reservation') {
              capacities = capacities.filter(item => {
                return item.serviceClassification.reservationListMode === 'stateOrdered';
              });
            } else if ($scope.queryPage === 'unorderedReservation') {
              capacities = capacities.filter(item => {
                return item.serviceClassification.reservationListMode === 'stateUnordered';
              });
            }
            serviceClassificationInput.options = _.chain(capacities)
              .map(capacity => capacity.serviceClassification)
              .uniqWith((itemA, itemB) => itemA._id === itemB._id)
              .map(item => ({ label: item.name, value: item._id }))
              .value();
          } else {
            let res;
            if ($scope.queryPage === 'reservation') {
              res = await ServiceClassification.getList({ limit: 999, displayVisible: 'all', reservationListMode: 'stateOrdered' });
            } else if ($scope.queryPage === 'unorderedReservation') {
              res = await ServiceClassification.getList({ limit: 999, displayVisible: 'all', reservationListMode: 'stateUnordered' });
            }
            serviceClassificationInput.options = res
              .map(item => ({ label: item.name, value: item._id }));
          }
        }),
        changed: (classifications) => {
          $scope.mainAction.queryParams[serviceHiddenKey] =
            ($scope.mainAction.queryParams[serviceHiddenKey] || []).filter(
              service => classifications.find(classification => service.startsWith(classification))
            );
          if ($scope.mainAction.queryParams[serviceHiddenKey]) {
            delete $scope.mainAction.queryParams[serviceKey];
          }
          $scope.mainAction.setClearFilterTrue();
        },
        type: 'multiFilter'
      };
      return serviceClassificationInput;
    },
    getServiceFilter: ($scope, serviceHiddenKey, serviceKey, serviceClassificationKey) => {
      const serviceInput = {
        name: serviceHiddenKey || '_service',
        label: '服務方案',
        options: [],
        click: $async(async () => {
          const res = await Promise.all(($scope.mainAction.queryParams[serviceClassificationKey] || [])
            .map(id => Service.getList({ limit: 999, classificationId: id })));
          const options = _.chain(res)
            .flatten()
            .map(service => ({
              category: service.classification.name,
              label: service.name,
              value: `${service.classification._id}-${service._id}`,
              _classificationId: service.classification._id
            }))
            .value();
          if (currentUser.hasRole('vender')) {
            const capacities = await ServiceCapacity.getList({ venderId: currentUser.getVenderId() });
            serviceInput.options = options.filter(option => {
              const matchedCapacities = capacities
                .filter(capacity => capacity.serviceClassification._id === option._classificationId);
              return matchedCapacities.some(capacity => _.isEmpty(capacity.services) || _.includes(capacity.services, option.value));
            });
          } else {
            serviceInput.options = options;
          }
        }),
        changed: (services) => {
          if (_.isEmpty(services)) {
            delete $scope.mainAction.queryParams[serviceKey];
          } else {
            $scope.mainAction.queryParams[serviceKey] = services.map(value => value.split('-')[1]);
          }
        },
        type: 'multiFilter',
        byCategory: true
      };
      return serviceInput;
    },

    setQueryParamsFromOriginFilter: ($scope, key) => {
      const origins = _.get($scope.mainAction.queryParams, key, []);
      delete $scope.mainAction.queryParams.selfBookingFrom;
      delete $scope.mainAction.queryParams.deviceType;
      delete $scope.mainAction.queryParams.origin;
      if (origins.length > 0) {
        const groupByType = _.groupBy(origins, origin => {
          if (['serviceAdmin', 'vender', 'sinyiApi'].includes(origin)) { return 'selfBookingFrom'; }
          if (['ios', 'android', 'web'].includes(origin)) { return 'deviceType'; }
          return 'origin';
        });
        Object.assign($scope.mainAction.queryParams, groupByType);
      }
    }
  };
}];
