import React, { Fragment } from 'react';

const ServiceInfoCell = ({ reservation }) => {
  return (
    <>
      {_.get(reservation, 'services', [])
        .map(service => _.get(service, 'serviceId.name') + `＊${service.count}`)
        .join(',')}
    </>
  );
};

export default ServiceInfoCell;
