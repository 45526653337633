export default [function directive () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      bool: '=',
      onChange: '=',
      item: '=',
      name: '='
    },
    template: `
      <div class="switch-toggle switch-candy yw-switch" ng-class="{'gray-disable': !bool}">
        <input
          id="off-edit-{{switchName}}"
          name="edit-{{switchName}}"
          type="radio"
          ng-click="bool = false; onChange(item, false);"
          ng-checked="!bool" />
        <label for="off-edit-{{switchName}}">否</label>
        <input
          id="on-edit-{{switchName}}"
          name="edit-{{switchName}}"
          type="radio"
          ng-click="bool = true; onChange(item, true);"
          ng-checked="bool" />
        <label for="on-edit-{{switchName}}">是</label>
        <a class="switch-candy"></a>
      </div>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.switchName = _.get(scope.item, 'obj.featureName', scope.name);
  }
}];
