export default ['$scope', '$rootScope', '$state', '$http', 'API_URL', 'session', 'currentUser', '$async', 'confirmDialog',
  function controller ($scope, $rootScope, $state, $http, API_URL, session, currentUser, $async, confirmDialog) {
    $scope.loginInfo = {};
    $scope.login = login;
    $scope.tryLogin = function (event) { if (event.key === 'Enter') $scope.login(); };

    function login () {
      if ($scope.status.isLoading) return;
      if (_.isEmpty($scope.loginInfo.username) || _.isEmpty($scope.loginInfo.password)) return $scope.alert('請輸入帳號及密碼');
      ($async(async () => {
        try {
          $scope.status.isLoading = true;
          const res = await session.login($scope.loginInfo.username, $scope.loginInfo.password);
          if (res.ok === false) throw new Error(res.msg);
          if (res.isSuccess === false) throw new Error(res.errorMessage);

          const sessionResult = res.result.session;
          const meResult = await $http({
            method: 'GET',
            url: API_URL + 'users/me',
            headers: { authorization: `Bearer ${sessionResult._id}` }
          });

          $rootScope.isLoggedIn = true;

          checkRolesAndRedirect({ ...sessionResult, user: meResult.data.result.me });
        } catch (e) {
          $scope.alert(e);
        } finally {
          $scope.status.isLoading = false;
        }
      }))();
    }

    function checkRolesAndRedirect (userInfo) {
      currentUser.setInfo(userInfo);
      const reservationNo = localStorage.getItem('reservationNo') || undefined; // eslint-disable-line
      if (reservationNo) {
        $state.go('reservation-detail', { reservationNo });
        localStorage.removeItem('reservationNo'); // eslint-disable-line
      } else {
        $state.go(currentUser.getInitialState());
      }
    }

    $scope.forgetPwd = async () => {
      if (!$scope.loginInfo.username) return $scope.alert('請確認輸入的帳號');
      try {
        $scope.status.isLoading = true;
        const res = await $http({
          method: 'POST',
          url: API_URL + 'users/forgetPassword',
          data: { username: $scope.loginInfo.username, origin: 'lsWeb' }
        });
        if (res?.data?.ok === false) throw new Error(res.data.msg);
        confirmDialog.openConfirm({
          title: '已發送密碼至帳號登記的手機號碼與電子郵件',
          htmlContent: `${res?.data?.result?.phone}<br>${res?.data?.result?.email}`,
          hideCancel: true
        }, 'custom-width-400');
      } catch (e) {
        $scope.alert(e);
      } finally {
        $scope.status.isLoading = false;
      }
    };
  }];
