export default ['$mdThemingProvider', '$mdAriaProvider', '$mdDateLocaleProvider',
  function ($mdThemingProvider, $mdAriaProvider, $mdDateLocaleProvider) {
    $mdThemingProvider.definePalette('localbondPalette', {
      50: 'f1f7e4',
      100: 'dbeabd',
      200: 'c3db92',
      300: 'aace65',
      400: '97c440',
      500: '84b90c',
      600: '74aa00',
      700: '5f9700',
      800: '498300',
      900: '1e6200',
      A100: 'b7ecf7',
      A200: '8be0f2',
      A400: '4bcae4',
      A700: '27646b',
      contrastDefaultColor: 'light',
      contrastDarkColors: ['50', '100', '200', '300', '400', 'A100', 'A200', 'A400'],
      contrastLightColors: undefined
    });

    $mdThemingProvider.definePalette('localbondGrayPalette', {
      50: 'ebebeb',
      100: 'ebebeb',
      200: 'd0d0d0',
      300: 'd0d0d0',
      400: '9d9d9d',
      500: '9d9d9d',
      600: '6b6b6b',
      700: '6b6b6b',
      800: '383838',
      900: '383838',
      A100: 'ebebeb',
      A200: '9d9d9d',
      A400: '6b6b6b',
      A700: '383838',
      contrastDefaultColor: 'light',
      contrastDarkColors: ['50', '100', '200', '300', '400', 'A100', 'A200', 'A400'],
      contrastLightColors: undefined
    });

    $mdThemingProvider.definePalette('localbondRedPalette', {
      50: 'ffecee',
      100: 'ffecee',
      200: 'ffced2',
      300: 'f69c99',
      400: 'ef7671',
      500: 'f9584b',
      600: 'fd472d',
      700: 'f03d2e',
      800: 'dd3328',
      900: 'd12b21',
      A100: 'f69c99',
      A200: 'ef7671',
      A400: 'fd472d',
      A700: 'c12014',
      contrastDefaultColor: 'light',
      contrastDarkColors: ['50', '100', '200', '300', '400', 'A100', 'A200', 'A400'],
      contrastLightColors: undefined
    });

    $mdThemingProvider
      .theme('default')
      .primaryPalette('localbondPalette', {
        default: '600'
      })
      .accentPalette('localbondGrayPalette', {
        default: '900'
      })
      .warnPalette('localbondRedPalette', {
        default: '700'
      });

    $mdAriaProvider.disableWarnings();

    $mdDateLocaleProvider.shortDays = ['日', '一', '二', '三', '四', '五', '六'];
  }
];
