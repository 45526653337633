import { inputLimit } from '../../../share/input-limit.js';

export default ['$scope', 'confirmDialog', 'LayoutModule', 'layoutModuleUtils',
  function controller ($scope, confirmDialog, LayoutModule, layoutModuleUtils) {
    const self = this;
    self.backGroundImageFile = {};
    self.imageFiles = {};
    $scope.singleFileUploaded = $scope.singleFileUploaded; // eslint-disable-line
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);

      if (!item.name) {
        return $scope.alert('模組名稱為必填');
      }

      const content = item.publicitySmallModule?.content;
      const fillInFromTextAndLink = (content?.buttonText && content?.link) || (!content?.buttonText && !content?.link);
      if (item.publicitySmallModule?.backgroundImage?.target === 'image' && !item.publicitySmallModule?.backgroundImage?.image) {
        return $scope.alert('需上傳背景圖片');
      }
      if (!content?.image) {
        return $scope.alert('需上傳模組內容圖片');
      }
      if (!content?.titleAndAlt) {
        return $scope.alert('需上傳模組內容圖片title&alt');
      }
      if (!fillInFromTextAndLink) {
        return $scope.alert('按鈕文案與連結需同時填入或不填');
      }

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      item.publicitySmallModule = {
        font: item.font,
        backgroundImage: {
          target: item.target,
          image: item.target === 'image' ? item.backgroundImage : null,
          color: item.target === 'color' ? item.color : ''
        },
        content: {
          image: item.image,
          titleAndAlt: item.titleAndAlt,
          title: item.title,
          paragraph: item.paragraph,
          buttonText: item.buttonText,
          link: item.link
        }
      };
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';

      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();

        item.style = layoutModule.style;
        item.isVisible = layoutModule.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item.font = layoutModule.publicitySmallModule.font;
        item.target = layoutModule.publicitySmallModule.backgroundImage.target;

        if (item.target !== 'none') {
          if (item.target === 'image') {
            item._backgroundImageUrl = layoutModule.publicitySmallModule.backgroundImage?.image;
            item.backgroundImage = layoutModule.publicitySmallModule.backgroundImage?.image?._id;
          } else {
            item.color = layoutModule.publicitySmallModule.backgroundImage?.color;
          }
        }
        _.forIn(layoutModule.publicitySmallModule.content, (value, key) => {
          if (key === 'image') {
            item._imageUrl = layoutModule.publicitySmallModule.content?.image;
            item.image = layoutModule.publicitySmallModule.content?.image?._id;
          } else {
            item[key] = value;
          }
        });
      }

      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      const openColorPickerModal = async () => {
        const color = await confirmDialog.openConfirm({
          title: '',
          templateUrl: '/view/dialog/color-picker-dialog.html',
          confirmValue: currentActionItem.color
        });
        currentActionItem.color = color;
      };

      if (!currentActionItem.target) {
        currentActionItem.color = '#ffffff';
        currentActionItem.target = 'image';
        currentActionItem.style = 'pictureLeftTextRight';
        currentActionItem.font = 'white';
      }

      $scope.mainImageUpload = {
        label: ' ',
        type: 'single-file',
        index: '_backgroundImageUrl',
        originIndex: 'backgroundImage',
        class: 'images-wrapper',
        files: $scope.backGroundImageFile,
        success: $scope.singleFileUploaded,
        error: $scope.alert,
        showIndex: currentActionItem.target === 'image'
      };

      $scope.mainImageColor = {
        label: ' ',
        index: 'color',
        type: 'color-picker-dialog',
        color: currentActionItem.color,
        onEditColor: openColorPickerModal,
        showIndex: currentActionItem.target === 'color'
      };

      return (
        [
          { label: '模組類型：', type: 'description', description: currentActionItem.label },
          { label: '*模組名稱：', type: 'text', index: 'name', remark: '不會顯示於前台' },
          {
            label: '顯示設定',
            type: 'radio',
            index: 'isVisible',
            radios: [
              { value: true, label: '顯示' },
              { value: false, label: '隱藏' }
            ]
          },
          {
            label: '樣式選擇',
            type: 'radio-with-image',
            index: 'style',
            radios: [
              { value: 'pictureLeftTextRight', label: '圖左文右', image: '/img/publicitySmall_pictureLeft.png' },
              { value: 'pictureRightTextLeft', label: '圖右文左', image: '/img/publicitySmall_pictureRight.png' }
            ]
          },
          { type: 'underline' },
          {
            label: '*背景圖片：',
            type: 'radio',
            index: 'target',
            radios: [
              { value: 'image', label: '上傳圖檔', remark: '背景圖比例：2:1、建議尺寸：寬1600 x 高800 ~ 寬1920 x 高960(高畫質)，可上傳 PNG 圖片，限制 1 MB' },
              { value: 'color', label: '單色', remark: '點選色塊可換色' },
              { value: 'none', label: '無' }
            ],
            validate: { limitSize: 1000000 },
            changed: (value) => {
              $scope.mainImageColor.showIndex = value === 'color';
              $scope.mainImageUpload.showIndex = value === 'image';
            }
          },
          $scope.mainImageUpload,
          $scope.mainImageColor,
          {
            label: '*文字樣式：',
            type: 'radio',
            index: 'font',
            radios: [
              { value: 'white', label: '白字(深色背景用)' },
              { value: 'black', label: '黑字(淺色背景用)' }
            ]
          },
          {
            label: '*圖片：',
            type: 'single-file',
            index: '_imageUrl',
            originIndex: 'image',
            class: 'images-wrapper',
            files: $scope.imageFiles,
            success: $scope.singleFileUploaded,
            error: $scope.alert,
            remark: '主圖片比例：1:1、建議尺寸：寬800 x 高800，可上傳 PNG 圖片，限制 1 MB',
            validate: { limitSize: 1000000 }
          },
          {
            label: '*圖片title & alt：',
            type: 'text',
            index: 'titleAndAlt'
          },
          {
            label: '標題：',
            type: 'text',
            index: 'title',
            remark: 'H1、字數限制：256字(未填則不顯示)',
            maxlength: inputLimit.default
          },
          {
            label: '段落：',
            type: 'textarea',
            index: 'paragraph',
            remark: '字數限制：256字(未填則不顯示)',
            maxlength: inputLimit.default
          },
          {
            label: '按鈕文案：',
            type: 'text',
            index: 'buttonText',
            remark: '字數限制：10字，未填則不顯示',
            maxlength: 10
          },
          {
            label: '連結：',
            type: 'link',
            index: 'link'
          },
          { type: 'action' }
        ]
      );
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
