export default ['$filter', '$async', 'timelineDialog', 'ywUtil', function directive ($filter, $async, timelineDialog, ywUtil) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      timeline: '='
    },
    template: `
      <div
        class="select-wrapper wrapper white"
        ng-class="{disabled: disabled}"
        ng-click="disabled || onClick()"
      >
        <a class="select" ng-disabled="disabled">
          <span ng-bind="selectedLabel || '請選擇'">
          </span>
          <span class="caret"></span>
        </a>
      </div>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.onClick = $async(async () => {
      const timeline = await timelineDialog.openConfirm({
        timeline: scope.timeline
      });
      scope.timeline = timeline;
      updateLabel(timeline);
    });

    function updateLabel (timeline) {
      if (timeline.type === 'time') {
        const days = _.get(timeline, 'weekday', '').split(',').map(num => parseInt(num));
        scope.selectedLabel = ywUtil.getSelectedWeeksLabel(days);
      } else if (timeline.type === 'date') {
        scope.selectedLabel = $filter('date')(timeline.date, 'yyyy/MM/dd');
      } else {
        scope.selectedLabel = '';
      }
    }

    updateLabel(scope.timeline);
  }
}];
