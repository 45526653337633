export default ['$scope', '$async', 'ywUtil', 'Layout',
  function controller ($scope, $async, ywUtil, Layout) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '頁面名稱', placeholder: '請輸入頁面名稱', params: 'name' }
        ]
      );

      $scope.filterItems = [
        {
          name: 'type',
          label: '頁面類型',
          options: [
            { value: 'homepages', label: '首頁' },
            { value: 'navigation', label: '大館/' },
            { value: 'marketing', label: '行銷頁/mkt/' },
            { value: 'olMarketing', label: '行銷頁(一次載入)/olmkt/' },
            { value: 'info', label: '其他資訊/info/' }
          ],
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'multiFilter'
        },
        {
          name: 'isVisible',
          label: '顯示狀態',
          options: { true: '顯示', false: '隱藏' },
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        }
      ];

      $scope.queryPage = 'layout'; // for GA

      $scope.mainAction.initResource(Layout);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true,
        spaceType: 'large'
      };
    }
  }];
