import moment from 'moment';

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('coupons', false, _transformer);
  const Coupon = Restangular.service('coupons');
  return Coupon;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'code'),
      _.get(item, 'couponEvent.subjectName'),
      _.get(item, 'title'),
      moment(_.get(item, 'createdAt')).format('YYYY/MM/DD'),
      _.get(item, 'available.used') + '/' + _.get(item, 'available.maxUserNum'),
      { anchorType: 'coupon-usage-detail' },
      { anchorType: 'coupon-event-detail' }
    ];

    if (item.manualLoseEffective) {
      item._columns._deleted = true;
    }

    return item;
  }
}];
