import moment from 'moment';
export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('accountTransactionDetails', false, _transformer);
  const AccountTransactionDetail = Restangular.service('accountTransactionDetails');
  return AccountTransactionDetail;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    let info = {
      transferReceived: '匯款入帳',
      billPayment: '帳單繳款',
      accountRefund: '帳戶退款',
      remittanceRefund: '匯款退匯'
    }[item?.type];
    if (item?.type === 'transferReceived') info += `\n匯款帳號：${item.transactionAccountNumber || ''}`;
    if (item?.type === 'billPayment') info += `\n單據編號：${item.billNo || ''}`;
    if (item?.type === 'remittanceRefund') info += `\n退匯帳號：${item.transactionAccountNumber || ''}`;

    item._columns = [
      item?.account?.vender?.name,
      moment(item?.transactionDate).format('YYYY/MM/DD HH:mm:ss'),
      {
        anchorType: 'info-with-tips-html',
        info,
        tips: item?.type === 'accountRefund' ? item.note : ''
      },
      item.amountAfterPay - item.amountBeforePay,
      item.amountBeforePay,
      item.amountAfterPay
    ];

    return item;
  }
}];
