import listController from './list';
import graphicFixModuleAction from './graphic-fix-module-action';
import graphicMoreModuleAction from './graphic-more-module-action';
import publicitySmallModuleAction from './publicity-small-module-action';
import publicityLargeModuleAction from './publicity-large-module-action';
import bannerLargeModuleAction from './banner-large-module-action';
import bannerModuleAction from './banner-module-action';
import articleMainImageModuleAction from './article-main-image-module-action';
import articleSmallImageModuleAction from './article-small-image-module-action';
import customHtmlModuleAction from './custom-html-module-action';
import graphicNModuleAction from './graphic-n-module-action';

export default ['$scope', '$controller', function controller ($scope, $controller) {
  // inherit
  $controller('mainActionController', { $scope: $scope });

  $scope.$on('$destroy', function () { $scope.mainAction.pager.clear(); });

  $scope.listController = listController;
  $scope.pageStates = {};
  $scope.currentPage = 'layout-module';
  $scope.currentPageState = 'list';

  $scope.$watch('currentModuleType', (currentModuleType) => {
    if (currentModuleType === 'graphicFix') {
      $scope.actionController = graphicFixModuleAction;
      return;
    }
    if (currentModuleType === 'graphicMore') {
      $scope.actionController = graphicMoreModuleAction;
      return;
    }
    if (currentModuleType === 'publicitySmall') {
      $scope.actionController = publicitySmallModuleAction;
      return;
    }
    if (currentModuleType === 'publicityLarge') {
      $scope.actionController = publicityLargeModuleAction;
      return;
    }
    if (currentModuleType === 'bannerLarge') {
      $scope.actionController = bannerLargeModuleAction;
      return;
    }
    if (currentModuleType === 'banner') {
      $scope.actionController = bannerModuleAction;
      return;
    }
    if (currentModuleType === 'articleMainImage') {
      $scope.actionController = articleMainImageModuleAction;
      return;
    }
    if (currentModuleType === 'articleSmallImage') {
      $scope.actionController = articleSmallImageModuleAction;
    }
    if (currentModuleType === 'customHtml') {
      $scope.actionController = customHtmlModuleAction;
    }
    if (currentModuleType === 'graphicN') {
      $scope.actionController = graphicNModuleAction;
    }
  }, true);

  init();

  function init () {
    /** define in-page states. use templateUrl if general templates unsufficient */
    $scope.pageStates = {
      list: { customLabel: '模組內容列表' },
      add: { customLabel: '模組內容新增' },
      edit: { customLabel: '模組內容編輯' }
    };
  }
}];
