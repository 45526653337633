export default ['$scope', '$async', '$state', 'confirmDialog', 'ywEvent', 'Restangular',
  function controller ($scope, $async, $state, confirmDialog, ywEvent, Restangular) {
    $scope.photoClassification = {
      items: [],
      itemsNeedsRemove: [],
      itemMoved: (index) => $scope.photoClassification.items.splice(index, 1),
      itemRemoved: (index) => {
        const item = $scope.photoClassification.items[index];
        if (item._id) {
          $scope.photoClassification.itemsNeedsRemove.push(item);
        }
        $scope.photoClassification.items.splice(index, 1);
      },
      itemAdded: () => $scope.photoClassification.items.push({}),
      onRouteToPhotoSetting: (item) => {
        $state.go('service-admin.content/photo-tag', { photoClassificationId: item._id });
      },
      onConfirm: $async(async () => {
        const items = $scope.photoClassification.items.map((item, index) => ({
          ...(item.plain ? item.plain() : item),
          sort: $scope.photoClassification.items.length - index
        }));
        if (items.some(item => !item.name)) {
          return ywEvent.emit('ALERT', '相片標籤分類不可為空');
        }
        const createAndUpdatePromises = items.map(item => !item._id
          ? Restangular.service('photoClassifications').post(item)
          : Restangular.service('photoClassifications').one(item._id).customPUT(item)
        );
        const createAndUpdateResults = await Promise.all(createAndUpdatePromises);
        const successResults = createAndUpdateResults.filter(result => !result.error);
        $scope.photoClassification.items = successResults;

        const removePromises = $scope.photoClassification.itemsNeedsRemove.map(
          removedItem => Restangular
            .service('photoClassifications')
            .one(removedItem._id)
            .customDELETE()
        );
        const removeResults = await Promise.all(removePromises);

        const results = createAndUpdateResults.concat(removeResults);
        const errorResults = results.filter(result => result.error);
        if (_.get(errorResults, 'length')) {
          errorResults.forEach(errorResult => ywEvent.emit('ALERT', errorResult.error));
        } else {
          ywEvent.emit('SUCCESS', '儲存成功');
        }
      })
    };
    // initialize data
    Restangular.service('photoClassifications').getList().then(res => {
      if (res.error) {
        ywEvent.emit('ALERT', res.error);
      } else {
        $scope.photoClassification.items = res.sort((a, b) => b.sort - a.sort);
      }
    });
  }
];
