export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <table class="table" ng-if="$ctrl.guidePurchaseUrl">
      <tr>
        <th>導購文章連結</th>
      </tr>
      <tr>
        <td>
          <a ng-href="{{$ctrl.guidePurchaseUrl}}" target="_blank">
            {{$ctrl.guidePurchaseTitle}}
          </a>
        </td>
      </tr>
    </table>
  `,
  controller: ['$element', '$async', 'WEBAPP_URL', 'ArticleClassification', function websiteContentPage ($element, $async, WEBAPP_URL, ArticleClassification) {
    const genGuidePurchaseInfo = $async(async (reservation) => {
      const type = _.get(reservation, 'guidePurchaseRef');
      const purchase = _.get(reservation, 'guidePurchase');
      if (!type || !purchase) {
        return;
      }
      if (type === 'Article') {
        const classification = await ArticleClassification.one(purchase.articleClassification).customGET();
        if (!classification.error) {
          this.guidePurchaseTitle = purchase.title;
          this.guidePurchaseUrl = `${WEBAPP_URL}article/${classification.urlId}/${purchase.urlId}`;
        }
      } else if (type === 'Album') {
        this.guidePurchaseTitle = purchase.name;
        this.guidePurchaseUrl = `${WEBAPP_URL}album/${purchase.urlId}`;
      } else if (type === 'Vender') {
        this.guidePurchaseTitle = purchase.name;
        this.guidePurchaseUrl = `${WEBAPP_URL}vender/${purchase.urlId}`;
      }
    });

    this.$onInit = () => {
      genGuidePurchaseInfo(this.reservation);
    };
  }]
};
