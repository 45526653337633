import React, { useState } from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';

const style = {
  icon: {
    fontSize: '24px'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  staffInfoWrapper: {
    borderRadius: '5px',
    border: '1px solid grey',
    position: 'absolute',
    zIndex: '1',
    overflow: 'hidden'
  },
  staffInfo: {
    whiteSpace: 'nowrap',
    padding: '4px 10px',
    cursor: 'pointer',
    backgroundColor: 'white'
  },
  staffInfoHover: {
    whiteSpace: 'nowrap',
    padding: '4px 10px',
    cursor: 'pointer',
    backgroundColor: 'lightgrey'
  }
};

const StaffInfoControl = ({ contentText, onClick }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      style={isHover ? style.staffInfoHover : style.staffInfo}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onClick}
    >
      {contentText}
    </div>
  );
};

const DetailControls = ({ reservation, ywEvent }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onReservationDetailClick = () => {
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_RESERVATION_DETAIL',
      payload: reservation
    });
  };

  const modifySinyiStaffInfo = () => {
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_SINYI_STAFF_EDIT_DIALOG',
      payload: reservation
    });
  };

  const removeSinyiStaffInfo = () => {
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_SINYI_STAFF_REMOVE_DIALOG',
      payload: reservation
    });
  };

  return (
    <div style={style.wrapper}>
      <div>
        <i
          className='fas fa-file-alt ptr'
          aria-hidden='true'
          style={style.icon}
          onClick={() => onReservationDetailClick(reservation)}
        />
      </div>
      {reservation && reservation?.staffInfo?.empId && (
        <div style={{ position: 'relative' }}>
          <StatefulToolTip
            parent={<img
              src='/img/ic-sinyi-member.svg'
              aria-hidden='true'
              style={{ marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => setIsDropdownOpen(isDropdownOpen => !isDropdownOpen)}
            />} // eslint-disable-line
          >
            {`${reservation?.staffInfo?.empName}/${reservation?.staffInfo?.depName}`}
            <br />(點擊編輯)
          </StatefulToolTip>
          {isDropdownOpen && (
            <div style={style.staffInfoWrapper}>
              <StaffInfoControl
                contentText='編輯信義員工資訊'
                onClick={() => {
                  modifySinyiStaffInfo(reservation);
                  setIsDropdownOpen(false);
                }}
              />
              <StaffInfoControl
                contentText='移除信義員工資訊'
                onClick={() => {
                  removeSinyiStaffInfo(reservation);
                  setIsDropdownOpen(false);
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailControls;
