import moment from 'moment';
import { reservationStatusList, reservationCompletedState } from './../share';
import { inputLimit } from './../share/input-limit.js';

import {
  createAcceptancesPdf
} from '../share/print-acceptance-helper.js';

const sleepFunc = (time) => {
  return new Promise((resolve) => setTimeout(resolve, Math.ceil(time * 100)));
};

export default ['$async', 'confirmDialog', 'ngDialog', 'ywEvent', 'Reservation', 'VenderStaff', 'Vender', 'Order', 'User', 'ServiceClassification', 'ServiceCapacity', 'Coupon', 'UserGroup', 'Article', 'multiSearchableObjPickerDialog', 'popupSelectionDialog', 'ywUtil', 'currentUser', 'ywDialog', 'imageUploadDialog', 'address', 'WEBAPP_URL', 'APP_URL', 'Restangular', 'newebpayDetailDialog', 'localStorageService', function factory ($async, confirmDialog, ngDialog, ywEvent, Reservation, VenderStaff, Vender, Order, User, ServiceClassification, ServiceCapacity, Coupon, UserGroup, Article, multiSearchableObjPickerDialog, popupSelectionDialog, ywUtil, currentUser, ywDialog, imageUploadDialog, address, WEBAPP_URL, APP_URL, Restangular, newebpayDetailDialog, localStorageService) {
  const util = {};

  const generationRefundBtnMenu = async (currentUser, reservation) => {
    const state = reservation.state;
    const cancelBtn = { label: '取消訂單', value: 'refund', cb: onRefund(reservation), showIndex: true };
    const denyBtn = { label: '評估後不施作', value: 'deny', cb: onDeny(reservation), showIndex: true };

    if (currentUser.hasRole('serviceAdmin')) {
      return [cancelBtn, denyBtn];
    }

    if (currentUser.hasRole('vender')) {
      if (state === 'dispatched') {
        const confirmOtherVenderDispatch = await Reservation.one(reservation._id).customPOST({}, 'confirmOtherVenderDispatch');
        // 若沒有其他可接單的廠商，顯示退款按鈕
        if (confirmOtherVenderDispatch.length === 0) {
          denyBtn.disabled = true;
          return [cancelBtn, denyBtn];
        }
      }

      if (state === 'accepted' || state === 'quoting' || state === 'quotationConfirmed' ||
        state === 'pending-ps1' || state === 'established' || state === 'staffAssigned') {
        return [cancelBtn, denyBtn];
      }

      if (state === 'staffArrived' || state === 'processing' || state === 'processed' ||
        state === 'pending-ps3' || state === 'acceptanceChecked') {
        cancelBtn.disabled = true;
        cancelBtn.tooltip = '服務員到現場後無法取消訂單';
        return [cancelBtn, denyBtn];
      }
    }

    return [];
  };

  util.handleStateControl = $async(async ({ reservation }) => {
    const state = reservation.state;
    const menus = await generationRefundBtnMenu(currentUser, reservation);
    const imageUploadTooltip = '服務完成前進行完工照上傳，系統會連動觸發"服務員已到"、"服務中"、"服務完成"之相關步驟，推進訂單狀態';
    const jumpToFinishTooltip = '點擊"收款完成訂單"，系統會連動觸發"服務員已到"、"服務中"、"服務完成"、"已收款"之相關步驟，推進訂單狀態至"訂單完成"';

    if (state === 'dispatching') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customRightBtn: menus.length
          ? [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
          : []
      });
    } else if (state === 'dispatched') {
      // 確認信用卡
      if (_.get(reservation, 'cardConfirmedStatus.isOnStatus')) {
        confirmDialog.openConfirm({
          hideConfirm: true,
          title: '請先告知用戶確認信用卡',
          cancelLabel: '關閉視窗',
          customRightBtn: currentUser.hasRole('serviceAdmin')
            ? [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
            : []
        });
      } else {
        let leftButtons = [
          { label: '接單', value: 'accept', cb: onAccept(reservation), showIndex: true },
          { label: '不接單', value: 'reject', cb: onReject(reservation), showIndex: true }
        ];

        if (!reservation.isStandard) {
          leftButtons = [...leftButtons, {
            label: '估價資訊', value: '', cb: onValuationInfoClick(reservation), showIndex: true
          }];
        }
        confirmDialog.openConfirm({
          hideConfirm: true,
          title: '更改訂單狀態',
          content: '欲更改訂單狀態，請選擇以下操作',
          cancelLabel: '關閉視窗',
          customLeftBtn: leftButtons,
          customRightBtn: menus.length
            ? [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
            : []
        }, 'custom-width-600');
      }
    } else if (state === 'accepted') {
      // 規格化訂單此時只能指派服務員，非規格化訂單此時要提供報價
      const leftButtons = !reservation.isStandard
        ? [
            { label: '估價資訊', value: '', cb: onValuationInfoClick(reservation), showIndex: true },
            { label: '報價與合約', value: '', cb: onRouteToQuoation(reservation), showIndex: true }
          ]
        : [];
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customLeftBtn: leftButtons,
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      });
    } else if (state === 'quoting') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '請客戶確認報價',
        cancelLabel: '關閉視窗',
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      });
    } else if (state === 'pending-ps1') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '請客戶完成付款',
        cancelLabel: '關閉視窗',
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      });
    } else if (state === 'established') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '請先指派服務員',
        cancelLabel: '關閉視窗',
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      });
    } else if (state === 'staffAssigned') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customLeftBtn: [
          { label: '服務員已到', value: '', cb: onStaffArrived(reservation), showIndex: true },
          { label: '服務中', value: '', cb: onProcessing(reservation), showIndex: true },
          { label: '上傳完工照', value: '', cb: onUploadImages(reservation), showIndex: true, tooltip: imageUploadTooltip }
        ].concat(
          _.includes(['cash', 'bankTransfer'], reservation.defaultPaymentMethod)
            ? [{ label: '收款完成訂單', value: '', cb: onJumpToFinished(reservation), showIndex: true, tooltip: jumpToFinishTooltip }]
            : []
        ),
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      }, 'custom-width-800');
    } else if (state === 'staffArrived') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customLeftBtn: [
          { label: '服務中', value: '', cb: onProcessing(reservation), showIndex: true },
          { label: '上傳完工照', value: '', cb: onUploadImages(reservation), showIndex: true, tooltip: imageUploadTooltip }
        ].concat(
          _.includes(['cash', 'bankTransfer'], reservation.defaultPaymentMethod)
            ? [{ label: '收款完成訂單', value: '', cb: onJumpToFinished(reservation), showIndex: true, tooltip: jumpToFinishTooltip }]
            : []
        ),
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      }, 'custom-width-600');
    } else if (state === 'processing') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customLeftBtn: [
          { label: '服務完成', value: '', cb: onProcessed(reservation), showIndex: true },
          { label: '上傳完工照', value: '', cb: onUploadImages(reservation), showIndex: true, tooltip: imageUploadTooltip }
        ].concat(
          _.includes(['cash', 'bankTransfer'], reservation.defaultPaymentMethod)
            ? [{ label: '收款完成訂單', value: '', cb: onJumpToFinished(reservation), showIndex: true, tooltip: jumpToFinishTooltip }]
            : []
        ),
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      }, 'custom-width-600');
    } else if (state === 'processed') {
      // 客戶驗收
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customLeftBtn: [
          { label: '上傳完工照', value: '', cb: onUploadImages(reservation), showIndex: true, tooltip: imageUploadTooltip }
        ].concat(
          _.includes(['cash', 'bankTransfer'], reservation.defaultPaymentMethod)
            ? [{ label: '收款完成訂單', value: '', cb: onJumpToFinished(reservation), showIndex: true, tooltip: jumpToFinishTooltip }]
            : []
        ),
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      }, 'custom-width-600');
    } else if (state === 'pending-ps3') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customLeftBtn: [
          { label: '已收款', value: '', cb: onPaid(reservation), showIndex: true },
          { label: '上傳完工照', value: '', cb: onUploadImages(reservation), showIndex: true }
        ],
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      }, 'custom-width-600');
    } else if (state === 'acceptanceChecked') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customLeftBtn: [
          { label: '上傳完工照', value: '', cb: onUploadImages(reservation), showIndex: true },
          { label: '收款完成訂單', value: '', cb: onJumpToFinished(reservation), showIndex: true, tooltip: jumpToFinishTooltip }
        ],
        customRightBtn: [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
      }, 'custom-width-600');
    } else if (state === 'completed') {
      confirmDialog.openConfirm({
        hideConfirm: true,
        title: '更改訂單狀態',
        content: '欲更改訂單狀態，請選擇以下操作',
        cancelLabel: '關閉視窗',
        customLeftBtn: [
          { label: '上傳完工照', value: '', cb: onUploadImages(reservation), showIndex: true }
        ],
        customRightBtn: currentUser.hasRole('serviceAdmin')
          ? [{ label: '退款', anchorType: 'dialog-menu-buttons', menus }]
          : []
      });
    }
  });

  const onAccept = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    const [res] = await Promise.all([
      Reservation.one('acceptDispatch').customPOST({
        reservationId: reservation._id,
        note: ''
      }),
      sleepFunc(1)
    ]);

    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '已變更');
      confirmCb();
    }
  });

  const onReject = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    await confirmDialog.openConfirm({
      title: '確認不接單嗎？'
    });
    const res = await Reservation.one('declineDispatch').customPOST({
      reservationId: reservation._id,
      note: ''
    });
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      if (currentUser.hasRole('vender')) {
        ywEvent.emit('REMOVE_ITEM_IN_LIST', res);
      } else {
        ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      }
      ywEvent.emit('SUCCESS', '已變更');
      confirmCb();
    }
  });

  const onValuationInfoClick = (reservation) => $async(async () => {
    const dialogData = {
      title: '估價資訊設定視窗',
      templateUrl: '/view/dialog/valuation-selection.html',
      confirmValue: {
        valuateTime: _.get(reservation, 'valuationInfo.valuateTime'),
        valuateEndTime: _.get(reservation, 'valuationInfo.valuateEndTime'),
        venderValuators: _.get(reservation, 'valuationInfo.venderValuators', [])
      },
      onDateEditClick: $async(async (data) => {
        dialogData.confirmValue = await openValuateDateTimePicker(data);
      }),
      onValuatorEditClick: (valuators) => {
        multiSearchableObjPickerDialog.openConfirm({
          closeCityFilter: true,
          selectedItems: dialogData.confirmValue.venderValuators.map(valuator => valuator._id),
          objResource: VenderStaff,
          customQueryParams: { venderId: _.get(reservation, 'vender._id'), subRoles: ['valuator'] },
          titleField: 'user.fullname',
          onConfirm: $async(async valuators => {
            if (!valuators.length) {
              return ywEvent.emit('ALERT', '請選擇服務員');
            }
            dialogData.confirmValue.venderValuators = valuators;
          })
        });
      }
    };
    const result = await confirmDialog.openConfirm(dialogData);
    const res = await Reservation.one(reservation._id).customPUT({
      valuateTime: result.valuateTime,
      valuateEndTime: result.valuateEndTime,
      venderValuators: result.venderValuators.map(valuator => valuator._id)
    }, 'arrangeValuation');
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      reservation.valuationInfo = res.valuationInfo;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '已變更');
    }
  });

  const onRouteToQuoation = (reservation) => () => routeToQuotation(reservation);

  const onStaffArrived = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    const res = await Reservation.one('attendSite').customPOST({
      reservationId: reservation._id,
      note: ''
    });
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '已變更');
      confirmCb();
    }
  });

  const onProcessing = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    const res = await Reservation.one('processing').customPOST({
      reservationId: reservation._id,
      note: ''
    });
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '已變更');
      confirmCb();
    }
  });

  const onProcessed = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    const res = await Reservation.one('processed').customPOST({
      reservationId: reservation._id,
      note: ''
    });
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '已變更');
      confirmCb();
    }
  });

  const onPaid = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    const { total, isUnsetCoupon } = await getTotal(reservation);
    const paymentMethod = _.get(reservation, 'paymentStageSetting[3].validMethods[0]');
    let res;
    if (isPayableCardBasePayment(total, reservation, paymentMethod)) {
      res = await Order.one('ps3CardPaymentZero').customPOST({
        reservationNo: reservation.reservationNo,
        amount: total
      });
    } else {
      res = await Order.one('payments').customPOST({
        reservationNo: reservation.reservationNo,
        paymentMethod: _.get(reservation, 'paymentStageSetting[3].validMethods[0]'),
        isUnsetCoupon,
        amount: total,
        note: ''
      });
    }
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      const updated = await Reservation.one(reservation._id).customGET();
      ywEvent.emit('UPDATE_ITEM_IN_LIST', updated);
      ywEvent.emit('SUCCESS', '已變更');
      confirmCb();
    }
  });

  const onUploadImages = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    const images = await imageUploadDialog.openConfirm({ title: '上傳完工照' });

    const processingImagesUploadResult = await Reservation.one(reservation._id).customPUT({
      imageIds: images.map(image => image._id),
      action: 'upload'
    }, 'processingImages');
    if (processingImagesUploadResult.error) {
      ywEvent.emit('ALERT', processingImagesUploadResult.error);
    } else {
      ywEvent.emit('UPDATE_ITEM_IN_LIST', processingImagesUploadResult);
      ywEvent.emit('SUCCESS', '已上傳');
      confirmCb();
    }
  });

  const onJumpToFinished = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    // 將訂單從當前狀態一直推進到訂單完成
    const { total, isUnsetCoupon } = await getTotal(reservation);
    const res = await Order.one('payments').customPOST({
      reservationNo: reservation.reservationNo,
      paymentMethod: _.get(reservation, 'paymentStageSetting[3].validMethods[0]'),
      isUnsetCoupon,
      amount: total,
      note: ''
    });
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      const updated = await Reservation.one(reservation._id).customGET();
      ywEvent.emit('UPDATE_ITEM_IN_LIST', updated);
      ywEvent.emit('SUCCESS', '已變更');
      confirmCb();
    }
  });

  const onRefund = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    const words = await Restangular.service('systemSettings').one('consoleCancelledDefaultWords').get();
    if (words.error) {
      return ywEvent.emit('ALERT', words.error);
    }

    const reasonDialogData = {
      title: '確認是否退款？',
      templateUrl: '/view/dialog/cancelled-word-dialog.html',
      customReason: '',
      placeholder: '請輸入',
      disableConfirm: true,
      selected: null,
      words,
      showOtherReasonOption: !currentUser.hasRole('vender'),
      onReasonChange: (reason) => {
        if (reasonDialogData.selected === -1) {
          reasonDialogData.disableConfirm = !reason;
        }
      },
      onSelectionChange: (selected) => {
        reasonDialogData.disableConfirm = selected == null || (selected === -1 && _.isEmpty(reasonDialogData.customReason));
      }
    };
    await confirmDialog.openConfirm(reasonDialogData);
    if (reasonDialogData.selected != null) {
      const selected = reasonDialogData.selected;
      const reason = selected === -1 ? reasonDialogData.customReason : words[selected].content;
      const res = await Reservation.one('cancel').customPOST({
        reservationId: reservation._id,
        note: reason
      });
      if (res.error) {
        ywEvent.emit('ALERT', res.error);
      } else {
        ywEvent.emit('UPDATE_ITEM_IN_LIST', res.reservation);
        ywEvent.emit('SUCCESS', '已退款');
        confirmCb();
      }
    }
  });

  const onDeny = (reservation) => $async(async (value, dialogData, close, confirmCb) => {
    const reasonDialogData = {
      title: '確認無法施作進行退款並加收車馬費？',
      templateUrl: '/view/dialog/deny-reservation-dialog.html',
      reason: '',
      trafficExpenses: null,
      reservation: reservation,
      disableConfirm: true,
      partialDenyChecked: false,
      denyMerchant: null,
      openMerchantSelectDialog: newebpayDetailDialog.openMerchantSelection,
      onValidationCheck: (reason, trafficExpenses) => {
        reasonDialogData.disableConfirm = !reason || !Number.isInteger(trafficExpenses) || trafficExpenses < 0;

        // check partial deny
        if (reasonDialogData.partialDenyChecked) {
          const isZeroOrNegativeInteger = _.isInteger(trafficExpenses) && trafficExpenses <= 0;
          if (reasonDialogData.partialDenyChecked && isZeroOrNegativeInteger) {
            reasonDialogData.partialDenyChecked = false;
            reasonDialogData.denyMerchant = null;
          }
        }
      },
      onPartialDenyChecked: (checked) => {
        if (!checked) {
          reasonDialogData.denyMerchant = null;
        }
      },
      onDenyMerchantConfirm: (merchant) => {
        reasonDialogData.denyMerchant = merchant;
      }
    };
    await confirmDialog.openConfirm(reasonDialogData);
    const { reason, trafficExpenses } = reasonDialogData;
    const res = await Reservation.one('deny').customPOST({
      reservationId: reservation._id,
      note: reason,
      travelingExpense: trafficExpenses,
      merchantOrderNo: _.get(reasonDialogData, 'denyMerchant.orderNo')
    });
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res.reservation);
      ywEvent.emit('SUCCESS', '已退款');
      confirmCb();
    }
  });

  const getTotal = async (reservation) => {
    let total = reservation.total;
    let isUnsetCoupon = false;
    if (!reservation.isStandard) {
      let result = await Restangular.service('orders').one('checkAmountForPayment').customGET('', {
        reservationNo: reservation.reservationNo,
        sourceRestriction: _.get(reservation, 'envInfo.origin')
      });
      if (result.error && _.get(result, 'errorSource.type') === 'CouponUsageError') {
        const errorMsg = _.get(result, 'error') && `<br/>錯誤訊息: ${_.get(result, 'error')}`;
        await confirmDialog.openConfirm({
          htmlContent: `選用之優惠券不符合使用資格，是否移除優惠券後付款? (即付款金額無優惠券折扣)${errorMsg || ''}`
        });
        isUnsetCoupon = true;
        result = await Restangular.service('orders').one('checkAmountForPayment').customGET('', {
          reservationNo: reservation.reservationNo,
          sourceRestriction: _.get(reservation, 'envInfo.origin'),
          isUnsetCoupon
        });
      } else if (result.error) {
        ywEvent.emit('ALERT', `取得結帳金額失敗：${result.error}`);
        throw new Error(result.error);
      }
      total = result.subtotal;
    }
    // 有可能在 checkAmountForPayment 就發現要用 isUnsetCoupon，這時傳出去給 makePayment，以免再跳第二次 dialog
    return { total, isUnsetCoupon };
  };

  // 判斷是否需要使用特殊API(ps3CardPaymentZero)付款
  // 判斷方式：是否為非規格訂單尾款、信用卡類的訂單、額度為0
  const isPayableCardBasePayment = (amount, reservation, paymentMethod) => {
    return reservation.state === 'pending-ps3' &&
      !reservation.isStandard &&
      amount === 0 &&
      _.includes(['card', 'applePay'], paymentMethod);
  };

  util.handleVenderAssign = $async(async ({ reservation }) => {
    const venders = await Reservation.one(reservation._id).customGET('supportVenders');
    if (!venders?.length) return ywEvent.emit('ALERT', '目前沒有廠商可提供服務');
    const venderOptions = venders.map(vender => ({ value: vender._id, label: vender.name }));
    const selected = await popupSelectionDialog.openConfirm({
      title: '廠商',
      type: 'single',
      options: venderOptions
    });
    let reason;
    if (_.get(reservation, 'vender.name')) {
      const venderName = venderOptions.find(option => option.value === selected).label;
      const dialogData = {
        title: '轉單確認',
        content: `確定要將訂單轉給 ${venderName}`,
        templateUrl: '/view/dialog/single-textarea.html',
        confirmValue: '',
        maxlength: 128,
        disableConfirm: true,
        onConfirmValueChange: (text) => {
          dialogData.disableConfirm = !text;
        }
      };
      reason = await confirmDialog.openConfirm(dialogData);
    }
    const res = await Reservation.one('dispatch').customPOST({
      reservationId: reservation._id,
      venderId: selected,
      note: reason
    });
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '已指派廠商');
    }
  });

  util.handleStaffAssign = $async(async ({ reservation }) => {
    multiSearchableObjPickerDialog.openConfirm({
      closeCityFilter: true,
      selectedItems: reservation.venderStaffs || [],
      objResource: VenderStaff,
      customQueryParams: reservation.vender
        ? { venderId: reservation.vender._id, subRoles: ['staff'], limit: 999 }
        : { subRoles: ['staff'], limit: 999 },
      titleField: 'user.fullname',
      searchField: 'name',
      onConfirm: $async(async staffs => {
        if (!staffs.length) {
          return ywEvent.emit('ALERT', '請選擇服務員');
        }
        const res = await Reservation.one('assignStaff').customPOST({
          reservationId: reservation._id,
          venderStaffIds: staffs.map(staff => staff._id),
          note: ''
        });
        if (res.error) {
          ywEvent.emit('ALERT', res.error);
        } else {
          ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
          ywEvent.emit('SUCCESS', '已變更');
        }
      })
    });
  });

  const routeToQuotation = reservation => {
    if (!_.get(reservation, 'vender._id')) {
      ywEvent.emit('ALERT', '尚未指定廠商');
    } else {
      const role = currentUser.getInitialState();
      const venderId = reservation.vender._id;
      window.open(
        `/${role}/reservation/${reservation._id}/quotation?venderId=${venderId}`,
        '_blank'
      );
    }
  };

  util.statusList = reservationStatusList;
  util.completedStatusList = reservationCompletedState;

  const getDisplayDuration = (fromDate) => {
    const from = moment(fromDate);
    return from.isBefore(from.clone().hour(12)) ? ' 上午' : ' 下午';
  };

  const openReviewDialog = $async(async (reservation) => {
    await confirmDialog.openConfirm({
      title: '評價資訊',
      templateUrl: '/view/dialog/reviews-of-reservation.html',
      item: reservation
    });
  });

  const openValuateDateTimePicker = async (data) => {
    const dialogOpts = { plain: true, closeByEscape: true, closeByDocument: true };
    const dialogData = {
      selectedDate: data.valuateTime,
      hour: data.valuateTime ? moment(data.valuateTime).hours() : 8,
      minute: data.valuateTime ? moment(data.valuateTime).minutes() : 0,
      endHour: data.valuateEndTime ? moment(data.valuateEndTime).hours() : undefined,
      endMinute: data.valuateEndTime ? moment(data.valuateEndTime).minutes() : undefined,
      checkInput: () => {
        const hour = dialogData.hour;
        const minute = dialogData.minute;
        const endHour = dialogData.endHour;
        const endMinute = dialogData.endMinute;
        const checkTimeFormat = (hour, minute) => {
          if (!Number.isInteger(hour) || !Number.isInteger(minute) ||
            !(hour >= 0 && hour <= 23) || !(minute >= 0 && minute <= 59)) {
            ywEvent.emit('ALERT', '格式錯誤，小時、分鐘必須為正整數且有效值為（0≦小時≦23，0≦分鐘≦59');
            return false;
          }
          return true;
        };
        return checkTimeFormat(hour, minute) &&
          ((!endHour && !endMinute) || checkTimeFormat(endHour, endMinute));
      }
    };
    await ywDialog.openConfirm(
      '/view/directive/valuate-date-picker.html',
      dialogData,
      null,
      dialogOpts
    );
    const hour = dialogData.hour;
    const minute = dialogData.minute;
    const endHour = dialogData.endHour;
    const endMinute = dialogData.endMinute;
    const valuateTime = moment(dialogData.selectedDate).hour(hour).minutes(minute).startOf('minute');
    const valuateEndTime = _.isNumber(endHour) && _.isNumber(endMinute)
      ? moment(dialogData.selectedDate).hour(endHour).minutes(endMinute).startOf('minute')
      : undefined;
    return {
      ...data,
      valuateTime: valuateTime.toDate(),
      valuateEndTime: valuateEndTime && valuateEndTime.toDate()
    };
  };

  const openDateTimePicker = async (date) => {
    const dialogOpts = { plain: true, closeByEscape: true, closeByDocument: true };
    const dialogData = {
      selectedDate: date,
      hour: 8,
      minute: 0,
      checkInput: () => {
        const hour = dialogData.hour;
        const minute = dialogData.minute;
        if (!Number.isInteger(hour) || !Number.isInteger(minute) ||
          !(hour >= 0 && hour <= 23) || !(minute >= 0 && minute <= 59)) {
          ywEvent.emit('ALERT', '格式錯誤，小時、分鐘必須為正整數且有效值為（0≦小時≦23，0≦分鐘≦59');
          return false;
        }
        return true;
      }
    };
    await ywDialog.openConfirm('/view/directive/expected-date-picker.html', dialogData, null, dialogOpts);
    const hour = dialogData.hour;
    const minute = dialogData.minute;
    const result = moment(dialogData.selectedDate).hour(hour).minutes(minute).startOf('minute');
    return result.toDate();
  };

  const openExpectedDatePicker = $async(async (reservation) => {
    const fromTime = await openDateTimePicker(_.get(reservation, 'bookedSession.from'));
    const session = {
      from: fromTime,
      to: moment(fromTime).clone().endOf('minute').toDate()
    };
    await confirmDialog.openConfirm({
      title: '時間修改確認',
      content: `修改服務時間至 ${moment(session.to).format('YYYY/MM/DD HH:mm')}`
    });

    const res = await Reservation.one(reservation._id).customPUT(
      { bookedSession: session },
      'bookedSession'
    );
    if (res.error) {
      await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
    } else {
      reservation.applyBookedSessionInfo = res.applyBookedSessionInfo;
      reservation.isRenewBookedSession = res.isRenewBookedSession;
      reservation.bookedSession = res.bookedSession;
      reservation.renewBookedSessionLogs = res.renewBookedSessionLogs;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '修改完成');
    }
  });
  const openObjectNoEditor = $async(async (reservation) => {
    const dialogData = {
      title: '修改物件編號',
      templateUrl: '/view/dialog/single-input.html',
      confirmValue: '',
      placeholder: '輸入物件編號',
      disableConfirm: true,
      onInputChange: (value) => {
        dialogData.disableConfirm = !value;
      }
    };
    const value = await confirmDialog.openConfirm(dialogData);
    const res = await Reservation.one(reservation._id).customPUT({ objectNo: value }, 'objectNo');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
    } else {
      reservation.objectNo = res.objectNo;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
      ywEvent.emit('SUCCESS', '修改完成');
    }
  });
  const openClientPhoneEditor = $async(async (reservation) => {
    const dialogData = {
      title: '修改聯絡人電話',
      templateUrl: '/view/dialog/single-input.html',
      confirmValue: '',
      placeholder: '輸入電話',
      disableConfirm: true,
      onInputChange: (value) => {
        dialogData.disableConfirm = !value;
      }
    };
    const value = await confirmDialog.openConfirm(dialogData);
    const res = await Reservation.one(reservation._id).customPUT({ phone: value }, 'basicInfo');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
    } else {
      reservation.phone = res.phone;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
      ywEvent.emit('SUCCESS', '修改完成');
    }
  });
  const openClientNameEditor = $async(async (reservation) => {
    const dialogData = {
      title: '修改聯絡人名稱',
      templateUrl: '/view/dialog/single-input.html',
      confirmValue: '',
      placeholder: '輸入聯絡人名稱',
      disableConfirm: true,
      onInputChange: (value) => {
        dialogData.disableConfirm = !value;
      }
    };
    const value = await confirmDialog.openConfirm(dialogData);
    const res = await Reservation.one(reservation._id).customPUT({ name: value }, 'basicInfo');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
    } else {
      reservation.name = res.name;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
      ywEvent.emit('SUCCESS', '修改完成');
    }
  });
  const openClientEmailEditor = $async(async (reservation) => {
    const dialogData = {
      title: '修改聯絡人email',
      templateUrl: '/view/dialog/single-input.html',
      confirmValue: '',
      placeholder: '輸入聯絡人email',
      disableConfirm: true,
      onInputChange: (value) => {
        dialogData.disableConfirm = !value;
      }
    };
    const value = await confirmDialog.openConfirm(dialogData);
    const res = await Reservation.one(reservation._id).customPUT({ email: value }, 'basicInfo');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
    } else {
      reservation.email = res.email;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
      ywEvent.emit('SUCCESS', '修改完成');
    }
  });
  const openServiceAddressEditor = $async(async (reservation) => {
    const dialogData = {
      title: '修改客戶地址',
      inputLimit: inputLimit.address,
      templateUrl: '/view/dialog/address-info-input.html',
      confirmValue: { hasElevator: false },
      mode: reservation.mode,
      disableConfirm: true,
      countyOptions: address.getSortedCounties,
      districtOptions: [],
      onCountyChange: (county) => {
        dialogData.confirmValue.district = undefined;
        dialogData.districtOptions = address[county];
        dialogData.disableConfirm = true;
      },
      onDistrictChange: (district) => {
        dialogData.disableConfirm = !dialogData.confirmValue.county ||
          !dialogData.confirmValue.district ||
          !dialogData.confirmValue.details;
      },
      onDetailChange: (value) => {
        dialogData.disableConfirm = !dialogData.confirmValue.county ||
          !dialogData.confirmValue.district ||
          !dialogData.confirmValue.details;
      }
    };
    const addressInfo = await confirmDialog.openConfirm(dialogData);
    const res = await Reservation.one(reservation._id).customPUT({ address: addressInfo }, 'basicInfo');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
    } else {
      reservation.address = res.address;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '修改完成');
    }
  });

  const openMoveInAddressEditor = $async(async (reservation, index, isCreation = false) => {
    const dialogData = {
      title: (isCreation ? '新增' : '修改') + '遷入地址',
      inputLimit: inputLimit.address,
      templateUrl: '/view/dialog/address-info-input.html',
      confirmValue: { hasElevator: false, type: index === 0 ? 'main' : 'others' },
      mode: reservation.mode,
      disableConfirm: true,
      countyOptions: address.getSortedCounties,
      districtOptions: [],
      onCountyChange: (county) => {
        dialogData.confirmValue.district = undefined;
        dialogData.districtOptions = address[county];
        dialogData.disableConfirm = true;
      },
      onDistrictChange: (district) => {
        dialogData.disableConfirm = !dialogData.confirmValue.county ||
          !dialogData.confirmValue.district ||
          !dialogData.confirmValue.details;
      },
      onDetailChange: (value) => {
        dialogData.disableConfirm = !dialogData.confirmValue.county ||
          !dialogData.confirmValue.district ||
          !dialogData.confirmValue.details;
      }
    };
    const addressInfo = await confirmDialog.openConfirm(dialogData);
    const moveInAddress = isCreation
      ? _.clone(reservation.moveInAddress).concat([addressInfo])
      : _.clone(reservation.moveInAddress).map((addr, idx) => idx === index ? addressInfo : addr);
    const res = await Reservation.one(reservation._id).customPUT({ moveInAddress }, 'basicInfo');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
    } else {
      reservation.moveInAddress = res.moveInAddress;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '修改完成');
    }
  });

  const onRemoveMoveInAddress = $async(async (reservation, index) => {
    await confirmDialog.openConfirm({
      title: '確定刪除地址？'
    });
    const moveInAddress = _.clone(reservation.moveInAddress).filter((addr, idx) => idx !== index);
    const res = await Reservation.one(reservation._id).customPUT({ moveInAddress }, 'basicInfo');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '刪除失敗', content: res.error });
    } else {
      reservation.moveInAddress = res.moveInAddress;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
      ywEvent.emit('SUCCESS', '刪除完成');
    }
  });

  const openServiceApplyLogDialog = (reservation) => {
    const logs = (reservation.applyBookedSessionLogs || []);
    confirmDialog.openConfirm({
      title: '客戶申請調整服務時間紀錄',
      templateUrl: '/view/dialog/reservation-apply-booked-session-time-logs.html',
      hideCancel: true,
      logs,
      getDisplayDuration
    }, 'custom-width-680');
  };

  const openServiceTimeLogDialog = (reservation) => {
    const logs = (reservation.renewBookedSessionLogs || [])
      .sort((logA, logB) => {
        if (logA.at === logB.at) {
          return 0;
        } else {
          return moment(logA.at).isAfter(logB.at) ? -1 : 1;
        }
      });
    if (logs.length) {
      if (logs[logs.length - 1].prevBookedSession) {
        logs[logs.length - 1].isPrevBookedSessionAM =
          moment(logs[logs.length - 1].prevBookedSession.from).hours() < 11;
      }
    }

    const expectServiceTimes = (reservation.expectServiceTimes || []).map(serviceTime => {
      return {
        date: serviceTime.from,
        isPrevBookedSessionAM: moment(serviceTime.from).hours() < 11
      };
    });

    const dialogData = {
      item: reservation,
      openServiceApplyLogDialog
    };

    confirmDialog.openConfirm({
      title: '服務時間修改歷程',
      isAddIcon: true,
      templateUrl: '/view/dialog/service-time-logs.html',
      hideCancel: true,
      logs,
      expectServiceTimes,
      data: dialogData
    }, 'custom-width-680');
  };

  util.previewAcceptances = $async(async (reservationList = []) => {
    if (_.isEmpty(reservationList)) {
      return;
    }
    ywEvent.emit('ALERT', '背景產生 PDF 中...');
    const url = await createAcceptancesPdf({ reservationList, APP_URL });
    window.open(url);
  });

  util.openAcceptanceNote = $async(async (reservation) => {
    const getOneReservation = await Reservation.one(reservation._id).customGET();
    const saveAcceptanceNote = async (getOneReservation, note) => {
      const res = await Reservation.one(getOneReservation._id).customPUT({ acceptanceNote: note }, 'acceptanceNote');
      if (res.error) {
        return ywEvent.emit('ALERT', res.error);
      }
      getOneReservation.acceptanceNote = res.acceptanceNote;
      ywEvent.emit('SUCCESS', '已更新');
    };

    await ngDialog.openConfirm({
      template: '/view/dialog/acceptance-note-dialog.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog custom-width-680',
      closeByEscape: true,
      closeByDocument: true,
      data: {
        note: getOneReservation.acceptanceNote,
        onPrint: async (note) => {
          note && (await saveAcceptanceNote(getOneReservation, note));
          util.previewAcceptances([getOneReservation]);
        },
        onSave: async (note) => {
          note && (await saveAcceptanceNote(getOneReservation, note));
        }
      }
    });
  });

  // 防止連點，考慮使用者體驗讓 debounce 一開始點擊就觸發，行為結束後不會預設再觸發一次
  util.openDetail = _.debounce($async(async ({ reservation, displayVisible }) => {
    const reservationResource = await Reservation.one(reservation?._id).customGET();
    reservation = _.cloneDeep(reservationResource);
    const state = util.statusList.find(state => state.value === reservation.state);
    const _stateText = _.get(state, 'label');
    const _isReservationComplete = util.completedStatusList.find(state => state === reservation.state);

    const assignedLogs = _.get(reservation, 'stateLogs', []).filter(log => log.newState === 'staffAssigned');

    const dispatchInfoTmpl = assignedLogs.length
      ? assignedLogs.map(log => '<div>' +
        moment(log.at).format('YYYY/MM/DD HH:mm') + ' ' + _.get(log, 'by.fullname', '') +
        '指派給' + _.get(log, 'payload.staffs', []).map(staff => staff.displayName).join('、') +
        '</div>').join('')
      : '尚無派單資訊';

    let userGroupNames;

    if (currentUser.hasRole('serviceAdmin')) {
      const user = await User.one(reservation.user._id).customGET();
      userGroupNames = _.get(user, 'userGroups', []).map(group => group.name).join('、');
    }

    const dialogData = {
      item: reservation,
      _role: currentUser.getRole(),
      _stateText,
      _isReservationComplete,
      fetchRemoveRefReservationOptions: $async(async () => {
        const res = await Promise.all(reservation.refReservations.map(id => Reservation.one(id).customGET()));
        dialogData._commentInput.refReservationOptions = res.map(item => item.reservationNo);
      }),

      dispatchInfoTmpl,

      userInfoTmpl: `
        <div>${reservation.user._id}(點擊複製)</div>
        ${userGroupNames ? `<div>會員群組：${userGroupNames}</div>` : ''}
      `,
      userInfoTmpl2: `
        <div>(點擊開啟功能選項)</div>
        <div>會員ID：${reservation.user._id}</div>
        ${userGroupNames ? `<div>會員群組：${userGroupNames}</div>` : ''}
      `,

      // handlers
      cloneUserId: id => {
        ywUtil.cloneText(id);
        ywEvent.emit('SUCCESS', '已複製');
      },
      filterUserInUserPage: id => {
        const role = currentUser.getInitialState();
        localStorageService.set('customerId', id);
        window.open(`/${role}/customer?userId=${id}`, '_blank');
      },
      showOriginImage: image => window.open(image.s3Url, '_blank'),
      routeToQuotation,
      changeQuotationPaymentState: $async(async (paymentStage, newState) => {
        if (newState !== 'paid') return;
        await confirmDialog.openConfirm({
          title: '轉換付款狀態',
          content: '是否轉換成已付款'
        });
        let paymentMethod = 'cash';
        if (paymentStage.type === 'deposit') {
          paymentMethod = _.get(reservation, 'paymentStageSetting["1"].validMethods[0]');
        } else if (paymentStage.type === 'partial') {
          paymentMethod = _.get(reservation, 'paymentStageSetting["2"].validMethods[0]');
        } else if (paymentStage.type === 'final') {
          paymentMethod = _.get(reservation, 'paymentStageSetting["3"].validMethods[0]');
        }
        const { total: amount, isUnsetCoupon } = await getTotal(reservation);
        let result;
        if (isPayableCardBasePayment(amount, reservation, paymentMethod)) {
          result = await Order.one('ps3CardPaymentZero').customPOST({
            reservationNo: reservation.reservationNo,
            amount
          });
        } else {
          result = await Order.one('payments').customPOST({
            reservationNo: reservation.reservationNo,
            paymentMethod,
            amount,
            isUnsetCoupon
          });
        }
        // 有denyReasons就當作優惠券無法使用，註銷優惠券
        if (result.error && _.get(result, 'errorSource.type') === 'CouponUsageError') {
          const errorMsg = _.get(result, 'error') && `<br/>錯誤訊息: ${_.get(result, 'error')}`;
          await confirmDialog.openConfirm({
            htmlContent: `選用之優惠券不符合使用資格，是否移除優惠券後付款? (即付款金額無優惠券折扣)${errorMsg || ''}`
          });
          result = await Order.one('payments').customPOST({
            reservationNo: reservation.reservationNo,
            paymentMethod,
            amount: paymentStage.amount,
            isUnsetCoupon: true
          });
        }

        if (result.error) {
          ywEvent.emit('ALERT', result.error);
        } else {
          const updated = result.order;
          paymentStage.order.state = updated.state;
          paymentStage.order.stateLogs = updated.stateLogs;
          ywEvent.emit('SUCCESS', '轉換完成');
          const reservation = await Reservation.one(updated.reservation).customGET();
          ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        }
      }),
      onPartialDenyChecked: (checked) => {
        if (!checked) {
          dialogData._denyMerchant = null;
        }
      },
      openReviewDialog,
      getDisplayDuration,
      openExpectedDatePicker,
      openObjectNoEditor,
      openClientNameEditor,
      openClientPhoneEditor,
      openClientEmailEditor,
      openServiceAddressEditor,
      openMoveInAddressEditor,
      onRemoveMoveInAddress,
      openServiceTimeLogDialog,
      openServiceApplyLogDialog,
      openAcceptanceNote: util.openAcceptanceNote,
      displayVisible,
      displayBillsButton: reservation.billProcessParticularStatus !== 'ban'
    };

    await ngDialog.openConfirm({
      template: '/view/dialog/reservation-detail.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog custom-width-800',
      closeByEscape: true,
      closeByDocument: true,
      data: dialogData
    });
  }), 3000, { leading: true, trailing: false });

  util.openPinDialog = $async(async ({ reservation, isPined }) => {
    const pin = !isPined;
    const result = await Restangular.service('pinReservations').one('top')
      .customPUT({ reservationId: reservation._id, pin: pin });
    if (result.error) {
      ywEvent.emit('ALERT', result.error);
    } else {
      const updated = await Reservation.one(reservation._id).customGET();
      ywEvent.emit('UPDATE_ITEM_IN_LIST', updated);
      ywEvent.emit('SUCCESS', '設定完成');
    }
  });

  util.openContactDeadlineDialog = async ({ reservation }) => {
    const onRemoveContactDeadline = async (value, dialogData, closeDialog) => {
      const removeDate = null;
      const result = await Restangular.service('pinReservations').one('connections')
        .customPUT({ reservationId: reservation._id, date: moment(removeDate).startOf('day').toDate(), target: 'reservation' });
      if (result.error) {
        ywEvent.emit('ALERT', result.error);
      } else {
        const updated = await Reservation.one(reservation._id).customGET();
        ywEvent.emit('UPDATE_ITEM_IN_LIST', updated);
        ywEvent.emit('SUCCESS', '設定完成');
        closeDialog();
      }
    };

    const connectionDate = _.get(reservation, 'pinConnectionInfo.date') || _.get(reservation, 'unpinConnectionInfo.date') ||
      _.get(reservation, 'unpinConnectionDate') || _.get(reservation, 'pinConnectionDate');
    const dialogData = {
      title: '設定聯絡日期',
      templateUrl: '/view/dialog/contact-deadline-dialog.html',
      confirmValue: connectionDate,
      customLeftBtn: [
        { label: '解除聯絡日期', value: 'accept', cb: onRemoveContactDeadline, showIndex: true, type: 'warn', disabled: !connectionDate }
      ]

    };
    const date = await confirmDialog.openConfirm(dialogData);
    const result = await Restangular.service('pinReservations').one('connections')
      .customPUT({ reservationId: reservation._id, date: moment(date).startOf('day').toDate() });
    if (result.error) {
      ywEvent.emit('ALERT', result.error);
    } else {
      const updated = await Reservation.one(reservation._id).customGET();
      ywEvent.emit('UPDATE_ITEM_IN_LIST', updated);
      ywEvent.emit('SUCCESS', '設定完成');
    }
  };

  util.openReservationDetailPage = $async(async ({ reservation }) => {
    const reservationId = reservation._id;
    window.open(`${APP_URL}/reservation-detail/${reservationId}`, '_blank');
  });

  return util;
}];
