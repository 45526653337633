export default ['$scope', '$async', 'ywUtil', 'currentUser', 'UserGroup', 'Upload', 'API_URL',
  function controller ($scope, $async, ywUtil, currentUser, UserGroup, Upload, API_URL) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.searchBarOptions = [
      { btnName: '群組名稱', placeholder: '查詢群組名稱', params: 'name' },
      { btnName: '會員ID', placeholder: '查詢會員ID', params: 'userId' },
      { btnName: '會員註冊電話', placeholder: '查詢會員註冊電話', params: 'phone' }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'customer-group'; // for GA

      $scope.mainAction.initResource(UserGroup);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        members: [],
        userGroups: []
      };
    }

    const exportCsv = (params) => {
      window.location = API_URL + 'users/exportCsv?' +
        ywUtil.convertSimpleObjToQueryString(params) +
        '&access_token=' + currentUser.getToken();
    };

    $scope.menuButtons = {
      downloadGroupMAC: (item) => exportCsv({ encodeType: 'utf8', userGroupId: item._id }),
      downloadGroupWindows: (item) => exportCsv({ encodeType: 'big5', userGroupId: item._id })
    };

    const uploadMemberCSV = $async(async (file) => {
      const res = await Upload.upload({
        url: API_URL + 'userGroups/uploadMemberCsv',
        data: { file: file }
      });
      if (_.get(res, 'data.error.code')) {
        $scope.alert(res.data.msg);
      } else {
        $scope.success('完成');
      }
    });

    const openInfoPageOfUploadMemberCSV = () => {
      window.open('https://docs.google.com/spreadsheets/d/1aM6UloOakq2fU-BfwZKI0-UfXAAuulInJq7Lezc_mP0/edit?usp=sharing', '_blank');
    };

    $scope.dropdownItemList = [{
      label: '批次建立會員',
      items: [
        { buttonListText: '上傳會員清單', class: 'glyphicon glyphicon-upload', callback: uploadMemberCSV, file: { accept: '.csv' } },
        { buttonListText: '上傳會員清單說明', class: 'fas fa-info-circle', callback: openInfoPageOfUploadMemberCSV }
      ]
    }];

    $scope.onUserIconClick = (item) => {
      ywUtil.cloneText(item._id);
      $scope.success('已複製');
    };
  }];
