export default ['$async', 'confirmDialog', 'Reservation', function ($async, confirmDialog, Reservation) {
  const getTradeStatus = (status) => {
    const map = {
      notYetPaid: '未付款',
      paid: '付款成功',
      paidFailed: '付款失敗',
      paidCanceled: '取消付款'
    };
    return map[status] || '狀態錯誤';
  };
  const getCloseStatus = (status) => {
    const map = {
      notYetClosed: '未請款',
      waitingClose: '等待提送請款至收單機構',
      closing: '請款處理中',
      closed: '請款完成'
    };
    return map[status] || '狀態錯誤';
  };
  const getBackStatus = (status) => {
    const map = {
      notYetBacked: '未退款',
      waitingBack: '等待提送退款至收單機構',
      backing: '退款處理中',
      backed: '退款完成'
    };
    return map[status] || '狀態錯誤';
  };

  const openDialog = $async(async (reservationNo) => {
    const results = await Reservation.one('paymentInfos').customGET('', { reservationNo });
    const succeededResults = results
      .filter(result => result.communicateStatus === 'SUCCESS')
      .map(result => result.result);
    return confirmDialog.openConfirm({
      title: '查詢藍新金流訂單狀態',
      templateUrl: '/view/dialog/newebpay-details.html',
      items: succeededResults,
      getTradeStatus,
      getBackStatus,
      getCloseStatus,
      hideConfirm: true,
      cancelLabel: '關閉'
    }, 'custom-width-1024');
  });

  const openMerchantSelection = $async(async (reservationNo, confirmCb) => {
    const results = await Reservation
      .one('paymentInfos')
      .customGET('', { reservationNo: reservationNo });
    const succeededResults = results
      .filter(result => result.communicateStatus === 'SUCCESS')
      .map(result => result.result);
    const dialogData = {
      title: '選擇要執行退款的刷卡紀錄',
      templateUrl: '/view/dialog/newebpay-details.html',
      items: succeededResults,
      confirmValue: null,
      onMerchantOrderNoChange: (merchant) => {
        dialogData.ConfirmValue = merchant;
      },
      getTradeStatus,
      getBackStatus,
      getCloseStatus,
      isSelection: true
    };
    const merchant = await confirmDialog.openConfirm(dialogData, 'custom-width-1024');
    confirmCb(merchant);
  });

  return {
    openDialog,
    openMerchantSelection
  };
}];
