export default ['API_URL', '$http', function (API_URL, $http) {
  const shortUrl = urls => {
    return $http({
      method: 'POST',
      url: 'https://link.livinglife.com.tw',
      data: { urls }
    }).then(res => res.data);
  };

  return shortUrl;
}];
