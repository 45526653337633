import Bowser from 'bowser';
export default ['localStorageService', 'API_URL', '$http', 'APP_VERSION', function (localStorageService, API_URL, $http, APP_VERSION) {
  return {
    login: function (username, password) {
      const browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
      return $http.post(API_URL + 'users/login', {
        username,
        password,
        loginTarget: 'adminWeb',
        envInfo: {
          appVersion: APP_VERSION,
          browserName: browser.browser.name,
          browserVersion: browser.browser.version,
          osName: browser.os.name,
          osVersion: browser.os.version
        }
      }).then(function (res) {
        return res.data;
      });
    },
    loginWithSMS: function (phone, code) {
      return $http.post(API_URL + 'users/smsLogin', { phone, code, loginTarget: 'adminWeb' }).then(function (res) {
        return res.data;
      });
    },
    getSMSLoginVerifyCode: function (phone) {
      return $http.post(API_URL + 'verifiedCode/sendSMSForLogin', { phone, loginTarget: 'adminWeb' }).then(function (res) {
        return res.data;
      });
    },
    isValid: function () {
      var currentTimestamp = Math.floor(new Date().getTime() / 1000); // eslint-disable-line
      const currentUser = localStorageService.get('currentUser');
      const expiry = localStorageService.get('expiry') || new Date(0);
      return !(expiry < new Date() || currentUser === undefined);
    },
    clearLogin: function () {
      $http.post(API_URL + 'users/logout').then(function () {
        localStorageService.remove('token', 'refreshToken', 'expiry', 'currentUser', 'pagerLimit');
      });
    },
    clear: function () {
      localStorageService.clearAll();
    },
    set: function (session) {
      if (session._id) { localStorageService.set('token', session._id); }
      if (session.refreshToken) { localStorageService.set('console_slrt', session.refreshToken); }
      if (session.expiry) { localStorageService.set('expiry', new Date(session.expiry)); }
      if (_.get(session, 'user._id')) { localStorageService.set('currentUser', session.user); }
    },
    get _id () { return localStorageService.get('token'); },
    get refreshToken () { return localStorageService.get('console_slrt'); },
    get currentUser () { return localStorageService.get('currentUser'); },
    set currentUser (user) { return localStorageService.set('currentUser', user); },
    get usernameCache () { return localStorageService.get('usernameCache'); },
    set usernameCache (username) { return localStorageService.set('usernameCache', username); },
    get passwordCache () { return localStorageService.get('passwordCache'); },
    set passwordCache(user) { return localStorageService.set('passwordCache', passwordCache); }, // eslint-disable-line
    get paymentTemplate () { return localStorageService.get('paymentTemplate'); },
    set paymentTemplate (paymentData) { return localStorageService.set('paymentTemplate', paymentData); }
  };
}];
