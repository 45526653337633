export default {
  bindings: {
    isChecked: '<',
    hide: '<',
    onClick: '&'
  },
  template:
        '<span ng-click="$ctrl.clicked()" ng-if="!$ctrl.hide" class="img-checkbox img"' +
        'ng-class="{\'checked-list-checkbox\': $ctrl.isChecked}"></span>',
  controller: function TableCheckbox () {
    const ctrl = this;

    ctrl.clicked = () => {
      ctrl.onClick();
    };
  }
};
