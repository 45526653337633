import moment from 'moment';

export default ['TIME_ZONE_OFFSET', function (TIME_ZONE_OFFSET) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      onChange: '=',
      currentDate: '=',
      selectedTimes: '=',
      reservedList: '=',
      reserveTimeLength: '='
    },
    templateUrl: '/view/directive/select-time-in-week.html',
    link
  };

  function link (scope, element) {
    const data = {
      dateList: [],
      selectedTimes: scope.selectedTimes || []
    };
    renewDateList();
    scope.data = data;

    function renewDateList () {
      data.dateList = generateDateList();
    }

    scope.$watch('currentDate', renewDateList);
    scope.$watch('reservedList', renewDateList);

    // 產生7天*48個半小時的資料結構
    function generateDateList () {
      const dateList = [];
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        const dayDisplay = moment(scope.currentDate).add(dayIndex - 3, 'days').utcOffset(TIME_ZONE_OFFSET).format('YYYY/MM/DD');
        const day = { day: dayIndex, dayDisplay, times: [] };
        for (let timeIndex = 0; timeIndex < 48; timeIndex++) {
          const fullDateTime = moment(scope.currentDate).add(dayIndex - 3, 'days').startOf('day').add(timeIndex * 30, 'minutes');
          const [tipsInfo, reservedStatus] = generateTipsContent(dayIndex, timeIndex);
          const timeObj = { fullDateTime, tipsInfo, disabled: false };
          if (_.find(data.selectedTimes, time => moment(time).isSame(fullDateTime))) timeObj.selected = true;
          if (moment().subtract(1, 'day').endOf('day').isAfter(fullDateTime)) timeObj.disabled = true; // 昨天之前時間一率設為disabled
          if (moment().add(89, 'day').endOf('day').isBefore(fullDateTime)) timeObj.disabled = true; // 包含今天的90天內才能預約
          if (reservedStatus === 0) timeObj.disabled = true; // 預約額滿設為disabled
          day.times.push(timeObj);
        }
        dateList.push(day);
      }
      return dateList;
    }

    function generateTipsContent (dayIndex, timeIndex) {
      const currentDate = moment(scope.currentDate).add(dayIndex - 3, 'days').utcOffset(TIME_ZONE_OFFSET);
      const dayDisplay = currentDate.format('YYYY/MM/DD');
      const startTimeDisplay = currentDate.startOf('day').add(timeIndex * 30, 'minutes').format('HH:mm');
      const endTimeDisplay = currentDate.startOf('day').add((timeIndex + 1) * 30, 'minutes').format('HH:mm');
      const reservedStatus = _.get(scope, `reservedList.${dayIndex}.${timeIndex}`, -1); // 預約狀態 -> 數字代表剩餘名額，-1代表不限制人數
      let tipsInfo = `${dayDisplay} ${startTimeDisplay} ~ ${endTimeDisplay}`;
      if (reservedStatus !== -1) tipsInfo += `, 剩餘：${reservedStatus}人`;
      return [tipsInfo, reservedStatus];
    }

    function resetSelected () {
      data.dateList.map((day) => { // eslint-disable-line
        day.times.map((time) => { // eslint-disable-line
          time.selected = false;
        });
      });
      if (scope.onChange) data.selectedTimes = [];
    }

    function mouseDown (el) {
      if (_.isEmpty(el.attr('id'))) return;
      const [day, time] = el.attr('id').split('-');
      const timeOccupied = scope.reserveTimeLength / 0.5; // 預約時段佔用幾個半小時
      if (!data.dateList[day].times[time].disabled) {
        resetSelected();
        for (let index = 0; index < timeOccupied; index++) {
          const targetTimeElement = data.dateList[day].times[Number(time) + index];
          if (!targetTimeElement) return resetSelected(); // 處理超出表格尾端狀況
          targetTimeElement.selected = true; // 將該時間段在畫面上標示為已選取
          // 紀錄已選取時段
          if (!_.isArray(data.selectedTimes)) data.selectedTimes = [];
          data.selectedTimes.push(targetTimeElement.fullDateTime);
          if (targetTimeElement.disabled === true) {
            resetSelected(); // 如果選擇時跨到不開放時段，則清除全部選取
            index = timeOccupied;
          }
        }
        if (scope.onChange) scope.onChange(data.selectedTimes);
      }
    }

    function wrap (fn) {
      return function () {
        const el = angular.element(this);
        scope.$apply(function () {
          fn(el);
        });
      };
    }

    element.delegate('td', 'mousedown', wrap(mouseDown));
  }
}];
