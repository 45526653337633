export default ['$async', 'ngDialog', 'address', function factory ($async, ngDialog, address) {
  /*
   * Params:
   * selectedIds,
   * objResource,
   * titleField,
   * subTitleField,
   * customSelectedFetch,
   * customFilter
   * onConfirm,
   */
  const openConfirm = $async(async function (data) {
    if (!data) return console.log('missing parameters');
    const objResource = data.objResource;
    const titleField = data.titleField;
    const subTitleField = data.subTitleField;
    const selectedIds = _.clone(data.selectedIds);
    let queryParams = { limit: 200, skip: 0 };
    const selectedItemQueryParams = { limit: 200, skip: 0 };

    // fetch selected data list for displaying
    let selectedItems = [];
    if (!data.customSelectedFetch) {
      selectedItems = await Promise.all(selectedIds.map(id => {
        return objResource.one(id).obj ? objResource.one(id).obj.customGET() : objResource.one(id).customGET();
      }));
      selectedItems = selectedItems.map(item => item.obj ? item.obj : item);
    } else {
      selectedItems = await data.customSelectedFetch(selectedItemQueryParams);
    }

    const dialogData = {
      items: [],
      selectedItems: _.cloneDeep(selectedItems),
      _selectedItems: selectedItems,
      titleField: titleField,
      subTitleField: subTitleField,
      setSelected: setSelected,
      removeSelected: removeSelected,
      selectAll: selectAll,
      unSelectAll: unSelectAll,
      customFilter: data.customFilter,
      queryItems: queryItems,
      isLoading: false,
      loadMore,
      queryParams,
      selectedItemQueryItems,
      selectedItemIsLoading: false,
      selectedItemsLoadMore,
      selectedItemQueryParams
    };

    const params = {
      template: '/view/dialog/multi-select-dialog.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog custom-width-800',
      closeByEscape: true,
      closeByDocument: true,
      data: dialogData
    };

    queryItems();
    let enableItemSwitching = false;

    await ngDialog.openConfirm(params);
    data.onConfirm(selectedItems);

    function setSelected (item) {
      dialogData.items.splice(dialogData.items.indexOf(item), 1);
      dialogData.selectedItems.push(item);
      selectedItems.push(item);
    }

    function removeSelected (item) {
      if (!enableItemSwitching) return;
      dialogData.selectedItems.splice(_.findIndex(dialogData.selectedItems, selectedItem => selectedItem._id === item._id), 1);
      selectedItems.splice(_.findIndex(selectedItems, selectedItem => selectedItem._id === item._id), 1);
      dialogData.items.push(item);
    }

    function selectAll () {
      dialogData.selectedItems = dialogData.selectedItems.concat(dialogData.items);
      selectedItems = selectedItems.concat(dialogData.items);
      dialogData.items = [];
    }

    function unSelectAll () {
      dialogData.items = dialogData.items.concat(dialogData.selectedItems);
      dialogData.selectedItems = [];
      selectedItems = [];
    }

    function queryItems (queryTitle, queryField) {
      dialogData.isLoading = true;
      if (queryTitle) {
        queryParams[queryTitle] = queryField;
        queryParams.skip = 0;
      }
      if (data.customParams) queryParams = Object.assign(queryParams, data.customParams);
      objResource.getList(queryParams).then(res => {
        const items = res.plain().map(item => item.obj || item);
        if (queryTitle) {
          dialogData.items = items.filter(item => {
            return !dialogData.selectedItems.find(selected => _.get(selected, 'objectId') === item.objectId);
          });
        } else {
          dialogData.items.push(...(items.filter(item => {
            return !dialogData.selectedItems.find(selected => _.get(selected, 'objectId') === item.objectId);
          })));
        }
        enableItemSwitching = true;
        dialogData.isLoading = false;
      });
    }

    function loadMore () {
      queryParams.skip += 200;
      queryItems();
    }

    async function selectedItemQueryItems (queryTitle, queryField) {
      dialogData.selectedItemIsLoading = true;
      if (queryTitle) {
        selectedItemQueryParams[queryTitle] = queryField;
        selectedItemQueryParams.skip = 0;
      }
      const loadMoreSelectedItemsResult = await data.customSelectedFetch(selectedItemQueryParams);
      if (queryTitle) {
        dialogData.selectedItems = loadMoreSelectedItemsResult;
      } else {
        dialogData.selectedItems = _.concat(dialogData.selectedItems, loadMoreSelectedItemsResult);
      }
      enableItemSwitching = true;
      dialogData.selectedItemIsLoading = false;
    }

    function selectedItemsLoadMore () {
      selectedItemQueryParams.skip += 200;
      selectedItemQueryItems();
    }
  });

  return { openConfirm };
}];
