import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';

const StyledCheckbox = styled(Checkbox)`
    & .MuiSvgIcon-root {
        font-size: 24px;
    }
`;

export default StyledCheckbox;
