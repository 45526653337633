export default ['$scope', '$async', 'Upload', 'address', 'confirmDialog', 'ywUtil', 'User',
  function controller ($scope, $async, Upload, address, confirmDialog, ywUtil, User) {
    const self = this;

    self.avatarFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      console.log('validator:', item);

      if (!item.name) return $scope.alert('請輸入關鍵字名稱');

      if (isEdit && !item.urlId) return $scope.alert('網址辨識名稱不能移除');
      if (item.urlId && !/^[a-z-0-9]+$/.test(item.urlId)) {
        return $scope.alert('網址辨識名稱格式不符');
      }

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      console.log('after transform: ', item);
    }

    function syncCurrentActionItem () {
      // const item = $scope.currentActionItem;

      $scope.inputItems = [
        {
          label: '關鍵字名稱*',
          type: 'text',
          index: 'name'
        },
        {
          label: '排序',
          type: 'number',
          index: 'sort',
          info: '數值越大越前面'
        },
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ]
        },
        {
          label: '網址辨識名稱',
          type: 'text',
          class: 'long',
          index: 'urlId',
          info: '由此自定網址名稱，使用全英文小寫或數字命名，可使用"-"分隔，例 interior-select，不設定則系統預設使用id'
        },
        { type: 'action' }
      ];
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
