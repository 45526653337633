export default ['$async', 'ywEvent', 'confirmDialog', 'Restangular', function ($async, ywEvent, confirmDialog, Restangular) {
  function createHandlerObject (target = 'commonReservation') {
    const handler = {
      labels: [],
      labelsNeedsRemove: [],

      onAddOne: () => handler.labels.push({
        name: '',
        color: '#FFFFFF',
        target
      }),
      onRemoveOne: (index) => {
        const label = handler.labels[index];
        if (label._id) {
          handler.labelsNeedsRemove.push(label);
        }
        handler.labels.splice(index, 1);
      },
      openColorPickerModal: $async(async (label) => {
        const color = await confirmDialog.openConfirm({
          title: '',
          templateUrl: '/view/dialog/color-picker-dialog.html',
          confirmValue: label.color
        });
        label.color = color;
      }),
      onConfirm: $async(async () => {
        if (handler.labels.some(label => !label.name)) return ywEvent.emit('ALERT', '標籤名稱不能為空');
        if (handler.labels.some(label => !label.color)) return ywEvent.emit('ALERT', '標籤顏色不能為空');

        const removePromises = handler.labelsNeedsRemove
          .map(label => Restangular.service('labels').one(label._id).customDELETE());
        const removeResults = await Promise.all(removePromises);
        if (removeResults.some(result => result.error)) {
          removeResults
            .filter(result => result.error)
            .forEach(result => ywEvent.emit('ALERT', result.error));
          return;
        }

        const promises = handler.labels.map(
          label => label._id
            ? Restangular.service('labels').one(label._id).customPUT(label)
            : Restangular.service('labels').post(label)
        );
        const results = await Promise.all(promises);
        if (results.some(result => result.error)) {
          results
            .filter(result => result.error)
            .forEach(result => ywEvent.emit('ALERT', result.error));
        } else {
          handler.labels = results;
          ywEvent.emit('SUCCESS', '儲存完成');
        }
      }),
      fetchLabels: $async(async () => {
        const res = await Restangular.service('labels').getList({ target, limit: 999 });
        if (!res.error) {
          handler.labels = res;
        }
      })
    };
    return handler;
  }
  return { createHandlerObject };
}];
