export default function directive () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      date: '=ywDate',
      disabled: '=ywDisabled',
      onChange: '=ywOnChange',
      showSeconds: '='
    },
    template: '<div ng-disabled="disabled" uib-timepicker ng-model="date" ng-change="onChange" arrowkeys="false" show-seconds="showSeconds" show-meridian="false"></div>'
  };
}
