export default ['$async', 'confirmDialog', 'ywEvent', 'ywUtil', 'Album', 'Keyword', 'Vender',
  function albumUtilFactory ($async, confirmDialog, ywEvent, ywUtil, Album, Keyword, Vender) {
    const util = {};

    // sources 為當前頁面持有的相簿總體，批次更新完後要再把資料寫回被更新的相簿中
    util.openBatchUpdate = async (albumIds = [], sources = []) => {
      const fieldOptions = [
        {
          value: 'isVisible',
          label: '顯示狀態',
          default: { data: true }
        },
        {
          value: 'keywords',
          label: '關鍵字',
          default: { action: 'add', data: [] }
        },
        {
          value: 'navData',
          label: '前往預約按鈕跳轉',
          default: { data: undefined }
        },
        {
          value: 'venderRef',
          label: '關聯廠商',
          default: { data: undefined }
        }
      ];

      const dialogData = {
        title: '批次設定相簿',
        templateUrl: '/view/dialog/batch-update-album.html',
        fieldOptions,
        targetField: _.head(fieldOptions).value,
        onTargetFieldChange: (targetField) => {
          const option = _.find(fieldOptions, opt => opt.value === targetField);
          dialogData.confirmValue = _.clone(_.get(option, 'default', {}));
        },
        resourceMap: {
          keyword: Keyword,
          vender: Vender
        },
        confirmValue: _.clone(_.head(fieldOptions).default)
      };
      const result = await confirmDialog.openConfirm(dialogData);
      const target = dialogData.targetField;
      return handleBatchUpdate(target, albumIds, result, sources);
    };

    const handleBatchUpdate = async (target, albumIds, params, sources) => {
      if (target === 'isVisible') {
        const result = await Album
          .one('visibleState')
          .customPUT({ albumIds, isVisible: params.data });
        replaceBatchUpdatedResult(result, albumIds, { isVisible: params.data }, sources);
      } else if (target === 'keywords') {
        const result = await Album
          .one('keyword')
          .customPUT({ albumIds, keywords: params.data, method: params.action });
        if (result.error) {
          ywEvent.emit('ALERT', result.error);
        } else {
          const keywords = await Promise.all(
            params.data.map(id => Keyword.one(id).customGET())
          );
          _.forEach(sources, (item, index) => {
            if (!item || !_.includes(albumIds, item._id)) { return; }
            if (params.action === 'add') {
              item.keywords = _.unionWith(
                (item.keywords || []),
                keywords,
                (a, b) => a._id === b._id
              );
            } else if (params.action === 'remove') {
              item.keywords = _.differenceWith(
                (item.keywords || []),
                keywords,
                (a, b) => a._id === b._id
              );
            }
            sources[index] = item.clone();
          });
          ywEvent.emit('SUCCESS', '設定完成');
        }
      } else if (target === 'navData') {
        const result = await Album
          .one('navData')
          .customPUT({ albumIds, navData: params.data });
        replaceBatchUpdatedResult(result, albumIds, { navData: params.data }, sources);
      } else if (target === 'venderRef') {
        const result = await Album
          .one('venderRef')
          .customPUT({ albumIds, venderId: params.data });
        replaceBatchUpdatedResult(result, albumIds, { vender: { _id: params.data } }, sources);
      }
    };

    const replaceBatchUpdatedResult = (result, albumIds, updatedData, sources) => {
      if (result.error) {
        ywEvent.emit('ALERT', result.error);
      } else {
        _.forEach(sources, (item, index) => {
          if (!item || !_.includes(albumIds, item._id)) { return; }
          _.assign(item, updatedData);
          sources[index] = item.clone();
        });
        ywEvent.emit('SUCCESS', '設定完成');
      }
    };

    return util;
  }
];
