const generateDynamicLink = (deeplink) => {
  let url = __NODE_ENV__ === 'prod' ? 'https://sinyiliving.page.link' : 'https://livingtest.page.link';
  const apn = 'com.sinyi.living' + (__NODE_ENV__ === 'prod' ? '' : '.debug');
  const ibi = 'com.yowoo.sy.living' + (__NODE_ENV__ === 'prod' ? '' : '.debug');
  url += `?link=${encodeURIComponent(deeplink)}&apn=${apn}&isi=1581712189&ibi=${ibi}`;
  return url;
};

export default ['$async', 'confirmDialog', 'ywUtil', 'ServiceClassification', 'Article', 'Vender', 'WEBAPP_URL', 'Restangular', 'ywEvent', 'shortUrl', function directive ($async, confirmDialog, ywUtil, ServiceClassification, Article, Vender, WEBAPP_URL, Restangular, ywEvent, shortUrl) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      data: '=ywData',
      labelText: '=',
      onConfirm: '=',
      hideOptions: '=',
      hideAction: '=',
      genLink: '=',
      disabled: '=ywDisabled'
    },
    template: `
      <md-button class="md-raised md-primary fit" ng-click="openConfirm(this)" ng-bind="labelText || '設定'" ng-disabled="disabled"></md-button>
      <span ng-if="pageName && actionName">
        目前設定：
        <span ng-if="isInitializing === true">讀取中</span>
        <span ng-if="isInitializing !== true">點擊到-{{ pageName }} 動作-{{ actionName }}</span>
      </span>
    `,
    link: link
  };

  function findLandingTypeByPage (page) {
    const options = ywUtil.landingOptions.options;
    const matchedOption = options.find((option) => option.page === page);
    return _.get(matchedOption, 'type');
  }

  function link (scope, element, attr) {
    const landingOptions = ywUtil.landingOptions.options;
    let navData = scope.data || { param: {} };
    let landingData = navData;

    // display current state by navData
    updateDisplayedDataText(navData);

    function updateDisplayedDataText (data) {
      const { pageName, actionName } = ywUtil.landingOptions.findNames(_.get(data, 'page'), _.get(data, 'action'));
      scope.pageName = pageName;
      scope.actionName = actionName;
    }

    function initLandingDataByCurrentNavData (data) {
      // init landingData for manipluation
      const currentPage = _.get(data, 'page');
      const currentType = findLandingTypeByPage(currentPage);
      const currentOptionIndex = ywUtil.landingOptions
        .findCurrentOptionIndex(currentType, currentPage);
      const currentAction = _.get(data, 'action') ||
        _.head(landingOptions[currentOptionIndex].actions);

      landingData = Object.assign(
        {},
        data,
        {
          type: currentType,
          pageName: landingOptions[currentOptionIndex].pageName,
          action: _.get(data, 'action', currentAction.key),
          param: _.get(data, 'param', {})
        }
      );
    }

    const generateDeeplink = async (domainUrl, data, utm = {}, webview, notes = {}, venders = []) => {
      let url = domainUrl;
      if (data.type === 'openUrl') {
        throw new Error('跳轉連結不支援產生連結');
      } else if (data.type === 'couponList') {
        url += 'coupon';
        if (data.action === 'fill') {
          url += `?fill=${data.param.couponCode}`;
        } else if (data.action === 'addItem') {
          url += `?acquire=${data.param.couponCode}`;
        }
      } else if (data.type === 'serviceClassification') {
        const classification = await ServiceClassification.one(data.param.classificationId).customGET();
        if (data.action === 'introduceService') {
          url += `service/${classification.identityCode}`;
        } else if (data.action === 'bookService') {
          url += `service/${classification.identityCode}/booking`;
        }
      } else if (data.type === 'article') {
        if (data.action === 'none') {
          const article = await Article.one(data.param.articleId).customGET();
          url += `article/${article.articleClassification.urlId}/${article.urlId}`;
        }
      } else if (data.type === 'famiCode') {
        const payload = { ...data.param, title: encodeURIComponent(data.param.title) };
        url += `fami-code-redemption?payload=${btoa(JSON.stringify(payload))}`; // eslint-disable-line
      }
      const notesAndUtm = { ...notes, ...utm };
      const utmString = Object.keys(notesAndUtm)
        .filter(key => notesAndUtm[key])
        .map(key => `${key}=${notesAndUtm[key]}`)
        .join('&');
      const webviewString = webview ? `webview=${webview}` : '';
      // priority vender id
      const venderString = (venders || []).map(id => `pvid=${id}`).join('&');
      const qs = [webviewString, utmString, venderString].filter(value => value).join('&');
      if (qs) {
        return url + (qs && /\?/.test(url) ? `&${qs}` : `?${qs}`);
      } else {
        return url;
      }
    };

    scope.openConfirm = () => {
      const confirmWhenResolve = (notUsedValue, dialogData, confirmCb) => {
        try {
          paramValidation(dialogData.landingData);
          const currentOptionIndex = dialogData.currentOptionIndex;
          const currentOption = landingOptions[currentOptionIndex];
          navData = {
            page: currentOption.page,
            action: dialogData.landingData.action,
            param: dialogData.landingData.param
          };
          scope.data = navData;

          if (scope.onConfirm) scope.onConfirm(navData);
          updateDisplayedDataText(navData);
          confirmCb();
        } catch (e) {
          dialogData.errMsg = e.message;
        }
      };

      const paramValidation = data => {
        console.log('validation: ', data);
        const currentOption = landingOptions[dialogData.currentOptionIndex];
        if (!data.pageName) {
          throw new Error('請選擇 點擊到');
        }
        if (!data.action) {
          throw new Error('請選擇動作');
        }
        _.forEach(_.get(currentOption, 'param', []), param => {
          if ((param.required || _.includes(param.requiredActions, data.action)) && _.isEmpty(data.param[param.key])) {
            throw new Error(`請填寫${param.name}`);
          }
        });
        return true;
      };

      const updateData = (target, val, obj) => {
        dialogData.landingData.param = {};

        if (target === 'page') {
          dialogData.currentOptionIndex = ywUtil.landingOptions
            .findCurrentOptionIndex(obj.type, val);
          const currentOption = landingOptions[dialogData.currentOptionIndex];

          // navData.pageName = obj.pageName;
          dialogData.landingData.type = obj.type;
          // Set first action, if action not match with page.
          const actions = currentOption.actions;
          const isActionMatchWithPage = actions.some(
            action => action.key === dialogData.landingData.action
          );
          if (!isActionMatchWithPage) {
            dialogData.landingData.action = _.head(actions).key;
            dialogData.landingData.actionName = _.head(actions).name;
          }
        }
      };

      // 轉成 yw-popup-selection 的格式
      const landingOptionsData = {
        selected: _.get(navData, 'pageName'),
        options: landingOptions.map((option) => ({
          label: option.pageName,
          value: option.pageName,
          type: option.page,
          hide: option.hide
        })),
        updateLandingOptionsData: (selectedItem) => {
          const page = _.find(landingOptions, item => item.pageName === selectedItem).page;
          const obj = _.find(landingOptions, item => item.pageName === selectedItem);
          updateData('page', page, obj);
        }
      };

      function setSelectedText (text) {
        navData.param.selectedText = text;
      }
      async function onSelectionSelect (selected) {
        if (dialogData.landingData.type === 'article') {
          const article = await Article.one(selected).customGET();
          dialogData.landingData.param.articleClassificationUrlId = article.articleClassification.urlId;
        }
        setSelectedText(selected.title || selected.name);
      }

      initLandingDataByCurrentNavData(navData);
      const dialogData = {
        templateUrl: '/view/dialog/navigation-setting.html',
        errMsg: '',
        landingOptions,
        confirmWhenResolve,
        updateData,
        landingData,
        landingOptionsData,
        webviewOptions: [],
        venders: {
          value: [],
          resource: Vender,
          info: `
          優先指派廠商邏輯</br>
          1. 用戶下單後，如果連結有設定優先指派廠商，且廠商有符合指派條件下，則在優先指派廠商內，以目前的自動派單邏輯輪替指派，此時不會指派到非優先指派廠商身上</br>
          2. 優先指派廠商皆不符指派條件，則以目前原派單邏輯，進行符合條件之廠商輪派</br>

          `
        },
        useShortUrl: false,
        onWebviewSelectClick: $async(async () => {
          if (!_.get(dialogData, 'webviewOptions.length')) {
            const settings = await Restangular.service('systemSettings').getList();
            const sources = _.get(_.find(settings, setting => setting.key === 'sourceRestrictionSetting'), 'value', []);
            dialogData.webviewOptions = sources;
          }
        }),
        currentOptionIndex: ywUtil.landingOptions
          .findCurrentOptionIndex(landingData.type, landingData.page),
        loadOptions: $async(async (param) => {
          if (param.key === 'classificationId' && !_.get(dialogData, '_classificationIdOptions.length')) {
            const res = await ServiceClassification.getList({ limit: 999, displayVisible: 'all' });
            dialogData._classificationIdOptions = res;
          } else if (param.key === 'articleId' && !_.get(dialogData, '_articleIdOptions.length')) {
            const res = await Article.getList();
            dialogData._articleIdOptions = res;
          }
        }),
        onSelectionSelect,
        showGenLinkOptions: scope.genLink,
        generateLink: $async(async () => {
          try {
            const deeplink = await generateDeeplink(WEBAPP_URL, dialogData.landingData, dialogData.utmData, dialogData.webview, dialogData.notes, dialogData.venders.value);
            const dynamicLink = generateDynamicLink(deeplink);
            if (dialogData.useShortUrl) {
              const shortUrls = await shortUrl([deeplink, dynamicLink]);
              if (shortUrls.error) throw new Error(shortUrls.error);
              dialogData.generatedData = {
                deeplink: shortUrls[deeplink],
                dynamicLink: shortUrls[dynamicLink]
              };
            } else {
              dialogData.generatedData = {
                deeplink,
                dynamicLink
              };
            }
          } catch (err) {
            ywEvent.emit('ALERT', err.message);
          }
        })
      };

      // 為了顯示開啟dialg時目前所選的名稱，預先讀取options
      if (_.get(landingData, 'page') === 'serviceClassification') {
        landingOptions[dialogData.currentOptionIndex].param.map(param => { // eslint-disable-line
          dialogData.loadOptions(param);
        });
      }

      confirmDialog.openConfirm(dialogData, 'custom-width-600');
    };

    scope.$watch('data', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      updateDisplayedDataText(scope.data);
    });
  }
}];
