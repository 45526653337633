export default ['Restangular', 'ywUtil', 'currentUser', 'ywTimezone',
  function factory (Restangular, ywUtil, currentUser, ywTimezone) {
    // reformat UserGroup object
    Restangular.addElementTransformer('userGroups', false, _transformer);
    const UserGroup = Restangular.service('userGroups');
    return UserGroup;

    function _transformer (item) {
      if (item.error) return item;
      if (typeof item === 'number') return item; // count

      item._columns = [
        { anchorType: 'checkbox' },
        { anchorType: 'user-icon' },
        _.get(item, 'name', ''),
        _.get(item, 'memberCount', 0),
        {
          anchorType: 'menu-buttons',
          btnIconClass: 'glyphicon glyphicon-download',
          btnList: [
            { name: 'downloadGroupMAC', label: '會員資料', class: 'fab fa-apple' },
            { name: 'downloadGroupWindows', label: '會員資料', class: 'fab fa-windows' }
          ]
        },
        { anchorType: 'edit' }
      ];
      return item;
    }
  }
];
