import listController from './list';
import actionController from './action';

export default ['$scope', '$controller', 'currentReservation', function controller ($scope, $controller, currentReservation) {
  // inherit
  $controller('mainActionController', { $scope: $scope });

  $scope.$on('$destroy', function () { $scope.mainAction.pager.clear(); });

  $scope.listController = listController;
  $scope.actionController = actionController;
  $scope.pageStates = {};
  $scope.currentPage = 'quotation';
  $scope.currentPageState = 'list';

  init();

  function init () {
    /** define in-page states. use templateUrl if general templates unsufficient */
    const no = currentReservation.reservationNo;
    const name = currentReservation.name;
    const phone = currentReservation.phone;
    $scope.pageStates = {
      list: { customLabel: `報價與合約 訂單編號：${no}, 聯絡人：${name}, 電話：${phone}` },
      add: { customLabel: `新增報價與合約 訂單編號：${no}, 聯絡人：${name}, 電話：${phone}` },
      edit: { customLabel: `編輯報價與合約 訂單編號：${no}, 聯絡人：${name}, 電話：${phone}` }
    };
  }
}];
