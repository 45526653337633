export default ['$scope', '$async', '$state', 'ywUtil', 'currentUser', 'ServiceClassification', 'Service',
  function controller ($scope, $async, $state, ywUtil, currentUser, ServiceClassification, Service) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.filterItems = [
      {
        name: 'classificationId',
        label: '服務分類',
        options: [],
        changed: (id) => {
          $scope.mainAction.queryParams.classificationId = id;
          $scope.mainAction.setClearFilterTrue();
        },
        resource: ServiceClassification,
        type: 'searchableFilter',
        filterField: 'classificationName',
        extraParams: { displayVisible: 'all' }
      }
    ];

    $scope.searchBarOptions = [
      {
        btnName: '服務方案名稱',
        placeholder: '請輸入服務方案名稱',
        params: 'name'
      }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'service'; // for GA

      $scope.mainAction.initResource(Service, { displayVisible: 'all' });

      const clearFilter = $scope.mainAction.clearFilter;
      $scope.mainAction.clearFilter = () => clearFilter(['displayVisible'], $scope.searchBar.textInput);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        stockDeductionMethod: 'multiple',
        paymentMethods: ['cash'],
        isStandard: true,
        isActivate: false,
        specialPrices: [],
        customizations: [],
        acceptanceItems: [],
        _acceptanceItems: [],
        specialBasicPrices: [],
        notifyMode: 'normal'
      };
    }
  }];
