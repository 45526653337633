import moment from 'moment';

export default ['$scope', '$async', 'Restangular', 'CouponEvent', 'confirmDialog', 'WEBAPP_URL', 'ywUtil', 'labelManagement', function controller ($scope, $async, Restangular, CouponEvent, confirmDialog, WEBAPP_URL, ywUtil, labelManagement) {
  $scope.CouponEvent = CouponEvent;
  $scope.sourceFilterItems = {
    label: '限定通路',
    options: [],
    type: 'multiFilter'
  };

  const loadSystemSetting = $async(async () => {
    const settings = await Restangular.service('systemSettings').one().get({ limit: 99 });
    if (settings.error) {
      $scope.alert(settings.error);
    } else {
      $scope.settingMap = settings.reduce((result, setting) => ({
        ...result,
        [setting.key]: setting
      }), {});

      $scope.filteredNationalHoliday = _.get($scope.settingMap, 'nationalHoliday.value', [])
        .filter(date => moment(date).isSameOrAfter(moment().startOf('date')));
      $scope.filteredMakeUpDay = _.get($scope.settingMap, 'makeUpDay.value', [])
        .filter(date => moment(date).isSameOrAfter(moment().startOf('date')));
      $scope.firstRegisterSetting.data = _.get($scope.settingMap, 'firstRegisteredCouponEvent.value', []) || [];

      $scope._autoDispatchPriority =
        Object.entries(_.get($scope.settingMap, 'autoDispatchPriority.value', {}))
          .reduce((result, entry, index) => ({
            ...result,
            [`entry${index + 1}`]: { name: entry[0], value: entry[1] }
          }), {});
    }
  });

  // 取得限定通路 options data
  const loadOriginOptions = $async(async () => {
    const defaultOptions = [
      { value: 'unlimited', label: '不限定通路' },
      { value: 'lsApp', label: '信義居家App' },
      { value: 'lsWeb', label: '信義居家Web' },
      { value: 'ios', label: 'iOS' },
      { value: 'android', label: 'Android' },
      { value: 'web', label: 'web' }
    ];
    const settings = await Restangular.service('systemSettings').one('sourceRestrictionSetting').get();
    const sourceSettingOptions = settings.error
      ? []
      : settings.map(setting => ({ value: setting, label: setting }));
    $scope.sourceFilterItems.options = defaultOptions.concat(sourceSettingOptions);
  });
  loadSystemSetting();
  loadOriginOptions();

  $scope.onAutoDispatchPriorityConfirm = $async(async () => {
    const values = Object.values($scope._autoDispatchPriority);
    if (values.some(value => !value.name)) {
      return $scope.alert('辨別名稱不可為空');
    } else if (values.some(value => !_.isNumber(value.value))) {
      return $scope.alert('指派等級不可為空');
    }

    const value = values.reduce((result, item) => ({
      ...result,
      [item.name]: item.value
    }), {});
    $scope.status.isLoading = true;
    const res = await Restangular.service('systemSettings').one('autoDispatchPriority')
      .customPUT({ value });
    if (res.error) {
      $scope.alert(res.error);
    } else {
      $scope.success('儲存完成');
    }
    $scope.status.isLoading = false;
  });

  $scope.openNationalHolidayDialog = $async(async () => {
    const sources = _.get($scope.settingMap, 'nationalHoliday.value', [])
      .map(date => new Date(date).setHours(0, 0, 0, 0));
    const dialogData = {
      title: '設定國定假日',
      templateUrl: '/view/dialog/date-picker.html',
      confirmValue: _.clone(sources),
      datePickerOptions: {
        customClass: function (data) {
          if (dialogData.confirmValue.indexOf(data.date.setHours(0, 0, 0, 0)) > -1) {
            return 'multiple selected';
          }
          return 'multiple';
        },
        dateDisabled: function (data) {
          const day = data.date.getDay();
          return day === 6 || day === 0;
        }
      }
    };
    const dateList = await confirmDialog.openConfirm(dialogData);
    const isoStrings = dateList.map(date => new Date(date).toISOString());
    $scope.status.isLoading = true;
    const res = await Restangular.service('systemSettings').one('nationalHoliday').customPUT({
      ...$scope.settingMap.nationalHoliday,
      value: isoStrings
    });
    if (res.error) {
      $scope.alert(res.error);
    } else {
      $scope.settingMap.nationalHoliday = res;
      $scope.filteredNationalHoliday = _.get(res, 'value', [])
        .filter(date => moment(date).isSameOrAfter(moment().startOf('date')));
      $scope.success('設定完成');
    }
    $scope.status.isLoading = false;
  });

  $scope.openMakeUpDayDialog = $async(async () => {
    const sources = _.get($scope.settingMap, 'makeUpDay.value', [])
      .map(date => new Date(date).setHours(0, 0, 0, 0));
    const dialogData = {
      title: '設定補班日',
      templateUrl: '/view/dialog/date-picker.html',
      confirmValue: _.clone(sources),
      datePickerOptions: {
        customClass: function (data) {
          if (dialogData.confirmValue.indexOf(data.date.setHours(0, 0, 0, 0)) > -1) {
            return 'multiple selected';
          }
          return 'multiple';
        },
        dateDisabled: function (data) {
          const day = data.date.getDay();
          return day !== 6 && day !== 0;
        }
      }
    };
    const dateList = await confirmDialog.openConfirm(dialogData);
    const isoStrings = dateList.map(date => new Date(date).toISOString());
    $scope.status.isLoading = true;
    const res = await Restangular.service('systemSettings').one('makeUpDay').customPUT({
      ...$scope.settingMap.makeUpDay,
      value: isoStrings
    });
    if (res.error) {
      $scope.alert(res.error);
    } else {
      $scope.settingMap.makeUpDay = res;
      $scope.filteredMakeUpDay = _.get(res, 'value', [])
        .filter(date => moment(date).isSameOrAfter(moment().startOf('date')));
      $scope.success('設定完成');
    }
    $scope.status.isLoading = false;
  });

  $scope.firstRegisterSetting = {
    data: [],
    sourceFilters: $scope.sourceFilterItems,
    addRow: () => $scope.firstRegisterSetting.data.push({
      couponEvents: [],
      origins: []
    }),
    removeRow: (index) => {
      $scope.firstRegisterSetting.data.splice(index, 1);
    }
  };

  // 首次註冊優惠儲存及驗證
  $scope.onFirstRegisteredCouponEventConfirm = $async(async () => {
    const firstRegisterSettingData = $scope.firstRegisterSetting.data;
    const result = firstRegisterSettingData.every(data => {
      return data.couponEvents.length && data.origins.length;
    });
    if (!result) return $scope.alert('註冊優惠任一欄位不得為空');
    $scope.status.isLoading = true;
    const res = await Restangular.service('systemSettings').one('firstRegisteredCouponEvent')
      .customPUT({
        value: firstRegisterSettingData
      });
    if (res.error) {
      $scope.alert(res.error);
    } else {
      $scope.success('設定完成');
    }
    $scope.status.isLoading = false;
  });

  $scope.on3DVerrificationThresholdConfirm = $async(async () => {
    $scope.status.isLoading = true;
    const res = await Restangular.service('systemSettings').one('3DVerificationThreshold')
      .customPUT({
        value: $scope.settingMap['3DVerificationThreshold'].value
      });
    if (res.error) {
      $scope.alert(res.error);
    } else {
      $scope.success('儲存完成');
    }
    $scope.status.isLoading = false;
  });

  $scope.onAppVersionUpdateConfirm = $async(async () => {
    $scope.status.isLoading = true;
    const res = await Restangular.service('systemSettings').one('appVersionUpdateSetting')
      .customPUT({
        value: $scope.settingMap.appVersionUpdateSetting.value
      });
    if (res.error) {
      $scope.alert(res.error);
    } else {
      $scope.success('儲存完成');
    }
    $scope.status.isLoading = false;
  });

  $scope.salesPromotion = {
    onAddOne: () => {
      $scope.settingMap.salesPromotionSetting.value.push({ downloads: [] });
    },
    onRemoveOne: (index) => {
      $scope.settingMap.salesPromotionSetting.value.splice(index, 1);
    },
    onFileEditClick: $async(async (index) => {
      const files = _.get($scope.settingMap.salesPromotionSetting.value[index], 'downloads', [])
        .map(info => info.file);
      const dialogData = {
        title: '相關下載',
        templateUrl: '/view/dialog/upload-files.html',
        confirmValue: files,
        files: [],
        uploadSuccess: (file) => dialogData.confirmValue.push(file),
        uploadError: (err) => $scope.alert(err),
        removeOne: (index) => dialogData.confirmValue.splice(index, 1),
        downloadFile: (file) => ywUtil.downloadFile(file.url, file.name)
      };
      const newFiles = await confirmDialog.openConfirm(dialogData, 'custom-width-680');
      $scope.settingMap.salesPromotionSetting.value[index].downloads = newFiles
        .map(file => ({ file: file, at: file.createdAt }));
    }),
    onBannerUploadSuccess: (result, index) => {
      $scope.settingMap.salesPromotionSetting.value[index].banner = result;
    },
    onRemoveBanner: (index) => {
      $scope.settingMap.salesPromotionSetting.value[index].banner = null;
    },
    onCloneLink: (index) => {
      const coupon = $scope.settingMap.salesPromotionSetting.value[index].coupon;
      ywUtil.cloneText(`${WEBAPP_URL}_qrcode_generators/sales-promotion?pmCode=${coupon}`);
      $scope.success('複製完成');
    },
    onSettingConfirm: $async(async () => {
      const settings = $scope.settingMap.salesPromotionSetting.value;
      if (settings.some(setting => !setting.coupon)) {
        return $scope.alert('優惠碼不得為空');
      }
      const nonRepeatCoupons = _.chain(settings)
        .map(setting => setting.coupon)
        .uniq()
        .value();
      if (settings.length !== nonRepeatCoupons.length) {
        return $scope.alert('優惠碼不得重複');
      }
      if (settings.some(setting => !setting.banner)) {
        return $scope.alert('請上傳Banner');
      }

      $scope.status.isLoading = true;
      const res = await Restangular.service('systemSettings').one('salesPromotionSetting')
        .customPUT({
          value: $scope.settingMap.salesPromotionSetting.value.map(setting => ({
            ...setting,
            banner: setting.banner._id,
            downloads: _.get(setting, 'downloads', []).map(info => ({
              file: info.file._id,
              at: info.at
            }))
          }))
        });
      if (res.error) {
        $scope.alert(res.error);
      } else {
        $scope.success('儲存完成');
      }
      $scope.status.isLoading = false;
    })
  };

  $scope.labelSetting = labelManagement.createHandlerObject();
  $scope.labelSetting.fetchLabels();

  $scope.onRecommendedDiscountSwitchChange = $async(async () => {
    $scope.status.isLoading = true;
    const res = await Restangular.service('systemSettings').one('recommendedDiscountSwitch')
      .customPUT({
        value: $scope.settingMap.recommendedDiscountSwitch.value
      });
    if (res.error) {
      $scope.alert(res.error);
    } else {
      $scope.success('設定完成');
    }
    $scope.status.isLoading = false;
  });
  $scope.onRecommendedDiscountSettingConfirm = $async(async () => {
    const header = $scope.settingMap.recommendedDiscountSetting.value.recommendHeader || '';
    const headerLines = header.split('\n');
    if (headerLines.length > 2) {
      return $scope.alert('標題最多2行');
    }
    if (headerLines.some(line => line && line.length > 8)) {
      return $scope.alert('標題每行上限8個字');
    }

    $scope.status.isLoading = true;
    const res = await Restangular.service('systemSettings').one('recommendedDiscountSetting')
      .customPUT({
        value: $scope.settingMap.recommendedDiscountSetting.value
      });
    if (res.error) {
      $scope.alert(res.error);
    } else {
      $scope.success('儲存完成');
    }
    $scope.status.isLoading = false;
  });

  $scope.freeStateSetting = {
    data: [],
    serviceStageOptions: [
      { value: 'reserve', label: '預約服務' },
      { value: 'planning', label: '規劃提案' },
      { value: 'contract', label: '簽訂合約' },
      { value: 'processing', label: '付款施作' },
      { value: 'acceptance', label: '驗收評價' },
      { value: 'cancelled', label: '訂單取消' }
    ],
    removedList: [],
    addRow: () => $scope.freeStateSetting.data.push({}),
    removeRow: (index) => {
      const [removed] = $scope.freeStateSetting.data.splice(index, 1);
      if (removed._id) {
        $scope.freeStateSetting.removedList.push(removed);
      }
    },
    rowMoved: (index) => $scope.freeStateSetting.data.splice(index, 1),
    onCopyId: (id) => {
      ywUtil.cloneText(id);
      $scope.success('已複製');
    },
    trueValue: true,
    onIsInitialClick: (item) => {
      $scope.freeStateSetting.data.forEach(state => {
        state.isInitial = state === item;
      });
    },
    onIsCompletedChange: (item) => {
      if (item.isCompleted && item.isCancelled) {
        item.isCancelled = false;
      }
    },
    onIsCancelledChange: (item) => {
      if (item.isCompleted && item.isCancelled) {
        item.isCompleted = false;
      }
    },
    loadStates: $async(async () => {
      const states = await Restangular.service('freeStates').getList({ limit: 999 });
      if (!states.error) {
        $scope.freeStateSetting.data = states.plain();
      }
    }),
    onConfirm: $async(async () => {
      const isRequiredTextEmpty = (state) => !state.displayNameAtConsole || !state.displayNameAtClient;
      if ($scope.freeStateSetting.data.some(isRequiredTextEmpty)) {
        return $scope.alert('後台、客戶端狀態名稱皆必填');
      }
      if ($scope.freeStateSetting.data.some(state => !state.serviceStage)) {
        return $scope.alert('請選擇服務階段');
      }
      const hasConflict = (state) => {
        const truePoints = ['isInitial', 'isCompleted', 'isCancelled']
          .map(key => state[key] ? 1 : 0)
          .reduce((result, count) => result + count, 0);
        return truePoints > 1;
      };
      if ($scope.freeStateSetting.data.some(hasConflict)) {
        return $scope.alert('狀態不得同時為初始、完成或取消');
      }

      $scope.status.isLoading = true;

      // setup orders
      $scope.freeStateSetting.data.forEach((state, index) => {
        state.sort = $scope.freeStateSetting.data.length - index;
      });

      // remove items in removedList
      const removeResults = await Promise.all($scope.freeStateSetting.removedList.map(
        state => Restangular.service('freeStates').one(state._id).remove()
      ));
      const firstRemoveError = removeResults.find(result => result.error);
      if (firstRemoveError) {
        $scope.alert('移除時發生錯誤');
      }

      const promises = $scope.freeStateSetting.data.map(state => state._id
        ? Restangular.service('freeStates').one(state._id).customPUT(state)
        : Restangular.service('freeStates').post(state)
      );
      const results = await Promise.all(promises);
      const firstError = results.find(result => result.error);
      if (firstError) {
        $scope.alert(firstError.message);
      } else {
        $scope.freeStateSetting.data = results.map(result => result.plain());
      }

      if (!firstError && !firstRemoveError) {
        $scope.success('設定完成');
      }
      $scope.status.isLoading = false;
    })
  };
  $scope.freeStateSetting.loadStates();
}];
