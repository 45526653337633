export default ['$scope', '$stateParams', '$async', '$state', 'ywUtil', 'ywEvent', 'currentUser', 'Restangular', 'Photo', 'PhotoTag', 'popupSelectionDialog', 'confirmDialog',
  function controller ($scope, $stateParams, $async, $state, ywUtil, ywEvent, currentUser, Restangular, Photo, PhotoTag, popupSelectionDialog, confirmDialog) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      // ywUtil.initSearchBarOptions(
      // $scope,
      // [
      // ]
      // );

      $scope.filterItems = [
      ];

      const openMultiPhotoUploadDialog = () => {
        const dialogData = {
          templateUrl: '/view/dialog/yw-dropzone.html',
          confirmValue: [],
          confirmWhenResolve: async (files, dialogData, confirmCb) => {
            dialogData.disableConfirm = true;
            const uploadedFiles = files.filter(file => !file._notUploadedYet);
            const promises = uploadedFiles.map(file => Photo.post({
              album: $stateParams.albumId,
              name: file.originalFilename,
              image: file._id
            }));
            const results = await Promise.all(promises);
            if (results.some(result => result.error)) {
              results
                .filter(result => result.error)
                .forEach(result => $scope.alert(result.error));
            } else {
              if (!$scope.items.find(photo => photo.isFrontCover)) {
                const photo = _.head(results);
                await Photo.one(photo._id).customPUT({}, 'frontCover');
              }
              $scope.success('上傳完畢');
              $scope.mainAction.filterQuery();
            }
            dialogData.disableConfirm = false;
            confirmCb();
          }
        };
        confirmDialog.openConfirm(dialogData);
      };
      $scope.dropdownItemList = [
        {
          label: '新增',
          items: [
            {
              buttonListText: '單筆相片',
              callback: () => {
                $scope.changePageState('add', $scope.mainAction._resource.one().clone());
                $scope.initActionItem();
              }
            },
            { buttonListText: '多筆上傳', callback: openMultiPhotoUploadDialog }
          ]
        }
      ];

      $scope.queryPage = ''; // for GA

      $scope.mainAction.initResource(Photo, { album: $stateParams.albumId });

      const clearFilter = $scope.mainAction.clearFilter;
      $scope.mainAction.clearFilter = () => clearFilter(['album'], $scope.searchBar.textInput);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true,
        sort: 0,
        tags: []
      };
    }

    const convertPhotoToPutData = (item) => ({
      ...item,
      image: typeof item.image === 'string' ? item.image : item.image._id,
      tags: (item.tags || []).map(tag => typeof tag === 'string' ? tag : tag._id)
    });

    $scope.onEditTag = $async(async (item) => {
      const [allPhotoClassifications, allTags] = await Promise.all([
        Restangular.service('photoClassifications').getList({ limit: 999 }),
        PhotoTag.getList({ limit: 999 })
      ]);
      const tagOptions = allTags.map(tag => {
        const photoClassification = allPhotoClassifications
          .find(item => item._id === tag.photoClassification);
        return {
          label: tag.name,
          value: `${photoClassification._id}-${tag._id}`,
          category: photoClassification.name
        };
      });
      const results = await popupSelectionDialog.openConfirm({
        title: '設定相片分類標籤',
        type: 'multiple',
        options: tagOptions,
        selectedList: (item.tags || []).map(tag => `${tag.photoClassification}-${tag._id}`),
        byCategory: true,
        // hideCategory: scope.hideCategory,
        imageUrl: item?.image?.url || '/img/photo-tag-default-image.jpg',
        showFooter: true
      });
      const tags = results.map(value => value.split('-')[1]);
      const res = await Photo.one(item._id).customPUT({ ...convertPhotoToPutData(item), tags });
      if (res.error) {
        $scope.alert(res.error);
      } else {
        const index = _.findIndex($scope.items, source => source._id === item._id);
        $scope.items[index] = res.clone();
        $scope.success('設定完成');
      }
    });

    $scope.onFrontCoverChange = $async(async (item) => {
      item.isFrontCover = false;
      if (item.isFrontCover) {
        return;
      }
      const res = await Photo.one(item._id).customPUT({}, 'frontCover');
      if (res.error) {
        item.isFrontCover = false;
        $scope.alert(res.error);
      } else {
        $scope.items.forEach(photo => {
          photo.isFrontCover = photo._id === item._id;
        });
        $scope.success('設定完成');
      }
    });

    $scope.previewImageHandler = {
      previewImages: (id) => {
        const imageUrls = $scope.currentItems.map(item => _.get(item, 'image.url'));
        const index = $scope.currentItems.findIndex(item => item._id === id);
        ywEvent.emit('OPEN_IMAGE_VIEWER', { imageUrls, index });
      }
    };
  }];
