import moment from 'moment';
export default ['$scope', '$async', 'ywUtil', 'Bill', 'Restangular', 'API_URL', 'currentUser', 'confirmDialog', 'Vender', 'ngDialog', 'UnorderedReservation', 'Upload',
  function controller ($scope, $async, ywUtil, Bill, Restangular, API_URL, currentUser, confirmDialog, Vender, ngDialog, UnorderedReservation, Upload) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.accountProcessingTime = function () {
      const format = 'hh:mm';
      const now = moment();
      if (now.isBetween(moment('02:00', format), moment('03:00', format)) ||
        ((now.date() === 19 && now.hour() >= 22) ||
          (now.date() === 20 && now.hour() < 7))) {
        $scope.alert('每日凌晨2:00~3:00及每月19號22:00~20號07:00帳務處理中，功能暫停使用。');
        return false;
      } else {
        return true;
      }
    };

    const labelInput = {
      name: 'venderLabels',
      label: '廠商標籤',
      options: [],
      changed: $scope.mainAction.setClearFilterTrue,
      type: 'colorFilter',
      click: $async(async () => {
        const res = await Restangular.service('labels').getList({ limit: 999, target: 'vender' });
        if (!res.error) {
          labelInput.options = res.map(label => ({
            label: label.name,
            value: label._id,
            color: label.color
          }));
        }
      })
    };

    $scope.filterItems = [
      {
        name: 'state',
        label: '單據狀態',
        options:
          [
            { value: 'established', label: '尚未處理' },
            { value: 'processed', label: '已處理' }
          ].concat(currentUser.hasRole('serviceAdmin') || currentUser.hasRole('vender')
            ? [{ value: 'invalid', label: '作廢' }]
            : []),
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'multiFilter'
      },
      {
        name: 'type',
        label: '單據類型',
        options: [
          { value: 'referralFeeInvoice', label: '轉介費帳單' },
          { value: 'serviceInvoice', label: '收款單' },
          { value: 'refundment', label: '退款單' }
          // { value: 'couponInvoice', label: '優惠券請款單' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'multiFilter'
      }
    ].concat(currentUser.hasRole('serviceAdmin')
      ? [labelInput,
          {
            name: 'checkingState',
            label: '異常狀態',
            options:
            [
              { value: 'success', label: '正常' },
              { value: 'failed', label: '異常' }
            ],
            changed: $scope.mainAction.setClearFilterTrue,
            type: 'noOwnAttrSelect'
          }]
      : []);

    $scope.searchBarOptions = [
      {
        btnName: '單據編號',
        placeholder: '請輸入單據編號',
        params: 'no'
      },
      {
        btnName: '單據名稱',
        placeholder: '請輸入單據名稱',
        params: 'title'
      }
    ].concat(currentUser.hasRole('serviceAdmin')
      ? [{
          btnName: '廠商名稱',
          placeholder: '請輸入廠商名稱',
          params: 'venderName'
        }]
      : []);
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    /* Order & date filter */
    $scope.customizeContentTemplate = '/view/template/bill-filter.html';
    $scope.extraFilter = {
      sort: {
        selected: '-createdTime',
        options: _.flatten([
          ['createdTime', '建立時間'],
          ['processedTime', '處理時間']
        ].map(([field, name]) => [
          { value: `-${field}`, label: `${name} 新-舊` },
          { value: `${field}`, label: `${name} 舊-新` }
        ]))
      },
      durationType: {
        options: [
          { value: 'createdTime', label: '依照建立時間' },
          { value: 'processedTime', label: '依照處理時間' }
        ],
        selected: 'createdTime'
      },
      startDate: {
        value: moment().subtract(180, 'day').startOf('day').toDate(),
        changed: (date) => {
          $scope.extraFilter.startDate.value = moment(date).startOf('day').toDate();
        }
      },
      dueDate: {
        value: moment().endOf('day').toDate(),
        changed: (date) => {
          $scope.extraFilter.dueDate.value = moment(date).endOf('day').toDate();
        }
      }
    };
    const resetExtraParams = () => {
      $scope.extraFilter.sort.selected = '-createdTime';
      $scope.extraFilter.durationType.selected = 'createdTime';
      $scope.extraFilter.startDate.value = moment().subtract(180, 'day').startOf('day').toDate();
      $scope.extraFilter.dueDate.value = moment().endOf('day').toDate();
    };

    $scope.customClearFilter = () => {
      resetExtraParams();
      applyInitialConditions();
      $scope.mainAction.clearFilter(['sort', 'createdAfterOrEqualTo', 'createdBeforeOrEqualTo'], $scope.searchBar.textInput);
    };
    $scope.customFilterQuery = () => {
      $scope.mainAction.queryParams.sort = $scope.extraFilter.sort.selected;

      const startDate = $scope.extraFilter.startDate.value;
      const dueDate = $scope.extraFilter.dueDate.value;
      if ($scope.extraFilter.durationType.selected === 'createdTime') {
        delete $scope.mainAction.queryParams.processedFrom;
        delete $scope.mainAction.queryParams.processedTo;
        $scope.mainAction.queryParams.createdAfterOrEqualTo = startDate && moment($scope.extraFilter.startDate.value).startOf('day').toDate();
        $scope.mainAction.queryParams.createdBeforeOrEqualTo = dueDate && moment($scope.extraFilter.dueDate.value).endOf('day').toDate();
      } else if ($scope.extraFilter.durationType.selected === 'processedTime') {
        delete $scope.mainAction.queryParams.createdAfterOrEqualTo;
        delete $scope.mainAction.queryParams.createdBeforeOrEqualTo;
        $scope.mainAction.queryParams.processedFrom = startDate && moment($scope.extraFilter.startDate.value).startOf('day').toDate();
        $scope.mainAction.queryParams.processedTo = dueDate && moment($scope.extraFilter.dueDate.value).endOf('day').toDate();
      }
      $scope.mainAction.filterQuery();
    };

    const applyInitialConditions = () => {
      $scope.mainAction.queryParams.sort = '-createdTime';
      $scope.mainAction.queryParams.createdAfterOrEqualTo = moment().subtract(180, 'day').startOf('day').toDate();
      $scope.mainAction.queryParams.createdBeforeOrEqualTo = moment().endOf('day').toDate();
    };

    // 在 query 之前，放入預設條件
    applyInitialConditions();
    if (currentUser.hasRole('serviceAdmin')) {
      $scope.dropdownItemList = [
        {
          label: '新增收款單',
          items: [
            { buttonListText: '單筆建立', callback: openSelfConstructSingleBillPage },
            { buttonListText: '批次匯入', callback: importSelfConstructCSV, file: { accept: 'text/csv' } },
            { buttonListText: '批次匯入說明', class: 'fas fa-info-circle', callback: openSelfConstructTipPage },
            { buttonListText: '批次匯入紀錄', callback: openSelfConstructLogDialog }
          ]
        }
      ];
      $scope.toolbarBtns = [
        {
          label: '補開轉介費帳單',
          onClick: async () => {
            confirmDialog.openConfirm({
              title: '補開轉介費帳單',
              isAddTip: true,
              tooltip: `1.每月20號會將前之前帳單進行上鎖，上鎖的轉介費帳單無法進行作廢，請在時間內進行處理。<br>
                2.可補開轉介費帳單由系統進行判斷，依據執行當下日期判斷該廠商可補開的轉介費帳單期數。<br>
                a.如執行日期當下為4/15號，但廠商無3月份轉介費帳單，則沒有可補開轉介費帳單。<br>
                b.如執行日當下為4/15號，廠商3月份轉介費帳單確實作廢，則該廠商可補開3月份轉介費帳單。<br>
                c.如執行日當下為4/20號，廠商4月份轉介費帳單確實作廢，則該廠商可補開4月份轉介費帳單。`,
              templateUrl: '/view/dialog/reissue-referralFeeInvoice.html',
              resource: Vender,
              disableConfirm: false,
              confirmWhenResolve: (confirmValue, dialogData, confirmCb) => {
                if (!confirmValue?.vender) {
                  $scope.alert('廠商 必填');
                  return;
                }
                confirmCb(confirmValue);
              }
            }).then(async (confirmValue) => {
              const recordId = confirmValue?.referralFeeInvoice?.bill?._id;
              Restangular.one(`bills/${recordId}/reCreate`).customPOST().then((res) => {
                if (res === 'ok') {
                  $scope.mainAction.refreshList(Bill);
                  $scope.success('補開資料已送出，請等候處理');
                } else {
                  $scope.alert('補開廠商轉介費帳單失敗');
                }
              });
            });
          }
        },
        {
          label: '作廢',
          class: 'md-accent',
          onClick: async () => {
            const isAccountProcessingTime = $scope.accountProcessingTime();
            if (!isAccountProcessingTime) return;
            const selectedItems = $scope.items.filter(item => item._selected && item.state !== 'invalid');
            const selectedIds = selectedItems.map(item => item._id);
            if (!selectedItems.length) { return $scope.alert('請選擇要作廢的項目'); }
            confirmDialog.openConfirm({
              title: '確認進行作廢？',
              info: '(作廢後資料不可逆，且已繳款單據不可作廢。)',
              inputLabel: '作廢原因：',
              placeholder: '請輸入作廢原因(必填)',
              templateUrl: '/view/dialog/single-input.html',
              confirmWhenResolve: (confirmValue, dialogData, confirmCb) => {
                if (!confirmValue) {
                  $scope.alert('作廢原因 必填');
                  return;
                }
                if (confirmValue.length > 50) {
                  $scope.alert('作廢原因最多50字');
                  return;
                }
                confirmCb(confirmValue);
              }
            }).then(async (confirmValue) => {
              const results = await Bill.one('invalid').customPOST({
                bills: selectedIds,
                reason: confirmValue
              });
              if (results.error) {
                $scope.alert(results.error);
              } else {
                $scope.mainAction.refreshList(Bill);
                $scope.success('設定完成');
              }
            });
          }
        }
      ];
    }

    async function openSelfConstructSingleBillPage () {
      const isAccountProcessingTime = $scope.accountProcessingTime();
      if (!isAccountProcessingTime) return;
      $scope.list = [{}];
      confirmDialog.openConfirm({
        title: '新增收款單',
        templateUrl: '/view/dialog/create-bill-dialog.html',
        minDate: moment(),
        confirmValue: {
          paymentNameReceivable: '服務費用',
          details: $scope.list
        },
        resource: Vender,
        confirmWhenResolve: (confirmValue, dialogData, confirmCb) => {
          if (!confirmValue?.vender) {
            $scope.alert('廠商 必填');
            return;
          }
          if (!confirmValue?.paymentNameReceivable) {
            $scope.alert('收款名稱 必填');
            return;
          }
          if (confirmValue?.details?.length === 0) confirmCb(confirmValue);
          const requiredMissing = confirmValue?.details
            .some(detail => ['name', 'amount'].some(key => !detail[key]));
          if (requiredMissing) {
            $scope.alert('名稱、金額 必填');
            return;
          }
          const amountValid = confirmValue?.details.some(detail => detail.amount < 1 || !_.isInteger(detail.amount));
          if (amountValid) {
            $scope.alert('金額須為正整數');
            return;
          }
          confirmCb(confirmValue);
        },
        itemDropped: (srcList, srcIndex, targetIndex, dragContent) => {
          srcList.splice(srcIndex, 1);
          srcList.splice(targetIndex, 0, dragContent);
          return true;
        },
        itemRemoved: (index) => {
          if ($scope.list.length === 1) return;
          $scope.list.splice(index, 1);
        },
        itemAdded: () => {
          $scope.list.push({});
        }
      }, 'custom-width-800').then(async (confirmValue) => {
        const results = await Bill.one('serviceInvoice').customPOST(confirmValue);
        if (results.error) {
          $scope.alert(results.error);
        } else {
          $scope.mainAction.refreshList(Bill);
          $scope.success('設定完成');
        }
      });
    }

    function importSelfConstructCSV (file) {
      const isAccountProcessingTime = $scope.accountProcessingTime();
      if (!isAccountProcessingTime) return;

      if (!file) return;

      // 檢查檔案類型，基本上不會用到因上方 dropdownItemList 已先限制，多做一層預防萬一
      const validFileType = 'text/csv';
      if (file.type !== validFileType) {
        $scope.alert('只能上傳 CSV 檔案');
        return;
      }

      // 檢查檔案大小是否超過 1 MB
      const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB
      if (file.size > maxSizeInBytes) {
        $scope.alert('檔案大小不能超過 1 MB');
        return;
      }

      $scope.status.isLoading = true;
      Upload.upload({
        url: `${API_URL}bills/importCsv`,
        header: { Authorization: `Bearer ${currentUser.getToken()}` },
        data: {
          file: file,
          envInfo: { deviceType: 'web', origin: 'lsWeb' }
        }
      }).then(res => {
        if (res.data.error) {
          $scope.alert(res.data.msg);
        } else {
          $scope.success('檔案已匯入，建立中...');
        }
      }).catch(() => {
        // 處理非預期錯誤
        $scope.alert('上傳失敗，請檢查檔案格式或稍後再試');
      }).finally(() => {
        $scope.status.isLoading = false;
      });
    }

    function openSelfConstructTipPage () {
      window.open('https://docs.google.com/spreadsheets/d/1xV0Dv3o2xyaX-Hq_Z9eSd01ZOyzWf1Zo5G19ahDZd_c/edit?usp=sharing', '_blank');
    }

    async function openSelfConstructLogDialog () {
      const logs = await Restangular.service('batchBillImportLogs').getList({ limit: 50 });
      confirmDialog.openConfirm({
        title: '建立收款單紀錄',
        templateUrl: '/view/dialog/self-construct-import-log.html',
        isAddTip: true,
        tooltip: '1.匯入檔案限制1MB大小。<br>2.系統會每3分鐘(00、03、06、09、12…..)抓取上傳檔案進行處理，如當下有資料尚在處理，則該次跳過不抓取檔案。<br>3.檢查檔案資料，如有一筆失敗則整批失敗，將不進行收款單資料建立。<br>4.檢查檔案通過後會進行收款單建立。',
        hideCancel: true,
        logs: _.map(logs, log => ({
          ...log,
          _successCount: _.uniqWith(_.get(log, 'successResults', []), log => log.rows).length,
          _errorCount: _.uniqWith(_.get(log, 'errorResults', []), log => log.rows).length
        })),
        onErrorDetailClick: openSelfConstructImportErrorDialog,
        onDownloadFile: (file) => ywUtil.downloadFile(file.url, file.originalFilename)
      }, 'custom-width-1024');
    }

    async function openSelfConstructImportErrorDialog (log) {
      confirmDialog.openConfirm({
        title: '匯入內容錯誤',
        templateUrl: '/view/dialog/self-construct-import-error-log.html',
        hideCancel: true,
        errors: log.errorResults
      }, 'custom-width-600');
    }

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.searchBar.hideSearchAndClearBtn = true;
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.customizeInfo = currentUser.hasRole('serviceAdmin')
        ? '帳單繳款核銷作業非即時處理，將於2:00~3:00更新入帳資訊時進行處理。\n將從最早尚未繳款帳單(依序為收款單、轉介費)開始處理，如帳戶金額不足，則無法進行帳單繳款核銷作業，請務必注意。\n每日凌晨2:00~3:00及每月19號22:00~20號07:00帳務處理，新增收款單、作廢及繳款、帳戶退款操作將無法執行操作。'
        : '帳單繳款核銷作業非即時處理，將於2:00~3:00更新入帳資訊時進行處理。\n將從最早尚未繳款帳單(依序為收款單、轉介費)開始處理，如帳戶金額不足，則無法進行帳單繳款核銷作業，請務必注意。';

      $scope.queryPage = 'service'; // for GA

      $scope.mainAction.initResource(Bill);
    });

    init();

    $scope.cellActions = {
      openDetail: async (item) => {
        const res = await Bill.one(item._id).get();
        let detail = '';
        res.details.forEach((item, index) => {
          if (index) detail += '、';
          if (res.type === 'serviceInvoice') {
            detail += `${item.name}：`;
          } else {
            detail += `${item.no}：`;
          }
          detail += `${item.amount?.toString()
            .split('')
            .reverse()
            .reduce((result, num, index) => result + (index && index % 3 === 0 && num !== '-' ? ',' : '') + num, '')
            .split('')
            .reverse()
            .join('')}元`;
        });
        confirmDialog.openConfirm({
          templateUrl: '/view/dialog/bill-detail-dialog.html',
          data: {
            title: res.title,
            bill: res,
            total: res.total?.toString()
              .split('')
              .reverse()
              .reduce((result, num, index) => result + (index && index % 3 === 0 && num !== '-' ? ',' : '') + num, '')
              .split('')
              .reverse()
              .join(''),
            billDate: moment(res.createdAt).format('YYYY/MM/DD'),
            dueDate: moment(res.dueDate).format('YYYY/MM/DD'),
            detail,
            showNotifyBtn: currentUser.hasRole('serviceAdmin') && ['referralFeeInvoice', 'serviceInvoice'].indexOf(item?.type) > -1 && ['processed', 'invalid'].indexOf(item?.state) < 0,
            showTips: ['referralFeeInvoice'].indexOf(item?.type) > -1 ? '轉介費繳款通知，將會通知廠商繳付所有尚未繳款之金額。' : ''
          },
          notify: async () => {
            const res = await Bill.one('notifyPayment').customPOST({ bill: item._id });
            if (res.error) {
              $scope.alert(res.error);
            } else {
              $scope.success('發送通知成功');
            }
          }
        }, 'custom-width-800');
      },
      notifyPayment: async (item) => {
        const res = await Bill.one('notifyPayment').customPOST({ bill: item._id });
        if (res.error) {
          $scope.alert(res.error);
        } else {
          $scope.success('發送通知成功');
        }
      }
    };

    const exportCsv = (params) => {
      window.location = API_URL + 'bills/exportCsv?' +
        ywUtil.convertSimpleObjToQueryString(params) +
        '&access_token=' + currentUser.getToken();
    };

    $scope.menuButtons = {
      downloadCsvMAC: (item) => exportCsv({ encodeType: 'utf8', billId: item._id }),
      downloadCsvWindows: (item) => exportCsv({ encodeType: 'big5', billId: item._id })
    };

    function venderPaid (bill) {
      confirmDialog.openConfirm({
        title: '確認收款',
        content: '確認後會進行廠商帳戶繳款流程判斷，\n如廠商帳戶金額不足則無法完成帳單繳款作業。'
      }).then(async () => {
        const results = await Bill.one('payment').customPOST({
          action: 'venderAccount',
          bill: bill._id
        });
        if (results.error) {
          $scope.alert(results.error);
        } else {
          $scope.mainAction.refreshList(Bill);
          $scope.success('收款成功');
        }
      });
    }

    function otherPaid (bill) {
      confirmDialog.openConfirm({
        title: '確認收款',
        templateUrl: '/view/dialog/single-textarea.html',
        info: '如廠商匯款至帳單指定虛擬帳戶，則請勿使用此功能收款。\n請確認廠商付款金額與帳單金額相符，才進行收款。',
        inputLabel: '收款備註*',
        maxlength: 200,
        confirmWhenResolve: (confirmValue, dialogData, confirmCb) => {
          if (!confirmValue) {
            $scope.alert('收款備註 必填');
            return;
          }
          confirmCb(confirmValue);
        }
      }, 'custom-width-500').then(async (confirmValue) => {
        const results = await Bill.one('payment').customPOST({
          action: 'otherMethod',
          bill: bill._id,
          note: confirmValue
        });
        if (results.error) {
          $scope.alert(results.error);
        } else {
          $scope.mainAction.refreshList(Bill);
          $scope.success('收款成功');
        }
      });
    }

    function paid (bill) {
      confirmDialog.openConfirm({
        title: '選擇收款方式',
        hideCancel: true,
        hideConfirm: true,
        customBtn: [
          {
            label: '廠商帳戶繳款',
            showIndex: true,
            cb: () => {
              ngDialog.close();
              venderPaid(bill);
            }
          },
          {
            label: '其他方式繳款',
            showIndex: true,
            cb: () => {
              ngDialog.close();
              otherPaid(bill);
            }
          }
        ]
      }, 'custom-width-400');
    }

    function refund (bill) {
      confirmDialog.openConfirm({
        title: '確認退款',
        templateUrl: '/view/dialog/single-textarea.html',
        info: '僅為標記帳單要退款給廠商費用，已透過人工處理完畢之註記。\n廠商可以透過帳單列表上查看該資訊內容。',
        inputLabel: '退款說明*',
        placeholder: '輸入退款資訊，廠商可以檢視該資訊。',
        maxlength: 200,
        confirmWhenResolve: (confirmValue, dialogData, confirmCb) => {
          if (!confirmValue) {
            $scope.alert('退款說明 必填');
            return;
          }
          confirmCb(confirmValue);
        }
      }, 'custom-width-600').then(async (confirmValue) => {
        const results = await Bill.one('refund').customPOST({
          method: 'otherMethod',
          bill: bill._id,
          note: confirmValue
        });
        if (results.error) {
          $scope.alert(results.error);
        } else {
          $scope.mainAction.refreshList(Bill);
          $scope.success('退款成功');
        }
      });
    }

    $scope.onBtnClick = (item) => {
      const isAccountProcessingTime = $scope.accountProcessingTime();
      if (!isAccountProcessingTime) return;
      if (item?.state === 'established') {
        if (item?.type === 'referralFeeInvoice' || item?.type === 'serviceInvoice') {
          // 繳款
          paid(item);
        }
        if (item?.type === 'refundment') {
          // 退款
          refund(item);
        }
      }
    };
  }];
