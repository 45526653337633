export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <md-button class="md-raised md-primary"
      ng-bind="'指定用戶自建單'"
      ng-click="$ctrl.openSpecificSelfConstruct($ctrl.reservation)"
    ></md-button>
  `,
  controller: ['$element', '$async', 'WEBAPP_URL', 'ywEvent', 'ywUtil', 'currentUser', 'Restangular', function reservationSelfConstructButton ($element, $async, WEBAPP_URL, ywEvent, ywUtil, currentUser, Restangular) {
    this.$onInit = () => {
    };

    this.openSpecificSelfConstruct = $async(async (reservation) => {
      const encrypt = async (text) => {
        try {
          if (!text) throw new Error('Text to encrypt is missing.');
          const result = await Restangular.service('system/encrypt').post({
            type: 'selfConstructedBooking',
            plainText: text
          });
          if (result.error) throw new Error(result.error);
          return result;
        } catch (error) {
          ywEvent.emit('ALERT', error);
        }
      };

      const token = currentUser.getToken();
      const { cipherText: encryptedToken } = await encrypt(token);
      const reservationId = _.get(reservation, '_id', null);
      const { cipherText: encryptedReservationId } = await encrypt(reservationId);
      const selfConstructedURL = `${WEBAPP_URL}self-construct-singlereservation`;
      if (encryptedToken && encryptedReservationId) {
        const params = {
          scCode: encryptedToken,
          reservationCode: encryptedReservationId,
          utm_source: 'backstage',
          utm_medium: 'customerservice',
          clearCart: true
        };
        if (_.get(reservation, 'staffInfo.empId')) {
          const { cipherText: encryptedStaffNumber } = await encrypt(reservation?.staffInfo?.empId);
          params.sinyiStaffNumber = encryptedStaffNumber;
        }
        window.open(`${selfConstructedURL}?${ywUtil.convertSimpleObjToQueryString(params)}`, '_blank');
      }
    });
  }]
};
