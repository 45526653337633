import moment from 'moment';

export default ['confirmDialog', function factory (confirmDialog) {
  return { openConfirm };

  function openConfirm ({ timeline }) {
    const dayOptions = [
      { label: '星期一', value: 1, checked: _.includes(timeline.weekday, 1) },
      { label: '星期二', value: 2, checked: _.includes(timeline.weekday, 2) },
      { label: '星期三', value: 3, checked: _.includes(timeline.weekday, 3) },
      { label: '星期四', value: 4, checked: _.includes(timeline.weekday, 4) },
      { label: '星期五', value: 5, checked: _.includes(timeline.weekday, 5) },
      { label: '星期六', value: 6, checked: _.includes(timeline.weekday, 6) },
      { label: '星期日', value: 7, checked: _.includes(timeline.weekday, 7) }
    ];

    const dialogData = {
      title: '設定時間',
      templateUrl: '/view/dialog/timeline-dialog.html',
      customDialog: true,
      confirmValue: _.cloneDeep(timeline),
      dayOptions: dayOptions,
      daySelectChanged: daySelectChanged,
      selectAll: selectAll,
      allChecked: timeline.type === 'time' && isSelectAllChecked(dayOptions),
      confirmWhenResolve: (timeline, ngDialogData, confirmCb) => {
        if (timeline.type === 'time') {
          const days = ngDialogData.dayOptions
            .filter(option => option.checked)
            .map(option => option.value);
          if (!days.length) {
            ngDialogData.errMsg = '請選擇星期';
          }
          timeline.weekday = days.join(',');
          timeline.time = [
            moment('2021-06-07').startOf('day'),
            moment('2021-06-07').endOf('day')
          ];
        } else if (timeline.type === 'date') {
          _.unset(timeline, 'time');
          _.unset(timeline, 'weekday');
        }
        confirmCb(timeline);
      },
      datepicker: {
        options: {
          startingDay: 1,
          minDate: new Date()
        }
      }
    };

    function daySelectChanged (ngDialogData) {
      ngDialogData.allChecked = isSelectAllChecked(ngDialogData.dayOptions);
    }

    function isSelectAllChecked (options) {
      return options.every(option => option.checked);
    }

    function selectAll (checked, ngDialogData) {
      ngDialogData.dayOptions.forEach(d => { d.checked = checked; });
    }

    return confirmDialog.openConfirm(dialogData, 'custom-width-600');
  }
}];
