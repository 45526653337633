export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <table class="table" ng-if="$ctrl.info.showUtm">
      <tr>
        <th ng-repeat="key in $ctrl.info.utmKey track by $index" ng-style="!$first && {'text-align':'center'}">{{key}}</th>
      </tr>
      <tr>
        <td ng-repeat="value in $ctrl.info.utmValue track by $index" ng-style="$first && {'text-align':'left'}">{{value}}</td>
      </tr>
    </table>
  `,
  controller: ['$element', function websiteContentPage ($element) {
    const getUtmFromReservation = (reservation) => {
      const _utmKey = ['source', 'medium', 'campaign', 'content'];
      // add prefix 'utm_', utm_source, utm_medium, utm_campaign, utm_content
      const utmKey = _utmKey.map(key => `utm_${key}`);
      const utmValue = _utmKey.map(key => _.get(reservation, `utm.${key}`), null);
      const showUtm = utmValue.some(value => value);
      return { utmKey, utmValue, showUtm };
    };

    this.$onInit = () => {
      this.info = getUtmFromReservation(this.reservation);
    };
  }]
};
