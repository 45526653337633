export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('venderContacts', false, _transformer);
  const VenderContact = Restangular.service('venderContacts');
  return VenderContact;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    return item;
  }
}];
