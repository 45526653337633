import React, { Fragment } from 'react';

const getReservationOrigin = (reservation) => {
  const { deviceType: type = 'ios', origin = '' } = _.get(reservation, 'envInfo', {});
  const { selfBookingLogs = [] } = _.get(reservation, 'dispatching', {});

  if (selfBookingLogs.length > 0) {
    const { role, byName } = selfBookingLogs[selfBookingLogs.length - 1];
    const roleDisplay = _.cond([
      [(data) => data === 'serviceAdmin', () => '居家自建'],
      [(data) => data === 'vender', () => '廠商自建'],
      [(data) => data === 'sinyiApi', () => '信義自建'],
      [_.stubTrue, () => '自建']
    ])(role);
    return `${roleDisplay}-${byName}`;
  }

  const isWebOrWebview = type.startsWith('web');
  if (isWebOrWebview) {
    if (origin && origin !== 'lsWeb') {
      return origin;
    }
  }

  if (type === 'ios') return 'iOS';
  if (type === 'android') return 'Android';
  if (type === 'csv') return 'CSV';
  return type;
};

const DeviceType = ({ reservation }) => {
  const reservationOrigin = getReservationOrigin(reservation);

  // const openDetailPage = (reservation) => {
  //   ywEvent.emit('IFC_EVENT_FROM_REACT', {
  //     type: 'OPEN_RESERVATION_DETAIL_PAGE',
  //     payload: reservation
  //   });
  // };

  return (
    <>
      <span>{reservationOrigin}</span>
      {/* <i
        className='fas fa-file-alt ptr'
        aria-hidden='true'
        style={{ fontSize: '24px', marginLeft: '10px' }}
        onClick={() => openDetailPage(reservation)}
      /> */}
    </>
  );
};

DeviceType.defaultProps = { reservation: {} };

export default DeviceType;
