export default ['$scope', '$async', '$state', 'ywUtil', 'currentUser', 'Restangular', 'Vender', 'ServiceClassification', 'multiSearchableObjPickerDialog', 'venderUtil', 'confirmDialog', 'labelManagement',
  function controller ($scope, $async, $state, ywUtil, currentUser, Restangular, Vender, ServiceClassification, multiSearchableObjPickerDialog, venderUtil, confirmDialog, labelManagement) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const labelInput = {
      name: 'labels',
      label: '廠商標籤',
      options: [],
      changed: $scope.mainAction.setClearFilterTrue,
      type: 'colorFilter',
      click: $async(async () => {
        const res = await Restangular.service('labels').getList({ limit: 999, target: 'vender' });
        if (!res.error) {
          labelInput.options = res.map(label => ({
            label: label.name,
            value: label._id,
            color: label.color
          }));
        }
      })
    };

    $scope.filterItems = [
      {
        name: 'serviceClassificationIds',
        label: '服務分類',
        options: [],
        changed: (id) => {
          if (!id) {
            $scope.mainAction.showClearFilter = false;
          } else {
            $scope.mainAction.queryParams.serviceClassificationIds = [id];
            $scope.mainAction.setClearFilterTrue();
          }
        },
        resource: ServiceClassification,
        type: 'searchableFilter',
        filterField: 'classificationName',
        extraParams: { displayVisible: 'all' }
      },
      {
        name: 'dispatchStatus',
        label: '派單狀態',
        options: [
          { value: 'allDispatch', label: '自動派單中' },
          { value: 'stopDispatch', label: '停止派單' },
          { value: 'partDispatch', label: '部分派單' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'noOwnAttrSelect'
      },
      {
        name: 'contractStatus',
        label: '合約狀態',
        options: [
          { value: 'active', label: '合約期限內' },
          { value: 'expired', label: '合約已到期' },
          { value: 'yetSetting', label: '未設定' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'noOwnAttrSelect'
      },
      labelInput
    ];

    $scope.searchBarOptions = [
      {
        btnName: '公司名稱',
        placeholder: '請輸入公司名稱',
        params: 'name'
      },
      {
        btnName: '統編',
        placeholder: '請輸入統編',
        params: 'vatNo'
      }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    $scope.menuButtons = {
      edit: (item) => $scope.changePageState('edit', item),
      viewAccounts: (item) => {
        $state.go('service-admin.vender/account', { venderId: item._id });
      },
      venderStaff: (item) => {
        $state.go('service-admin.vender/vender-staff', { venderId: item._id });
      },
      advancedFilterItem: (item) => {
        $state.go('service-admin.vender/advanced-filter-item', { venderId: item._id });
      }
    };

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'vender'; // for GA

      $scope.mainAction.initResource(Vender);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        _serviceCapacities: [],
        _venderContactsList: [],
        _imtercomAdminName: '',
        notServiceIn: [],
        _notServiceInModified: false, // 用以判斷是否要呼叫額外API `/notServiceIn`
        isActiveSmsSending: true,
        labels: [],
        _introductionInfoVenderIntroduction: [],
        _introductionInfoServiceIntroduction: [],
        orderContacts: [
          { period: '平日(週一到週五)', phone: '', extension: '' },
          { period: '週末(含國定假日)', phone: '', extension: '' }
        ],
        _introductionInfoMedalOfHonor: [],
        _introductionInfoIsVisible: true,
        _introductionInfoIsFilterListVisible: true,
        accountReceiptMails: [],
        customerBudget: 0
      };
    }

    $scope.openDispatchSettingDialog = $async(async (item) => {
      const convertRegionsToDistrictOptions = (regions) => _.chain(regions)
        .map(region => region.districts.map(district => ({ district, county: region.county, _id: region.county + district })))
        .flatten()
        .value();
      const districtOptions = convertRegionsToDistrictOptions(item.allClassificationForRegions);
      const selectedList = convertRegionsToDistrictOptions(item.regionsForNotDispatch);

      await multiSearchableObjPickerDialog.openConfirm({
        titleField: 'district',
        subTitleField: 'county',
        useCustomOptions: true,
        customOptions: districtOptions,
        customSelectedFetch: () => selectedList,
        useCustomSelectedFetch: true,
        closeCityFilter: true,
        onConfirm: $async(async (results) => {
          const regions = _.chain(results)
            .groupBy(option => option.county)
            .toPairs() // [county, districtObj]
            .map(([county, districtObjs]) => ({ county, districts: districtObjs.map(obj => obj.district) }))
            .value();
          const res = await Vender.one(item._id).customPUT({ regionsForNotDispatch: regions }, 'dispatchInfo');
          if (res.error) {
            $scope.alert(res.error);
          } else {
            item.allClassificationForRegions = res.allClassificationForRegions;
            item.regionsForNotDispatch = res.regionsForNotDispatch;
            item.dispatchRegions = res.dispatchRegions;
            item.dispatchStatus = res.dispatchStatus;
          }
        })
      });
    });

    $scope.toolbarBtns = [
      {
        label: '標籤設定',
        onClick: $async(async () => {
          const handler = labelManagement.createHandlerObject('vender');
          handler.fetchLabels();
          await confirmDialog.openConfirm({
            title: '廠商標籤設定',
            templateUrl: '/view/dialog/label-management-dialog.html',
            handler
          }, 'custom-width-1024');
          handler.onConfirm();
        })
      },
      {
        label: '批次設定',
        onClick: () => {
          const selectedItems = $scope.items.filter(item => item._selected);
          const selectedIds = selectedItems.map(item => item._id);
          if (!selectedItems.length) { return $scope.alert('請選擇要設定的項目'); }
          venderUtil.openBatchUpdate(selectedIds, $scope.items);
        }
      }
    ];
  }];
