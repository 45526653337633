const getCountInfo = (item) => {
  const viewCount = _.get(item, 'clicks', 0);
  const oldViewCount = _.get(item, 'sinyiOriginalInfo.views', 0);
  return `${viewCount + oldViewCount} (${viewCount}/${oldViewCount})`;
};

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('photos', false, _transformer);
  const Photo = Restangular.service('photos');
  return Photo;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'sort'),
      { anchorType: 'preview-image', imageRef: _.get(item, 'image'), clickHandlerName: 'previewImages' },
      _.get(item, 'name'),
      _.get(item, 'description'),
      { anchorType: 'true-or-false', isTrue: _.get(item, 'isVisible') },
      { anchorType: 'photo-tag-setup' },
      { anchorType: 'single-radio-button' },
      getCountInfo(item),
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
