const commentInputVariants = [
  {
    type: 'additionalNote',
    label: '訂單備註',
    placeholder: '請輸入訂單備註(客戶可查看)'
  },
  {
    type: 'serviceAdminNote',
    label: '居家備註',
    placeholder: '請輸入居家備註'
  },
  {
    type: 'contactRecord',
    label: '聯絡紀錄',
    placeholder: '請輸入聯絡紀錄'
  },
  {
    type: 'customerServiceNote',
    label: '客訴處理紀錄',
    placeholder: '請輸入客訴紀錄'
  },
  {
    type: 'venderNote',
    label: '廠商備註',
    placeholder: '請輸入廠商備註'
  },
  {
    type: 'amountDiff',
    label: '金額異動',
    placeholder: '請輸入金額異動備註'
  },
  {
    type: 'refReservationNote',
    label: '關聯訂單',
    placeholder: '請輸入關聯訂單備註'
  },
  {
    type: 'systemNote',
    label: '系統通知',
    placeholder: ''
  }
];

const getTypeText = (type, refReservationAction) => {
  let _type = _.chain(commentInputVariants)
    .find(variant => variant.type === type)
    .get('label')
    .value();
  if (type === 'reservationNote') {
    _type = '客戶備註';
  }
  if (type === 'dispatchNote') {
    _type = '手動指派廠商紀錄';
  }
  if (type === 'refReservationNote') {
    _type = (refReservationAction === 'add' ? '新增' : '移除') + _type;
  }
  if (type === 'cancelVenderNote') {
    _type = '手動取消廠商紀錄';
  }
  return _type;
};

export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <table class="table">
      <tr>
        <th>備註</th>
        <th>發送時間</th>
        <th>發送人</th>
      </tr>
      <tr ng-repeat="note in $ctrl._notes track by $index" ng-if="note.note">
        <td style="text-align: left;">
          {{note._type && note._type + '：'}}
          <span ng-if="note.type === 'amountDiff'">
            {{note.variedPrice}}
            <span ng-if="note.merchantOrderNo">(已發動信用卡退款:{{note.merchantOrderNo}})</span>
            ，
          </span>
          {{note.type === 'refReservationNote' && note.refReservationNos ? note.refReservationNos.join(',') + '，備註：' : ''}}
          {{note.type === 'refReservationNote' && note.refUnorderedReservationNos ? note.refUnorderedReservationNos.join(',') + '，備註：' : ''}}
          {{note.note}}
        </td>
        <td style="text-align: left;">
          {{note.at | date: 'yyyy/MM/dd HH:mm'}}
        </td>
        <td style="text-align: left;">{{note.by.fullname}}</td>
      </tr>
    </table>

    <div style="margin-left: 10px;">
      <md-radio-group ng-model="$ctrl._commentInput.variant.type" class="md-primary">
        <md-radio-button
          ng-repeat="radioButton in $ctrl._commentInputVariants track by $index"
          ng-value="radioButton.type"
          ng-click="$ctrl._commentInput.variant = radioButton;"
          style="display: inline-block; margin-right: 10px;"
        >
          {{radioButton.label}}
        </md-radio-button>
      </md-radio-group>

      <md-checkbox ng-model="$ctrl._commentInput.isSendSmsWithNote" ng-if="$ctrl._commentInput.variant.type === 'additionalNote'" ng-disabled="!$ctrl.reservation.isSendNotify">
        <div>同時發簡訊給訂單聯絡人</div>
      </md-checkbox>

      <md-checkbox ng-model="$ctrl._commentInput.isSendSmsWithRecord" ng-if="$ctrl._commentInput.variant.type === 'contactRecord'" ng-disabled="!$ctrl.reservation.isSendNotify">
        <div>同時寄發簡訊詢問是否保留預約(簡訊為固定內容，記錄內容不會隨簡訊發送)</div>
      </md-checkbox>

      <div
        style="display: flex; align-items: center;"
        ng-if="$ctrl._commentInput.variant.type === 'amountDiff'"
      >
        <div>異動金額</div>
        <input
          type="number"
          class="yw-input ml-5 mr-5"
          ng-model="$ctrl._commentInput.variedPrice"
          ng-change="$ctrl.onVariedPriceChange($ctrl._commentInput.variedPrice)"
          style="width: 100px;"
        />
        <div class="mr-15">元</div>
        <div layout="row" layout-align="start center">
          <md-checkbox
            ng-if="$ctrl.reservation.defaultPaymentMethod === 'card' || $ctrl.reservation.defaultPaymentMethod === 'applePay'"
            ng-disabled="!$ctrl._commentInput.variedPrice  || $ctrl._commentInput.variedPrice >= 0"
            uib-tooltip="異動金額為負值才可勾選"
            tooltip-enable="!$ctrl._commentInput.variedPrice  || $ctrl._commentInput.variedPrice >= 0"
            ng-model="$ctrl._partialDenyChecked"
          >
            執行信用卡退款
          </md-checkbox>
          <i
            class="fas fa-info-circle ptr ml-5"
            style="font-size: 24px;"
            uib-popover-template="'partial-deny-info'"
            popover-trigger="'mouseenter'"
            popover-placement="'top'"
          />
        </div>
      </div>
      <div ng-if="$ctrl._partialDenyChecked">
        選擇要執行退款的刷卡紀錄
        <div class="select-wrapper dark wrapper">
          <a class="select" ng-click="$ctrl.openMerchantSelectDialog($ctrl.reservation.reservationNo, $ctrl.onDenyMerchantConfirm)">
            <span>{{$ctrl._denyMerchant.orderNo || '請選擇'}}</span>
            <span class="caret"></span>
          </a>
        </div>
      </div>

      <div ng-if="$ctrl._commentInput.variant.type === 'refReservationNote'">
        <div ng-if="$ctrl._refReservationNos.length">
          已關聯訂單：
          <span ng-repeat="refNo in $ctrl._refReservationNos track by $index">
            {{$index !== 0 ? ', ' : ''}}
            {{refNo}}
          </span>
        </div>

        <div>
          <span style="margin-right: 30px;">動作</span>
          <md-radio-group ng-model="$ctrl._commentInput.refReservationAction" class="md-primary">
            <md-radio-button
              ng-value="'add'"
              style="display: inline-block; margin-right: 10px;"
            >
              新增關聯訂單
            </md-radio-button>
            <md-radio-button
              ng-value="'remove'"
              style="display: inline-block; margin-right: 10px;"
            >
              移除關聯訂單
            </md-radio-button>
          </md-radio-group>
        </div>

        <div ng-if="$ctrl._commentInput.refReservationAction === 'add'">
          訂單編號
          <input
            type="text"
            class="yw-input"
            ng-model="$ctrl._commentInput.refReservationNoteString"
            placeholder="輸入訂單編號，多筆以“,”分隔"
          />
        </div>

        <div ng-if="$ctrl._commentInput.refReservationAction === 'remove'">
          選擇移除訂單
          <yw-popup-multi-selection
            yw-blank-item="'選擇訂單'"
            yw-options="$ctrl._commentInput.refReservationOptions"
            yw-selected-list="$ctrl._commentInput.refReservationNoteList"
            yw-on-click="$ctrl.fetchRemoveRefReservationOptions()"
            >
          </yw-popup-multi-selection>
        </div>
      </div>
    </div>

    <div style="display: flex; flex-wrap: nowrap; align-items: flex-start;">
      <div style="flex-grow: 1; padding: 10px 10px 0 10px;">
        <textarea
          style="height: 60px; width: 100%;"
          maxlength="500"
          ng-model="$ctrl._commentInput.text"
          placeholder="{{$ctrl._commentInput.variant.placeholder}}"
        ></textarea>
      </div>
      <div>
        <md-button
          class="md-raised md-primary"
          ng-disabled="!$ctrl._commentInput.text || $ctrl._commentInput.isLoading"
          ng-click="$ctrl.addComment($ctrl._commentInput)"
        >
          {{$ctrl._commentInput.isLoading ? '發送中' : '發送備註'}}
        </md-button>
        <div class="color-gray-500" style="margin-left: 10px;">
          {{$ctrl._commentInput.text.length}} / 500
        </div>
      </div>
    </div>
  `,
  controller: [
    '$element',
    '$async',
    'confirmDialog',
    'currentUser',
    'ywEvent',
    'newebpayDetailDialog',
    'Reservation',
    'UnorderedReservation',
    reservationNote
  ]
};

function reservationNote (
  $element,
  $async,
  confirmDialog,
  currentUser,
  ywEvent,
  newebpayDetailDialog,
  Reservation,
  UnorderedReservation
) {
  const setupDisplayNotes = (reservation) => {
    const invalidTypes = currentUser.hasRole('serviceAdmin')
      ? []
      : ['serviceAdminNote', 'customerServiceNote', 'dispatchNote', 'refReservationNote'];

    const oriNotes = (reservation.notes || [])
      .filter(note => !_.isEmpty(note.note) && !_.includes(invalidTypes, note.type))
      .map(noteDetail => {
        const {
          note,
          type,
          variedPrice,
          merchantOrderNo,
          at,
          by,
          refReservationAction,
          refReservationNos,
          refUnorderedReservationAction,
          refUnorderedReservationNos
        } = noteDetail; // variedPrice and merchantOrderNo existed when note.type === 'amountDiff'
        const _type = getTypeText(type, refReservationAction || refUnorderedReservationAction);
        return { note, at, by, variedPrice, merchantOrderNo, type, _type, refReservationNos, refUnorderedReservationNos };
      });
    const cancelOrDeniedNote = _.chain(reservation)
      .get('stateLogs')
      .filter(log => log.newState === 'cancelled' || log.newState === 'denied')
      .map(log => ({
        note: log.note,
        at: log.at,
        by: log.by,
        type: log.newState === 'cancelled' ? 'cancelledNote' : 'deniedNote',
        _type: log.newState === 'cancelled' ? '退款原因' : '評估後不施作原因'
      }))
      .value();
    this._notes = [...oriNotes, ...cancelOrDeniedNote]
      .sort((a, b) => new Date(a.at).getTime() - new Date(b.at).getTime());
  };

  const initCommentInput = () => {
    const commentInputVariantsByRole = _.cond([
      [
        (type) => type === 'serviceAdmin',
        () => commentInputVariants.filter(variant => !_.includes(['venderNote', 'systemNote'], variant.type))
      ],
      [
        (type) => type === 'vender',
        () => commentInputVariants
          .filter(
            variant => _.includes(['additionalNote', 'venderNote', 'amountDiff'], variant.type)
          )
      ],
      [_.stubTrue, () => []]
    ])(currentUser.getRole());

    this._commentInputVariants = this.reservation.reservationListMode === 'stateUnordered'
      ? commentInputVariantsByRole.filter((variant) => variant.type !== 'amountDiff')
      : commentInputVariantsByRole;

    this._commentInput = {
      text: '',
      isLoading: false,
      variant: _.head(this._commentInputVariants),
      refReservationAction: 'add',
      refReservationOptions: [],
      refReservationNoteList: [],
      isSendSmsWithNote: false,
      isSendSmsWithRecord: false
    };
  };

  const updateRefReservations = $async(async (refReservationIds) => {
    const promises = (refReservationIds || [])
      .map(id => this._resource.one(id).customGET());
    const refReservations = await Promise.all(promises);
    this._refReservationNos = refReservations.map(
      reservation => reservation.reservationNo || reservation.unorderedReservationNo
    );
  });

  this.fetchRemoveRefReservationOptions = $async(async () => {
    const refReservationIds = this._mode === 'stateUnordered'
      ? this.reservation.refUnorderedReservations
      : this.reservation.refReservations;
    const res = await Promise.all(refReservationIds.map(id => this._resource.one(id).customGET()));
    this._commentInput.refReservationOptions = res.map(item => item.reservationNo || item.unorderedReservationNo);
  });

  this.openMerchantSelectDialog = newebpayDetailDialog.openMerchantSelection;

  this.onDenyMerchantConfirm = (merchant) => {
    this._denyMerchant = merchant;
  };

  this.onVariedPriceChange = (price) => {
    const isZeroOrPositiveInteger = _.isInteger(price) && price >= 0;
    if (this._partialDenyChecked && isZeroOrPositiveInteger) {
      this._partialDenyChecked = false;
      this._denyMerchant = null;
    }
  };

  this.addComment = $async(async ({
    text,
    variant,
    variedPrice,
    refReservationAction,
    refReservationNoteList,
    refReservationNoteString,
    isSendSmsWithNote,
    isSendSmsWithRecord
  }) => {
    const commentData = {
      note: text,
      type: variant.type
    };
    if (variant.type === 'additionalNote') {
      await confirmDialog.openConfirm({
        title: '送出訂單備註',
        content: '提醒!!這個欄位用戶看的到喔!!請確認選擇對應狀態及內容再送出~',
        isDangers: true
      });
      commentData.isSendSms = isSendSmsWithNote;
    }
    if (variant.type === 'contactRecord') {
      commentData.isSendSms = isSendSmsWithRecord;
    }
    if (variant.type === 'amountDiff') {
      if (!_.isInteger(variedPrice)) {
        return ywEvent.emit('ALERT', '請輸入異動金額');
      } else if (this._partialDenyChecked && !this._denyMerchant) {
        return ywEvent.emit('ALERT', '請選擇執行退款的刷卡紀錄');
      } else if (this._partialDenyChecked && this._denyMerchant) {
        const backBalance = parseInt(this._denyMerchant.backBalance);
        if (Math.abs(variedPrice) > backBalance) {
          return ywEvent.emit('ALERT', '退款金額大於退款餘額');
        }
      }
      await confirmDialog.openConfirm({
        title: '確認金額異動',
        content: '確定執行此金額異動？'
      });
      commentData.variedPrice = variedPrice;
      if (this._denyMerchant) {
        commentData.merchantOrderNo = this._denyMerchant.orderNo;
      }
    }
    if (variant.type === 'refReservationNote') {
      const refNosKey = this._mode === 'stateUnordered' ? 'refUnorderedReservationNos' : 'refReservationNos';
      if (refReservationAction === 'add') {
        if (!refReservationNoteString) {
          return ywEvent.emit('ALERT', '請輸入關聯訂單編號');
        }
        commentData[refNosKey] = refReservationNoteString.split(',');
      } else if (refReservationAction === 'remove') {
        if (!refReservationNoteList.length) {
          return ywEvent.emit('ALERT', '請選擇關聯訂單編號');
        }
        commentData[refNosKey] = refReservationNoteList;
      }
      const refActionKey = this._mode === 'stateUnordered' ? 'refUnorderedReservationAction' : 'refReservationAction';
      commentData[refActionKey] = refReservationAction;
    }
    this._commentInput.isLoading = true;
    const res = await this._resource.one(this.reservation._id).customPUT(commentData, 'notes');
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      const invalidTypes = currentUser.hasRole('serviceAdmin')
        ? []
        : ['serviceAdminNote', 'customerServiceNote', 'dispatchNote', 'refReservationNote'];
      const notes = (res.notes || []).filter(note => !_.isEmpty(note.note) && !_.includes(invalidTypes, note.type)).map((note) => ({
        ...note,
        _by: currentUser.get(),
        _type: getTypeText(note.type, note.refReservationAction || note.refUnorderedReservationAction)
      }));

      this._notes = notes;
      this._commentInput.text = '';
      this._commentInput.variedPrice = undefined;
      this._commentInput.isSendSmsWithNote = false;
      this._commentInput.isSendSmsWithRecord = false;

      this._denyMerchant = null;
      if (variant.type === 'refReservationNote') {
        if (this._mode === 'stateUnordered') {
          this.reservation.refUnorderedReservations = res.refUnorderedReservations;
          updateRefReservations(res.refUnorderedReservations);
        } else {
          this.reservation.refReservations = res.refReservations;
          updateRefReservations(res.refReservations);
        }
      }
      ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
    }
    this._commentInput.isLoading = false;
  });

  this.$onInit = () => {
    this._mode = _.get(this.reservation, 'reservationListMode');
    this._resource = this._mode === 'stateUnordered' ? UnorderedReservation : Reservation;

    setupDisplayNotes(this.reservation);
    initCommentInput();
    this._partialDenyChecked = false;
    this._denyMerchant = null;
    updateRefReservations(
      this._mode === 'stateUnordered'
        ? this.reservation.refUnorderedReservations
        : this.reservation.refReservations
    );
  };
}
