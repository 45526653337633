import { resizeImage } from 'simple-image-resize';

export default ['$scope', '$async', 'Upload', 'API_URL', 'address', 'confirmDialog', 'ywUtil', 'ArticleClassification', 'Keyword', 'Article', 'Vender',
  function controller ($scope, $async, Upload, API_URL, address, confirmDialog, ywUtil, ArticleClassification, Keyword, Article, Vender) {
    const self = this;
    $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.imageFile = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      console.log('validator:', item);

      if (!item.articleClassification) return $scope.alert('文章分類不能為空');
      if (!item.title) return $scope.alert('文章標題不能為空');
      if (!item.summary) return $scope.alert('文章摘要不能為空');
      if (!item.from) return $scope.alert('文章出處不能為空');
      if (!item.byline) return $scope.alert('原文作者不能為空');

      if (isEdit && !item.urlId) return $scope.alert('網址辨識名稱不能移除');
      if (item.urlId) {
        const validateString = item.urlId.replace(/^DOC_/, '');
        if (!/^[a-z-0-9_]+$/.test(validateString)) return $scope.alert('網址辨識名稱格式不符');
      }

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      item.isImageInvisible = $scope.isImageInvisibleCheckbox.checked || false;
      item.gotoReservationDefaultName = $scope.gotoReservationDefaultName.showIndex ? item.gotoReservationDefaultName : '';
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';

      if (isEdit) {
        const article = await Article.one(item._id).customGET();

        item.articleClassification = item.articleClassification._id;

        if (!item.docContents) {
          item.docContents = article.docContents || [];
        }

        item.keywords = _.get(item, 'keywords', []).map(keyword => keyword._id);

        item._image = item.image;
        item.image = _.get(item, '_image._id');
        item._mediumImage = item.mediumImage;
        item.mediumImage = _.get(item, '_mediumImage._id');
        item._smallImage = item.smallImage;
        item.smallImage = _.get(item, '_smallImage._id');
        item.isImageInvisible = _.get(item, 'isImageInvisible');

        item.isDisplayGotoReservation = _.get(item, 'isDisplayGotoReservation');
        item.gotoReservationDefaultName = _.get(item, 'gotoReservationDefaultName');

        item.venderId = _.get(item, 'vender._id');
      }

      $scope.isImageInvisibleCheckbox = {
        value: false,
        label: '文章圖示不顯示於內文頁',
        index: 'isImageInvisible',
        checked: item.isImageInvisible
      };

      $scope.gotoReservationDefaultName = {
        label: '前往預約按鈕顯示文字',
        type: 'text',
        index: 'gotoReservationDefaultName',
        info: '沒有填寫則預設顯示"前往預約"',
        showIndex: item.isDisplayGotoReservation === true
      };

      $scope.navDataSetting = {
        label: '前往預約按鈕跳轉',
        type: 'navigation-setting',
        index: 'navData',
        buttonLabel: '設定',
        showIndex: item.isDisplayGotoReservation === true
      };

      $scope.inputItems = [
        {
          title: '文章摘要',
          inputs: [
            {
              label: '出版狀態*',
              type: 'radio',
              index: 'isPublish',
              radios: [
                { value: false, label: '不出版' },
                { value: true, label: '出版' }
              ]
            },
            {
              label: '上架區間',
              type: 'date-picker-duration',
              startIndex: 'publishStartTime',
              endIndex: 'publishEndTime'
            },
            {
              label: '信義原廠商ID',
              type: 'description',
              description: _.get(item, 'sinyiOriginalInfo.venderId', '')
            },
            {
              label: '關聯廠商',
              type: 'searchable-obj-picker',
              index: 'venderId',
              title: '關聯廠商',
              resource: Vender,
              filterField: 'name'
            },
            {
              label: '文章類別*',
              type: 'searchable-obj-picker',
              index: 'articleClassification',
              title: '文章類別',
              resource: ArticleClassification,
              filterField: 'name'
            },
            {
              label: '文章標題*',
              type: 'text',
              class: 'long',
              index: 'title'
            },
            {
              label: '文章摘要*',
              type: 'textarea',
              class: 'long',
              index: 'summary',
              maxlength: 150,
              info: ''
            },
            {
              label: '文章出處*',
              type: 'text',
              index: 'from'
            },
            {
              label: '原文作者*',
              type: 'text',
              index: 'byline'
            },
            {
              label: '關鍵字',
              type: 'multi-searchable-obj-picker',
              index: 'keywords',
              title: '關鍵字',
              resource: Keyword,
              filterField: 'name',
              titleField: 'name',
              closeCityFilter: true,
              showSelectedItemsCount: true,
              queryParams: { limit: 200 }
            },
            {
              label: '文章圖示',
              subLabel: '建議尺寸：1600x1200',
              type: 'single-file',
              index: '_image',
              originIndex: 'image',
              class: 'images-wrapper',
              files: $scope.imageFile,
              success: $scope.singleFileUploaded,
              error: $scope.alert,
              beforeUploadFile: (file) => {
                const mediumPromise = resizeImage(file, { maxWidth: 800, maxHeight: 600, quality: 0.7 })
                  .then((blob) => Upload.upload({
                    url: API_URL + 'files',
                    data: { file: blob }
                  }));
                const smallPromise = resizeImage(file, { maxWidth: 600, maxHeight: 450, quality: 0.7 })
                  .then((blob) => Upload.upload({
                    url: API_URL + 'files',
                    data: { file: blob }
                  }));

                return Promise.all([mediumPromise, smallPromise])
                  .then(results => {
                    const errorResult = results.find(result => !_.get(result, 'data.ok'));
                    if (errorResult) {
                      return Promise.reject(new Error(_.get(errorResult, 'data.msg')));
                    }
                    const [mediumResult, smallResult] = results;
                    item.mediumImage = mediumResult.data.result._id;
                    item.smallImage = smallResult.data.result._id;
                  });
              }
            },
            {
              label: ' ',
              type: 'checkbox',
              index: 'isImageInvisible',
              checkboxes: [$scope.isImageInvisibleCheckbox]
            },
            {
              label: '網址辨識名稱',
              type: 'text',
              class: 'long',
              index: 'urlId',
              info: '由此自定網址名稱，使用全英文小寫或數字命名，可使用"-"及"_"分隔，例 interior-select，不設定則系統預設使用id'
            },
            {
              label: '顯示前往預約按鈕',
              type: 'radio',
              index: 'isDisplayGotoReservation',
              radios: [
                { value: true, label: '顯示' },
                { value: false, label: '隱藏' }
              ],
              changed: (value) => {
                $scope.gotoReservationDefaultName.showIndex = value === true;
                $scope.navDataSetting.showIndex = value === true;
              }
            },
            $scope.gotoReservationDefaultName,
            $scope.navDataSetting,
            { type: 'action' }
          ]
        },
        {
          title: '內文管理',
          inputs: [
            {
              type: 'article-doc-content',
              index: 'docContents',
              itemMoved: (index) => item.docContents.splice(index, 1),
              itemRemoved: (index) => item.docContents.splice(index, 1),
              itemAdded: () => item.docContents.push({ title: '', content: '' }),
              onContentEditClick: async (index) => {
                const content = item.docContents[index];
                const newContent = await confirmDialog.openConfirm({
                  title: '編輯內文',
                  templateUrl: '/view/dialog/rich-editor.html',
                  confirmValue: content.content || ''
                }, 'custom-width-1024');
                content.content = newContent;
              }
            },
            { type: 'action' }
          ]
        }
      ];
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
