export default ['$async', 'ywEvent', 'Upload', 'API_URL', 'confirmDialog', function directive ($async, ywEvent, Upload, API_URL, confirmDialog) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      content: '='
    },
    template: `
      <ng-quill-editor
        ng-model="content"
        custom-options="customOptions"
        on-editor-created="onEditorCreated(editor)"
        track-changes="all"
      ></ng-quill-editor>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.customOptions = [{
      import: 'attributors/style/size',
      whitelist: ['18px', '20px', '24px', '28px', '36px']
    }];

    scope.onEditorCreated = (editor) => {
      editor.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/jpeg,image/png,image/webp';
        input.click();
        input.onchange = $async(async () => {
          const image = _.get(input, 'files[0]');
          const res = await Upload.upload({
            url: API_URL + 'files',
            data: { file: image }
          });
          if (res.error) {
            ywEvent.emit('ALERT', res.error);
          } else {
            // insert to editor
            const range = editor.getSelection();
            const imagePosition = range ? range.index : 0;
            editor.insertEmbed(imagePosition, 'image', _.get(res, 'data.result.s3Url'), 'user');
          }
        });
      });
      editor.getModule('toolbar').addHandler('color', async (value) => {
        const currentSelection = editor.getSelection();
        if (value === 'color-picker') {
          value = await confirmDialog.openConfirm({
            templateUrl: '/view/dialog/color-picker-dialog.html',
            confirmValue: ''
          });
        }
        editor.setSelection(currentSelection);
        editor.format('color', value);
      });
    };
  }
}];
