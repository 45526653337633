export default ['$scope', '$async', 'Upload', 'API_URL', 'currentUser', 'CouponEvent', 'Coupon', 'ywUtil',
  function controller ($scope, $async, Upload, API_URL, currentUser, CouponEvent, Coupon, ywUtil) {
    const self = this;

    self.avatarFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;
    $scope.mainAction._addItemToList = addItemToList;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    const save = async () => {
      const results = await $scope.$parent.save({ doNothingAfterSave: true });
      if (results.error) {
        return Promise.reject(results.error);
      }
      $scope.afterSaved({ res: results });
      if ($scope.currentActionItem._downloadNow) {
        ywUtil.exportCouponCsv(results.map(coupon => coupon._id));
      }
    };
    $scope.save = save;

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      if (!_.isNil(item.code)) {
        if (item.loop !== 1) {
          return $scope.alert('優惠碼數量設為1時，才可自訂優惠碼(2-10碼)');
        }
        if (_.get(item, 'code.length') < 2 || _.get(item, 'code.length') > 10) {
          return $scope.alert('自訂優惠碼需2到10碼');
        }
      }

      console.log('currentActionItem: ', item);
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      console.log('transform finished: ', item);
    }

    function syncCurrentActionItem () {
      // const item = $scope.currentActionItem;

      const couponEventPickerInput = {
        label: '優惠券活動*',
        type: 'coupon-event-picker',
        index: 'couponEventId',
        title: '優惠活動',
        resource: CouponEvent,
        titleField: 'subjectName',
        subTitleField: 'couponDefault.title',
        filterField: 'subjectName',
        changed: (id) => {
          CouponEvent.one(id).customGET().then(couponEvent => {
            couponEventPickerInput.couponEventInfo = couponEvent;
          });
        }
      };
      $scope.inputItems = [
        couponEventPickerInput,
        { label: '優惠券數量', type: 'number', index: 'loop' },
        {
          label: '自訂優惠碼',
          type: 'text',
          index: 'code',
          info: '優惠券數量設為1時，</br>才可自訂優惠碼(2~10碼)'
        },
        {
          label: 'Email備存',
          type: 'text',
          index: 'requestorEmail',
          info: '輸入Email後，點選確認時會寄送本次產出的優惠碼供行銷備存',
          class: 'long'
        },
        {
          label: '下載備存',
          type: 'switch',
          index: '_downloadNow',
          info: '開啟後，點選確認時會下載本次產出的優惠碼'
        },
        { label: '備註', type: 'textarea', index: 'description', maxlength: 200 },
        { type: 'action' }
      ];
    }

    function addItemToList (res) {
      if (_.isArray(res)) {
        res.forEach(function (v) {
          const coupon = Coupon.one(v._id);
          Object.keys(v).forEach(key => { coupon[key] = v[key]; });
          $scope.items.unshift(coupon.clone());
        });
        $scope.mainAction.pager.totalItems += res.length;
      }
      return res;
    }
  }];
