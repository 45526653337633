export default ['$scope', '$async', 'User', 'checkboxDialog',
  function controller ($scope, $async, User, checkboxDialog) {
    const self = this;

    self.iconFiles = {};
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      if (!item.name) return $scope.alert('請輸入群組名稱');
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      console.log('before transform: ', item);
    }

    function syncCurrentActionItem () {
      // const item = $scope.currentActionItem;

      $scope.inputItems = [
        {
          label: '群組名稱*',
          type: 'text',
          index: 'name',
          maxlength: 120
        },
        {
          label: '會員設定',
          type: 'checkbox-picker',
          index: 'members',
          resource: User,
          titleField: 'fullname',
          subTitleField: 'phone',
          extraParams: { role: 'customer' },
          onClick: $async(async (item) => {
            const [addedMembers, removedMembers] = await checkboxDialog.openConfirm({
              objResource: User,
              titleField: 'fullname',
              subTitleField: 'phone',
              filterField: 'phone',
              inputPlaceholder: '手機搜尋',
              extraParams: { role: 'customer' },
              selectedIds: [],
              groupId: item._id
            });
            item.members = addedMembers;
            item.membersLeave = removedMembers;
          })
        },
        { type: 'action' }
      ];
    }
  }];
