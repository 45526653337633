export default ['ngQuillConfigProvider', function (ngQuillConfigProvider) {
  const ngQuillConfig = {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [], // text direction

        // [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ size: ['18px', '20px', '24px', '28px', '36px'] }], // custom dropdown
        [],

        [],
        [
          {
            color: [
              '#000000',
              '#e60101',
              '#DD3703',
              '#fdff00',
              '#75aa00',
              '#0065cc',
              '#9832ff',
              '#ffffff',
              '#fbcccc',
              '#FCE8E6',
              '#feffcb',
              '#F8FFE8',
              '#cde0f5',
              '#ead7ff',
              '#bbbbbb',
              '#ef6565',
              '#FF683A',
              '#fdff65',
              '#C3DB92',
              '#66a2e0',
              '#c285ff',
              '#888888',
              '#a10000',
              '#F9450F',
              '#b2b200',
              '#89C10D',
              '#0048b2',
              '#6b25b1',
              '#444444',
              '#5d0000',
              '#C52600',
              '#666600',
              '#649100',
              '#002866',
              '#3c1466',
              'color-picker'
            ]
          },
          { background: [] }], // dropdown with defaults from theme
        [],
        [{ align: [] }],

        [], // remove formatting button

        ['link', 'image']
      ]
    },
    theme: 'snow'
  };
  ngQuillConfigProvider.set(ngQuillConfig);
}];
