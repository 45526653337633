export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <table class="table" ng-if="$ctrl.reservation.vatNo || $ctrl.isServiceAdmin">
      <tr>
        <th>統一編號</th>
        <th>買受人抬頭</th>
      </tr>
      <tr>
      <td style="text-align: left;">
        {{$ctrl.reservation.vatNo}}
        <i class="far fa-edit" ng-click="$ctrl.openVatNoEditor($ctrl.reservation)"></i>
      </td>
      <td style="text-align: left;">
        {{$ctrl.reservation.vatTitle}}
        <i class="far fa-edit" ng-click="$ctrl.openVatTitleEditor($ctrl.reservation)"></i>
      </td>
      </tr>
    </table>
  `,
  controller: ['$element', '$async', 'ywEvent', 'ywUtil', 'confirmDialog', 'Reservation', 'UnorderedReservation', 'currentUser', function reservationEnvInfo ($element, $async, ywEvent, ywUtil, confirmDialog, Reservation, UnorderedReservation, currentUser) {
    this.isServiceAdmin = currentUser.hasRole('serviceAdmin');

    this.openVatNoEditor = $async(async (reservation) => {
      const dialogData = {
        title: '修改統一編號',
        templateUrl: '/view/dialog/single-input.html',
        confirmValue: '',
        placeholder: '輸入統一編號',
        disableConfirm: true,
        onInputChange: (value) => {
          dialogData.disableConfirm = !value;
        }
      };
      const value = await confirmDialog.openConfirm(dialogData);
      const resource = reservation.reservationListMode === 'stateUnordered'
        ? UnorderedReservation
        : Reservation;
      const res = await resource.one(reservation._id).customPUT({ vatNo: value }, 'vatNoInfo');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
      } else {
        reservation.vatNo = res.vatNo;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        ywEvent.emit('SUCCESS', '修改完成');
      }
    });
    this.openVatTitleEditor = $async(async (reservation) => {
      const dialogData = {
        title: '修改買受人抬頭',
        templateUrl: '/view/dialog/single-input.html',
        confirmValue: '',
        placeholder: '輸入買受人抬頭',
        disableConfirm: true,
        onInputChange: (value) => {
          dialogData.disableConfirm = !value;
        }
      };
      const value = await confirmDialog.openConfirm(dialogData);
      const resource = reservation.reservationListMode === 'stateUnordered'
        ? UnorderedReservation
        : Reservation;
      const res = await resource.one(reservation._id).customPUT({ vatTitle: value }, 'vatNoInfo');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
      } else {
        reservation.vatTitle = res.vatTitle;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        ywEvent.emit('SUCCESS', '修改完成');
      }
    });
  }]
};
