export default ['Restangular', '$filter', 'ywTimezone', 'ywUtil', function factory (Restangular, $filter, ywTimezone, ywUtil) {
  // reformat object
  Restangular.addElementTransformer('adminNotifications', false, _transformer);
  const AdminNotifications = Restangular.service('adminNotifications');
  return AdminNotifications;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._isChecked = item.status === 'finished';

    function getCategoryLabel () {
      const notificationCategory = _.find(ywUtil.getAdminNotificationCategory, category => {
        return category.value === item.category;
      });
      return _.get(notificationCategory, 'label');
    }
    function getStatusLabel () {
      const notificationStatus = _.find(ywUtil.getAdminNotificationStatus, status => {
        return status.value === item.status;
      });
      return _.get(notificationStatus, 'label', '未處理');
    }

    const ret = [
      $filter('date')(_.get(item, 'createdAt', ''), 'yyyy/MM/dd', ywTimezone.get()),
      _.get(item, 'targetName', ''),
      getCategoryLabel(),
      { anchorType: 'admin-notification-content', content: _.get(item, 'content'), alignLeft: true },
      { anchorType: 'admin-notification-status', status: getStatusLabel() },
      { anchorType: 'functional-checkbox' }
    ];

    ret.obj = item;
    return ret;
  }
}];
