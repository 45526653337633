export default ['$scope', 'currentUser', 'User',
  function controller ($scope, currentUser, User) {
    $scope.inputItems = [];
    $scope.update = update;

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;
      if (!item.fullname) return $scope.alert('名稱不能為空');
      if (!/^\d{8,10}$/.test(item.phone)) return $scope.alert('聯絡電話須為8-10位數字');
      if (item.password && !/\w{8}/.test(item.password)) return $scope.alert('密碼須為8碼以上英文數字');
      if (item.password && item.password !== item._passwordAgain) return $scope.alert('密碼不符合');
      return true;
    }

    function update () {
      if (!validator()) return Promise.reject(new Error('validate error'));
      $scope.status.isLoading = true;
      $scope._action(User.one(currentUser.get()._id).customPUT({
        fullname: $scope.currentActionItem.fullname,
        email: $scope.currentActionItem.email,
        phone: $scope.currentActionItem.phone,
        password: $scope.currentActionItem.password,
        origin: 'lsWeb'
      })).then(res => {
        $scope.success('更新成功！');
        $scope.status.isLoading = false;
      }).catch(err => {
        console.log(err);
        $scope.alert(err);
        $scope.status.isLoading = false;
      });
    }

    function syncCurrentActionItem () {
      $scope.currentActionItem = currentUser.get();
      $scope.inputItems = [
        { label: '名稱*', type: 'text', index: 'fullname' },
        { label: 'email*', type: 'text', index: 'email', class: 'long' },
        { label: '手機*', type: 'text', index: 'phone' },
        { label: '帳號', type: 'description', description: $scope.currentActionItem.username },
        { label: '密碼', type: 'password', index: 'password', placeholder: '大小寫英文/數字8碼以上', class: 'long' },
        { label: '確認密碼', type: 'password', index: '_passwordAgain', class: 'long' },
        { label: ' ', type: 'description', description: '如需變更密碼，才需要進行輸入密碼和確認密碼欄位' },
        { type: 'action', hideCancel: true }
      ];
    }
    syncCurrentActionItem();
  }];
