/*
  example:
  <life-calendar selected-date="selectedDate" max-date="maxDate" time-period="timePeriod"></life-calendar>
*/

export default {
  bindings: {
    selectedDate: '=', // Date, 必填
    maxDate: '<', // Date
    minDate: '<', // Date
    timePeriod: '=' // object, 有開始 { showFromTime, showToTime, fromTimeLabel, fromHour, fromMinute, toTimeLabel, toHour, toMinute }
  },
  template: `
    <div class="life-calendar">
      <div uib-datepicker
        datepicker-options="$ctrl.options"
        ng-model="$ctrl.selectedDate"
      ></div>
      <div class="time-wrapper" ng-if="$ctrl.timePeriod.showFromTime || $ctrl.timePeriod.showToTime">
        <div class="label-wrapper">
          <div class="life-headline-small"><span>*</span>{{$ctrl.timePeriod.fromTimeLabel || '輸入開始時間'}}</div>
          <div class="life-body-medium ">24小時制</div>
        </div>
        <div class="input-wrapper">
          <life-label-input label="'時'" value="$ctrl.timePeriod.fromHour" type="'number'" max="23" min="0"></life-label-input>
          <div class="life-body-large">:</div>
          <life-label-input label="'分'" value="$ctrl.timePeriod.fromMinute" type="'number'" max="59" min="0"></life-label-input>
        </div>
      </div>
      <div class="time-wrapper" ng-if="$ctrl.timePeriod.showToTime">
        <div class="label-wrapper">
          <div class="life-headline-small">{{$ctrl.timePeriod.toTimeLabel || '輸入結束時間'}}</div>
          <div class="life-body-medium">24小時制</div>
        </div>
        <div class="input-wrapper">
          <life-label-input label="'時'" value="$ctrl.timePeriod.toHour" type="'number'" max="23" min="0"></life-label-input>
          <div class="life-body-large">:</div>
          <life-label-input label="'分'" value="$ctrl.timePeriod.toMinute" type="'number'" max="59" min="0"></life-label-input>
        </div>
      </div>
    </div>
  `,
  controller: ['$element',
    function calendarController ($element) {
      this.$onInit = () => {
        this.options = {
          showWeeks: false,
          maxDate: this.maxDate,
          minDate: this.minDate
        };
      };
    }
  ]
};
