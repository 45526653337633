import { inputLimit } from '../../../share/input-limit.js';

export default ['$scope', 'LayoutModule', 'layoutModuleUtils',
  function controller ($scope, LayoutModule, layoutModuleUtils) {
    const self = this;
    self.imageFiles = {};
    $scope.dragContents = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);
      if (!item.name) {
        return $scope.alert('模組名稱為必填');
      }
      if (_.isEmpty(item.graphicFixModule?.pages)) {
        return $scope.alert('請至少建立一組頁籤內容');
      }
      if (!_.isEmpty(item.graphicFixModule?.pages)) {
        if (item.graphicFixModule?.pages?.some(page => !page?.title)) {
          return $scope.alert('頁籤標題皆為必填');
        }
        if (item.graphicFixModule?.pages?.some(page => page?.contents?.some(content => !content?.image))) {
          return $scope.alert('需上傳頁籤內容所有圖片');
        }
      }
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      const pagesData = [];
      item.pages.forEach((page) => {
        const pageContentData = [page.slice(2, 8), page.slice(9, 15), page.slice(16)];
        const contents = pageContentData.map((data, index) => {
          return {
            image: data[0][`${`image${index + 1}`}`],
            title: data[1][`${`title${index + 1}`}`],
            description: data[2][`${`description${index + 1}`}`],
            priceDescription: data[3][`${`priceDescription${index + 1}`}`],
            link: data[4][`${`link${index + 1}`}`],
            tagWord: data[5][`${`tagWord${index + 1}`}`]
          };
        });
        pagesData.push({
          title: page[0].title,
          contents: contents
        });
      });
      item.graphicFixModule = {
        paragraphTitle: item.paragraphTitle,
        pages: pagesData
      };
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();
        item.isVisible = layoutModule?.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item.paragraphTitle = layoutModule.graphicFixModule?.paragraphTitle || '';
        item[`${item.type}Module`] = layoutModule[`${item.type}Module`];
      }

      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      currentActionItem.visibleItemIndex = 0;
      currentActionItem.logListEvent = (index) => {
        currentActionItem.newIndex = index;
      };

      const defaultContents = [
        {
          titleLabel: '*頁籤標題：',
          type: 'module-text',
          index: 'title',
          title: '預設標題',
          remark: '字數限制：256字(多個頁籤時顯示，建議8字內，字數寬度超過360px會隱藏)',
          maxlength: inputLimit.default
        }
      ];

      const graphicContent = (itemNumber) => {
        return (
          [
            { titleLabel: `內容${itemNumber}` },
            {
              label: '*圖片：',
              remark: '圖片比例：3:2、建議尺寸：寬700 x 高466 ~ 寬1200 x 高800，限制 1 MB',
              type: 'module-file',
              index: `_imageUrl${itemNumber}`,
              originIndex: `image${itemNumber}`,
              class: 'images-wrapper',
              files: $scope.imageFiles,
              success: (result, item) => {
                console.log('moderator imageUploaded: ', item);
                item[`_imageUrl${itemNumber}`] = result;
                item[`image${itemNumber}`] = result._id;
              },
              error: $scope.alert,
              validate: { limitSize: 1000000 }
            },
            {
              label: '標題：',
              type: 'module-text',
              index: `title${itemNumber}`,
              remark: '字數限制：256字(未填則不顯示、圖片的 title 、 alt 同此標題)',
              maxlength: inputLimit.default
            },
            {
              label: '敘述：',
              type: 'module-text',
              index: `description${itemNumber}`,
              remark: '字數限制：256字(未填則不顯示)',
              maxlength: inputLimit.default
            },
            {
              label: '價格描述：',
              type: 'module-text',
              index: `priceDescription${itemNumber}`,
              remark: '字數限制：256字(未填則不顯示)',
              maxlength: inputLimit.default
            },
            {
              label: '連結：',
              type: 'module-link',
              index: `link${itemNumber}`
            },
            {
              label: '標籤文字：',
              type: 'module-text',
              index: `tagWord${itemNumber}`,
              remark: '字數限制：10字',
              maxlength: 10
            }
          ]
        );
      };

      const graphicContents = _.chain([1, 2, 3])
        .map((itemNumber) => {
          return graphicContent(itemNumber);
        }).flatten().value();

      $scope.dragContents = [...defaultContents, ...graphicContents];

      if (currentActionItem.graphicFixModule) {
        currentActionItem.pages = [];
        for (let i = 0; i < currentActionItem.graphicFixModule?.pages?.length; i++) {
          const moduleContent = currentActionItem.graphicFixModule?.pages[i];
          const pageDragContents = _.cloneDeep($scope.dragContents);
          pageDragContents[0].title = moduleContent.title;
          moduleContent.contents.forEach((content, index) => {
            _.forIn(content, (value, key) => {
              if (key === 'image') {
                const result = _.find(pageDragContents, function (item) {
                  if (item.originIndex === `${key}${index + 1}`) {
                    return true;
                  }
                });
                if (result) {
                  result[`_imageUrl${index + 1}`] = value;
                  result[`image${index + 1}`] = value._id;
                }
              } else {
                const result = _.find(pageDragContents, function (item) {
                  if (item.index === `${key}${index + 1}`) {
                    return true;
                  }
                });
                if (result) result[`${key}${index + 1}`] = value;
              }
            });
          });
          currentActionItem.pages.push(_.cloneDeep(pageDragContents));
        }
      } else {
        currentActionItem.pages = [_.cloneDeep($scope.dragContents)];
      }

      const dragModules = layoutModuleUtils.getDragModules($scope, 'pages');

      return (
        [
          { label: '模組類型：', type: 'description', description: currentActionItem.label },
          { label: '*模組名稱：', type: 'text', index: 'name', remark: '不顯示於前台' },
          {
            label: '顯示設定',
            type: 'radio',
            index: 'isVisible',
            radios: [
              { value: true, label: '顯示' },
              { value: false, label: '隱藏' }
            ]
          },
          { type: 'underline' },
          { label: '段落標題：', type: 'text', index: 'paragraphTitle', remark: 'H1、字數限制：256字(未填則不顯示，建議8字內)', maxlength: inputLimit.default },
          {
            label: '頁籤'
          },
          {
            type: 'drag-module',
            module: 'graphicFix',
            subType: 'graphicDragModule-wrapper',
            index: 'pages',
            itemDropped: dragModules.itemDropped,
            itemRemoved: dragModules.itemRemoved,
            itemAdded: dragModules.itemAdded,
            itemVisible: dragModules.itemVisible,
            itemDragend: dragModules.itemDragend
          },
          { type: 'action' }
        ]
      );
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
