export default ['session', 'Upload', 'API_URL', function directive (session, Upload, API_URL) {
  return {
    restrict: 'A',
    transclude: true,
    scope: {
      files: '=ywUpload', // files in proccessing
      success: '&',
      error: '&',
      hook: '=',
      acceptFiles: '=',
      disabled: '=ywDisabled',
      validate: '=ywValidate',
      multipleUpload: '=',
      beforeUploadFile: '=',
      compress: '=',
      fileSizeLimit: '='
    },
    template: `
        <span ng-transclude type="file" ngf-select="uploadFiles($files, $width, $height)" ngf-fix-orientation="true" accept="{{acceptMimeType}}" ngf-multiple="multipleUpload"
          ng-disabled="disabled">
        </span>
        <canvas ng-if="imgLoaded" style="position: fixed; z-index: -1; top: -100000px;" id="thumb-image-canvas" content="user-scalable=no" />
      `,
    link: link
  };

  function link (scope, element, attr) {
    scope.uploadFiles = uploadFiles;
    console.log('accept: ', scope.acceptFiles);
    const imageMimeType = 'image/jpeg,image/png,image/webp';
    scope.acceptMimeType = scope.acceptFiles || imageMimeType;

    function getBase64 (img, callback) {
      const reader = new FileReader(); // eslint-disable-line
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }

    function uploadFiles (files) {
      console.log('Selected files: ', files);

      const self = this; // scope
      if (_.isEmpty(files)) return;
      if (files !== null && files.$error) return self.error({ message: files.$error });

      const acceptTypes = self.acceptMimeType.split(',');
      _.forEach(files, file => {
        const limitSize = self.validate?.limitSize || 20000000;
        if (file.size > limitSize) return self.error({ message: `檔案過大!允許的大小為${limitSize / 1000000}MB` });
        if (acceptTypes.indexOf(file.type) < 0) return self.error({ message: '不支援的檔案格式' });

        if (scope.acceptMimeType === imageMimeType) {
          if (scope.compress && scope.fileSizeLimit && file.size > scope.fileSizeLimit) {
            scope.imgLoaded = true;
            getBase64(file, res => {
              setTimeout(() => {
                const canvas = document.getElementById('thumb-image-canvas');
                const ctx = canvas.getContext('2d');

                var background = new Image(); // eslint-disable-line
                background.onload = drawImage;
                function drawImage () {
                  if (this.naturalHeight > this.naturalWidth) {
                    canvas.width = 1000;
                    canvas.height = 1000 * this.naturalHeight / this.naturalWidth;
                    ctx.drawImage(this, 0, 0, this.naturalWidth, this.naturalHeight, 0, 0, 1000, 1000 * this.naturalHeight / this.naturalWidth);
                  } else {
                    canvas.width = 1000 * this.naturalWidth / this.naturalHeight;
                    canvas.height = 1000;
                    ctx.drawImage(this, 0, 0, this.naturalWidth, this.naturalHeight, 0, 0, 1000 * this.naturalWidth / this.naturalHeight, 1000);
                  }
                }
                background.src = res;

                setTimeout(() => {
                  canvas.toBlob(function (blob) {
                    scope.imgLoaded = false;
                    blob.name = file.name;
                    doUpload(blob);
                  }, file.type, 0.8);
                }, 100);
              }, 100);
            });
          } else {
            // load image
            const img = document.createElement('img');
            img.src = URL.createObjectURL(file); // eslint-disable-line
            img.onload = function () {
              if (self.validate && !validation(img)) return;
              doUpload(file);
            };
          }
        } else {
          doUpload(file);
        }
      });

      function validation (img) {
        if (_.get(self, 'validate.width.limit') && (self.validate.width.limit - 1 > img.width || self.validate.width.limit + 1 < img.width)) { return alertAfterApply('寬度須為' + self.validate.width.limit + 'px'); }
        if (_.get(self, 'validate.height.limit') && (self.validate.height.limit - 1 > img.height || self.validate.height.limit + 1 < img.height)) { return alertAfterApply('高度須為' + self.validate.height.limit + 'px'); }
        if (self.validate.width && self.validate.width.max && self.validate.width.max < img.width) { return alertAfterApply('寬度不可超過' + self.validate.width.max + 'px'); }
        if (self.validate.height && self.validate.height.max && self.validate.height.max < img.height) { return alertAfterApply('高度不可超過' + self.validate.height.max + 'px'); }
        return true;
      }

      function alertAfterApply (msg) {
        scope.$apply(function () { self.error({ message: msg }); });
        return false;
      }

      function doUpload (file) {
        // for progress display
        const proccessId = new Date().getTime() + _.uniqueId();
        file.proccessId = proccessId;
        if (_.isArray(self.files)) {
          self.files.push(file);
        }
        file.progress = 0;

        // file.upload = Upload.upload({
        const uploadAPICall = () => Upload.upload({
          url: API_URL + 'files',
          data: { file: file }
        });

        file.upload = scope.beforeUploadFile
          ? scope.beforeUploadFile(file).then(uploadAPICall)
          : uploadAPICall();

        file.upload.then(successCallback, errorCallback, progressCallback);

        /** upload callbacks */
        function successCallback (response) {
          _.remove(self.files, file => file.proccessId === proccessId);
          self.success({ result: response.data.result });
          if (scope.hook) {
            scope.hook(files);
          }
        }

        function errorCallback (response) {
          console.warn('upload error: ', response);
        }

        function progressCallback (evt) {
          file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        }
      }
    }
  }
}];
