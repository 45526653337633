import React, { Fragment } from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';

const ColorLabel = ({ color }) => {
  return (
    <div style={{
      display: 'inline-block',
      height: '10px',
      width: '25px',
      borderRadius: '7px',
      backgroundColor: color || '#ffffff',
      marginRight: '5px',
      border: color === '#FFFFFF' && '1px solid #999'
    }}
    />
  );
};

const LabelList = ({ labels }) => {
  return (
    <>
      {labels.map((label, index) => (
        <StatefulToolTip
          key={`label-${index}`}
          parent={<ColorLabel color={label.color} />}
          position='top' arrow='center'
        >
          {label.name}
        </StatefulToolTip>
      ))}
    </>
  );
};

LabelList.defaultProps = {
  lables: []
};

export default LabelList;
