import React, { useEffect, useReducer } from 'react';

import Header from './Header';
import Content from './Content';
import withTheme from '../withTheme';
import { Table } from '../common/Table';

const subHeaderStyle = {
  backgroundColor: '#ebebeb',
  textAlign: 'center'
};

const initializeReducer = () => {
  return {
    items: [],
    pinItems: [],
    showPinItems: true
  };
};
const reducer = (state, action) => {
  if (action.type === 'SET_TABLE_ITEMS') {
    return { ...state, items: action.payload };
  } else if (action.type === 'SET_TABLE_PIN_ITEMS') {
    return {
      ...state,
      pinItems: action.payload.sort((a, b) => {
        if (_.get(a, 'pinConnectionInfo.date') && !_.get(b, 'pinConnectionInfo.date')) {
          return -1;
        }
        if (_.get(a, 'pinConnectionInfo.date') && _.get(b, 'pinConnectionInfo.date')) {
          return new Date(_.get(a, 'pinConnectionInfo.date')).getTime() -
            new Date(_.get(b, 'pinConnectionInfo.date')).getTime();
        }
        return 0;
      })
    };
  } else if (action.type === 'SHOW_TABLE_PIN_ITEMS') {
    return {
      ...state,
      showPinItems: action.payload
    };
  }
  throw new Error('unexpected action dispatched');
};

const UnorderedReservationList = ({ ywEvent, currentUser }) => {
  const [state, dispatch] = useReducer(reducer, {}, initializeReducer);
  const setTableItems = (items = []) => dispatch({ type: 'SET_TABLE_ITEMS', payload: items });
  const setTablePinItems = (items = []) => dispatch({ type: 'SET_TABLE_PIN_ITEMS', payload: items });
  const setTablePinItemsShow = (show = false) => dispatch({ type: 'SHOW_TABLE_PIN_ITEMS', payload: show });

  useEffect(() => {
    const handleIFCReceived = ({ type, payload }) => {
      if (type === 'SET_CURRENT_TABLE_LIST') {
        setTableItems(payload);
      } else if (type === 'SET_PIN_RESERVATIONS') {
        setTablePinItems(payload);
      } else if (type === 'SHOW_PIN_RESERVATIONS') {
        setTablePinItemsShow(payload);
      }
    };
    const IFCEvent = ywEvent.register('IFC_EVENT', handleIFCReceived);

    return () => {
      if (IFCEvent) {
        ywEvent.unregister(IFCEvent);
      }
    };
  });

  const showItemSplitHeader = state.showPinItems &&
    !!_.get(state.pinItems, 'length') &&
    !!_.get(state.items, 'length');

  return (
    <Table>
      <thead>
        <Header items={state.items} ywEvent={ywEvent} />
      </thead>

      <tbody>
        {showItemSplitHeader &&
          <tr style={subHeaderStyle}>
            <td colSpan='10' style={subHeaderStyle}>
              置頂訂單
            </td>
          </tr>}
        {state.showPinItems && state.pinItems.map(item => (
          <Content
            key={`pin-${item._id}`}
            item={item}
            ywEvent={ywEvent}
            currentUser={currentUser}
          />
        ))}

        {showItemSplitHeader &&
          <tr style={subHeaderStyle}>
            <td colSpan='10' style={subHeaderStyle}>
              一般訂單
            </td>
          </tr>}
        {state.items.map((item) => (
          <Content
            key={item._id}
            item={item}
            ywEvent={ywEvent}
            currentUser={currentUser}
            pinReservations={state.pinItems}
          />
        ))}
      </tbody>

      <tfoot>
        <Header items={state.items} ywEvent={ywEvent} />
      </tfoot>
    </Table>
  );
};

export default withTheme(UnorderedReservationList);
