/*
  example:
  <life-select label="'請選擇'" value="selectValue" hint="'showHint'" disabled="false" options="radioOptions"></life-select>
*/

export default {
  bindings: {
    label: '=', // string, 必填
    value: '=', // string or number, 必填
    options: '<', // object, 必填, { value, label }
    disabled: '<', // boolean
    hint: '<' // string, 提示內容
  },
  template: `
    <md-input-container class="life-select">
      <label ng-class="{'life-label-disabled' : $ctrl.disabled}">{{$ctrl.label}}</label>
      <md-select ng-model="$ctrl.value" ng-disabled="$ctrl.disabled" ng-class="{'life-select-disabled' : $ctrl.disabled}">
        <md-option ng-repeat="option in $ctrl.options" ng-value="option.value">{{option.label}}</md-option>
      </md-select>
    </md-input-container>
    <div class="life-select-hint life-body-small" ng-if="$ctrl.hint" ng-bind="$ctrl.hint"></div>
  `
};
