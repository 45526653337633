export default ['$scope', '$stateParams', '$async', 'ywUtil',
  function controller ($scope, $stateParams, $async, ywUtil) {
    const self = this;
    // $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.imageFile = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);

      if (!item.name) return $scope.alert('修繕專長項目名稱為必填');

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      item.serviceClassification = $stateParams.serviceClassificationId;
      item.sort = item.sort || 0;
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      // const item = $scope.currentActionItem;
      // const isEdit = $scope.currentPageState === 'edit';

      $scope.inputItems = [
        { label: '修繕專長項目名稱*', type: 'text', index: 'name' },
        { label: '排序', type: 'number', index: 'sort', info: '數值越大越前面' },
        { type: 'action' }
      ];
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
