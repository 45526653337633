export default ['confirmDialog',
  function directive (confirmDialog) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        thumbUrl: '=',
        imageUrl: '=',
        label: '='
      },
      template: '<span ng-click="openConfirm()">{{label}}<img ng-src="{{thumbUrl}}" class="image-preview-table-cell"></span>',
      link: link
    };

    function link (scope, element, attr) {
      scope.openConfirm = function openConfirm () {
        confirmDialog.openConfirm({
          templateUrl: '/view/directive/image-preview-dialog.html',
          hideHeader: true,
          hideCancel: true,
          imageUrl: scope.imageUrl
        });
      };
    }
  }
];
