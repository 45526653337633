export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('services', false, _transformer);
  const Service = Restangular.service('services');
  return Service;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      { anchorType: 'copy-id' },
      _.get(item, 'order', ''),
      _.get(item, 'classification.name', ''),
      _.get(item, 'name', ''),
      _.get(item, 'isVisible') ? '' : '隱藏',
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
