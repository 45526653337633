export default ['$scope', '$async', '$state', 'ywUtil', 'Keyword',
  function controller ($scope, $async, $state, ywUtil, Keyword) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '關鍵字名稱', placeholder: '請輸入關鍵字名稱', params: 'name' },
          { btnName: '網域辨識名稱', placeholder: '請輸入網域辨識名稱', params: 'urlId' }
        ]
      );

      $scope.filterItems = [
        {
          name: 'isVisible',
          label: '是否顯示',
          options: { true: '顯示', false: '隱藏' },
          changed: $scope.mainAction.setClearFilterTrue,
          type: 'noOwnAttrSelect'
        }
      ];

      $scope.queryPage = 'keyword'; // for GA

      $scope.mainAction.initResource(Keyword);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true
      };
    }
  }];
