export default {
  bindings: {
    reservation: '<',
    isServiceAdmin: '<'
  },
  template: `
    <table class="table">
      <tr>
        <th>
          分類/服務方案
          <span uib-dropdown is-open="editServicesOpen" ng-if="$ctrl.isServiceAdmin">
            <i uib-dropdown-toggle class="far fa-edit"></i>
            <ul class="dropdown-menu" uib-dropdown-menu>
              <li><a ng-click="$ctrl.onEditServices($ctrl.reservation, true)">編輯目前設定</a></li>
              <li><a ng-click="$ctrl.onEditServices($ctrl.reservation, false)">重新設定</a></li>
            </ul>
          </span>

          <span
            class="fas fa-info-circle ptr"
            uib-tooltip="查看服務分類/方案修改歷程"
            ng-click="$ctrl.onOpenEditLogDialog($ctrl.reservation)"
          ></span>
        </th>
        <th style="text-align: right;"></th>
        <th style="text-align: right;"></th>
        <th style="text-align: right;" ng-if="!$ctrl.reservation.isStandard">總價</th>
      </tr>
      <tr ng-repeat="service in $ctrl.reservation.services track by $index">
        <td style="text-align: left;">
          {{$ctrl.reservation.serviceClassificationName}}
          /
          {{service.serviceId.name}}
        </td>
        <td></td>
        <td></td>
        <td style="text-align: right; white-space: nowrap;">
          {{$ctrl.reservation.quotation ? $ctrl.reservation.quotation.total : '需評估後報價'}}
        </td>
      </tr>

      <tr ng-repeat="customization in $ctrl.normalCustomizations track by $index">
        <td style="text-align: left;">
          {{customization.title + '：' + customization.name}}
          <span ng-if="customization.customizedValue">，{{customization.customizedValue}}</span>
        </td>
        <td style="text-align: right; white-space: nowrap;">
          <span>X{{customization.count}}</span>
        </td>
        <td style="text-align: right; white-space: nowrap;">
          <span ng-if="$ctrl.reservation.isStandard">{{'$'}}{{customization.addCost}}</span>
        </td>
        <td style="text-align: right; white-space: nowrap;">
          <span ng-if="$ctrl.reservation.isStandard">{{'$'}}{{customization.subtotal}}</span>
        </td>
      </tr>
      <tr ng-repeat="customizations in $ctrl.customizedInputCustomizationLists track by $index">
        <td style="text-align: left;">
          {{customizations[0].title}}：
          <span ng-repeat="customization in customizations track by $index">
            {{$index > 0 ? ',' : ''}}
            {{customization.name}}
            <span ng-if="customization.expandedItem === 'word'">{{customization.customizedValue}}</span>
            <span ng-if="customization.expandedItem === 'date'">
              {{customization.customizedValue | date: 'yyyy/MM/dd'}}
            </span>
          </span>
        </td><td>
        </td><td>
        </td><td>
        </td>
      </tr>

      <tr ng-if="$ctrl.reservation.discount">
        <td style="text-align: left;">
          {{$ctrl.reservation.discount.title}}
          <div ng-repeat="text in $ctrl._remindToVender track by $index" class="color-red">
            {{$index === 0 ? '廠商注意事項：' : ''}}
            {{text}}
          </div>
        </td>
        <td></td>
        <td></td>
        <td style="text-align: right; white-space: nowrap;">
          {{'-$'}}{{-$ctrl.reservation.discount.value}}
        </td>
      </tr>
      <tr ng-if="!$ctrl.reservation.discount && $ctrl.reservation.originRef.coupon">
        <td style="text-align: left;">
          {{'優惠碼-'}}{{$ctrl.reservation.originRef.coupon.title}}
          <div ng-repeat="text in $ctrl._remindToVender track by $index" class="color-red">
            {{text}}
          </div>
        </td>
        <td></td>
        <td></td>
        <td style="text-align: right;">
          <span style="white-space: nowrap;" ng-if="$ctrl.reservation.originRef.coupon.discountValueType === 'number'">
            {{'-$'}}{{-$ctrl.reservation.originRef.coupon.discountValue}}
          </span>
          <span style="white-space: nowrap;" ng-if="$ctrl.reservation.originRef.coupon.discountValueType === 'percentage' && $ctrl.reservation.paymentStageSetting['3'].order">
            {{'-$'}}{{$ctrl.reservation.paymentStageSetting['3'].order.subtotal - ($ctrl.round($ctrl.reservation.paymentStageSetting['3'].order.subtotal * $ctrl.reservation.originRef.coupon.discountValue / 100))}}
          </span>
        </td>
      </tr>
  
    </table>

    <table class="table">
      <tr>
        <th>款項名目</th>
        <th style="text-align: right;">金額</th>
      </tr>
      <tr ng-if="$ctrl.reservation.quotation.travelingExpense.isExists && $ctrl.reservation.quotation.travelingExpense.total > 0">
        <td style="text-align: left;">車馬費(未稅)</td>
        <td style="text-align: right; white-space: nowrap;">
          {{$ctrl.reservation.quotation.travelingExpense.total}}
        </td>
      </tr>
      <tr ng-if="$ctrl.reservation.quotation.evaluationFee.isExists && $ctrl.reservation.quotation.evaluationFee.total > 0">
        <td style="text-align: left;">案前作業費(未稅)</td>
        <td style="text-align: right; white-space: nowrap;">
          {{$ctrl.reservation.quotation.evaluationFee.total}}
        </td>
      </tr>
      <tr ng-if="$ctrl.reservation.quotation.designContractAmount.isExists && $ctrl.reservation.quotation.designContractAmount.total > 0">
        <td style="text-align: left;">設計合約成交金額(未稅)</td>
        <td style="text-align: right; white-space: nowrap;">
          {{$ctrl.reservation.quotation.designContractAmount.total}}
        </td>
      </tr>
      <tr ng-if="$ctrl.reservation.quotation.engineeringContractAmount.isExists && $ctrl.reservation.quotation.engineeringContractAmount.total > 0">
        <td style="text-align: left;">工程合約成交金額(未稅)</td>
        <td style="text-align: right; white-space: nowrap;">
          {{$ctrl.reservation.quotation.engineeringContractAmount.total}}
        </td>
      </tr>
      <tr
        ng-if="$ctrl.reservation.quotation.additionAndReduction.isExists && $ctrl.reservation.quotation.additionAndReduction.total > 0"
        class="color-red-600"
      >
        <td style="text-align: left;">追加減款(未稅)</td>
        <td style="text-align: right; white-space: nowrap;">
          {{$ctrl.reservation.quotation.additionAndReduction.total}}
        </td>
      </tr>
    </table>
  `,
  controller: ['$element', '$async', 'ywEvent', 'Coupon', 'UnorderedReservation', 'serviceEditDialog', 'confirmDialog', function reservationSinyiStaffInfo ($element, $async, ywEvent, Coupon, UnorderedReservation, serviceEditDialog, confirmDialog) {
    const resetData = $async(async () => {
      let coupon = null;
      if (_.get(this.reservation, 'originRef.coupon._id')) {
        coupon = await Coupon.one(_.get(this.reservation, 'originRef.coupon._id')).customGET();
      }
      this._remindToVender = _.get(coupon, 'remind.toVender', '')
        .split('\n')
        .filter(remind => remind);

      this.normalCustomizations = this.reservation.customizations
        .filter((item) => !item.expandedItem);
      const customizationsWithoutNumbers = this.reservation.customizations
        .filter((item) => item.expandedItem);
      this.customizedInputCustomizationLists = Object.values(_.groupBy(
        customizationsWithoutNumbers,
        (item) => item.customizationId
      ));
    });

    this.onEditServices = $async(async (item, showCurrentSelection) => {
      try {
        const data = await serviceEditDialog.openConfirm(item, showCurrentSelection);
        const result = await UnorderedReservation.one(item._id).customPUT(data, 'scAndService');
        if (result.error) {
          throw new Error(result.error);
        } else {
          Object.keys(result).forEach(key => {
            item[key] = result[key];
          });
          resetData();
          ywEvent.emit('UPDATE_ITEM_IN_LIST', result);
          ywEvent.emit('SUCCESS', '編輯完成');
        }
      } catch (err) {
        ywEvent.emit('ALERT', err.message);
      }
    });

    this.onOpenEditLogDialog = $async(async (item) => {
      const data = await UnorderedReservation.one(item._id).customGET('changeScAndServiceLog');
      if (data.error) {
        ywEvent.emit('ALERT', data.error);
      } else {
        if (data.length) {
          const creationLog = {
            at: item.createdAt,
            byName: item.name,
            afterEdit: data[0].beforeEdit,
            _id: item._id
          };
          data.unshift(creationLog);
        }
        const reversedData = data.reverse();
        confirmDialog.openConfirm({
          title: '分類/方案修改歷程',
          templateUrl: '/view/dialog/unordered-reservation-service-edit-log.html',
          logs: reversedData
        }, 'custom-width-1024');
      }
    });

    this.$onInit = resetData;
    this.$onChange = resetData;
  }]
};
