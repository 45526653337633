export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('advancedFilterItems', false, _transformer);
  const AdvancedFilterItem = Restangular.service('advancedFilterItems');
  return AdvancedFilterItem;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'sort'),
      _.get(item, 'name'),
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
