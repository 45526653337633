// aim to replace the dialog in yw-popup-selection
// and provide multi-selection function
export default ['ngDialog', function factory (ngDialog) {
  return {
    openConfirm: openConfirm
  };

  function openConfirm (dialogData) {
    const params = {
      template: '/view/dialog/popup-selection-factory.html',
      showClose: false,
      className: `ngdialog-theme-default yw-plain-dialog ${dialogData.imageUrl ? 'custom-width-800' : ''}`,
      closeByEscape: true,
      closeByDocument: true,
      data: Object.assign(dialogData, {
        itemClicked: itemClicked,
        confirmClicked: confirmClicked,
        searchTextChange: searchTextChange,
        searchText: ''
      })
    };

    if (dialogData.byCategory) {
      params.data.categories = _.union(_.map(dialogData.options, opt => opt.category));
      console.log('categories: ', params.data.categories);
    }

    const originOptions = _.clone(dialogData.options);

    // called when type === 'multiple'
    function itemClicked (value) {
      if (_.includes(dialogData.selectedList, value)) { _.pull(dialogData.selectedList, value); } else { dialogData.selectedList.push(value); }
    }

    function confirmClicked (confirm, closeThisDialog) {
      if (dialogData.type === 'multiple') {
        confirm(dialogData.selectedList);
      }
    }

    function searchTextChange (text) {
      if (_.isEmpty(text)) dialogData.options = originOptions;
      dialogData.options = _.filter(originOptions, option => option.label.match(text));
    }

    return ngDialog.openConfirm(params);
  }
}];
