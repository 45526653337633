export default ['$timeout', function directive ($timeout) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      options: '=',
      blankItem: '=',
      disabled: '=',
      placeholder: '=',
      currentOption: '=',
      onChanged: '&'
    },
    /* eslint-disable */
    template: `
      <span class="dropdown-selection" uib-dropdown>
        <a class="btn btn-default dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style="height: 36px;"
          uib-dropdown-toggle>
          {{label}}
          <span class="caret" style="margin-left:10px;"></span>
        </a>
        <ul class="dropdown-menu" uib-dropdown-menu style="left:0;">
          <li ng-repeat="option in options">
            <a ng-bind="option.label" ng-click="onSelected(option)"></a>
          </li>
        </ul>
      </span>`,
    /* eslint-enable */
    link: link
  };

  function link (scope, element, attr) {
    function setDefaultLabel () {
      scope.label = scope.placeholder || '請選擇';
    }
    setDefaultLabel();

    scope.onSelected = function (option) {
      scope.label = option.label;
      scope.currentOption = option;
      $timeout(function () {
        scope.$apply();
        if (scope.onChanged) scope.onChanged();
      });
    };

    scope.$watch('currentOption', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      if (_.isEmpty(newValue)) setDefaultLabel();
    });
  }
}];
