import moment from 'moment';

export default ['$timeout', '$filter', 'ywDialog', 'TIME_ZONE_OFFSET', function directive ($timeout, $filter, ywDialog, TIME_ZONE_OFFSET) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      options: '=ywOptions',
      changed: '&ywOnChange',
      date: '=ywDate',
      minDate: '=ywMinDate',
      maxDate: '=ywMaxDate',
      initDate: '=ywInitDate',
      defaultSelection: '=ywDefaultSelection',
      disabled: '=ywDisabled',
      defaultLabel: '=ywDefaultLabel',
      white: '=',
      hideCancel: '=',
      toDateStart: '=',
      toDateEnd: '=',
      withTime: '=',
      withSecond: '=',
      activeStyle: '='
    },
    template: `
      <div class="dropdown-wrapper wrapper" ng-class="{disabled: disabled, white: white, 'active-control': date && activeStyle}">
        <a class="select" ng-disabled="disabled" ng-click="disabled || openPopupMenu()">
        <span ng-bind="dateLabel">
        </span>
        <span class="caret"></span>
        </a>
      </div>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.openPopupMenu = function () {
      const dialogOpts = { plain: true, closeByEscape: true, closeByDocument: true };
      const data = {
        selectedDate: this.date || this.defaultSelection || new Date(),
        minDate: this.minDate,
        maxDate: this.maxDate,
        hideCancel: this.hideCancel,
        cancelSelection,
        hourSlider: (scope.date && moment(scope.date).hour()) || 0,
        minuteSlider: (scope.date && moment(scope.date).minute()) || 0,
        secondSlider: (scope.date && moment(scope.date).second()) || 0,
        getDateTime () {
          return moment(this.selectedDate)
            .hour(this.hourSlider)
            .minute(this.minuteSlider)
            .second(this.secondSlider)
            .millisecond(0);
        },
        getFormatedDateTime () {
          return this.getDateTime().utcOffset(TIME_ZONE_OFFSET).format(scope.withSecond ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD HH:mm');
        },
        withTime: scope.withTime,
        withSecond: scope.withSecond
      };

      ywDialog.openConfirm('/view/directive/popup-date-picker.html', data, null, dialogOpts)
        .then(function (value) { // date selected callback
          if (_.isUndefined(value)) value = null;
          console.log('date: ', value);
          if (!_.isNull(value)) {
            scope.date = moment(value).toDate();
            if (scope.toDateStart) {
              scope.date = moment(value).startOf('day').utc().format();
            } else if (scope.toDateEnd) {
              scope.date = moment(value).endOf('day').utc().format();
            } else if (scope.withTime) {
              scope.date = moment(data.getDateTime()).utc().format();
            }
          } else {
            scope.date = null;
          }
          $timeout(function () {
            scope.$apply();
            scope.setDateLabel();
            scope.changed();
          });
        });
    };

    const cancelSelection = (confirmCb) => {
      scope.date = undefined;
      $timeout(function () {
        scope.$apply();
        scope.setDateLabel();
        scope.changed();
      });
      confirmCb();
    };

    scope.setDateLabel = function () {
      if ((_.isUndefined(this.date) || _.isNull(this.date)) && this.defaultLabel) {
        this.dateLabel = this.defaultLabel;
        return this.dateLabel;
      }
      if ((_.isUndefined(this.date) || _.isNull(this.date)) && !this.initDate) {
        this.dateLabel = '請選擇';
        return this.dateLabel;
      }

      if (_.isUndefined(this.date) || _.isNull(this.date)) { this.date = new Date(); }
      this.dateLabel = moment(this.date).utcOffset(TIME_ZONE_OFFSET).format(scope.withSecond ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD');
    };

    scope.setDateLabel();

    scope.$watch('date', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      scope.setDateLabel();
    });

    scope.$on('init-date', function () {
      scope.date = undefined;
      scope.setDateLabel();
    });
  }
}];
