export default {
  template: `
    <div class="page-header">
      <div class="main">
        網站內容管理
        <h1 class="sub">網站內容設定</h1>
      </div>
    </div>
    <div class="page-content">
      <div class="action-wrapper action-common base-wrapper show-tab-left-border" style="padding: 0;">
        <md-tabs md-dynamic-height md-border-bottom>
          <md-tab label="版位設定">
            <md-content class="md-padding" style="margin-left: 20px;">
              <div ng-controller="saWebsiteContentLayoutController">
                <div ng-include="'/view/page/service-admin/website-content-layout.html'"></div>
              </div>
            </md-content>
          </md-tab>
          <md-tab label="相簿設定">
            <md-content class="md-padding" style="margin-left: 20px;">
              <div ng-controller="saWebsiteContentAlbumController">
                <div ng-include="'/view/page/service-admin/website-content-album.html'"></div>
              </div>
            </md-content>
          </md-tab>
        </md-tabs>
      </div>
    </div>
  `,
  controller: ['$element', function websiteContentPage ($element) {
    this.$onInit = () => {
      $element.addClass('subpage-component');
    };
  }]
};
