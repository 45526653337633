import React, { Component } from 'react';
import Checkbox from './../common/Checkbox';

import { reservationStatusList } from './../../share';
import ExtraControls from './../common/ReservationInfo/ExtraControls';
import PaymentInfo from './../common/ReservationInfo/PaymentInfo';
import ServiceClassificationCell from './../common/ReservationInfo/ServiceClassificationCell';
import ServiceInfoCell from './../common/ReservationInfo/ServiceInfoCell';
import UserInfo from './../common/ReservationInfo/UserInfo';
import DeviceType from './../common/ReservationInfo/DeviceType';
import StaffAssigner from './StaffAssigner';
import ReservationStatus from './ReservationStatus';
import DetailControls from '../common/ReservationInfo/DetailControls';

const style = {
  icon: {
    fontSize: '24px'
  },
  td: {
    textAlign: 'center'
  }
};

const processedIndex = reservationStatusList
  .findIndex(status => status.value === 'processed');
const statusListAfterProcess = reservationStatusList
  .slice(processedIndex)
  .map(status => status.value);

class Content extends Component {
  render () {
    const {
      reservation,
      ywEvent,
      currentUser,
      openStateChangeDialog,
      openVenderAssignDialog,
      openStaffAssignDialog,
      openAcceptanceNoteDialog,
      selectedItems,
      toggleItem,
      pinReservations
    } = this.props;

    const checked = selectedItems.some(item => item._id === reservation._id);

    return (
      <tr>
        <td>
          <Checkbox checked={checked} onChange={() => toggleItem(reservation)} />
        </td>
        <td>
          <ExtraControls
            reservation={reservation}
            pinReservations={pinReservations}
            ywEvent={ywEvent}
          />
        </td>
        <td><PaymentInfo reservation={reservation} /></td>
        <td>
          <ServiceClassificationCell reservation={reservation} />
        </td>
        <td>
          <ServiceInfoCell reservation={reservation} />
        </td>
        <td style={style.td}>
          <UserInfo reservation={reservation} />
        </td>
        <td>
          {_.get(reservation, 'vender.name')}
          {currentUser.hasRole('serviceAdmin') &&
          !_.includes(statusListAfterProcess, reservation.state) &&
            <i
              className='img icon-list-edit ptr'
              aria-hidden='true'
              style={style.icon}
              onClick={() => openVenderAssignDialog(reservation)}
            />}
        </td>
        <td>
          <StaffAssigner
            reservation={reservation}
            onEditStaff={openStaffAssignDialog}
          />
        </td>
        <td>
          <ReservationStatus
            reservation={reservation}
            currentUser={currentUser}
            onEditStateClick={openStateChangeDialog}
          />
        </td>
        <td style={style.td}>
          <DetailControls
            reservation={reservation}
            ywEvent={ywEvent}
          />
        </td>
        <td style={style.td}>
          <i
            className='fas fa-file-alt ptr'
            aria-hidden='true'
            style={style.icon}
            onClick={() => openAcceptanceNoteDialog(reservation)}
          />
        </td>
        <td style={style.td}>
          <DeviceType
            reservation={reservation}
            // ywEvent={ywEvent}
          />
        </td>
      </tr>
    );
  }
}

Content.defaultProps = {
  reservations: [],
  selectedItems: []
};

export default Content;
