export default ['$async', 'confirmDialog', 'ywEvent', 'ywUtil', 'ArticleClassification', 'Keyword', 'Article', 'Vender',
  function articleUtilFactory ($async, confirmDialog, ywEvent, ywUtil, ArticleClassification, Keyword, Article, Vender) {
    const util = {};

    // sources 為當前頁面持有的文章總體，批次更新完後要再把資料寫回被更新的文章中
    util.openBatchUpdate = async (articleIds = [], sources = []) => {
      const fieldOptions = [
        {
          value: 'isPublish',
          label: '出版狀態',
          default: { data: true }
        },
        {
          value: 'publishDuration',
          label: '上架區間',
          default: { data: { publishStartTime: new Date() } }
        },
        {
          value: 'articleClassification',
          label: '文章類別',
          default: { data: undefined }
        },
        {
          value: 'keywords',
          label: '關鍵字',
          default: { action: 'add', data: [] }
        },
        {
          value: 'from',
          label: '文章出處',
          default: { data: '' }
        },
        {
          value: 'byline',
          label: '原文作者',
          default: { data: '' }
        },
        {
          value: 'navData',
          label: '前往預約按鈕跳轉',
          default: { data: undefined }
        },
        {
          value: 'venderRef',
          label: '關聯廠商',
          default: { data: undefined }
        }
      ];

      const dialogData = {
        title: '批次設定文章',
        templateUrl: '/view/dialog/batch-update-article.html',
        fieldOptions,
        targetField: _.head(fieldOptions).value,
        onTargetFieldChange: (targetField) => {
          const option = _.find(fieldOptions, opt => opt.value === targetField);
          dialogData.confirmValue = _.clone(_.get(option, 'default', {}));
        },
        resourceMap: {
          articleClassification: ArticleClassification,
          keyword: Keyword,
          vender: Vender
        },
        confirmValue: _.clone(_.head(fieldOptions).default)
      };
      const result = await confirmDialog.openConfirm(dialogData);
      const target = dialogData.targetField;
      return handleBatchUpdate(target, articleIds, result, sources);
    };

    const handleBatchUpdate = async (target, articleIds, params, sources) => {
      if (target === 'isPublish') {
        const result = await Article
          .one('publishState')
          .customPUT({ articleIds, isPublish: params.data });
        replaceBatchUpdatedResult(result, articleIds, { isPublish: params.data }, sources);
      } else if (target === 'publishDuration') {
        const result = await Article
          .one('publishTime')
          .customPUT({
            articleIds,
            publishStartTime: params.data.publishStartTime,
            publishEndTime: params.data.publishEndTime
          });
        replaceBatchUpdatedResult(result, articleIds, params.data, sources);
      } else if (target === 'articleClassification') {
        const result = await Article
          .one('classification')
          .customPUT({ articleIds, articleClassification: params.data });
        const articleClassification = result.error
          ? null
          : await ArticleClassification.one(params.data).customGET();
        replaceBatchUpdatedResult(result, articleIds, { articleClassification }, sources);
      } else if (target === 'keywords') {
        const result = await Article
          .one('keyword')
          .customPUT({ articleIds, keywords: params.data, method: params.action });
        if (result.error) {
          ywEvent.emit('ALERT', result.error);
        } else {
          const keywords = await Promise.all(
            params.data.map(id => Keyword.one(id).customGET())
          );
          _.forEach(sources, (item, index) => {
            if (!item || !_.includes(articleIds, item._id)) { return; }
            if (params.action === 'add') {
              item.keywords = _.unionWith(
                (item.keywords || []),
                keywords,
                (a, b) => a._id === b._id
              );
            } else if (params.action === 'remove') {
              item.keywords = _.differenceWith(
                (item.keywords || []),
                keywords,
                (a, b) => a._id === b._id
              );
            }
            sources[index] = item.clone();
          });
          ywEvent.emit('SUCCESS', '設定完成');
        }
      } else if (target === 'from') {
        const result = await Article
          .one('from')
          .customPUT({ articleIds, from: params.data });
        replaceBatchUpdatedResult(result, articleIds, { from: params.data }, sources);
      } else if (target === 'byline') {
        const result = await Article
          .one('byline')
          .customPUT({ articleIds, byline: params.data });
        replaceBatchUpdatedResult(result, articleIds, { byline: params.data }, sources);
      } else if (target === 'navData') {
        const result = await Article
          .one('navData')
          .customPUT({ articleIds, navData: params.data });
        replaceBatchUpdatedResult(result, articleIds, { navData: params.data }, sources);
      } else if (target === 'venderRef') {
        const result = await Article
          .one('venderRef')
          .customPUT({ articleIds, venderId: params.data });
        replaceBatchUpdatedResult(result, articleIds, { vender: { _id: params.data } }, sources);
      }
    };

    const replaceBatchUpdatedResult = (result, articleIds, updatedData, sources) => {
      if (result.error) {
        ywEvent.emit('ALERT', result.error);
      } else {
        _.forEach(sources, (item, index) => {
          if (!item || !_.includes(articleIds, item._id)) { return; }
          _.assign(item, updatedData);
          sources[index] = item.clone();
        });
        ywEvent.emit('SUCCESS', '設定完成');
      }
    };

    return util;
  }
];
