export default ['ToBuyStoreProduct', 'confirmDialog', 'ywEvent',
  function directive (ToBuyStoreProduct, confirmDialog, ywEvent) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        title: '=',
        storeId: '='
      },
      template:
            '<button class="yw-btn yw-btn-orange" ng-click="openConfirm()" style="margin: 0; height: 36px; width: 120px; border-radius: 30px; font-size:16px;">' +
                '<span class="glyphicon glyphicon-sort"></span>' +
                '編輯排序' +
            '</button>',
      link: link
    };

    function link (scope, element, attr) {
      scope.openConfirm = function () {
        const dialogData = {
          title: _.get(scope, 'title', '編輯排序'),
          templateUrl: '/view/dialog/product-order-setup-dialog-content.html',
          confirmLabel: '確認',

          // methods
          itemMoved: itemMoved,

          // data
          products: []
        };

        ToBuyStoreProduct.getList({ toBuyStore: scope.storeId, limit: 1000 }).then(res => {
          dialogData.products = res.map(product => product.obj.plain());
        });

        function applyChange () {
          ToBuyStoreProduct.one('sortOrder').obj.customPUT({ toBuyStoreProductIds: dialogData.products.map(product => product.objectId) })
            .then(res => {
              if (res.error) ywEvent.emit('ALERT', res.error);
              else ywEvent.emit('SUCCESS', '成功');
            });
        }

        function itemMoved (dialogData, idx) {
          console.log('event moved: ', idx);
          dialogData.products.splice(idx, 1); // remove from list
        }

        return confirmDialog.openConfirm(dialogData).then(applyChange);
      };
    }
  }];
