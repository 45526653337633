export default ['$scope', '$async', 'ywUtil', 'Keyword', 'Vender',
  function controller ($scope, $async, ywUtil, Keyword, Vender) {
    const self = this;
    // $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.imageFile = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      console.log('validator:', item);

      if (!item.name) return $scope.alert('相簿名稱不能為空');
      if (isEdit && !item.urlId) return $scope.alert('網址辨識名稱不能為空');
      if (item.urlId) {
        const validateString = item.urlId.replace(/^Photo_/, '');
        if (!/^[a-z-0-9_]+$/.test(validateString)) return $scope.alert('網址辨識名稱格式不符');
      }

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';

      if (isEdit) {
        item.keywords = (item.keywords || []).map(keyword => keyword._id);
        item.venderId = _.get(item, 'vender._id');
      }

      $scope.inputItems = [
        { label: '相簿名稱*', type: 'text', index: 'name' },
        { label: '相簿說明', type: 'textarea', index: 'description' },
        {
          label: '信義原廠商ID',
          type: 'description',
          description: _.get(item, 'sinyiOriginalInfo.venderId', '')
        },
        {
          label: '關聯廠商',
          type: 'searchable-obj-picker',
          index: 'venderId',
          title: '關聯廠商',
          resource: Vender,
          filterField: 'name'
        },
        {
          label: '關鍵字',
          type: 'multi-searchable-obj-picker',
          index: 'keywords',
          title: '關鍵字',
          resource: Keyword,
          filterField: 'name',
          titleField: 'name',
          closeCityFilter: true,
          showSelectedItemsCount: true,
          queryParams: { limit: 200 }
        },
        { label: '排序', type: 'number', index: 'sort' },
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ]
        },
        {
          label: '網址辨識名稱',
          type: 'text',
          class: 'long',
          index: 'urlId',
          info: '由此自定網址名稱，使用全英文小寫或數字命名，可使用"-"及"_"分隔，例 interior-select，不設定則系統預設使用id'
        },
        {
          label: '前往預約按鈕跳轉',
          type: 'navigation-setting',
          index: 'navData',
          buttonLabel: '設定'
        },
        { type: 'action' }
      ];
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
