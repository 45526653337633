export default ['$scope', '$async', '$state', 'ywUtil', 'currentUser', 'TA3Banner',
  function controller ($scope, $async, $state, ywUtil, currentUser, TA3Banner) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();

      $scope.queryPage = 'ta3-banner'; // for GA

      $scope.mainAction.initResource(TA3Banner);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true,
        consultationMethod: 'lineAt',
        order: 0
      };
    }

    $scope.toolbarBtns = [
      {
        label: '說明',
        onClick: () => {
          window.open('https://docs.google.com/presentation/d/1vNh3v2vpbYvlait2FsUWiimiK_YhZb9BshBhqYmXmDA/edit#slide=id.g153433f88c6_0_11', '_blank');
        }
      }
    ];
  }];
