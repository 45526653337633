export default ['$state', '$http', 'session', 'ywEvent', 'API_URL', function factory ($state, $http, session, ywEvent, API_URL) {
  const currentUser = {}; // factory object
  let _currentUser = null; // user info object stored here

  currentUser.initial = function () {
    if (!_currentUser) { _currentUser = session.currentUser || {}; }
    if (session.isValid()) { currentUser.setAuthToken(); }
  };

  currentUser.refetch = function () {
    return $http({
      method: 'GET',
      url: API_URL + 'users/me'
    }).then(function (res) {
      currentUser.setInfo({ user: res.data.result.me });
    });
  };

  currentUser.get = function () {
    return _currentUser;
  };

  currentUser.getToken = function (role = '') {
    return session._id;
  };

  currentUser.getVenderId = function () {
    return _.get(currentUser.get(), 'link.vender', null);
  };

  currentUser.getCommunity = function () {
    return _.get(currentUser.get(), 'currentHouse.community', {});
  };

  currentUser.getCommId = function () {
    return _.get(currentUser.get(), 'currentHouse.community.objectId', '');
  };

  currentUser.getCommName = function () {
    return _.get(currentUser.get(), 'currentHouse.community.title', '');
  };

  currentUser.getHouse = function () {
    return _.get(currentUser.get(), 'currentHouse', {});
  };

  currentUser.hasRole = function (role) {
    const user = currentUser.get();
    return _.get(user, 'role') === role;
  };

  currentUser.getRole = function () {
    const user = currentUser.get();
    return _.get(user, 'role');
  };

  currentUser.setAuthToken = function () {
    $http.defaults.headers.common.Authorization = `Bearer ${session._id}`;
  };

  currentUser.setInfo = function (current) {
    session.set(current);
    if (_.get(current, 'user')) { _currentUser = current.user; } // update currentUser reference
    currentUser.setAuthToken();
  };

  currentUser.logout = function () {
    session.clearLogin();
    _currentUser = null;
    ywEvent.emit('LOGGED_OUT');
    $state.go('login');
  };

  currentUser.getPermission = function (name) {
    return _.get(currentUser.get(), `permission.${name}`) === 'access';
  };

  currentUser.getInitialState = function () {
    switch (currentUser.getRole()) {
      case 'admin': return 'admin';
      case 'serviceAdmin': return 'service-admin';
      case 'vender': return 'vender';
      case 'venderStaff': return 'vender-staff';
      default: return 'login';
    }
  };

  return currentUser;
}];
