import moment from 'moment';

export default ['Restangular', 'ywUtil', 'ywEvent', 'WEBAPP_URL', function factory (Restangular, ywUtil, ywEvent, WEBAPP_URL) {
  Restangular.addElementTransformer('layouts', false, _transformer);
  const Layout = Restangular.service('layouts');
  return Layout;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    function clickAction () {
      ywUtil.cloneText(`${WEBAPP_URL}${{ navigation: '', marketing: 'mkt/', olMarketing: 'olmkt/', info: 'info/' }[item.type]}${item.urlId}`);
      ywEvent.emit('SUCCESS', '已複製');
    }

    item._columns = [
      { anchorType: 'checkbox' },
      { anchorType: 'true-or-false', isTrue: _.get(item, 'isVisible') },
      item.name,
      item.type !== 'homepages'
        ? {
            anchorType: 'content',
            content: item.type !== 'homepages' && `${{ navigation: '', marketing: '/mkt', olMarketing: '/olmkt', info: '/info' }[item.type]}/${item.urlId}`,
            popover: '點擊複製',
            icon: 'link',
            clickAction
          }
        : null,
      moment(_.get(item, 'updatedAt')).format('YYYY/MM/DD HH:mm'),
      _.get(item, 'operationLog.byName'),
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
