export default ['$async', 'Restangular', 'ywEvent', 'confirmDialog', function ($async, Restangular, ywEvent, confirmDialog) {
  const link = $async(async (scope, element, attr) => {
    scope.clicked = $async(async phone => {
      const res = await Restangular.service('sipPhoneNums').one('registerForwarding').customPOST({ phone });
      if (res.error) {
        ywEvent.emit('ALERT', String(res.error));
      } else {
        confirmDialog.openConfirm({ htmlContent: `為保障隱私，顯示電話為070轉接號碼，請勿從通話紀錄中重撥!<br/>電話：${res}。` });
      }
    });
  });

  return {
    restrict: 'E',
    transclude: true,
    scope: {
      phone: '='
    },
    template: `
    <span>
      <img
        src="/img/ic_form_phone_blue-2x.png"
        style="zoom: 0.5; cursor: pointer;"
        ng-click="clicked(phone)"
      />
    </span>
    `,
    link: link
  };
}];
