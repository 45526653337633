import listController from './list';
import actionController from './action';

export default ['$scope', '$controller', 'currentAlbum', function controller ($scope, $controller, currentAlbum) {
  // inherit
  $controller('mainActionController', { $scope: $scope });

  $scope.$on('$destroy', function () { $scope.mainAction.pager.clear(); });

  $scope.listController = listController;
  $scope.actionController = actionController;
  $scope.pageStates = {};
  $scope.currentPage = 'photo';
  $scope.currentPageState = 'list';

  init();

  function init () {
    /** define in-page states. use templateUrl if general templates unsufficient */
    const name = currentAlbum.name;
    $scope.pageStates = {
      list: { customLabel: `相片列表-${name}` },
      add: { customLabel: `新增相片-${name}` },
      edit: { customLabel: `編輯相片-${name}` }
    };
  }
}];
