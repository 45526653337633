export default {
  bindings: {
    staffInfo: '<'
  },
  template: `
    <table class="table" ng-if="$ctrl.staffInfo">
      <tr>
        <th>信義員工資訊</th>
        <th>信義員工手機</th>
        <th>信義分店資訊</th>
        <th><span ng-if="$ctrl.isServiceAdmin">業務區資訊</span></th>
      </tr>
      <tr>
        <td style="text-align: left;">
          <div>姓名：{{$ctrl.staffInfo.empName}}</div>
          <div>
            員編：{{$ctrl.staffInfo.empId}}
          </div>
          <div ng-if="$ctrl.isServiceAdmin">
            身份：{{$ctrl.staffInfo.empTitle}}
          </div>
        </td>
        <td style="text-align: left;">
          {{$ctrl.staffInfo.empPhone}}
        </td>
        <td style="text-align: left;">
          <div>{{$ctrl.staffInfo.depName}}</div>
          <div ng-if="$ctrl.isServiceAdmin">分店代碼：{{$ctrl.staffInfo.depId}}</div>
          <div>分店電話：{{$ctrl.staffInfo.depTel}}</div>
          <div>分店地址：{{$ctrl.staffInfo.depAddress}}</div>
        </td>
        <td style="text-align: left;">
          <div ng-if="$ctrl.isServiceAdmin">
            <div>{{$ctrl.staffInfo.depArea}}</div>
            <div>業務區代碼：{{$ctrl.staffInfo.depAreaId}}</div>
          </div>
        </td>
      </tr>
    </table>
  `,
  controller: ['$element', 'currentUser', function reservationSinyiStaffInfo ($element, currentUser) {
    this.$onInit = () => {
      this.isServiceAdmin = currentUser.hasRole('serviceAdmin');
    };
  }]
};
