import React, { Fragment } from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';
import moment from 'moment';

const style = {
  icon: {
    fontSize: '24px'
  }
};

const ExtraControls = ({ reservation, pinReservations, ywEvent }) => {
  const isPined = reservation.isPinTop || pinReservations.some(
    pinItem => pinItem.isPinTop && pinItem._id === reservation._id
  );

  const pinClassName = `fas fa-thumbtack ptr ${isPined ? 'color-text-orange' : 'color-gray-300'}`;

  let clockClassName = 'fas fa-clock ptr ';
  let clockText = '未設定聯絡日期(點擊設定)';
  const connectionDate = _.get(reservation, 'pinConnectionInfo.date') ||
    _.get(reservation, 'pinConnectionDate') ||
    _.get(reservation, 'unpinConnectionDate') || // reservation的資料
    _.get(reservation, 'unpinConnectionInfo.date'); // pinRservation的資料
  const connectionUrgent = !!connectionDate &&
    moment().startOf('day').isSameOrAfter(moment(connectionDate).subtract(3, 'days'));
  if (connectionDate) {
    clockClassName += connectionUrgent ? 'color-red-600' : 'color-primary-600';
    const dateText = moment(connectionDate).format('YYYY/MM/DD');
    clockText = connectionUrgent ? `須於三天內聯絡：${dateText}` : `已設定聯絡日期：${dateText}`;
  }

  const onPinClick = () => {
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_PIN_DIALOG',
      payload: { reservation, isPined }
    });
  };

  const onContactDeadlineClick = () => {
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'OPEN_CONTACT_DEADLINE_DIALOG',
      payload: reservation
    });
  };

  return (
    <>
      <div>
        <StatefulToolTip
          parent={<i
            className={pinClassName}
            aria-hidden='true'
            style={style.icon}
            onClick={onPinClick}
          />} // eslint-disable-line
          position='top' arrow='center'
        >
          {isPined ? '已置頂' : '點擊置頂'}
        </StatefulToolTip>
      </div>
      <div>
        <StatefulToolTip
          parent={<i
            className={clockClassName}
            aria-hidden='true'
            style={style.icon}
            onClick={onContactDeadlineClick}
          />} // eslint-disable-line
          position='top' arrow='center'
        >
          {clockText}
        </StatefulToolTip>
      </div>
    </>
  );
};

ExtraControls.defaultProps = {
  pinReservations: []
};

export default ExtraControls;
