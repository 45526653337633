export default ['ywEvent', 'confirmDialog', 'shortUrl', 'DYNAMIC_LINK_URL', function directive (ywEvent, confirmDialog, shortUrl, DYNAMIC_LINK_URL) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {},
    template: `
      <md-button class="md-raised md-primary fit" ng-click="openDialog()">連結產生器</md-button>
    `,
    link: link
  };

  function link (scope, element, attr) {
    const dialogData = {
      generateLink,
      copySuccessed,
      deeplink: '',
      dynamicLink: '',
      shortDynamicLink: '',
      navData: {},
      utmData: {},
      isLoading: false,
      hideOptions: [1]
    };
    function copySuccessed () {
      ywEvent.emit('SUCCESS', '已複製到剪貼簿');
    }
    async function generateLink () {
      const utmParams = _.map(dialogData.utmData, (val, key) => `${key}=${val}`);
      const page = dialogData.navData.page;
      const pageParam = _.get(dialogData.navData.param, dialogData.navData.pageParam);
      const actionParam = _.get(dialogData.navData.param, dialogData.navData.actionParam);
      const action = dialogData.navData.action;
      dialogData.deeplink = `https://${DYNAMIC_LINK_URL}/${page}`;
      if (pageParam) dialogData.deeplink += '/' + pageParam;
      if (actionParam) dialogData.deeplink += '/' + actionParam;
      if (action) dialogData.deeplink += '/' + action;
      if (!_.isEmpty(utmParams)) dialogData.deeplink += '?' + utmParams.join('&');
      const dynamicLinkParams = `link=${encodeURIComponent(dialogData.deeplink)}&apn=com.yowoo.communityPlus&isi=1419354850&ibi=com.yowoo.communityPlus`;
      dialogData.dynamicLink = `https://${DYNAMIC_LINK_URL}/?${dynamicLinkParams}`;

      try {
        dialogData.isLoading = true;
        const shorUrls = await shortUrl([dialogData.dynamicLink]);
        if (shorUrls.error) throw new Error(shorUrls.error);
        dialogData.shortDynamicLink = shorUrls[dialogData.dynamicLink];
      } catch (error) {
        console.log(error);
        ywEvent.emit('ALERT', error);
      } finally {
        dialogData.isLoading = false;
      }
    }
    scope.openDialog = () => {
      confirmDialog.openConfirm({
        templateUrl: '/view/directive/dynamic-link-generater.html',
        hideCancel: true,
        confirmLabel: '關閉',
        data: dialogData
      });
    };
  }
}];
