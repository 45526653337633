import WebSocket from 'isomorphic-ws';

export default ['ywEvent', 'WEBSOCKET_URL',
  function (ywEvent, WEBSOCKET_URL) {
    const WebSocketFactory = {};
    let ws = {};

    WebSocketFactory.connect = function (channels) {
      ws = new WebSocket(WEBSOCKET_URL);
      ws.onopen = function open () {
        channels.forEach(channel => {
          ws.send(JSON.stringify({ action: 'subscribe', channel: channel }));
        });
      };
      ws.onmessage = function incoming (event) {
        const msg = JSON.parse(event.data);
        console.log('Message from server: ', msg);
        ywEvent.emit('WEBSOCKET_NOTIFICATION', msg);
      };
      ws.onclose = function close (event) {
        console.log('closeEvent', event);
        if (event.code === 3000) return;
        WebSocketFactory.connect(channels);
      };
      ws.onerror = function error (event) {
        console.log('error: ', event);
      };
    };

    WebSocketFactory.disconnect = function () {
      if (ws) {
        ws.close(3000, 'destroy');
      }
    };

    WebSocketFactory.publish = function (channel, msg) {
      const msgToSend = JSON.stringify({
        action: 'publish',
        channel: channel,
        message: msg
      });
      ws.addEventListener('open', function (event) {
        ws.send(msgToSend);
      });
    };

    return WebSocketFactory;
  }
];
