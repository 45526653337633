export default ['$scope', '$async', 'Upload', 'API_URL',
  function controller ($scope, $async, Upload, API_URL) {
    const self = this;

    self.avatarFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      if (!item.fullname) return $scope.alert('請輸入名稱');
      if (!item.email) return $scope.alert('email不能為空');
      if (!item.phone) return $scope.alert('請輸入手機');
      if (item.phone && !/^\d{10}$/.test(item.phone)) return $scope.alert('聯絡電話須為10位數字');
      if (!item.username) return $scope.alert('請輸入帳號');
      if (!/^[A-Za-z0-9]{6,}$/.test(item.username)) return $scope.alert('帳號須為6碼以上英文數字');
      if ($scope.currentPageState === 'add' && !item.password) return $scope.alert('密碼不能為空');
      if ($scope.currentPageState === 'add' && !/\w{6}/.test(item.password)) return $scope.alert('密碼須為6碼以上英文數字');
      if (item.password && item.password !== item._passwordAgain) return $scope.alert('密碼不符合');

      console.log('currentActionItem: ', $scope.currentActionItem);
      return true;
    }

    function transform () {
      console.log('transform finished: ', $scope.currentActionItem);
      // const item = $scope.currentActionItem;
    }

    function syncCurrentActionItem () {
      // const item = $scope.currentActionItem;

      $scope.inputItems = [
        { label: '名稱*', type: 'text', index: 'fullname' },
        { label: 'email*', type: 'text', index: 'email', class: 'long' },
        { label: '手機*', type: 'text', index: 'phone' },
        { label: '帳號', type: 'text', index: 'username', placeholder: '大小寫英文/數字 6 碼以上', class: 'long' },
        { label: '密碼', type: 'password', index: 'password', placeholder: '大小寫英文/數字 8 碼以上', class: 'long' },
        { label: '確認密碼', type: 'password', index: '_passwordAgain', class: 'long' },
        { type: 'action' }
      ];
    }
  }];
