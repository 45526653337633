export default ['confirmDialog', '$sce', function (confirmDialog, $sce) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      timepart: '=',
      buttonTitle: '=',
      readOnly: '=',
      pageStatus: '='
    },
    template: `
      <md-button ng-if="!readOnly" class="md-raised md-primary" ng-click="openConfirm(pageStatus)">{{buttonTitle || '設定'}}</md-button>
      <span ng-if="readOnly"
      class="glyphicon glyphicon-info-sign"
      ng-click="openConfirm()"
      uib-popover="請點擊"
      popover-trigger="'mouseenter'"
      popover-placement="top"></span>
    `,
    link
  };
  function link (scope) {
    const params = {
      templateUrl: '/view/directive/timepart-setting.html',
      timepart: _.clone(scope.timepart),
      readOnly: scope.readOnly,
      title: '設定開放時間',
      onChange (data) {
        this.timepart = data;
      }
    };

    if (scope.readOnly) {
      params.title = '顯示開放時間';
      delete params.onChange;
    }

    scope.openConfirm = (pageStatus) => {
      if (pageStatus === 'edit') {
        const dialogData = {
          title: '警告',
          htmlContent: $sce.trustAsHtml(
            '<h4 style="margin: 20px;">更改開放時段將會取消目前時段外的預約資料，是否繼續?</h4>'),
          isDangers: true
        };
        confirmDialog.openConfirm(dialogData).then(() => {
          openTimepartDialog();
        });
      } else {
        openTimepartDialog();
      }
      async function openTimepartDialog () {
        params.timepart = _.clone(scope.timepart);
        await confirmDialog.openConfirm(params, 'custom-width-1024');
        scope.timepart = params.timepart;
      }
    };
  }
}];
