const _ = require('lodash');

export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <md-button class="md-raised md-primary"
      ng-bind="'轉介費增減'"
      ng-click="$ctrl.openReferralDialog($ctrl.reservation)"
    ></md-button>
  `,
  controller: ['$async', 'ywEvent', 'currentUser', 'Reservation', 'UnorderedReservation', 'confirmDialog',
    function reservationReferralFeeUpdateButton ($async, ywEvent, currentUser, Reservation, UnorderedReservation, confirmDialog) {
      this.$onInit = () => {
      };

      const referralFeeUpdate = {};
      const initReferralFeeUpdateValue = {
        variedPrice: null,
        note: ''
      };

      const variedPriceSumCalculate = (referralFeeDiff) => {
        const variedPriceSum = referralFeeDiff.map((item) => {
          return item.variedPrice;
        }).reduce((total, fee) => total + fee, 0);
        return variedPriceSum;
      };

      this.openReferralDialog = $async(async (reservation) => {
        let referralFeeDetail = {};
        const reservationType = _.get(reservation, 'reservationNo') ? 'reservation' : 'unOrderedReservation';
        if (reservationType === 'unOrderedReservation') {
          referralFeeDetail = await UnorderedReservation.one(reservation._id).customGET('totalReferralFeeProperty');
        } else {
          referralFeeDetail = await Reservation.one(reservation._id).customGET('totalReferralFeeProperty');
        }

        if (referralFeeDetail.error) return ywEvent.emit('ALERT', '轉介費取得失敗');
        // referralFeeSum: 取得轉介費 list 顯示, 並 reduce 計算調整轉介費的加總
        referralFeeDetail.referralFeeSum = 0;
        if (!_.isEmpty(referralFeeDetail.referralFeeDiff)) referralFeeDetail.referralFeeSum = variedPriceSumCalculate(referralFeeDetail.referralFeeDiff);

        // 修改欄位資料
        referralFeeUpdate.updateValue = _.cloneDeep(initReferralFeeUpdateValue);

        const dialogData = {
          title: '轉介費增減',
          templateUrl: '/view/dialog/referral-fee-update-setting.html',
          isShowReferralTip: true,
          isAdmin: currentUser.hasRole('serviceAdmin'),
          referralFeeDetail,
          referralFeeUpdateValue: referralFeeUpdate,
          hideCancel: true,
          disableSave: true,
          onKeyPress: (e) => {
            if (e.keyCode === 13) e.preventDefault();
          },
          onInputChange: (referralFeeUpdateValue) => {
            dialogData.disableSave = !referralFeeUpdateValue.updateValue.variedPrice || !referralFeeUpdateValue.updateValue.note;
          },
          onReferralFeeUpdate: async () => {
            const { variedPrice, note } = referralFeeUpdate.updateValue;
            // 總轉介費, 由後端 API 提供
            const totalReferralFee = referralFeeDetail?.totalReferralFee?.total;
            if (variedPrice + totalReferralFee < 0) return ywEvent.emit('ALERT', '轉介費扣除不得大於訂單轉介費金額');
            let res;
            if (reservationType === 'unOrderedReservation') {
              res = await UnorderedReservation.one(reservation._id).customPUT({ variedPrice, note }, 'referralFee');
            } else {
              res = await Reservation.one(reservation._id).customPUT({ variedPrice, note }, 'referralFee');
            }

            if (res) {
              if (res.error) {
                await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
              } else {
                referralFeeUpdate.updateValue = _.cloneDeep(initReferralFeeUpdateValue);
                referralFeeDetail.referralFeeSum = variedPriceSumCalculate(res.referralFeeDiff);
                referralFeeDetail.totalReferralFee = res.totalReferralFee;
                referralFeeDetail.referralFeeDiff = res.referralFeeDiff;
                ywEvent.emit('SUCCESS', '修改完成');
                dialogData.disableSave = true;
              }
            }
          }
        };
        await confirmDialog.openConfirm(dialogData, 'custom-width-680');
      });
    }]
};
