export default ['$scope', '$async', 'Restangular', 'ServiceClassification', 'AdvancedFilterItem', 'currentVender', function controller ($scope, $async, Restangular, ServiceClassification, AdvancedFilterItem, currentVender) {
  $scope.currentVender = currentVender;

  const init = $async(async () => {
    $scope.AdvancedFilterItem = AdvancedFilterItem;

    $scope.venderAdvancedFilters = await Restangular.service('venderAdvancedFilters').getList({ limit: 500, vender: currentVender._id });
    const serviceClassifications = await Promise.all($scope.venderAdvancedFilters.map(filter => ServiceClassification.one(filter.serviceClassification).customGET()));
    $scope.venderAdvancedFilters.forEach((filter, index) => {
      filter._serviceClassificationName = _.get(serviceClassifications, `[${index}].name`);
    });
  });
  init();

  $scope.onConfirm = $async(async () => {
    const promises = $scope.venderAdvancedFilters.map(
      filter => Restangular
        .service('venderAdvancedFilters')
        .one(filter._id)
        .customPUT({ advancedFilterItems: filter.advancedFilterItems })
    );
    const results = await Promise.all(promises);
    if (results.every(result => !result.error)) {
      $scope.venderAdvancedFilters = results.map((result, index) => Object.assign(
        {},
        result,
        { _serviceClassificationName: $scope.venderAdvancedFilters[index]._serviceClassificationName }
      ));
      $scope.success('儲存完成');
    } else {
      results
        .filter(result => result.error)
        .forEach(result => $scope.alert(result.error));
    }
  });
}];
