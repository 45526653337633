let current = null;

export default ['currentUser', 'localStorageService', function factory (currentUser, localStorageService) {
  current = currentUser;
  const ret = { currentPage: 1, perPage: 50, totalItems: 0, _items: [] };
  ret.toOffsetPage = toOffsetPage;
  ret.clear = clear;
  ret.isCached = isCached;

  ret.options = [
    { label: '50筆', value: 50 },
    { label: '100筆', value: 100 },
    { label: '200筆', value: 200 }
  ];

  ret.setPagerLimit = (limit = 50) => localStorageService.set('pagerLimit', limit);
  ret.getPagerLimit = () => localStorageService.get('pagerLimit');
  if (ret.getPagerLimit()) ret.perPage = ret.getPagerLimit();

  Object.defineProperty(ret, 'totalPages', {
    get: function () { return parseInt(ret.totalItems / ret.perPage) + (ret.totalItems % ret.perPage === 0 ? 0 : 1); }
  });

  Object.defineProperty(ret, 'allPages', {
    get: function () { return _.tail(_.range(ret.totalPages + 1)); }
  });

  Object.defineProperty(ret, 'skip', {
    get: function () { return (ret.currentPage - 1) * ret.perPage; }
  });

  Object.defineProperty(ret, 'isSafe', {
    get: function () { return (ret.currentPage <= ret.totalPages) && (ret.currentPage > 0); }
  });

  Object.defineProperty(ret, 'currentItems', {
    get: function () { return ret._items.slice(ret.skip, ret.skip + ret.perPage); },
    set: function (items) {
      if (ret._items.length === 0) ret._items = items;
      else { // insert items
        ret._items[ret.skip] = {};
        Array.prototype.splice.apply(ret._items, [ret.skip, ret.perPage].concat(items));
      }
    }
  });

  return ret;
}];

/**
 * calculate savely and set currentPage with offset
 */
function toOffsetPage (offset, page, perPage) {
  const self = this;
  if (self.currentPage === 1 && offset < 0) return;
  if (self.currentPage === self.totalPages && offset > 0) return;
  const count = (self.currentPage + offset) * perPage;
  ga('send', 'event', current.getCommName(), 'list_count', page + '_list_' + count);
  self.currentPage = parseInt(self.currentPage) + parseInt(offset);
}

function clear () {
  this._items = [];
  this.currentPage = 1;
  this.totalItems = 0;
}

function isCached () {
  const self = this;

  if (self.currentItems.length === 0) return false;
  if (self.currentItems.length < self.perPage && self.currentPage !== self.totalPages) return false;
  if (self.currentItems.length !== self.totalItems && self.totalPages === 1) return false;
  for (let i = 0; i < self.currentItems.length; i++) { if (!self.currentItems[i]) return false; }
  return true;
}
