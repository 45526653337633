import _ from 'lodash';
import moment from 'moment';

async function mergePdfs (pdfsArrayBuffer) {
  const mergedPdf = await PDFLib.PDFDocument.create();

  const actions = pdfsArrayBuffer.map(async (pdfBuffer) => {
    const pdf = await PDFLib.PDFDocument.load(pdfBuffer);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page) => mergedPdf.addPage(page));
  });
  await Promise.all(actions);

  return mergedPdf.save();
}

const loadJsFile = (url) => {
  return new Promise((resolve, reject) => {
    if (typeof NotoSansCJKtc !== 'undefined') return resolve(true);
    const script = document.createElement('script');
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      reject(new Error('js file is not loaded'));
    };
    script.src = url;
    document.head.appendChild(script);
  });
};

// 載入字體檔案
const loadFontFile = () => loadJsFile('/lib/noto_sans_encode.js');

// 載入套件
const loadLibs = () => [
  loadJsFile(
    'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.4/pdfmake.min.js'
  ),
  loadJsFile('https://cdn.jsdelivr.net/npm/pdf-lib/dist/pdf-lib.min.js')
];

const initLibs = async () => {
  await Promise.all([loadFontFile(), ...loadLibs()]);
  pdfMake.vfs = {
    'NotoSansCJKtc-Regular.ttf': NotoSansCJKtc
  };
  return true;
};

// 改編：https://github.com/bpampuch/pdfmake/issues/74#issuecomment-879839985
/* applyVerticalAlignment start */
function mapTableBodies (innerTableCell, maxWidth, usedWidth) {
  const findInlineHeight = this.findInlineHeight(
    innerTableCell,
    maxWidth,
    usedWidth
  );

  usedWidth = findInlineHeight.width;
  return findInlineHeight.height;
}

function findInlineHeight (cell, maxWidth, usedWidth = 0) {
  const calcLines = (inlines) => {
    if (!inlines) {
      return {
        height: 0,
        width: 0
      };
    }
    let currentMaxHeight = 0;
    let lastHadLineEnd = false;
    for (const currentNode of inlines) {
      usedWidth += currentNode.width;
      if (usedWidth > maxWidth || lastHadLineEnd) {
        currentMaxHeight += currentNode.height;
        usedWidth = currentNode.width;
      } else {
        currentMaxHeight = Math.max(currentNode.height, currentMaxHeight);
      }
      lastHadLineEnd = !!currentNode.lineEnd;
    }
    return {
      height: currentMaxHeight,
      width: usedWidth
    };
  };

  if (cell._offsets) {
    usedWidth += cell._offsets.total;
  }
  if (cell._inlines && cell._inlines.length) {
    return calcLines(cell._inlines);
  } else if (cell.stack && cell.stack[0]) {
    return cell.stack
      .map((item) => {
        return findInlineHeight(item, maxWidth);
      })
      .reduce((prev, next) => {
        return {
          height: prev.height + next.height,
          width: Math.max(prev.width + next.width)
        };
      });
  } else if (cell.table) {
    let currentMaxHeight = 0;
    for (const currentTableBodies of cell.table.body) {
      const innerTableHeights = currentTableBodies.map((innerTableCell) =>
        mapTableBodies(innerTableCell, maxWidth, usedWidth)
      );
      currentMaxHeight = Math.max(...innerTableHeights, currentMaxHeight);
    }
    return {
      height: currentMaxHeight,
      width: usedWidth
    };
  } else if (cell.canvas) {
    return {
      height: cell._maxHeight,
      width: cell._maxWidth
    };
  } else if (cell._height) {
    usedWidth += cell._width;
    return {
      height: cell._height,
      width: usedWidth
    };
  }

  return {
    height: null,
    width: usedWidth
  };
}

function applyVerticalAlignment (node, rowIndex, align, manualHeight) {
  // New default argument
  const allCellHeights = node.table.body[rowIndex].map(
    (innerNode, columnIndex) => {
      const mFindInlineHeight = findInlineHeight(
        innerNode,
        node.table.widths[columnIndex]._calcWidth
      );
      return mFindInlineHeight.height + _.get(innerNode, '_margin.1', 0) + _.get(innerNode, '_margin.3', 0);
    }
  );

  const maxRowHeight = manualHeight
    ? Array.isArray(manualHeight)
      ? manualHeight[rowIndex]
      : manualHeight
    : Math.max(...allCellHeights); // handle manual height
  node.table.body[rowIndex].forEach((cell, ci) => {
    if (allCellHeights[ci] && maxRowHeight > allCellHeights[ci]) {
      let topMargin;

      let cellAlign = align;
      if (Array.isArray(align)) {
        cellAlign = align[ci];
      }

      if (cellAlign === 'bottom') {
        topMargin = maxRowHeight - allCellHeights[ci];
      } else if (cellAlign === 'center') {
        topMargin = (maxRowHeight - allCellHeights[ci]) / 2;
      }

      if (topMargin) {
        if (cell._margin) {
          cell._margin[1] = topMargin;
        } else {
          cell._margin = [0, topMargin, 0, 0];
        }
      }
    }
  });

  return 0;
}
/* applyVerticalAlignment end */

const getFormContent = async (reservation) => {
  const { notes: notesSetting } = _.get(reservation, 'serviceClassification.acceptanceSetting', {});
  const serviceIds = reservation.services.map((service) => service.serviceId._id);
  let notes;
  if (!_.isEmpty(notesSetting) && !_.isEmpty(serviceIds)) {
    notes = notesSetting
      .filter(noteSetting => _.isEmpty(noteSetting.services) || !_.isEmpty(_.intersection(noteSetting.services, serviceIds)))
      .map(noteSetting => noteSetting.describe);
  }

  return [
    {
      layout: {
        hLineWidth: function (i, node) {
          if (i === node.table.body.length) return 0;
          return 1;
        }
      },
      table: {
        widths: [72, '*', 72, 100],
        body: [
          [
            {
              text: '服務內容',
              style: ['fill-grey', 'size-14', 'm-4']
            },
            {
              text: reservation.services
                .map((service) => `${service.serviceId.name}x${service.count}`)
                .join(', '),
              style: ['size-14', 'm-4']
            },
            {
              text: '訂單編號',
              style: ['fill-grey', 'size-14', 'm-4']
            },
            {
              text: reservation.reservationNo,
              style: ['size-14', 'm-4']
            }
          ],
          [
            {
              text: '服務廠商',
              style: ['fill-grey', 'size-14', 'm-4']
            },
            {
              text: _.get(reservation, 'vender.name', ''),
              style: ['size-14', 'm-4']
            },
            {
              text: '總金額',
              style: ['fill-grey', 'size-14', 'm-4']
            },
            {
              text: (_.get(reservation, 'vender.acceptanceSetting.isHiddenTotal') ? '' : `NT$${reservation.total}`),
              style: ['size-14', 'm-4']
            }
          ]
        ]
      }
    },
    {
      layout: {
        hLineWidth: function (i, node) {
          if (i === node.table.body.length) return 0;
          return 1;
        }
      },
      table: {
        widths: [72, '*', 72, '*'],
        body: [
          [
            {
              text: '顧客姓名',
              style: ['fill-grey', 'size-14', 'm-4']
            },
            {
              text: reservation.name,
              style: ['size-14', 'm-4']
            },
            {
              text: '聯絡電話',
              style: ['fill-grey', 'size-14', 'm-4']
            },
            {
              text: (_.get(reservation, 'vender.acceptanceSetting.isHiddenPhone') ? '' : reservation.phone),
              style: ['size-14', 'm-4']
            }
          ]
        ]
      }
    },
    {
      layout: {
        hLineWidth: function (i, node) {
          if (i === node.table.body.length) return 0;
          return 1;
        }
      },
      table: {
        widths: [72, '*'],
        body: [
          [
            {
              text: '服務地址',
              style: ['fill-grey', 'size-14', 'm-4']
            },
            {
              text: _.get(reservation, 'address.fullAddress', ''),
              style: ['size-14', 'm-4']
            }
          ]
        ]
      }
    },
    !notes
      ? {}
      : {
          layout: {
            hLineWidth: function (i, node) {
              if (i === node.table.body.length) return 0;
              return 1;
            }
          },
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: '說明',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                }
              ],
              [
                {
                  ol: notes,
                  style: ['size-11', 'm-6', 'grey-text']
                }
              ]
            ]
          }
        }
  ];
};

const getCheckboxContent = () => ({
  alignment: 'center',
  canvas: [
    {
      type: 'rect',
      x: 0,
      y: 0,
      w: 16,
      h: 16,
      r: 1,
      lineWidth: 2
    }
  ]
});

const getMechanicalCleaningContent = (reservation) => {
  const { contents: contentsSetting } = _.get(reservation, 'serviceClassification.acceptanceSetting', {});
  const serviceIds = reservation.services.map((service) => service.serviceId._id);
  let contents = [];
  if (!_.isEmpty(contentsSetting) && !_.isEmpty(serviceIds)) {
    contents = contentsSetting
      .filter(contentSetting => _.isEmpty(contentSetting.services) || !_.isEmpty(_.intersection(contentSetting.services, serviceIds)))
      .map(contentSetting => [
        {
          text: contentSetting.item,
          style: ['size-14', 'm-4']
        },
        getCheckboxContent(),
        getCheckboxContent(),
        {},
        getCheckboxContent(),
        getCheckboxContent(),
        {}
      ]);
  }

  return [
    _.isEmpty(contents)
      ? {}
      : {
          layout: {
            paddingTop: function (index, node) {
              return applyVerticalAlignment(node, index, 'center'); // Add height array as argument
            },
            hLineWidth: function (i, node) {
              if (i === node.table.body.length) return 0;
              return 1;
            }
          },
          table: {
            headerRows: 3,
            dontBreakRows: true,
            widths: [100, 'auto', 'auto', '*', 'auto', 'auto', '*'],
            body: [
              [
                {
                  text: '服務內容表格',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  colSpan: 7,
                  alignment: 'center'
                },
                {},
                {},
                {},
                {},
                {},
                {}
              ],
              [
                {
                  text: '檢查項目',
                  style: ['fill-grey', 'size-11'],
                  rowSpan: 2,
                  alignment: 'center'
                  // margin: [0, 18.5],
                },
                {
                  text: '清潔前',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  colSpan: 3,
                  alignment: 'center'
                },
                {},
                {},
                {
                  text: '清潔後',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  colSpan: 3,
                  alignment: 'center'
                },
                {},
                {}
              ],
              [
                {},
                {
                  text: '正常',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                },
                {
                  text: '異常',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                },
                {
                  text: '備註/說明',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                },
                {
                  text: '正常',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                },
                {
                  text: '異常',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                },
                {
                  text: '備註/說明',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                }
              ],
              ...contents
            ]
          }
        }
  ];
};

const createCustomizationContent = (customizationGroup) => {
  const rows = customizationGroup.map(customization => Array(customization.count).fill(customization)).flat();
  return {
    layout: {
      paddingTop: function (index, node) {
        return applyVerticalAlignment(node, index, 'center'); // Add height array as argument
      },
      hLineWidth: function (i, node) {
        if (i === node.table.body.length) return 0;
        return 1;
      }
    },
    table: {
      headerRows: 1,
      dontBreakRows: true,
      widths: [38, '*'],
      body: [
        [
          {
            text: '勾選',
            style: ['fill-grey', 'size-11', 'm-1'],
            alignment: 'center'
          },
          {
            text: customizationGroup[0].title,
            style: ['fill-grey', 'size-11', 'm-1'],
            alignment: 'center'
          }
        ],
        ...rows.map((customization) => [
          getCheckboxContent(),
          {
            text: customization.name,
            style: ['size-14', 'm-4']
          }
        ])
      ]
    }
  };
};

const getHomeCleaningContent = (reservation) => {
  const shouldPrintCustomizationItemIds = _.chain(reservation.services)
    .map((service) => service.serviceId.customizations)
    .flatten()
    .filter((customization) => customization.isAcceptance)
    .reduce((pre, curr) => {
      pre.push(...(curr.items.map(item => item._id)));
      return pre;
    }, [])
    .value();
  const customizationGroups = _.groupBy(
    reservation.customizations
      .filter(customization => shouldPrintCustomizationItemIds.includes(customization.itemId)),
    (c) => c.title
  );

  const { contents: contentsSetting } = _.get(reservation, 'serviceClassification.acceptanceSetting', {});
  const serviceIds = reservation.services.map((service) => service.serviceId._id);
  let contents = [];
  if (!_.isEmpty(contentsSetting) && !_.isEmpty(serviceIds)) {
    contents = contentsSetting
      .filter(contentSetting => _.isEmpty(contentSetting.services) || !_.isEmpty(_.intersection(contentSetting.services, serviceIds)))
      .map(contentSetting => [
        getCheckboxContent(),
        {
          text: contentSetting.area,
          style: ['size-14', 'm-4']
        },
        {
          text: contentSetting.item,
          style: ['size-14', 'm-4']
        }
      ]);
  }

  const customizationContent = Object.keys(customizationGroups).map((key) => createCustomizationContent(customizationGroups[key]));

  return [
    _.isEmpty(contents) && _.isEmpty(customizationContent)
      ? {}
      : {
          layout: {
            hLineWidth: function (i, node) {
              if (i === node.table.body.length) return 0;
              return 1;
            }
          },
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: '服務內容表格',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                }
              ]
            ]
          }
        },
    ...customizationContent,
    _.isEmpty(contents)
      ? {}
      : {
          layout: {
            paddingTop: function (index, node) {
              return applyVerticalAlignment(node, index, 'center'); // Add height array as argument
            },
            hLineWidth: function (i, node) {
              if (i === node.table.body.length) return 0;
              return 1;
            }
          },
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: [38, 130, '*'],
            body: [
              [
                {
                  text: '勾選',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                },
                {
                  text: '區域',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                },
                {
                  text: '項目',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                }
              ],
              ...contents
            ]
          }
        }
  ];
};

const getRemarkContent = (reservation) => {
  const acceptanceNote = _.get(reservation, 'acceptanceNote');
  return [
    !acceptanceNote
      ? {}
      : {
          layout: {
            hLineWidth: function (i, node) {
              if (i === node.table.body.length) return 0;
              return 1;
            }
          },
          table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: ['*'],
            body: [
              [
                {
                  text: '備註',
                  style: ['fill-grey', 'size-11', 'm-1'],
                  alignment: 'center'
                }
              ],
              [
                {
                  text: acceptanceNote,
                  style: ['size-11', 'm-6', 'grey-text']
                }
              ]
            ]
          }
        }
  ];
};

const getSignerContent = (reservation) => {
  const warning = _.get(reservation, 'serviceClassification.acceptanceSetting.warning');
  return [
    {
      layout: {
        paddingTop: function (index, node) {
          return applyVerticalAlignment(node, index, ['center']); // Add height array as argument
        }
      },
      margin: [0, 0, 0, 15],
      table: {
        dontBreakRows: true,
        widths: [110, 145, '*'],
        body: [
          [
            {
              text: '驗收人簽名',
              style: ['fill-grey', 'size-18'],
              alignment: 'center'
            },
            { text: '' },
            {
              text: warning,
              style: ['size-11', 'm-6', 'grey-text']
            }
          ]
        ]
      }
    },
    {
      layout: 'noBorders',
      table: {
        dontBreakRows: true,
        widths: ['*', '*'],
        body: [
          [
            {
              text: '服務人員：＿＿＿＿＿＿',
              fontSize: '16'
            },
            {
              text: '離開時間：＿＿＿＿＿＿',
              fontSize: '16',
              alignment: 'right'
            }
          ]
        ]
      }
    }
  ];
};

const createPdfByReservation = async (reservation, APP_URL) => {
  const fonts = {
    NotoSansCJKtc: {
      normal: 'NotoSansCJKtc-Regular.ttf',
      bold: 'NotoSansCJKtc-Regular.ttf',
      italics: 'NotoSansCJKtc-Regular.ttf',
      bolditalics: 'NotoSansCJKtc-Regular.ttf'
    }
  };

  const { layout } = _.get(reservation, 'serviceClassification.acceptanceSetting', {});
  const formContent = await getFormContent(reservation);
  const checkItemsContent = layout === 'homeCleaning' ? getHomeCleaningContent(reservation) : getMechanicalCleaningContent(reservation);
  const RemarkContent = getRemarkContent(reservation);
  const signerContent = getSignerContent(reservation);

  const getSvgText = (url) => fetch(url).then((res) => res.text());
  const svgText = await getSvgText(`${APP_URL}img/logo_backend.svg`);

  const bookDate = _.get(reservation, 'bookedSession.from') && moment(_.get(reservation, 'bookedSession.from'));

  const docDefinition = {
    header: function (currentPage, pageCount, pageSize) {
      return [
        {
          table: {
            widths: ['*', 'auto', 'auto'],
            body: [
              [
                {
                  svg: svgText,
                  width: 130,
                  height: 20
                },
                currentPage === 1 && !_.isEmpty(reservation.objectNo)
                  ? {
                      text: `物編：${reservation.objectNo}`,
                      fontSize: '15',
                      style: ['fill-grey'],
                      margin: [5, 0]
                    }
                  : {},
                currentPage === 1
                  ? {
                      text: `服務日期：${bookDate ? `${bookDate.format('YYYY 年 MM 月 DD 日')}` : '＿＿年＿＿月＿＿日'}`,
                      fontSize: '15',
                      margin: [10, 0, 0, 0]
                    }
                  : {}
              ]
            ]
          },
          layout: 'noBorders',
          margin: [20, 35]
        }
      ];
    },
    footer: function (currentPage, pageCount) {
      if (pageCount === 1) return '';
      return {
        columns: [
          {
            text: currentPage.toString() + '/' + pageCount,
            alignment: 'center'
          }
        ]
      };
    },
    defaultStyle: {
      font: 'NotoSansCJKtc'
    },
    content: [
      ...formContent,
      ...checkItemsContent,
      ...RemarkContent,
      ...signerContent
    ],
    styles: {
      'size-10': { fontSize: 10 },
      'size-11': { fontSize: 11 },
      'size-12': { fontSize: 12 },
      'size-13': { fontSize: 13 },
      'size-14': { fontSize: 14 },
      'size-18': { fontSize: 18 },
      'fill-grey': { fillColor: '#F0F0F0' },
      'grey-text': { color: '#4A4A4A' },
      'm-1': { margin: 1 },
      'm-4': { margin: 4 },
      'm-6': { margin: 6 }
    },
    pageMargins: [20, 70, 20, 30]
  };

  return pdfMake.createPdf(docDefinition, false, fonts);
};

const createAcceptancesPdf = async ({ reservationList, APP_URL }) => {
  await initLibs();

  const pdfs = await Promise.all(
    reservationList.map((reservation) =>
      createPdfByReservation(reservation, APP_URL)
    )
  );

  const buffers = await Promise.all(
    pdfs.map(
      (pdf) =>
        new Promise((resolve) => {
          pdf.getBuffer((buffer) => resolve(buffer));
        })
    )
  );

  const mergedPdf = await mergePdfs(buffers);

  const pdfUrl = URL.createObjectURL(
    new Blob([mergedPdf], { type: 'application/pdf' })
  );
  return pdfUrl;
};

export {
  loadFontFile,
  loadLibs,
  createAcceptancesPdf
};
