import moment from 'moment';

export default ['$scope', '$async', 'ywUtil', 'currentUser', 'NotificationGroup', 'Vender', 'Restangular', 'confirmDialog',
  function controller ($scope, $async, ywUtil, currentUser, NotificationGroup, Vender, Restangular, confirmDialog) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.searchBarOptions = [
      { btnName: '用戶手機', placeholder: '查詢用戶手機', params: 'phone' },
      { btnName: '用戶名稱', placeholder: '查詢用戶名稱', params: 'name' },
      { btnName: '用戶ID', placeholder: '查詢用戶ID', params: 'userId' },
      { btnName: '推播文案', placeholder: '查詢推播文案', params: 'pushContent' },
      { btnName: 'email主旨', placeholder: '查詢email主旨', params: 'emailTitle' },
      { btnName: 'email內文', placeholder: '查詢email內文', params: 'emailContent' },
      { btnName: '簡訊文案', placeholder: '查詢簡訊文案', params: 'smsContent' }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    const venderOptions = [];
    if (currentUser.hasRole('serviceAdmin')) {
      Vender.getList({ limit: 999 }).then(res => {
        if (!res.error) {
          const venders = res.map(vender => ({ label: vender.name, value: vender._id }));
          venderOptions.push(...venders);
        }
      });
    }

    const defaultFilter = {
      timeAfter: moment().subtract(30, 'day').startOf('day').toDate()
    };

    $scope.filterItems = [
      {
        name: 'timeAfter',
        label: '發送時間起',
        changed: (date) => {
          $scope.mainAction.queryParams.timeAfter = date && ywUtil.toDateStart(date).toISOString();
          $scope.mainAction.setClearFilterTrue();
        },
        type: 'dateFilter',
        filterDate: defaultFilter.timeAfter
      },
      {
        name: 'timeBefore',
        label: '發送時間迄',
        changed: (date) => {
          $scope.mainAction.queryParams.timeBefore = date && ywUtil.toDateEnd(date).toISOString();
          $scope.mainAction.setClearFilterTrue();
        },
        type: 'dateFilter'
      },
      {
        name: 'venderId',
        label: '發送廠商',
        options: venderOptions,
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'noOwnAttrSelect'
      }
    ];

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'notification'; // for GA

      $scope.mainAction.initResource(NotificationGroup, defaultFilter);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        category: 'message',
        targetType: 'phone',
        couponEventIds: [],
        _userGroups: []
      };
    }

    $scope.notificationSettings = {
      openStopMessageDialog: $async(async (item) => {
        await confirmDialog.openConfirm({
          title: '停止發送訊息',
          hideCancel: false,
          confirmLabel: '停止',
          cancelLabel: '關閉',
          isDangers: true,
          content: '確定停止發送此則通知?'
        });

        if (!moment(item?.time).isAfter(moment())) {
          $scope.alert('該訊息已超過可終止時間');
          return;
        }

        const res = await NotificationGroup.one('abandonment').customPOST({ notificationGroupId: item?._id });
        if (res.error) {
          $scope.alert(res.error);
        } else {
          // update the item in table
          const index = $scope.items.findIndex(originItem => originItem._id === item?._id);
          $scope.items[index] = res;
          return $scope.success('已停止發送');
        }
      })
    };

    $scope.openNotificationContentDialog = $async(async (notificationGroup) => {
      const navInfo = ywUtil.landingOptions.findNames(
        _.get(notificationGroup, 'navData.page'),
        _.get(notificationGroup, 'navData.action')
      );
      await confirmDialog.openConfirm({
        title: '通知內容',
        templateUrl: '/view/dialog/notification-content.html',
        hideCancel: true,
        notificationInfo: {
          pushContent: _.get(notificationGroup, 'notificationInfo.pushContent', '').split('\n'),
          emailTitle: _.get(notificationGroup, 'notificationInfo.emailTitle', ''),
          emailContent: _.get(notificationGroup, 'notificationInfo.emailContent', '').split('\n'),
          smsContent: _.get(notificationGroup, 'notificationInfo.smsContent', '').split('\n')
        },
        navInfo
      });
    });

    $scope.openNotificationDetailDialog = $async(async (notificationGroup) => {
      let skip = 0;
      const dialogData = {
        title: '發送結果',
        templateUrl: '/view/dialog/notification-detail.html',
        hideCancel: true,
        details: [],
        notFoundInfo: [],
        countInfo: notificationGroup.count,
        isLoading: false,
        hasMore: true,
        loadMore: $async(async () => {
          if (dialogData.isLoading) {
            return;
          }
          dialogData.isLoading = true;
          const res = await NotificationGroup.one(notificationGroup._id).customGET('details', { skip });
          if (!res.error) {
            dialogData.details.push(...res.notificationSendingRet);
            dialogData.notFoundInfo = res.notFoundInfo;
            skip += res.length;

            if (_.get(res, 'notificationSendingRet.length', 0) < 50) {
              dialogData.hasMore = false;
            }
          }
          dialogData.isLoading = false;
        }),
        openUserNoutFoundDialog: (infos) => {
          confirmDialog.openConfirm({
            title: '查無用戶',
            templateUrl: '/view/dialog/notification-user-not-found.html',
            infos
          });
        }
      };

      // load data
      dialogData.loadMore();
      Restangular.service('notifications').one('').customGET(
        'count',
        { groupId: notificationGroup._id, isRead: true }
      ).then(res => {
        if (!res.error) {
          dialogData.countInfo.isRead = res;
        }
      });

      await confirmDialog.openConfirm(dialogData, 'custom-width-1024');
    });
  }];
