export default ['$scope', '$async', 'ywUtil', 'currentUser', 'CouponEvent',
  function controller ($scope, $async, ywUtil, currentUser, CouponEvent) {
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.searchBarOptions = [
      {
        btnName: '活動主題',
        placeholder: '查詢活動主題',
        params: 'subjectName'
      },
      {
        btnName: '優惠名稱',
        placeholder: '查詢優惠名稱',
        params: 'title'
      }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    $scope.filterItems = [
      { name: 'isCrossVender', label: '篩選異業合作', options: { true: '異業合作', false: '非異業合作' }, changed: $scope.mainAction.setClearFilterTrue, type: 'noOwnAttrSelect' },
      { name: 'isForSinyiUsed', label: '篩選信義使用活動', options: { true: '信義使用', false: '非信義使用' }, changed: $scope.mainAction.setClearFilterTrue, type: 'noOwnAttrSelect' }
    ];

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'coupon-event'; // for GA

      $scope.mainAction.initResource(CouponEvent);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        _couponAvailableMax: 1,
        couponAvailableMaxUserType: 'totalUsed',
        couponDiscountValueType: 'number',
        effectiveDateIncludeNormalDay: true,
        effectiveDateIncludeHoliday: true,
        isFirstOrder: false,
        _notificationTypes: [],
        _notificationPushContent: 'Hi~$name$，您的$couponTitle$優惠券將於15天後到期囉，請盡早預約以免時間將近容易滿班哦！',
        _notificationEmailTitle: '[信義居家]優惠券即將到期通知',
        _notificationEmailContent: '親愛的客戶 $name$ 您好：\n\n您的優惠券$couponTitle$，\n可使用將於15日後到期，請盡早預約以免時間將近容易滿班哦！\n 請注意：優惠券限於活動期間內日期進行折抵，超出優惠券活動日期將無法使用。\n 若有其他疑問可以再利用信義居家LINE官方帳號與客服聯繫。\n 立即下載APP可收到訂單最新資訊：\nhttps://link.livinglife.com.tw/dt7S48,\n\n此封信為系統自動發送信件, 請勿直接回覆, 如有任何疑問,\n 歡迎透過信義居家LINE官方帳號洽詢。 (此請放Line@官方帳號會開啟的連結)',
        _notificationSmsContent: 'Hi~$name$，您的$couponTitle$優惠券將於15天後到期囉，請盡早預約以免時間將近容易滿班哦！',
        displayDiscountValueInfo: { setting: 'default' }
      };
    }
  }];
