export default ['$timeout', '$async', 'API_URL', 'Upload', 'uuid', 'ywEvent', function ywDropzoneDirective ($timeout, $async, API_URL, Upload, uuid, ywEvent) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      previews: '=files'
    },
    template: `
      <div
        class="yw-dropzone ptr"
        ng-class="{ highlight: highlightDropzone }"
        style="margin: 0 10px 10px 10px;"
        ng-click="onUploadByClick()"
      >
        拖曳檔案至此或點擊進行上傳
      </div>
      <div style="text-align: left; padding: 10px; max-height: 350px; overflow-y: auto;">
        <span ng-repeat="preview in previews track by $index">
          <a ng-if="!preview._notUploadedYet" ng-href="{{preview.url}}" target="_blank" class="file-link">
            <img ng-src="{{ preview.thumbImage.url || preview.url }}"/>
            <span class="file-remove-cross" ng-click="onRemoveClick($event, preview, $index)">╳</span>
          </a>
          <img ng-if="preview._notUploadedYet" src="/img/loading.gif" class="file-loading"></img>
        </span>
      </dvi>
    `,
    link: function (scope, elements, attrs) {
      scope.onUploadByClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = 'multiple';
        input.click();
        input.onchange = $async(async () => {
          const files = _.get(input, 'files', []);
          const previews = Array.prototype.map.call(files, file => {
            file._notUploadedYet = true;
            file._id = uuid.gen();
            return file;
          });
          scope.previews = (scope.previews || []).concat(previews);
        });
      };

      scope.onRemoveClick = (e, file, index) => {
        e.preventDefault();
        e.stopPropagation();
        scope.previews.splice(index, 1);
      };

      const dragEnterHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        scope.highlightDropzone = true;
        $timeout(() => scope.$apply());
      };
      const dragLeaveHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        scope.highlightDropzone = false;
        $timeout(() => scope.$apply());
      };
      const dragOverHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        scope.highlightDropzone = true;
        $timeout(() => scope.$apply());
      };
      const dropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        scope.highlightDropzone = false;
        $timeout(() => scope.$apply());

        const files = _.get(e, 'dataTransfer.files');
        const previews = Array.prototype.map.call(files, file => {
          file._notUploadedYet = true;
          file._id = uuid.gen();
          return file;
        });
        scope.previews = (scope.previews || []).concat(previews);
        $timeout(() => scope.$apply());
      };
      elements[0].addEventListener('dragenter', dragEnterHandler, false);
      elements[0].addEventListener('dragleave', dragLeaveHandler, false);
      elements[0].addEventListener('dragover', dragOverHandler, false);
      elements[0].addEventListener('drop', dropHandler, false);

      scope.$watch('previews', $async(async (newValue, oldValue) => {
        if (newValue === oldValue) return;
        const files = newValue;
        // is uploading file
        if (files.some(file => file._notUploadedYet && file._isLoading)) return;
        const index = files.findIndex(file => file._notUploadedYet && !file._isLoading);
        if (index < 0) return; // no file needs to upload
        const file = files[index];
        file._isLoading = true;
        const res = await Upload.upload({
          url: API_URL + 'files',
          data: { file: file }
        });
        if (res.data.error) {
          scope.previews = scope.previews.filter(preview => preview._id !== file._id);
          ywEvent.emit('ALERT', res.data.msg);
        } else {
          scope.previews = files.map((preview, i) => i === index ? res.data.result : preview);
        }
        $timeout(() => scope.$apply());
      }));
    }
  };
}];
