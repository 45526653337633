const getRole = (role) => {
  const roleMap = {
    staff: '服務員',
    valuator: '估價員'
  };
  return roleMap[role] || '-';
};

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('venderStaffs', false, _transformer);
  const VenderStaff = Restangular.service('venderStaffs');
  return VenderStaff;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'user.fullname', ''),
      _.get(item, 'subRoles', []).map(getRole).join(','),
      {
        anchorType: 'icon-button',
        name: 'sendNewPassword',
        icon: 'envelope-square',
        tips: '寄送新密碼'
      },
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
