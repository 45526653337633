import React from 'react';

// Table

const tableStyle = {
  marginBottom: 0,
  width: '100%'
};

export const Table = ({ children, style, ...props }) => {
  return (
    <table id='divPrint' className='table' style={{ ...tableStyle, style }} {...props}>
      {children}
    </table>
  );
};

Table.defaultProps = {
  style: {}
};

// HeaderTR

const trStyle = { backgroundColor: '#d9d9d9' };

export const HeaderTR = ({ children }) => (
  <tr style={trStyle}>
    {children}
  </tr>
);

// TH

export const Th = ({ children, alignLeft, nowrap, ...props }) => (
  <th className='cell' style={{ textAlign: alignLeft ? 'left' : '', whiteSpace: nowrap ? 'nowrap' : '' }}>
    {children}
  </th>
);
