import listController from './list';

export default ['$scope', '$controller', function controller ($scope, $controller) {
  // inherit
  $controller('mainActionController', { $scope: $scope });

  $scope.$on('$destroy', function () { $scope.mainAction.pager.clear(); });

  $scope.listController = listController;
  $scope.pageStates = {};
  $scope.currentPage = 'reservation';
  $scope.currentPageState = 'list';

  init();

  function init () {
    /** define in-page states. use templateUrl if general templates unsufficient */
    $scope.pageStates = {
      list: { customLabel: '訂單列表' }
    };
  }
}];
