import { generateTableSettings } from '../../../share';

export default ['$scope', '$async', 'Upload', 'API_URL', 'address', 'ServiceClassification', 'CouponEvent', 'UserGroup', 'confirmDialog', 'ywUtil', 'localStorageService', 'layoutModuleUtils', 'Restangular',
  function controller ($scope, $async, Upload, API_URL, address, ServiceClassification, CouponEvent, UserGroup, confirmDialog, ywUtil, localStorageService, layoutModuleUtils, Restangular) {
    const self = this;
    $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.entranceIconFiles = {};
    self.figureImageFiles = {};
    self.figureImageSndFiles = {};
    self.sampleImageFirstFiles = {};
    self.sampleImageSecondFiles = {};
    self.sampleImageThirdFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      if (!item.name) return $scope.alert('分類名稱不可為空');

      if (!!item._figureImage ^ !!item._figureImageSnd) {
        return $scope.alert('兩張形象圖需同時設定');
      }

      if (item._productMainFeature.some(feature => !_.get(feature, 'icon._id') || !feature.item)) {
        return $scope.alert('主攻特色的icon及特色描述為必填');
      }
      if (item.serviceChargeAmount !== 0) {
        if (!item.serviceChargeAmount) return $scope.alert('轉介費抽成%不得為空');
      }
      if (item.serviceChargeAmount) {
        if (item.serviceChargeAmount < 0) return $scope.alert('轉介費抽成%不得為負數');
        if (item.serviceChargeAmount > 100) return $scope.alert('轉介費抽成%不得大於100');
      }

      if (item._productQAndA.some(faq => !faq.question || !faq.answer)) {
        return $scope.alert('常見問題的問題及解答為必填');
      }

      if (item._productPriceList.some(price => !price.price || !price.service)) {
        return $scope.alert('價目表的服務方案及價格為必填');
      }

      if (!item.identityCode) {
        return $scope.alert('SEO 網址識別名稱為必填');
      } else if (item.identityCode && /^[a-z\d_-]+$/.test(item.identityCode) === false) {
        return $scope.alert('網址識別名稱為僅能包含小寫字母、英數、符號(-, _)的連續字串');
      }

      return true;
    }

    function transform () {
      console.log('transform finished: ', $scope.currentActionItem);
      const item = $scope.currentActionItem;
      item.isAddMoveInAddressInfo = $scope.isAddMoveInAddressInfoCheckbox.checked;
      item.canCrossoverStandardService = $scope.canCrossoverStandardServiceCheckbox.checked;
      item.canCrossoverService = $scope.canCrossoverServiceCheckbox.checked;
      item.quoteItemInclude = $scope.quoteItemIncludeCheckboxes.map(checkbox => ({
        [checkbox.value]: item.reservationListMode === 'stateUnordered' && checkbox.checked
      })).reduce((result, value) => ({ ...result, ...value }), {});

      item.entranceIcon = _.get(item, '_entranceIconUrl._id', null);
      item.sampleImageFirst = _.get(item, '_sampleImageFirstUrl._id', null);
      item.sampleImageSecond = _.get(item, '_sampleImageSecondUrl._id', null);
      item.sampleImageThird = _.get(item, '_sampleImageThirdUrl._id', null);

      item.bookingFigureImage = _.get(item, '_bookingFigureImageUrl._id', null);

      item.isSiteImagesRequired = $scope.isSiteImagesRequiredCheckbox.checked;

      item.reservationListMode === 'stateOrdered' && delete item.isCheckingCustomerBudget;

      item.serviceCharge = {
        type: 'rate',
        amount: item.serviceChargeAmount
      };

      if (!_.isNumber(item.order)) {
        item.order = 0;
      }

      item.acceptanceSetting = {
        layout: item._acceptanceLayout,
        notes: item._acceptanceNotes,
        contents: (() => {
          if (item._acceptanceLayout === 'mechanicalCleaning') {
            return item._acceptanceContents.map(content => ({
              item: content.item,
              services: content.services
            }));
          }
          return item._acceptanceContents;
        })(),
        warning: item._acceptanceWarning
      };

      // 介紹頁設定
      if (item._productLayout === 'publicVersion') {
        item.productIntroduction = {
          layout: item._productLayout,
          title: item._productTitle,
          body: item._productBody,
          figureImage: item._figureImage,
          figureImageSnd: item._figureImageSnd,
          priceList: item._productPriceList,
          mainFeature: item._productMainFeature.map(feature => ({
            icon: _.get(feature, 'icon._id'),
            item: feature.item
          })),
          message: {
            title: item._productMessageTitle || '',
            body: _.get(item, '_productMessageBody', '')
              .split('\n').filter(text => text) || []
          },
          introductions: item._productIntroduction.map(intro => intro._id),
          qAndA: item._productQAndA,
          precautions: _.get(item, '_productPrecautions', '')
            .split('\n').filter(text => text) || [],
          displayGotoReservation: item._displayGotoReservation
        };
      }
    }

    const syncCurrentActionItem = $async(async () => {
      const item = $scope.currentActionItem;

      $scope.isAddMoveInAddressInfoCheckbox = {
        value: false,
        label: '增加遷入地址資訊',
        checked: item.isAddMoveInAddressInfo
      };
      $scope.isAddMoveInAddressInfoInput = {
        label: ' ',
        type: 'checkbox',
        index: 'isAddMoveInAddressInfo',
        checkboxes: [$scope.isAddMoveInAddressInfoCheckbox],
        showIndex: item.mode === 'relocation',
        info: '決定用戶下訂流程，是否要增加遷入地址的資訊輸入'
      };

      $scope.canCrossoverStandardServiceCheckbox = {
        value: false,
        label: '規格化方案可跨方案選取',
        checked: item.canCrossoverStandardService
      };
      $scope.canCrossoverStandardServiceInput = {
        label: ' ',
        type: 'checkbox',
        index: 'canCrossoverStandardService',
        checkboxes: [$scope.canCrossoverStandardServiceCheckbox],
        showIndex: item.reservationListMode === 'stateOrdered'
      };

      $scope.canCrossoverServiceCheckbox = {
        value: false,
        label: '同分類下可跨方案選取',
        checked: item.canCrossoverService
      };
      $scope.canCrossoverServiceInput = {
        label: ' ',
        type: 'checkbox',
        index: 'canCrossoverService',
        checkboxes: [$scope.canCrossoverServiceCheckbox],
        showIndex: item.reservationListMode === 'stateUnordered'
      };

      $scope.quoteItemIncludeCheckboxes = [
        {
          value: 'travelingExpense',
          label: '車馬費(未稅)',
          checked: _.get(item, 'quoteItemInclude.travelingExpense', false)
        },
        {
          value: 'evaluationFee',
          label: '案前作業費(未稅)',
          checked: _.get(item, 'quoteItemInclude.evaluationFee', false)
        },
        {
          value: 'designContractAmount',
          label: '設計合約成交金額(未稅)',
          checked: _.get(item, 'quoteItemInclude.designContractAmount', false)
        },
        {
          value: 'engineeringContractAmount',
          label: '工程合約成交金額(未稅)',
          checked: _.get(item, 'quoteItemInclude.engineeringContractAmount', false)
        }
      ];
      $scope.quoteItemIncludeInput = {
        label: '報價款項名目',
        type: 'checkbox',
        index: 'quoteItemInclude',
        checkboxes: $scope.quoteItemIncludeCheckboxes,
        showIndex: item.reservationListMode === 'stateUnordered'
      };

      $scope.isCheckingCustomerBudget = {
        label: '用戶下訂預算檢查',
        type: 'radio',
        index: 'isCheckingCustomerBudget',
        radios: [
          { value: true, label: '檢查' },
          { value: false, label: '不檢查' }
        ],
        showIndex: item.reservationListMode === 'stateUnordered'
      };

      $scope.moduleTypes = [];
      $scope.moduleTypesItems = [];
      await layoutModuleUtils.getLayoutModuleTypes($scope);

      if ($scope.currentPageState === 'edit') {
        item._entranceIconUrl = item.entranceIcon;
        item.entranceIcon = _.get(item, '_entranceIconUrl._id');

        item._sampleImageFirstUrl = item.sampleImageFirst;
        item.sampleImageFirst = _.get(item, '_sampleImageFirstUrl._id');
        item._sampleImageSecondUrl = item.sampleImageSecond;
        item.sampleImageSecond = _.get(item, '_sampleImageSecondUrl._id');
        item._sampleImageThirdUrl = item.sampleImageThird;
        item.sampleImageThird = _.get(item, '_sampleImageThirdUrl._id');

        item._bookingFigureImageUrl = item.bookingFigureImage;
        item.bookingFigureImage = _.get(item, '_bookingFigureImageUrl._id');

        item.recommendLinks = item.recommendLinks || [];
        item.serviceChargeAmount = _.get(item, 'serviceCharge.amount', 0);

        const fetchedData = await ServiceClassification.one(item._id).get({ isDisplayIntroductionsDetail: true });
        const productIntroduction = fetchedData.productIntroduction;
        item._productLayout = _.get(productIntroduction, 'layout');
        item._productTitle = _.get(productIntroduction, 'title');
        item._productBody = _.get(productIntroduction, 'body');
        item._figureImageUrl = _.get(productIntroduction, 'figureImage');
        item._figureImage = _.get(productIntroduction, 'figureImage._id');
        item._figureImageSndUrl = _.get(productIntroduction, 'figureImageSnd');
        item._figureImageSnd = _.get(productIntroduction, 'figureImageSnd._id');
        item._productPriceList = _.get(productIntroduction, 'priceList', []);
        item._productMainFeature = _.get(productIntroduction, 'mainFeature', []);
        item._productMessageTitle = _.get(productIntroduction, 'message.title', '');
        item._productMessageBody = _.get(productIntroduction, 'message.body', []).join('\n');
        item._productIntroduction = _.get(productIntroduction, 'introductions', []).map(module => {
          return {
            ...module,
            typeName: layoutModuleUtils.getLayoutModuleTypeName(module.type)
          };
        });
        item._productQAndA = _.get(productIntroduction, 'qAndA', []);
        item._productPrecautions = _.get(productIntroduction, 'precautions', []).join('\n');
        item._displayGotoReservation = _.get(productIntroduction, 'displayGotoReservation');
        item.acquireCouponEvents = _.get(item, 'acquireCouponEvents', []);

        const acceptanceSetting = fetchedData.acceptanceSetting;
        item._acceptanceLayout = _.get(acceptanceSetting, 'layout', 'homeCleaning');
        item._acceptanceNotes = _.get(acceptanceSetting, 'notes', []);
        item._acceptanceContents = _.get(acceptanceSetting, 'contents', []);
        item._acceptanceWarning = _.get(acceptanceSetting, 'warning', '');
      } else if ($scope.currentPageState === 'add') {
        item._acceptanceNotes = [];
        item._acceptanceContents = [];
        item._acceptanceLayout = 'homeCleaning';
      }

      $scope.contentManagement = {
        label: '服務介紹',
        type: 'custom-content',
        searchBarOptions: [
          { btnName: '模組名稱', placeholder: '請輸入模組名稱', params: 'name' },
          { btnName: '模組ID', placeholder: '請輸入模組ID', params: 'moduleId' }
        ],
        searchBarOptionsActive: { btnName: '模組名稱', placeholder: '請輸入模組名稱', params: 'name' },
        customSearchText: '',
        changeSearchBarActiveOption,
        moduleType: {
          options: $scope.moduleTypes
        },
        articleModuleArray: item._productIntroduction || [],
        itemMoved: (index) => $scope.contentManagement.articleModuleArray.splice(index, 1),
        itemRemoved: (index) => $scope.contentManagement.articleModuleArray.splice(index, 1),
        addModule,
        clear,
        onEditClick,
        onCopyIdClick
      };

      function changeSearchBarActiveOption (currentOption, newOption) {
        $scope.contentManagement.searchBarOptionsActive = newOption;
      }

      async function addModule () {
        function seletAll (isChecked, arr) {
          arr.forEach(item => {
            item.select = isChecked;
          });
        }
        const data = {};
        try {
          $scope.status.isLoading = true;
          data.layoutModuleList = await Restangular.one('layoutModules').get({
            type: $scope.contentManagement.moduleType.select,
            name: $scope.contentManagement.searchBarOptionsActive.params === 'name' ? $scope.contentManagement.customSearchText : undefined,
            moduleId: $scope.contentManagement.searchBarOptionsActive.params === 'moduleId' ? $scope.contentManagement.customSearchText : undefined
          });
          const articleUsedModule = _.isEmpty($scope.contentManagement.articleModuleArray) ? [] : $scope.contentManagement.articleModuleArray.map(articleModule => { return articleModule._id; });
          data.layoutModuleList = _.isEmpty(articleUsedModule) ? data.layoutModuleList : data.layoutModuleList.filter(layoutModule => !articleUsedModule.some((item) => item === layoutModule?._id));
          data.layoutModuleList = data.layoutModuleList.map(module => { return { ...module, typeName: layoutModuleUtils.getLayoutModuleTypeName(module.type) }; });
        } catch (error) {
          console.log('ALERT', error);
          $scope.status.isLoading = false;
          return;
        } finally {
          $scope.status.isLoading = false;
        }
        if (_.isArray(data.layoutModuleList) && data.layoutModuleList.length === 0) return $scope.alert('找不到模組');
        confirmDialog.openConfirm({
          title: '請選擇需要加入頁面的模組',
          templateUrl: '/view/dialog/layout-module-selector.html',
          data,
          seletAll
        }, 'custom-width-800').then(() => {
          data.layoutModuleList.forEach(item => {
            if (item.select) $scope.contentManagement.articleModuleArray.push(item);
          });
        });
      }

      function onEditClick (index) {
        const { _id, type } = $scope.contentManagement.articleModuleArray[index];
        localStorageService.set('module', JSON.stringify({ id: _id, type }));
        window.open('layout-module', '_blank');
      }

      function clear () {
        $scope.contentManagement.moduleType.select = undefined;
        $scope.contentManagement.customSearchText = '';
      }

      function onCopyIdClick (id) {
        ywUtil.cloneText(id);
        $scope.success('已複製');
      }

      $scope.publicProductIntroInputs = [
        {
          label: '形象圖-桌機版',
          subLabel: '寬1920 X 高480\n限制1MB',
          type: 'single-file',
          index: '_figureImageUrl',
          originIndex: '_figureImage',
          class: 'images-wrapper',
          files: $scope.figureImageFiles,
          success: $scope.singleFileUploaded,
          error: $scope.alert,
          validate: { width: { limit: 1920 }, height: { limit: 480 }, limitSize: 1000000 }
        },
        {
          label: '形象圖-手機版',
          subLabel: '寬1200 X 高800\n限制1MB',
          type: 'single-file',
          index: '_figureImageSndUrl',
          originIndex: '_figureImageSnd',
          class: 'images-wrapper',
          files: $scope.figureImageSndFiles,
          success: $scope.singleFileUploaded,
          error: $scope.alert,
          validate: { width: { limit: 1200 }, height: { limit: 800 }, limitSize: 1000000 }
        },
        { label: '主標', type: 'text', index: '_productTitle' },
        { label: '簡介', type: 'rich-editor', index: '_productBody' },
        { type: 'empty' },
        {
          label: '價目表',
          type: 'product-intro-price-list',
          index: '_productPriceList',
          add: () => item._productPriceList.push({ displayColor: 'black' }),
          remove: (index) => item._productPriceList.splice(index, 1),
          moved: (index) => item._productPriceList.splice(index, 1),
          colorOptions: [
            { value: 'black', label: '黑色' },
            { value: 'darkGray', label: '深灰' },
            { value: 'gray', label: '灰色' },
            { value: 'red', label: '紅色' },
            { value: 'primary', label: '品牌色(綠)' }
          ]
        },
        { type: 'empty' },
        {
          label: '主攻特色',
          type: 'product-intro-main-feature',
          index: '_productMainFeature',
          add: () => item._productMainFeature.push({ file: {} }),
          remove: (index) => item._productMainFeature.splice(index, 1),
          moved: (index) => item._productMainFeature.splice(index, 1),
          iconUploadSuccess: (result, index) => {
            item._productMainFeature[index].icon = result;
          },
          error: $scope.alert,
          removeIcon: index => {
            item._productMainFeature[index].icon = null;
          }
        },
        { type: 'empty' },
        { label: '重點訊息標題', type: 'text', index: '_productMessageTitle' },
        { label: '重點訊息內文', type: 'textarea', index: '_productMessageBody' },
        { type: 'empty' },
        $scope.contentManagement,
        {
          label: '預約流程資訊顯示設定*',
          type: 'radio',
          index: 'isDisplayBookingInfo',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ]
        },
        { type: 'empty' },
        {
          label: '常見問題',
          type: 'product-intro-qanda',
          index: '_productQAndA',
          add: () => item._productQAndA.push({}),
          remove: (index) => item._productQAndA.splice(index, 1),
          moved: (index) => item._productQAndA.splice(index, 1)
        },
        { type: 'empty' },
        { label: '注意事項', type: 'textarea', index: '_productPrecautions' }
      ];
      $scope.customizeProductIntroInputs = [
        { label: '網址', type: 'text', index: '_productiUrl', class: 'long' }
      ];
      const changeProductIntroControls = (layout) => {
        $scope.publicProductIntroInputs.forEach(input => {
          input.showIndex = layout === 'publicVersion';
        });
        $scope.customizeProductIntroInputs.forEach(input => {
          input.showIndex = layout === 'customized';
        });
      };
      changeProductIntroControls(item._productLayout);

      $scope.isSiteImagesRequiredCheckbox = { value: false, label: '用戶上傳說明圖片顯示必填', checked: item.isSiteImagesRequired };
      $scope.isSiteImagesRequiredInput = {
        label: ' ',
        type: 'checkbox',
        index: 'isSiteImagesRequired',
        checkboxes: [$scope.isSiteImagesRequiredCheckbox],
        showIndex: !!item.isDisplaySiteImages
      };

      const isMechanicalCleaning = item._acceptanceLayout === 'mechanicalCleaning';
      $scope.inputItems = [
        {
          title: '基本設定',
          inputs: [
            {
              label: '分類名稱*',
              type: 'text',
              index: 'name',
              info: '前台設計排版，至多顯示2行，服務名稱請控制在10字內',
              infoPlacement: 'right'
            },
            {
              label: '分類簡述',
              type: 'text',
              index: 'briefly',
              maxlength: 30,
              info: '長度限定30字內(前台設計排版，至多顯示2行)，不填則不顯示',
              infoPlacement: 'right'
            },
            {
              label: '入口Icon',
              type: 'single-file',
              index: '_entranceIconUrl',
              originIndex: 'entranceIcon',
              class: 'images-wrapper',
              files: $scope.entranceIconFiles,
              success: $scope.singleFileUploaded,
              error: $scope.alert
            },
            {
              label: '示意圖1',
              type: 'single-file',
              index: '_sampleImageFirstUrl',
              originIndex: 'sampleImageFirst',
              class: 'images-wrapper',
              files: $scope.sampleImageFirstFiles,
              success: $scope.singleFileUploaded,
              error: $scope.alert,
              info: `
                <div>服務列表圖片為選填，上傳建議尺寸為1600x1200，每張大小控制在300kb以下為佳。</div>
                <div>列表圖片的顯示邏輯為根據上傳的數量 (0~3張)，<b>系統會自動排版，填滿高寬後裁切</b>。</div>
                <div>點擊<i class="fas fa-info-circle" style="font-size: 24px;"></i>可查看詳細規範與下載模板</div>
              `,
              infoClick: () => window.open('https://docs.google.com/spreadsheets/d/19Tkzq9GEySGIFnEcDDtBJFLBvFX_eT63YH_-t6GIz7A/edit#gid=1705200610', '_blank')
            },
            {
              label: '示意圖2',
              type: 'single-file',
              index: '_sampleImageSecondUrl',
              originIndex: 'sampleImageSecond',
              class: 'images-wrapper',
              files: $scope.sampleImageSecondFiles,
              success: $scope.singleFileUploaded,
              error: $scope.alert
            },
            {
              label: '示意圖3',
              type: 'single-file',
              index: '_sampleImageThirdUrl',
              originIndex: 'sampleImageThird',
              class: 'images-wrapper',
              files: $scope.sampleImageThirdFiles,
              success: $scope.singleFileUploaded,
              error: $scope.alert
            },
            {
              label: '下訂流程形象圖',
              type: 'single-file',
              index: '_bookingFigureImageUrl',
              originIndex: 'bookingFigureImage',
              class: 'images-wrapper',
              files: $scope.sampleImageThirdFiles,
              success: $scope.singleFileUploaded,
              error: $scope.alert,
              subLabel: '寬1920 X 高480\n限制1MB',
              validate: { width: { limit: 1920 }, height: { limit: 480 }, limitSize: 1000000 }
            },
            { label: '排序', type: 'number', index: 'sortOrder', info: '數值越大越前面' },
            {
              label: '下訂模式',
              type: 'radio',
              index: 'mode',
              radios: [
                { value: 'general', label: '一般清潔' },
                { value: 'deviceCleaning', label: '家電清潔' },
                { value: 'relocation', label: '搬家' },
                { value: 'decoration', label: '裝潢修繕' }
              ],
              changed: (value) => {
                $scope.isAddMoveInAddressInfoInput.showIndex = value === 'relocation';
              }
            },
            $scope.isAddMoveInAddressInfoInput,
            {
              label: '訂單列表管理模式',
              type: 'radio',
              index: 'reservationListMode',
              radios: [
                { value: 'stateOrdered', label: '訂單狀態依照固定步驟進行' },
                { value: 'stateUnordered', label: '工程管理模式' }
              ],
              changed: (value) => {
                $scope.canCrossoverStandardServiceInput.showIndex = value === 'stateOrdered';
                $scope.canCrossoverServiceInput.showIndex = value === 'stateUnordered';
                $scope.quoteItemIncludeInput.showIndex = value === 'stateUnordered';
                $scope.isCheckingCustomerBudget.showIndex = value === 'stateUnordered';
              },
              info: '決定訂單進單後以哪種模式進行管理，訂單會進到不同的訂單列表中'
            },
            { label: '轉介費抽成%*', type: 'number', index: 'serviceChargeAmount', info: '此為預設轉介費抽成％，可至廠商設定中，針對服務分類、方案、地區進行個別設定，轉介費計算將優先依個別設定計算。' },
            $scope.canCrossoverStandardServiceInput,
            $scope.canCrossoverServiceInput,
            $scope.isCheckingCustomerBudget,
            $scope.quoteItemIncludeInput,
            {
              label: '公告文字',
              type: 'text',
              index: 'bulletin',
              class: 'bulletin-input',
              info: '長度限制30字內，可使用emoji，有填寫才會顯示公告',
              maxlength: 30
            },
            {
              label: '公告連結',
              type: 'text',
              index: 'bulletinHyperlink',
              class: 'bulletinHyperlink-input',
              info: '不填則純文字顯示公告，不進行跳轉'
            },
            {
              label: '訂單推薦連結設定',
              type: 'reservation-recommend-links',
              index: 'recommendLinks',
              add: () => item.recommendLinks.push({ file: {} }),
              remove: (index) => item.recommendLinks.splice(index, 1)
            },
            // 1.11.0 功能無使用，先隱藏
            // { label: '驗收完成提示內容', type: 'textarea', index: 'acDisclaimer', maxlength: 100 },
            {
              label: '訂單成立通知email標題',
              type: 'text',
              index: 'newReservationMailTitle',
              info: '非必填，預設使用通用訂單成立通知設定，點擊可查看變數參照表',
              onInfoClick: () => {
                window.open('https://docs.google.com/spreadsheets/d/1sZGZzk4E2NQOSCij7Tyoy33LarotkWelIW7i44cocW4/edit?usp=sharing', '_blank');
              },
              maxlength: 30
            },
            { label: '訂單成立通知email內文', type: 'rich-editor', index: 'newReservationMailContent' },
            {
              label: '顯示設定',
              type: 'radio',
              index: 'isVisible',
              radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }],
              info: '決定用戶在服務列表是否看到該服務分類'
            },
            {
              label: '廠商列表篩選項目',
              type: 'radio',
              index: 'isVenderFilterListVisible',
              radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }],
              info: '控制用戶前台的廠商列表查詢功能的"專家類型"篩選，隱藏則不顯示該篩選項'
            },
            {
              label: '限定信義成交會員下訂',
              type: 'radio',
              index: 'isLimitedDealCustomerVerified',
              radios: [{ value: false, label: '不限定' }, { value: true, label: '限定，須為已驗證之信義成交會員才可下訂' }]
            },
            {
              label: '限定群組顯示',
              type: 'multi-searchable-obj-picker',
              index: 'targetUserGroups',
              title: '使用者群組',
              resource: UserGroup,
              titleField: 'name',
              filterField: 'name',
              queryParams: { limit: 200 },
              closeCityFilter: true,
              showSelectedItemsCount: true
            },
            {
              label: '下訂流程-用戶上傳說明圖片顯示',
              type: 'radio',
              index: 'isDisplaySiteImages',
              radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }],
              changed: (value) => {
                if (!value) {
                  $scope.isSiteImagesRequiredCheckbox.checked = false;
                }
                $scope.isSiteImagesRequiredInput.showIndex = !!value;
              }
            },
            $scope.isSiteImagesRequiredInput,
            {
              label: '下訂歸戶優惠券',
              type: 'multi-searchable-obj-picker',
              index: 'acquireCouponEvents',
              title: '優惠活動',
              resource: CouponEvent,
              titleField: 'subjectName',
              subTitleField: 'couponDefault.title',
              filterField: 'subjectName',
              queryParams: { limit: 200 },
              closeCityFilter: true,
              showSelectedItemsCount: true,
              info: '下訂時會發送設定的優惠券給用戶，訂單取消，如贈送的優惠券尚未使用，則會做廢此處發送的優惠券，如已使用，則該訂單保留，使用的優惠券不會做廢'
            },
            // { label: '庫存設定說明', type: 'textarea', index: 'settingDescription' },
            { type: 'action' }
          ]
        },
        {
          title: '介紹頁面設定',
          inputs: [
            /* 改為純公版，保留怕死灰復燃 */
            // { label: '版面選擇',
            // type: 'radio',
            // index: '_productLayout',
            // radios: [
            // { value: 'publicVersion', label: '公版' },
            // { value: 'customized', label: '自訂' },
            // ],
            // changed: (layout) => {
            // changeProductIntroControls(layout);
            // item._productPriceList = item._productPriceList || [];
            // item._productQAndA = item._productQAndA || [];
            // item._productMainFeature = item._productMainFeature || [];
            // item._productIntroduction = item._productIntroduction || [];
            // },
            // },
            ...$scope.publicProductIntroInputs,
            ...$scope.customizeProductIntroInputs,
            $scope.bookingFigureImageInput,
            {
              label: '前往預約按鈕顯示文字',
              type: 'text',
              index: '_displayGotoReservation',
              info: '沒有填寫則預設顯示"前往預約"'
            },
            { type: 'action' }
          ]
        },
        {
          title: '驗收單設定',
          inputs: [
            {
              label: '版型',
              type: 'radio',
              index: '_acceptanceLayout',
              radios: [
                { value: 'homeCleaning', label: '居家清潔' },
                { value: 'mechanicalCleaning', label: '家電清潔' }
              ],
              changed: (value, curTabInputSettings) => {
                const isMechanicalCleaning = value === 'mechanicalCleaning';
                const contentsSettings = curTabInputSettings.find(inputItem => inputItem.index === '_acceptanceContents');
                contentsSettings.label = isMechanicalCleaning ? '檢查項目' : '服務內容';
                const itemSettings = contentsSettings.columns.find(column => column.index === 'item');
                itemSettings.header = isMechanicalCleaning ? '檢查項目' : '項目說明';
                const areaSettings = contentsSettings.columns.find(column => column.index === 'area');
                areaSettings.hideColumn = isMechanicalCleaning;
              }
            },
            generateTableSettings($scope, {
              label: '說明事項',
              class: 'w-100',
              index: '_acceptanceNotes',
              columns: [
                { index: 'describe', header: '說明事項', class: 'w-100' },
                {
                  index: 'services',
                  header: '限定方案',
                  type: 'multi-selection-dropdown',
                  class: 'w-100',
                  options: [...(item.services || []).map(service => ({ label: service.name, value: service._id }))]
                }
              ]
            }),
            {
              label: isMechanicalCleaning ? '檢查項目' : '服務內容',
              class: 'w-100',
              ...generateTableSettings($scope, {
                index: '_acceptanceContents',
                columns: [
                  { index: 'area', header: '區域', class: 'w-100', hideColumn: isMechanicalCleaning },
                  { index: 'item', header: isMechanicalCleaning ? '檢查項目' : '項目說明', class: 'w-100' },
                  {
                    index: 'services',
                    header: '限定方案',
                    type: 'multi-selection-dropdown',
                    class: 'w-100',
                    options: [...(item.services || []).map(service => ({ label: service.name, value: service._id }))]
                  }
                ]
              })
            },
            { label: '簽收警語', type: 'textarea', index: '_acceptanceWarning' },
            { type: 'action' }
          ]
        },
        {
          title: 'SEO',
          inputs: [
            {
              label: '分類網址識別名稱*',
              type: 'text',
              info: '50字元內，英數或符號(-, _)結合的連續字串',
              index: 'identityCode',
              maxlength: 50,
              infoPlacement: 'right'
            },
            {
              label: '分頁標題名稱',
              type: 'text',
              index: 'paginationTitle'
            },
            {
              label: 'Meta Tag 關鍵字設定',
              type: 'text',
              info: '建議：最多 3 組關鍵字，關鍵字之間以逗號（,）分隔，總字數15字內。',
              index: 'metaTagKeywords'
            },
            {
              label: 'Meta Tag 描述',
              type: 'text',
              info: '建議：描述限50字內',
              index: 'metaTagDescribe'
            },
            { type: 'action' }
          ]
        }
      ];
    });
    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
