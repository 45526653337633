export default ['$scope', 'LayoutModule', 'layoutModuleUtils',
  function controller ($scope, LayoutModule, layoutModuleUtils) {
    const self = this;
    self.imageFiles = {};
    $scope.singleFileUploaded = $scope.singleFileUploaded; // eslint-disable-line
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);

      if (!item.name) {
        return $scope.alert('模組名稱為必填');
      }

      if (!item.defaultView || !item.defaultMobileView) {
        return $scope.alert('需上傳視覺圖桌機版、手機版圖片');
      }

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;

      item.bannerLargeModule = [{
        defaultView: item.defaultView,
        defaultMobileView: item.defaultMobileView,
        alt: item.alt
      }];

      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();

        item.style = layoutModule.style;
        item.alt = layoutModule.bannerLargeModule?.[0]?.alt;
        item.isVisible = layoutModule.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item._defaultViewUrl = layoutModule.bannerLargeModule?.[0]?.defaultView;
        item.defaultView = layoutModule.bannerLargeModule?.[0]?.defaultView?._id;
        item._defaultMobileViewUrl = layoutModule.bannerLargeModule?.[0]?.defaultMobileView;
        item.defaultMobileView = layoutModule.bannerLargeModule?.[0]?.defaultMobileView?._id;
      }

      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      if (currentActionItem.style === 'none') {
        currentActionItem.style = 'fillet';
      }

      return (
        [
          { label: '模組類型：', type: 'description', description: currentActionItem.label },
          { label: '*模組名稱：', type: 'text', index: 'name', remark: '不會顯示於前台' },
          {
            label: '顯示設定',
            type: 'radio',
            index: 'isVisible',
            radios: [
              { value: true, label: '顯示' },
              { value: false, label: '隱藏' }
            ]
          },
          {
            label: '樣式選擇',
            type: 'radio-with-image',
            index: 'style',
            radios: [
              { value: 'fillet', label: '圓角卡片式', image: '/img/bannerLarge_fillet.png' },
              { value: 'solid', label: '滿版全屏', image: '/img/bannerLarge_solid.png' },
              { value: 'filledBothSide', label: '左右填滿', image: '/img/bannerLarge_filledBothSide.png' }
            ]
          },
          { type: 'underline' },
          { label: '圖片alt：', type: 'text', index: 'alt' },
          {
            label: '*預設視覺圖：',
            type: 'single-file',
            index: '_defaultViewUrl',
            originIndex: 'defaultView',
            class: 'images-wrapper',
            files: $scope.imageFiles,
            success: $scope.singleFileUploaded,
            error: $scope.alert,
            remark: '實際上傳檔案不限制尺寸，檔案限制1MB',
            validate: { limitSize: 1000000 }
          },
          {
            label: '*預設視覺圖-手機版：',
            type: 'single-file',
            index: '_defaultMobileViewUrl',
            originIndex: 'defaultMobileView',
            class: 'images-wrapper',
            files: $scope.imageFiles,
            success: $scope.singleFileUploaded,
            error: $scope.alert,
            remark: '實際上傳檔案不限制尺寸，檔案限制1MB',
            validate: { limitSize: 1000000 }
          },
          {
            label:
          `
圖片設定提醒:

滿版全屏 樣式
桌機版 圖片比例：16:9、建議尺寸：寬1600 x 高900 ~ 寬1920 x 高1080(高畫質)
手機版 圖片比例：1:2、建議尺寸：寬1000 x 高2000 ~ 寬1200 x 高2400 (高畫質)

-
圓角卡片 跟 左右填滿 樣式
桌機版建議寬度 寬1600 ~ 1920(高畫質) (高度不受限，以內容為主)
手機版建議寬度 高1200 (高度不受限，以內容為主)

-

圓角卡片式 高不限
Desktop w1500*h不限長度,
Mobile w1600*h不限長度,
          `
          },
          { type: 'action' }
        ]
      );
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
