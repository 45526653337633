import moment from 'moment';

const getModuleTypeName = (item) => {
  if (item.error || typeof item === 'number') return;
  let label;
  switch (_.get(item, 'type', '')) {
    case 'graphicFix':
      label = '01_圖文模組(固定三則)';
      break;
    case 'graphicMore':
      label = '02_圖文模組(1~N則)';
      break;
    case 'publicitySmall':
      label = '03_宣傳模組(小圖)';
      break;
    case 'publicityLarge':
      label = '04_宣傳模組(大圖)';
      break;
    case 'bannerLarge':
      label = '05_橫幅大圖(不可點擊)';
      break;
    case 'banner':
      label = '06_橫幅Banner';
      break;
    case 'articleMainImage':
      label = '07_文章模組(1主圖3標題)';
      break;
    case 'articleSmallImage':
      label = '08_文章模組(小圖+敘述)';
      break;
    case 'customHtml':
      label = '09_自訂HTML模組';
      break;
    case 'graphicN':
      label = '10_圖文模組(擴增N排)';
      break;
    default:
      label = '';
      break;
  }
  return label;
};

export default ['Restangular',
  function factory (Restangular) {
    Restangular.addElementTransformer('layoutModules', false, _transformer);
    const LayoutModule = Restangular.service('layoutModules');
    return LayoutModule;

    function _transformer (item) {
      if (item.error) return item;
      if (typeof item === 'number') return item; // count

      item._columns = [
        { anchorType: 'checkbox' },
        { anchorType: 'copy-id' },
        { anchorType: 'true-or-false', isTrue: _.get(item, 'isVisible'), trueOnly: true },
        _.get(item, 'name', ''),
        getModuleTypeName(item),
        _.get(item, 'layouts', []).map(layout => layout.name).join(','),
        moment(_.get(item, 'updatedAt')).format('YYYY/MM/DD HH:mm'),
        _.get(item, 'operationLog.byName'),
        { anchorType: 'edit' }
      ];
      return item;
    }
  }
];
