export default {
  bindings: {
    createdTime: '<',
    resource: '<',
    envInfo: '<'
  },
  template: `
    <div class="life-footer life-body-xs">
      <div>成立時間：{{$ctrl.createdTime | date: 'yyyy/MM/dd HH:mm'}}</div>
      <div>訂單來源：{{$ctrl.resource}}</div>
      <div>訂購人裝置：{{$ctrl.envInfo}}</div>
    </div>
  `
};
