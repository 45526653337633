export default ['$http', 'API_URL', 'ywEvent', 'APP_VERSION', 'WebSocket', function ($http, API_URL, ywEvent, APP_VERSION, WebSocket) {
  let serverVersion = '';

  const get = () => serverVersion;
  const set = (version) => {
    serverVersion = version;
    ywEvent.emit('SERVER_VERSION_CHANGED', serverVersion);
  };

  const fetch = () => {
    $http.get(API_URL + 'users/getMe').then(res => {
      console.log('read Server Version: ', res.headers('X-Version'));
      set(res.headers('X-Version'));
    });
  };

  const checkWebVersion = () => {
    if (__NODE_ENV__ === 'dev' || __NODE_ENV__ === 'dev2') return;
    $http({
      method: 'GET',
      url: '/version'
    }).then(res => {
      WebSocket.publish('web_notice', { event: 'NEW_WEB_VERSION', version: res.data.version, env: __NODE_ENV__ });
    });
  };

  // exposed object
  return {
    get,
    set,
    fetch,
    checkWebVersion
  };
}];
