import { inputLimit } from '../../../share/input-limit.js';

export default ['$scope', 'LayoutModule', 'layoutModuleUtils',
  function controller ($scope, LayoutModule, layoutModuleUtils) {
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      if (!item.customHtmlModuleContent) return $scope.alert('HTML內容為必填');
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      item.customHtmlModule = {
        title: item.customHtmlModuleTitle,
        content: item.customHtmlModuleContent
      };
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();
        item.style = layoutModule?.style;
        item.isVisible = layoutModule?.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item.paragraphTitle = layoutModule.articleSmallImageModule.paragraphTitle || '';
        item[`${item.type}Module`] = layoutModule[`${item.type}Module`];
        item.customHtmlModuleTitle = layoutModule.customHtmlModule.title;
        item.customHtmlModuleContent = layoutModule.customHtmlModule.content;
      } else {
        item.marginStyle = 'bottom';
      }

      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      return [
        { label: '模組類型：', type: 'description', description: currentActionItem.label },
        { label: '*模組名稱：', type: 'text', index: 'name', remark: '不會顯示於前台' },
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [
            { value: true, label: '顯示' },
            { value: false, label: '隱藏' }
          ]
        },
        {
          label: '下間距設定*',
          type: 'radio',
          index: 'marginStyle',
          radios: [
            { value: 'bottom', label: '有下間距' },
            { value: 'none', label: '無下間距(設定為錨點時使用)' }
          ]
        },
        { type: 'underline' },
        { label: '標題：', type: 'text', index: 'customHtmlModuleTitle', remark: 'H1、字數限制：256字(未填則不顯示，建議8字內)', maxlength: inputLimit.default },
        {
          label: '*HTML內容：',
          type: 'html-editor',
          index: 'customHtmlModuleContent'
        },
        { type: 'action' }
      ];
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
