import moment from 'moment';

import { deniedMethods, getOrigin } from '../share';

export default ['Restangular', '$filter', '$state', 'ywUtil', 'currentUser', 'APP_URL',
  function factory (Restangular, $filter, $state, ywUtil, currentUser, APP_URL) {
    // reformat User object
    Restangular.addElementTransformer('users', false, _transformer);
    const User = Restangular.service('users');
    User.varifyPassword = varifyPassword;
    return User;

    function _transformer (item) {
      if (item.error) return item;
      if (typeof item === 'number') return item; // count

      if ($state.current.name === 'admin.management/service-account') {
        const PERMISSION_SETTINGS = {
          'console:tab:reservation': { name: '來件管理', order: 1 },
          'console:tab:marketing': { name: '行銷管理', order: 2 },
          'console:tab:service': { name: '服務管理', order: 3 },
          'console:tab:vender': { name: '廠商管理', order: 4 },
          'console:tab:accountManagement': { name: '帳務管理', order: 5 },
          'console:tab:websiteContent': { name: '網站內容管理', order: 6 },
          'console:tab:other': { name: '其他設定', order: 7 }
        };
        const permission = _.get(item, 'permission', {});
        item._columns = [
          { anchorType: 'checkbox' },
          _.get(item, 'username', ''),
          _.get(item, 'fullname', ''),
          _.chain(permission).keys().filter(key => PERMISSION_SETTINGS[key] && permission[key] === 'access')
            .sort((a, b) => PERMISSION_SETTINGS[a].order - PERMISSION_SETTINGS[b].order)
            .map(key => PERMISSION_SETTINGS[key].name).join(', ').value(),
          { anchorType: 'edit' }
        ];
      } else {
        const customerChecked = _.cond([
          [
            (syMember) => syMember.isDealCustomer && !syMember.beDealCustomerByVerifyIdentityTime,
            () => ({ color: '#76CE62', tooltip: '已驗證信義成交客戶(信義)' })
          ],
          [
            (syMember) => syMember.isDealCustomer && syMember.beDealCustomerByVerifyIdentityTime,
            () => ({ color: '#0814FA', tooltip: '已驗證信義成交客戶(居家)' })
          ],
          [
            (syMember) => !_.isEmpty(syMember.memberId) && syMember.isDealCustomer === false && syMember.phoneValid === true,
            () => ({ color: '#F68920', tooltip: '待驗證信義成交客戶' })
          ],
          [
            (syMember) => !_.isEmpty(syMember.memberId) && syMember.isDealCustomer === false && !syMember.phoneValid,
            () => ({ color: '#808080', tooltip: '信義一般客戶' })
          ],
          [(syMember) => _.isEmpty(syMember.memberId), () => null], // '非信義客戶'
          [() => true, () => null]
        ])(_.get(item, 'syMember', {}));

        item._columns = [
          { anchorType: 'user-icon' },
          {
            anchorType: 'user-info',
            banTooltip: `
              <div>黑名單限制方式：${deniedMethods(_.get(item, 'deniedSetting', {}))}</div>
              <div>原因：${_.get(item, 'deniedSetting.reason')}</div>
            `
          },
          // _.get(item, 'fullname'),
          _.get(item, 'phone'),
          _.get(item, 'email'),
          ywUtil.getFullAddressFromContactAddress(_.get(item, 'contactAddress', {})),
          {
            anchorType: 'link',
            url: `${APP_URL}service-admin/reservation?removeDateStart=true&removeVisibility=true&state=completed&userId=${_.get(item, '_id')}`,
            linkText: _.get(item, 'userReservationInfo.completedReservations.length', 0)
          },
          {
            anchorType: 'link',
            url: `${APP_URL}service-admin/reservation?removeDateStart=true&removeVisibility=true&userId=${_.get(item, '_id')}`,
            linkText: _.get(item, 'userReservationInfo.allReservations.length', 0)
          },
          {
            anchorType: 'link',
            url: `${APP_URL}service-admin/unordered-reservation?removeDateStart=true&removeVisibility=true&state=completed&userId=${_.get(item, '_id')}`,
            linkText: _.get(item, 'userReservationInfo.completedUnorderedReservations.length', 0)
          },
          {
            anchorType: 'link',
            url: `${APP_URL}service-admin/unordered-reservation?removeDateStart=true&removeVisibility=true&userId=${_.get(item, '_id')}`,
            linkText: _.get(item, 'userReservationInfo.allUnorderedReservations.length', 0)
          },
          {
            anchorType: 'link',
            url: `${APP_URL}service-admin/coupon?userId=${_.get(item, '_id')}`,
            linkText: _.get(item, 'couponCount', 0)
          },
          moment(item.createdAt).format('YYYY/MM/DD HH:mm') + `(${getOrigin(_.get(item, 'firstCreated.origin'))})`,
          {
            anchorType: 'true-or-false',
            isTrue: !!customerChecked,
            trueOnly: true,
            style: { color: customerChecked && customerChecked.color },
            tooltip: customerChecked && customerChecked.tooltip
          },
          _.get(item, 'sinyiStaffInfo')
            ? _.get(item, 'sinyiStaffInfo.empTitle', '') + '-' + _.get(item, 'sinyiStaffInfo.empId', '')
            : '',
          {
            anchorType: 'menu-buttons',
            btnList: [
              { name: 'editUser', label: '會員資料維護' },
              { name: 'createSelfConstructReservation', label: '建立自建單' },
              { name: 'logoutUser', label: '登出會員' }
            ]
          }
        ];
      }
      return item;
    }

    function varifyPassword (password) {
      return Restangular.one('users/validate').customPOST({ password });
    }
  }
];
