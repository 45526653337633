export default {
  bindings: {
    user: '<',
    envInfo: '<'
  },
  template: `
    <i
      class="fas fa-info-circle ptr"
      uib-popover-html="$ctrl.envInfoTmpl"
      popover-trigger="'mouseenter'"
      ng-click="$ctrl.cloneDeviceInfo()"
    ></i>
  `,
  controller: ['$element', 'ywEvent', 'ywUtil', function reservationEnvInfo ($element, ywEvent, ywUtil) {
    const envContent = (user, envInfo) => {
      const content = [];
      content.push(`userId: ${user._id}`);
      if (envInfo.deviceModel) {
        content.push(`瀏覽器型號: ${envInfo.browserName + envInfo.browserVersion}`);
      }
      if (envInfo.browserName) {
        content.push(`裝置型號: ${envInfo.deviceModel}`);
      }
      if (envInfo.osName) {
        content.push(`作業系統版本: ${envInfo.osName + ' ' + envInfo.osVersion}`);
      }
      if (envInfo.appVersion) {
        content.push(`平台版號: ${envInfo.appVersion}`);
      }
      return content;
    };

    this.$onInit = () => {
      this.envInfoTmpl = `
        <div>(點擊複製)</div>
        ${envContent(this.user, this.envInfo).map(data => `<div>${data}</div>`).join('')}
      `;
    };

    this.cloneDeviceInfo = () => {
      const content = envContent(this.user, this.envInfo);
      ywUtil.cloneText(content.map(data => data + '\n').join(''));
      ywEvent.emit('SUCCESS', '已複製');
    };
  }]
};
