import moment from 'moment';

export default ['$window', '$document', 'TIME_ZONE_OFFSET', function ($window, $document, TIME_ZONE_OFFSET) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      onChange: '=',
      timepart: '=',
      readOnly: '='
    },
    templateUrl: '/view/directive/time-period-in-week.html',
    link
  };

  function link (scope, element) {
    const data = {
      startCell: null,
      startCellIsChecked: false,
      dragging: false,
      dateList: generateDateList(scope.timepart),
      timelineList: []
    };
    scope.data = data;

    // 產生7天*48個半小時的資料結構
    function generateDateList (timepart) {
      const dateList = [];
      const dayInWeek = '日一二三四五六';
      for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
        const dayDisplay = `周${dayInWeek[dayIndex]}`;
        const day = { day: dayIndex, dayDisplay, times: [] };
        for (let timeIndex = 0; timeIndex < 48; timeIndex++) {
          const startTimeDisplay = moment()
            .startOf('day')
            .add(timeIndex * 30, 'minutes')
            .utcOffset(TIME_ZONE_OFFSET)
            .format('HH:mm');
          const endTimeDisplay = moment()
            .startOf('day')
            .add((timeIndex + 1) * 30, 'minutes')
            .utcOffset(TIME_ZONE_OFFSET)
            .format('HH:mm');
          if (timepart) {
            const statusInTimepart = _.get(timepart, `${dayIndex}.${timeIndex}`, 0);
            day.times.push({ selected: statusInTimepart, startTimeDisplay, endTimeDisplay });
          } else {
            day.times.push({ selected: 0, startTimeDisplay, endTimeDisplay });
          }
        }
        dateList.push(day);
      }
      dateList.push(dateList.shift()); // 後端資料是從週日開始，讀取時改為顯示週一開始
      return dateList;
    }

    function mouseUp (el) {
      data.dragging = false;
    }

    function mouseDown (el) {
      if (scope.readOnly) return;
      data.dragging = true;
      data.startCellIsChecked = el.attr('data-selected') === '1'; // 記下第一個點擊到的時間是否已選擇
      setStartCell(el);
      setEndCell(el);
    }

    function mouseEnter (el) {
      if (!data.dragging) return;
      setEndCell(el);
    }

    function setStartCell (el) {
      data.startCell = el;
    }

    function setEndCell (el) {
      cellsBetween(data.startCell, el).each(function () {
        const el = angular.element(this);
        if (_.isEmpty(el.attr('id'))) return;
        const [day, time] = el.attr('id').split('-');
        // 如果第一個選的時間是已選擇，則全部設為未選
        if (data.startCellIsChecked) {
          el.attr('data-selected', 0);
          data.dateList[day].times[time].selected = 0;
        } else {
          el.attr('data-selected', 1);
          data.dateList[day].times[time].selected = 1;
        }

        if (scope.onChange) scope.onChange(transformDateListToTimepart(data.dateList));
      });
    }

    function cellsBetween (start, end) {
      const coordsStart = getCoords(start);
      const coordsEnd = getCoords(end);
      const topLeft = {
        column: $window.Math.min(coordsStart.column, coordsEnd.column),
        row: $window.Math.min(coordsStart.row, coordsEnd.row)
      };
      const bottomRight = {
        column: $window.Math.max(coordsStart.column, coordsEnd.column),
        row: $window.Math.max(coordsStart.row, coordsEnd.row)
      };
      return element.find('td').filter(function () {
        const el = angular.element(this);
        const coords = getCoords(el);
        return coords.column >= topLeft.column &&
            coords.column <= bottomRight.column &&
            coords.row >= topLeft.row &&
            coords.row <= bottomRight.row;
      });
    }

    function getCoords (cell) {
      return {
        column: cell[0].cellIndex,
        row: cell.parent()[0].rowIndex
      };
    }

    function wrap (fn) {
      return function () {
        const el = angular.element(this);
        scope.$apply(function () {
          fn(el);
        });
      };
    }

    function transformDateListToTimepart (dateList) {
      const formatedTimepart = dateList.map(day => {
        return day.times.map(time => time.selected);
      });
      formatedTimepart.unshift(formatedTimepart.pop()); // 儲存時改成從週日開始
      return formatedTimepart;
    }

    element.delegate('td', 'mousedown', wrap(mouseDown));
    element.delegate('td', 'mouseenter', wrap(mouseEnter));
    $document.delegate('body', 'mouseup', wrap(mouseUp));
  }
}];
