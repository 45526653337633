export default ['$scope', '$async', '$state', 'ywUtil', 'User',
  function controller ($scope, $async, $state, ywUtil, User) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.mainAction.clearFilter = _.partial($scope.mainAction.clearFilter, ['role']);

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      ywUtil.initSearchBarOptions(
        $scope,
        [{ btnName: '名稱', placeholder: '請輸入名稱', params: 'fullname' }]
      );

      $scope.queryPage = 'service-account'; // for GA

      $scope.mainAction.initResource(User, { role: 'serviceAdmin' });
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {};
    }
  }];
