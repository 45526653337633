/*
  example:
  <life-date-picker title="'服務日期'" selected-date="selectedDate" max-date="maxDate" time-period="timePeriod"></life-date-picker>
*/

import moment from 'moment';

function getTimeString (value) {
  if (Number(value) < 10) {
    return '0' + String(value);
  } else {
    return String(value);
  }
}

export default {
  bindings: {
    label: '=', // string
    title: '=', // string
    selectedDate: '=', // Date
    maxDate: '<', // Date
    minDate: '<', // Date
    timePeriod: '=' // object, 有開始結束時間 { showFromTime, showToTime, fromTimeLabel, fromHour, fromMinute, toTimeLabel, toHour, toMinute }
  },
  template: `
    <div class="life-date-picker" ng-click="$ctrl.openDialog()">
      <div class="life-date-picker-text">
        <div class="life-body-large life-text-default life-label" ng-class="{'life-label-active': $ctrl.formatDate}">{{$ctrl.label || '選擇時間'}}</div>
        <div ng-if="$ctrl.formatDate" class="life-body-large life-label life-text-main">{{$ctrl.formatDate}}</div>
      </div>
      <span class="life-select-icon"></span>
    </div>
  `,
  controller: ['$element', 'lifeDialog',
    function datePickerController ($element, lifeDialog) {
      this.$onInit = () => {
        this.formatDate = '';
        if (this.selectedDate) {
          this.formatDate += moment(this.selectedDate).format('yyyy/MM/DD');
          if (this.timePeriod?.fromHour && this.timePeriod?.fromMinute) {
            this.formatDate += ` ${getTimeString(this.timePeriod?.fromHour)}:${getTimeString(this.timePeriod?.fromMinute)}`;
            if (this.timePeriod?.toHour && this.timePeriod?.toMinute) {
              this.formatDate += ` - ${getTimeString(this.timePeriod?.toHour)}:${getTimeString(this.timePeriod?.toMinute)}`;
            }
          }
        }
      };

      this.openDialog = () => {
        this._selectedDate = this.selectedDate;
        this._timePeriod = _.clone(this.timePeriod);
        lifeDialog.openConfirm({
          title: `選擇${this.title || ''}`,
          templateUrl: '/view/life-template/life-date-picker-dialog.html',
          selectedDate: this._selectedDate || new Date(),
          timePeriod: this._timePeriod,
          maxDate: this.maxDate,
          minDate: this.minDate,
          isFullWidthPage: true,
          rightButton: [
            {
              label: '關閉',
              color: 'neutral',
              type: 'outline',
              onClick: (ngDialogData, confirm, closeThisDialog) => {
                closeThisDialog();
              }
            },
            {
              label: '確認',
              color: 'primary',
              type: 'raised',
              onClick: (ngDialogData, confirm, closeThisDialog) => {
                this.selectedDate = ngDialogData.selectedDate;
                this.timePeriod = ngDialogData.timePeriod;

                this.formatDate = moment(this.selectedDate).format('yyyy/MM/DD');
                if (this.timePeriod?.fromHour && this.timePeriod?.fromMinute) {
                  this.formatDate += ` ${getTimeString(this.timePeriod?.fromHour)}:${getTimeString(this.timePeriod?.fromMinute)}`;
                  if (this.timePeriod?.toHour && this.timePeriod?.toMinute) { // 這個有空可以處理一下排版問題ＱＱ
                    this.formatDate += ` - ${getTimeString(this.timePeriod?.toHour)}:${getTimeString(this.timePeriod?.toMinute)}`;
                  }
                }
                console.log(this.formatDate);
                confirm();
              }
            }
          ]
        }, 'life-full-page-dialog');
      };
    }
  ]
};
