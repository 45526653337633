import { removeInnerProperties } from './../../../share';

export default ['$scope', '$location', '$async', '$state', 'ywUtil', 'currentUser', 'Restangular', 'User', 'UserGroup', 'confirmDialog', 'address', 'API_URL', 'WEBAPP_URL', 'localStorageService',
  function controller ($scope, $location, $async, $state, ywUtil, currentUser, Restangular, User, UserGroup, confirmDialog, address, API_URL, WEBAPP_URL, localStorageService) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const countyOptions = address.getSortedCounties;
    const districtInput = {
      name: '_districts',
      label: '地區',
      options: [],
      changed: (districts) => {
        console.log('districts: ', districts);
        $scope.mainAction.queryParams.regions = address.getRegions(
          $scope.mainAction.queryParams._counties,
          districts
        );
        $scope.mainAction.setClearFilterTrue();
      },
      type: 'multiFilter',
      byCategory: true
    };
    const syncDistrictOptions = function (counties) {
      if (_.isEmpty(counties)) {
        $scope.mainAction.queryParams._districts = [];
        delete $scope.mainAction.queryParams.regions;
        districtInput.options = [];
      } else {
        districtInput.options = address.syncDistrictOptions(counties);
        $scope.mainAction.queryParams._districts =
          ($scope.mainAction.queryParams._districts || []).filter(
            district => counties.find(county => district.startsWith(county))
          );
        $scope.mainAction.queryParams.regions = address.getRegions(
          counties,
          $scope.mainAction.queryParams._districts
        );
      }
      $scope.mainAction.setClearFilterTrue();
    };

    $scope.filterItems = [
      {
        name: 'createdAfterOrEqualTo',
        label: '註冊時間起',
        changed: (date) => {
          $scope.mainAction.queryParams.createdAfterOrEqualTo = date && ywUtil.toDateStart(date).toISOString();
          $scope.mainAction.setClearFilterTrue();
        },
        type: 'dateFilter'
      },
      {
        name: 'createdBefore',
        label: '註冊時間迄',
        changed: (date) => {
          $scope.mainAction.queryParams.createdBefore = date && ywUtil.toDateEnd(date).toISOString();
          $scope.mainAction.setClearFilterTrue();
        },
        type: 'dateFilter'
      },
      {
        name: '_counties',
        label: '縣市',
        options: countyOptions,
        changed: syncDistrictOptions,
        type: 'multiFilter'
      },
      districtInput,
      {
        name: 'userGroupNames',
        label: '會員群組',
        options: [],
        type: 'multiFilter',
        click: $async(async (item) => {
          const groups = await UserGroup.getList({ limit: 99 });
          item.options = (groups || []).map(group => ({ label: group.name, value: group.name }));
        })
      },
      {
        name: 'registerOrigin',
        label: '註冊來源',
        options: [
          { value: 'ls', label: '居家' },
          { value: 'sinyi', label: '信義' },
          { value: 'sinyiApp', label: 'sinyiApp' },
          { value: 'sinyiWeb', label: 'sinyiWeb' },
          { value: 'localbond', label: 'localbond' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'multiFilter'
      },
      {
        name: 'chooseCustomer',
        label: '信義房屋客戶',
        options: [
          { value: 'dealCustomerVerifyInSinyi', label: '已驗證信義成交客戶(信義)' },
          { value: 'dealCustomerVerifyInLiving', label: '已驗證信義成交客戶(居家)' },
          { value: 'notYetVerifyDealCustomer', label: '待驗證信義成交客戶' },
          { value: 'normalSinyiMember', label: '信義一般客戶' },
          { value: 'nonSinyiMember', label: '非信義客戶' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'multiFilter'
      },
      {
        name: 'empTitle',
        label: '信義同仁身份',
        options: [
          { value: '幕僚', label: '幕僚' },
          { value: '秘書', label: '秘書' },
          { value: '業務', label: '業務' },
          { value: '主管', label: '主管' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'multiFilter'
      },
      {
        name: 'blackListLimit',
        label: '黑名單限制',
        options: [
          { value: 'nonBlackList', label: '非黑名單' },
          { value: 'isExcludeAutoDispatch', label: '訂單不媒合廠商' },
          { value: 'isOnlyCardPayment', label: '僅能信用卡下單' },
          { value: 'isForbidLogin', label: '禁止登入' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'multiFilter'
      }
    ];

    const exportCsv = (encodeType) => {
      const { limit, skip, where, regions, ...rest } = $scope.mainAction.queryParams;
      const params = _.isEmpty(regions)
        ? rest
        : { ...rest, regions: regions.map(region => JSON.stringify(region)) };
      params.encodeType = encodeType;
      const finalParams = removeInnerProperties(params);
      window.location = API_URL + 'users/exportCsv?' +
        ywUtil.convertSimpleObjToQueryString(finalParams) +
        '&access_token=' + currentUser.getToken();
    };

    $scope.dropdownItemList = [{
      label: '下載',
      items: [
        { buttonListText: '會員資料', class: 'fab fa-apple', callback: _.partial(exportCsv, 'utf8') },
        { buttonListText: '會員資料', class: 'fab fa-windows', callback: _.partial(exportCsv, 'big5') }
      ]
    }];

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      const { userId } = $location.search();
      ywUtil.initSearchBarOptions(
        $scope,
        [
          { btnName: '註冊電話', placeholder: '查詢註冊電話', params: 'phone' },
          { btnName: '會員名稱', placeholder: '查詢會員名稱', params: 'fullname' },
          { btnName: 'email', placeholder: '查詢email', params: 'email' },
          { btnName: '地址', placeholder: '查詢地址', params: 'address' },
          { btnName: '會員ID', placeholder: '查詢會員ID', params: 'userId' },
          { btnName: 'utm_source', placeholder: '查詢utm_source', params: 'registerUtmSource' },
          { btnName: 'utm_medium', placeholder: '查詢utm_medium', params: 'registerUtmMedium' },
          { btnName: 'utm_campaign', placeholder: '查詢utm_campaign', params: 'registerUtmCampaign' },
          { btnName: 'utm_content', placeholder: '查詢utm_content', params: 'registerUtmContent' }
        ],
        userId ? { defaultOptionName: 'userId', defaultValue: userId } : {}
      );

      $scope.queryPage = 'customer'; // for GA

      $scope.mainAction.initResource(User, { role: 'customer', isGetCouponCount: true, userId: localStorageService.get('customerId') });

      localStorageService.remove('customerId');

      const clearFilter = $scope.mainAction.clearFilter;
      $scope.mainAction.clearFilter = () => clearFilter(['role', 'isGetCouponCount'], $scope.searchBar.textInput);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
      };
    }

    $scope.onUserIconClick = (item) => {
      ywUtil.cloneText(item._id);
      $scope.success('已複製');
    };

    $scope.menuButtons = {
      editUser: (item) => $scope.changePageState('edit', item),
      createSelfConstructReservation: async (item) => {
        const {
          cipherText: encryptedUserId,
          error: encryptUserIdError
        } = await Restangular.service('system/encrypt').post({
          type: 'selfConstructedBooking',
          plainText: item._id
        });
        const {
          cipherText: encryptedToken,
          error: encryptTokenError
        } = await Restangular.service('system/encrypt').post({
          type: 'selfConstructedBooking',
          plainText: currentUser.getToken()
        });
        if (encryptUserIdError || encryptTokenError) {
          $scope.alert(encryptUserIdError || encryptTokenError);
        } else {
          const params = {
            scCode: encryptedToken,
            scUserId: encryptedUserId,
            utm_source: 'backstage',
            utm_medium: 'customerservice',
            clearCart: true
          };
          window.open(`${WEBAPP_URL}self-construct-singlereservation?${ywUtil.convertSimpleObjToQueryString(params)}`, '_blank');
        }
      },
      logoutUser: $async(async (item) => {
        await confirmDialog.openConfirm({
          title: '登出會員',
          content: '確定要將該會員所有裝置的帳號登出？'
        });
        const res = await User.one(item._id).customPOST({}, 'forceLogout');
        if (res.error) {
          $scope.alert(res.error);
        } else {
          $scope.success('已登出該使用者');
        }
      })
    };
  }];
