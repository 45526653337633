/*
  example:
  <life-tag label="'Confirm'"></life-tag>
*/

export default {
  bindings: {
    label: '<', // string, 必填, 標籤文字
    selected: '<', // boolean, 是否被選擇
    clickable: '<', // boolean, 是否可以點擊
    onClick: '&' // function
  },
  template: `
    <md-button
      md-no-ink
      class="life-tag"
      ng-class="{'life-tag-clickable': $ctrl.clickable, 'life-tag-selected': $ctrl.selected}"
      ng-click="$ctrl.onClick()">
      {{$ctrl.label}}
    </md-button>
  `
};
