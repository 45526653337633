/*
  stateOrderedList 和 stateUnorderedList 的資料在新增刪除的時候，需要注意調動的資料陣列順序會影響 array.splice
*/

import {
  createAcceptancesPdf
} from '../share/print-acceptance-helper.js';

export default {
  bindings: {
    isShow: '<',
    orderStatus: '<',
    reservationDetail: '='
  },
  template: `
    <div class="life-card-menu" id="life-menu" ng-if="$ctrl.isShow">
      <div ng-repeat="item in $ctrl.buttonList track by $index" ng-click="!item.disabled && item.onclick()">
        <div class="life-menu-item" id="life-menu-item" ng-class="{'disabled': item.disabled}">
          <i class="fas {{item.icon}} fa-lg"></i>
          <span style="margin-left: 8px;" class="life-body-medium">{{item.content}}</span>
        </div>
      </div>
      <div class="life-menu-item warning" id="life-menu-item" ng-if="$ctrl.orderStatus === 'stateOrdered'" ng-class="{'disabled': $ctrl.cancelBtnInfo.isDisabled}" ng-click="!$ctrl.cancelBtnInfo.isDisabled && $ctrl.cancelBtnInfo.onCancelClick()">
        <i class="fas fa-file-excel fa-lg"></i>
        <span style="margin-left: 8px;" class="life-body-medium">取消訂單</span>
      </div>
    </div>
  `,
  controller: ['$element', '$async', 'ywEvent', 'ywUtil', 'Reservation', 'UnorderedReservation', 'currentUser', 'APP_URL', 'localStorageService', 'lifeDialog', 'Restangular', 'WEBAPP_URL', 'ArticleClassification',
    function reservationEnvInfo ($element, $async, ywEvent, ywUtil, Reservation, UnorderedReservation, currentUser, APP_URL, localStorageService, lifeDialog, Restangular, WEBAPP_URL, ArticleClassification) {
      this.isServiceAdmin = currentUser.hasRole('serviceAdmin');
      this.isVender = currentUser.hasRole('vender');

      // 訂單來源紀錄元件
      const generateOrderSource = () => {
        return {
          color: 'regular',
          content: '訂單來源紀錄',
          icon: 'fa-file-alt',
          disabled: true
          // onclick: () => openOrderSourceDialog()
        };
      };

      // 會員管理列表元件
      const goCustomerList = {
        color: 'regular',
        content: '前往會員管理列表',
        icon: 'fa-user-friends',
        disabled: !this.isServiceAdmin,
        onclick: () => {
          localStorageService.set('customerId', this.reservationDetail?.user?._id);
          window.open(`${APP_URL}service-admin/customer`, '_blank');
        }
      };

      // 指定用戶自建單元件
      const selfConstructLink = {
        color: 'regular',
        content: '建立指定用戶自建單',
        icon: 'fa-paste',
        onclick: () => openSpecificSelfConstruct()
      };

      // 訂單設定元件
      const generateReservationSetting = (Resource) => {
        return {
          color: 'regular',
          content: '訂單設定',
          icon: 'fa-cog',
          disabled: !this.isServiceAdmin,
          onclick: () => {
            lifeDialog.openConfirm({
              title: '訂單設定',
              templateUrl: '/view/life-template/life-reservation-setting-dialog.html',
              isVisible: this.reservationDetail?.isVisible,
              isSendNotify: this.reservationDetail?.isSendNotify,
              billProcessParticularStatus: this.reservationDetail?.billProcessParticularStatus === 'none',
              isVisibleChanged: async (value) => {
                const res = await Resource
                  .one(this.reservationDetail._id)
                  .customPUT({ isVisible: value }, 'isVisible');
                this.reservationDetail.isVisible = value;
                ywEvent.emit('SHOW_LIFE_NOTICE', '修改成功');
                if (res.error) {
                  ywEvent.emit('SHOW_LIFE_NOTICE', res.error);
                }
              },
              isSendNotifyChanged: async (value) => {
                const res = await Resource
                  .one(this.reservationDetail._id)
                  .one('stateNotification')
                  .customPUT();
                this.reservationDetail.isSendNotify = value;
                ywEvent.emit('SHOW_LIFE_NOTICE', '修改成功');
                if (res.error) {
                  ywEvent.emit('SHOW_LIFE_NOTICE', res.error);
                }
              },
              billProcessParticularStatusChanged: async (value) => {
                const res = await Resource
                  .one(this.reservationDetail._id)
                  .one('skipBillProcess')
                  .customPOST({
                    isSkip: !value
                  });
                this.reservationDetail.billProcessParticularStatus = (value ? 'none' : 'skip');
                ywEvent.emit('SHOW_LIFE_NOTICE', '修改成功');
                if (res.error) {
                  ywEvent.emit('SHOW_LIFE_NOTICE', res.error);
                }
              },
              isFullWidthPage: true,
              rightButton: [
                {
                  label: '關閉',
                  color: 'neutral',
                  type: 'outline',
                  onClick: (ngDialogData, confirm, closeThisDialog) => closeThisDialog()
                }
              ]
            }, 'life-full-page-dialog');
          }
        };
      };

      // 一般訂單使用卡片按鈕 array
      const stateOrderedList = [
        generateOrderSource(),
        {
          color: 'regular',
          content: '訂單歷程',
          icon: 'fa-info-circle',
          onclick: () => {}
        },
        goCustomerList,
        {
          color: 'regular',
          content: '下載驗收單',
          icon: 'fa-file-download',
          onclick: async () => {
            ywEvent.emit('SHOW_LIFE_NOTICE', '背景產生檔案中，請稍候');
            const url = await createAcceptancesPdf({ reservationList: [this.reservationDetail], APP_URL });
            window.open(url);
          }
        },
        selfConstructLink,
        generateReservationSetting(Reservation)
      ];

      // 工程訂單使用卡片按鈕 array
      const stateUnorderedList = [
        generateOrderSource(),
        {
          color: 'regular',
          content: '訂單歷程',
          icon: 'fa-info-circle',
          onclick: () => {}
        },
        goCustomerList,
        selfConstructLink,
        generateReservationSetting(Reservation)
      ];

      // 開啟訂單來源紀錄 dialog
      // const openOrderSourceDialog = () => {
      //   lifeDialog.openConfirm({
      //     title: '訂單來源紀錄',
      //     templateUrl: '/view/life-template/life-reservation-source-dialog.html',
      //     data: {
      //       utmInfo: this.utmInfo,
      //       guidePurchaseInfo: {
      //         title: this.guidePurchaseTitle,
      //         url: this.guidePurchaseUrl
      //       }
      //     },
      //     isFullWidthPage: false,
      //     rightButton: [
      //       {
      //         label: '我知道了',
      //         color: 'primary',
      //         type: 'raised',
      //         onClick: (ngDialogData, confirm, closeThisDialog) => closeThisDialog()
      //       }
      //     ]
      //   }, 'life-standard-dialog');
      // };

      // 前往指定用戶自建單
      const openSpecificSelfConstruct = $async(async () => {
        const encrypt = async (text) => {
          try {
            if (!text) throw new Error('Text to encrypt is missing.');
            const result = await Restangular.service('system/encrypt').post({
              type: 'selfConstructedBooking',
              plainText: text
            });
            if (result.error) throw new Error(result.error);
            return result;
          } catch (error) {
            ywEvent.emit('ALERT', error);
          }
        };

        const token = currentUser.getToken();
        const { cipherText: encryptedToken } = await encrypt(token);
        const reservationId = _.get(this.reservationDetail, '_id', null);
        const { cipherText: encryptedReservationId } = await encrypt(reservationId);
        const selfConstructedURL = `${WEBAPP_URL}self-construct-singlereservation`;
        if (encryptedToken && encryptedReservationId) {
          const params = {
            scCode: encryptedToken,
            reservationCode: encryptedReservationId,
            utm_source: 'backstage',
            utm_medium: 'customerservice'
          };
          if (_.get(this.reservationDetail, 'staffInfo.empId')) {
            const { cipherText: encryptedStaffNumber } = await encrypt(this.reservationDetail?.staffInfo?.empId);
            params.sinyiStaffNumber = encryptedStaffNumber;
          }
          window.open(`${selfConstructedURL}?${ywUtil.convertSimpleObjToQueryString(params)}`, '_blank');
        }
      });

      // 取得訂單 utm 資料
      const getUtmFromReservation = (reservation) => {
        const _utmKey = ['source', 'medium', 'campaign', 'content'];
        // add prefix 'utm_', utm_source, utm_medium, utm_campaign, utm_content
        const utmKey = _utmKey.map(key => `utm_${key}`);
        const utmValue = _utmKey.map(key => _.get(reservation, `utm.${key}`), null);
        const utmData = _utmKey.map((key, index) => ({
          [utmKey[index]]: _.get(reservation, `utm.${key}`, '')
        }));
        const showUtm = utmValue.some(data => data);
        return { utmData, showUtm };
      };

      // 取得導購位置
      const genGuidePurchaseInfo = $async(async (reservation) => {
        const type = _.get(reservation, 'guidePurchaseRef');
        const purchase = _.get(reservation, 'guidePurchase');
        if (!type || !purchase) {
          return;
        }
        if (type === 'Article') {
          const classification = await ArticleClassification.one(purchase.articleClassification).customGET();
          if (!classification.error) {
            this.guidePurchaseTitle = purchase.title;
            this.guidePurchaseUrl = `${WEBAPP_URL}article/${classification.urlId}/${purchase.urlId}`;
          }
        } else if (type === 'Album') {
          this.guidePurchaseTitle = purchase.name;
          this.guidePurchaseUrl = `${WEBAPP_URL}album/${purchase.urlId}`;
        } else if (type === 'Vender') {
          this.guidePurchaseTitle = purchase.name;
          this.guidePurchaseUrl = `${WEBAPP_URL}vender/${purchase.urlId}`;
        }
      });

      const getCancelButtonInfo = (reservation) => {
        const disableState = ['cancelled', 'denied'];
        const disableStateForVender = disableState.concat(['new', 'pending-ps1', 'established', 'dispatching', 'completed', 'dispatched']);
        let isDisabled = false;
        if ((this.isServiceAdmin && _.includes(disableState, reservation?.state)) || (this.isVender && _.includes(disableStateForVender, reservation?.state))) {
          isDisabled = true;
        }
        return {
          isDisabled,
          onCancelClick: () => { console.log('onCancelClick'); } // click function 待補
        };
      };

      this.$onInit = () => {
        this.buttonList = this.orderStatus === 'stateOrdered' ? stateOrderedList : stateUnorderedList;
      };

      this.$onChanges = (changes) => {
        if (!_.isEmpty(this.reservationDetail)) {
          this.utmInfo = getUtmFromReservation(this.reservationDetail);
          genGuidePurchaseInfo(this.reservationDetail);
          this.cancelBtnInfo = getCancelButtonInfo(this.reservationDetail);
        }
      };
    }
  ]
};
