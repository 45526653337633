'use strict';

import './logo_backend.svg';
import './img_head.svg';

import './btn_form_photo_p.png';
import './btn_form_radio_male.png';
import './btn_form_radio_n.png';
import './btn_form_switch_p.png';

import './btn_list_detail_p.png';
import './btn_list_edit_p.png';
import './btn_list_eye_p.png';
import './btn_list_leave_d.png';
import './btn_list_leave_n.png';
import './btn_list_message_p.png';
import './btn_list_takeoff_n.png';
import './ic_btn_add_n.png';
import './ic_btn_approve_n.png';
import './ic_btn_delete_n.png';
import './ic_btn_gotoff_d.png';
import './ic_btn_gotoff_n.png';
import './ic_btn_message_n.png';
import './ic_form_arrow.png';
import './ic_list_nophoto.png';
import './ic_search.png';
import './icon_blue_close.png';
import './icon_red_open.png';
import './loading.gif';
import './btn_store_gpsmap.png';
import './ic_btn_move.png';
import './ic_coins.png';
import './ic_card.png';
import './ic_coins_red.png';
import './ic_card_red.png';
import './ic_form_phone_blue-2x.png';
import './ic-login-account.svg';
import './ic-login-password.svg';
import './default_head_image_9s.gif';
import './default-head-bear.png';
import './default-head-cat.png';
import './ic-store-approval.png';
import './btn-function-left.png';
import './btn-function-right.png';
import './btn-checkbox-selected.png';
import './btn-checkbox-unselected.png';
import './btn-mail-device-mobile.png';
import './btn-mail-device-web.png';
import './ic-sinyi-member.svg';

import './bannerLarge_fillet.png';
import './bannerLarge_solid.png';
import './bannerLarge_filledBothSide.png';
import './graphicMore_moreUsed.png';
import './graphicMore_fewerUsed.png';
import './publicityLarge_pictureLeft.png';
import './publicityLarge_pictureRight.png';
import './publicityLarge_pictureCenter.png';
import './publicitySmall_pictureLeft.png';
import './publicitySmall_pictureRight.png';
import './articleSmallImage_left.png';
import './articleSmallImage_right.png';

import './graphic_fix_thumbnail.jpg';
import './graphic_more_thumbnail.jpg';
import './publicity_small_thumbnail.jpg';
import './publicity_large_thumbnail.jpg';
import './banner_large_thumbnail.jpg';
import './banner_thumbnail.jpg';
import './article_main_image_thumbnail.jpg';
import './article_small_image_thumbnail.jpg';
import './html_thumbnail.jpg';
import './ckeditor-image-icon.png';
import './graphic_n_thumbnail.jpeg';
import './photo-tag-default-image.jpg';
import './contract-signing-default.svg';
import './deposit-confirmation-default.svg';
import './contract-signing-disable.svg';
import './deposit-confirmation-disable.svg';
import './check-default.svg';
import './check-disable.svg';
import './x-circle.svg';
import './checkbox.svg';
import './plus-icon-active.svg';
import './minus-icon-active.svg';
import './plus-icon-disable.svg';
import './cross.svg';
import './cross-white.svg';
