import { reservationStatusList } from '../../share';
const Currency = (num) => {
  return num
    .toString()
    .split('')
    .reverse()
    .reduce((result, num, index) => result + (index && index % 3 === 0 && num !== '-' ? ',' : '') + num, '')
    .split('')
    .reverse()
    .join('');
};

export default ['$scope', 'Reservation', '$async', 'ywEvent', '$state', 'lifePageUtil', '$window', 'APP_URL', 'lifeDialog',
  function controller ($scope, Reservation, $async, ywEvent, $state, lifePageUtil, $window, APP_URL, lifeDialog) {
    $scope.reservationMode = 'stateOrdered';
    $scope.reservationDetail = {};
    $scope.isShowMenu = false;
    $scope._isPaid = false;
    $scope.reservationState = '';
    $scope.billTotal = '';
    const { reservationNo } = $state.params;

    if (reservationNo) {
      getReservationData(reservationNo);
    }

    function getReservationData (reservationNo) {
      const getReservation = $async(async () => {
        const reservation = await Reservation.one(reservationNo).customGET();
        if (reservation.error) {
          ywEvent.emit('ALERT', reservation.error);
        } else {
          $scope.reservationDetail = reservation;
          $scope._isPaid = getReservationPaymentStages(reservation);
          $scope.reservationState = reservationStatusList.find(state => state.value === reservation.state)?.label;
          $scope.billTotal = getBillTotal(reservation);
        }
      });
      getReservation();
    }

    const getReservationPaymentStages = (reservationDetail) => {
      const paymentStageSetting = _.get(reservationDetail, 'paymentStageSetting', {});
      const paymentData = Object.values(paymentStageSetting).filter(item => !_.isEmpty(item) && !!item.order);
      if (reservationDetail.state === 'completed') return true;
      if (reservationDetail.state === 'denied' && reservationDetail.travelingExpense > 0) return true;
      if (reservationDetail.reservationListMode === 'stateOrdered' && !reservationDetail.isStandard && paymentData.length === 1 && paymentData[0].order.state === 'paid') return true;
      return false;
    };

    const getBillTotal = (reservationDetail) => {
      let result;
      if (reservationDetail.state === 'denied') return `$ ${Currency(reservationDetail.travelingExpense)}`;
      if (reservationDetail.isStandard === true) return `$ ${Currency(reservationDetail.total)}`;
      if (!reservationDetail.quotation) {
        result = '評估後報價';
      } else {
        result = reservationDetail.quotation?.confirmation?.state === 'confirmed' ? `$ ${Currency(reservationDetail.total)}` : '評估後報價';
      }
      return result;
    };

    $scope.onStatusClick = $async(async () => {
      await lifeDialog.openConfirm({
        title: '不發送訂單變更通知',
        htmlContent: '123',
        leftButton: [
          {
            label: '關閉',
            color: 'neutral',
            type: 'outline',
            onClick: () => console.log('關閉')
          },
          {
            label: '確定',
            color: 'primary',
            type: 'raised',
            onClick: () => console.log('確定')
          }
        ],
        rightButton: [
          {
            label: '取消',
            color: 'warning',
            type: 'raised',
            onClick: () => console.log('取消')
          }
        ]
        // selectCount: '已選 2'
      }, 'life-standard-dialog');
      // const res = await _resource
      //   .one(reservation._id)
      //   .one('stateNotification')
      //   .customPUT();
      // if (res.error) {
      //   ywEvent.emit('ALERT', res.error);
      // } else {
      // reservation.isSendNotify = !reservation.isSendNotify;
      // ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
      ywEvent.emit('SUCCESS', '已變更');
      // }
    });

    $window.onclick = (e) => {
      e.preventDefault();
      if (e.target.id !== 'life-menu-item' && e.target.id !== 'life-menu' && e.target.id !== 'life-menu-icon' && e.target.id !== 'life-menu-img') {
        if ($scope.isShowMenu) $scope.isShowMenu = false;
      }
      $scope.$apply();
    };

    $scope.onMenuIconClick = () => {
      $scope.isShowMenu = !$scope.isShowMenu;
    };

    $scope.onLogoIconClick = () => {
      $window.location.href = `${APP_URL}/service-admin/reservation`;
    };

    $scope.$on('$destroy', () => {
      console.log('pageDestroy');
      lifePageUtil.removeLoadViewPortMeta();
    });

    this.$onInit = () => {
      console.log('pageInit');
      lifePageUtil.addLoadViewPortMeta();
    };
  }
];
