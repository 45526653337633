import React, { useCallback } from 'react';

import Checkbox from './../common/Checkbox';
import { HeaderTR, Th } from './../common/Table';

const Header = ({ items, ywEvent }) => {
  const allChecked = !!items.length && items.every((item) => item._selected);
  const indeterminate = !!items.length && !allChecked && items.some((item) => item._selected);

  const onToggleAll = useCallback(() => {
    const checked = items.length && items.every((item) => !item._selected);
    ywEvent.emit('IFC_EVENT_FROM_REACT', {
      type: 'TOGGLE_ALL_ITEMS',
      payload: checked
    });
  }, [items]);

  return (
    <HeaderTR>
      <Th>
        <Checkbox
          checked={allChecked}
          indeterminate={indeterminate}
          onChange={onToggleAll}
        />
      </Th>
      <Th />
      <Th alignLeft>單號金額</Th>
      <Th alignLeft>分類</Th>
      <Th alignLeft>服務方案</Th>
      <Th>聯絡人</Th>
      <Th alignLeft>廠商</Th>
      <Th alignLeft>訂單狀態</Th>
      <Th>明細</Th>
      <Th>訂單來源</Th>
    </HeaderTR>
  );
};

Header.defaultProps = {
  items: []
};

export default Header;
