export default ['$scope', '$stateParams', '$async', 'ywUtil', 'Quotation', 'UnorderedReservation', 'currentUser', function controller ($scope, $stateParams, $async, ywUtil, Quotation, UnorderedReservation, currentUser) {
  $scope._attachments = [];
  $scope.quotation = [];

  $scope.isVender = currentUser.hasRole('vender');

  Promise.all([
    UnorderedReservation.one($stateParams.reservationId).customGET(),
    Quotation.one($stateParams.quotationId).customGET()
  ]).then(([reservation, quotation]) => {
    $scope.reservation = reservation;
    if (reservation.completedDate) $scope.hasCompletedDate = true;
    $scope.quotation = quotation;
    quotation.purchasingFee = {
      isExists: quotation.purchasingFee?.isExists ? quotation.purchasingFee?.isExists : true,
      total: quotation.purchasingFee?.total ? quotation.purchasingFee?.total : 0
    };
    $scope.serviceCharge.calc();
  });

  $scope.serviceCharge = {
    calc: () => {
      const serviceCharge = $scope.reservation.serviceCharge;
      const method = serviceCharge.type === 'rate'
        ? (value) => _.round(value * (serviceCharge.amount / 100))
        : (value) => _.max([value - serviceCharge.amount, 0]);
      $scope.serviceCharge.travelingExpense =
        method($scope.quotation.travelingExpense.total || 0);
      $scope.serviceCharge.evaluationFee =
        method($scope.quotation.evaluationFee.total || 0);
      $scope.serviceCharge.designContractAmount =
        method($scope.quotation.designContractAmount.total || 0);
      $scope.serviceCharge.engineeringContractAmount =
        method($scope.quotation.engineeringContractAmount.total || 0);
      $scope.serviceCharge.additionAndReduction =
        method($scope.quotation.additionAndReduction.total || 0);
      $scope.serviceCharge.total = $scope.serviceCharge.travelingExpense +
        $scope.serviceCharge.evaluationFee +
        $scope.serviceCharge.designContractAmount +
        $scope.serviceCharge.engineeringContractAmount +
        $scope.serviceCharge.additionAndReduction;
    }
  };

  $scope.removeAttachment = (index) => {
    $scope.quotation.attachments.splice(index, 1);
  };
  $scope.onAttachmentUploadSuccess = (result) => {
    $scope.quotation.attachments.push({
      file: result
    });
  };
  $scope.onAttachmentUploadFailed = (msg) => $scope.alert(msg);

  $scope.downloadFile = (file) => ywUtil.downloadFile(file.url, file.originalFilename);

  $scope.onConfirm = $async(async () => {
    const plainData = $scope.quotation.plain();
    let dealDateResult = true;
    for (const [key, value] of Object.entries(plainData)) {
      if (key !== 'additionAndReduction' && key !== 'purchasingFee' && value.isExists && value.total > 0 && !value.dealDate) {
        dealDateResult = false;
      }
    }
    if (!dealDateResult) return $scope.alert('項目金額不為零時，成交日期為必填');
    const {
      confirmation,
      publication,
      paymentStageSettings,
      confirmationLogs,
      publicationLogs,
      ...data
    } = plainData;
    data.attachments = data.attachments.map(file => ({
      name: file.file?.originalFilename,
      fileId: file.file._id
    }));
    data.total = data.travelingExpense.total +
      data.evaluationFee.total +
      data.designContractAmount.total +
      data.engineeringContractAmount.total +
      data.additionAndReduction.total;
    const result = await Quotation.one($scope.quotation._id).customPUT(data);
    if (result.error) {
      $scope.alert(result.error);
      return;
    }

    const reservationResult = await UnorderedReservation.one($scope.reservation._id).customPUT({
      expectedCompletedDate: $scope.reservation.expectedCompletedDate,
      completedDate: $scope.reservation.completedDate,
      constructionDate: $scope.reservation.constructionDate
    }, 'timeInfo');
    if (reservationResult.error) {
      $scope.alert(reservationResult.error);
    } else {
      $scope.success('儲存完成');
      window.close();
    }
  });
}];
