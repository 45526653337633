export default ['ngDialog', function factory (ngDialog) {
  return {
    openConfirm: openConfirm,
    openAlert: openAlert,
    closeDialog: closeDialog,
    getOpenDialogs: getOpenDialogs
  };

  function openConfirm (dialogData, customCss, preCloseCallback) {
    const params = {
      template: '/view/dialog/confirm-dialog.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog ' + (customCss || ''),
      closeByEscape: true,
      closeByDocument: true,
      preCloseCallback,
      data: dialogData
    };

    return ngDialog.openConfirm(params);
  }

  function openAlert (dialogData, customCss, preCloseCallback) {
    const params = {
      template: '/view/dialog/confirm-dialog.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog ' + (customCss || ''),
      closeByEscape: false,
      closeByDocument: false,
      preCloseCallback,
      data: dialogData
    };

    return ngDialog.openConfirm(params);
  }

  function closeDialog (id) {
    return ngDialog.close(id);
  }
  function getOpenDialogs () {
    return ngDialog.getOpenDialogs();
  }
}];
