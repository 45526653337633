import React from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';
import moment from 'moment';

import { reservationStatusList, findStateIndex } from './../../share';

const style = {
  icon: {
    verticalAlign: 'sub'
  }
};

const dateTimeFormat = 'YYYY/MM/DD HH:mm';

const statusList = reservationStatusList;
const statusMap = statusList.reduce((map, item) => ({
  ...map,
  [item.value]: item.label
}), {});

const cancelledIndex = findStateIndex('cancelled');

const CurrentStatus = ({ reservation, currentUser }) => {
  let currentState = _.find(statusList, st => st.value === reservation.state);
  if (!currentState) {
    return null;
  }
  // 待確認信用卡 狀態
  if (_.get(reservation, 'cardConfirmedStatus.isOnStatus')) {
    currentState = { label: '確認信用卡', value: 'dispatched' };
  }

  // 從stateLogs拿取狀態變化，並移除已指派廠商狀態
  let logRows = (reservation.stateLogs || [])
    .filter(log => !_.includes(['dispatched'], log.newState))
    .map(log => ({
      newState: log.newState,
      at: log.at,
      info: statusMap[log.newState] +
      '：' +
      moment(log.at).format(dateTimeFormat) +
      (log.newState === 'accepted' ? ` (${_.get(log, 'payload.venderName', '')})` : '')
    }));

  // 從dispatchLogs補回指派廠商相關紀錄
  if (currentUser.hasRole('serviceAdmin')) {
    const autoDispatchLogs = _.get(reservation, 'dispatching.autoDispatchLogs', [])
      .map(log => ({
        newState: 'dispatched', // 未必代表狀態變更，若已指派後變更廠商狀態本身未變化
        at: log.at,
        info: `${statusMap.dispatched}： ${moment(log.at).format(dateTimeFormat)} (${log.venderName})`
      }));
    const manuallyDispatchLogs = _.get(reservation, 'dispatching.manuallyDispatchLogs', [])
      .map(log => ({
        newState: 'dispatched', // 未必代表狀態變更，若已指派後變更廠商狀態本身未變化
        at: log.at,
        info: `${_.get(log, 'by.fullname', '')} 手動指派廠商： ${moment(log.at).format(dateTimeFormat)} (${log.venderName})`
      }));
    const declineLogs = _.get(reservation, 'dispatching.declineLogs', [])
      .map(log => ({
        newState: 'declined', // 非此狀態，無意義
        at: log.at,
        info: `廠商不接單： ${moment(log.at).format(dateTimeFormat)} (${log.venderName})`
      }));
    logRows = logRows.concat(autoDispatchLogs).concat(manuallyDispatchLogs).concat(declineLogs);
  }

  // 確認信用卡的 log
  if (_.has(reservation, 'cardConfirmedStatus')) {
    const at = _.get(reservation, 'cardConfirmedStatus.at');
    if (at) {
      logRows.push({
        newState: 'dispatched',
        at,
        info: `確認信用卡： ${moment(at).format(dateTimeFormat)}`
      });
    }
  }

  // 廠商不顯示派單歷程
  if (!currentUser.hasRole('serviceAdmin')) {
    logRows = logRows.filter(log => !_.includes(['dispatched', 'accepted'], log.newState));
  }

  if (_.get(reservation, 'paymentStageSetting["1"].order.state') === 'paid') {
    const paidLog = _.find(
      _.get(reservation, 'paymentStageSetting["1"].order.stateLogs', []),
      log => log.newState === 'paid'
    );
    if (paidLog) {
      logRows = logRows.map(log => {
        return log.newState === 'pending-ps1'
          ? {
              newState: 'paid-ps1',
              at: paidLog.at,
              info: '訂金已付款：' + moment(paidLog.at).format(dateTimeFormat)
            }
          : log;
      });
    }
  }
  if (_.get(reservation, 'paymentStageSetting["3"].order.state') === 'paid') {
    const paidLog = _.find(
      _.get(reservation, 'paymentStageSetting["3"].order.stateLogs', []),
      log => log.newState === 'paid'
    );
    if (paidLog) {
      logRows = logRows.map(log => {
        return log.newState === 'pending-ps3'
          ? {
              newState: 'paid-ps3',
              at: paidLog.at,
              info: '尾款已付款：' + moment(paidLog.at).format(dateTimeFormat)
            }
          : log;
      });
    }
  }

  // 照時間排序
  logRows = logRows.sort((logA, logB) => {
    if (logA.at === logB.at) {
      return 0;
    } else {
      return moment(logA.at).isBefore(logB.at) ? -1 : 1;
    }
  });

  const currentLog = _.find(
    reservation.stateLogs || [],
    log => log.newState === reservation.state
  );

  return (
    <StatefulToolTip
      parent={(
        <span>
          {_.get(currentState, 'label')}
          ：
          {moment(_.get(currentLog, 'at')).format(dateTimeFormat)}
        </span>
      )}
      position='top'
      arrow='center'
    >
      {logRows.map((log, idx) => (
        <div key={idx}>{log.info}</div>
      ))}
    </StatefulToolTip>
  );
};

const ReservationStatus = ({ reservation, currentUser, onEditStateClick }) => {
  const hideControl = findStateIndex(reservation.state) >= cancelledIndex;

  return (
    <div style={style.root}>
      <CurrentStatus reservation={reservation} currentUser={currentUser} />
      {!hideControl &&
        <span
          className='img icon-list-edit ptr'
          style={style.icon}
          onClick={() => onEditStateClick(reservation)}
        />}
    </div>
  );
};

export default ReservationStatus;
