export default ['$scope', '$stateParams', '$async', '$state', 'ywUtil', 'currentUser', 'PhotoTag',
  function controller ($scope, $stateParams, $async, $state, ywUtil, currentUser, PhotoTag) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      // ywUtil.initSearchBarOptions(
      // $scope,
      // [
      // ]
      // );

      $scope.filterItems = [
      ];

      $scope.queryPage = ''; // for GA

      $scope.mainAction.initResource(
        PhotoTag,
        { photoClassificationId: $stateParams.photoClassificationId }
      );

      const clearFilter = $scope.mainAction.clearFilter;
      $scope.mainAction.clearFilter = () => clearFilter(['photoClassificationId'], $scope.searchBar.textInput);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        sort: 0
      };
    }
  }];
