export default ['confirmDialog', 'ywEvent', 'Restangular', function factory (confirmDialog, ywEvent, Restangular) {
  const util = {};

  util.modifySinyiStaffInfo = async ({ reservation }) => {
    const mode = _.get(reservation, 'reservationListMode');
    const _resource = mode === 'stateUnordered' ? 'unorderedReservations' : 'reservations';
    const dialogData = {
      title: '修改信義員工資訊',
      templateUrl: '/view/dialog/single-input.html',
      confirmValue: '',
      placeholder: '輸入信義員工員編(不含s)',
      disableConfirm: true,
      onInputChange: (value) => {
        dialogData.disableConfirm = !value;
      }
    };
    const id = await confirmDialog.openConfirm(dialogData);
    const res = await Restangular.service(_resource).one(reservation._id).customPUT({ sinyiStaff: id }, 'sinyiStaff');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
    } else {
      reservation.staffInfo = res.staffInfo;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
      ywEvent.emit('SUCCESS', '修改完成');
    }
  };

  util.removeSinyiStaffInfo = async ({ reservation }) => {
    const mode = _.get(reservation, 'reservationListMode');
    const _resource = mode === 'stateUnordered' ? 'unorderedReservations' : 'reservations';
    await confirmDialog.openConfirm({
      title: '移除信義員工資訊',
      content: '確定移除此訂單的信義員工資訊？',
      confirmLabel: '移除',
      isDangers: true
    });
    const res = await Restangular.service(_resource).one(reservation._id).customPUT({ sinyiStaff: '' }, 'sinyiStaff');
    if (res.error) {
      await confirmDialog.openConfirm({ title: '移除失敗', content: res.error });
    } else {
      reservation.staffInfo = null;
      ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
      ywEvent.emit('SUCCESS', '移除完成');
    }
  };

  return util;
}];
