export default ['$scope', 'LayoutModule', 'layoutModuleUtils',
  function controller ($scope, LayoutModule, layoutModuleUtils) {
    const self = this;
    self.imageFiles = {};
    self.imageDoubleFiles = {};
    $scope.inputItems = [];
    $scope.dragContents = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);

      if (!item.name) {
        return $scope.alert('模組名稱為必填');
      }

      if (_.isEmpty(item.bannerModule)) {
        return $scope.alert('請至少建立一組Banner內容');
      } else {
        if (item.bannerModule.some(content => !content.title)) {
          return $scope.alert('需上傳模組內容所有Banner標題');
        }
        if (item.bannerModule.some(content => !content.defaultView)) {
          return $scope.alert('需上傳模組內容所有桌機版圖片');
        }
        if (item.bannerModule.some(content => !content.defaultMobileView)) {
          return $scope.alert('需上傳模組內容所有手機版圖片');
        }
      }

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      item.bannerModule = [];

      item.contents.forEach((content) => {
        const transformContent = {
          title: content[0].title,
          defaultView: content[1].defaultView,
          defaultMobileView: content[2].defaultMobileView,
          navData: content[3].navData
        };
        item.bannerModule.push(transformContent);
      });

      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      if (isEdit) {
        const layoutModule = await LayoutModule.one(item._id).customGET();

        item.style = layoutModule?.style;
        item.isVisible = layoutModule?.isVisible;
        item.label = layoutModuleUtils.getLayoutModuleTypeName(layoutModule.type);
        item.contents = layoutModule[`${item.type}Module`];
      }
      $scope.inputItems = getModuleAction(item, $scope);
    }

    const getModuleAction = (currentActionItem, $scope) => {
      currentActionItem.visibleItemIndex = 0;
      currentActionItem.logListEvent = (index) => {
        currentActionItem.newIndex = index;
      };

      $scope.dragContents = [
        {
          label: '*Banner標題(ALT使用)：',
          type: 'module-text',
          index: 'title',
          remark: '字數限制：8字',
          maxlength: 8
        },
        {
          label: '*桌機版圖片：',
          remark: '圖片比例：4:1、建議尺寸：寬2400 x 高600，限制 1 MB',
          limitSize: 1000000,
          type: 'module-file',
          index: '_defaultViewUrl',
          originIndex: 'defaultView',
          class: 'images-wrapper',
          files: $scope.imageFiles,
          success: (result, item) => {
            console.log('moderator imageUploaded: ', item);
            item._defaultViewUrl = result;
            item.defaultView = result._id;
          },
          error: $scope.alert,
          validate: { width: { limit: 2400 }, height: { limit: 600 }, limitSize: 1000000 }
        },
        {
          label: '*手機版圖片：',
          remark: '圖片比例：3:2、建議尺寸：寬1800 x 高1200，限制 1 MB',
          type: 'module-file',
          index: '_defaultMobileViewUrl',
          originIndex: 'defaultMobileView',
          class: 'images-wrapper',
          files: $scope.imageDoubleFiles,
          success: (result, item) => {
            console.log('moderator imageUploaded: ', item);
            item._defaultMobileViewUrl = result;
            item.defaultMobileView = result._id;
          },
          error: $scope.alert,
          validate: { width: { limit: 1800 }, height: { limit: 1200 }, limitSize: 1000000 }
        },
        { label: '頁面跳轉', type: 'module-navigation-setting', index: 'navData', buttonLabel: '頁面跳轉' }
      ];

      if (currentActionItem.contents) {
        const bannerContentsLength = currentActionItem.contents.length;
        for (let i = 0; i < bannerContentsLength; i++) {
          const cloneBannerContent = _.cloneDeep($scope.dragContents);
          const moduleContent = currentActionItem.contents[i];
          _.forIn(moduleContent, (value, key) => {
            if (value) {
              if (key === 'defaultView' || key === 'defaultMobileView') {
                const result = _.find(cloneBannerContent, function (item) {
                  if (item.originIndex === key) {
                    return true;
                  }
                });
                if (result) {
                  result[`_${key}Url`] = value;
                  result[key] = value._id;
                }
              } else {
                const result = _.find(cloneBannerContent, function (item) {
                  if (item.index === key) {
                    return true;
                  }
                });
                if (result) result[key] = value;
              }
            }
          });
          currentActionItem.contents.splice(i, 1, _.cloneDeep(cloneBannerContent));
        }
      } else {
        currentActionItem.contents = [_.cloneDeep($scope.dragContents)];
      }

      const dragModules = layoutModuleUtils.getDragModules($scope, 'contents');

      return (
        [
          { label: '模組類型：', type: 'description', description: currentActionItem.label },
          { label: '*模組名稱：', type: 'text', index: 'name', remark: '不會顯示於前台' },
          {
            label: '顯示設定',
            type: 'radio',
            index: 'isVisible',
            radios: [
              { value: true, label: '顯示' },
              { value: false, label: '隱藏' }
            ]
          },
          { type: 'underline' },
          {
            type: 'drag-module',
            module: 'banner',
            subType: 'graphicDragModule-wrapper',
            index: 'contents',
            itemDropped: dragModules.itemDropped,
            itemRemoved: dragModules.itemRemoved,
            itemAdded: dragModules.itemAdded,
            itemVisible: dragModules.itemVisible,
            itemDragend: dragModules.itemDragend
          },
          { type: 'action' }
        ]
      );
    };

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
