import moment from 'moment';

export default ['$scope', '$location', '$async', 'ywUtil', 'currentUser', 'ywEvent', 'Reservation', 'ServiceClassification', 'Service', 'ServiceCapacity', 'reservationUtil', 'API_URL', 'WEBAPP_URL', 'Restangular', 'confirmDialog', 'newebpayDetailDialog', 'Upload', 'address', 'filterItemFactory', 'sinyiStaffInfoUtil',
  function controller ($scope, $location, $async, ywUtil, currentUser, ywEvent, Reservation, ServiceClassification, Service, ServiceCapacity, reservationUtil, API_URL, WEBAPP_URL, Restangular, confirmDialog, newebpayDetailDialog, Upload, address, filterItemFactory, sinyiStaffInfoUtil) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;
    $scope.useReactTable = true;

    const getPinReservationShowFlag = () => {
      const { skip, limit, sort, ...rest } = $scope.mainAction.queryParams;
      const defaultFilter = {
        createdAfterOrEqualTo: moment().subtract(1, 'year').add(1, 'day').startOf('day').toDate(),
        createdBeforeOrEqualTo: moment().endOf('day').toDate(),
        isForAcceptanceList: false,
        isGetConnectionSetting: true
      };
      if (currentUser.hasRole('serviceAdmin')) {
        defaultFilter.displayVisible = 'visible';
      }

      return _.isEqual(defaultFilter, rest);
    };

    const filterQuery = $scope.mainAction.filterQuery;
    $scope.mainAction.filterQuery = () => {
      filterQuery();
      ywEvent.emit('IFC_EVENT', { type: 'SHOW_PIN_RESERVATIONS', payload: getPinReservationShowFlag() });

      if ($scope.mainAction.pager.currentPage === 1) {
        loadPinReservations();
      }
    };

    $scope.searchBarOptions = [
      {
        btnName: '訂單編號',
        placeholder: '請輸入訂單編號',
        params: 'reservationNo'
      },
      {
        btnName: '包套編號',
        placeholder: '請輸入包套編號',
        params: 'packageNo'
      },
      {
        btnName: '聯絡人名稱',
        placeholder: '請輸入聯絡人名稱',
        params: 'name'
      },
      {
        btnName: '聯絡人電話',
        placeholder: '請輸入聯絡人電話',
        params: 'phone'
      },
      {
        btnName: '聯絡人mail',
        placeholder: '請輸入聯絡人mail',
        params: 'email'
      },
      {
        btnName: '聯絡地址',
        placeholder: '請輸入聯絡地址',
        params: 'address'
      },
      {
        btnName: '服務員名稱',
        placeholder: '請輸入服務員名稱',
        params: 'venderStaffName'
      },
      {
        btnName: '會員ID',
        placeholder: '請輸入會員ID',
        params: 'userId'
      },
      {
        btnName: '物件編號',
        placeholder: '請輸物件編號',
        params: 'objectNo'
      },
      {
        btnName: '服務分類',
        placeholder: '請輸服務分類',
        params: 'serviceClassificationName'
      },
      {
        btnName: '服務方案',
        placeholder: '請輸服務方案',
        params: 'serviceName'
      },
      {
        btnName: '推薦同仁員編',
        placeholder: '請輸入推薦同仁員編',
        params: 'empId'
      },
      {
        btnName: '推薦同仁姓名',
        placeholder: '請輸入推薦同仁姓名',
        params: 'empName'
      }
    ];

    const getAllQueriedReservations = async () => {
      const currentQueryParams = _.cloneDeep($scope.mainAction.queryParams);
      currentQueryParams.skip = 0;
      currentQueryParams.limit = 9999;
      try {
        $scope.status.isLoading = true;
        return await Reservation.getList(currentQueryParams);
      } catch (e) {
        console.error(e);
      } finally {
        $scope.status.isLoading = false;
      }
    };

    $scope.printButtons = {
      label: '列印驗收單',
      btns: [
        {
          buttonText: '列印驗收單(篩選)',
          callback: async () => {
            const reservations = await getAllQueriedReservations();
            reservationUtil.previewAcceptances(reservations);
          }
        }, {
          buttonText: '列印驗收單(勾選)',
          callback: () => reservationUtil.previewAcceptances($scope.selectedReservations)
        }
      ]
    };

    const isServiceAdmin = currentUser.hasRole('serviceAdmin');

    if (isServiceAdmin) {
      $scope.searchBarOptions.push({
        btnName: '廠商名稱',
        placeholder: '請輸入廠商名稱',
        params: 'venderName'
      });
      $scope.searchBarOptions.push({
        btnName: '推薦同仁手機',
        placeholder: '請輸入推薦同仁手機',
        params: 'empPhone'
      });
    }
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    /** filterItems */
    const districtFilterItem = filterItemFactory.getDistrictFilter(
      $scope,
      'regions',
      '_counties',
      '_districts'
    );
    const countyFilterItem = filterItemFactory.getCountyFilter(
      $scope,
      'regions',
      '_counties',
      '_districts',
      districtFilterItem
    );

    const paymentMethodOptions = [
      { label: '現金', value: 'cash' },
      { label: '匯款', value: 'bankTransfer' },
      { label: '感服金請款', value: 'branchBudget' },
      { label: '信用卡', value: 'card' },
      { label: 'Apple pay', value: 'applePay' }
    ];
    const ratingOptions = [
      { label: '未評分', value: null },
      { label: '1顆星', value: 1 },
      { label: '2顆星', value: 2 },
      { label: '3顆星', value: 3 },
      { label: '4顆星', value: 4 },
      { label: '5顆星', value: 5 }
    ];
    const visibilityOptions = [
      { label: '未隱藏訂單', value: 'visible' },
      { label: '已隱藏訂單', value: 'invisible' }
    ];

    const serviceClassificationInput = filterItemFactory.getServiceClassificationFilter(
      $scope,
      'serviceClassification',
      '_service',
      'service'
    );
    const serviceInput = filterItemFactory.getServiceFilter(
      $scope,
      '_service',
      'service',
      'serviceClassification'
    );

    const originFilterItem = {
      name: '_origin',
      label: '訂單來源',
      options: ywUtil.getDefaultOriginOptions(),
      changed: $scope.mainAction.setClearFilterTrue,
      click: $async(async () => {
        const sourceRestrictionSetting = await Restangular
          .service('systemSettings')
          .one('sourceRestrictionSetting')
          .get();
        if (sourceRestrictionSetting.error) {
          $scope.alert(sourceRestrictionSetting.error);
        } else {
          const defaultOriginOptions = ywUtil.getDefaultOriginOptions();
          const originList = (sourceRestrictionSetting || []).map(value => ({ label: value, value }));
          originFilterItem.options = defaultOriginOptions.concat(originList);
        }
      }),
      type: 'multiFilter'
    };

    const labelInput = {
      name: 'labels',
      label: '訂單標籤',
      options: [],
      changed: $scope.mainAction.setClearFilterTrue,
      type: 'colorFilter',
      click: $async(async () => {
        const res = await Restangular.service('labels').getList({ limit: 999, target: 'commonReservation' });
        if (!res.error) {
          labelInput.options = res.map(label => ({
            label: label.name,
            value: label._id,
            color: label.color
          }));
        }
      })
    };

    $scope.filterItems = [
      countyFilterItem,
      districtFilterItem,
      serviceClassificationInput,
      serviceInput,
      originFilterItem,
      { name: 'defaultPaymentMethod', label: '付款方式', options: paymentMethodOptions, changed: $scope.mainAction.setClearFilterTrue, type: 'multiFilter' },
      { name: 'hasProcessingImage', label: '完工照', options: { true: '有完工照', false: '無完工照' }, changed: $scope.mainAction.setClearFilterTrue, type: 'noOwnAttrSelect' },
      { name: 'venderStaffRating', label: '服務員評分', options: ratingOptions, changed: $scope.mainAction.setClearFilterTrue, type: 'multiFilter' },
      labelInput,
      { name: 'billProcessStatus', label: '出帳狀態', options: { published: '已出帳', yet: '未出帳' }, changed: $scope.mainAction.setClearFilterTrue, type: 'noOwnAttrSelect' }
    ];

    if (isServiceAdmin) {
      $scope.filterItems.push(
        { name: 'displayVisible', label: '隱藏訂單', options: visibilityOptions, changed: $scope.mainAction.setClearFilterTrue, type: 'noOwnAttrSelect' }
      );
    }

    /** Order & status filter */
    $scope.customizeContentTemplate = '/view/template/reservation-order-filter.html';
    const DEFAULT_ORDER = '-createdTime';
    $scope.selectedOrder = { value: DEFAULT_ORDER };
    $scope.orderOptions = _.flatten([
      ['createdTime', '建立時間'],
      ['bookedTime', '服務時間'],
      ['completedTime', '訂單完成時間']
    ].map(([field, name]) => [
      { value: `-${field}`, label: `${name} 新-舊` },
      { value: `${field}`, label: `${name} 舊-新` }
    ]));

    const DEFAULT_DURATION = 'createdTime';
    $scope.selectedDuration = {
      type: DEFAULT_DURATION,
      start: $location.search().removeDateStart ? undefined : moment().subtract(1, 'year').add(1, 'day').startOf('day').toDate(),
      end: moment().endOf('day').toDate()
    };
    $scope.durationOptions = [
      { value: 'createdTime', label: '依照建立時間' },
      { value: 'bookedSession', label: '依照服務時間' }
    ];

    $scope.reservationStatus = [
      { key: 'dispatched', label: '已媒合廠商', selected: false },
      { key: 'cardUnconfirmed', label: '確認信用卡', selected: false },
      { key: 'accepted', label: '廠商已接單', selected: false },
      { key: 'quoting', label: '報價已提供', selected: false },
      { key: 'established', label: '訂單確立', selected: false },
      { key: 'staffAssigned', label: '已指派服務人員', selected: false },
      { key: 'staffArrived', label: '服務人員已到現場', selected: false },
      { key: 'processing', label: '服務人員服務中', selected: false },
      { key: 'processed', label: '服務完成', selected: false },
      { key: 'pending', label: '待付款', selected: false }, // 目前待付款有兩階段
      { key: 'completed', label: '訂單完成', selected: false },
      { key: 'cancelled', label: '已取消', selected: false },
      { key: 'denied', label: '評估後不施作', selected: false }
    ];
    if (isServiceAdmin) {
      $scope.reservationStatus.unshift({ key: 'dispatching', label: '廠商媒合中', selected: false });
    }
    $scope.reservationStatusToggle = (item) => {
      item.selected = !item.selected;
      $scope.mainAction.setClearFilterTrue();
    };

    $scope.reservationQuery = () => {
      // Origin condition
      filterItemFactory.setQueryParamsFromOriginFilter($scope, '_origin');

      // Sort condition
      $scope.mainAction.queryParams.sort = $scope.selectedOrder.value;

      // State condition
      const statusAry = _.flatten($scope.reservationStatus
        .filter(s => s.selected && s.key !== 'cardUnconfirmed')
        .map(s => s.key === 'pending' ? ['pending-ps1', 'pending-ps3'] : s.key));
      if (_.isEmpty(statusAry)) {
        _.unset($scope.mainAction.queryParams, 'state');
      } else {
        $scope.mainAction.queryParams.state = statusAry;
      }

      // cardUnconfirmed state
      delete $scope.mainAction.queryParams.cardUnconfirmed;
      if ($scope.reservationStatus.find(s => s.selected && s.key === 'cardUnconfirmed')) {
        $scope.mainAction.queryParams.cardUnconfirmed = true;
      }

      // Duration condition
      delete $scope.mainAction.queryParams.createdAfterOrEqualTo;
      delete $scope.mainAction.queryParams.createdBeforeOrEqualTo;
      delete $scope.mainAction.queryParams.bookedSession;
      const { type, start, end } = $scope.selectedDuration;
      if (type === 'createdTime') {
        if (start) $scope.mainAction.queryParams.createdAfterOrEqualTo = moment(start).startOf('day').toDate();
        if (end) $scope.mainAction.queryParams.createdBeforeOrEqualTo = moment(end).endOf('day').toDate();
      } else {
        $scope.mainAction.queryParams[type] = JSON.stringify({
          start: moment(start).startOf('day').toDate(),
          end: moment(end).endOf('day').toDate()
        });
      }

      $scope.mainAction.filterQuery();
    };

    const applyInitialConditions = () => {
      // Sort condition
      $scope.mainAction.queryParams.sort = $scope.selectedOrder.value;
      $scope.mainAction.queryParams.isForAcceptanceList = false;

      // Duration condition
      const { type, start, end } = $scope.selectedDuration;
      if (type === 'createdTime') {
        $scope.mainAction.queryParams.createdAfterOrEqualTo = start;
        $scope.mainAction.queryParams.createdBeforeOrEqualTo = end;
      } else {
        $scope.mainAction.queryParams[type] = JSON.stringify({ start, end });
      }

      // visibility
      if (isServiceAdmin && !$location.search().removeVisibility) {
        $scope.mainAction.queryParams.displayVisible = 'visible';
      }
    };

    $scope.clearReservationFilter = () => {
      $scope.selectedOrder.value = DEFAULT_ORDER;
      $scope.selectedDuration.type = DEFAULT_DURATION;
      $scope.selectedDuration.start = moment().subtract(1, 'year').add(1, 'day').startOf('day').toDate();
      $scope.selectedDuration.end = moment().endOf('day').toDate();
      $scope.reservationStatus.forEach(s => { s.selected = false; });

      applyInitialConditions();
      ywEvent.emit('IFC_EVENT', { type: 'SHOW_PIN_RESERVATIONS', payload: true });
      $scope.mainAction.clearFilter(['createdAfterOrEqualTo', 'createdBeforeOrEqualTo', 'sort', 'isForAcceptanceList', 'isGetConnectionSetting', 'displayVisible'], $scope.searchBar.textInput);
    };

    // 在 query 之前，放入預設條件
    applyInitialConditions();

    /** End Order & status filter */

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    const openNewebpayQueryDialog = $async(async () => {
      const no = await confirmDialog.openConfirm({
        title: '查詢藍新金流訂單狀態',
        templateUrl: '/view/dialog/single-input.html',
        inputLabel: '請輸入訂單編號',
        confirmValue: ''
      });
      newebpayDetailDialog.openDialog(no);
    });

    $scope.dropdownItemList = [
      {
        label: '建立自建單',
        items: [
          { buttonListText: '單筆建立', callback: openSelfConstructSingleReservationPage },
          { buttonListText: '批次匯入', callback: importSelfConstructCSV, file: { accept: 'text/csv' } },
          { buttonListText: '批次匯入自建單說明', class: 'fas fa-info-circle', callback: openSelfConstructTipPage }
        ]
      },
      {
        label: '查詢',
        items: [
          { buttonListText: '藍新金流訂單狀態', callback: openNewebpayQueryDialog }
        ]
      },
      {
        label: '下載',
        items: [
          { buttonListText: '訂單資料', class: 'fab fa-apple', callback: _.partial(exportCsv, 'utf8', 'reservation') },
          { buttonListText: '訂單資料', class: 'fab fa-windows', callback: _.partial(exportCsv, 'big5', 'reservation') }
        ]
      }
    ];
    if (currentUser.hasRole('serviceAdmin')) {
      $scope.dropdownItemList[0].items = $scope.dropdownItemList[0].items.concat([
        { buttonListText: '批次匯入自建單紀錄', callback: openSelfConstructLogDialog }
      ]);
    }

    async function openSelfConstructSingleReservationPage () {
      const token = currentUser.getToken();
      const { cipherText } = await getEncryptToken(token);
      if (cipherText) {
        const params = {
          scCode: cipherText,
          utm_source: 'backstage',
          utm_medium: 'customerservice',
          clearCart: true
        };
        window.open(`${WEBAPP_URL}self-construct-singlereservation?${ywUtil.convertSimpleObjToQueryString(params)}`, '_blank');
      }
    }

    async function getEncryptToken (token) {
      try {
        if (!token) throw new Error('User\'s token is missing.');

        $scope.status.isLoading = true;
        const result = await Restangular.service('system/encrypt').post({
          type: 'selfConstructedBooking',
          plainText: token
        });
        if (result.error) throw new Error(result.error);
        return result;
      } catch (error) {
        ywEvent.emit('ALERT', error);
      } finally {
        $scope.status.isLoading = false;
      }
    }

    function importSelfConstructCSV (file) {
      if (!file) return;

      // 檢查檔案類型，基本上不會用到因上方 dropdownItemList 已先限制，多做一層預防萬一
      const validFileType = 'text/csv';
      if (file.type !== validFileType) {
        $scope.alert('請提供一個 csv 檔案');
        return;
      }

      $scope.status.isLoading = true;
      Upload.upload({
        url: `${API_URL}reservations/importCsv`,
        header: { Authorization: `Bearer ${currentUser.getToken()}` },
        data: {
          file: file,
          envInfo: { deviceType: 'web', origin: 'lsWeb' },
          utm: {
            source: 'backstage',
            medium: 'customerservice'
          }
        }
      }).then(res => {
        if (res.data.error) {
          $scope.alert(res.data.msg);
        } else {
          $scope.success('檔案已匯入，建立中...');
        }
      }).catch(() => {
        // 處理非預期錯誤
        $scope.alert('上傳失敗，請檢查檔案格式或稍後再試');
      }).finally(() => {
        $scope.status.isLoading = false;
      });
    }

    function openSelfConstructTipPage () {
      window.open('https://docs.google.com/spreadsheets/d/1bFHP93eQa-4Ptm86zT66sag7ftdLiPtIGOlDbq-8Izo/edit?usp=sharing', '_blank');
    }

    async function openSelfConstructLogDialog () {
      const logs = await Restangular.service('batchReservationImportLogs').getList();
      confirmDialog.openConfirm({
        title: '建立自建單紀錄',
        templateUrl: '/view/dialog/self-construct-import-log.html',
        hideCancel: true,
        logs: _.map(logs, log => ({
          ...log,
          _successCount: _.uniqWith(_.get(log, 'successResults', []), log => log.rows).length,
          _errorCount: _.uniqWith(_.get(log, 'errorResults', []), log => log.rows).length
        })),
        onErrorDetailClick: openSelfConstructImportErrorDialog,
        onDownloadFile: (file) => ywUtil.downloadFile(file.url, file.originalFilename)
      }, 'custom-width-1024');
    }

    async function openSelfConstructImportErrorDialog (log) {
      confirmDialog.openConfirm({
        title: '匯入內容錯誤',
        templateUrl: '/view/dialog/self-construct-import-error-log.html',
        hideCancel: true,
        errors: log.errorResults
      }, 'custom-width-600');
    }

    function exportCsv (encodeType, dateTarget) {
      const { limit, skip, where, ...rest } = $scope.mainAction.queryParams;
      const params = Object.assign({ encodeType }, rest);
      params.timezoneOffset = -new Date().getTimezoneOffset();
      window.location = API_URL + 'reservations/exportCsv?' +
        ywUtil.convertSimpleObjToQueryString(params) +
        '&access_token=' + currentUser.getToken();
    }

    if (currentUser.hasRole('serviceAdmin')) {
      $scope.toolbarBtns = [
        {
          label: '設定標籤',
          onClick: $async(async () => {
            const selectedItems = ($scope.selectedReservations || []);
            const selectedIds = selectedItems.map(item => item._id);
            if (!selectedItems.length) { return $scope.alert('請選擇要設定的項目'); }
            const data = await confirmDialog.openConfirm({
              title: '設定訂單標籤',
              templateUrl: '/view/dialog/reservation-color-label-setting.html',
              labelResource: Restangular.service('labels'),
              confirmValue: {
                action: 'add',
                labels: []
              }
            });
            const results = await Reservation.one('labels').customPUT({
              reservations: selectedIds,
              labels: data.labels,
              action: data.action
            });
            if (results.error) {
              $scope.alert(results.error);
            } else {
              $scope.mainAction.filterQuery();
              $scope.success('設定完成');
            }
          })
        }
      ];
    }

    const handleEventFromReact = $async(async ({ type, payload }) => {
      console.log('event: ', type, payload);
      const displayVisible = _.get($scope.mainAction, 'queryParams.displayVisible');
      if (type === 'OPEN_RESERVATION_STATE_DIALOG') {
        reservationUtil.handleStateControl({ reservation: payload });
      } else if (type === 'OPEN_VENDER_ASSIGN_DIALOG') {
        reservationUtil.handleVenderAssign({ reservation: payload });
      } else if (type === 'OPEN_STAFF_ASSIGN_DIALOG') {
        reservationUtil.handleStaffAssign({ reservation: payload });
      } else if (type === 'OPEN_RESERVATION_DETAIL') {
        reservationUtil.openDetail({ reservation: payload, displayVisible });
      } else if (type === 'OPEN_OPEN_ACCEPTANCE_NOTE_DIALOG') {
        reservationUtil.openAcceptanceNote(payload);
      } else if (type === 'SELECTED_ITEMS_CHANGE') {
        $scope.selectedReservations = _.get(payload, 'current', []);
      } else if (type === 'OPEN_PIN_DIALOG') {
        reservationUtil.openPinDialog({
          reservation: payload.reservation,
          isPined: payload.isPined
        });
      } else if (type === 'OPEN_CONTACT_DEADLINE_DIALOG') {
        reservationUtil.openContactDeadlineDialog({ reservation: payload });
      } else if (type === 'OPEN_SINYI_STAFF_EDIT_DIALOG') {
        sinyiStaffInfoUtil.modifySinyiStaffInfo({ reservation: payload });
      } else if (type === 'OPEN_SINYI_STAFF_REMOVE_DIALOG') {
        sinyiStaffInfoUtil.removeSinyiStaffInfo({ reservation: payload });
      } else if (type === 'OPEN_RESERVATION_DETAIL_PAGE') {
        reservationUtil.openReservationDetailPage({ reservation: payload });
      }
    });

    const handleWebsocketEvent = $async(async (eventSource) => {
      console.log('ws event: ', eventSource);
      const { event } = eventSource;
      if (!event) {
        return;
      }
      const { key, msg, payload } = event;
      if (key === 'RESERVATION_CREATED' || key === 'UNORDERED_RESERVATION_CREATED') {
        $scope.info(msg);
      } else if (key === 'RESERVATION_STATE_CHANGED') {
        const id = payload.reservationId;
        const reservation = await Reservation.one(id).customGET();
        $scope.updateItemInList(reservation);
      } else if (key === 'RESERVATION_TRANSFER') {
        $scope.info(msg);
        if (currentUser.hasRole('vender')) {
          const id = payload.reservationId;
          $scope.removeItemInList({ _id: id });
        }
      }
    });

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.searchBar.hideSearchAndClearBtn = true;
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'reservation'; // for GA

      // init events
      const reactIFCEvent = ywEvent.register('IFC_EVENT_FROM_REACT', handleEventFromReact);
      const websocketEvent = ywEvent.register('WEBSOCKET_NOTIFICATION', handleWebsocketEvent);

      const { reservationNo, userId, state } = $location.search();
      if (reservationNo) {
        $scope.customTextChangeCb(reservationNo);
        $scope.mainAction.customSearchText = reservationNo;
      } else if (userId) {
        $scope.customTextChangeCb(userId);
        $scope.mainAction.customSearchText = userId;

        const newOption = _.find($scope.searchBarOptions, option => option.params === 'userId');
        changeSearchBarActiveOption($scope.searchBarOptionsActive, newOption);
      }
      if (state) {
        const stateList = typeof state === 'string' ? [state] : state;
        $scope.mainAction.queryParams.state = stateList;
        $scope.reservationStatus.forEach(stateInfo => {
          if (_.includes(stateList, stateInfo.key)) {
            stateInfo.selected = true;
          }
        });
      }
      $scope.mainAction.initResource(Reservation, { isGetConnectionSetting: true });

      // 拿取、更新pin 訂單
      $scope.watchPerPage = $scope.$watch('mainAction.pager.currentPage', currentPageUpdated);
      setTimeout(() => {
        ywEvent.emit(
          'IFC_EVENT',
          { type: 'SHOW_PIN_RESERVATIONS', payload: getPinReservationShowFlag() }
        );
      }, 500);
      loadPinReservations();
      const listItemUpdateEvent = ywEvent.register('UPDATE_ITEM_IN_LIST', handleItemUpdateInList);

      $scope.$on('$destroy', () => {
        ywEvent.unregister(reactIFCEvent);
        ywEvent.unregister(websocketEvent);
        ywEvent.unregister(listItemUpdateEvent);
      });
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
      };
    }

    function currentPageUpdated (newValue, oldValue) {
      if ($scope.mainAction.pager.isSafe) {
        ywEvent.emit(
          'IFC_EVENT',
          { type: 'SHOW_PIN_RESERVATIONS', payload: $scope.mainAction.pager.currentPage === 1 }
        );
        if ($scope.mainAction.pager.currentPage === 1) {
          loadPinReservations();
        }
      }
    }

    let isPinReservationSyncLoading = false;
    async function loadPinReservations () {
      if (isPinReservationSyncLoading) {
        return;
      }
      isPinReservationSyncLoading = true;
      const pinResults = await Restangular
        .service('pinReservations')
        .one('myself')
        .customGET('', { limit: 999 });
      const pinReservations = pinResults.error ? [] : pinResults.plain();
      ywEvent.emit('IFC_EVENT', { type: 'SET_PIN_RESERVATIONS', payload: pinReservations });
      isPinReservationSyncLoading = false;
    }

    async function handleItemUpdateInList (item) {
      loadPinReservations();
    }
  }];
