export default ['$scope', '$async', '$state', 'ywUtil', 'ArticleClassification',
  function controller ($scope, $async, $state, ywUtil, ArticleClassification) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();

      $scope.queryPage = 'article-classification'; // for GA

      $scope.mainAction.initResource(ArticleClassification);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true
      };
    }
  }];
