export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <table class="table">
      <tr>
        <th>用戶上傳圖片</th>
      </tr>
      <tr>
        <td
          ng-if="$ctrl.reservation.siteImages.length"
          style="text-align: left;"
        >
          <img
            uib-tooltip="點擊看原始圖片"
            ng-repeat="image in $ctrl.reservation.siteImages track by $index"
            class="ptr"
            ng-src="{{image.thumbImage.s3Url}}"
            style="width: 100px; height: 100px; object-fit: cover; margin-right: 7px;"
            ng-click="$ctrl.showOriginImage($index)"
          />
        </td>
      </tr>
    </table>
  `,
  controller: ['$element', 'ywEvent', function reservationUserImage ($element, ywEvent) {
    this.$onInit = () => {
    };

    this.showOriginImage = (index) => {
      const imageUrls = this.reservation.siteImages.map(img => img.url);
      ywEvent.emit('OPEN_IMAGE_VIEWER', { imageUrls, index });
    };
  }]
};
