export default ['Landmark', 'NgMap', 'GeoCoder', function factory (Landmark, NgMap, GeoCoder) {
  const getLandmarksByMapViewport = limit => () => {
    return NgMap.getMap().then(map => {
      const boundsKM = _getBoundsRadiusKM(map.getBounds());
      const center = map.getCenter();
      return _recursiveGetLandmarks(limit, { distanceKM: boundsKM, lat: center.lat(), lng: center.lng() }, [], 0);
    });
  };

  const getAllLandmarksByMapViewport = getLandmarksByMapViewport(undefined);

  const setMapByAddress = searchText => {
    return Promise.all([
      NgMap.getMap(),
      GeoCoder.geocode({ address: searchText })
    ]).then((data) => _.nth(data, 0).setCenter(_.get(_.nth(data, 1), '[0].geometry.location')));
  };

  const _recursiveGetLandmarks = (limit, reqData, data, skip) => {
    if (!_.isUndefined(limit) && data.length >= limit) {
      return Promise.resolve(data);
    }
    return Landmark.getList(Object.assign({}, reqData, { disabled: 'all', skip: skip }))
      .then(res => {
        if (res.length >= 50) { return _recursiveGetLandmarks(limit, reqData, data.concat(res), skip + 50); } else { return Promise.resolve(data.concat(res)); }
      });
  };

  const _getBoundsRadiusKM = (bounds) => {
    // r = radius of the earth in km
    const r = 6378.8;
    // degrees to radians (divide by 57.2958)
    const neLat = bounds.getNorthEast().lat() / 57.2958;
    const neLng = bounds.getNorthEast().lng() / 57.2958;
    const cLat = bounds.getCenter().lat() / 57.2958;
    const cLng = bounds.getCenter().lng() / 57.2958;
    // distance = circle radius from center to Northeast corner of bounds
    const radiusKM = r * Math.acos(
      Math.sin(cLat) * Math.sin(neLat) +
            Math.cos(cLat) * Math.cos(neLat) * Math.cos(neLng - cLng)
    );
    return radiusKM;
  };

  return {
    getLandmarksByMapViewport,
    getAllLandmarksByMapViewport,
    setMapByAddress,
    _getBoundsRadiusKM
  };
}];
