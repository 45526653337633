import React from 'react';

export const SinyiIcon = ({ style }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' style={style}>
      <g className='Frame 201'>
        <path fillRule='evenodd' d='M8 4a4 4 0 0 0-4 4v7.063l10.81-6.788v3.88l-.178.113L24 18.513V8a4 4 0 0 0-4-4H8Zm15.33 18.217-9.06-6.04v-3.683L4 18.944V20a4 4 0 0 0 4 4h12a3.996 3.996 0 0 0 3.33-1.783Z' className='Subtract' clipRule='evenodd' />
      </g>
    </svg>
  );
};
