import moment from 'moment';

export default ['$scope', '$async', 'ywUtil',
  function controller ($scope, $async, ywUtil) {
    const self = this;
    // $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.imageFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);

      if (!item.name) {
        return $scope.alert('活動名稱為必填');
      }
      if (!item._visibilityDateStart || !item._visibilityDateEnd) {
        return $scope.alert('活動時間起迄為必填');
      }
      if (!item.image) {
        return $scope.alert('需上傳圖片');
      }
      if (!item.briefly) {
        return $scope.alert('簡述為必填');
      }
      if (!item.phone) {
        return $scope.alert('手機直撥電話為必填');
      }
      if (!item.valuationUrl) {
        return $scope.alert('預約估價連結為必填');
      }
      if (item.consultationMethod === 'lineAt' && !item.lineAt) {
        return $scope.alert('line@連結為必填');
      }

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;

      item.visibility = {
        period: {
          from: item._visibilityDateStart,
          to: item._visibilityDateEnd
        }
      };

      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      // const isEdit = $scope.currentPageState === 'edit';

      if ($scope.currentPageState === 'add') {
        item._visibilityDateStart = moment().startOf('day');
      } else if ($scope.currentPageState === 'edit') {
        item._visibilityDateStart = _.get(item, 'visibility.period.from');
        item._visibilityDateEnd = _.get(item, 'visibility.period.to');
      }

      item._imageUrl = item.image;
      item.image = _.get(item, '_imageUrl._id');

      $scope.lineAtLinkInput = {
        label: 'line@連結*',
        type: 'text',
        index: 'lineAt',
        info: '輸入line@帳號id, 例如 @587slrzh',
        showIndex: item.consultationMethod === 'lineAt'
      };

      $scope.inputItems = [
        { label: '活動名稱*', type: 'text', index: 'name' },
        {
          label: '活動期間*',
          type: 'date-picker-duration',
          startIndex: '_visibilityDateStart',
          endIndex: '_visibilityDateEnd',
          info: '必填，活動期間內的Banner才會顯示'
        },
        {
          label: 'TA3使用Banner圖*',
          subLabel: '寬640 X 高312',
          type: 'single-file',
          index: '_imageUrl',
          originIndex: 'image',
          class: 'images-wrapper',
          files: $scope.imageFiles,
          success: $scope.singleFileUploaded,
          error: $scope.alert,
          validate: { width: { limit: 640 }, height: { limit: 312 } }
        },
        { label: '簡述*', type: 'text', index: 'briefly', info: '必填，上限20字，於TA3 Banner列表顯示' },
        { label: '說明', type: 'textarea', index: 'description', info: '於TA3 Banner活動內頁顯示，支援斷行' },
        { label: '手機直撥電話*', type: 'text', index: 'phone', info: '必填，設定"手機直撥"按鈕要撥打的電話' },
        { label: '預約估價連結*', type: 'text', index: 'valuationUrl', info: '必填，設定"預約估價"按鈕要開啟的對應連結網址' },
        {
          label: '諮詢方式',
          type: 'radio',
          index: 'consultationMethod',
          radios: [{ value: 'lineAt', label: 'line@' }],
          changed: consultationMethodChanged
        },
        $scope.lineAtLinkInput,
        { label: '排序', type: 'number', index: 'order', info: '數值越大越前面' },
        {
          label: '顯示設定',
          type: 'radio',
          index: 'isVisible',
          radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }]
        },
        { type: 'action' }
      ];
    }

    function consultationMethodChanged () {
      $scope.lineAtLinkInput.showIndex = $scope.currentActionItem.consultationMethod === 'lineAt';
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
