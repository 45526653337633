import listController from './list';
import actionController from './action';

export default ['$scope', '$controller', function controller ($scope, $controller) {
  // inherit
  $controller('mainActionController', { $scope: $scope });

  $scope.$on('$destroy', function () { $scope.mainAction.pager.clear(); });

  $scope.listController = listController;
  $scope.actionController = actionController;
  $scope.pageStates = {};
  $scope.currentPage = 'customer';
  $scope.currentPageState = 'list';

  init();

  function init () {
    /** define in-page states. use templateUrl if general templates unsufficient */
    $scope.pageStates = {
      list: { customLabel: '會員列表' },
      add: { customLabel: '新增會員' },
      edit: { customLabel: '編輯會員' }
    };
  }
}];
