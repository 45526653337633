export default ['$scope', '$stateParams', '$async', 'ywUtil', 'currentUser', 'VenderStaff', 'User', 'confirmDialog',
  function controller ($scope, $stateParams, $async, ywUtil, currentUser, VenderStaff, User, confirmDialog) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.searchBarOptions = [
      {
        btnName: '名稱',
        placeholder: '請輸入服務員名稱',
        params: 'name'
      }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    $scope.filterItems = [
      {
        name: 'subRole',
        label: '角色',
        options: [{ value: 'staff', label: '服務員' }, { value: 'valuator', label: '估價員' }],
        type: 'noOwnAttrSelect'
      }
    ];

    $scope.cellActions = {
      sendNewPassword: $async(async (item) => {
        await confirmDialog.openConfirm({
          title: '寄送新密碼',
          htmlContent: `
            確定發送密碼以下位置?<br/>
            ${item.user.phone ? '手機：' + item.user.phone + '<br/>' : ''}
            ${item.user.email ? 'email：' + item.user.email : ''}
          `
        });
        User.one(item.user._id).one('password').one('reset').customPOST({
          origin: 'lsWeb'
        }).then(res => {
          if (res.error) {
            $scope.alert(res.error);
          } else {
            $scope.success('新密碼已發送');
          }
        });
      })
    };

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'vender-staff'; // for GA

      const venderId = currentUser.hasRole('vender')
        ? _.get(currentUser.get(), 'link.vender')
        : $stateParams.venderId; // service admin user
      $scope.mainAction.initResource(VenderStaff, { venderId });
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        subRoles: ['staff']
      };
    }
  }];
