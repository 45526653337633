import moment from 'moment';
import { isValidEmail } from '../share';

export default ['$async', 'ywEvent', 'ServiceClassification', 'ServiceCapacity', 'Service', 'address', 'confirmDialog', 'uuid',
  function directive ($async, ywEvent, ServiceClassification, ServiceCapacity, Service, address, confirmDialog, uuid) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        capacities: '=',
        venderId: '=',
        contacts: '='
      },
      template: `
    <div>
      聯絡人資料
      <span class="fas fa-edit fa-2x ml-15" ng-click="openContactDialog()"></span>
      <i
        class="fas fa-info-circle ptr"
        aria-hidden="true"
        style="font-size: 24px;"
        uib-popover-html="'1.下方服務方案使用中聯絡人資料無法進行刪除操作。<br/>2.設定預選的聯絡人會在新增下方服務方案時自動加入'"
        popover-trigger="'mouseenter'"
        popover-placement="bottom"
      ></i>
    </div>
    <div
      style="font-weight: 600; white-space: nowrap; padding: 7px 0; min-width: 935px;"
    >
      <table class="table">
        <thead>
          <tr>
            <th>分類*</th>
            <th>方案</th>
            <th>抽成％*</th>
            <th>服務等級設定*</th>
            <th>縣市</th>
            <th>地區</th>
            <th>服務時間</th>
            <th>啟用</th>
            <th>聯絡人*</th>
            <th>刪除</th>
          </tr>
        </thead>
        <tbody>
          <tr ng-repeat="row in capacities track by $index">
            <td>
              <yw-popup-selection
                active-style="true"
                yw-options="classificationOptions"
                yw-label="'選擇分類'"
                yw-selected="row.serviceClassificationId"
                yw-on-change="onClassificationChange(row);"
              ></yw-popup-selection>
            </td>
            <td>
              <multi-searchable-obj-picker
                selected-items="row._services"
                obj-resource="Service"
                title-field="'name'"
                close-city-filter="true"
                close-search="item.closeSearch"
                show-selected-items="true"
                query-params="{classificationId: row.serviceClassificationId, displayVisible: 'all'}"
                use-input-appearance="true"
              ></multi-searchable-obj-picker>
            </td>
            <td>
              <input
                type="number"
                min="0"
                class="input"
                style="width: 80px;"
                ng-model="row.serviceCharge.amount"
              />
            </td>
            <td>
              <yw-popup-selection
                active-style="true"
                yw-options="levelOptions"
                yw-label="'選擇等級'"
                yw-selected="row.priority"
              ></yw-popup-selection>
            </td>
            <td>
              <yw-popup-multi-selection
                active-style="true"
                yw-options="countyOptions"
                yw-label="'縣市'"
                yw-selected-list="regionData[$index]._counties"
                yw-on-change="onCountiesChange($index, regionData[$index]._counties)"
                >
              </yw-popup-multi-selection>
            </td>
            <td>
              <yw-popup-multi-selection
                active-style="true"
                yw-options="districtOptionsByIndex[$index]"
                yw-label="'地區'"
                yw-selected-list="regionData[$index]._districts"
                yw-by-category="true"
                yw-on-change="onDistrctsChange($index, regionData[$index]._districts)"
                >
              </yw-popup-multi-selection>
            </td>
            <td style="min-width: 150px;">
              <div layout="column">
                <md-checkbox ng-model="row.isIncludeNormalDay" class="md-primary">平日</md-checkbox>
                <md-checkbox ng-model="row.isIncludeHoliday" class="md-primary">國定假日</md-checkbox>
              </div>
            </td>
            <td>
              <md-menu>
                <i class="fas fa-2x ptr"
                  ng-class="dispatchClass(row)"
                  ng-style="dispatchStyle(row)"
                  ng-click="$mdMenu.open($event)"
                  uib-tooltip="{{ dispatchTooltip(row) }}"
                />
                <md-menu-content>
                  <md-menu-item ng-repeat="btn in dispatchButtons track by $index">
                    <md-button ng-click="dispatchButtonCallback[btn.name](row)">
                      <span ng-bind="btn.label"/>
                    </md-button>
                  </md-menu-item>
                </md-menu-content>
              </md-menu>
            </td>
            <td>
              <multi-searchable-obj-picker
                selected-items="row._contacts"
                use-custom-options="true",
                custom-options="contacts",
                custom-selected-fetch="customSelectedFetch(row._contacts)"
                use-custom-selected-fetch="true"
                title-field="'name'"
                sub-title-field="'phone1'"
                close-city-filter="true"
              ></multi-searchable-obj-picker>
            </td>
            <td>
              <span
                class="glyphicon glyphicon-remove btn-minus"
                ng-if="!disabled"
                ng-click="removeRow($index)"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <span ng-if="!item.disabled">
      <md-tooltip md-direction="top">新增特殊供應價格</md-tooltip>
      <span
        class="glyphicon glyphicon-plus btn-plus"
        ng-click="addRow()"></span>
    </span>
    `,
      link: link
    };

    function link (scope, element, attr) {
      scope.classificationOptions = [];
      scope.regionData = [];
      scope.levelOptions = ['A', 'B', 'C'];
      scope.countyOptions = Object.keys(address);
      scope.districtOptionsByIndex = [];
      scope.Service = Service;

      const init = $async(async () => {
        const classifications = await ServiceClassification.getList({ limit: 999, displayVisible: 'all' });
        scope.classificationOptions = classifications.map(classification => ({
          label: classification.name,
          value: classification._id
        }));

        if (scope.venderId) {
          const capacities = await ServiceCapacity.getList({ venderId: scope.venderId, limit: 999 });
          scope.capacities = await Promise.all(capacities.map(async capacity => {
            const { vender, serviceClassification, ...rest } = capacity;
            rest.venderId = _.get(capacity, 'vender._id');
            rest.serviceClassificationId = _.get(capacity, 'serviceClassification._id');
            rest._services = await Promise.all((rest.services || []).map(serviceId => Service.one(serviceId).customGET()));
            rest._contacts = _.cloneDeep(rest.contacts);
            // 剔除今天以前的日期
            rest.deactivatedServiceDate = (rest.deactivatedServiceDate || []).filter(dateString => !moment(dateString).isBefore(moment(), 'days'));
            return rest;
          }));

          scope.districtOptionsByIndex = capacities
            .map(capacity => _.map(capacity.regions, region => region.county))
            .map(counties => syncDistrictOptions(counties));

          scope.regionData = capacities.map(capacity => ({
            _counties: (capacity.regions || []).map(region => region.county),
            _districts: _.chain(capacity.regions || [])
              .map(region => region.districts.map(district => `${region.county}-${district}`))
              .flatMap()
              .value()
          }));
        }
      });

      scope.addRow = () => {
        if (typeof scope.capacities !== 'object') {
          scope.capacities = [];
        }
        const _contacts = [];
        scope.contacts.forEach(contact => {
          if (contact.isDefault) {
            _contacts.push(contact);
          }
        });
        scope.capacities.push({
          venderId: scope.venderId,
          serviceCharge: { type: 'rate' },
          _contacts,
          _services: [],
          isEnableService: true,
          isIncludeHoliday: true,
          isIncludeNormalDay: true
        });
        scope.districtOptionsByIndex.push([]);
        scope.regionData.push({
          _counties: [],
          _districts: []
        });
      };
      scope.removeRow = index => {
        scope.capacities.splice(index, 1);
        scope.districtOptionsByIndex.splice(index, 1);
        scope.regionData.splice(index, 1);
      };

      scope.onCountiesChange = (index, counties) => {
        // district options changed
        scope.districtOptionsByIndex[index] = syncDistrictOptions(counties);
        // remove districts belong to removed counties
        scope.regionData[index]._districts = scope.regionData[index]._districts
          .filter(district => counties.some(county => district.startsWith(county)));
        scope.capacities[index].regions = scope.regionData[index]._counties.map(county => ({
          county,
          districts: scope.regionData[index]._districts
            .filter(district => district.startsWith(county))
            .map(district => district.split('-')[1])
        }));
      };
      scope.onDistrctsChange = (index, districts) => {
        scope.capacities[index].regions = scope.regionData[index]._counties.map(county => ({
          county,
          districts: districts
            .filter(district => district.startsWith(county))
            .map(district => district.split('-')[1])
        }));
      };

      const syncDistrictOptions = function (counties) {
        return counties
          .map(county => ({ category: county, districts: address[county] }))
          .map(districtsByCounty => _.map(
            districtsByCounty.districts,
            district => ({
              category: districtsByCounty.category,
              label: district,
              value: `${districtsByCounty.category}-${district}`
            })
          ))
          .reduce((res, districts) => res.concat(districts), []);
      };

      scope.customSelectedFetch = (contacts) => {
        return contacts;
      };

      scope.openContactDialog = () => {
        const dialogData = {
          title: '編輯聯絡人',
          templateUrl: '/view/dialog/contact-dialog.html',
          confirmValue: _.cloneDeep(scope.contacts),
          confirmWhenResolve,
          addContract,
          removeContract
        };
        confirmDialog.openConfirm(dialogData, 'custom-width-1024').then((contacts) => {
          scope.contacts = contacts;
        });

        function confirmWhenResolve (contacts, dialogData, confirmCb) {
          if (contacts?.length === 0) confirmCb(contacts);
          const requiredMissing = contacts
            .some(contact => ['name', 'phone1', 'email'].some(key => !contact[key]));
          if (requiredMissing) {
            dialogData.errMsg = '姓名、手機1、Email 必填';
            return;
          }
          const phoneValid = contacts.some(
            contact => ['phone1', 'phone2', 'telephone']
              .some(key => !contact[key] || /^\d{8,10}/.test(contact[key]))
          );
          if (!phoneValid) {
            dialogData.errMsg = '手機、電話須為8-10位數字';
            return;
          }
          const emailValid = contacts.some(contact => isValidEmail(contact.email));
          if (!emailValid) {
            dialogData.errMsg = 'Email 格式無效';
            return;
          }
          confirmCb(contacts);
        }
        function addContract () {
          dialogData.confirmValue.push({ _uuid: uuid.gen(), isDefault: false });
        }
        function removeContract (index) {
          dialogData.confirmValue.splice(index, 1);
        }
      };

      const getCapacityStatus = (capacity) => ({
        isActive: capacity.isEnableService && _.isEmpty(capacity.deactivatedServiceDate),
        isPartial: capacity.isEnableService && !_.isEmpty(capacity.deactivatedServiceDate),
        isInactive: !capacity.isEnableService
      });

      scope.dispatchTooltip = (capacity) => {
        const status = getCapacityStatus(capacity);
        return status.isInactive ? '全部停用' : status.isActive ? '全部啟用' : '部份日期停用';
      };

      scope.dispatchClass = (capacity) => {
        const status = getCapacityStatus(capacity);
        return {
          'fa-check': status.isActive || status.isPartial,
          'fa-times': status.isInactive
        };
      };

      scope.dispatchStyle = (capacity) => {
        const status = getCapacityStatus(capacity);
        return {
          color: status.isActive ? '#7DA826' : status.isInactive ? '#E65133' : '#F39124'
        };
      };

      scope.dispatchButtons = [
        { name: 'toggleStatus', label: '全部啟用/停用' },
        { name: 'disablePartially', label: '部分日期停用' },
        { name: 'showInactiveLog', label: '查看停用紀錄' }
      ];

      scope.dispatchButtonCallback = {
        toggleStatus: async (capacity) => {
          const status = getCapacityStatus(capacity);
          if (status.isInactive) {
            await confirmDialog.openConfirm({ title: '啟用服務方案', content: '是否啟用此服務方案？' });
            capacity.isEnableService = true;
            capacity.deactivatedServiceDate = [];
          } else {
            await confirmDialog.openConfirm({ title: '停用服務方案', content: '是否停用此服務方案？' });
            capacity.isEnableService = false;
            capacity.deactivatedServiceDate = [];
          }
        },

        disablePartially: async (capacity) => {
          const dialogData = {
            title: '設定',
            templateUrl: '/view/dialog/date-picker.html',
            confirmValue: _.clone((capacity.deactivatedServiceDate || [])
              .filter(dateString => !moment(dateString).isBefore(moment(), 'days')) // 剔除今天以前的日期
              .map(dateString => Date.parse(dateString))),
            datePickerOptions: {
              customClass: function (data) {
                if (dialogData.confirmValue.indexOf(data.date.setHours(0, 0, 0, 0)) > -1) {
                  return 'multiple selected';
                }
                return 'multiple';
              },
              minDate: new Date()
            }
          };
          const dateList = await confirmDialog.openConfirm(dialogData);
          capacity.deactivatedServiceDate = dateList.map(date => new Date(date).toISOString());
          capacity.isEnableService = true;
        },

        showInactiveLog: async (capacity) => {
          if (_.isEmpty(capacity._id)) {
            ywEvent.emit('ALERT', '新建資料，無停用日期紀錄');
            return;
          }

          const capacities = await ServiceCapacity.one(capacity._id).customGET('capacityLog', { target: 'actualDeactivatedServiceDate' });
          // 合併連續日期
          const inactiveDurations = _
            .chain([
              ...(capacity.deactivatedServiceDate || []),
              ...(capacities.actualDeactivatedServiceDate || [])
            ])
            .union() // 預防重複出現日期
            .sort((a, b) => b.localeCompare(a)) // 排序從"遠到近"
            .map(isoStr => moment(isoStr))
            .reduce((acc, curr, currIdx, ary) => {
              const pre = ary[currIdx - 1];
              const next = ary[currIdx + 1];
              // 當為第一個日期（index 為 0），或與前一筆差異超過兩天，為連續日期的"結束日"
              // 為什麼是結束日，因為是從排序是從"遠到近"
              if (currIdx === 0 || Math.abs(curr.diff(pre, 'days')) > 1) {
                acc.push(curr.format('YYYY/MM/DD'));
              }
              // 當與前一筆差異超過一天，且與下一筆差異超過兩天或為最後一筆資料，為連續日期的"起始日"
              if (Math.abs(curr.diff(pre, 'days')) === 1 && (currIdx === ary.length - 1 || Math.abs(curr.diff(next, 'days') > 1))) {
                const last = acc[acc.length - 1];
                acc[acc.length - 1] = `${curr.format('YYYY/MM/DD')} - ${last}`;
              }
              return acc;
            }, [])
            .value();

          const dialogData = {
            title: '停用紀錄',
            templateUrl: '/view/dialog/inactive-duration-dialog.html',
            hideCancel: true,
            data: inactiveDurations
          };
          confirmDialog.openConfirm(dialogData, 'custom-width-400');
        }
      };

      scope.onClassificationChange = (row) => {
        row._services = [];
      };

      init();

      // ywEvent.register('INIT_SERVICE_CAPACITY_SETTING', init);
    }
  }];
