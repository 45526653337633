export default ['$async', 'ywDialog', 'address', function factory ($async, ywDialog, address) {
  const openConfirm = (data) => {
    if (!data) return console.log('missing parameters');
    const ywTitle = data.ywTitle;
    const titleTooltip = data.titleTooltip;
    const ywInputPlaceholder = data.ywInputPlaceholder;
    const selectedId = data.selectedId;
    const titleField = data.titleField;
    const subTitleField = data.subTitleField;
    const addressFilter = data.addressFilter;
    const extraParams = data.extraParams;
    const queryField = data.queryField;
    const filterField = data.filterField;
    const objResource = data.objResource;
    const selectedCb = data.selectedCb;
    const customerBudget = data.customerBudget;
    const budgetField = data.budgetField;

    const cityOptions = Object.keys(address);
    const syncAreaOptions = function (city) {
      dialogData.addressOpts.area.options = address[city];
      queryObjs();
    };

    const dialogData = {
      title: ywTitle,
      titleTooltip: titleTooltip,
      inputPlaceholder: ywInputPlaceholder,
      objs: [],
      titleSearchCb: titleSearchCb,
      selectedCb: selectedCb,
      disselect: disselect,
      loadMore: loadMore,
      hasMore: true,
      selectedId: selectedId,
      titleField: titleField,
      subTitleField: subTitleField,
      budgetField: budgetField,
      customerBudget: customerBudget,
      _: _,

      addressFilter: addressFilter,
      addressOpts: {
        city: { label: '縣市', options: cityOptions, changed: syncAreaOptions, selected: undefined },
        area: { label: '地區', options: [], changed: queryObjs, selected: undefined }
      }
    };
    const dialogOpts = { plain: true, closeByEscape: true, closeByDocument: true };
    ywDialog.openConfirm('/view/dialog/searchable-obj-select-dialog.html', dialogData, null, dialogOpts);

    function queryObjs () {
      const limitPerQuery = 20;
      if (!dialogData.createdBefore) {
        dialogData.objs = [];
        dialogData.hasMore = true;
      }

      const queryParams = {
        ...extraParams,
        fields: queryField,
        limit: limitPerQuery,
        createdBefore: dialogData.createdBefore,
        city: dialogData.addressOpts.city.selected,
        area: dialogData.addressOpts.area.selected
      };
      queryParams[filterField || 'q'] = dialogData.queryText;
      dialogData.customerBudget = dialogData.customerBudget >= 0 ? dialogData.customerBudget : undefined;
      if (dialogData.customerBudget && dialogData.customerBudget > 0) {
        queryParams.customerBudget = dialogData.customerBudget;
      }

      objResource.getList(queryParams)
        .then(function (res) {
          const newObjs = res.plain();
          dialogData.objs = dialogData.objs.concat(newObjs);
          if (!newObjs.length || newObjs.length < limitPerQuery) { dialogData.hasMore = false; }
        });
    }

    function titleSearchCb () {
      if (dialogData.createdBefore) { delete dialogData.createdBefore; }
      queryObjs();
    }

    function loadMore () {
      if (dialogData.objs.length) { dialogData.createdBefore = dialogData.objs[dialogData.objs.length - 1].createdAt; }
      queryObjs();
    }

    function disselect (closeCb) {
      console.log('closeCb: ', closeCb);
      selectedCb({ _id: undefined });
      if (closeCb) { closeCb(); }
    }

    queryObjs();
  };

  return { openConfirm };
}];
