export default ['$timeout', '$async', 'confirmDialog', function directive ($timeout, $async, confirmDialog) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      items: '=items',
      type: '='
    },
    template: `
      <button class="yw-btn" ng-click="openItemSetting()">
        設定
      </button>
      <span ng-if="items.length">已設定<span>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.openItemSetting = () => {
      const dialogData = {
        title: '編輯項目選項',
        templateUrl: '/view/dialog/customization-items.html',
        confirmValue: _.cloneDeep(scope.items) || [],
        type: scope.type,
        expandedItemOptions: [
          { value: 'word', label: '文字欄位' },
          { value: 'date', label: '日期欄位' }
        ],
        addItem: (name) => {
          const newItem = { name, addCost: 0 };
          if (scope.type === 'customizedInput') {
            newItem.expandedItem = 'word';
          }
          dialogData.confirmValue.push(newItem);
          dialogData.inputText = '';
        },
        removeItem: (item, index) => {
          dialogData.confirmValue.splice(index, 1);
        },
        itemIsCustomizedOptionChange: (item) => {
          if (!item.isCustomizedOption) {
            item.required = false;
          }
        },
        confirmWhenResolve: (items, ngDialogData, confirmCb) => {
          if (items.some(item => !item.name)) {
            ngDialogData.errMsg = '項目選項不能為空';
          } else if (items.some(item => !_.isNumber(item.addCost))) {
            ngDialogData.errMsg = '請輸入加價金額';
          } else {
            console.log('newItems: ', items);
            scope.items = items;
            confirmCb();
          }
        }
      };

      confirmDialog.openConfirm(dialogData, 'custom-width-680');
    };

    scope.$watch('type', (newValue, oldValue) => {
      if (_.isEqual(newValue, oldValue)) return;
      if (oldValue === 'customizedInput' || newValue === 'customizedInput') {
        scope.items = [];
        $timeout(() => {
          scope.$apply();
        });
      }
    });
  }
}];
