/*
  example:
  <life-textarea label="'labelText'" value="textareaValue" disabled="true" hint="'showHint'"></life-textarea>
*/

export default {
  bindings: {
    label: '<', // string, 必填, input 的欄位名稱
    value: '=', // string, 必填
    disabled: '<', // boolean
    hint: '<' // string, 提示內容
  },
  template: `
    <md-input-container class="life-input">
      <label ng-class="{'life-label-disabled' : $ctrl.disabled}" ng-bind="$ctrl.label"></label>
      <textarea ng-model="$ctrl.value" ng-disabled="$ctrl.disabled" class="life-textarea" md-no-autogrow/>

      <div class="hint textarea-hint" ng-if="$ctrl.hint" ng-bind="$ctrl.hint"></div>
    </md-input-container>
  `
};
