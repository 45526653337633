import moment from 'moment';

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('quotations', false, _transformer);
  const Quotation = Restangular.service('quotations');
  return Quotation;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    const publicationStateMap = {
      pending: '未發佈',
      published: '已發佈',
      cancelled: '已作廢'
    };
    const confirmationStateMap = {
      pending: '未確認',
      confirmed: '已確認',
      rejected: '不接受'
    };

    item._columns = [
      _.get(item, 'sn', ''),
      _.get(item, 'total', ''),
      {
        anchorType: 'documentation-list',
        files: _.get(item, 'attachments', []).map(file => ({ name: file.name, ...file.file }))
      },
      {
        anchorType: 'publication-state',
        state: _.get(item, 'publication.state'),
        stateText: publicationStateMap[_.get(item, 'publication.state')]
      },
      {
        anchorType: 'confirmation-state',
        state: _.get(item, 'confirmation.state'),
        stateText: (_.get(item, 'confirmation.state') === 'confirmed' ? moment(item.confirmation.at).format('YYYY/MM/DD HH:mm') : '') +
          confirmationStateMap[_.get(item, 'confirmation.state')]
      },
      _.get(item, 'note', ''),
      // 已發佈或已作廢的報價單都呈現預覽模式
      { anchorType: ['published', 'cancelled'].includes(_.get(item, 'publication.state')) ? 'preview' : 'edit' },
      { anchorType: 'icon-button', icon: 'edit', name: 'deductReferralFee' }
    ];

    return item;
  }
}];
