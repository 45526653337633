/**
 * Main controller which defined variables and functionalities available for all child controllers
 * @class mainController
 */
export default ['$scope', '$state', '$timeout', 'ywEvent',
  function controller ($scope, $state, $timeout, ywEvent) {
    $scope.status = { isLoading: false, message: '' };
    $scope.alert = {};
    $scope.noticeList = [];
    $scope.lifeNoticeList = [];
    $scope.siteStuff = { navItems: [] }; // Stuffs shared with all page controllers
    $scope.pageStateHistory = [];
    $scope._currentActionItem = {};
    $scope._currentSelectedItem = {};
    $scope._subActionItem = {};
    $scope.toggleLeftMenu = false;

    $scope.imageUploaded = imageUploaded;
    $scope.fileUploaded = imageUploaded;
    $scope.singleFileUploaded = singleFileUploaded;

    const loggedOutEventId = ywEvent.register('LOGGED_OUT', () => {
      $scope.currentSelectedItem = {}; // reset attributes
      $scope.closeNotice();
      $scope.alert('已登出');
    });
    const switchAccount = ywEvent.register('SWITCH_ACCOUNT', () => {
      $scope.closeNotice();
    });
    const alertEventId = ywEvent.register('ALERT', (data) => {
      if (!data) return;

      if (_.isString(data)) { $scope.alert(data); } else { notification(data.msg, data.isFixed, data.type); }
    });
    const successEventId = ywEvent.register('SUCCESS', (data) => {
      if (!data) return;

      if (_.isString(data)) { $scope.success(data); } else { notification(data.msg, data.isFixed, data.type); }
    });
    const lifeNoticeId = ywEvent.register('SHOW_LIFE_NOTICE', (data) => {
      if (!data) return;

      if (_.isString(data)) { $scope.lifeNotification(data); } else { $scope.lifeNotification(data.msg, data.isFixed, data.type); }
    });
    $scope.$on('$destroy', () => {
      ywEvent.unregister(switchAccount);
      ywEvent.unregister(loggedOutEventId);
      ywEvent.unregister(alertEventId);
      ywEvent.unregister(successEventId);
      ywEvent.unregister(lifeNoticeId);
    });

    $scope.$on('toggleLeftMenu', () => {
      $scope.toggleLeftMenu = !$scope.toggleLeftMenu;
    });

    init();

    function init () {
      /**
         * In page alert
         */
      $scope.alert = function (msg, isFixed, type = 'alert') {
        notification(msg, isFixed, type);
      };
      $scope.success = function (msg, isFixed, type = 'success') {
        notification(msg, isFixed, type);
      };
      $scope.info = function (msg, isFixed, type = 'info', action) {
        notification(msg, isFixed, type, action);
      };

      $scope.closeNotice = function (id) {
        if (!id) $scope.noticeList = [];
        _.remove($scope.noticeList, function (o) { return o.id === id; });
      };

      $scope.closeLifeNotice = function (id) {
        if (!id) $scope.lifeNoticeList = [];
        _.remove($scope.lifeNoticeList, function (o) { return o.id === id; });
      };

      /** scope setter/getter objects inherited by child controllers */
      Object.defineProperties($scope, {
        currentPath: { get: function () { return $state.current.name; } }, // url path helper
        currentLabel: { get: currentLabelGetter }, // main label and sub label

        // in-page state
        currentPageState: {
          set: function (state) {
            $scope.pageStateHistory.push(state);
          },
          get: function () { return $scope.pageStateHistory[$scope.pageStateHistory.length - 1]; }
        },
        customLabel: {
          get: function () { return _.get(this.pageStates[$scope.currentPageState], 'customLabel', ''); }
        },

        currentActionItem: {
          set: function (obj) { $scope._currentActionItem = obj; },
          // 利用 getter 與 ng-repeat，建立一個新的 scope (_recursiveScope) 來做到巢狀 template 用不同 currentActionItem。
          get: function () { return _.get(this, '_recursiveScope.currentActionItem', null) || $scope._currentActionItem; }
        },

        currentSelectedItem: {
          set: function (obj) { $scope._currentSelectedItem = obj; },
          get: function () { return $scope._currentSelectedItem; }
        },

        subActionItem: {
          set: function (obj) { $scope._subActionItem = obj; },
          get: function () { return $scope._subActionItem; }
        }
      });
    }
    function notification (msg, isFixed, type, action) {
      // toBuyStore action頁面在save/update會先有一個Promise處理非同步檢查，
      // 之後validation會有無法顯示alert的問題，這邊加上$timeout為workaround
      $timeout(() => {
        msg = String(msg);
        if (_.isEmpty(msg)) return;

        const timeoutMs = 5000;
        const newId = _.uniqueId('notice_');
        const newNotice = {
          id: newId,
          type: type,
          message: msg,
          action: action ? _.partial(action, newId) : undefined
        };
        $scope.noticeList.push(newNotice);

        if (isFixed) return;
        $timeout(function () {
          $scope.closeNotice(newId);
        }, timeoutMs);
      });
    }

    $scope.lifeNotification = (msg, isFixed) => {
      $timeout(() => {
        msg = String(msg);
        if (_.isEmpty(msg)) return;

        const timeoutMs = 5000;
        const newId = _.uniqueId('life_notice_');
        const newNotice = {
          id: newId,
          message: msg
        };
        $scope.lifeNoticeList.push(newNotice);

        if (isFixed) return;
        $timeout(function () {
          $scope.closeLifeNotice(newId);
        }, timeoutMs);
      });
    };

    /** match item's url and location path */
    function currentLabelGetter () {
      return _checkItems($scope.siteStuff.navItems);

      function _checkItems (items) {
        const ret = { main: '', sub: '' };
        // find main label
        items.some(function (item) { // eslint-disable-line
          if ($scope.currentPath.indexOf(item.url) > -1) {
            ret.main = item.label;
            // check sub label
            if (item.subItems) ret.sub = (_checkItems(item.subItems)).main; // recursive call
          }
        });
        return ret;
      }
    }

    /**
     * append uploaded image
     */
    function imageUploaded (result, paramName, originParamName) {
      console.log('moderator imageUploaded: ', result);
      $scope.currentActionItem[paramName].push(result);
      // set param images
      $scope.currentActionItem[originParamName] = $scope.currentActionItem[paramName].map(function (item) { return item._id; });
    }

    function singleFileUploaded (result, paramName, originParamName) {
      console.log('moderator fileUploaded: ', result);
      $scope.currentActionItem[paramName] = result;
      // set param images
      $scope.currentActionItem[originParamName] = result._id;
    }
  }];
