export default ['Restangular', '$location', '$filter', 'ywTimezone', function factory (Restangular, $location, $filter, ywTimezone) {
  Restangular.addElementTransformer('accounts', false, _transformer);
  const Account = Restangular.service('accounts');
  return Account;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    if ($location.path() === '/service-admin/account') { // 廠商繳款帳戶列表
      item._columns = [
        { anchorType: 'labels', labels: item?.vender?.labels },
        item?.vender?.name,
        item?.virtualAccount,
        {
          anchorType: 'button',
          label: '退款',
          info: String(item?.total),
          hideBtn: item?.total < 1
        },
        {
          anchorType: 'icon-button',
          icon: 'file-alt',
          name: 'openDetail'
        }
      ];
    } else { // 廠商帳號列表
      item._columns = [
        { anchorType: 'checkbox' },
        _.get(item, 'username', ''),
        _.get(item, 'fullname', ''),
        $filter('date')(item.createdAt, 'yyyy/MM/dd HH:mm', ywTimezone.get()),
        {
          anchorType: 'icon-button',
          name: 'sendNewPassword',
          icon: 'envelope-square',
          tips: '寄送新密碼'
        },
        { anchorType: 'edit' }
      ];
    }

    return item;
  }
}];
