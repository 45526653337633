export default ['$async', 'ywDialog', 'ywEvent', function factory ($async, ywDialog, ywEvent) {
  const openConfirm = async ({
    objResource,
    selectedIds,
    titleField,
    filterField,
    subTitleField,
    extraParams,
    inputPlaceholder,
    groupId
  }) => {
    let tmpArr = [];
    let oriArr = [];
    const onCheckboxChecked = (item) => {
      const checked = !item._checked;
      item._checked = checked;

      if (checked) {
        if (!tmpArr.includes(item._id)) {
          tmpArr.push(item._id);
        }
      } else {
        tmpArr = tmpArr.filter(id => id !== item._id);
      }

      dialogData.selectedIds = [...tmpArr];
    };

    const loadMore = () => {
      if (dialogData.objs.length) {
        dialogData.createdBefore = dialogData.objs[dialogData.objs.length - 1].createdAt;
      }
      queryObjects();
    };

    const queryObjects = async () => {
      const limitPerQuery = 20;
      if (!dialogData.createdBefore) {
        dialogData.objs = [];
        dialogData.hasMore = true;
      }

      const queryParams = {
        ...extraParams,
        limit: limitPerQuery,
        createdBefore: dialogData.createdBefore,
        [filterField || titleField]: dialogData.queryText
      };
      const results = await objResource.getList(queryParams);
      if (results.error) {
        ywEvent.emit('ALERT', results.error);
      }
      const newArr = results
        .filter(obj => obj.userGroups.some(group => group._id === groupId))
        .map(obj => obj._id);
      oriArr = [...oriArr, ...newArr];
      tmpArr = [...tmpArr, ...newArr];

      dialogData.selectedIds = tmpArr;

      const newObjects = results.map(obj => ({
        ...obj,
        _checked: obj.userGroups.map(group => group._id).includes(groupId)
      }));
      dialogData.objs = dialogData.objs.concat(newObjects);
      if (!newObjects.length || newObjects.length < limitPerQuery) {
        dialogData.hasMore = false;
      }
    };

    const onSearchTextChange = () => {
      if (dialogData.createdBefore) { delete dialogData.createdBefore; }
      queryObjects();
    };

    const dialogData = {
      _: _,
      titleField,
      subTitleField,
      inputPlaceholder,
      selectedIds: _.clone(selectedIds),
      groupId,
      // handlers
      onCheckboxChecked,
      onSearchTextChange,
      loadMore,
      hasMore: true
    };
    queryObjects();
    const dialogOpts = { plain: true, closeByEscape: true, closeByDocument: true };
    const results = await ywDialog.openConfirm('/view/dialog/checkbox-dialog.html', dialogData, null, dialogOpts);
    // 根據原始資料與結果資料，找出新增與刪除的成員
    const addedMembers = results.filter(id => !oriArr.includes(id));
    const removedMembers = oriArr.filter(id => !results.includes(id));
    return [addedMembers, removedMembers];
  };

  return { openConfirm };
}];
