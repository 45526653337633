import moment from 'moment';

const getContractState = contract => {
  if (!_.get(contract, 'to')) {
    return 'yetSetting';
  }
  return moment(contract.to).isAfter(moment()) ? 'active' : 'expired';
};

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('venders', false, _transformer);
  const Vender = Restangular.service('venders');
  return Vender;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._countStatistics =
      _.get(item, 'countStatistics.monthCount', 0) +
      '/' +
      _.get(item, 'countStatistics.quarterCount', 0) +
      '/' +
      _.get(item, 'countStatistics.yearCount', 0);

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'sortOrder', ''),
      { anchorType: 'labels', labels: item?.labels },
      _.get(item, 'name', ''),
      _.get(item, 'vatNo', ''),
      _.get(item, 'serviceClassifications', []).map(c => c.name).join(','),
      {
        anchorType: 'vender-contract-status',
        state: getContractState(item.contract)
      },
      { anchorType: 'vender-info' },
      {
        anchorType: 'menu-buttons',
        btnList: [
          { name: 'edit', label: '廠商設定' },
          { name: 'viewAccounts', label: '廠商帳號管理' },
          { name: 'venderStaff', label: '服務員帳號管理' },
          { name: 'advancedFilterItem', label: '分類進階篩選設定' }
        ]
      }
    ];

    return item;
  }
}];
