export default ['$scope', 'layoutModuleUtils', 'confirmDialog', 'Restangular', 'ywUtil', 'localStorageService', 'currentUser',
  function controller ($scope, layoutModuleUtils, confirmDialog, Restangular, ywUtil, localStorageService, currentUser) {
    $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      console.log('validator:', item);

      if (!item.name) return $scope.alert('頁面名稱不能為空');
      if (!item.type) return $scope.alert('請選擇平台位置');

      if (isEdit && !item.urlId) return $scope.alert('網址辨識名稱不能移除');
      if (item.urlId && !/^[a-z-0-9_]+$/.test(item.urlId)) return $scope.alert('網址辨識名稱格式不符');

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      if (item.type === 'homepages') {
        item.meta = {
          title: '',
          tag: '',
          tagDescribe: ''
        };
      } else {
        item.meta = {
          title: _.get(item, '_metaTitle', ''),
          tag: _.get(item, '_metaTag', ''),
          tagDescribe: _.get(item, '_metaTagDescribe', '')
        };
      }
      item.modules = $scope.contentManagement.articleModuleArray.map(module => module._id);
      console.log('after transform: ', item);
    }

    function customSave () {
      if ($scope.status.isLoading) return;
      ga('send', 'event', currentUser.getCommName(), 'create', $scope.queryPage + '_create');
      console.log('do save: ');
      try {
        if (!$scope.mainAction._validator()) return Promise.reject(new Error('validate error'));
      } catch (validateError) {
        return Promise.reject(validateError);
      }
      const saveAction = $scope.mainAction._resource.post;
      $scope.status.isLoading = true;
      return $scope._action(saveAction({ ...$scope.currentActionItem }))
        .then(res => $scope.afterSaved({ res: res }))
        .catch(err => {
          if (err.cause?.isThrowVisibleHomepagesError) {
            confirmDialog.openConfirm({
              title: '注意',
              htmlContent: `${err.message.split('，')[0]}<br>${err.message.split('，')[1]}`
            }).then(() => {
              $scope._action(saveAction({ ...$scope.currentActionItem, isForceSettingVisible: true }))
                .then(res => {
                  $scope.afterSaved({ res: res, isRefresh: true });
                })
                .catch((err) => {
                  console.log('save err: ', err.message);
                  $scope.alert(err.message || '發生錯誤');
                })
                .finally(() => $scope.status.isLoading = false); // eslint-disable-line
            });
          } else {
            console.log('save err: ', err.message);
            $scope.alert(err.message || '發生錯誤');
          }
        })
        .finally(() => $scope.status.isLoading = false); // eslint-disable-line
    }

    function customUpdate () {
      if ($scope.status.isLoading) return;
      ga('send', 'event', currentUser.getCommName(), 'edit', $scope.queryPage + '_edit');
      console.log('do update: ');
      try {
        if (!$scope.mainAction._validator()) return Promise.reject(new Error('validate error'));
      } catch (validateError) {
        return Promise.reject(validateError);
      }
      $scope.status.isLoading = true;
      return $scope._action($scope.currentActionItem.put())
        .then(res => $scope.afterUpdate(res))
        .catch(err => {
          if (err.cause?.isThrowVisibleHomepagesError) {
            confirmDialog.openConfirm({
              title: '注意',
              htmlContent: `${err.message.split('，')[0]}<br>${err.message.split('，')[1]}`
            }).then(() => {
              $scope.currentActionItem.isForceSettingVisible = true;
              $scope._action($scope.currentActionItem.put())
                .then(res => $scope.afterUpdate(res, true))
                .catch((err) => {
                  console.log('update err: ', err);
                  $scope.alert(err.message || '發生錯誤');
                })
                .finally(() => $scope.status.isLoading = false); // eslint-disable-line
            });
          } else {
            console.log('update err: ', err);
            $scope.alert(err.message || '發生錯誤');
          }
        })
        .finally(() => $scope.status.isLoading = false); // eslint-disable-line
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';

      $scope.moduleTypes = [];
      $scope.moduleTypesItems = [];
      await layoutModuleUtils.getLayoutModuleTypes($scope);

      if (isEdit) {
        $scope.layout = await Restangular.one('layouts').one(item._id).customGET();
        if (!_.isEmpty($scope.layout)) {
          $scope.layout.modules = $scope.layout.modules.map(module => {
            return {
              ...module,
              typeName: layoutModuleUtils.getLayoutModuleTypeName(module.type)
            };
          });
        }
        item._metaTitle = _.get(item, 'meta.title');
        item._metaTag = _.get(item, 'meta.tag');
        item._metaTagDescribe = _.get(item, 'meta.tagDescribe');
        item._image = item.image;
        item.image = _.get(item, '_image._id');
      }

      $scope.contentManagement = {
        type: 'custom-content',
        searchBarOptions: [
          { btnName: '模組名稱', placeholder: '請輸入模組名稱', params: 'name' },
          { btnName: '模組ID', placeholder: '請輸入模組ID', params: 'moduleId' }
        ],
        searchBarOptionsActive: { btnName: '模組名稱', placeholder: '請輸入模組名稱', params: 'name' },
        customSearchText: '',
        changeSearchBarActiveOption,
        moduleType: {
          options: $scope.moduleTypes
        },
        articleModuleArray: _.isEmpty($scope.layout) ? [] : $scope.layout.modules,
        itemMoved: (index) => $scope.contentManagement.articleModuleArray.splice(index, 1),
        itemRemoved: (index) => $scope.contentManagement.articleModuleArray.splice(index, 1),
        addModule,
        clear,
        onEditClick,
        onCopyIdClick
      };

      $scope.urlIdInput = {
        label: '網址辨識名稱',
        type: 'text',
        class: 'long',
        index: 'urlId',
        info: '由此自定網址名稱，使用全英文小寫或數字命名，可使用"-"及"_"分隔，例 interior-select，不設定則系統預設使用id',
        showIndex: item.type !== 'homepages'
      };

      $scope.metaTitleInput = {
        label: 'Meta Title',
        subLabel: '(建議60字)',
        type: 'text',
        class: 'long',
        index: '_metaTitle',
        showIndex: item.type !== 'homepages'
      };

      $scope.metaTagDescribeInput = {
        label: 'Meta Tag 描述',
        subLabel: '(建議120字內)',
        type: 'textarea',
        class: 'long',
        index: '_metaTagDescribe',
        showIndex: item.type !== 'homepages'
      };

      $scope.metaTagInput = {
        label: 'Meta Tag 關鍵字設定',
        type: 'text',
        class: 'long',
        index: '_metaTag',
        showIndex: item.type !== 'homepages'
      };

      $scope.metaImage = {
        label: 'og:image',
        subLabel: '寬1200 X 高 630\n限制1MB',
        type: 'single-file',
        index: '_image',
        originIndex: 'image',
        class: 'images-wrapper',
        files: $scope.imageFile,
        success: $scope.singleFileUploaded,
        error: $scope.alert,
        validate: { width: { limit: 1200 }, height: { limit: 630 }, limitSize: 1000000 },
        showIndex: item.type !== 'homepages'
      };

      $scope.spaceTypeRadio = {
        label: '間距模式*',
        type: 'radio',
        index: 'spaceType',
        radios: [
          { value: 'small', label: '小間距 (緊湊資訊型)' },
          { value: 'large', label: '大間距 (舒適情境型)' }
        ],
        info: '可針對頁面的屬性挑選模組間距，點擊ICON前往說明指南',
        showIndex: item.type !== 'homepages',
        onInfoClick: () => {
          window.open('https://reurl.cc/5MdWxV', '_blank');
        }
      };

      $scope.inputItems = [
        {
          title: '頁面設定',
          inputs: [
            {
              label: '頁面名稱*',
              type: 'text',
              class: 'long',
              index: 'name',
              info: '不會顯示於前台'
            },
            {
              label: '平台位置*',
              type: 'radio',
              index: 'type',
              radios: [
                { value: 'homepages', label: '首頁', info: '首頁模組區塊位置同時間只能有一個為顯示狀態' },
                { value: 'navigation', label: '大館，檔案位置：/' },
                { value: 'marketing', label: '行銷頁，檔案位置：/mkt/' },
                { value: 'olMarketing', label: '行銷頁(一次載入)，檔案位置：/olmkt/', info: '頁面需要使用到錨點設計時才選擇。元件一次載入，會影響畫面渲染速度。' },
                { value: 'info', label: '其他資訊，檔案位置：/info/' }
              ],
              changed: (value) => {
                $scope.urlIdInput.showIndex = value !== 'homepages';
                $scope.metaTitleInput.showIndex = value !== 'homepages';
                $scope.metaTagInput.showIndex = value !== 'homepages';
                $scope.metaImage.showIndex = value !== 'homepages';
                $scope.metaTagDescribeInput.showIndex = value !== 'homepages';
                $scope.spaceTypeRadio.showIndex = value !== 'homepages';
                item.spaceType = 'large';
              }
            },
            $scope.urlIdInput,
            $scope.metaTitleInput,
            $scope.metaTagInput,
            $scope.metaTagDescribeInput,
            $scope.metaImage,
            {
              label: '顯示設定*',
              type: 'radio',
              index: 'isVisible',
              radios: [
                { value: true, label: '顯示' },
                { value: false, label: '隱藏' }
              ]
            },
            $scope.spaceTypeRadio,
            {
              type: 'action',
              customSave,
              customUpdate
            }
          ]
        },
        {
          title: '內文管理',
          inputs: [
            $scope.contentManagement,
            {
              type: 'action',
              customSave,
              customUpdate
            }
          ]
        }
      ];

      function changeSearchBarActiveOption (currentOption, newOption) {
        $scope.contentManagement.searchBarOptionsActive = newOption;
      }

      async function addModule () {
        function seletAll (isChecked, arr) {
          arr.forEach(item => {
            item.select = isChecked;
          });
        }
        const data = {};
        try {
          $scope.status.isLoading = true;
          data.layoutModuleList = await Restangular.one('layoutModules').get({
            type: $scope.contentManagement.moduleType.select,
            name: $scope.contentManagement.searchBarOptionsActive.params === 'name' ? $scope.contentManagement.customSearchText : undefined,
            moduleId: $scope.contentManagement.searchBarOptionsActive.params === 'moduleId' ? $scope.contentManagement.customSearchText : undefined
          });
          const articleUsedModule = _.isEmpty($scope.contentManagement.articleModuleArray) ? [] : $scope.contentManagement.articleModuleArray.map(articleModule => { return articleModule._id; });
          data.layoutModuleList = _.isEmpty(articleUsedModule) ? data.layoutModuleList : data.layoutModuleList.filter(layoutModule => !articleUsedModule.some((item) => item === layoutModule?._id));
          data.layoutModuleList = data.layoutModuleList.map(module => { return { ...module, typeName: layoutModuleUtils.getLayoutModuleTypeName(module.type) }; });
        } catch (error) {
          console.log('ALERT', error);
          $scope.status.isLoading = false;
          return;
        } finally {
          $scope.status.isLoading = false;
        }
        if (_.isArray(data.layoutModuleList) && data.layoutModuleList.length === 0) return $scope.alert('找不到模組');
        confirmDialog.openConfirm({
          title: '請選擇需要加入頁面的模組',
          templateUrl: '/view/dialog/layout-module-selector.html',
          data,
          seletAll
        }, 'custom-width-800').then(() => {
          data.layoutModuleList.forEach(item => {
            if (item.select) $scope.contentManagement.articleModuleArray.push(item);
          });
        });
      }

      function onEditClick (index) {
        const { _id, type } = $scope.contentManagement.articleModuleArray[index];
        localStorageService.set('module', JSON.stringify({ id: _id, type }));
        window.open('layout-module', '_blank');
      }

      function clear () {
        $scope.contentManagement.moduleType.select = undefined;
        $scope.contentManagement.customSearchText = '';
      }

      function onCopyIdClick (id) {
        ywUtil.cloneText(id);
        $scope.success('已複製');
      }
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
