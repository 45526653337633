/**
 * Main controller which defined variables and functionalities available for all child controllers
 * @class mainController
 */
import Bowser from 'bowser';
export default ['$scope', '$controller', '$anchorScroll', '$q', 'ywPager', 'ywUtil', 'currentUser', 'Restangular', '$state', 'ywEvent', 'confirmDialog', 'firebaseAnalytics', 'lifePageUtil',
  function controller ($scope, $controller, $anchorScroll, $q, ywPager, ywUtil, currentUser, Restangular, $state, ywEvent, confirmDialog, firebaseAnalytics, lifePageUtil) {
    var self = this; // eslint-disable-line

    const browser = Bowser.getParser(window.navigator.userAgent).parsedResult;

    // inherit
    $controller('baseListController', { $scope: $scope });
    $controller('baseActionController', { $scope: $scope });

    $scope.queryList = queryList;
    $scope.tryQuery = function (event) { if (event.keyCode === 13) $scope.queryList($scope.mainAction.queryParams, $scope.mainAction._resource); };
    $scope.catchError = function (err) { console.warn(err); };
    $scope.save = save;
    $scope.update = update;
    $scope.delete = remove;
    $scope.deleteItem = removeItem;
    $scope.toggleSelectAll = toggleSelectAll;
    $scope.clearItems = clearItems;
    $scope.addItemToList = addItemToList;
    $scope.updateItemInList = updateItemInList;
    $scope.removeItemInList = removeItemInList;

    $scope.changePageState = changePageState;

    $scope.mainAction = {};
    $scope.mainAction._validator = function () { return true; }; // to be overwrite
    $scope.mainAction._resource = {}; // to be overwrite
    $scope.mainAction._addItemToList = function () { return false; };
    $scope.currentModuleType = '';

    $scope.mainAction.pager = ywPager;
    $scope.mainAction.queryParams = { limit: ywPager.getPagerLimit() }; // TODO: no need in $scope? better in self?
    $scope.mainAction.initResource = initResource;
    $scope.mainAction.initQuery = initQuery;
    $scope.mainAction.doQuery = doQuery;
    $scope.mainAction.filterQuery = filterQuery;
    $scope.mainAction.updateListIFC = updateListIFC;
    $scope.mainAction.setTableHeadFoot = setTableHeadFoot;
    $scope.mainAction.previewImage = previewImage;
    $scope.mainAction.tryQuery = _.debounce(tryQuery, 500);
    $scope.mainAction.removeFile = removeFile;
    $scope.mainAction.removeSingleFile = removeSingleFile;
    $scope.mainAction.refreshList = refreshList;
    $scope.mainAction.customPagerAction = customPagerAction;
    $scope.mainAction.filterItemChange = filterItemChange;
    $scope.mainAction.filterStartDateChange = filterStartDateChange;
    $scope.mainAction.filterDueDateChange = filterDueDateChange;
    $scope.mainAction.clearFilter = clearFilter;
    $scope.mainAction.setClearFilterTrue = () => { $scope.mainAction.showClearFilter = true; };
    $scope.mainAction.showClearFilter = false;
    $scope.mainAction.handleImportCsvRes = handleImportCsvRes;
    $scope.mainAction.openCompoundSearchTextDialog = openCompoundSearchTextDialog;

    $scope.printform = printform;
    $scope.tableHeadFoot = {};
    console.log('mainAction');
    lifePageUtil.removeViewPortMeta();

    $scope.$on('$locationChangeSuccess', (e) => {
      e.preventDefault();
      lifePageUtil.locationChangeViewPortMeta(window.location.pathname.split('/')[1]);
    });

    $scope.broadcast = function (event) { $scope.$broadcast(event); };

    $scope.showPrevPage = function () { $scope.pageStateHistory.pop(); };
    $scope.initActionItem = function () { $scope.currentActionItem = {}; };

    $scope.onCopyId = (item) => {
      ywUtil.cloneText(item._id);
      $scope.success('已複製');
    };

    const refreshListEvent = ywEvent.register('REFRESH_LIST', Resource => {
      if (!Resource) return;
      refreshList(Resource);
    });
    const updateItemInListEvent = ywEvent.register('UPDATE_ITEM_IN_LIST', updateItemInList);
    const removeItemInListEvent = ywEvent.register('REMOVE_ITEM_IN_LIST', removeItemInList);
    const ifcEventFromReact = ywEvent.register('IFC_EVENT_FROM_REACT', handleIFCEventFromReact);
    $scope.$on('$destroy', () => {
      promiseArray = [];
      ywEvent.unregister(refreshListEvent);
      ywEvent.unregister(updateItemInListEvent);
      ywEvent.unregister(removeItemInListEvent);
      ywEvent.unregister(ifcEventFromReact);
    });

    init();

    // TODO: reset items when cancel

    /// /////
    function init () {
      $scope.watchCurrentPage = $scope.$watch('mainAction.pager.currentPage', pagerUpdated);
      $scope.watchPerPage = $scope.$watch('mainAction.pager.perPage', pagerUpdated);

      Object.defineProperty($scope, 'items', {
        get: function () { return $scope.mainAction.pager._items; }
      });
      Object.defineProperty($scope, 'currentItems', {
        get: function () { return $scope.mainAction.pager.currentItems; }
      });
      Object.defineProperty($scope, 'fullItems', {
        get: function () { return $scope.mainAction.pager._items; },
        set: function (items) { $scope.mainAction.pager._items = items; }
      });
    }

    function customPagerAction (customAction) {
      // unbind pager watch
      $scope.watchCurrentPage();
      $scope.watchPerPage();
      $scope.$watch('mainAction.pager.currentPage', customPagerUpdated);
      $scope.$watch('mainAction.pager.perPage', customPagerUpdated);
      function customPagerUpdated () {
        if (!$scope.mainAction.pager.isSafe) {
          $scope.mainAction.pager.currentPage = 1;
          return $scope.mainAction.pager.currentPage;
        }
        $scope.mainAction.queryParams.skip = $scope.mainAction.pager.skip;
        $scope.mainAction.queryParams.limit = $scope.mainAction.pager.perPage;
        ywPager.setPagerLimit($scope.mainAction.pager.perPage);
        return customAction();
      }
    }

    let promiseArray = [];
    // query action
    function queryList (params, Resource) {
      promiseArray.push(fetchItems(params, Resource));
      return Promise.all(promiseArray).then(items => {
        $scope.status.isLoading = false;
        if (!_.isEmpty(promiseArray)) {
          $scope.mainAction.pager.currentItems = _.last(items);
          $scope.mainAction.updateListIFC();
        }
        promiseArray.shift();
        cancelAllSelection();
      });
    }

    // fetch and append
    function fetchItems (params, Resource) {
      params = params || {};
      $scope.status.isLoading = true;
      console.log('fetchItems params: ', params);
      return new Promise((resolve, reject) => {
        Resource.getList(params).then(items => {
          if (items.length === 0) $scope.alert('沒有更多資料了!');
          resolve(items);
        }, err => {
          $scope.status.isLoading = false;
          $scope.alert(err.data.msg);
          console.log('fetch error', err);
        });
      });
    }

    // do http request
    function save (params) {
      const { customSaveAction, doNothingAfterSave, extraParams, isOnlyExtraParams, isShowPrevPage } = params || {};
      if ($scope.status.isLoading) return;
      ga('send', 'event', currentUser.getCommName(), 'create', $scope.queryPage + '_create');
      console.log('do save: ');
      try {
        if (!$scope.mainAction._validator()) return Promise.reject(new Error('validate error'));
      } catch (validateError) {
        return Promise.reject(validateError);
      }
      const saveAction = customSaveAction || $scope.mainAction._resource.post;
      $scope.status.isLoading = true;
      return $scope._action(saveAction(isOnlyExtraParams ? { ...extraParams } : { ...$scope.currentActionItem, ...extraParams }))
        .then(res => doNothingAfterSave ? Promise.resolve(res) : $scope.afterSaved({ res, isShowPrevPage }))
        .catch(err => {
          console.log('save err: ', err.message);
          $scope.alert(err.message || '發生錯誤');
        })
        .finally(() => $scope.status.isLoading = false); // eslint-disable-line
    }

    $scope.afterSaved = ({ res, isRefresh, isShowPrevPage = true }) => {
      return Promise.resolve(res)
        .then($scope.addItemToList)
        .then(() => {
          $scope.success('完成。');
          $scope.status.isLoading = false;
          if (isShowPrevPage) {
            $scope.showPrevPage();
          }
          isRefresh ? $scope.mainAction.refreshList($scope.mainAction._resource) : $scope.mainAction.updateListIFC();
          return res;
        });
    };

    function addItemToList (res) {
      if ($scope.mainAction._addItemToList(res)) return true;
      $scope.items.unshift(res);
      $scope.mainAction.pager.totalItems++;
      return res;
    }

    function update (params) {
      const { doNothingAfterUpdate, updateFields, apiCategory, isShowPrevPage } = params || {};
      if ($scope.status.isLoading) return;
      ga('send', 'event', currentUser.getCommName(), 'edit', $scope.queryPage + '_edit');
      console.log('do update: ');
      try {
        if (!$scope.mainAction._validator()) return Promise.reject(new Error('validate error'));
      } catch (validateError) {
        return Promise.reject(validateError);
      }
      $scope.status.isLoading = true;

      return $scope._action(updateFields ? Restangular.service(apiCategory).one($scope.currentActionItem._id).customPUT(updateFields) : $scope.currentActionItem.put())
        .then(res => doNothingAfterUpdate ? Promise.resolve(res) : $scope.afterUpdate({ res, isShowPrevPage }))
        .catch(err => {
          console.log('update err: ', err);
          $scope.alert(err.message || '發生錯誤');
        })
        .finally(() => $scope.status.isLoading = false); // eslint-disable-line
    }

    $scope.afterUpdate = ({ res, isRefresh, isShowPrevPage = true }) => {
      return Promise.resolve(res)
        .then(() => {
          $scope.success('完成。');
          $scope.status.isLoading = false;
          console.log('updated: ', res);
          // update the item in table
          const index = $scope.items.findIndex(item => item === $scope.originActionItem);
          $scope.items[index] = res;
          if (isShowPrevPage) {
            $scope.showPrevPage();
          }
          isRefresh ? $scope.mainAction.refreshList($scope.mainAction._resource) : $scope.mainAction.updateListIFC();
          return res;
        });
    };

    function updateItemInList (res) {
      _.forEach($scope.items, (item, i) => {
        if (_.eq(_.get(item, '_id'), _.get(res, '_id'))) {
          $scope.items[i] = res;
          $scope.mainAction.updateListIFC();
        }
      });
      return res;
    }

    function removeItemInList (target) {
      const index = _.findIndex($scope.items, item => item._id === target._id);
      $scope.items.splice(index, 1);
      $scope.mainAction.pager.totalItems -= 1;
      $scope.mainAction.updateListIFC();
    }

    function refreshList (Resource) {
      if (!Resource) return;
      Resource.getList($scope.mainAction.queryParams).then(res => {
        $scope.mainAction.pager.currentItems = res;
        $scope.mainAction.updateListIFC();
      });
    }

    function handleIFCEventFromReact ({ type, payload }) {
      if (type === 'TOGGLE_ALL_ITEMS') {
        $scope.currentItems.forEach(item => {
          item._selected = payload;
        });
        $scope.mainAction.updateListIFC();
      } else if (type === 'TOGGLE_ITEM_SELECTION') {
        $scope.currentItems.forEach(item => {
          if (item._id === payload) {
            item._selected = !item._selected;
          }
        });
        $scope.mainAction.updateListIFC();
      }
    }

    // delete action
    function remove () {
      ga('send', 'event', currentUser.getCommName(), 'delete', $scope.queryPage + '_delete');
      // get selected items
      const selectedItems = $scope.items.filter(function (item) {
        return item._selected;
      });
      if (!selectedItems.length) { return $scope.alert('請選擇要刪除的項目'); }

      return $scope._delete(selectedItems).then(function (ids) {
        if (!ids) return; // handle delete canceled
        // update list in table
        for (let i = $scope.items.length - 1; i > -1; i--) {
          if (ids.indexOf($scope.items[i]._id) > -1) $scope.items.splice(i, 1);
        }
        $scope.mainAction.pager.totalItems -= ids.length;
        $scope.mainAction.updateListIFC();
      }, function () { // canceled, un-select selected items
        selectedItems.forEach(function (item) { item._selected = false; });
      }).catch(err => console.error(err));
    }

    // delete item passed in
    function removeItem (item) {
      console.log('removeItem: ', item);
      return $scope._delete([item]).then(function (ids) {
        // update list in table
        for (let i = $scope.items.length - 1; i > -1; i--) {
          if (ids.indexOf($scope.items[i]._id) > -1) $scope.items.splice(i, 1);
        }
        $scope.mainAction.pager.totalItems -= ids.length;
        return $q.deffer(true).promise;
      }, function () {
        return $q.deffer(false).promise;
      });
    }

    function cancelAllSelection () {
      if ($scope.currentItems && $scope.currentItems.length) {
        $scope.items.map(item => {
          item._selected = false;
          return item;
        });
        $scope.tableHeadFoot.selectAll = false;
      }
    }

    function toggleSelectAll(selectAll) { // eslint-disable-line
      $scope.currentItems.map(function (item) { // eslint-disable-line
        if (item) {
          item._selected = selectAll;
        }
      });
      $scope.mainAction.updateListIFC();
      $scope.mainAction.setTableHeadFoot($scope.tableHeadFoot);
    }

    // sync data to pager
    function pagerUpdated (newValue, oldValue) {
      if (!$scope.mainAction.pager.isSafe) {
        $scope.mainAction.pager.currentPage = 1;
        return $scope.mainAction.pager.currentPage;
      }

      // update query params
      $scope.mainAction.queryParams.skip = $scope.mainAction.pager.skip;
      $scope.mainAction.queryParams.limit = $scope.mainAction.pager.perPage;
      ywPager.setPagerLimit($scope.mainAction.pager.perPage);

      // do query if needed
      if (!$scope.mainAction.pager.isCached() && $scope.mainAction._resource.hasOwnProperty('getList')) { // eslint-disable-line
        queryList($scope.mainAction.queryParams, $scope.mainAction._resource).then($scope.mainAction.updateListIFC);
      } else {
        cancelAllSelection();
        $scope.mainAction.updateListIFC();
      }
    }

    function updateListIFC (data) {
      ywEvent.emit('IFC_EVENT', { type: 'SET_CURRENT_TABLE_LIST', payload: $scope.mainAction.pager.currentItems });
      return Promise.resolve(data);
    }

    function setTableHeadFoot (data) {
      ywEvent.emit('IFC_EVENT', { type: 'SET_TABLE_HEAD_FOOT', payload: $scope.tableHeadFoot });
      return Promise.resolve(data);
    }

    const limit = $scope.mainAction.queryParams.limit;
    $scope.mainAction.queryParams = { skip: 0, limit: limit };

    function initResource (Resource, extraParams, notToQuery, noCount) {
      if (Resource) $scope.mainAction._resource = Resource;
      if (extraParams) { angular.extend($scope.mainAction.queryParams, extraParams); }
      if (notToQuery) { return true; }
      return noCount ? doQueryWithoutGetCount() : doQuery();
    }

    function doQuery () {
      getCount();
      return doQueryWithoutGetCount();
    }

    function initQuery () {
      $scope.mainAction.fullItems = [];
      $scope.mainAction.pager.clear();
      $scope.mainAction.queryParams = Object.assign($scope.mainAction.queryParams, {
        skip: 0,
        limit: $scope.mainAction.queryParams.limit
      });
    }

    function doQueryWithoutGetCount () {
      initQuery();
      return queryList($scope.mainAction.queryParams, $scope.mainAction._resource);
    }

    function getCount () {
      // for pager toolbar
      $scope.mainAction._resource.one('count').get($scope.mainAction.queryParams)
        .then(function (res) {
          if (res && res.error) {
            $scope.alert(res.error);
            $scope.status.isLoading = false;
          } else {
            $scope.mainAction.pager.totalItems = res || 0;
          }
        }).catch(function (err) {
          console.log(err);
        });
    }

    function filterQuery (value, page, name) {
      ga('send', 'event', currentUser.getCommName(), 'filter', page + '_filter_' + name);
      $scope.mainAction.doQuery();
      $scope.mainAction.showClearFilter = true;
      return Promise.resolve(true);
    }

    function previewImage (page) {
      ga('send', 'event', currentUser.getCommName(), 'pic_preview', page + '_pic_preview');
    }

    function tryQuery (event) {
      if (event.keyCode === 13) {
        $scope.mainAction.doQuery();
        $scope.mainAction.showClearFilter = true;
      }
    }

    function removeFile (item, key, paramName, originParamName) {
      confirmDialog.openConfirm({
        title: '刪除',
        content: '確定要刪除?'
      }).then(() => {
        console.log('removeFile: (item, key)', item._id, key);
        item[paramName].splice(key, 1);
        item[originParamName] = item[paramName].map(function (item) { return item._id; });
      });
    }

    function removeSingleFile (item, paramName, originParamName, callback) {
      confirmDialog.openConfirm({
        title: '刪除',
        content: '確定要刪除?'
      }).then(() => {
        console.log('removeSingleFile: (item, paramName, originParamName)', item._id, paramName, originParamName);
        item[paramName] = null;
        item[originParamName] = null;
        if (callback) callback();
      });
    }

    function changePageState (state, item, type = null) {
      console.log('state: ', state);
      if (type) $scope.currentModuleType = type;
      $scope.originActionItem = item;
      $scope.currentActionItem = item.clone();
      $scope.currentPageState = state;
    }
    $scope.$watch('currentPageState', () => {
      if (_.get(browser, 'platform.type') === 'desktop') window.scroll({ top: 0 });
    });

    function printform (id, isCustom = false) {
      ywEvent.emit('IFC_EVENT', { type: 'FORCE_VISIBLE' });
      const printContent = document.getElementById(id); /* 取得要列印的區域 */
      if (!printContent) return;
      const windowUrl = '';
      const uniqueName = new Date();
      const windowName = 'Print' + uniqueName.getTime();
      const printWindow = window.open(windowUrl, windowName);

      printWindow.document.write('<html><head><title>列印</title>');
      printWindow.document.write('<link rel="stylesheet" type="text/css" href="//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.5/css/bootstrap.min.css" media="all">');
      if (isCustom) {
        printWindow.document.write('</head><body><div class="pager-table">');
      } else {
        printWindow.document.write('</head><body><div>');
      }
      printWindow.document.write(printContent.outerHTML);
      printWindow.document.write('</div></body></html>');
      printWindow.document.close();
      printWindow.focus();
      setTimeout(function () {
        printWindow.print();
        printWindow.close();
      }, 500);
    }

    function clearItems () {
      $scope.mainAction.pager._items = [];
      $scope.mainAction.pager.totalItems = 0;
    }

    function filterItemChange (key, value) {
      $scope.mainAction.queryParams[key] = value;
      $scope.mainAction.filterQuery();
    }

    function filterStartDateChange (date) {
      $scope.mainAction.queryParams.createdAfterOrEqualTo = date && ywUtil.toDateStart(date).toISOString();
      $scope.mainAction.filterQuery();
    }

    function filterDueDateChange (date) {
      $scope.mainAction.queryParams.createdBefore = date && ywUtil.toDateEnd(date).toISOString();
      $scope.mainAction.filterQuery();
    }

    function clearFilter (filterKeys, searchBarInput) {
      if (searchBarInput) {
        searchBarInput.multiple = false;
      }
      $scope.mainAction.queryParams = _.pick($scope.mainAction.queryParams, _.concat(['limit', 'skip'], filterKeys));
      _.unset($scope, 'mainAction.customSearchText');
      if (_.get($state, 'params.queryParam')) $state.params.queryParam = undefined;
      $scope.mainAction.doQuery();
      $scope.mainAction.showClearFilter = false;
      $scope.$broadcast('init-date');
      $scope.$broadcast('init-filter');
    }

    function handleImportCsvRes (res, Resource) {
      if (res.headers('lcb-res-version') === 'v3') {
        if (_.get(res, 'data.ok') === false) {
          $scope.alert(_.get(res, 'data.msg', ''), 'fixed');
        } else {
          if (Resource) $scope.mainAction.refreshList(Resource);
          $scope.success(_.get(res, 'data.msg', ''));
        }
      } else {
        if (_.get(res, 'data.result.isSuccess') === false) {
          $scope.alert(_.get(res, 'data.result.errorMessage'), 'fixed');
        } else {
          if (Resource) $scope.mainAction.refreshList(Resource);
          $scope.success('上傳成功');
        }
      }
    }

    function openCompoundSearchTextDialog (searchBarOptions, searchBarInput, ctrlScope) {
      const currentInputFields = (searchBarOptions || [])
        .filter(option => !_.isNil($scope.mainAction.queryParams[option.params]))
        .map(option => ({ ...option, value: $scope.mainAction.queryParams[option.params] }));
      const textInputs = currentInputFields.length
        ? currentInputFields
        : [_.clone(_.head(searchBarOptions)), _.clone(_.nth(searchBarOptions, 1))];

      const dialogData = {
        title: '複合式分類設定',
        templateUrl: '/view/dialog/compound-search-text-dialog.html',
        disableConfirm: (textInputs.length < 2),
        searchBarOptions: searchBarOptions,
        textInputs: textInputs,
        onAddTextInput: () => {
          const rest = _.differenceWith(searchBarOptions, dialogData.textInputs, (a, b) => a.params === b.params);
          if (rest.length) {
            dialogData.textInputs.push(_.clone(_.head(rest)));
          }
          dialogData.disableConfirm = false;
        },
        onRemoveTextInput: (index) => {
          dialogData.textInputs.splice(index, 1);
          dialogData.disableConfirm = dialogData.textInputs.length < 2;
        },
        onChangeSearchBarOption: (index, targetOption) => {
          if (!dialogData.textInputs.find(option => option.params === targetOption.params)) {
            dialogData.textInputs[index] = { ...targetOption, value: dialogData.textInputs[index].value };
          }
        },
        customLeftBtn: [
          {
            showIndex: true,
            label: '恢復預設搜尋',
            cb: (value, dialogData, closeDialog) => {
              searchBarOptions.forEach(option => _.unset($scope.mainAction.queryParams, option.params));
              $scope.mainAction.customSearchText = '';
              searchBarInput.multiple = false;
              closeDialog();
            }
          }
        ]
      };
      confirmDialog.openConfirm(dialogData).then(() => {
        searchBarOptions.forEach(option => {
          const textInput = dialogData.textInputs.find(input => input.params === option.params);
          if (textInput && textInput.value) {
            $scope.mainAction.queryParams[textInput.params] = textInput.value || '';
          } else {
            _.unset($scope.mainAction.queryParams, option.params);
          }
        });

        const validInputs = dialogData.textInputs.filter(textInput => textInput.value);
        const isMultiple = validInputs.length > 1;
        searchBarInput.multiple = isMultiple && {
          btnName: validInputs.map(input => input.btnName).join(','),
          text: validInputs.map(input => input.value).join(',')
        };

        $scope.mainAction.showClearFilter = true;
      });
    }
  }
];
