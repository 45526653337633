export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('unorderedReservations', false, _transformer);
  const UnorderedReservation = Restangular.service('unorderedReservations');
  return UnorderedReservation;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
    ];

    return item;
  }
}];
