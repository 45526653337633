import moment from 'moment';

export default ['$scope', '$location', '$stateParams', '$async', 'ywUtil', 'currentUser', 'Coupon', 'API_URL', 'confirmDialog',
  function controller ($scope, $location, $stateParams, $async, ywUtil, currentUser, Coupon, API_URL, confirmDialog) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.filterItems = [
      {
        name: 'state',
        label: '活動狀態',
        options: [
          { value: 'yetToStart', label: '未開始' },
          { value: 'inProgress', label: '進行中' },
          { value: 'end', label: '已結束' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'noOwnAttrSelect'
      },
      {
        name: 'manualLoseEffective',
        label: '優惠碼刪除狀態',
        options: [
          { value: false, label: '未刪除' },
          { value: true, label: '已刪除' }
        ],
        changed: $scope.mainAction.setClearFilterTrue,
        type: 'noOwnAttrSelect'
      }
    ];

    /* Order & date filter */
    $scope.customizeContentTemplate = '/view/template/coupon-filter.html';
    $scope.extraFilter = {
      durationType: {
        options: [
          { value: 'createdTime', label: '依照建立時間' },
          { value: 'visibilityPeriod', label: '依照活動時間' }
        ],
        selected: 'createdTime'
      },
      startDate: {
        value: undefined,
        changed: (date) => {
          $scope.extraFilter.startDate.value = moment(date).startOf('day').toDate();
        }
      },
      dueDate: {
        value: undefined,
        changed: (date) => {
          $scope.extraFilter.dueDate.value = moment(date).endOf('day').toDate();
        }
      }
    };
    const resetExtraParams = () => {
      $scope.extraFilter.durationType.selected = 'createdTime';
    };

    $scope.customClearFilter = () => {
      resetExtraParams();
      $scope.mainAction.clearFilter();
    };
    $scope.customFilterQuery = () => {
      const startDate = $scope.extraFilter.startDate.value;
      const dueDate = $scope.extraFilter.dueDate.value;
      if ($scope.extraFilter.durationType.selected === 'createdTime') {
        delete $scope.mainAction.queryParams.startTime;
        delete $scope.mainAction.queryParams.endTime;
        $scope.mainAction.queryParams.createdAfterOrEqualTo = startDate && moment($scope.extraFilter.startDate.value).startOf('day').toDate();
        $scope.mainAction.queryParams.createdBeforeOrEqualTo = dueDate && moment($scope.extraFilter.dueDate.value).endOf('day').toDate();
      } else if ($scope.extraFilter.durationType.selected === 'visibilityPeriod') {
        delete $scope.mainAction.queryParams.createdAfterOrEqualTo;
        delete $scope.mainAction.queryParams.createdBeforeOrEqualTo;
        $scope.mainAction.queryParams.startTime = startDate && moment($scope.extraFilter.startDate.value).startOf('day').toDate();
        $scope.mainAction.queryParams.endTime = dueDate && moment($scope.extraFilter.dueDate.value).endOf('day').toDate();
      }
      $scope.mainAction.filterQuery();
    };

    $scope.searchBarOptions = [
      {
        btnName: '優惠碼',
        placeholder: '請輸入優惠碼',
        params: 'code'
      },
      {
        btnName: '活動主題',
        placeholder: '請輸入優惠碼',
        params: 'subjectName'
      },
      {
        btnName: '優惠名稱',
        placeholder: '請輸入優惠碼',
        params: 'title'
      },
      {
        btnName: '會員ID',
        placeholder: '請輸入會員ID',
        params: 'userId'
      },
      {
        btnName: '會員認證手機',
        placeholder: '請輸入會員認證手機',
        params: 'phone'
      },
      {
        btnName: '優惠活動ID',
        placeholder: '請輸入優惠活動ID',
        params: 'couponEventId'
      }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    $scope.dropdownItemList = [{
      label: '下載',
      items: [
        { buttonListText: '優惠碼與歸戶狀況', class: 'fab fa-apple', callback: _.partial(exportCsv, 'allCouponUsageAndInfo', 'utf8') },
        { buttonListText: '優惠碼與歸戶狀況', class: 'fab fa-windows', callback: _.partial(exportCsv, 'allCouponUsageAndInfo', 'big5') },
        { buttonListText: '優惠碼', class: 'fab fa-apple', callback: _.partial(exportCsv, 'couponInfo', 'utf8') },
        { buttonListText: '優惠碼', class: 'fab fa-windows', callback: _.partial(exportCsv, 'couponInfo', 'big5') },
        { buttonListText: '歸戶狀況', class: 'fab fa-apple', callback: _.partial(exportCsv, 'couponOwnerRecordUsageStatus', 'utf8') },
        { buttonListText: '歸戶狀況', class: 'fab fa-windows', callback: _.partial(exportCsv, 'couponOwnerRecordUsageStatus', 'big5') }
      ]
    }];

    function exportCsv (mode, encodeType, customParam = {}) {
      const { limit, skip, where, ...rest } = $scope.mainAction.queryParams;
      const params = Object.assign({ encodeType, mode }, { ...rest, ...customParam });
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = API_URL + 'coupons/codeCsv?access_token=' + currentUser.getToken();

      const input = document.createElement('input');
      input.type = 'checkbox';
      input.name = 'isCouponUsageStatus';
      input.value = true;
      form.appendChild(input);

      Object.keys(params)
        .filter(key => !_.isNil(params[key]))
        .forEach(key => {
          const input = document.createElement('input');
          input.type = _.cond([
            [type => type === 'string', () => 'text'],
            [type => type === 'boolean', () => 'checkbox']
          ])(typeof params[key]);
          input.name = key;
          input.value = params[key];
          if (input.type === 'checkbox') {
            input.checked = true;
          }
          form.appendChild(input);
        });
      document.body.appendChild(form);
      form.submit();
      form.remove();
    }

    $scope.openCouponUsageDetailDialog = $async(async (coupon) => {
      const dialogData = {
        title: '使用資訊',
        templateUrl: '/view/dialog/coupon-usage-detail.html',
        hideCancel: true,
        details: [],
        onUserIconClick: (text) => {
          ywUtil.cloneText(text);
          $scope.success('已複製');
        },
        exportCsv: (encodeType) => exportCsv(encodeType, { code: coupon.code })
      };

      // load data
      const res = await Coupon.one(coupon._id).customGET('ownerInfos');
      dialogData.details = res.data;

      await confirmDialog.openConfirm(dialogData, 'custom-width-1024');
    });

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.searchBar.hideSearchAndClearBtn = true;
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'coupon'; // for GA

      const { userId, couponEventId } = $location.search();
      if (userId) {
        $scope.customTextChangeCb(userId);
        $scope.mainAction.customSearchText = userId;

        const newOption = _.find($scope.searchBarOptions, option => option.params === 'userId');
        changeSearchBarActiveOption($scope.searchBarOptionsActive, newOption);
      } else if (couponEventId) {
        $scope.customTextChangeCb(couponEventId);
        $scope.mainAction.customSearchText = couponEventId;

        const newOption = _.find($scope.searchBarOptions, option => option.params === 'couponEventId');
        changeSearchBarActiveOption($scope.searchBarOptionsActive, newOption);
      }
      $scope.mainAction.initResource(Coupon, { manualLoseEffective: false });

      const clearFilter = $scope.mainAction.clearFilter;
      $scope.mainAction.clearFilter = () => clearFilter(['manualLoseEffective'], $scope.searchBar.textInput);
    });

    init();

    $scope.delete = function () {
      const selectedItems = $scope.items.filter(function (item) {
        return item._selected;
      });
      if (!selectedItems.length) { return $scope.alert('請選擇要作廢的項目'); }

      return $scope._makeVoid(selectedItems).then(function (ids) {
        // find item and mark red.
        _.forEach(ids, function (id) {
          const selectItem = _.find($scope.items, function (o) {
            return o._id === id;
          });
          selectItem._columns._deleted = true;
          selectItem._columns._green = false;
          selectItem._columns._red = true;
        });
      }, function () {
        // canceled, un-select selected items
        selectedItems.forEach(function (item) { item._selected = false; });
      });
    };

    function initActionItem () {
      $scope.currentActionItem = {
        _downloadNow: true
      };
    }
  }];
