export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <table class="table">
      <tr>
        <th>
          廠商備註圖(僅供後台查看)
          <span
            class="glyphicon glyphicon-trash"
            ng-click="$ctrl.removeMemoImageConfirm($ctrl.reservation)"
            style="float: right;"
          ></span>
          <span
            class="glyphicon glyphicon-plus"
            ng-click="$ctrl.addMemoImageConfirm($ctrl.reservation)"
            style="float: right;"
          ></span>
        </th>
      </tr>
      <tr>
        <td
          ng-if="$ctrl.reservation.memoImages.length"
          style="text-align: left;"
        >
          <span
            ng-repeat="image in $ctrl.reservation.memoImages track by $index"
            style="display: inline-block; margin-right: 7px; position: relative;"
          >
            <img
              uib-tooltip="點擊看原始圖片"
              class="ptr"
              ng-src="{{image.thumbImage.s3Url}}"
              style="width: 100px; height: 100px; object-fit: cover;"
              ng-click="$ctrl.showOriginImage($index)"
            />
            <md-checkbox ng-model="image._checked" class="remove-checkbox" style="position: absolute; top: -8px; right: 0;"></md-checkbox>
          </span>
        </td>
      </tr>
    </table>
  `,
  controller: ['$element', '$async', 'confirmDialog', 'imageUploadDialog', 'ywEvent', 'Reservation', 'UnorderedReservation', function reservationVenderImage ($element, $async, confirmDialog, imageUploadDialog, ywEvent, Reservation, UnorderedReservation) {
    this.$onInit = () => {
      const mode = _.get(this.reservation, 'reservationListMode');
      this._resource = mode === 'stateUnordered' ? UnorderedReservation : Reservation;
    };

    this.showOriginImage = (index) => {
      const imageUrls = this.reservation.memoImages.map(img => img.url);
      ywEvent.emit('OPEN_IMAGE_VIEWER', { imageUrls, index });
    };

    this.removeMemoImageConfirm = $async(async (reservation) => {
      if (!reservation.memoImages?.length ||
        !reservation.memoImages.filter(image => {
          return !!image._checked;
        })?.length
      ) {
        return ywEvent.emit('ALERT', '請選擇要刪除的備註圖片');
      }

      await confirmDialog.openConfirm({ content: '確定刪除已選擇的廠商備註圖?' });
      const res = await this._resource.one(reservation._id).customPUT({
        imageIds: reservation.memoImages
          .filter(img => img._checked).map(img => img._id),
        action: 'delete'
      }, 'memoImages');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '刪除失敗', content: res.error });
      } else {
        reservation.memoImages = res.memoImages.filter(img => !img._checked);
        ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
        ywEvent.emit('SUCCESS', '刪除完成');
      }
    });

    this.addMemoImageConfirm = $async(async (reservation) => {
      const images = await imageUploadDialog.openConfirm({ title: '上傳備註圖' });

      const res = await this._resource.one(reservation._id).customPUT({
        imageIds: images.map(image => image._id),
        action: 'upload'
      }, 'memoImages');
      if (res.error) {
        ywEvent.emit('ALERT', res.error);
      } else {
        reservation.memoImages = res.memoImages;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
        ywEvent.emit('SUCCESS', '已上傳');
      }
    });
  }]
};
