export default {
  bindings: {
    reservation: '<',
    onChangeQuotationPaymentState: '&'
  },
  template: `
    <table class="table">
      <tr>
        <th>分類/服務方案</th>
        <th style="text-align: right;" ng-if="$ctrl.reservation.isStandard">數量</th>
        <th style="text-align: right;" ng-if="$ctrl.reservation.isStandard">單價</th>
        <th style="text-align: right;" ng-if="$ctrl.reservation.isStandard">小計</th>

        <th
          ng-if="!$ctrl.reservation.isStandard && $ctrl.reservation.quotation.paymentStageSettings.length === 1"
        ></th>
        <th
          ng-if="!$ctrl.reservation.isStandard && $ctrl.reservation.quotation.paymentStageSettings.length !== 1"
          colspan="2"
        ></th>
        <th style="text-align: right;" ng-if="!$ctrl.reservation.isStandard">總價</th>
        <th
          style="text-align: right;"
          ng-if="!$ctrl.reservation.isStandard && $ctrl.reservation.quotation.paymentStageSettings.length === 1"
        >
          付款狀態
        </th>
      </tr>
      <tr ng-repeat="service in $ctrl.reservation.services track by $index">
        <td style="text-align: left;">
          {{$ctrl.reservation.serviceClassificationName}}
          /
          {{service.serviceId.name}}
          <div ng-if="service.basicPrice !== service.price">
            <div>一般價格：{{service.basicPrice}}</div>
            <div>
              {{service.basicPrice > service.price ? '活動優惠：' : '假日加價：'}}
              {{service.price - service.basicPrice}}
            </div>
          </div>
        </td>
        <td style="text-align: right; white-space: nowrap;" ng-if="$ctrl.reservation.isStandard">
          X{{service.count}}
        </td>
        <td style="text-align: right; white-space: nowrap;" ng-if="$ctrl.reservation.isStandard">
          {{'$'}}{{service.price}}
        </td>

        <td
          ng-if="!$ctrl.reservation.isStandard && $ctrl.reservation.quotation.paymentStageSettings.length === 1"
        ></td>
        <td
          ng-if="!$ctrl.reservation.isStandard && $ctrl.reservation.quotation.paymentStageSettings.length !== 1"
          colspan="2"
        ></td>
        <td style="text-align: right; white-space: nowrap;" ng-if="$ctrl.reservation.isStandard">
          {{'$'}}{{service.price * service.count}}
        </td>
        <td style="text-align: right; white-space: nowrap;" ng-if="!$ctrl.reservation.isStandard">
          {{$ctrl.reservation.quotation ? $ctrl.reservation.quotation.total : '需評估後報價'}}
        </td>
        <td
          style="text-align: right;"
          ng-if="!$ctrl.reservation.isStandard && $ctrl._quotationPaymentStages.length === 1"
        >
          <md-button
            class="md-raised md-primary fit"
            ng-if="$ctrl._quotationPaymentStages[0].order.state === 'pending' && $ctrl._quotationPaymentStages[0].payable"
            ng-click="$ctrl.onPaidClick($ctrl._quotationPaymentStages[0], 'paid')"
          >
            待付款
          </md-button>
          <div ng-if="$ctrl._quotationPaymentStages[0].order.state === 'pending' && !$ctrl._quotationPaymentStages[0].payable">
            待付款
          </div>
          <div ng-if="$ctrl._quotationPaymentStages[0].order.state === 'paid'">
            已付款
            {{$ctrl._quotationPaymentStages[0].order.stateLogs[0].at | date: 'yyyy/MM/dd HH:mm'}}
          </div>
          <div ng-if="$ctrl._quotationPaymentStages[0].order.state === 'refund'">已退款</div>
        </td>
      </tr>

      <tr ng-repeat="customization in $ctrl.normalCustomizations track by $index">
        <td style="text-align: left;">
          {{customization.title + '：' + customization.name}}
          <span ng-if="customization.customizedValue">，{{customization.customizedValue}}</span>
        </td>
        <td style="text-align: right; white-space: nowrap;">
          <span>X{{customization.count}}</span>
        </td>
        <td style="text-align: right; white-space: nowrap;">
          <span ng-if="$ctrl.reservation.isStandard">{{'$'}}{{customization.addCost}}</span>
        </td>
        <td style="text-align: right; white-space: nowrap;">
          <span ng-if="$ctrl.reservation.isStandard">{{'$'}}{{customization.subtotal}}</span>
        </td>
      </tr>
      <tr ng-repeat="customizations in $ctrl.customizedInputCustomizationLists track by $index">
        <td style="text-align: left;">
          {{customizations[0].title}}：
          <span ng-repeat="customization in customizations track by $index">
            {{$index > 0 ? ',' : ''}}
            {{customization.name}}
            <span ng-if="customization.expandedItem === 'word'">{{customization.customizedValue}}</span>
            <span ng-if="customization.expandedItem === 'date'">
              {{customization.customizedValue | date: 'yyyy/MM/dd'}}
            </span>
          </span>
        </td><td>
        </td><td>
        </td><td>
        </td>
      </tr>

      <tr ng-if="$ctrl.reservation.discount">
        <td style="text-align: left;">
          {{$ctrl.reservation.discount.title}}
          <div ng-repeat="text in $ctrl._coupon._remindToVender track by $index" class="color-red">
            {{$index === 0 ? '廠商注意事項：' : ''}}
            {{text}}
          </div>
        </td>
        <td></td>
        <td></td>
        <td style="text-align: right; white-space: nowrap;">
          {{'-$'}}{{-$ctrl.reservation.discount.value}}
        </td>
      </tr>
      <!-- 非規格訂單未必會有discount -->
      <tr ng-if="!$ctrl.reservation.discount && $ctrl.reservation.originRef.coupon">
        <td style="text-align: left;">
          {{'優惠碼-'}}{{$ctrl.reservation.originRef.coupon.title}}
          <div ng-repeat="text in $ctrl._coupon._remindToVender track by $index" class="color-red">
            {{text}}
          </div>
        </td>
        <td></td>
        <td></td>
        <td style="text-align: right;">
          <span style="white-space: nowrap;" ng-if="$ctrl.reservation.originRef.coupon.discountValueType === 'number'">
            {{'-$'}}{{-$ctrl.reservation.originRef.coupon.discountValue}}
          </span>
          <span style="white-space: nowrap;" ng-if="$ctrl.reservation.originRef.coupon.discountValueType === 'percentage' && $ctrl.reservation.paymentStageSetting['3'].order">
            {{'-$'}}{{$ctrl.reservation.paymentStageSetting['3'].order.subtotal - ($ctrl.round($ctrl.reservation.paymentStageSetting['3'].order.subtotal * $ctrl.reservation.originRef.coupon.discountValue / 100))}}
          </span>
        </td>
      </tr>
    </table>

    <table class="table" ng-if="!$ctrl.reservation.isStandard && $ctrl._quotationPaymentStages.length > 1">
      <tr>
        <th>款項性質</th>
        <th style="text-align: right;">收款名目</th>
        <th style="text-align: right;">金額</th>
        <th style="text-align: right;">通知付款日期</th>
        <th style="text-align: right;">付款狀態</th>
      </tr>
      <tr ng-repeat="paymentStage in $ctrl._quotationPaymentStages track by $index">
        <td style="text-align: left;">
          <div ng-if="paymentStage.type === 'deposit'">訂金</div>
          <div ng-if="paymentStage.type === 'partial'">中間款</div>
          <div ng-if="paymentStage.type === 'final'">尾款</div>
        </td>
        <td style="text-align: right;">{{paymentStage.name}}</td>
        <td style="text-align: right;">{{paymentStage.amount}}</td>
        <td style="text-align: right;">{{paymentStage.reminder | date: 'yyyy/MM/dd'}}</td>
        <td style="text-align: right;">
          <md-button
            class="md-raised md-primary fit"
            ng-if="paymentStage.order.state === 'pending' && paymentStage.payable"
            ng-click="$ctrl.onPaidClick(paymentStage, 'paid')"
          >
            待付款
          </md-button>
          <div ng-if="paymentStage.order.state === 'pending' && !paymentStage.payable">
            待付款
          </div>
          <div ng-if="paymentStage.order.state === 'paid'">
            已付款
            {{paymentStage.order.stateLogs[0].at | date: 'yyyy/MM/dd HH:mm'}}
          </div>
          <div ng-if="paymentStage.order.state === 'refund'">已退款</div>
        </td>
      </tr>
      </tr>
      </tr>
      </tr>
    </table>
  `,
  controller: ['$element', '$async', 'Coupon', function reservationSinyiStaffInfo ($element, $async, Coupon) {
    const getDisplayedQoutationPaymentStages = (reservation) => {
      return _.get(reservation, 'quotation.paymentStageSettings', [])
        .sort((a, b) => {
          const typeMap = { deposit: 0, partial: 1, final: 2 };
          return typeMap[a.type] - typeMap[b.type];
        })
        .map(stateStage => {
          return _.cond([
            [
              (type) => type === 'deposit',
              () => ({
                ...stateStage,
                order: _.get(reservation, 'paymentStageSetting["1"].order'),
                accountsPayable: _.get(reservation, 'paymentStageSetting["1"].accountsPayable'),
                payable: reservation.state === 'pending-ps1' &&
                  _.includes(['cash', 'bankTransfer', 'branchBudget'], _.get(reservation, 'paymentStageSetting["1"].validMethods[0]'))
              })
            ],
            [
              (type) => type === 'partial',
              () => ({
                ...stateStage,
                order: _.get(reservation, 'paymentStageSetting["2"].order'),
                accountsPayable: _.get(reservation, 'paymentStageSetting["2"].accountsPayable'),
                payable: reservation.state === 'pending-ps2' &&
                  _.includes(['cash', 'bankTransfer', 'branchBudget'], _.get(reservation, 'paymentStageSetting["2"].validMethods[0]'))
              })
            ],
            [
              (type) => type === 'final',
              () => ({
                ...stateStage,
                order: _.get(reservation, 'paymentStageSetting["3"].order'),
                accountsPayable: _.get(reservation, 'paymentStageSetting["3"].accountsPayable'),
                payable: reservation.state === 'pending-ps3'
              })
            ],
            [_.stubTrue, () => stateStage]
          ])(stateStage.type);
        });
    };

    const resetData = $async(async () => {
      this._quotationPaymentStages = getDisplayedQoutationPaymentStages(this.reservation);

      let coupon = null;
      if (_.get(this.reservation, 'originRef.coupon._id')) {
        coupon = await Coupon.one(_.get(this.reservation, 'originRef.coupon._id')).customGET();
      }
      this._coupon = {
        ...coupon,
        _remindToVender: _.get(coupon, 'remind.toVender', '')
          .split('\n')
          .filter(remind => remind)
      };

      this.normalCustomizations = this.reservation.customizations
        .filter((item) => !item.expandedItem);
      const customizationsWithoutNumbers = this.reservation.customizations
        .filter((item) => item.expandedItem);
      this.customizedInputCustomizationLists = Object.values(_.groupBy(
        customizationsWithoutNumbers,
        (item) => item.customizationId
      ));
    });

    this.onPaidClick = (paymentStage, newState) => {
      this.onChangeQuotationPaymentState({ paymentStage, newState });
    };

    this.round = Math.round;

    this.$onInit = resetData;
    this.$onChange = resetData;
  }]
};
