export default {
  bindings: {
    hideFooter: '<',
    leftButton: '<',
    rightButton: '<',
    isFullWidthPage: '<', // 是否為整頁 or 半頁式 dialog
    ngDialogData: '<',
    confirm: '&',
    closeThisDialog: '&'
  },
  template: `
    <div class="dialog-footer" ng-class="{'full-width-button': $ctrl.isFullWidthPage}" ng-if="!$ctrl.hideFooter">
      <div ng-if="!!$ctrl.rightButton" class="footer-button-set" ng-class="{'full-width-set': $ctrl.isFullWidthPage}">
        <life-button
          ng-if="!!$ctrl.rightButton" 
          ng-repeat="button in $ctrl.rightButton track by $index"
          size="'md'" 
          label="button.label" 
          color="button.color" 
          type="button.type"
          is-full-width="$ctrl.isFullWidthPage"
          ng-style="{'width': '100%'}"
          ng-click="button.onClick($ctrl.ngDialogData, $ctrl.confirm, $ctrl.closeThisDialog)"
        />
      </div>
      <div ng-if="!!$ctrl.leftButton" class="footer-button-set" ng-class="{'full-width-set': $ctrl.isFullWidthPage}">
        <life-button 
          ng-repeat="button in $ctrl.leftButton track by $index"
          size="'md'" 
          label="button.label" 
          color="button.color" 
          type="button.type"
          is-full-width="$ctrl.isFullWidthPage"
          ng-style="{'width': '100%'}"
          ng-click="button.onClick($ctrl.ngDialogData, $ctrl.confirm, $ctrl.closeThisDialog)"
        />
      </div>
      <div ng-if="$ctrl.selectCount">{{$ctrl.selectCount}}</div>
    </div>
  `
};
