export default ['$scope', '$async', 'Upload', 'API_URL', 'currentUser', 'ywUtil',
  function controller ($scope, $async, Upload, API_URL, currentUser, ywUtil) {
    const self = this;

    self.files = [];
    self.uploaded = $scope.imageUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      const partialPaymentHasNoReminder = item.paymentStageSettings
        .some(setting => setting.type === 'partial' && !setting.reminder);
      if (partialPaymentHasNoReminder) {
        return $scope.alert('中間款的通知付款日期為必填');
      }

      console.log('currentActionItem: ', item);
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;

      if ($scope.currentPageState === 'add') {
        item.attachments = item._attachmentObjs.map(file => ({
          name: file.originalFilename || file.name,
          fileId: file._id
        }));
      } else if ($scope.currentPageState === 'edit') {
        item.attachments = item._attachmentObjs.map(file => ({
          name: file.originalFilename || file.name,
          fileId: _.get(file, 'file._id', file._id)
        }));
      }

      console.log('transform finished: ', item);
    }

    function syncCurrentActionItem () {
      const item = $scope.currentActionItem;

      // 以下是變數命名習慣
      // 沒有底線的是直接從後端拿到的資料
      // 有底線的則是我們需要處理會用到的
      if ($scope.currentPageState === 'edit') {
        item._attachmentObjs = item.attachments || [];
        item._attachments = item._attachmentObjs.map(file => _.get(file, 'file._id'));
      }

      const typeOptions = [
        { value: 'deposit', label: '訂金' },
        // { value: 'partial', label: '中間款' }, // 暫時不用，先不允許設定
        { value: 'final', label: '尾款' }
      ];

      $scope.inputItems = [
        { label: '總價', type: 'number', index: 'total' },
        {
          label: '',
          type: 'payment-stage-settings',
          index: 'paymentStageSettings',
          typeOptions,
          typeChanged: (type, index) => {
            console.log('type: ', type);
            // 當款項性質改變，自動帶入收款名目
            item.paymentStageSettings[index].name = !type ? null : typeOptions.find(option => option.value === type).label;
          },
          addOne: () => {
            item.paymentStageSettings.push({});
          },
          removeOne: (index) => {
            item.paymentStageSettings.splice(index, 1);
          }
        },
        { type: 'empty' },
        {
          label: '',
          type: 'document-upload',
          index: '_attachments',
          objIndex: '_attachmentObjs',
          files: self.files,
          success: $scope.fileUploaded,
          error: $scope.alert,
          removeOne: (index) => {
            item._attachmentObjs.splice(index, 1);
            item._attachments.splice(index, 1);
          }
        },
        { type: 'empty' },
        { label: '內部備註', type: 'textarea', index: 'note' },
        { type: 'action' }
      ];
    }
  }];
