import React from 'react';

import { findStateIndex } from './../../share';

const style = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap'
  }
};

const completedIndex = findStateIndex('completed');

const StaffAssigner = ({ reservation, onEditStaff }) => {
  const staffs = _.get(reservation, 'venderStaffs', [])
    .map(staff => staff.displayName)
    .join(',');

  const hideControl = findStateIndex(reservation.state) >= completedIndex;

  return (
    <div style={style.root}>
      <span>{staffs || '未指派'}</span>
      {!hideControl &&
        <span
          className='img icon-list-edit ptr'
          onClick={() => onEditStaff(reservation)}
        />}
    </div>
  );
};

export default StaffAssigner;
