export default ['session', 'Upload', 'API_URL', 'ywEvent', function directive (session, Upload, API_URL, ywEvent) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      success: '&',
      error: '&',
      size: '=',
      ratio: '='
    },
    template: `
    <!-- loading widgets -->
    <span class="cell" ng-if="progress >= 0 && progress !== 100">
        <img src="/img/loading.gif" class="loading"></img>
        <span class="progressbar">{{progress}}%</span>
    </span>
    <div class="cropArea">
      <div ng-hide="croppedImage">
        <span type="file" ngf-select="handleFileSelect($files)" ngf-pattern="'.png,.jpg,.jpeg'" class="cell">
          <img src="/img/btn_form_photo_p.png" />
        </span>
      </div>
      <span ng-if="sourceImage">
        <ui-cropper image="sourceImage"
                    area-type="rectangle"
                    chargement="'Loading'"
                    result-image="croppedImage"
                    canvas-scalemode="true"
                    aspect-ratio="ratio || 1"
                    area-init-size="size"
                    area-min-relative-size="size"
                    result-image-size="size"
                    on-change="croppedImageChange($dataURI)">
        </ui-cropper>
      </span>
      <div ng-if="croppedImage">
        <div>圖片預覽:</div>
        <img ng-src="{{croppedImage}}" />
        <button ng-click="cancelSelect()" class="yw-btn yw-btn-orange">取消</button>
        <button ng-click="confirmImgUpload(croppedImage)" class="yw-btn yw-btn-blue" ng-disabled="isUpdating">確認裁切</button>
      </div>
    </div>`,
    link: link
  };

  function link (scope, element, attr) {
    scope.sourceImage = '';
    scope.croppedImage = '';
    scope.isUpdating = false;

    scope.handleFileSelect = function (evt) {
      if (!evt) return;
      const file = evt[0];
      if (file.$error) return ywEvent.emit('ALERT', '請選擇圖片！');
      var reader = new FileReader(); // eslint-disable-line
      reader.onload = function (evt) {
        scope.$apply(function (scope) {
          scope.sourceImage = evt.target.result;
        });
      };
      reader.readAsDataURL(file);
    };

    scope.croppedImageChange = function (dataURI) {
      scope.croppedImage = dataURI;
    };

    scope.cancelSelect = function () {
      scope.sourceImage = '';
      scope.croppedImage = '';
    };

    scope.confirmImgUpload = function (croppedImage) {
      scope.isUpdating = true;
      const upload = Upload.upload({
        url: API_URL + 'files',
        data: { file: dataUrltoBlob(croppedImage) }
      });
      upload.then(successCallback, errorCallback, progressCallback);

      /** upload callbacks */
      function successCallback (response) {
        scope.sourceImage = '';
        scope.croppedImage = '';
        scope.isUpdating = false;
        scope.success({ result: response.data.result.result });
      }

      function errorCallback (response) {
        scope.isUpdating = false;
        console.warn('upload error: ', response);
        scope.error(response);
      }

      function progressCallback (evt) {
        scope.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
      }
    };

    function dataUrltoBlob (dataURI) {
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]); // eslint-disable-line
      } else {
        byteString = decodeURI(dataURI.split(',')[1]);
      }
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const array = [];
      for (let i = 0; i < byteString.length; i++) {
        array.push(byteString.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: mimeString }); // eslint-disable-line
    }
  }
}];
