import firebase from 'firebase/app';
import 'firebase/analytics';

export default function () {
  if (__NODE_ENV__ === 'prod') {
    firebase.initializeApp({
      apiKey: 'AIzaSyCsmKU-vfA_zAFZV21jE-oe2Xx5zTiEsWU',
      authDomain: 'yw-cloudcoummunity.firebaseapp.com',
      databaseURL: 'https://yw-cloudcoummunity.firebaseio.com',
      projectId: 'yw-cloudcoummunity',
      storageBucket: 'yw-cloudcoummunity.appspot.com',
      messagingSenderId: '992900994730',
      appId: '1:992900994730:web:3365324f6d92c8c510e2d3',
      measurementId: 'G-NJPC0QKH9M'
    });
  } else {
    firebase.initializeApp({
      apiKey: 'AIzaSyAD4SmMyTTzS-0jKq7pFZ6Bk6JQrZmyw50',
      authDomain: 'yw-cloudcommunity-dev.firebaseapp.com',
      databaseURL: 'https://yw-cloudcommunity-dev.firebaseio.com',
      projectId: 'yw-cloudcommunity-dev',
      storageBucket: 'yw-cloudcommunity-dev.appspot.com',
      messagingSenderId: '1048963575968',
      appId: '1:1048963575968:web:16c0076b402e46b9d819c5',
      measurementId: 'G-2NWYS2J8YR'
    });
  }

  return firebase.analytics();
}
