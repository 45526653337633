import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#649100',
      main: '#75aa00',
      dark: '#89C10D',
      contrastText: '#fff'
    }
  }
});

const withTheme = (PassedComponent) => {
  const WrappedComponent = (props) => {
    return (
      <ThemeProvider theme={theme}>
        <PassedComponent {...props} />
      </ThemeProvider>
    );
  };

  return WrappedComponent;
};

export default withTheme;
