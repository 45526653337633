export default ['$scope', '$async', 'ywUtil', '$stateParams',
  function controller ($scope, $async, ywUtil, $stateParams) {
    const self = this;
    // $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.imageFile = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    function validator () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      console.log('validator:', item);

      if (!item.name) return $scope.alert('標籤名稱不能為空');
      if (isEdit && !item.urlId) return $scope.alert('網址辨識名稱不能為空');
      if (item.urlId) {
        if (!/^[a-z-0-9]+$/.test(item.urlId)) return $scope.alert('網址辨識名稱格式不符');
      }

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      console.log('after transform: ', item);
    }

    async function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      item.photoClassification = $stateParams.photoClassificationId;
      // const isEdit = $scope.currentPageState === 'edit';

      $scope.inputItems = [
        { label: '標籤名稱*', type: 'text', index: 'name' },
        {
          label: '網址辨識名稱',
          type: 'text',
          class: 'long',
          index: 'urlId',
          info: '由此自定網址名稱，使用全英文小寫或數字命名，可使用"-"分隔，例 interior-select，不設定則系統預設使用id'
        },
        { label: '排序', type: 'number', index: 'sort' },
        { type: 'action' }
      ];
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
