import { isValidEmail } from './../../../share';
import _ from 'lodash';

export default ['$scope', '$async', 'Upload', 'address', 'confirmDialog', 'ywUtil', 'User',
  function controller ($scope, $async, Upload, address, confirmDialog, ywUtil, User) {
    const self = this;

    self.avatarFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    const save = async () => {
      await $scope.$parent.save({
        customSaveAction: User.one('serviceAdmin').customPOST
      });
    };
    $scope.save = save;

    function validator () {
      const item = $scope.currentActionItem;
      const isAdd = $scope.currentPageState === 'add';

      console.log('validator:', item);

      if (_.isEmpty(item.username)) return $scope.alert('帳號為必填');
      if (_.isEmpty(item.fullname)) return $scope.alert('名稱為必填');
      if (isAdd && _.isEmpty(item._password)) return $scope.alert('密碼為必填');
      if (isAdd && _.isEmpty(item._confirmedPassword)) return $scope.alert('請輸入確認密碼');
      if ((!_.isEmpty(item._password) || !_.isEmpty(item._confirmedPassword)) && item._password !== item._confirmedPassword) return $scope.alert('兩次密碼不同');
      if (_.isEmpty(item.email) && item.isGetEmailNotification) return $scope.alert('接收居家 Email 通知開啟時請輸入email');
      if (!_.isEmpty(item.email) && !isValidEmail(item.email)) return $scope.alert('Email 格式不符');

      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';
      console.log('before transform: ', item);

      if (!_.isEmpty(item._password)) {
        item.password = item._password;
        if (isEdit) item.origin = 'lsWeb';
      }

      item.permission = $scope.permissionCheckboxes
        .reduce((pre, { index, checked }) => {
          pre[index] = checked ? 'access' : 'deny';
          return pre;
        }, {});
    }

    function syncCurrentActionItem () {
      const item = $scope.currentActionItem;
      const isEdit = $scope.currentPageState === 'edit';

      $scope.permissionCheckboxes = [
        ['console:tab:reservation', '來件管理'],
        ['console:tab:marketing', '行銷管理'],
        ['console:tab:service', '服務管理'],
        ['console:tab:vender', '廠商管理'],
        ['console:tab:accountManagement', '帳務管理'],
        ['console:tab:websiteContent', '網站內容管理'],
        ['console:tab:other', '其他設定']
      ].map(([index, label]) => ({
        value: false,
        label,
        index,
        checked: _.get(item, `permission.${index}`) === 'access'
      }));

      $scope.inputItems = [
        {
          label: '帳號*',
          type: 'text',
          index: 'username',
          disabled: isEdit
        },
        {
          label: '名稱*',
          type: 'text',
          index: 'fullname'
        },
        {
          label: '密碼' + (isEdit ? '' : '*'),
          type: 'password',
          index: '_password'
        },
        {
          label: '再次輸入密碼' + (isEdit ? '' : '*'),
          type: 'password',
          index: '_confirmedPassword'
        },
        {
          label: 'email',
          type: 'text',
          index: 'email',
          class: 'long'
        },
        {
          label: '接收居家 Email 通知',
          type: 'switch',
          index: 'isGetEmailNotification'
        },
        {
          label: '權限',
          type: 'checkbox',
          index: '_permissions',
          checkboxes: $scope.permissionCheckboxes
        },
        { type: 'action' }
      ];
    }

    $scope.$watch('currentActionItem', syncCurrentActionItem);
  }];
