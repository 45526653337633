export default ['$scope', '$stateParams', '$async', 'Upload', 'API_URL', 'currentUser',
  function controller ($scope, $stateParams, $async, Upload, API_URL, currentUser) {
    const self = this;

    self.avatarFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      if (!item.name) return $scope.alert('名稱不能為空');
      if (!item.username) return $scope.alert('帳號不能為空');
      if (!/^\d{8,10}$/.test(item.phone)) return $scope.alert('聯絡電話須為8-10位數字');
      if ($scope.currentPageState === 'add' && !item.password) return $scope.alert('密碼不能為空');
      if ($scope.currentPageState === 'add' && !/\w{6}/.test(item.password)) return $scope.alert('密碼須為6碼以上英文數字');
      if (item.password && item.password !== item._passwordAgain) return $scope.alert('密碼不符合');

      const noAnySubRole = $scope.subRoleCheckboxes
        .filter(checkbox => checkbox.checked)
        .length < 1;
      if (noAnySubRole) return $scope.alert('至少指派一種角色');

      console.log('currentActionItem: ', item);
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;
      item.avatar = _.get(item, '_avatarUrl._id');
      console.log('$stateParams.venderId', $stateParams.venderId);
      item.venderId = currentUser.hasRole('vender')
        ? _.get(currentUser.get(), 'link.vender')
        : $stateParams.venderId; // service admin user

      item.subRoles = $scope.subRoleCheckboxes
        .filter(checkbox => checkbox.checked)
        .map(checkbox => checkbox.value);
      console.log('transform finished: ', item);
    }

    function syncCurrentActionItem () {
      const item = $scope.currentActionItem;

      $scope.subRoleCheckboxes = [
        { value: 'staff', label: '服務員', checked: _.includes(item.subRoles, 'staff') },
        { value: 'valuator', label: '估價員', checked: _.includes(item.subRoles, 'valuator') }
      ];

      $scope.inputItems = [
        { label: '名稱', type: 'text', index: 'name' },
        { label: '帳號', type: 'text', index: 'username' },
        { label: '密碼', type: 'password', index: 'password' },
        { label: '確認密碼', type: 'password', index: '_passwordAgain' },
        { label: '電話', type: 'text', index: 'phone' },
        { label: 'email', type: 'text', index: 'email', class: 'long' },
        { label: '描述', type: 'textarea', index: 'description' },
        {
          label: '大頭貼',
          type: 'single-file',
          index: '_avatarUrl',
          originIndex: 'avatar',
          class: 'images-wrapper',
          files: $scope.avatarFiles,
          success: $scope.singleFileUploaded,
          error: $scope.alert
        },
        {
          label: '角色',
          type: 'checkbox',
          index: 'subRoles',
          checkboxes: $scope.subRoleCheckboxes
        },
        { type: 'action' }
      ];

      if ($scope.currentPageState === 'edit') {
        item.name = item.user.fullname;
        item.username = item.user.username;
        item.avatar = item.user.avatar;
        item.phone = item.user.phone;
        item.email = item.user.email;
        item._avatarUrl = item.user.avatar;
        item.avatar = _.get(item, '_avatarUrl._id');

        Object.keys(item.plain()).forEach(key => {
          if (
            key !== '_id' &&
            !_.some($scope.inputItems, input => input.index === key || input.originIndex === key)
          ) {
            delete item[key];
          }
        });
      }
    }
  }];
