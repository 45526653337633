import React, { Fragment } from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';
import { SinyiIcon } from './../Icons';

const style = {
  no: {
    whiteSpace: 'nowrap'
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  modifiedSvg: {
    fill: '#f03d2e'
  }
};

const Currency = ({ num }) => {
  return num
    .toString()
    .split('')
    .reverse()
    .reduce((result, num, index) => result + (index && index % 3 === 0 && num !== '-' ? ',' : '') + num, '')
    .split('')
    .reverse()
    .join('');
};

const method = type => {
  switch (type) {
    case 'cash': return '現金';
    case 'bankTransfer': return '匯款';
    case 'card': return '線上刷卡';
    case 'piWallet': return 'pi 拍錢包';
    case 'applePay': return 'Apple Pay';
    case 'branchBudget': return '感服金';
    default: return 'Error';
  }
};

const PaymentIcon = ({ paymentMethod, totalDiff }) => {
  if (paymentMethod === 'branchBudget') {
    return <SinyiIcon style={totalDiff ? style.modifiedSvg : null} />;
  }

  let iconClassName = 'list-icon ic-coins';
  switch (paymentMethod) {
    case 'cash': iconClassName = 'list-icon ic-coins'; break;
    case 'bankTransfer': iconClassName = 'ic-atm'; break;
    case 'card': iconClassName = 'list-icon ic-card'; break;
    case 'applePay': iconClassName = 'fab fa-apple'; break;
    case 'piWallet': iconClassName = 'ic-pi-wallet'; break;
    default: iconClassName = 'list-icon ic-coins'; break;
  }
  if (totalDiff) {
    if (paymentMethod === 'applePay') {
      iconClassName += ' color-red';
    } else if (paymentMethod === 'bankTransfer') {
      iconClassName += ' bg-red';
    } else {
      iconClassName += '-red';
    }
  }
  return <span className={iconClassName} style={{ fontSize: 28 }} />;
};

const PaymentInfo = ({ reservation }) => {
  const state = _.get(reservation, 'state');
  const isDenied = state === 'denied';

  const type = _.get(reservation, 'paymentStageSetting[1].validMethods[0]') ||
    _.get(reservation, 'paymentStageSetting[3].validMethods[0]') ||
    _.get(reservation, 'defaultPaymentMethod');

  let tipText = method(type);
  if (reservation.totalDiff) {
    tipText += ', 已修改';
  }

  return (
    <>
      {!reservation.isVisible && (
        <StatefulToolTip
          parent={
            <span
              className='fas fa-eye-slash' style={{
                fontSize: 22,
                marginRight: 2,
                verticalAlign: 'middle'
              }}
            />
          }
          position='top'
          arrow='center'
        >
          隱藏的訂單
        </StatefulToolTip>
      )}
      <span style={style.no}>
        {reservation.reservationNo || reservation.unorderedReservationNo}
      </span>
      <br />
      <div style={style.flex}>
        <StatefulToolTip
          parent={<PaymentIcon paymentMethod={type} totalDiff={reservation.totalDiff} />}
          position='top'
          arrow='center'
        >
          {tipText}
        </StatefulToolTip>
        <span className='color-red'><Currency num={isDenied ? reservation.travelingExpense : reservation.total} /></span>
      </div>
    </>
  );
};

export default PaymentInfo;
