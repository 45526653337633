export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('serviceClassifications', false, _transformer);
  const ServiceClassification = Restangular.service('serviceClassifications');
  return ServiceClassification;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      { anchorType: 'copy-id' },
      _.get(item, 'sortOrder', ''),
      _.get(item, 'name', ''),
      _.get(item, 'services', []).map(service => service.name).join(','),
      _.get(item, 'isVisible') ? '' : '隱藏',
      { anchorType: 'service-locations' },
      {
        anchorType: 'menu-buttons',
        btnList: [
          { name: 'edit', label: '服務分類設定' },
          { name: 'advancedFilterItems', label: '廠商進階篩選項目設定' },
          { name: 'advancedFilterName', label: '廠商進階篩選項目名稱設定' }
        ]
      }
    ];

    return item;
  }
}];
