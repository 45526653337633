export default ['$scope', '$q', function controller ($scope, $q) {
  $scope._action = _action;

  /// ///////
  /**
     * Base action callback to be inherited. With valdation injector and basic success callback.
     */
  // TODO: decouple status and alert
  function _action (promise) {
    $scope.status.isLoading = true;
    // do action
    console.log('Wait action: currentActionItem: ', $scope.currentActionItem);
    return promise.then(function (res) {
      if (res.error) {
        if (res.errorSource?.debugInfo?.isThrowVisibleHomepagesError) {
          return $q.reject(new Error(res.error, {
            cause: {
              isThrowVisibleHomepagesError: true
            }
          }));
        }
        return $q.reject(new Error(res.error));
      }
      return res;
    });
  }
}];
