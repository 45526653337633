export default ['ngDialog', function directive (ngDialog) {
  return {
    openConfirm: openConfirm
  };

  function openConfirm (data, scope) {
    if (!data || !scope) return console.log('missing parameters');

    const dialogData = {
      confirmValue: _.cloneDeep(data) || [],
      title: '項目編輯',
      file: [],
      classificationOptions: scope.classificationOptions,
      iconValidate: { width: { limit: 300 }, height: { limit: 300 } },
      onClassificationChange: (selectedItem) => {
        dialogData.confirmValue.name = selectedItem.label;
      },
      iconUploadSuccess: (result) => {
        dialogData.confirmValue._icon = result;
      },
      removeIcon: () => {
        dialogData.confirmValue._icon = null;
      },
      error: (err) => {
        console.log(err);
        scope.alert(err);
      },
      confirmWhenResolve: (data, confirmCb) => {
        if (data.type === 'serviceClassification') {
          if (!data.serviceClassification) return scope.alert('請選擇服務');
        } else if (data.type === 'link') {
          if (!data.name) return scope.alert('請填寫項目名稱');
          if (data.name.length > 10) return scope.alert('項目名稱限10個字');
          if (!data._icon.url) return scope.alert('請上傳圖示');
          if (!data.link) return scope.alert('請填寫連結');
          if (!/^http/.test(data.link)) return scope.alert('連結網址需為 http:// or https:// 開頭');
          if (data.description && data.description.length > 30) return scope.alert('描述限30個字');
        }
        confirmCb(data);
      }
    };

    const params = {
      template: '/view/dialog/subcatalog-editor-dialog.html',
      data: dialogData,
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog custom-width-680',
      closeByEscape: true,
      closeByDocument: true
    };

    return ngDialog.openConfirm(params);
  }
}];
