import moment from 'moment';

const getCountInfo = (item) => {
  const viewCount = _.get(item, 'clicks', 0);
  const oldViewCount = _.get(item, 'sinyiOriginalInfo.views', 0);
  return `${viewCount + oldViewCount} (${viewCount}/${oldViewCount})`;
};

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('articles', false, _transformer);
  const Article = Restangular.service('articles');
  return Article;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count
    const publishStartEndTime =
      !_.get(item, 'publishStartTime') && !_.get(item, 'publishEndTime')
        ? '未限制'
        : `${_.get(item, 'publishStartTime') ? moment(_.get(item, 'publishStartTime')).format('YYYY/MM/DD') : ''}~${_.get(item, 'publishEndTime') ? moment(_.get(item, 'publishEndTime')).format('YYYY/MM/DD') : ''}`;

    item._columns = [
      { anchorType: 'checkbox' },
      {
        anchorType: 'icon-button',
        name: 'cloneLink',
        icon: 'link'
      },
      { anchorType: 'true-or-false', isTrue: _.get(item, 'isPublish'), trueOnly: true },
      {
        anchorType: 'icon-button',
        name: 'previewArticle',
        icon: 'eye'
      },
      _.get(item, 'articleClassification.name', ''),
      _.get(item, 'title', ''),
      publishStartEndTime,
      moment(_.get(item, 'updateFieldsAt')).format('YYYY/MM/DD'),
      moment(_.get(item, 'createdAt')).format('YYYY/MM/DD'),
      getCountInfo(item),
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
