// 使用範例：監聽頁籤變化事件並觸發確認視窗
// 可在 HTML 檔的 md-tabs 加上 tab-watcher 屬性監聽，並於 controller 中撰寫以下邏輯
// $scope.$on('tab-change', function (event, tabEvent) {
//     confirmDialog.openConfirm({
//       title: '資料已修改但尚未儲存哦',
//       content: '資料已修改但尚未儲存哦',
//       isDangers: true
//     }).then(() => {
//       tabEvent.accept();
//     })
// });

export default function tabWatcher () {
  return {
    require: ['mdTabs'],
    restrict: 'A',
    link: function (scope, el, attrs, controllers) {
      const mdTabsCtrl = controllers[0];
      const origSelectFn = mdTabsCtrl.select;

      // 覆蓋原本的 select 函數
      mdTabsCtrl.select = function (index, canSkipClick) {
        // 發送事件，並帶有 accept/reject 函數
        scope.$emit('tab-change', {
          index: index,
          accept: function () {
            return origSelectFn(index, canSkipClick);
          },
          reject: function () { }
        });
      };
    }
  };
}
