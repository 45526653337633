import moment from 'moment';

export default ['$timeout', 'Restangular', 'ngDialog', 'Community', function directive ($timeout, Restangular, ngDialog, Community) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      serviceTimes: '=',
      notServiceIn: '=',
      confirmCallback: '=',
      options: '=',
      disabled: '=ywDisabled',
      itemId: '='
    },
    template: '<button class="yw-btn" ng-class="{\'yw-btn-blue\': !disabled}" ng-click="openConfirm(this)">' +
      '{{disabled ? \'查看時間\' : \'選擇時間\'}}' +
      '</button>',
    link: link
  };

  function link (scope, element, attr) {
    scope.openConfirm = openConfirm;

    function openConfirm (scope) {
      const dialogData = {
        daysList: initWeekArray(angular.copy(scope.serviceTimes)),
        removedDaysList: [],
        directiveScope: scope,
        officialHolidayList: angular.copy(scope.notServiceIn) || [],
        minOfficialHolidayDate: new Date(),
        disabled: scope.disabled,
        // functions
        addServiceTime: addServiceTime,
        removeServiceTime: removeServiceTime,
        onConfirm: onConfirm,
        addOfficialHoliday: addOfficialHoliday,
        removeOfficialHoliday: removeOfficialHoliday
      };
      if (_.isEmpty(dialogData.daysList)) { addServiceTime(dialogData.daysList); }

      const params = {
        template: '/view/dialog/service-time-setting-dialog.html',
        showClose: false,
        className: 'ngdialog-theme-default yw-plain-dialog custom-width-600',
        closeByEscape: true,
        closeByDocument: true,
        data: dialogData
      };

      ngDialog.openConfirm(params);
    }

    function initWeekArray (daysList) {
      return daysList.map(function (days) {
        days._week = days.week.split(',').map(function (n) { return parseInt(n); });
        return days;
      });
    }

    function addServiceTime (daysList) {
      daysList.push({
        type: 'time',
        principle: 'include',
        _week: [],
        time: [new Date('2016-01-01 00:00:00'), new Date('2016-01-01 00:00:59')]
      });
    }

    function removeServiceTime (days, daysList, removeList) {
      // add to removedDaysList if objectId exist
      if (days.objectId) { removeList.push(days); }
      daysList.splice(daysList.indexOf(days), 1);
    }

    function onConfirm (scope, dialogData, confirmCb) {
      validateDaysList(dialogData.daysList, scope)
        .then(function () {
          return validateOfficialHoliday(dialogData.officialHolidayList);
        })
        .then(function () {
          return saveServiceTime(dialogData.daysList);
        })
        .then(function (daysList) {
          scope.serviceTimes = daysList; // set service time result
          return saveOfficialHoliday(dialogData.officialHolidayList);
        })
        .then(function (ohList) {
          scope.notServiceIn = ohList; // set not service in result
          return removeUnusedServiceTime(dialogData.removedDaysList);
        })
        .then(function () {
          confirmCb(); // dialog confirm callback
          $timeout(function () {
            if (scope.confirmCallback) { scope.confirmCallback(scope); } // directive confirm callback
          });
        }).catch(function (err) {
          $timeout(function () { dialogData.statusMsg = err; });
        });
    }

    function saveServiceTime (daysList) {
      return transformDaysList(daysList)
        .then(saveAsTimeline)
        .then(onTimelineSaved);
    }

    function saveAsTimeline (timelines) {
      if (_.isEmpty(timelines)) {
        return Promise.resolve([]);
      }

      const storeId = _.get(scope, 'options.storeId');
      const promises = timelines.map(function (tl) {
        if (storeId && tl.type === 'date') {
          if (tl.objectId) return Restangular.one('toBuyStores', storeId).obj.one('notServiceIn', tl.objectId).customPUT(tl);
          return Restangular.one('toBuyStores', storeId).obj.all('notServiceIn').post(tl);
        }
        if (storeId) {
          if (tl.objectId) return Restangular.one('toBuyStores', storeId).obj.one('serviceTimes', tl.objectId).customPUT(tl);
          return Restangular.one('toBuyStores', storeId).obj.all('serviceTimes').post(tl);
        }
        const service = Restangular.service('timelines');
        return tl.objectId ? service.one(tl.objectId).customPUT(tl) : service.post(tl);
      });
      return Promise.all(promises);
    }

    function onTimelineSaved (res) {
      const result = res.map(function (timeline) { return timeline.plain(); });
      return Promise.resolve(result);
    }

    function validateDaysList (daysList, scope) {
      if (scope.options && scope.options.hideWeekSelect) { daysList[0]._week = [0, 1, 2, 3, 4, 5, 6]; }
      if (!daysList.every(function (days) { return !_.isEmpty(days._week); })) { return Promise.reject(new Error('星期欄位不可為空')); }
      if (!daysList.every(function (days) { return days.time[0] && days.time[1]; })) { return Promise.reject(new Error('時間格式錯誤')); }
      if (!daysList.every(function (days) { return new Date(days.time[0]).getTime() < new Date(days.time[1]).getTime(); })) { return Promise.reject(new Error('開始時間必須小於結束時間')); }
      return Promise.resolve(daysList);
    }

    function transformDaysList (daysList) {
      return Promise.resolve(daysList
        .map(function (days) {
          days._week.sort(function (a, b) { return a > b; });
          days.week = days._week.join(',');
          return days;
        }).filter(days => {
          return !(moment(days.time[0]).hour() === 0 &&
            moment(days.time[0]).minute() === 0 &&
            moment(days.time[1]).hour() === 0 &&
            moment(days.time[1]).minute() === 0);
        }));
    }

    function removeUnusedServiceTime (removeList) {
      if (_.isEmpty(removeList)) { return Promise.resolve(true); }
      const storeId = _.get(scope, 'options.storeId');
      const promises = removeList.map(function (days) {
        if (storeId && days.type === 'date') return Restangular.one('toBuyStores', storeId).obj.one('notServiceIn', days.objectId).remove();
        if (storeId) return Restangular.one('toBuyStores', storeId).obj.one('serviceTimes', days.objectId).remove();
        return Restangular.service('timelines').one(days.objectId).remove();
      });
      return Promise.all(promises);
    }

    function addOfficialHoliday (ohList) {
      ohList.push({ type: 'date', principle: 'include' });
    }

    function removeOfficialHoliday (oh, ohList, removeList) {
      if (oh.objectId) { removeList.push(oh); }
      ohList.splice(ohList.indexOf(oh), 1);
    }

    function saveOfficialHoliday (ohList) {
      return transformOfficialHoliday(ohList)
        .then(saveAsTimeline)
        .then(onTimelineSaved);
    }

    function validateOfficialHoliday (ohList) {
      if (!_.isEmpty(ohList) && !ohList.every(function (oh) { return oh.date; })) { return Promise.reject(new Error('特殊休息日不可為空')); }
      return Promise.resolve(ohList);
    }

    function transformOfficialHoliday (ohList) {
      return Promise.resolve(ohList);
    }
  }
}];
