export default ['$timeout', 'Restangular', 'ngDialog', function directive ($timeout, Restangular, ngDialog) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      customizations: '=ywCustomizations',
      removableCustomizations: '=ywRemovableCustomizations',
      disabled: '=ywDisabled'
    },
    template: `
    <div
      style="font-weight: 600; white-space: nowrap; padding: 7px 0;"
      ng-if="customizations.length"
    >
      <table class="table">
        <thead>
          <tr>
            <th>排序</th>
            <th>項目名稱</th>
            <th>選擇類型</th>
            <th>項目選項</th>
            <th>最低選擇數量</th>
            <th>最高選擇數量</th>
            <th>單項可重複選</th>
            <th>需驗收</th>
            <th>刪除</th>
          </tr>
        </thead>
        <tbody dnd-list="customizations" class="draggable-list">
          <tr
            class="draggable-item customization-item"
            ng-repeat="c in customizations"
            dnd-draggable="c"
            dnd-moved="itemMoved($index)"
            dnd-effect-allowed="copyMove"
          >
            <td>
              <span
                dnd-handle
                class="handle"
                style="margin-left: 10px; margin-right: 20px;"
              ></span>
            </td>
            <td>
              <input
                dnd-nodrag
                type="text"
                class="input"
                ng-model="c.title"
                placeholder="項目名稱"
                ng-disabled="disabled"
                ng-class="{disabled: disabled}"
              />
            </td>
            <td>
              <yw-popup-selection
                yw-options="selectTypeOptions"
                yw-selected="c.selectType"
              ></yw-popup-selection>
            </td>
            <td>
              <customization-items
                items="c.items"
                type="c.selectType"
              />
            </td>
            <td>
              <input
                dnd-nodrag
                type="number"
                class="input"
                ng-model="c.quantityMin"
                ng-disabled="disabled"
                ng-class="{disabled: disabled}"
                style="width: 70px; margin-left: 10px; margin-right: 30px;"
                ng-if="c.selectType === 'multiple'"
                ng-change="quantityMinChange(c)"
              />
            </td>
            <td>
              <input
                dnd-nodrag
                type="number"
                class="input"
                ng-model="c.quantityMax"
                ng-disabled="disabled"
                ng-class="{disabled: disabled}"
                style="width: 70px; margin-right: 30px;"
                ng-if="c.selectType === 'multiple'"
                ng-change="quantityMaxChange(c)"
              />
            </td>
            <td>
              <md-checkbox
                ng-if="c.selectType === 'multiple'"
                ng-model="c.isRepeatable"
              ></md-checkbox>
            </td>
            <td>
              <md-checkbox
                ng-model="c.isAcceptance"
              ></md-checkbox>
            </td>
            <td>
              <span
                class="glyphicon glyphicon-remove btn-minus"
                ng-if="!disabled"
                ng-click="removeCustomization(c)"
                style="top: 3px;"></span>
            </td>
          </tr>
          <tr class="dndPlaceholder">
            <td style="height: 65px;">拖曳至此</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <span ng-if="!disabled">
      <md-tooltip md-direction="top">新增客製化項目</md-tooltip>
      <span
        class="glyphicon glyphicon-plus btn-plus"
        ng-click="addCustomization()"></span>
    </span>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.selectTypeOptions = [
      { label: '單選', value: 'single' },
      { label: '多選', value: 'multiple' },
      { label: '非選', value: 'customizedInput' }
    ];

    if (!scope.removableCustomizations) { scope.removableCustomizations = []; }

    scope.addCustomization = function () {
      console.log(scope.customizations);
      scope.customizations.push({ selectType: 'single' });
    };

    scope.removeCustomization = function (customization) {
      if (customization.objectId) { scope.removableCustomizations.push(customization); }
      scope.customizations.splice(scope.customizations.indexOf(customization), 1);
    };

    scope.itemMoved = idx => {
      scope.customizations.splice(idx, 1); // remove from list
    };

    scope.quantityMinChange = c => {
      if (c.quantityMin === null) {
        c.quantityMin = 0;
      }
    };
    scope.quantityMaxChange = c => {
      if (c.quantityMax === null) {
        c.quantityMax = 0;
      }
    };
  }
}];
