import moment from 'moment';
import { inputLimit } from './../share/input-limit.js';

export default [
  '$async',
  'searchableObjPickerDialog',
  'ngDialog',
  'confirmDialog',
  'ywEvent',
  'ywUtil',
  'currentUser',
  'address',
  'Restangular',
  'Vender',
  'UnorderedReservation',
  'User',
  'ServiceCapacity',
  'ServiceClassification',
  'lifeDialog',
  unorderedReservationUtil
];
function unorderedReservationUtil (
  $async,
  searchableObjPickerDialog,
  ngDialog,
  confirmDialog,
  ywEvent,
  ywUtil,
  currentUser,
  address,
  Restangular,
  Vender,
  UnorderedReservation,
  User,
  ServiceCapacity,
  ServiceClassification,
  lifeDialog
) {
  const util = {};

  // 開啟報價提示 dialog
  const openQuotationHintDialog = (reservation) => {
    confirmDialog.openConfirm({
      title: '請先設定報價金額',
      content: '請先設定報價金額，再進行狀態變更',
      confirmLabel: '設定報價'
    }).then(res => {
      routeToQuotation(reservation);
    });
  };

  // 開啟報價合約頁面
  const routeToQuotation = reservation => {
    if (!_.get(reservation, 'vender._id')) {
      ywEvent.emit('ALERT', '尚未指定廠商');
    } else {
      const role = currentUser.getInitialState();
      const venderId = reservation.vender._id;
      const quotationId = reservation.quotation._id;
      window.open(
        `/${role}/unordered-reservation/${reservation._id}/quotation/${quotationId}?venderId=${venderId}`,
        '_blank'
      );
    }
  };

  util.handleStateControl = $async(async ({ reservation }) => { // 這ㄍ檔案是複製來改的，目前只處理到這個 func
    const states = await Restangular.service('freeStates').getList({ limit: 200 });
    const isCompletedStatus = ['6302e3774ee06d006f6c171d', '63043e1ae8b27700703af13a', '63155bc6664730006ee29c67', '6302e3774ee06d006f6c171b', '63043e1ae8b27700703af138', '63155bc6664730006ee29c65'];
    const stateOptions = states
      .filter(state => !(currentUser.hasRole('vender') && state.isInitial))
      .filter(state => !(currentUser.hasRole('vender') && state.isOnlyServiceAdminUsed))
      .map(state => ({ label: state.displayNameAtConsole, value: state._id }));

    const data = await lifeDialog.openConfirm({
      title: '更改訂單狀態',
      templateUrl: '/view/life-template/life-reservation-status-edit-dialog.html',
      options: stateOptions,
      reservation,
      timePeriod: {
        showToTime: true
      },
      rightButton: [
        {
          label: '取消',
          color: 'neutral',
          type: 'outline',
          onClick: (ngDialogData, confirm, closeThisDialog) => {
            console.log('關閉');
            // closeThisDialog();
          }
        },
        {
          label: '確定',
          color: 'primary',
          type: 'raised',
          onClick: (ngDialogData, confirm, closeThisDialog) => {
            console.log('確定', ngDialogData);
            if (isCompletedStatus.indexOf(ngDialogData.nextStateId) > -1) {
              if (ngDialogData.reservation.subtotal <= 0) {
                return lifeDialog.openConfirm({ // 可以拿出去
                  title: '確認是否設定報價金額',
                  content: '須先設定報價金額才能進行狀態改變',
                  rightButton: [
                    {
                      label: '取消',
                      color: 'neutral',
                      type: 'outline',
                      onClick: (ngDialogData, confirm, closeThisDialog) => {
                        closeThisDialog();
                      }
                    },
                    {
                      label: '前往設定',
                      color: 'primary',
                      type: 'raised',
                      onClick: (ngDialogData, confirm, closeThisDialog) => {
                      }
                    }
                  ]
                }, 'life-standard-dialog');
              }
            }
            if (['6302e3774ee06d006f6c171d', '63043e1ae8b27700703af13a', '63155bc6664730006ee29c67'].indexOf(ngDialogData.nextStateId) > -1) {
              if (!ngDialogData.reservation.completedDate) {
                if (!ngDialogData.completedDate) {
                  // return ywEvent.emit('SHOW_LIFE_NOTICE', '請填寫完工日期');
                  return lifeDialog.openConfirm({ // 可以拿出去
                    title: '確認是否設定完工日期',
                    content: '須先設定完工日期才能進行狀態改變',
                    rightButton: [
                      {
                        label: '取消',
                        color: 'neutral',
                        type: 'outline',
                        onClick: (ngDialogData, confirm, closeThisDialog) => {
                          closeThisDialog();
                        }
                      },
                      {
                        label: '前往設定',
                        color: 'primary',
                        type: 'raised',
                        onClick: (ngDialogData, confirm, closeThisDialog) => {
                        }
                      }
                    ]
                  }, 'life-standard-dialog');
                }
              } else {
                ngDialogData.completedDate = ngDialogData.reservation.completedDate;
              }
            }
            confirm(ngDialogData);
          }
        }
      ]
    }, 'life-standard-dialog');

    console.log(data); // 要看一下為什麼沒拿到資料ＱＱ
    // return;
    const result = await UnorderedReservation.one(reservation._id).customPUT({
      nextStateId: data.nextStateId
      // note:
    }, 'state');
    if (result.error) {
      ywEvent.emit('SHOW_LIFE_NOTICE', result.error);
    } else {
      reservation.state = result.state;
      reservation.stateLogs = result.stateLogs;
      ywEvent.emit('SHOW_LIFE_NOTICE', '已變更');
    }
  });

  util.handleVenderAssign = $async(async ({ reservation }) => {
    const venders = await UnorderedReservation.one(reservation._id).customGET('supportVenders');
    if (!venders?.length) return ywEvent.emit('ALERT', '目前沒有廠商可提供服務');
    searchableObjPickerDialog.openConfirm({
      ywTitle: '廠商 @來件數（月/季/年）',
      titleTooltip: `
        <div>來件數統計說明(以2022/08/24為例):</div>
        <div>月: 當月目前廠商被指派的訂單數，計算區間為2022/08/01-2022/08/31</div>
        <div>季: 當季目前廠商被指派的訂單數，計算區間為2022/07/01-2022/09/30</div>
        <div>年: 當年目前廠商被指派的訂單數，計算區間為2022/01/01-2022/12/31</div>

        <div>更新時機: 由於即時運算可能會導致列表過慢，但希望做到相對即時，所以在工程訂單有廠商被指派時，就重新計算更新資料，載入廠商列表抓取以完成計算的值顯示</div>
      `,
      selectedId: _.get(reservation, 'vender._id'),
      objResource: Vender,
      filterField: 'name',
      titleField: 'name',
      subTitleField: '_countStatistics',
      customerBudget: _.get(reservation, 'customerBudget', ''),
      budgetField: true,
      extraParams: { isGetCountStatistics: true, venderIds: venders },
      addressField: false,
      selectedCb: $async(async (selected, confirmCb) => {
        let reason;
        if (_.get(reservation, 'vender.name')) {
          const dialogData = {
            title: selected.name ? '轉單確認' : '取消廠商',
            content: selected.name ? `確定要將訂單轉給 ${selected.name}` : '確定要取消廠商訂單？',
            templateUrl: '/view/dialog/single-textarea.html',
            confirmValue: '',
            maxlength: 128,
            disableConfirm: true,
            onConfirmValueChange: (text) => {
              dialogData.disableConfirm = !text;
            }
          };
          reason = await confirmDialog.openConfirm(dialogData);
        }
        if (selected.name) {
          const res = await UnorderedReservation.one('dispatch').customPOST({
            unorderedReservationId: reservation._id,
            venderId: selected._id,
            note: reason
          });
          if (res.error) {
            ywEvent.emit('ALERT', res.error);
          } else {
            reservation.vender = res.vender;
            reservation.dispatching = res.dispatching;
            reservation.serviceCapacity = res.serviceCapacity;
            ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
            ywEvent.emit('SUCCESS', '已指派廠商');
            confirmCb();
          }
        } else {
          const res = await UnorderedReservation.one('cancelDispatch').customPOST({
            unorderedReservationId: reservation._id,
            note: reason
          });
          if (res.error) {
            ywEvent.emit('ALERT', res.error);
          } else {
            reservation.dispatching = res.dispatching;
            ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
            ywEvent.emit('SUCCESS', '已取消指派廠商');
            confirmCb();
          }
        }
      })
    });
  });

  util.openDetail = $async(async ({ reservation, displayVisible }) => {
    const cloneUserId = id => {
      ywUtil.cloneText(id);
      ywEvent.emit('SUCCESS', '已複製');
    };
    const filterUserInUserPage = id => {
      const role = currentUser.getInitialState();
      window.open(`/${role}/customer?userId=${id}`, '_blank');
    };
    const openObjectNoEditor = $async(async (reservation) => {
      const dialogData = {
        title: '修改物件編號',
        templateUrl: '/view/dialog/single-input.html',
        confirmValue: '',
        placeholder: '輸入物件編號',
        disableConfirm: true,
        onInputChange: (value) => {
          dialogData.disableConfirm = !value;
        }
      };
      const value = await confirmDialog.openConfirm(dialogData);
      const res = await UnorderedReservation.one(reservation._id).customPUT({ objectNo: value }, 'objectNo');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
      } else {
        reservation.objectNo = res.objectNo;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        ywEvent.emit('SUCCESS', '修改完成');
      }
    });
    const openClientPhoneEditor = $async(async (reservation) => {
      const dialogData = {
        title: '修改聯絡人電話',
        templateUrl: '/view/dialog/single-input.html',
        confirmValue: '',
        placeholder: '輸入電話',
        disableConfirm: true,
        onInputChange: (value) => {
          dialogData.disableConfirm = !value;
        }
      };
      const value = await confirmDialog.openConfirm(dialogData);
      const res = await UnorderedReservation.one(reservation._id).customPUT({ phone: value }, 'basicInfo');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
      } else {
        reservation.phone = res.phone;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        ywEvent.emit('SUCCESS', '修改完成');
      }
    });
    const openClientNameEditor = $async(async (reservation) => {
      const dialogData = {
        title: '修改聯絡人名稱',
        templateUrl: '/view/dialog/single-input.html',
        confirmValue: '',
        placeholder: '輸入聯絡人名稱',
        disableConfirm: true,
        onInputChange: (value) => {
          dialogData.disableConfirm = !value;
        }
      };
      const value = await confirmDialog.openConfirm(dialogData);
      const res = await UnorderedReservation
        .one(reservation._id)
        .customPUT({ name: value }, 'basicInfo');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
      } else {
        reservation.name = res.name;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        ywEvent.emit('SUCCESS', '修改完成');
      }
    });

    const openClientEmailEditor = $async(async (reservation) => {
      const dialogData = {
        title: '修改聯絡人email',
        templateUrl: '/view/dialog/single-input.html',
        confirmValue: '',
        placeholder: '輸入聯絡人email',
        disableConfirm: true,
        onInputChange: (value) => {
          dialogData.disableConfirm = !value;
        }
      };
      const value = await confirmDialog.openConfirm(dialogData);
      const res = await UnorderedReservation.one(reservation._id)
        .customPUT({ email: value }, 'basicInfo');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
      } else {
        reservation.email = res.email;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
        ywEvent.emit('SUCCESS', '修改完成');
      }
    });
    const openServiceAddressEditor = $async(async (reservation) => {
      // 取得可指派的縣市行政區
      let regions = [];
      let vender = null;
      if (reservation.vender) {
        const capacities = await ServiceCapacity.getList({
          venderId: reservation.vender._id,
          serviceClassificationId: reservation.serviceClassification._id
        });
        const regionCandidates = _.chain(capacities)
          .map(capacity => capacity.regions)
          .flatten()
          .value();
        const regionMap = {};
        for (const region of regionCandidates) {
          if (regionMap[region.county]) {
            regionMap[region.county].districts = _.union(
              regionMap[region.county].districts,
              region.districts
            );
          } else {
            regionMap[region.county] = region;
          }
        }
        regions = Object.values(regionMap);
        vender = await Vender.one(reservation.vender._id).customGET();
      } else {
        const classification = await ServiceClassification.one(reservation.serviceClassification._id).customGET();
        regions = classification.venderDispatchRegions;
      }

      const dialogData = {
        title: '修改客戶地址',
        inputLimit: inputLimit.address,
        templateUrl: '/view/dialog/address-info-input.html',
        confirmValue: { hasElevator: false },
        mode: reservation.mode,
        disableConfirm: true,
        countyOptions: regions.map(region => ({ label: region.county, value: region.county })),
        districtOptions: [],
        onCountyChange: (county) => {
          dialogData.confirmValue.district = undefined;
          dialogData.districtOptions = _.chain(regions)
          // 從serviceCapacities取得的行政區空陣列代表全行政區
            .map(region => ({
              ...region,
              districts: reservation.vender && _.isEmpty(region.districts)
                ? address[region.county]
                : region.districts
            }))
          // 排除不派單行政區廠商
            .map(region => {
              const notDispatchRegion = _.find(
                _.get(vender, 'regionsForNotDispatch', []),
                venderRegion => venderRegion.county === region.county
              );
              if (notDispatchRegion) {
                return {
                  ...region,
                  districts: region.districts
                    .filter(district => !_.includes(notDispatchRegion.districts, district))
                };
              } else {
                return region;
              }
            })
            .find(region => region.county === county)
            .get('districts')
            .map(district => ({ label: district, value: district }))
            .value();
          dialogData.disableConfirm = true;
        },
        onDistrictChange: (district) => {
          dialogData.disableConfirm = !dialogData.confirmValue.county ||
          !dialogData.confirmValue.district ||
          !dialogData.confirmValue.details;
        },
        onDetailChange: (value) => {
          dialogData.disableConfirm = !dialogData.confirmValue.county ||
          !dialogData.confirmValue.district ||
          !dialogData.confirmValue.details;
        }
      };
      const addressInfo = await confirmDialog.openConfirm(dialogData);
      const res = await UnorderedReservation
        .one(reservation._id)
        .customPUT({ address: addressInfo }, 'basicInfo');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
      } else {
        reservation.address = res.address;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
        ywEvent.emit('SUCCESS', '修改完成');
      }
    });
    const openContactDateEditEditor = $async(async (reservation) => {
      const contactDate = await confirmDialog.openConfirm({
        title: '修改方便聯絡時段',
        templateUrl: '/view/dialog/contact-date-edit-dialog.html',
        options: [
          { value: '不拘(09:00-18:00)', label: '不拘(09:00-18:00)' },
          { value: '上午(09:00-12:00)', label: '上午(09:00-12:00)' },
          { value: '下午(13:30-18:00)', label: '下午(13:30-18:00)' }
        ],
        confirmValue: undefined
      });
      const res = await UnorderedReservation
        .one(reservation._id)
        .customPUT({ contactDate }, 'timeInfo');
      if (res.error) {
        await confirmDialog.openConfirm({ title: '修改失敗', content: res.error });
      } else {
        reservation.contactDate = res.contactDate;
        ywEvent.emit('UPDATE_ITEM_IN_LIST', res);
        ywEvent.emit('SUCCESS', '修改完成');
      }
    });

    const editVender = (reservation) => util.handleVenderAssign({ reservation });
    const openVenderAssignmentLogs = $async(async (reservation) => {
      const dispatchLogs = _.get(reservation, 'dispatching.manuallyDispatchLogs', []);
      const dispatchCancelLogs = _.get(reservation, 'dispatching.manuallyDispatchCancelLogs', []).map((item) => {
        item = { ...item, venderName: '取消指派廠商' };
        return item;
      });

      const logs = dispatchLogs.concat(dispatchCancelLogs).sort((a, b) => new Date(a.at) - new Date(b.at));
      const results = await Promise.all(logs.map(log => User.one(log.by).customGET()));
      logs.forEach((log, index) => {
        log._by = results[index];
      });
      confirmDialog.openConfirm({
        title: '廠商指派歷程',
        templateUrl: '/view/dialog/manually-dispatch-logs.html',
        logs: logs
      }, 'custom-width-680');
    });

    const editState = (reservation) => util.handleStateControl({ reservation });
    const openStateLogs = $async(async (reservation) => {
      const logs = _.get(reservation, 'stateLogs', []);
      confirmDialog.openConfirm({
        title: '服務時間修改歷程',
        templateUrl: '/view/dialog/unordered-state-logs.html',
        logs: logs
      }, 'custom-width-680');
    });

    let userGroupNames;

    if (currentUser.hasRole('serviceAdmin')) {
      const user = await User.one(reservation.user._id).customGET();
      userGroupNames = _.get(user, 'userGroups', []).map(group => group.name).join('、');
    }

    const dialogData = {
      _role: currentUser.getRole(),
      item: reservation,
      displayVisible,
      displayBillsButton: reservation.billProcessParticularStatus !== 'ban',
      isServiceAdmin: currentUser.hasRole('serviceAdmin'),

      userInfoTmpl: `
        <div>${reservation.user._id}(點擊複製)</div>
        ${userGroupNames ? `<div>會員群組：${userGroupNames}</div>` : ''}
      `,
      userInfoTmpl2: `
        <div>(點擊開啟功能選項)</div>
        <div>會員ID：${reservation.user._id}</div>
        ${userGroupNames ? `<div>會員群組：${userGroupNames}</div>` : ''}
      `,

      // methods
      cloneUserId,
      filterUserInUserPage,
      openObjectNoEditor,
      openClientNameEditor,
      openClientPhoneEditor,
      openQuotationHintDialog,
      routeToQuotation,
      openClientEmailEditor,
      openServiceAddressEditor,
      openContactDateEditEditor,
      editVender,
      openVenderAssignmentLogs,
      editState,
      openStateLogs
    };

    await ngDialog.openConfirm({
      template: '/view/dialog/unordered-reservation-detail.html',
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog custom-width-680',
      closeByEscape: true,
      closeByDocument: true,
      data: dialogData
    });
  });

  util.openPinDialog = $async(async ({ reservation, isPined }) => {
    const pin = !isPined;
    const result = await Restangular.service('pinReservations').one('top')
      .customPUT({ reservationId: reservation._id, pin: pin, target: 'unorderedReservation' });
    if (result.error) {
      ywEvent.emit('ALERT', result.error);
    } else {
      const updated = await UnorderedReservation.one(reservation._id).customGET();
      ywEvent.emit('UPDATE_ITEM_IN_LIST', updated);
      ywEvent.emit('SUCCESS', '設定完成');
    }
  });

  util.openContactDeadlineDialog = async ({ reservation }) => {
    const onRemoveContactDeadline = async (value, dialogData, closeDialog) => {
      const removeDate = null;
      const result = await Restangular.service('pinReservations').one('connections')
        .customPUT({ reservationId: reservation._id, date: moment(removeDate).startOf('day').toDate(), target: 'unorderedReservation' });
      if (result.error) {
        ywEvent.emit('ALERT', result.error);
      } else {
        const updated = await UnorderedReservation.one(reservation._id).customGET();
        ywEvent.emit('UPDATE_ITEM_IN_LIST', updated);
        ywEvent.emit('SUCCESS', '設定完成');
        closeDialog();
      }
    };

    const connectionDate = _.get(reservation, 'pinConnectionInfo.date') ||
      _.get(reservation, 'unpinConnectionDate');
    const dialogData = {
      title: '設定聯絡日期',
      templateUrl: '/view/dialog/contact-deadline-dialog.html',
      confirmValue: connectionDate,
      customLeftBtn: [
        {
          label: '解除聯絡日期',
          value: 'accept',
          cb: onRemoveContactDeadline,
          showIndex: true,
          type: 'warn',
          disabled: !connectionDate
        }
      ]

    };
    const date = await confirmDialog.openConfirm(dialogData);
    const result = await Restangular.service('pinReservations').one('connections')
      .customPUT({
        reservationId: reservation._id,
        date: moment(date).startOf('day').toDate(),
        target: 'unorderedReservation'
      });
    if (result.error) {
      ywEvent.emit('ALERT', result.error);
    } else {
      const updated = await UnorderedReservation.one(reservation._id).customGET();
      ywEvent.emit('UPDATE_ITEM_IN_LIST', updated);
      ywEvent.emit('SUCCESS', '設定完成');
    }
  };

  return util;
}
