export default ['ngDialog', 'Upload', 'session', function factory (ngDialog, Upload, session) {
  return { openConfirm: openConfirm };

  function openConfirm (data) {
    if (!data) return console.log('missing parameters');

    const dialogData = {
      headFoot: ['住戶', '付款金額', '建立日期', '備註欄', '開閉清單', '訂單刪除'],
      detailToggleMap: {},
      title: data.title,
      dateSelection: data.dateSelection,
      dueDate: data.dueDate,
      minDate: new Date(),
      data: data.data
    };

    // methods
    dialogData.detailToggleCb = detailToggleCb;
    dialogData.removePayment = removePayment;
    dialogData.confirm = confirm;

    const params = { template: '/view/dialog/payments-selection-dialog.html', data: dialogData };

    const options = {
      showClose: false,
      className: 'ngdialog-theme-default yw-plain-dialog custom-width-1024',
      closeByEscape: true,
      closeByDocument: true
    };
    angular.extend(params, options);

    return ngDialog.openConfirm(params);

    function detailToggleCb (index) {
      console.log('abc');
      dialogData.detailToggleMap[index] = (dialogData.detailToggleMap[index] === undefined) ? true : !dialogData.detailToggleMap[index];
    }

    function removePayment (item, index) {
      dialogData.data.splice(index, 1);
    }

    function confirm (res, confirmCb) {
      if (dialogData.dateSelection && !res.dueDate) return setErrorMsg('請選擇繳費期限');
      const data = res.data.map(function (payment) {
        if (res.dueDate) { payment.dueDate = res.dueDate; }
        return payment;
      });
      confirmCb(data);
    }

    function setErrorMsg (msg) {
      dialogData.errorMsg = msg;
    }
  }
}];
