'use strict';

export default ['$timeout', '$log', 'confirmDialog', 'ywEvent', 'PushMessage',
  function directive ($timeout, $log, confirmDialog, ywEvent, PushMessage) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        userId: '=ywUserId',
        tipsDirection: '='
      },
      template: '<div class="popover-color-black">' +
        '<span ng-click="openConfirm()">' +
          '<i class="glyphicon glyphicon-envelope" aria-hidden="true" style="font-size:25px;" ' +
            'uib-popover="發送推播訊息" popover-trigger="\'mouseenter\'" popover-placement="{{tipsDirection}}"></i>' +
          '</span>' +
        '</div>',
      link: link
    };

    function link (scope, element, attr) {
      scope.openConfirm = function () {
        const dialogData = {
          title: '推播訊息',
          templateUrl: '/view/page/push-message-dialog-content.html',
          customDialog: true
        };

        confirmDialog.openConfirm(dialogData).then(function (data) {
          if (_.isEmpty(dialogData.message)) return Promise.reject(new Error('請輸入推播內容'));
          return PushMessage.post({
            userIds: [scope.userId],
            message: dialogData.message
          });
        }).then(res => {
          if (res.error) return ywEvent.emit('ALERT', res.error);
          ywEvent.emit('SUCCESS', '發送推播成功！');
        }).catch(err => {
          console.log(String(err));
          if (_.isUndefined(err) || err === '$document') return;
          ywEvent.emit('ALERT', String(err));
        });
      };
    }
  }
];
