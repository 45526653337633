/*
  example:
  <md-radio-group ng-model="selectedValue" md-no-ink>
    <life-radio ng-repeat="option in radioOptions track by $index" option="option" has-line="true" has-input="true"></life-radio>
  </md-radio-group>
*/

export default {
  bindings: {
    option: '<', // object, 必填, { value, label, inputContent }
    hasLine: '<', // boolean
    hasInput: '<', // boolean
    hint: '<' // string, 提示內容
  },
  template: `
    <div class="life-radio" ng-mouseover="$ctrl.mouseover()" ng-mouseleave="$ctrl.mouseleave()" ng-class="{'life-radio-isHover': $ctrl.isHover, 'life-radio-hasLine': $ctrl.hasLine}">
      <md-radio-button ng-value="$ctrl.option.value" class="md-primary">
        <div class="life-radio-label life-body-medium">{{$ctrl.option.label}}</div>
      </md-radio-button>
      <life-input ng-if="$ctrl.hasInput" placeholder="'label'" value="$ctrl.option.inputContent" class="life-radio-input"></life-input>
    </div>
    <div class="life-radio-hint life-body-small" ng-if="$ctrl.hint" ng-bind="$ctrl.hint"></div>
  `,
  controller: ['$element',
    function inputController ($element) {
      this.isHover = false;
      this.mouseover = () => {
        this.isHover = true;
      };
      this.mouseleave = () => {
        this.isHover = false;
      };
    }
  ]
};
