// Polyfill setImmediate function.
const setImmediate = window && window.setImmediate
  ? window.setImmediate
  : function (fn) {
    setTimeout(fn, 0);
  };

/**
 * CKEditor directive.
 *
 * @example
 * <div ckeditor="options" ng-model="content" ready="onReady()"></div>
 */

export default function ckeditorDirective ($parse) {
  return {
    restrict: 'A',
    require: ['ckeditor', 'ngModel'],
    controller: [
      '$scope',
      '$element',
      '$attrs',
      '$parse',
      'Upload',
      'API_URL',
      'ywEvent',
      '$q',
      ckeditorController
    ],
    link: function (scope, element, attrs, ctrls) {
      // get needed controllers
      const controller = ctrls[0]; // our own, see below
      const ngModelController = ctrls[1];
      // Initialize the editor content when it is ready.
      controller.ready().then(function initialize () {
        // Sync view on specific events.
        ['dataReady', 'change', 'blur', 'saveSnapshot', 'key'].forEach(function (event) {
          controller.onCKEvent(event, function syncView () {
            ngModelController.$setViewValue(controller.instance.getData() || '');
          });
        });

        controller.instance.setReadOnly(!!attrs.readonly);
        attrs.$observe('readonly', function (readonly) {
          controller.instance.setReadOnly(!!readonly);
        });

        // Defer the ready handler calling to ensure that the editor is
        // completely ready and populated with data.
        setImmediate(function () {
          $parse(attrs.ready)(scope);
        });
      });

      // Set editor data when view data change.
      ngModelController.$render = function syncEditor () {
        controller.ready().then(function () {
          // "noSnapshot" prevent recording an undo snapshot
          controller.instance.setData(ngModelController.$viewValue || '', {
            noSnapshot: true,
            callback: function () {
              // Amends the top of the undo stack with the current DOM changes
              // ie: merge snapshot with the first empty one
              // http://docs.ckeditor.com/#!/api/CKEDITOR.editor-event-updateSnapshot
              controller.instance.fire('updateSnapshot');
            }
          });
        });
      };
    }
  };
}

/**
 * CKEditor controller.
 */

function ckeditorController ($scope, $element, $attrs, $parse, Upload, API_URL, ywEvent, $q) {
  const config = $parse($attrs.ckeditor)($scope) || {
    width: '800px',
    height: '300px',
    extraPlugins: 'colorbutton, font, image, justify, table, iframe, stylesheetparser',
    toolbarGroups: [
      { name: 'document', groups: ['mode'] },
      { name: 'basicstyles', groups: ['basicstyles'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
      { name: 'links' },
      { name: 'insert' },
      '/',
      { name: 'styles' },
      { name: 'colors' },
      { name: 'tools' }
    ]
  };
  const editorElement = $element[0];
  let instance;
  const readyDeferred = $q.defer(); // a deferred to be resolved when the editor is ready

  // Create editor instance.
  if (editorElement.hasAttribute('contenteditable') &&
      editorElement.getAttribute('contenteditable').toLowerCase() === 'true') {
    instance = this.instance = CKEDITOR.inline(editorElement, config); // eslint-disable-line
  } else {
    instance = this.instance = CKEDITOR.replace(editorElement, config); // eslint-disable-line
  }

  instance.addCommand('uploadImage', {
    exec: function () {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/jpeg,image/png,image/webp';
      input.click();
      input.onchange = async () => {
        const image = _.get(input, 'files[0]');
        const res = await Upload.upload({
          url: API_URL + 'files',
          data: { file: image }
        });
        if (res.error) {
          ywEvent.emit('ALERT', res.error);
        } else {
          const element = CKEDITOR.dom.element.createFromHtml(`<img src="${_.get(res, 'data.result.s3Url')}" />`); // eslint-disable-line
          instance.insertElement(element);
        }
      };
    }
  });
  instance.ui.addButton('SuperButton', {
    label: '圖片',
    command: 'uploadImage',
    toolbar: 'insert',
    icon: '/img/ckeditor-image-icon.png'
  });

  /**
   * Listen on events of a given type.
   * This make all event asynchronous and wrapped in $scope.$apply.
   *
   * @param {String} event
   * @param {Function} listener
   * @returns {Function} Deregistration function for this listener.
   */

  this.onCKEvent = function (event, listener) {
    instance.on(event, asyncListener);

    function asyncListener () {
      const args = arguments;
      setImmediate(function () {
        applyListener.apply(null, args);
      });
    }

    function applyListener () {
      const args = arguments;
      $scope.$apply(function () {
        listener.apply(null, args);
      });
    }

    // Return the deregistration function
    return function $off () {
      instance.removeListener(event, applyListener);
    };
  };

  this.onCKEvent('instanceReady', function () {
    readyDeferred.resolve(true);
  });

  /**
   * Check if the editor if ready.
   *
   * @returns {Promise}
   */
  this.ready = function ready () {
    return readyDeferred.promise;
  };

  // Destroy editor when the scope is destroyed.
  $scope.$on('$destroy', function onDestroy () {
    // do not delete too fast or pending events will throw errors
    readyDeferred.promise.then(function () {
      instance.destroy(false);
    });
  });
}
