const isSameCustomization = (c1, c2) => {
  const keys = [
    'selectType',
    'quantityMin',
    'quantityMax',
    'isRepeatable',
    'isAcceptance'
  ];
  const isSameItem = (item1, item2) =>
    item1.name === item2.name && item1.addCost === item2.addCost;
  return (
    keys.every((key) => c1[key] === c2[key]) &&
    c1.items.length === c2.items.length &&
    c1.items.every((item, index) => isSameItem(item, c2.items[index]))
  );
};

export default ['confirmDialog', 'ywUtil', 'ywEvent', 'ServiceClassification', 'Service', function factory (confirmDialog, ywUtil, ywEvent, ServiceClassification, Service) {
  const defaultValue = {
    services: {
      options: [],
      radioSelected: undefined
    },
    customizations: []
  };

  const onClassificationChange = async (id, dialogData) => {
    dialogData.confirmValue = _.cloneDeep(defaultValue);

    const services = await Service.getList({
      limit: 99,
      classificationId: id,
      county: dialogData.reservation.address.county,
      district: dialogData.reservation.address.district,
      displayVisible: 'all'
    });
    dialogData.confirmValue.services.options = services;
    const classification = await ServiceClassification.one(id).customGET();
    dialogData.confirmValue.services.classification = classification;
    if (dialogData.confirmValue.services.classification.canCrossoverService) {
      dialogData.confirmValue.services.inputType = 'checkbox';
    } else {
      dialogData.confirmValue.services.inputType =
        !dialogData.classification.canCrossoverStandardService &&
        (services || []).every(service => !service.isStandardRepeatable)
          ? 'radio'
          : 'checkbox';
    }
  };

  const onServiceChange = (dialogData) => {
    const targetServices = dialogData.confirmValue.services.inputType === 'radio'
      ? dialogData.confirmValue.services.options.filter(
        service => service._id === dialogData.confirmValue.services.radioSelected
      )
      : dialogData.confirmValue.services.options.filter(service => service.checked);
    dialogData.confirmValue.customizations = targetServices
      .map(service => service.customizations || [])
      .reduce(
        (results, customizations) => _.unionWith(results, customizations, isSameCustomization),
        []
      );
  };

  const onCustomizationCheckboxChecked = (item, customization, dialogData) => {
    if (item.checked && customization.quantityMax) {
      const currentItemCount = customization.items
        .map(item => item.checked ? 1 : 0)
        .reduce((sum, count) => sum + count, 0);
      if (currentItemCount > customization.quantityMax) {
        item.checked = false;
      }
    }
  };

  const onCustomizationItemAdd = (item, customization, dialogData) => {
    item.count = _.min([(item.count || 0) + 1, (customization.quantityMax || Number.MAX_SAFE_INTEGER)]);
  };
  const onCustomizationItemMinus = (item, customization, dialogData) => {
    item.count = _.max([(item.count || 0) - 1, 0]);
  };

  const initServicesAndCustomizations = async (reservation, dialogData) => {
    dialogData.classification.selected = reservation.serviceClassification._id;
    await onClassificationChange(dialogData.classification.selected, dialogData);
    if (dialogData.confirmValue.services.inputType === 'radio') {
      dialogData.confirmValue.services.radioSelected = _.get(reservation, 'services[0].serviceId._id');
    } else {
      dialogData.confirmValue.services.options.forEach(service => {
        service.checked = !!reservation.services.find(info => info.serviceId._id === service._id);
      });
    }

    onServiceChange(dialogData);
    dialogData.confirmValue.customizations.forEach(customizationOption => {
      reservation.customizations.forEach(customization => {
        if (customizationOption._id !== customization.customizationId) {
          return;
        }
        if (customizationOption.selectType === 'single') {
          customizationOption.selected = customization.itemId;
        } else if (customizationOption.selectType === 'multiple') {
          (customizationOption.items || []).forEach(itemOption => {
            if (itemOption._id === customization.itemId) {
              itemOption.checked = true;
              itemOption.customizedValue = customization.customizedValue;
            }
          });
        } else if (customizationOption.selectType === 'customizedInput') {
          (customizationOption.items || []).forEach(itemOption => {
            if (itemOption._id === customization.itemId) {
              itemOption.customizedValue = customization.customizedValue;
            }
          });
        }
      });
    });
  };

  async function openConfirm (unorderedReservation, showCurrentSelection) {
    const params = {
      title: '編輯服務分類與方案',
      templateUrl: '/view/dialog/service-edit-dialog.html',
      reservation: unorderedReservation,
      classification: {
        selected: undefined,
        options: []
      },
      confirmValue: _.cloneDeep(defaultValue),

      // methods
      onClassificationChange,
      onServiceChange,
      onCustomizationCheckboxChecked,
      onCustomizationItemAdd,
      onCustomizationItemMinus,

      confirmWhenResolve: (result, dialogData, confirmCb) => {
        // data validation
        const { services, customizations } = result;
        if (services.inputType === 'radio' && !services.radioSelected) {
          return ywEvent.emit('ALERT', '請選擇服務');
        }
        if (services.inputType === 'checkbox' && !services.options.some(service => service.checked)) {
          return ywEvent.emit('ALERT', '請選擇服務');
        }
        customizations.forEach(customization => {
          if (customization.selectType === 'single' && !customization.selected) {
            ywEvent.emit('ALERT', `請選擇${customization.title}`);
            throw new Error('customization validate failed');
          }
          if (customization.selectType === 'multiple') {
            const count = customization.items
              .map(item => customization.isRepeatable ? (item.count || 0) : (item.checked ? 1 : 0))
              .reduce((sum, count) => sum + count, 0);
            if (customization.quantityMin && count < customization.quantityMin) {
              ywEvent.emit('ALERT', `${customization.title}需至少選擇${customization.quantityMin}項`);
              throw new Error('customization validate failed');
            }
            if (customization.quantityMax && count > customization.quantityMax) {
              ywEvent.emit('ALERT', `${customization.title}最多選擇${customization.quantityMax}項`);
              throw new Error('customization validate failed');
            }
          }
          customization.items.forEach(item => {
            const isRequiredField = item.required && (
              customization.selectType === 'customizedInput' ||
                (customization.selectType === 'single' && customization.selected === item._id) ||
                (customization.selectType === 'multiple' && (item.checked || item.count))
            );
            if (isRequiredField && !item.customizedValue) {
              ywEvent.emit('ALERT', `${item.name}輸入欄位必填`);
              throw new Error('customization validate failed');
            }
          });
        });
        confirmCb(result);
      }
    };
    const classifications = await ServiceClassification.getList({ reservationListMode: 'stateUnordered', displayVisible: 'all' });
    params.classification.options = classifications.map(item => ({ label: item.name, value: item._id }));

    // initial current selection
    if (showCurrentSelection) {
      initServicesAndCustomizations(unorderedReservation, params);
    }

    const result = await confirmDialog.openConfirm(params, 'custom-width-680');
    const { services, customizations } = result;

    // data transfer
    const transferedCustomizations = _.chain(customizations)
      .map(customization => { // eslint-disable-line
        if (customization.selectType === 'single') {
          return {
            itemId: customization.selected,
            count: 1,
            customizedValue: customization.items.find(item => item._id === customization.selected).customizedValue
          };
        } else if (customization.selectType === 'multiple') {
          return customization.items
            .filter(item => item.checked || item.count > 0)
            .map(item => ({ itemId: item._id, count: item.count || 1, customizedValue: item.customizedValue }));
        } else if (customization.selectType === 'customizedInput') {
          return customization.items
            .filter(item => !_.isEmpty(item.customizedValue))
            .map(item => ({ itemId: item._id, count: 1, customizedValue: item.customizedValue }));
        }
      })
      .flatten()
      .value();

    return {
      serviceClassificationId: params.classification.selected,
      services: services.inputType === 'radio'
        ? [{ serviceId: services.radioSelected, count: 1 }]
        : services.options.filter(service => service.checked).map(service => ({ serviceId: service._id, count: 1 })),
      customizations: transferedCustomizations
    };
  }

  return { openConfirm };
}];
