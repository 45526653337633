/*
  example:
  <life-tab tabs="labels"></life-tab>
*/

export default {
  bindings: {
    tabs: '<' // object, 必填, { label, templateUrl, htmlContent }
  },
  template: `
    <div class="life-tab">
      <md-tabs md-dynamic-height md-swipe-content>
        <md-tab ng-repeat="tab in $ctrl.tabs track by $index" label="{{tab.label}}" class="life-title-small" md-on-select="$ctrl.onTabSelected(tab)">
        </md-tab>
      </md-tabs>
      <div ng-if="$ctrl.selectedTab.templateUrl" ng-include="$ctrl.selectedTab.templateUrl"></div>
      <div ng-if="$ctrl.selectedTab.htmlContent" ng-bind-html="$ctrl.selectedTab.htmlContent"></div>
    </div>
  `,
  controller: ['$element',
    function tabController ($element) {
      this.onTabSelected = (tab) => {
        this.selectedTab = tab;
      };
    }
  ]
};
