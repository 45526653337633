export default ['Restangular', '$filter', 'ywTimezone', function factory (Restangular, $filter, ywTimezone) {
  Restangular.addElementTransformer('ta3Banners', false, _transformer);
  const TA3Banner = Restangular.service('ta3Banners');
  return TA3Banner;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'order', ''),
      { anchorType: 'true-or-false', isTrue: _.get(item, 'isVisible') },
      _.get(item, 'name', ''),
      $filter('date')(_.get(item, 'visibility.period.from'), 'yyyy/MM/dd', ywTimezone.get()) +
        ' - ' +
        $filter('date')(_.get(item, 'visibility.period.to'), 'yyyy/MM/dd', ywTimezone.get()),
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
