export const tokenRefreshInterceptorConfig = ['$httpProvider', function ($httpProvider) {
  $httpProvider.interceptors.push('tokenRefreshInterceptor');
}];

export const tokenRefreshInterceptor = ['$q', '$injector', '$location', function ($q, $injector, $location) {
  let replays = [];
  let refreshTokenPromise = null;

  return {
    response: function (response) {
      if (response.status === 401 || _.get(response, 'data.error.code') === 401) {
        const session = $injector.get('session');
        if (!session.refreshToken) {
          return response;
        }
        return $q(function (resolve, reject) {
          const replay = {
            success: function (token) {
              response.config.headers.Authorization = `Bearer ${token}`;
              $injector.get('$http')(response.config).then(resolve, reject);
            },
            cancel: function () {
              reject(response);
            }
          };

          replays.push(replay);

          if (!refreshTokenPromise) {
            const API_URL = $injector.get('API_URL');
            refreshTokenPromise = fetch(
              `${API_URL}users/refreshToken`,
              {
                method: 'POST',
                body: JSON.stringify({ refreshToken: session.refreshToken, origin: 'lsWeb' }),
                headers: { 'Content-Type': 'application/json' }
              }
            )
              .then((res) => res.json())
              .then(replaceAuthData)
              .then(clearRefreshTokenPromise)
              .then(replayRequests)
              .catch(cancelRequestsAndRedirect);
          }
        });
      }
      return response;
    }
  };

  function replaceAuthData (result) {
    const data = _.get(result, 'result');
    $injector.get('session').set(data);
    $injector.get('currentUser').setAuthToken();
    return data;
  }

  function clearRefreshTokenPromise (auth) {
    refreshTokenPromise = null;
    return auth;
  }

  function replayRequests (auth) {
    replays.forEach(function (replay) {
      replay.success(auth._id);
    });
    replays = [];
    return auth;
  }

  function cancelRequestsAndRedirect (err) {
    console.log('cancelRequestsAndRedirect: ', err);
    refreshTokenPromise = null;
    replays.forEach(function (replay) {
      replay.cancel();
    });
    replays = [];

    $injector.get('currentUser').logout();
  }
}];
