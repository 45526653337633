export default ['$timeout', 'Restangular', 'ywDialog', 'ywEvent', 'User', '$filter',
  function directive ($timeout, Restangular, ywDialog, ywEvent, User, $filter) {
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        label: '=ywLabel',
        building: '=buildingSettings',
        floor: '=floorSettings',
        room: '=roomSettings',
        userName: '=',
        confirmed: '=',
        familyCodeCallback: '=',
        clear: '=',
        refresh: '=',
        defaultTab: '='
      },
      templateUrl: '/view/directive/yw-user-selector.html',
      link: link
    };

    function link (scope, element, attr) {
      scope.customizeOptionService = Restangular.service('families/findOneAndUpdate');
      scope.userNameDisabled = true;

      scope.selectTab = (index) => {
        if (scope.refresh) scope.refresh();
        if (scope.clear) scope.clear(false, 'clearReceiver', index);
      };

      scope.inputChange = function () {
        scope.familyCodeShortAddr = undefined;
        scope.barcodeShortAddr = undefined;
        scope.userNameDisabled = true;
        if (scope.refresh) scope.refresh();
      };

      scope.familyCodeSearch = function (event) {
        if (!((event && event.key === 'Enter') || (event && event.type === 'blur'))) return;
        if (!this.familyCode) return;
        Restangular.service('families').getList({ code: this.familyCode }).then(res => {
          const family = _.head(res.plain());
          if (_.isEmpty(family)) {
            scope.familyCodeShortAddr = '查無戶別編碼';
            if (scope.refresh) scope.refresh();
            return;
          }
          if (scope.clear && scope.building.lastSelected && scope.floor.lastSelected && scope.room.lastSelected !== undefined) {
            scope.clear();
          }
          scope.userNameDisabled = false;
          ['building', 'floor', 'room'].forEach(param => {
            scope[param].selected = family[param];
          });
          scope.room.changed(family.room);
          ['building', 'floor', 'room'].forEach(param => {
            scope[param].lastSelected = scope[param].selected;
          });
          scope.familyCodeShortAddr = $filter('short-addr')(family);
          if (scope.refresh) scope.refresh();
        });
      };

      scope.barcodeSearch = async function (event) {
        if (!((event && event.key === 'Enter') || (event && event.type === 'blur'))) return;
        if (!this.barcode) return;
        let result = await Restangular.service('families').one().get({ identifier: this.barcode });
        let family = result.plain()[0];
        if (result.error) {
          result = await Restangular.service('families/verifiedCode').get(this.barcode);
          family = result.plain();
        }
        if (family.error) {
          scope.barcodeShortAddr = '查無此條碼';
          if (scope.refresh) {
            setTimeout(() => {
              scope.refresh();
            }, 200);
          }
          return;
        }
        if (scope.clear && scope.building.lastSelected && scope.floor.lastSelected && scope.room.lastSelected !== undefined) {
          scope.clear();
        }
        scope.userNameDisabled = false;
        ['building', 'floor', 'room'].forEach(param => {
          scope[param].selected = family[param];
        });
        scope.room.changed(family.room);
        ['building', 'floor', 'room'].forEach(param => {
          scope[param].lastSelected = scope[param].selected;
        });
        scope.barcodeShortAddr = $filter('short-addr')(family);
        if (scope.refresh) scope.refresh();
      };

      scope.selectionBtnClicked = function () {
        const params = {
          where: {
            building: (scope.building.selected !== undefined ? scope.building.selected : ''),
            floor: (scope.floor.selected !== undefined ? scope.floor.selected : ''),
            room: (scope.room.selected !== undefined ? scope.room.selected : '')
          }
        };
        let promise = Restangular.service('families').getList(params).then(function (res) {
          scope.members = res.plain().reduce(function (prevResult, currFamily) {
            if (currFamily && currFamily.members) { return prevResult.concat(currFamily.members.map(function (member) { return { fullname: member.fullname }; })); } else { return prevResult; }
          }, []);

          // set displayed members
          scope.displayMembers = scope.members.map(function (member) { return { value: member.fullname, label: member.fullname, removable: true }; });
          return Promise.resolve(scope.members);
        });

        promise = promise.then(function () {
          return User.getList(params.where).then(function (res) {
            console.log('User.getList: ', res);
            const defaultMembers = res.map(function (member) { return { fullname: member.obj.fullname, objectId: member.obj.objectId }; });
            if (_.isEmpty(defaultMembers)) return Promise.resolve(defaultMembers);

            // set displayed members
            scope.displayMembers = defaultMembers.map(function (member) {
              return {
                value: member.fullname,
                label: member.fullname,
                objectId: member.objectId,
                removable: false
              };
            }).concat(scope.displayMembers);
            return Promise.resolve(defaultMembers);
          });
        });

        promise.then(function () {
          console.log('displayMembers: ', scope.displayMembers);
          const dialogOpts = { plain: true, closeByEscape: true, closeByDocument: true };
          const data = {
            options: scope.displayMembers,
            title: scope.label,
            selected: scope.userName,
            showFooter: true,
            openCustomizeCreationDialog: scope.openCustomizeCreationDialog,
            removeCustomizeOption: scope.removeCustomizeOption
          };

          ywDialog.openConfirm('/view/directive/popup-selection-menu.html', data, null, dialogOpts)
            .then(function (item) { // item selected callback
              scope.userName = item.value;
              $timeout(function () {
                scope.$apply();
                scope.confirmed(scope.userName, item);
              });
            });
        });
      };

      scope.openCustomizeCreationDialog = function (closeCb) {
        if (closeCb) closeCb();

        const dialogOpts = { closeByEscape: true, closeByDocument: true };
        ywDialog.openConfirm('/view/directive/custom-option-creation-dialog.html', {}, null, dialogOpts)
          .then(function (data) {
            if (!data) return;

            if (scope.members.find(function (member) { return member.fullname === data; }) !== undefined) { return ywEvent.emit('ALERT', '名稱不可重複'); }

            scope.members.push({ fullname: data });
            const reqData = { members: scope.members };
            const reqParams = {
              building: scope.building.selected,
              floor: scope.floor.selected,
              room: scope.room.selected
            };
            scope.customizeOptionService.one().customPOST(reqData, '', reqParams).then(function (res) {
              scope.userName = data;
              $timeout(function () {
                scope.$apply();
                scope.confirmed(scope.userName);
              });
            });
          });
      };

      scope.removeCustomizeOption = function (e, value) {
        e.stopImmediatePropagation();

        _.remove(scope.members, function (member) { return member.fullname === value; });

        const reqParams = {
          building: scope.building.selected,
          floor: scope.floor.selected,
          room: scope.room.selected
        };
        const reqData = {
          members: scope.members
        };
        scope.customizeOptionService.one().customPOST(reqData, '', reqParams).then(function (res) {
          _.remove(scope.displayMembers, function (member) { return member.value === value; });
          $timeout(function () {
            scope.$apply();
          });
        });
      };

      scope.userNameModified = function (removeUserName) {
        $timeout(function () {
          scope.$apply();
          if (removeUserName) scope.userName = undefined;
          scope.confirmed(scope.userName);
        });
      };

      scope.addressChanged = async function (value, paramName) {
        if (scope.clear && scope.building.lastSelected && scope.floor.lastSelected && scope.room.lastSelected !== undefined) {
          scope.clear();
          if (paramName === 'building') {
            scope.floor.selected = undefined;
            scope.room.selected = undefined;
          } else if (paramName === 'floor') {
            scope.room.selected = undefined;
          }
        }
        if (scope[paramName] && scope[paramName].changed) { await scope[paramName].changed(paramName, value); }
        ['building', 'floor', 'room'].forEach(param => {
          scope[param].lastSelected = scope[param].selected;
        });
        ['building', 'floor', 'room'].every(function (param) {
          if (scope[param].selected === undefined) { element[0].querySelector('yw-popup-selection.' + param + ' a.select').click(); }
          return scope[param].selected !== undefined;
        });
        if (scope.building.selected && scope.floor.selected && scope.room.selected !== undefined) {
          scope.userNameDisabled = false;
        } else {
          scope.userNameDisabled = true;
        }
      };
    }
  }];
