import React, { Fragment } from 'react';

import LabelList from './LabelList';

const ServiceClassificationCell = ({ reservation }) => {
  return (
    <>
      <div>
        <LabelList labels={_.get(reservation, 'labels', [])} />
      </div>
      {_.get(reservation, 'serviceClassification.name')}
    </>
  );
};

export default ServiceClassificationCell;
