// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/btn_form_photo_p.png");
var ___HTML_LOADER_IMPORT_1___ = require("/img/loading.gif");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var code = "<div style=\"text-align: left; padding: 0 15px;\">\n  <span\n    class=\"cell\"\n    yw-upload=\"ngDialogData._files\"\n    success=\"ngDialogData.success(result);\"\n    hook=\"ngDialogData.hook\"\n    error=\"ngDialogData.error(message)\"\n    multiple-upload=\"true\"\n    compress=\"true\"\n    file-size-limit=\"1000000\"\n  >\n      <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" width=\"80\"></img>\n  </span>\n  <span ng-repeat=\"(key, urlObj) in ngDialogData.confirmValue track by $index\">\n    <span style=\"display: inline-block; max-width: 93px; vertical-align: top;\">\n      <span class=\"cell\" style=\"position: relative; display: inline-block;\">\n        <img ng-src=\"{{ urlObj.thumbImage.url || item.defaultThumb }}\" width=\"80\" height=\"80\">\n        <a class='cross' ng-click='ngDialogData.removeFile($index)'\n          style=\"position: absolute; top: 0; right: 0; background-color: white;\"\n        >╳\n        </a>\n      </span>\n    </span>\n  </span>\n  <!-- loading widgets -->\n  <span class=\"cell\" ng-repeat=\"(key, fileObj) in ngDialogData._files\" ng-if=\"fileObj.progress >= 0\" >\n      <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" class=\"loading\" width=\"80\" height=\"80\"></img>\n  </span>\n</div>\n";
// Exports
var _module_exports = code;;
var path = '/view/dialog/upload-images.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;