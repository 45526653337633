export default ['ywEvent', 'confirmDialog', 'shortUrl', function directive (ywEvent, confirmDialog, shortUrl) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      fieldLabel: '='
    },
    template: `
      <md-button class="md-raised md-primary fit" ng-click="openUrlShorter()">短網址產生器</md-button>
    `,
    link: link
  };

  function link (scope, element, attr) {
    const dialogData = {
      generateShortUrl,
      shortUrl: '',
      isLoading: false,
      fieldLabel: scope.fieldLabel || '請輸入連結 ex: https://livinglife.com.tw/',
      copySuccessed
    };
    function copySuccessed () {
      ywEvent.emit('SUCCESS', '已複製到剪貼簿');
    }
    async function generateShortUrl (url) {
      const encodeUrl = encodeURI(url);
      try {
        dialogData.isLoading = true;
        const shorUrls = await shortUrl([encodeUrl]);
        if (shorUrls.error) throw new Error(shorUrls.error);
        dialogData.shortUrl = shorUrls[encodeUrl];
      } catch (error) {
        console.log(error);
        ywEvent.emit('ALERT', error);
      } finally {
        dialogData.isLoading = false;
      }
    }
    scope.openUrlShorter = async () => {
      await confirmDialog.openConfirm({
        templateUrl: '/view/directive/url-shorter.html',
        hideCancel: true,
        confirmLabel: '關閉',
        data: dialogData
      });

      dialogData.shortUrl = '';
    };
  }
}];
