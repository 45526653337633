import moment from 'moment';

export default ['$scope', '$async', 'ywUtil', 'currentUser', 'Banner', 'confirmDialog', 'address', 'Restangular',
  function controller ($scope, $async, ywUtil, currentUser, Banner, confirmDialog, address, Restangular) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    $scope.searchBarOptions = [
      {
        btnName: '活動名稱',
        placeholder: '請輸入活動名稱',
        params: 'name'
      }
    ];
    $scope.searchBarOptionsActive = _.head($scope.searchBarOptions);

    $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

    function changeSearchBarActiveOption (currentOption, newOption) {
      $scope.searchBarOptionsActive = newOption;
      $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
      _.unset($scope.mainAction.queryParams, currentOption.params);
    }

    $scope.customTextSearchCb = _.debounce(function (text, page, name) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
      $scope.mainAction.filterQuery(text, page, name);
    }, 1000, {
      leading: true,
      trailing: false
    });
    $scope.customTextChangeCb = function (text) {
      $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
        ? undefined
        : text;
    };

    const countyOptions = address.getSortedCounties;
    const districtInput = {
      name: '_districts',
      label: '地區',
      options: [],
      changed: (districts) => {
        console.log('districts: ', districts);
        $scope.mainAction.queryParams.regions = address.getRegions(
          $scope.mainAction.queryParams._counties,
          districts
        );
        $scope.mainAction.setClearFilterTrue();
      },
      type: 'multiFilter',
      byCategory: true
    };
    const syncDistrictOptions = function (counties) {
      if (_.isEmpty(counties)) {
        $scope.mainAction.queryParams._districts = [];
        delete $scope.mainAction.queryParams.regions;
        districtInput.options = [];
      } else {
        districtInput.options = address.syncDistrictOptions(counties);
        $scope.mainAction.queryParams._districts =
          ($scope.mainAction.queryParams._districts || []).filter(
            district => counties.find(county => district.startsWith(county))
          );
        $scope.mainAction.queryParams.regions = address.getRegions(
          counties,
          $scope.mainAction.queryParams._districts
        );
      }
      $scope.mainAction.setClearFilterTrue();
    };

    const sourceFilterInput = {
      name: 'visibleSource',
      label: '特定通路',
      options: [],
      type: 'multiFilter',
      click: $async(async () => {
        const defaultOptions = [
          { value: 'unlimited', label: '不限定通路' },
          { value: 'lsApp', label: '信義居家App' },
          { value: 'lsWeb', label: '信義居家Web' }
        ];
        const settings = await Restangular.service('systemSettings').one('sourceRestrictionSetting').get();
        const sourceSettingOptions = settings.error
          ? []
          : settings.map(setting => ({ value: setting, label: setting }));
        sourceFilterInput.options = defaultOptions.concat(sourceSettingOptions);
      })
    };

    $scope.filterItems = [
      {
        name: '_counties',
        label: '縣市',
        options: countyOptions,
        changed: syncDistrictOptions,
        type: 'multiFilter'
      },
      districtInput,
      sourceFilterInput,
      {
        name: 'displayAt',
        label: '版位',
        options: [
          { value: 'homepage', label: '首頁' },
          { value: 'articlepage', label: '文章頁面' },
          { value: 'articleIdleReminder', label: '文章閒置提醒' },
          { value: 'articleListAdvertisement', label: '文章列表廣告' }
        ],
        type: 'multiFilter'
      },
      {
        name: 'isVisible',
        label: '顯示設定',
        options: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }],
        type: 'noOwnAttrSelect'
      }
    ];

    /* Order & date filter */
    $scope.customizeContentTemplate = '/view/template/banner-filter.html';
    $scope.extraFilter = {
      durationType: {
        options: [
          { value: 'createdTime', label: '依照建立時間' },
          { value: 'visibilityPeriod', label: '依照活動時間' }
        ],
        selected: 'createdTime'
      },
      startDate: {
        value: undefined,
        changed: (date) => {
          $scope.extraFilter.startDate.value = moment(date).startOf('day').toDate();
        }
      },
      dueDate: {
        value: undefined,
        changed: (date) => {
          $scope.extraFilter.dueDate.value = moment(date).endOf('day').toDate();
        }
      }
    };
    const resetExtraParams = () => {
      $scope.extraFilter.durationType.selected = 'createdTime';
    };

    $scope.customClearFilter = () => {
      resetExtraParams();
      $scope.mainAction.clearFilter([], $scope.searchBar.textInput);
    };
    $scope.customFilterQuery = () => {
      const startDate = $scope.extraFilter.startDate.value;
      const dueDate = $scope.extraFilter.dueDate.value;
      if ($scope.extraFilter.durationType.selected === 'createdTime') {
        delete $scope.mainAction.queryParams.periodFrom;
        delete $scope.mainAction.queryParams.periodTo;
        $scope.mainAction.queryParams.createdAfterOrEqualTo = startDate && moment($scope.extraFilter.startDate.value).startOf('day').toDate();
        $scope.mainAction.queryParams.createdBeforeOrEqualTo = dueDate && moment($scope.extraFilter.dueDate.value).endOf('day').toDate();
      } else if ($scope.extraFilter.durationType.selected === 'visibilityPeriod') {
        delete $scope.mainAction.queryParams.createdAfterOrEqualTo;
        delete $scope.mainAction.queryParams.createdBeforeOrEqualTo;
        $scope.mainAction.queryParams.periodFrom = startDate && moment($scope.extraFilter.startDate.value).startOf('day').toDate();
        $scope.mainAction.queryParams.periodTo = dueDate && moment($scope.extraFilter.dueDate.value).endOf('day').toDate();
      }
      $scope.mainAction.filterQuery();
    };

    const init = $async(async () => {
      // rendering options
      $scope.searchBar = ywUtil.getSearchbarSettings();
      $scope.searchBar.hideSearchAndClearBtn = true;
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();

      $scope.queryPage = 'banner'; // for GA

      resetExtraParams();
      $scope.mainAction.initResource(Banner);
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
        isVisible: true,
        _visibilityLocationType: 'noRestriction',
        _counties: [],
        _districts: [],
        _visibleSources: { ls: true, others: false, webviews: [] }
      };
    }

    $scope.openAddressDetailDialog = (item) => {
      confirmDialog.openConfirm({
        title: '縣市/地區',
        templateUrl: '/view/dialog/address-detail.html',
        hideCancel: true,
        regions: _.get(item, 'visibility.location.regions', [])
      });
    };
  }];
