export default ['$scope', '$async', '$state', '$stateParams', 'ywUtil', 'currentUser', 'AdvancedFilterItem',
  function controller ($scope, $async, $state, $stateParams, ywUtil, currentUser, AdvancedFilterItem) {
    $scope.filterItems = [];
    $scope.tableHead = [];
    $scope.initActionItem = initActionItem;

    const init = $async(async () => {
      // rendering options
      $scope.toolbar = ywUtil.getToolbarSettings();
      $scope.tableHead = ywUtil.getTableHeader();
      $scope.searchBar = ywUtil.getSearchbarSettings();
      // ywUtil.initSearchBarOptions(
      // $scope,
      // [
      // ]
      // );

      $scope.filterItems = [
      ];

      $scope.queryPage = ''; // for GA

      $scope.mainAction.initResource(
        AdvancedFilterItem,
        { serviceClassification: $stateParams.serviceClassificationId }
      );
    });

    init();

    function initActionItem () {
      $scope.currentActionItem = {
      };
    }
  }];
