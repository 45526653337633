export default function () {
  return function (input) {
    if (!input) return '';

    const roleMap = {
      admin: '系統管理員',
      serviceAdmin: '居家管理員',
      vender: '廠商',
      venderStaff: '服務員'
    };

    return roleMap[input] || '';
  };
}
