/*
  example:
  <life-label-input label="'labelText'" value="inputValue" type="'number'"></life-label-input>
*/

export default {
  bindings: {
    label: '<', // string, 必填, input 的欄位名稱
    type: '<', // string, default: 'text'
    value: '=', // string or number, 必填
    disabled: '<', // boolean
    hint: '<', // string, 提示內容
    max: '<', // number
    min: '<' // number,
  },
  template: `
    <md-input-container class="life-input">
      <label ng-class="{'life-label-disabled' : $ctrl.disabled}" ng-bind="$ctrl.label"></label>
      <input
        ng-attr-type="{{$ctrl.type || 'text'}}"
        ng-model="$ctrl.value"
        ng-disabled="$ctrl.disabled"
        ng-change="$ctrl.onChanged()" />
      <div class="hint" ng-if="$ctrl.hint" ng-bind="$ctrl.hint"></div>
    </md-input-container>
  `,
  controller: ['$element',
    function labeInputController ($element) {
      this.$onInit = () => {
        this.onChanged = () => {
          if (_.isNumber(this.max) && this.value > this.max) {
            this.value = this.max;
          }
          if (_.isNumber(this.min) && this.value < this.min) {
            this.value = this.min;
          }
        };
      };
    }
  ]
};
