import React, { Fragment } from 'react';
import { StatefulToolTip } from 'react-portal-tooltip';

import { deniedMethods } from './../../../share';

const UserInfo = ({ reservation }) => {
  const deniedTypes = ['isExcludeAutoDispatch', 'isOnlyCardPayment', 'isForidLogin'];
  const isUserDenied = deniedTypes.some(type => _.get(reservation, `user.deniedSetting.${type}`));

  return (
    <>
      {_.get(reservation, 'name')}
      {isUserDenied &&
        <StatefulToolTip
          parent={<span className='fas fa-ban color-red' style={{ fontSize: 28 }} />}
          position='top' arrow='center'
        >
          <div>黑名單限制方式：{deniedMethods(_.get(reservation, 'user.deniedSetting', {}))}</div>
          <div>原因：{_.get(reservation, 'user.deniedSetting.reason')}</div>
        </StatefulToolTip>}
    </>
  );
};

export default UserInfo;
