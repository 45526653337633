export default {
  bindings: {
    reservation: '<'
  },
  template: `
    <i
      class="fas pull-right ml-15 fa-file-invoice-dollar"
      tooltip-class="remove-triangle"
      ng-class="{'red-font': $ctrl.reservation.billProcessParticularStatus !== 'none'}"
      ng-click="$ctrl.onClick($ctrl.reservation)"
      uib-dropdown-toggle
      uib-tooltip="
      訂單出帳設定：{{$ctrl.reservation.billProcessParticularStatus === 'none' ? '開啟' : '關閉'}}
      出帳：計算應收轉介費到該期帳單(每月20號)，提供廠商繳款。
      "
    />
  `,
  controller: ['$element', '$async', 'currentUser', 'ywEvent', 'confirmDialog', 'Reservation', 'UnorderedReservation',
    function reservationTerminateFee ($element, $async, currentUser, ywEvent, confirmDialog, Reservation, UnorderedReservation) {
      this.onClick = $async(async (reservation) => {
        const _resource = _.get(reservation, 'reservationNo') ? Reservation : UnorderedReservation;

        await confirmDialog.openConfirm({
          title: '訂單出帳設定',
          content: reservation.billProcessParticularStatus === 'none' ? '關閉訂單出帳設定？' : '開啟訂單出帳設定？'
        });
        const res = await _resource
          .one(reservation._id)
          .one('skipBillProcess')
          .customPOST({
            isSkip: reservation.billProcessParticularStatus === 'none'
          });
        if (res.error) {
          ywEvent.emit('ALERT', res.error);
        } else {
          reservation.billProcessParticularStatus = res.billProcessParticularStatus;
          ywEvent.emit('UPDATE_ITEM_IN_LIST', reservation);
          ywEvent.emit('SUCCESS', '已變更');
        }
      });

      this.$onInit = () => {
        this.isServiceAdmin = currentUser.hasRole('serviceAdmin');
      };
    }]
};
