export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('articleClassifications', false, _transformer);
  const ArticleClassification = Restangular.service('articleClassifications');
  return ArticleClassification;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      _.get(item, 'sort', ''),
      { anchorType: 'true-or-false', isTrue: _.get(item, 'isVisible') },
      _.get(item, 'name', ''),
      _.get(item, 'keywords', []).map(keyword => keyword.name).join('、'),
      { anchorType: 'edit' }
    ];

    return item;
  }
}];
