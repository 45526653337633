import moment from 'moment';

export default ['$scope', '$async', 'Upload', 'API_URL', 'address', 'ServiceClassification', 'UserGroup', 'Service',
  function controller ($scope, $async, Upload, API_URL, address, ServiceClassification, UserGroup, Service) {
    const self = this;
    $scope.customizeActionContentTmpl = '/view/page/admin/tabset-action-page.html';

    self.displayImageFiles = {};
    self.uploaded = $scope.imageUploaded;
    self.singleFileUploaded = $scope.singleFileUploaded;
    $scope.inputItems = [];
    $scope.isPackageActivate = false; // 是否可以設定包套
    $scope.paymentMethodCheckboxes = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    function validator () {
      const item = $scope.currentActionItem;
      console.log('validator:', item);
      if (_.isEmpty(item.name)) return $scope.alert('服務名稱不能為空');
      if (!item.classificationId) return $scope.alert('請選擇服務方案分類');
      if (item.isActivate && !item.copyService) return $scope.alert('請選擇包套複製方案');

      // 包套方案設定
      if (item.isActivate) {
        if (!item.times) return $scope.alert('必須填寫包套次數');
        if (!item.everyN || !item.unit) return $scope.alert('必須填寫包套週期');
        if (item.times && (!_.isInteger(item.times) || item.times < 0)) {
          return $scope.alert('包套次數需為正整數');
        } else if (item.times && _.isInteger(item.times) && item.times > 100) {
          return $scope.alert('包套次數不得超過上限100次');
        }
        if (item.everyN && (!_.isInteger(item.everyN) || item.everyN < 0)) {
          return $scope.alert('包套週期頻率需為正整數');
        } else if (item.everyN && _.isInteger(item.everyN) && item.everyN > 100) {
          return $scope.alert('包套週期不得超過上限100次');
        }
      }

      // 價格設定
      if (item.isStandard && _.isNil(item.basicPrice)) return $scope.alert('一般價格不能為空');
      const specialBasicPrices = item.specialBasicPrices || [];
      if (specialBasicPrices.some(priceInfo => _.isEmpty(priceInfo.counties))) {
        return $scope.alert('請選擇一般價格之特定縣市');
      }
      if (specialBasicPrices.some(priceInfo => !_.isInteger(priceInfo.price) || priceInfo.price < 0)) {
        return $scope.alert('特定縣市價格須為正整數');
      }
      const specialPrices = item.specialPrices || [];
      if (!specialPrices.every(spPrice => validateTimeline(_.head(spPrice.timelines)))) {
        return false;
      }
      function validateTimeline (timeline) {
        if (timeline.type === 'time' && !timeline.weekday) {
          return $scope.alert('請選擇特殊供應價格時間');
        }
        if (timeline.type === 'date' && !timeline.date) {
          return $scope.alert('請選擇特殊供應價格時間');
        }
        return true;
      }
      if (specialPrices.some(spPrice => _.isNil(spPrice.price))) {
        return $scope.alert('特殊供應價格金額不能為空');
      }

      if (item.recentPickableInterval && (!_.isInteger(item.recentPickableInterval) || item.recentPickableInterval < 0)) {
        return $scope.alert('最近可預約日數需為正整數');
      }

      if (item.lastCancelDays && (!_.isInteger(item.lastCancelDays) || item.lastCancelDays < 0)) {
        return $scope.alert('最晚可取消日數需為正整數');
      }

      // 最晚申請可取消日數
      if (!item.lastApplyDays) {
        return $scope.alert('最晚線上申請改期日數需為必填');
      } else {
        if (!_.isInteger(item.lastApplyDays) || item.lastApplyDays < 0) {
          return $scope.alert('最晚線上申請改期日數需為正整數');
        }
      }

      if (!_.isInteger(item.minimumCharge) || item.minimumCharge < 0) {
        return $scope.alert('最低消費門檻須為正整數');
      }

      // 客製化選項
      if (!_.isEmpty(item.customizations)) {
        const validateEmptyTitle = item.customizations.every(c => c.title);
        if (!validateEmptyTitle) return $scope.alert('項目名稱不能為空');
        const validateEmptyItem = item.customizations.every(c => _.get(c, 'items.length'));
        if (!validateEmptyItem) return $scope.alert('項目選項不能為空');
        const validateMinMax = item.customizations
          .every(c => !c.quantityMin || !c.quantityMax || c.quantityMin <= c.quantityMax);
        if (!validateMinMax) return $scope.alert('最低選擇數量不得大於最高');
      }

      // 驗收與保固
      if (item.acceptanceItems.some(item => !item)) {
        return $scope.alert('請輸入驗收列項名稱');
      }
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;

      item.stockUnit = item.stockUnit || 0;

      if (item.isActivate && $scope.isPackageActivate) {
        item.paymentMethods = ['card'];
      } else {
        item.paymentMethods = $scope.paymentMethodCheckboxes
          .filter(checkbox => checkbox.checked)
          .map(checkbox => checkbox.value);
      }

      if (_.some(item.paymentMethods, (method) => method === 'card')) {
        item.paymentMethods.push('applePay');
      }

      item.isStandardRepeatable = $scope.isStandardRepeatableCheckbox.checked;

      item.displayImage = _.get(item, '_displayImageUrl._id', null);

      // 包套設定
      if ($scope.isPackageActivate) {
        item.packageInfo = {
          isActivate: _.get(item, 'isActivate', null)
        };
        if (item.isActivate) {
          item.packageInfo = {
            ...item.packageInfo,
            times: _.get(item, 'times', 0),
            frequency: {
              everyN: _.get(item, 'everyN', 0),
              unit: _.get(item, 'unit', '')
            },
            copyService: _.get(item, 'copyService')
          };
        }
      } else {
        item.packageInfo = {
          isActivate: false
        };
      }

      // 價格設定
      if (!item.isStandard && _.isNil(item.basicPrice)) {
        item.basicPrice = 0;
      }
      if (!_.isEmpty(item.specialPrices)) {
        item.specialPrices.forEach((price, index) => {
          const regionData = _.get($scope.specialPricesInput, `regionData[${index}]`, {});
          price.regions = regionData._counties.map(county => ({
            county,
            districts: regionData._districts
              .filter(district => district.startsWith(county))
              .map(district => district.split('-')[1])
          }));
          if (price.startTime) {
            if (_.get(price, 'timelines[0].type') === 'time') {
              price.startTime = moment(price.startTime).startOf('day').toDate();
            } else {
              delete price.startTime;
            }
          }
          if (price.endTime) {
            if (_.get(price, 'timelines[0].type') === 'time') {
              price.endTime = moment(price.endTime).endOf('day').toDate();
            } else {
              delete price.endTime;
            }
          }
        });
      }

      if (!_.isNumber(item.minimumCharge)) {
        item.minimumCharge = 0;
      }

      // 客製化選項
      if (!_.isEmpty(item.customizations)) {
        item.customizations = item.customizations.map(function (c) {
          if (c.selectType === 'single') {
            c.quantityMin = 0;
            c.quantityMax = 0;
            c.isRepeatable = false;
          }
          return c;
        });
      }

      // 驗收
      item.acceptanceItems = _.get(item, '_acceptanceItems', []).map(data => data.item);

      console.log('transform finished: ', item);
    }

    function syncCurrentActionItem () {
      const item = $scope.currentActionItem;

      $scope.paymentMethodCheckboxes = [
        { value: 'cash', label: '現金', checked: _.includes(item.paymentMethods, 'cash') },
        { value: 'bankTransfer', label: '匯款', checked: _.includes(item.paymentMethods, 'bankTransfer') },
        { value: 'branchBudget', label: '感服金請款', checked: _.includes(item.paymentMethods, 'branchBudget') },
        { value: 'card', label: '信用卡', checked: _.includes(item.paymentMethods, 'card') }
      ];
      $scope.isStandardRadios = [{ value: true, label: '是' }, { value: false, label: '否' }];
      $scope.isStandardRepeatableCheckbox = {
        value: false,
        label: '規格化方案單項可複選',
        checked: item.isStandardRepeatable
      };

      if ($scope.currentPageState === 'edit') {
        item.classificationId = _.get(item, 'classification._id');
        item._acceptanceItems = _.get(item, 'acceptanceItems', [])
          .map(data => ({ item: data }));

        item._displayImageUrl = item.displayImage;
        item.displayImage = _.get(item, '_displayImageUrl._id');

        item.specialBasicPrices = _.get(item, 'specialBasicPrices', []);
        item.isActivate = _.get(item, 'packageInfo.isActivate');
        if (item.isActivate) {
          $scope.paymentMethodCheckboxes.forEach(checkbox => {
            if (checkbox.value === 'card') {
              checkbox.checked = true;
            } else {
              checkbox.checked = false;
              checkbox.disabled = true;
            }
          });
          $scope.isStandardRadios.forEach((radio) => {
            if (radio.value) {
              radio.checked = true;
            } else {
              radio.checked = false;
              radio.disabled = true;
            }
          });
          $scope.isStandardRepeatableCheckbox.disabled = true;
        }
        item.times = _.get(item, 'packageInfo.times');
        item.everyN = _.get(item, 'packageInfo.frequency.everyN');
        item.unit = _.get(item, 'packageInfo.frequency.unit');
        item.copyService = _.get(item, 'packageInfo.copyService');
        item.lastApplyDays = _.get(item, 'lastApplyDays');
      }

      $scope.isSetPackageRadios = [{ value: false, label: '否' }, { value: true, label: '是' }];
      $scope.isSetPackageInput = {
        label: '包套方案',
        type: 'radio',
        index: 'isActivate',
        radios: $scope.isSetPackageRadios,
        showIndex: !!$scope.isPackageActivate,
        changed: (value) => {
          $scope.setFrequency.showIndex = value === true;
          $scope.setPackageTimes.showIndex = value === true;
          $scope.setCopyService.showIndex = value === true;
          if (value) {
            $scope.paymentMethodCheckboxes.forEach(checkbox => {
              if (checkbox.value === 'card') {
                checkbox.checked = true;
              } else {
                checkbox.checked = false;
                checkbox.disabled = true;
              }
            });
            $scope.isStandardRadios.forEach((radio) => {
              if (radio.value) {
                radio.checked = true;
                item.isStandard = true;
              } else {
                radio.checked = false;
                radio.disabled = true;
              }
            });
            $scope.isStandardInput.value = true;
            $scope.isStandardRepeatableInput.showIndex = true;
            $scope.isStandardRepeatableCheckbox.checked = false;
            $scope.isStandardRepeatableCheckbox.disabled = true;
            $scope.repeatableQuantityInput.showIndex = false;
            item.repeatableQuantity = null;
          } else {
            $scope.paymentMethodCheckboxes.forEach(checkbox => {
              checkbox.disabled = false;
              checkbox.checked = _.includes(item.paymentMethods, checkbox.value);
              $scope.isStandardRepeatableCheckbox.disabled = false;
            });
            $scope.isStandardRadios.forEach((radio) => {
              radio.disabled = false;
            });
          }
        }
      };

      $scope.setPackageTimes = {
        label: '包套次數*',
        type: 'number',
        index: 'times',
        showIndex: !!item.isActivate && !!$scope.isPackageActivate
      };

      $scope.setFrequency = {
        label: '週期*',
        type: 'service-set-interval',
        index: 'everyN', // 間隔數
        selected: item.unit,
        options: { days: '天', weeks: '週', months: '月' },
        changed: (value) => {
          item.unit = value || ''; // 週期頻率
        },
        blankItem: '請選擇',
        remark: '下單付款後，將預先以希望服務時間往後預約',
        showIndex: !!item.isActivate && !!$scope.isPackageActivate
      };

      // 複製方案
      $scope.setCopyService = {
        label: '設定複製方案*',
        type: 'service-set-picker',
        index: 'copyService',
        title: '設定複製方案',
        resource: Service,
        titleField: 'name',
        subTitleField: '_id',
        filterField: 'name',
        showSelectedText: true,
        extraParams: { displayVisible: 'all', isStandard: false, classificationMode: ['general', 'deviceCleaning'], includesPaymentMethods: ['card'] },
        remark: true,
        showIndex: !!item.isActivate && !!$scope.isPackageActivate
      };

      $scope.paymentMethodInput = {
        label: '支援付款方式',
        type: 'checkbox',
        index: 'paymentMethods',
        checkboxes: $scope.paymentMethodCheckboxes
      };
      const onIsStandardChange = (value) => {
        _.set($scope.isStandardRepeatableInput, 'showIndex', value);
        _.set($scope.repeatableQuantityInput, 'showIndex', value && !!item.isStandardRepeatable);

        if (value) {
          $scope.basicPriceInput.label += '*';
        } else {
          $scope.basicPriceInput.label = $scope.basicPriceInput.label.replace('*', '');
        }
      };
      $scope.isStandardInput = {
        label: '預約流程規格化',
        type: 'radio',
        index: 'isStandard',
        radios: $scope.isStandardRadios,
        changed: onIsStandardChange
      };

      $scope.isStandardRepeatableInput = {
        label: ' ',
        type: 'checkbox',
        index: 'isStandardRepeatable',
        checkboxes: [$scope.isStandardRepeatableCheckbox],
        checked: (value) => _.set($scope.repeatableQuantityInput, 'showIndex', value),
        showIndex: !!item.isStandard || !!item.isActivate
      };
      $scope.repeatableQuantityInput = {
        label: '複選上限',
        type: 'number',
        index: 'repeatableQuantity',
        showIndex: !!item.isStandard && !!item.isStandardRepeatable
      };

      const syncDistrictOptions = function (counties) {
        return counties
          .map(county => ({ category: county, districts: address[county] }))
          .map(districtsByCounty => _.map(
            districtsByCounty.districts,
            district => ({
              category: districtsByCounty.category,
              label: district,
              value: `${districtsByCounty.category}-${district}`
            })
          ))
          .reduce((res, districts) => res.concat(districts), []);
      };
      $scope.specialPricesInput = {
        label: '特殊供應價格設定',
        type: 'special-prices-list',
        index: 'specialPrices',
        addRow: () => {
          item.specialPrices.push({
            timelines: [{
              type: 'time',
              principle: 'include',
              weekday: '',
              time: []
            }]
          });
          $scope.specialPricesInput.regionData.push({
            _counties: [],
            _districts: []
          });
          $scope.specialPricesInput.districtOptionsByIndex.push([]);
        },
        removeRow: (index) => {
          item.specialPrices.splice(index, 1);
          $scope.specialPricesInput.regionData.splice(index, 1);
          $scope.specialPricesInput.districtOptionsByIndex.plice(index, 1);
        },
        isDurationValid: (timelines) => {
          return timelines.every(timeline => timeline.type === 'time');
        },
        regionData: item.specialPrices.map(price => ({
          _counties: (price.regions || []).map(region => region.county),
          _districts: _.chain(price.regions || [])
            .map(region => region.districts.map(district => `${region.county}-${district}`))
            .flatMap()
            .value()
        })),
        countyOptions: Object.keys(address),
        districtOptionsByIndex: item.specialPrices
          .map(price => syncDistrictOptions((price.regions || []).map(region => region.county))),
        onCountiesChange: (index, counties) => {
          $scope.specialPricesInput.districtOptionsByIndex[index] = syncDistrictOptions(counties);
          $scope.specialPricesInput.regionData[index]._districts =
            $scope.specialPricesInput.regionData[index]._districts.filter(
              district => counties.find(county => district.startsWith(county))
            );
        }
      };

      $scope.basicPriceInput = {
        label: '一般價格(通用)' + (item.isStandard ? '*' : ''),
        type: 'number',
        index: 'basicPrice'
      };

      const checkDisableByServiceClassification = async (id) => {
        if (!id) {
          return;
        }
        const classification = await ServiceClassification.one(id).customGET();

        // 依服務方案分類設定包套選項
        if (classification?.canCrossoverStandardService) {
          $scope.isPackageActivate = false; // 規格化方案可跨方案選取則不可以設定包套
        } else {
          $scope.isPackageActivate = !!((classification?.mode === 'general' || classification?.mode === 'deviceCleaning')); // 指定分類下訂才可設定包套
        }

        if ($scope.isPackageActivate && $scope.currentActionItem.isActivate) {
          $scope.paymentMethodCheckboxes.forEach(checkbox => {
            if (checkbox.value === 'card') {
              checkbox.checked = true;
            } else {
              checkbox.checked = false;
              checkbox.disabled = true;
            }
          });
          $scope.isStandardRadios.forEach((radio) => {
            if (radio.value) {
              radio.checked = true;
              item.isStandard = true;
            } else {
              radio.checked = false;
              radio.disabled = true;
            }
          });
        }
        _.set($scope.isSetPackageInput, 'showIndex', $scope.isPackageActivate); // 顯示是否包套選項

        _.set($scope.setCopyService, 'showIndex', $scope.isPackageActivate && $scope.currentActionItem.isActivate); // 顯示設定複製方案
        _.set($scope.setFrequency, 'showIndex', $scope.isPackageActivate && $scope.currentActionItem.isActivate); // 顯示包套週期
        _.set($scope.setPackageTimes, 'showIndex', $scope.isPackageActivate && $scope.currentActionItem.isActivate); // 顯示包套次數

        const isStateUnordered = classification.reservationListMode === 'stateUnordered';
        $scope.paymentMethodCheckboxes.forEach(checkbox => {
          if (checkbox.value === 'card') {
            if (isStateUnordered) {
              checkbox.checked = false;
            }
            checkbox.disabled = isStateUnordered;
          } else {
            if (isStateUnordered) {
              checkbox.disabled = false;
            }
          }
        });
        $scope.paymentMethodInput.info = isStateUnordered
          ? '所選的服務分類的"訂單列表管理模式"="工程管理模式"時，不支援信用卡付款'
          : '';

        $scope.isStandardRadios.forEach(radio => {
          if (radio.value === true) {
            if (isStateUnordered) {
              item.isStandard = false;
              onIsStandardChange(false);
            }
            radio.disabled = isStateUnordered;
          }
        });
        $scope.isStandardInput.info = isStateUnordered
          ? '所選的服務分類的"訂單列表管理模式"="工程管理模式"時，僅支援非規格化流程'
          : '';
      };
      checkDisableByServiceClassification(_.get(item, 'classification._id'));

      $scope.inputItems = [
        {
          title: '基本設定',
          inputs: [
            { label: '服務方案名稱*', type: 'text', index: 'name' },
            { label: '方案描述', type: 'textarea', index: 'description' },
            {
              label: '服務方案分類*',
              type: 'searchable-obj-picker',
              index: 'classificationId',
              title: '服務方案分類',
              resource: ServiceClassification,
              filterField: 'classificationName',
              extraParams: { displayVisible: 'all' },
              changed: checkDisableByServiceClassification
            },
            // { label: '佔用庫存單位', type: 'number', index: 'stockUnit' },
            // { label: '扣除庫存設定',
            // type: 'radio',
            // index: 'stockDeductionMethod',
            // radios: [
            // { value: 'multiple', label: '依下訂組數倍數扣除' },
            // { value: 'onlyOne', label: '單張訂單僅扣1組庫存' },
            // ],
            // },
            $scope.isSetPackageInput,
            $scope.setPackageTimes,
            $scope.setFrequency,
            $scope.setCopyService,
            $scope.paymentMethodInput,
            $scope.isStandardInput,
            $scope.isStandardRepeatableInput,
            $scope.repeatableQuantityInput,
            {
              label: '顯示圖片',
              type: 'single-file',
              index: '_displayImageUrl',
              originIndex: 'displayImage',
              class: 'images-wrapper',
              files: $scope.displayImageFiles,
              success: $scope.singleFileUploaded,
              error: $scope.alert
            },
            {
              label: '日期選擇活動提示文字',
              type: 'text',
              index: 'calenderEventHint',
              class: 'calender-event-hint-input',
              info: '長度限制30字內，可使用emoji，有填寫才會顯示，跨方案選擇時，顯示方案排序優先度較高的。',
              maxlength: 30
            },
            {
              label: '最近可預約日數',
              type: 'number',
              index: 'recentPickableInterval',
              info: `
                <div>D為用戶操作下訂當天，用戶可以選到最近的日期為D+N日，此處設定為N值。</div>
                <br/>
                <div>範例說明: 用戶於2021/11/09使用平台系統下訂，設定為3，則用戶可選到最近的日期為2021/11/12日。</div>
                <br/>
                <div>*當用戶跨方案選擇，N值不同時，取所有方案最大N值設定，為用戶可下訂最近的日期。</div>
              `
            },
            {
              label: '最晚可取消日數',
              type: 'number',
              index: 'lastCancelDays',
              info: `
                <div>D為預計服務日期，用戶最晚可以取消的日期為D-N日，此處設定為N值。</div>
                <br/>
                <div>範例說明: 服務執行日期為 2021/05/28，設定為2，則用戶在2021/05/26開始則無法取消訂單，最晚可取消的時間為2021/05/25的 23:59。欲使用戶下單即無法取消，則建議設定超過用戶可能會遇到的工期，例如1000。</div>
                <br/>
                <div>*當用戶跨方案選擇，N值不同時，取所有方案最大N值設定，為用戶最晚可取消日數。</div>
              `
            },
            {
              label: '最晚線上申請改期日數*',
              type: 'number',
              index: 'lastApplyDays',
              info: `
                <div>D為預計服務日期，用戶最晚可以線上申請改期的日期為 D-N日，此處設定為N值。</div>
                <br/>
                <div>範例說明: 服務執行日期為 2021/05/28，N設定為2，則用戶在2021/05/26開始則無法於會員專區中提出改期需求，最晚可提出改期需求的時間為2021/05/25的 23:59。欲使用戶下單無法申請改期，則建議設定超過用戶可能會遇到的工期，例如1000。</div>
                <br/>
                <div>*當用戶跨方案選擇，N值不同時，取所有方案最大N值設定，為用戶最晚可申請改期日期。</div>
              `
            },
            { label: '排序', type: 'number', index: 'order', info: '數值越大越前面' },
            {
              label: '顯示設定',
              type: 'radio',
              index: 'isVisible',
              radios: [{ value: true, label: '顯示' }, { value: false, label: '隱藏' }]
            },
            {
              label: '限定群組顯示',
              type: 'multi-searchable-obj-picker',
              index: 'targetUserGroups',
              title: '使用者群組',
              resource: UserGroup,
              titleField: 'name',
              filterField: 'name',
              queryParams: { limit: 200 },
              closeCityFilter: true,
              showSelectedItemsCount: true
            },
            {
              label: '通知發送模式',
              type: 'radio',
              index: 'notifyMode',
              radios: [{ value: 'normal', label: '預設' }, { value: 'simple', label: '簡化' }],
              info: '部分狀態觸發預設會發送的通知，在簡化模式下不會發送，詳請可查看"流程狀態與通知對應"文件，點擊[i]icon可開啟文件連結(https://link.livinglife.com.tw/9RHIAR)',
              onInfoClick: () => {
                window.open('https://link.livinglife.com.tw/9RHIAR', '_blank');
              }
            },
            { type: 'action' }
          ]
        },
        {
          title: '價格設定',
          inputs: [
            $scope.basicPriceInput,
            {
              label: '一般價格(特定縣市)',
              type: 'special-basic-prices',
              index: 'specialBasicPrices',
              countyOptions: Object.keys(address),
              addRow: () => item.specialBasicPrices.push({ item: '' }),
              removeRow: (index) => item.specialBasicPrices.splice(index, 1)
            },
            $scope.specialPricesInput,
            { label: '最低消費門檻', type: 'number', index: 'minimumCharge', info: '跨方案時，取此欄數字較高的方案為最低消費門檻的金額' },
            { type: 'action' }
          ]
        },
        {
          title: '客製化項目',
          inputs: [
            {
              label: '客製化項目設定',
              type: 'service-customization',
              index: 'customizations',
              removableItemIndex: 'removableCustomizations'
            },
            { type: 'action' }
          ]
        }
      ];
    }
  }];
