export default ['$scope', 'ywUtil', 'currentUser', 'ywEvent', '$state', 'AdminNotifications', function controller ($scope, ywUtil, currentUser, ywEvent, $state, AdminNotifications) {
  $scope.currentUser = currentUser;
  $scope.systemAlert = '';

  $scope.logout = function () {
    currentUser.logout();
  };

  $scope.toggleLeftMenu = function () {
    $scope.$emit('toggleLeftMenu');
  };

  // const systemAlert = ywEvent.register('SYSTEM_ALERT', (data) => {
  // if (!data) return;
  // $scope.systemAlert = data;
  // $scope.info(data);
  // });
  $scope.$on('$destroy', () => {
    // ywEvent.unregister(systemAlert);
  });

  $scope.isAdmin = currentUser.hasRole('admin');
  $scope.hasAdminToken = currentUser.getToken('admin');
}];
