import moment from 'moment';

export default ['$scope', '$async', 'currentUser', 'Restangular', 'NotificationGroup', 'CouponEvent', 'Vender', 'UserGroup',
  function controller ($scope, $async, currentUser, Restangular, NotificationGroup, CouponEvent, Vender, UserGroup) {
    $scope.inputItems = [];
    $scope.transform = transform;
    $scope.mainAction._validator = validator;

    $scope.$watch('currentActionItem', syncCurrentActionItem);

    const save = async () => {
      const item = $scope.currentActionItem;
      const results = await $scope.$parent.save({
        customSaveAction: Restangular.service('notifications').post,
        doNothingAfterSave: true,
        extraParams: (item.time ? null : { time: moment().add(20, 'seconds').toDate() })
      });
      if (results.error) {
        return Promise.reject(results.error);
      }
      const newRow = NotificationGroup.one(results.notificationGroup._id);
      for (const key in results.notificationGroup) {
        newRow[key] = results.notificationGroup[key];
      }
      $scope.afterSaved({ res: newRow.clone() });
    };
    $scope.save = save;

    function validator () {
      console.log('validator:', $scope.currentActionItem);
      const item = $scope.currentActionItem;

      if (item.category === 'acquireCoupon' && _.isEmpty(item.couponEventIds)) {
        return $scope.alert('請選擇優惠券活動');
      }

      if (item.targetType === 'phone' && !item._phones) {
        return $scope.alert('請輸入手機號碼');
      }
      if (item.targetType === 'userId' && !item._userIds) {
        return $scope.alert('請輸入用戶ID');
      }
      if (item.targetType === 'userGroup' && _.isEmpty(item._userGroups)) {
        return $scope.alert('請選擇會員群組');
      }

      const types = $scope.notificationTypesCheckboxes.filter(type => type.checked);
      if (_.isEmpty(types)) {
        return $scope.alert('請至少選擇一項推播方式');
      }
      if (item._notificationIsAppPub && !item._notificationPushContent) {
        return $scope.alert('請輸入推播文案');
      }
      if (item._notificationIsEmail && !item._notificationEmailTitle) {
        return $scope.alert('請輸入email title');
      }
      if (item._notificationIsEmail && !item._notificationEmailContent) {
        return $scope.alert('請輸入email內文');
      }
      if (item._notificationIsSms && !item._notificationSmsContent) {
        return $scope.alert('請輸入簡訊文案');
      }

      console.log('currentActionItem: ', item);
      return true;
    }

    function transform () {
      const item = $scope.currentActionItem;

      if (item.targetType === 'global') {
        item.userGroup = 'global';
      } else {
        _.unset(item, 'userGroup');
      }

      if (item.targetType === 'phone') {
        item.phones = (item._phones || '').split('\n');
      }
      if (item.targetType === 'userId') {
        item.userIds = (item._userIds || '').split('\n');
      }
      if (item.targetType === 'userGroup') {
        item.userGroups = item._userGroups;
      }

      item.notificationMethod = {
        isAppPub: !!$scope.notificationTypesCheckboxes.find(checkbox => checkbox.index === 'isAppPub').checked,
        pushContent: item._notificationPushContent || '',
        isEmail: !!$scope.notificationTypesCheckboxes.find(checkbox => checkbox.index === 'isEmail').checked,
        emailTitle: item._notificationEmailTitle,
        emailContent: item._notificationEmailContent,
        isSms: !!_.get($scope.notificationTypesCheckboxes.find(checkbox => checkbox.index === 'isSms'), 'checked', false),
        smsContent: item._notificationSmsContent
      };

      console.log('transform finished: ', item);
    }

    function syncCurrentActionItem () {
      const item = $scope.currentActionItem;

      const categoryInput = currentUser.hasRole('serviceAdmin')
        ? {
            label: '發送類型*',
            type: 'radio',
            index: 'category',
            radios: [
              { value: 'message', label: '純訊息推播' },
              { value: 'acquireCoupon', label: '歸戶優惠券推播' }
            ],
            changed: (type) => {
              couponEventPickerInput.showIndex = type === 'acquireCoupon';
              updateNavDataInputByCategory(type);
              if (type === 'acquireCoupon') {
                item.navData = {};
              }
            }
          }
        : { type: 'empty' };

      const couponEventPickerInput = {
        label: '選擇優惠券活動*',
        type: 'multi-searchable-obj-picker',
        index: 'couponEventIds',
        title: '優惠活動',
        resource: CouponEvent,
        titleField: 'subjectName',
        subTitleField: 'couponDefault.title',
        filterField: 'subjectName',
        queryParams: { limit: 200 },
        closeCityFilter: true,
        showSelectedItemsCount: true,
        showIndex: item.category === 'acquireCoupon'
      };

      const phonesInput = {
        label: '推播用戶手機',
        type: 'textarea',
        index: '_phones',
        showIndex: item.targetType === 'phone',
        info: '多筆資料請使用換行分隔'
      };
      const userIdsInput = {
        label: '推播用戶ID',
        type: 'textarea',
        index: '_userIds',
        showIndex: item.targetType === 'userId',
        info: '多筆資料請使用換行分隔'
      };
      const userGroupsInput = {
        label: '選擇會員群組',
        type: 'multi-searchable-obj-picker',
        index: '_userGroups',
        resource: UserGroup,
        titleField: 'name',
        closeCityFilter: true,
        showSelectedItemsCount: true,
        showIndex: item.targetType === 'userGroup'
      };

      $scope.notificationTypesCheckboxes = [
        {
          value: false,
          label: 'app推播',
          index: 'isAppPub',
          checked: item._notificationIsAppPub
        },
        {
          value: false,
          label: 'email',
          index: 'isEmail',
          checked: item._notificationIsEmail
        }
      ];
      const smsCheckbox = { value: false, label: '簡訊', index: 'isSms', checked: item._notificationIsSms };
      if (currentUser.hasRole('serviceAdmin')) {
        $scope.notificationTypesCheckboxes.push(smsCheckbox);
      } else if (currentUser.hasRole('vender')) {
        Vender.one(currentUser.get().link.vender).customGET().then(res => {
          if (_.get(res, 'isActiveSmsSending')) {
            $scope.notificationTypesCheckboxes.push(smsCheckbox);
          }
        });
      }

      const updateNavDataInputByCategory = (category) => {
        if (category === 'message') {
          $scope.notificationPushNavData.info = '指定app推播點擊跳轉的位置，不設定則開啟app預設頁面';
          $scope.notificationPushNavData.disabled = false;
        } else if (category === 'acquireCoupon') {
          $scope.notificationPushNavData.info = '歸戶優惠券推播直接跳轉至優惠券列表';
          $scope.notificationPushNavData.disabled = true;
        }
      };

      $scope.notificationPushNavData = {
        label: 'app推播頁面跳轉',
        type: 'navigation-setting',
        index: 'navData',
        info: '指定app推播點擊跳轉的位置，不設定則開啟app預設頁面',
        buttonLabel: '設定',
        showIndex: !!_.get(item, '_notificationIsAppPub')
      };
      $scope.notificationPushContentInput = {
        label: '推播文案',
        type: 'textarea',
        index: '_notificationPushContent',
        showIndex: !!_.get(item, '_notificationIsAppPub'),
        maxlength: 200
      };

      $scope.notificationEmailTitleInput = {
        label: 'email主旨',
        type: 'text',
        class: 'long',
        index: '_notificationEmailTitle',
        showIndex: !!_.get(item, '_notificationIsEmail'),
        maxlength: 200
      };
      $scope.notificationEmailContentInput = {
        label: 'email內文',
        type: 'textarea',
        index: '_notificationEmailContent',
        showIndex: !!_.get(item, '_notificationIsEmail')
      };

      $scope.notificationSmsContentInput = {
        label: '簡訊文案',
        type: 'textarea',
        index: '_notificationSmsContent',
        showIndex: !!_.get(item, '_notificationIsSms'),
        showLength: true,
        info: `
          <div>扣點規則如下，請參考~</div>
          <div>1.訊息內容為長度超過160個字的純半形英數字，或內容包含中文且長度超過70個字時，將以長簡訊發送；反之則為短簡訊。</div>
          <div>2.【國內長簡訊】若簡訊內容為純半形英數字，每153個字需一點；包含任一中文字，則每67個字需一點。</div>
          <div>3.【國內短簡訊】每一則需一點。</div>
          <div>4.【國際簡訊】為上述國內訊息的三倍計費。</div>
        `
      };

      $scope.inputItems = [
        categoryInput,
        couponEventPickerInput,
        {
          label: '尋找用戶方式*',
          type: 'radio',
          index: 'targetType',
          radios: [
            { value: 'phone', label: '手機號碼' },
            { value: 'userId', label: '用戶ID' }
          ].concat(currentUser.hasRole('serviceAdmin')
            ? [{ value: 'userGroup', label: '會員群組' }, { value: 'global', label: '所有用戶' }]
            : []
          ),
          changed: (type) => {
            phonesInput.showIndex = type === 'phone';
            userIdsInput.showIndex = type === 'userId';
            userGroupsInput.showIndex = type === 'userGroup';
          }
        },
        phonesInput,
        userIdsInput,
        userGroupsInput,
        {
          label: '通知方式',
          type: 'checkbox',
          index: '_notificationTypes',
          checkboxes: $scope.notificationTypesCheckboxes,
          checked: (bool, option) => {
            if (option.index === 'isAppPub') {
              $scope.notificationPushNavData.showIndex = !!bool;
              $scope.notificationPushContentInput.showIndex = !!bool;
            } else if (option.index === 'isEmail') {
              $scope.notificationEmailTitleInput.showIndex = !!bool;
              $scope.notificationEmailContentInput.showIndex = !!bool;
            } else if (option.index === 'isSms') {
              $scope.notificationSmsContentInput.showIndex = !!bool;
            }
          },
          info: `
            <div>將於到期日15天前的早上九點進行推播。</div>
            <div>帶入變數名稱使用如下：</div>
            <div>客戶名稱: $name$</div>
            <div>優惠主題: $couponSubject$</div>
            <div>優惠名稱: $couponTitle$</div>
          `
        },
        $scope.notificationPushNavData,
        $scope.notificationPushContentInput,
        $scope.notificationEmailTitleInput,
        $scope.notificationEmailContentInput,
        $scope.notificationSmsContentInput,
        { label: '通知發送時間', type: 'datetime-picker', index: 'time', info: '沒填時間則為立即發送' },
        { label: '內部備註', type: 'textarea', index: 'note' },
        { type: 'action' }
      ];

      updateNavDataInputByCategory(item.category);
    }
  }];
