export default [function factory () {
  const util = {};
  const addViewPortMeta = function () {
    // 進入使用此 utils 的頁面，動態加載 meta viewport 設定
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'viewport');
    metaTag.setAttribute('content', 'width=device-width, initial-scale=1');
    document.head.appendChild(metaTag);
    console.log('addViewPortMeta');
  };

  const removeViewPortMeta = function () {
    const metaTagToRemove = document.querySelector('meta[name="viewport"]');
    if (metaTagToRemove) {
      metaTagToRemove.remove();
      console.log('removeViewPortMeta');
    }
  };

  const addLoadViewPortMeta = function () {
    // 進入頁面時新增監聽器
    window.addEventListener('load', addViewPortMeta);
  };

  const removeLoadViewPortMeta = function () {
    // 離開頁面時移除監聽器
    window.removeEventListener('load', addViewPortMeta);

    // 離開頁面時，移除動態加載的 meta viewport 設定
    removeViewPortMeta();
  };

  const locationChangeViewPortMeta = function (pathname) {
    const addViewPortRoute = ['reservation-detail', 'unordered-reservation-detail'];
    removeViewPortMeta();
    if (addViewPortRoute.indexOf(pathname) > -1) addViewPortMeta();
  };

  util.addLoadViewPortMeta = addLoadViewPortMeta;
  util.removeLoadViewPortMeta = removeLoadViewPortMeta;
  util.removeViewPortMeta = removeViewPortMeta;
  util.locationChangeViewPortMeta = locationChangeViewPortMeta;
  return util;
}];
