export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('orders', false, _transformer);
  const Order = Restangular.service('orders');
  return Order;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count
    if (item === 'ok') return item;

    item._columns = [
    ];

    return item;
  }
}];
