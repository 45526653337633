export default ['$timeout', 'ywDialog', function directive ($timeout, ywDialog) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      options: '=ywOptions',
      removableOptions: '=ywRemovableOptions',
      label: '=ywLabel',
      blankItem: '=ywBlankItem',
      changed: '&ywOnChange',
      clicked: '&ywOnClick',
      selected: '=ywSelected',
      disabled: '=ywDisabled',
      customizeService: '=ywCustomizeService',
      customizeOptionCreated: '=ywCustomizeOptionCreated',
      removed: '=ywRemoved',
      white: '=',
      searchable: '=ywSearchable',
      byCategory: '=ywByCategory',
      hideCategory: '=ywHideCategory',
      activeStyle: '='
    },
    template: `
      <div
        class="select-wrapper wrapper"
        ng-class="{disabled: disabled, white: white, 'active-control': selected !== undefined && activeStyle}"
        ng-click="disabled || onClick()"
      >
        <a class="select" ng-disabled="disabled">
          <span ng-bind="selectedLabel">
          </span>
          <span class="caret"></span>
        </a>
      </div>
    `,
    link: link
  };

  function link (scope, element, attr) {
    scope.onClick = function () {
      if (scope.clicked) {
        scope.clicked();
      }
      scope.openPopupMenu();
    };

    let data = {};
    scope.openPopupMenu = function () {
      const convertedOptions = this.convertOptions(this.removableOptions, true).concat(this.convertOptions(this.options, false));
      const dialogOpts = { plain: true, closeByEscape: true, closeByDocument: true };
      data = {
        options: convertedOptions,
        originOptions: _.clone(convertedOptions),
        blankItem: this.blankItem,
        title: this.blankItem || this.label,
        selected: this.selected,
        showFooter: (!!scope.customizeService),
        openCustomizeCreationDialog: scope.openCustomizeCreationDialog,
        removeCustomizeOption: scope.removeCustomizeOption,
        searchable: scope.searchable,
        byCategory: scope.byCategory,
        hideCategory: scope.hideCategory,
        searchTextChange
      };
      if (scope.byCategory) {
        data.categories = _.union(_.map(scope.options, opt => opt.category));
        console.log('categories: ', data.categories);
      }

      ywDialog.openConfirm('/view/directive/popup-selection-menu.html', data, null, dialogOpts)
        .then(function (item) { // item selected callback
          scope.selected = (item.value !== scope.blankItem) ? item.value : undefined;
          $timeout(function () {
            scope.$apply();
            if (scope.changed) { scope.changed({ selectedItem: item }); }
          });
        });
    };

    function searchTextChange (text) {
      data.options = _.filter(data.originOptions, option => option.label.match(text));
    }

    scope.convertOptions = function (options, removable) {
      if (options instanceof Array) {
        if (_.isObject(options[0])) {
          return options.map(function (o) { o.removable = removable; return o; });
        } else {
          return options.map(function (opt, index) {
            return { value: opt, label: opt, removable: removable };
          });
        }
      } else if (options !== undefined) { // opject
        const result = [];
        for (const opt in options) {
          result.push({ value: opt, label: options[opt], removable: removable });
        }
        return result;
      } else {
        return [];
      }
    };

    scope.openCustomizeCreationDialog = function (closeCb) {
      if (closeCb) closeCb();

      const dialogOpts = { closeByEscape: true, closeByDocument: true };
      ywDialog.openConfirm('/view/directive/custom-option-creation-dialog.html', {}, null, dialogOpts)
        .then(function (data) {
          if (!data) return;
          scope.customizeService.one().customPOST({ value: data }).then(function (res) {
            if (scope.customizeOptionCreated) { scope.customizeOptionCreated(res.plain()); }
            scope.selected = data;
            $timeout(function () {
              scope.$apply();
              if (scope.changed) { scope.changed(); }
            });
          });
        });
    };

    scope.removeCustomizeOption = function (e, value) {
      e.stopImmediatePropagation();

      scope.customizeService.getList().then(function (res) {
        const item = res.find(function (item) { return item.value === value; });
        if (!item) return;
        scope.customizeService.one(item._id).remove().then(function (res) {
          if (res.error) return console.log(res.error);
          if (value === scope.selected) scope.selected = undefined;
          $timeout(function () {
            scope.$apply();
            if (scope.removed) { scope.removed(value); }
          });
        });
      });
    };

    scope.setSelectedLabel = function () {
      const self = this;
      if (this.selected !== undefined) {
        if (this.options instanceof Array && this.options.indexOf(this.selected) > -1) {
          this.selectedLabel = this.selected;
        } else if (this.options instanceof Array && this.options.some(function (o) { return o.value === self.selected; })) {
          this.selectedLabel = this.options.find(function (o) { return o.value === self.selected; }).label;
        } else if (this.removableOptions instanceof Array && this.removableOptions.indexOf(this.selected) > -1) {
          this.selectedLabel = this.selected;
        } else if (this.options && this.options[this.selected] !== undefined) {
          this.selectedLabel = this.options[this.selected];
        } else if (this.removableOptions && this.removableOptions[this.selected] !== undefined) {
          this.selectedLabel = this.removableOptions[this.selected];
        }
      } else {
        if (this.label) { this.selectedLabel = this.label; } else { this.selectedLabel = (this.blankItem) ? this.blankItem : '請選擇'; }
      }
    };

    scope.setSelectedLabel();

    scope.$watch('options', function (newValue, oldValue) {
      // if (newValue === oldValue) return;
      data.options = scope.convertOptions(scope.removableOptions, true).concat(scope.convertOptions(scope.options, false));
      scope.setSelectedLabel();
    });

    scope.$watch('removableOptions', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      data.options = scope.convertOptions(scope.removableOptions, true).concat(scope.convertOptions(scope.options, false));
    });
    scope.$watch('selected', function (newValue, oldValue) {
      if (newValue === oldValue) return;
      scope.setSelectedLabel();
    });
  }
}];
