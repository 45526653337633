export default ['$timeout', 'Restangular', 'searchableObjPickerDialog', 'address', function directive ($timeout, Restangular, searchableObjPickerDialog, address) {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      ywTitle: '=',
      ywInputPlaceholder: '=',
      objResource: '=',
      blankItem: '=ywBlankItem',
      selectedId: '=',
      changed: '&ywOnChange',
      disabled: '=ywDisabled',
      filterField: '=',
      queryField: '=',
      addressFilter: '=',
      titleField: '=',
      subTitleField: '=',
      extraParams: '=',
      activeStyle: '=',
      showSelectedText: '=',
      ywType: '=',
      referralFeeInvoice: '=',
      disableConfirm: '='
    },
    template: `
      <div>
        <div class="select-wrapper dark wrapper" ng-class="{disabled: disabled, 'active-control': selectedLabel !== blankItem && selectedLabel !== '請選擇' && activeStyle}">
          <a class="select" ng-disabled="disabled" ng-click="disabled || openSearchableObjSelection({}, this)">
            <span ng-bind="selectedLabel">
            </span>
            <span class="caret"></span>
          </a>
        </div>
        <span ng-if="!!selectedName && !!showSelectedText" class="service-copy-text">
          {{selectedClassificationName}}-{{selectedName}}
        </span>
      </div>
    `,
    link: function (scope, elem, attrs) {
      scope.openSearchableObjSelection = function (data, scope) {
        const { changed, activeStyle, ...dialogData } = scope;
        searchableObjPickerDialog.openConfirm({ ...dialogData, selectedCb });
      };

      function selectedCb (obj, confirmCb) {
        scope.selectedId = obj._id || null;
        scope.selectedName = obj.name || null;
        scope.selectedClassificationName = obj?.classification?.name || null;
        $timeout(function () {
          scope.$apply();
          if (scope.changed) { scope.changed(); }
          if (confirmCb) { confirmCb(); }
        });
      }

      scope.setSelectedLabel = function () {
        if (scope.selectedId !== undefined) {
          if (!_.isEmpty(_.get(scope, 'dialogData.objs'))) {
            const selectedObj = scope.dialogData.objs.find(function (obj) { return obj._id === scope.selectedId; });
            scope.selectedLabel = (selectedObj)
              ? _.get(selectedObj, scope.titleField, selectedObj.title || selectedObj.name)
              : '';
          } else {
            if (scope.ywType === 'referralFeeInvoice') {
              Restangular.one('bills/reCreateChecking').customPOST({
                venderId: scope.selectedId
              }).then((res) => {
                scope.referralFeeInvoice = res;
                scope.disableConfirm = !res.hasBill;
              }).catch((error) => {
                console.error('Error occurred:', error);
              });
            }

            scope.objResource.one(scope.selectedId).customGET().then((res) => {
              if (res) {
                scope.selectedLabel = _.get(res, scope.titleField) || _.get(res, 'title') || _.get(res, 'name');
                scope.selectedName = scope.selectedLabel;
                scope.selectedClassificationName = res?.classification?.name || null;
              } else if (_.isArray(res)) {
                const target = _.find(res, item => item._id === scope.selectedId);
                scope.selectedLabel = _.get(target, 'name');
              }
            });
          }
        } else {
          this.selectedLabel = (this.blankItem) ? this.blankItem : '請選擇';
        }
      };

      scope.$watch('selectedId', (newValue, oldValue) => {
        if (newValue === oldValue) return;
        scope.setSelectedLabel();
      });

      scope.$on('init-filter', () => {
        scope.selectedId = undefined;
        scope.setSelectedLabel();
      });

      scope.setSelectedLabel();
    }
  };
}];
