export default ['uuid', function (uuid) {
  const eventMap = {}; // key: event name, value: array of registered callback

  function registerEvent (name, callback) {
    if (!name || !callback) { throw new Error('ywEvent: empty parameter'); }

    const handler = { id: uuid.gen(), callback: callback };
    if (!eventMap[name]) {
      eventMap[name] = [handler]; // init event if none
    } else {
      eventMap[name].push(handler); // push if event exist
    }
    return handler.id;
  }

  function unregisterEvent (id, eventName) {
    if (!id) throw new Error('unregisterEvent: first argument "id" is necessary');
    for (const eveName in eventMap) {
      const index = _.findIndex(eventMap[eveName], handler => _.eq(id, handler.id));
      if (index > -1) {
        eventMap[eveName].splice(index, 1);
        return true;
      }
    }
    return false;
  }

  function emitEvent (name, data) {
    if (!name || !eventMap[name]) { return console.log('no one register event: ', name); }
    // do callbacks
    eventMap[name].forEach(function (handler) {
      if (data) { handler.callback(data); } else { handler.callback(); }
    });
  }

  // exposed object
  return {
    register: registerEvent,
    unregister: unregisterEvent,
    emit: emitEvent
  };
}];
