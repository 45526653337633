export default ['$scope', '$async', 'confirmDialog', 'Restangular', 'Article', 'ywEvent', function controller ($scope, $async, confirmDialog, Restangular, Article, ywEvent) {
  $scope.homepageLayout = {
    items: [],
    documentId: null,
    itemMoved: (index) => $scope.homepageLayout.items.splice(index, 1),
    itemRemoved: (index) => $scope.homepageLayout.items.splice(index, 1),
    itemAdded: () => $scope.homepageLayout.items.push({ content: [] }),
    openContentSetupDialog: (item) => {
      const dialogData = {
        title: '內容設定',
        templateUrl: '/view/dialog/website-content-dialog.html',
        items: item.content || [],
        itemMoved: (index) => dialogData.items.splice(index, 1),
        itemRemoved: (index) => dialogData.items.splice(index, 1),
        itemAdded: () => dialogData.items.push({}),
        removeImage: (index) => {
          dialogData.items[index].image = null;
        },
        imageUploadSuccess: (result, index) => {
          dialogData.items[index].image = result;
        },
        error: (message) => ywEvent.emit('ALERT', message),
        confirmWhenResolve: (data, dialogData, confirmCb) => {
          item.content = dialogData.items.map(item => ({
            describe: item.describe,
            image: item.image,
            navData: item.navData
          }));
          confirmCb();
        }
      };
      confirmDialog.openConfirm(dialogData, 'custom-width-800');
    },
    onConfirm: $async(async () => {
      const dataList = $scope.homepageLayout.items;
      // validate
      if (dataList.some(layout => !layout.title)) {
        return ywEvent.emit('ALERT', '請輸入標題');
      }
      if (dataList.some(layout => layout.content.some(content => !content.image))) {
        return ywEvent.emit('ALERT', '內容設定中顯示圖為必填');
      }

      const homepages = dataList
        .map(layout => ({
          ...layout,
          content: layout.content.map(content => ({ ...content, image: _.get(content, 'image._id') }))
        }))
        .map(layout => ({ title: layout.title, content: layout.content }));
      const method = !$scope.homepageLayout.documentId
        ? Restangular.service('articleLayouts').post
        : Restangular.service('articleLayouts').one($scope.homepageLayout.documentId).customPUT;
      const res = await method({ type: 'homepages', homepages });
      if (res.error) {
        ywEvent.emit('ALERT', res.error);
      } else {
        $scope.homepageLayout.items = res.homepages;
        ywEvent.emit('SUCCESS', '儲存成功');
      }
    })
  };

  $scope.mainArticleLayout = {
    ids: [],
    documentId: null,
    resource: Article,
    filterField: 'title',
    titleField: 'title',
    title: '文章列表',
    addressFilter: false,
    onConfirm: $async(async () => {
      const mainArticles = $scope.mainArticleLayout.ids.filter(id => id);
      const method = !$scope.mainArticleLayout.documentId
        ? Restangular.service('articleLayouts').post
        : Restangular.service('articleLayouts').one($scope.mainArticleLayout.documentId).customPUT;
      const res = await method({ type: 'mainArticles', mainArticles });
      if (res.error) {
        ywEvent.emit('ALERT', res.error);
      } else {
        $scope.mainArticleLayout.ids = res.mainArticles.map(article => article._id);
        ywEvent.emit('SUCCESS', '儲存成功');
      }
    })
  };

  // initialize data
  Restangular.service('articleLayouts').getList().then(res => {
    if (res.error) {
      ywEvent.emit('ALERT', res.error);
    } else {
      const homepageData = res.find(data => data.type === 'homepages');
      if (homepageData) {
        $scope.homepageLayout.items = homepageData.homepages;
        $scope.homepageLayout.documentId = homepageData._id;
      }

      const mainArticleData = res.find(data => data.type === 'mainArticles');
      if (mainArticleData) {
        $scope.mainArticleLayout.ids = mainArticleData.mainArticles.map(article => article._id);
        $scope.mainArticleLayout.documentId = mainArticleData._id;
      }
    }
  });
}];
