export default {
  bindings: {
  },
  template: `
    <div class="image-viewer" ng-if="$ctrl.open">
      <div class="content" layout="row">
        <div class="side flex-centered">
          <div class="image-nav-btn" ng-click="$ctrl.onNavLeft()">
            <span class="glyphicon glyphicon-menu-left"></span>
          </div>
        </div>
        <div class="image-container flex-centered">
        <img ng-src="{{$ctrl.imageUrls[$ctrl.index]}}"/>
        </div>
        <div class="side flex-centered">
          <div class="image-nav-btn" ng-click="$ctrl.onNavRight()">
            <span class="glyphicon glyphicon-menu-right"></span>
          </div>
        </div>

        <div class="count-container">
          <span class="count">
            {{$ctrl.index + 1}}
            /
            {{$ctrl.imageUrls.length || 0}}
          </span>
        </div>

        <div class="close-btn" ng-click="$ctrl.onClose()">
          <span class="glyphicon glyphicon-remove mr-5"></span>關閉
        </div>
      </div>
    </div>
  `,
  controller: ['$element', 'ywEvent', function imageViewer ($element, ywEvent) {
    this.imageUrls = [
    ];
    this.index = 0;

    this.onClose = () => {
      this.open = false;
    };

    this.onNavLeft = () => {
      this.index = this.index
        ? _.max([this.index - 1, 0])
        : _.max([_.get(this.imageUrls, 'length', 0) - 1, 0]);
    };
    this.onNavRight = () => {
      this.index = this.index === _.max([_.get(this.imageUrls, 'length') - 1, 0])
        ? 0
        : this.index + 1;
    };

    const handleImageViewerOpen = ({ imageUrls, index }) => {
      this.imageUrls = imageUrls || [];
      this.index = index || 0;
      this.open = true;
    };

    this.$onInit = () => {
      this.openEvent = ywEvent.register('OPEN_IMAGE_VIEWER', handleImageViewerOpen);
    };

    this.$onDestroy = () => {
      ywEvent.unregister('OPEN_IMAGE_VIEWER', handleImageViewerOpen);
    };
  }]
};
